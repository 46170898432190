import classNames from "classnames";
import { bool } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { IconCard } from "../../components";
import { deletePaymentMethod, savePaymentMethod } from "../../ducks/paymentMethods.duck";
import { handleCardSetup } from "../../ducks/stripe.duck";
import { isScrollingDisabled, manageDisableScrolling } from "../../ducks/UI.duck";
import { PaymentMethodsForm } from "../../forms";
import { ensureCurrentUser, ensurePaymentMethodCard, ensureStripeCustomer } from "../../util/data";
import { FormattedMessage, injectIntl, intlShape } from "../../util/reactIntl";
import { propTypes } from "../../util/types";
import { createStripeSetupIntent, loadData, stripeCustomer } from "./BuyerPaymentDetailsPage.duck.js";
import css from "./BuyerPaymentDetailsPage.module.css";
import BuyerBillingDetailsForm from "../../forms/BuyerBillingDetailsForm/BuyerBillingDetailsForm";
import { buyerUpdateCompany } from "../../ducks/buyer-company.duck";
import LoaderComponent from "../../components/Loader/Loader";

export class BuyerPaymentDetailsPageComponent extends Component {
  constructor(props) {
    super(props);
    this.getClientSecret = this.getClientSecret.bind(this);
    this.getPaymentParams = this.getPaymentParams.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleComponentSubmit = this.handleComponentSubmit.bind(this);
    this.handleRemovePaymentMethod = this.handleRemovePaymentMethod.bind(this);
    this.setIsSubmitting = this.setIsSubmitting.bind(this);
    this.setCardState = this.setCardState.bind(this);
    this.submitBillingDetails = this.submitBillingDetails.bind(this);
    this.fileLogo = null;
    this.submitInProgress = false;
    this.state = { isSubmitting: false, cardState: null, firstLoad: false };
  }

  setIsSubmitting(value) {
    this.setState({ ...this.state, isSubmitting: value });
  }

  setCardState(value) {
    this.setState({ ...this.state, cardState: value });
  }

  getClientSecret(setupIntent) {
    return setupIntent && setupIntent.attributes ? setupIntent.attributes.clientSecret : null;
  };

  getPaymentParams(currentUser, formValues) {
    const { name, addressLine1, addressLine2, postal, state, city, country } = formValues;
    const addressMaybe = addressLine1 && postal
      ? {
        address: {
          city: city,
          country: country,
          line1: addressLine1,
          line2: addressLine2,
          postal_code: postal,
          state: state,
        },
      } : {};

    const billingDetails = {
      name,
      email: ensureCurrentUser(currentUser).attributes.email,
      ...addressMaybe,
    };

    return ({
      payment_method_data: {
        billing_details: billingDetails,
      },
    });
  };

  async handleComponentSubmit(params) {
    this.setIsSubmitting(true);
    const ensuredCurrentUser = ensureCurrentUser(this.props.currentUser);
    const stripeCustomer = ensuredCurrentUser.stripeCustomer;
    const { stripe, card, formValues } = params;

    return await this.props.onCreateSetupIntent()
      .then(async (setupIntent) => {
        const stripeParams = {
          stripe,
          card,
          setupIntentClientSecret: this.getClientSecret(setupIntent),
          paymentParams: this.getPaymentParams(this.props.currentUser, formValues),
        };

        const { setupIntent: { payment_method } } = await this.props.onHandleCardSetup(stripeParams);
        return ({ payment_method });
      })
      .then(async ({ payment_method }) => {
        // Note: stripe.handleCardSetup might return an error inside successful call (200),
        // but those are rejected in thunk functions.
        const savedCard = await this.props.onSavePaymentMethod(stripeCustomer, payment_method);
        return ({ savedCard, payment_method });
      })
      .then((res) => {
        // Update currentUser entity and its sub entities: stripeCustomer and defaultPaymentMethod.
        this.props.fetchStripeCustomer();
        this.setIsSubmitting(false);
        this.setCardState("default");
        this.setState({ ...this.state, callHandler: true, showCardFormDialog: false });
        return res;
      })
      .catch((e) => {
        this.setIsSubmitting(false);
        this.setState({ ...this.state, showCardFormDialog: false });
        throw e;
      });
  };

  handleSubmit(params) {
    this.setIsSubmitting(true);
    const ensuredCurrentUser = ensureCurrentUser(this.props.currentUser);
    const stripeCustomer = ensuredCurrentUser.stripeCustomer;
    const { stripe, card, formValues } = params;

    this.props.onCreateSetupIntent()
      .then((setupIntent) => {
        const stripeParams = {
          stripe,
          card,
          setupIntentClientSecret: this.getClientSecret(setupIntent),
          paymentParams: this.getPaymentParams(this.props.currentUser, formValues),
        };

        return this.props.onHandleCardSetup(stripeParams);
      })
      .then((result) => {
        const newPaymentMethod = result.setupIntent.payment_method;

        // Note: stripe.handleCardSetup might return an error inside successful call (200), but those are rejected in thunk functions.
        return this.props.onSavePaymentMethod(stripeCustomer, newPaymentMethod);
      })
      .then(() => {
        // Update currentUser entity and its sub entities: stripeCustomer and defaultPaymentMethod
        this.props.fetchStripeCustomer();
        this.setIsSubmitting(false);
        this.setCardState("default");
        this.setState({ ...this.state, showCardFormDialog: false });
      })
      .catch(() => {
        this.setIsSubmitting(false);
        this.setState({ ...this.state, showCardFormDialog: false });
      });
  };

  handleRemovePaymentMethod() {
    this.props.onDeletePaymentMethod().then(() => {
      this.props.fetchStripeCustomer();
    });
  };

  async submitBillingDetails(params) {
    const { companyVAT: companyVATnumber, ...rest } = params;
    const newBillingDetails = { ...rest, companyVATnumber };
    this.setState((prev) => ({ ...prev, firstLoad: true }));

    return await this.props.onBuyerUpdateCompany(newBillingDetails);
  }

  render() {
    const {
      currentUser,
      addPaymentMethodError,
      deletePaymentMethodError,
      createStripeCustomerError,
      handleCardSetupError,
      // deletePaymentMethodInProgress,
      // scrollingDisabled,
      // onManageDisableScrolling,
      intl,
      stripeCustomerFetched,
      currentCompany,
      currentCompanyUpdateInProgress,
    } = this.props;

    const hasDefaultPaymentMethod = currentUser
      && ensureStripeCustomer(currentUser.stripeCustomer).attributes.stripeCustomerId
      && ensurePaymentMethodCard(currentUser.stripeCustomer.defaultPaymentMethod).id;
    const modeComponent = !hasDefaultPaymentMethod && this.props.hideTitle;
    const card = hasDefaultPaymentMethod
      ? ensurePaymentMethodCard(currentUser.stripeCustomer.defaultPaymentMethod).attributes.card : null;

    if (this.state.callHandler && stripeCustomerFetched && this.props.runBaseHandler) {
      this.props.runBaseHandler();
    }

    const mainContainerCss = classNames(css.pageContainer, { [css.pageMode]: !this.props.hideTitle });
    const addCardButtonCss = classNames(css.changeCardButton,
      { [css.changeCardComponentModeButton]: this.props.hideTitle });

    const billingDetails = {
      companyLegalName: currentCompany?.attributes?.publicData?.companyLegalName,
      companyRegistrationNumber: currentCompany?.attributes?.publicData?.companyRegistrationNumber,
      companyVATnumber: currentCompany?.attributes?.publicData?.companyVATnumber,
      streetAndNumber: currentCompany?.attributes?.publicData?.streetAndNumber,
      city: currentCompany?.attributes?.publicData?.city,
      postcode: currentCompany?.attributes?.publicData?.postcode,
      country: currentCompany?.attributes?.publicData?.country,
    };

    if (!this.state.firstLoad && (currentCompanyUpdateInProgress || !currentCompany || !stripeCustomerFetched)) {
      return <LoaderComponent />;
    }

    return (
      <>
        {/*<div className={mainContainerCss}>
          {this.props.hideTitle ? null : (
            <h1 className={css.companyPageInfo}>
              <FormattedMessage id="BuyerPaymentDetailsPage.PaymentDetailsPageInfoLabel"/>
            </h1>
          )}
          <div className={css.addCardFormContainer}>
            {!stripeCustomerFetched ?
              null :
              <>
                {modeComponent ?
                  <PaymentMethodsForm
                    formId="PaymentMethodsForm"
                    onSubmit={this.handleComponentSubmit}
                    handleRemovePaymentMethod={this.handleRemovePaymentMethod}
                    hasDefaultPaymentMethod={hasDefaultPaymentMethod}
                    addPaymentMethodError={addPaymentMethodError}
                    deletePaymentMethodError={deletePaymentMethodError}
                    createStripeCustomerError={createStripeCustomerError}
                    handleCardSetupError={handleCardSetupError}
                    inProgress={this.state.isSubmitting}
                    componentMode={true}
                    onCancelHandler={() => this.setState({ ...this.state, showCardFormDialog: false })}
                  />
                  :
                  <div className={css.cardInfo}>
                    <h4>Card number</h4>
                    <div className={css.cardDetails}>
                      <div>
                        {card ? (
                          <IconCard brand={card.brand}/>
                        ) : (
                          <i className="bi bi-credit-card"/>
                        )}
                      </div>
                      <div className={css.cardNumber}>
                        {card ? (
                          <span>****&nbsp;****&nbsp;****&nbsp;{card.last4Digits}</span>
                        ) : (
                          <span>Card number</span>
                        )}
                      </div>
                      <div>
                        {card ? (
                          <>{card.expirationMonth}&nbsp;/&nbsp;{card.expirationYear.toString().substring(2)}</>
                        ) : (
                          <>MM&nbsp;/&nbsp;YY</>
                        )}
                      </div>
                    </div>
                    <div className={css.cardButtons}>
                      {!this.props.hideTitle ? (
                        <button
                          className={css.removeCardButton}
                          onClick={() => this.setState({ ...this.state, showDeleteDialog: true })}
                          disabled={!card}
                        >
                          Remove payment card
                        </button>
                      ) : null}
                      <button
                        className={addCardButtonCss}
                        onClick={() => this.setState({ ...this.state, showCardFormDialog: true })}
                      >
                        {!card ? "Add card" : "Use another card"}
                      </button>
                    </div>
                    {this.state.showDeleteDialog ? (
                      <div className={css.previewDialogContainer}>
                        <div className={css.previewSmallDialog}>
                          <h1 className={css.previewDialogTitle}>Are you sure you want to remove the payment
                            card?</h1>
                          <p className={css.previewDialogContent}>
                            The payment card •••• •••• •••• {card?.last4Digits} will
                            be removed from your account.
                          </p>
                          <div className={css.previewDialogButtons}>
                            <a
                              className={css.previewDialogCancelButton}
                              onClick={() => this.setState({ ...this.state, showDeleteDialog: false })}>
                              Go back
                            </a>
                            <a
                              className={css.previewDialogConfirmButton}
                              onClick={() => {
                                this.handleRemovePaymentMethod();
                                this.setState({ ...this.state, showDeleteDialog: false });
                              }}
                            >Remove card</a>
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {this.state.showCardFormDialog ? (
                      <div className={css.previewDialogContainer}>
                        <div className={css.previewDialog}>
                          <h1 className={css.previewDialogTitle}>Add payment card</h1>
                          <div className={css.cardIcons}/>
                          <button
                            className={css.closeButton}
                            onClick={() => this.setState({ ...this.state, showCardFormDialog: false })}
                          />
                          <div className={css.previewDialogContent}>
                            <PaymentMethodsForm
                              formId="PaymentMethodsForm"
                              onSubmit={this.handleSubmit}
                              handleRemovePaymentMethod={this.handleRemovePaymentMethod}
                              hasDefaultPaymentMethod={hasDefaultPaymentMethod}
                              addPaymentMethodError={addPaymentMethodError}
                              deletePaymentMethodError={deletePaymentMethodError}
                              createStripeCustomerError={createStripeCustomerError}
                              handleCardSetupError={handleCardSetupError}
                              inProgress={this.state.isSubmitting}
                              onCancelHandler={() => this.setState({ ...this.state, showCardFormDialog: false })}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                }
              </>
            }
          </div>
        </div>*/}
        {this.props.showBillingData && currentCompany ?
          <BuyerBillingDetailsForm
            intl={intl}
            billingDetails={billingDetails}
            onSubmit={this.submitBillingDetails}
            billingDetailsUpdateInProgress={currentCompanyUpdateInProgress}
          />
          : null
        }
      </>
    );
  }
}

BuyerPaymentDetailsPageComponent.defaultProps = {
  currentUserListing: null,
  currentUserListingFetched: false,
};

BuyerPaymentDetailsPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = (state) => {
  const { currentUser } = state.user;
  const { handleCardSetupError } = state.stripe;
  const { buyerCurrentCompany: currentCompany, buyerUpdateCompanyInProgress: currentCompanyUpdateInProgress } = state.buyerCompany;
  const { stripeCustomerFetched } = state.BuyerPaymentDetailsPage;
  const {
    deletePaymentMethodInProgress,
    addPaymentMethodError,
    deletePaymentMethodError,
    createStripeCustomerError,
  } = state.paymentMethods;

  return {
    currentUser,
    scrollingDisabled: isScrollingDisabled(state),
    deletePaymentMethodInProgress,
    addPaymentMethodError,
    deletePaymentMethodError,
    createStripeCustomerError,
    handleCardSetupError,
    stripeCustomerFetched,
    currentCompany,
    currentCompanyUpdateInProgress,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onManageDisableScrolling: (componentId, disableScrolling) => {
    dispatch(manageDisableScrolling(componentId, disableScrolling));
  },
  fetchStripeCustomer: () => dispatch(stripeCustomer()),
  onHandleCardSetup: (params) => dispatch(handleCardSetup(params)),
  onCreateSetupIntent: (params) => dispatch(createStripeSetupIntent(params)),
  onSavePaymentMethod: (stripeCustomer, newPaymentMethod) => {
    return dispatch(savePaymentMethod(stripeCustomer, newPaymentMethod));
  },
  onDeletePaymentMethod: (params) => dispatch(deletePaymentMethod(params)),
  onBuyerUpdateCompany: (data) => dispatch(buyerUpdateCompany(data))
});

const BuyerPaymentDetailsPage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
)(BuyerPaymentDetailsPageComponent);

BuyerPaymentDetailsPage.loadData = loadData;

export default BuyerPaymentDetailsPage;
