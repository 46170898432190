import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import { isPasswordRecoveryEmailNotFoundError } from '../../util/errors';
import { Form, PrimaryButton, FieldTextInput, NamedLink } from '../../components';

import css from './PasswordRecoveryForm.module.css';

class PasswordRecoveryFormComponent extends Component {
  constructor(props) {
    super(props);
    this.submittedValues = {};
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        render={fieldRenderProps => {
          const {
            rootClassName,
            className,
            formId,
            handleSubmit,
            pristine,
            initialValues,
            intl,
            inProgress,
            recoveryError,
            values,
            errors,
            submitFailed,
            touched,
          } = fieldRenderProps;

          // email
          const emailPlaceholder = intl.formatMessage({
            id: 'PasswordRecoveryForm.emailPlaceholder',
          });

          const requiredEmailText = intl.formatMessage({ id: 'LoginForm.emailRequired' });
          const requiredValidEmailText = intl.formatMessage({ id: 'LoginForm.emailValidRequired' });    
          const emailRequired = validators.required(requiredEmailText);
          const emailValid = validators.emailFormatValid(requiredValidEmailText);

          const classes = classNames(rootClassName || css.root, className);
          const submitInProgress = inProgress;
          const submittedOnce = Object.keys(this.submittedValues).length > 0;
          const submitDisabled = submitInProgress;
          const emailNotFoundText = intl.formatMessage({ id: 'PasswordRecoveryForm.emailNotFound' });
          const customErrorText = submittedOnce && isPasswordRecoveryEmailNotFoundError(recoveryError)
            ? emailNotFoundText
            : null;

          const loginLink = (
            <NamedLink name="LoginPage" className={css.modalHelperLink}>
              <FormattedMessage id="PasswordRecoveryForm.loginLinkText" />
            </NamedLink>
          );

          const hasError = (field) => {
            return (errors[field]) && (submitFailed || touched[field]);
          };
          return (
            <Form
              className={classes}
              onSubmit={e => {
                this.submittedValues = values;
                handleSubmit(e);
              }}
            >
              <div className={css.formFields}>
                <div className={css.formRow}>
                  <div className={css.errorTextBlock}>
                    {customErrorText}
                  </div>
                  <div className={css.formField}>
                    <label
                      htmlFor={formId ? `${formId}.email` : 'email'}>
                      E-mail
                      <span className={css.requiredMarker}>*</span>
                      {hasError('email') && (<span className={css.errorMessage}>{errors['email']}</span>)}

                    </label>
                    <div className={css.fieldInputContainer}>
                      <FieldTextInput
                        type="text"
                        id={formId ? `${formId}.email` : 'email'}
                        name="email"
                        className={css.email}
                        hasError={hasError('email') || (customErrorText && (submitFailed || touched['email']))}
                        validate={validators.composeValidators(emailRequired, emailValid)}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className={css.bottomWrapper}>
                <p className={css.bottomWrapperText}>
                  <span className={css.modalHelperText}>
                    <FormattedMessage
                      id="PasswordRecoveryForm.loginLinkInfo"
                      values={{ loginLink }}
                    />
                  </span>
                </p>

                <PrimaryButton
                  type="submit"
                  inProgress={submitInProgress}
                  disabled={submitDisabled}
                  className={css.submitButton}
                >
                  <FormattedMessage id="PasswordRecoveryForm.sendInstructions" />
                </PrimaryButton>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

PasswordRecoveryFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  formId: null,
  inProgress: false,
  recoveryError: null,
};

const { bool, string } = PropTypes;

PasswordRecoveryFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  formId: string,

  inProgress: bool,
  recoveryError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,
};

const PasswordRecoveryForm = compose(injectIntl)(PasswordRecoveryFormComponent);
PasswordRecoveryForm.displayName = 'PasswordRecoveryForm';

export default PasswordRecoveryForm;
