import { storableError } from "../../util/errors";
import { registerationSupplierRequest } from "../../util/api";
import { send } from "../../ducks/mail.duck";

// ================ Action types ================ //
export const SUPPLIER_REGISTRATION_REQUEST = "app/Supplier/request/REGISTRATION";
export const SUPPLIER_REGISTRATION_SUCCESS = "app/Supplier/request/REGISTRATION_SUCCESS";
export const SUPPLIER_REGISTRATION_ERROR = "app/Supplier/request/REGISTRATION_ERROR";

// ================ Reducer ================ //
const initialState = {
  supplierRegistrationRequestError: null,
  supplierRegistrationRequestInProgress: false,
  supplierRequestSent: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SUPPLIER_REGISTRATION_REQUEST:
      return {
        ...state,
        supplierRegistrationRequestInProgress: true,
        supplierRegistrationRequestError: null,
        supplierRequestSent: false,
      };
    case SUPPLIER_REGISTRATION_SUCCESS:
      return { ...state, supplierRegistrationRequestInProgress: false, supplierRequestSent: true };
    case SUPPLIER_REGISTRATION_ERROR:
      return { ...state, supplierRegistrationRequestInProgress: false, supplierRegistrationRequestError: payload };
    default:
      return state;
  }
}

// ================ Action creators ================ //
export const supplierRegistrationRequest = () => ({ type: SUPPLIER_REGISTRATION_REQUEST });
export const supplierRegistrationSuccess = () => ({ type: SUPPLIER_REGISTRATION_SUCCESS });
export const supplierRegistrationError = error => ({
  type: SUPPLIER_REGISTRATION_ERROR,
  payload: error,
  error: true,
});

// ================ Thunks ================ //
export const supplierRegistration = request => (dispatch) => {
  dispatch(supplierRegistrationRequest());

  return registerationSupplierRequest(request)
    .then(() => {
      dispatch(supplierRegistrationSuccess());
      dispatch(send({
        subject: "Vegshelf registration request received",
        template: "user-registration-sent",
        data: {
          name: `${request.firstName}`,
        },
        to: request.businessEMail,
      }));
    })
    .catch((e) => {
      dispatch(supplierRegistrationError(storableError(storableError(e))));
      // This is thrown so that form can be cleared
      // after a timeout on changePassword submit handler
      throw e;
    });
};
