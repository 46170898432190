import React, { Component } from 'react';
import { object } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import css from './ShortProductViewItem.module.css';
import NamedLink from '../NamedLink/NamedLink';
import classNames from 'classnames';
import { addOrRemoveFavourites } from '../../ducks/favourite.duck';
import { displayRegistrationConfirmDialog } from '../../ducks/Modal.duck';
import { connect } from 'react-redux';
import { parseListingState } from "../../ducks/supplier-company.duck";
import { slugify } from "../../util/urlTrimming";
import { localePart, storedLocale } from "../Wrapper/ClassWrapper";

const locale = storedLocale() || localePart();

export class ShortProductViewItemComponent extends Component {
  constructor(props) {
    super(props);
    this.onFavouriteButtonClick = this.onFavouriteButtonClick.bind(this);
  }

  onFavouriteButtonClick() {
    this.props.onAddOrRemoveFavourite(this.props.productItem);
  }

  render() {
    const {
      productItem,
      isFirst,
      showState,
      showEditButton,
      favouriteProductIds,
      currentUserType,
      isAuthenticated,
      onDisplayRegistrationConfirmDialog,
    } = this.props;

    if (productItem?.newLine) {
      return (<div className={css.newLine}>&nbsp;</div>);
    }

    const nodeCss = classNames(css.productItem, {
      [css.isFirstProduct]: isFirst,
      [css.newButton]: productItem.newButton
    });
    if (productItem.newButton) {
      return (<div key={productItem.id.uuid} className={nodeCss}>
        <NamedLink className={css.addProductButton} name="SupplierAddProductPage">
          <div className={css.addProductButtonIcon}>add</div>
          <FormattedMessage className={css.addProductButtonLabel} id="SupplierProfilePage.addProductButtonLabel" />
        </NamedLink></div>);
    }
    const style = {};
    const images = productItem?.attributes?.publicData?.productImages;
    if (images && images.length > 0) {
      style.backgroundImage = `url('${images[0].url}')`;
    }
    const price = parseFloat(productItem?.attributes?.publicData?.wholesalePrice);
    const displayPrice = (Math.round(price * 100) / 100).toFixed(2);
    const msrp = parseFloat(productItem?.attributes?.publicData?.suggestedPrice);
    const displayMsrp = (Math.round(msrp * 100) / 100).toFixed(2);
    const stateCss = classNames(css.productState, { [css.hideState]: !showState });
    const editButtonCss = classNames(css.editButtonCss, { [css.hideState]: !showEditButton });
    const detailsPage = showEditButton ? 'ProductSupplierDetailsPage' : 'ProductDetailsPage';
    const isFavourite = favouriteProductIds ? favouriteProductIds.indexOf(productItem?.id?.uuid) > -1 : false;
    const favouriteButtonCss = classNames(css.favouriteButtonCss, { [css.hideState]: currentUserType !== 'buyer' });
    const favouriteIconButtonCss = classNames(css.favouriteProductButtonIcon, {
      [css.favouriteProductDisabledButtonIcon]: !isFavourite,
      [css.favouriteProductEnabledButtonIcon]: isFavourite
    });
    let productState = parseListingState(productItem);
    const isOutOfStock = productState === "outOfStock";

    return (
      <div key={productItem?.id?.uuid} className={nodeCss}>
        <NamedLink className={css.noUnderline} name={detailsPage} params={{ product: slugify(productItem?.attributes?.publicData?.productName), id: productItem?.id?.uuid, locale: locale }}>
          {isOutOfStock && <div className={classNames(css.outOfStockMask)}>&nbsp;</div>}
          {isOutOfStock && <div className={css.backSoon}><FormattedMessage id="SearchProductsPage.backSoon" /></div>}
          <div className={css.productImage} style={style}>&nbsp;</div>
        </NamedLink>
        <div className={css.productItemInfo}>
          <p className={css.productName}>
            <NamedLink name={detailsPage} params={{ product: slugify(productItem?.attributes?.publicData?.productName), id: productItem?.id?.uuid, locale: locale }}>
              {productItem?.attributes?.title}
            </NamedLink>
          </p>
          <p className={css.productPrice}>
            {isAuthenticated ?
              <>
                €&nbsp;{displayPrice} &nbsp;
              </>
              :
              <a onClick={() => onDisplayRegistrationConfirmDialog()} className={css.registrationButton}>
                <FormattedMessage id="SearchProductsPage.seeWholesalePrice" />
              </a>
            }
            <span className={css.msrpLabel}>
              •&nbsp;&nbsp;<FormattedMessage id="SearchProductsPage.MSRPLabel" /> €&nbsp;{displayMsrp}
            </span>
          </p>
          <p className={css.brandName}>
            <NamedLink name="SupplierDetailsPage" params={{ brand: slugify(productItem?.attributes?.publicData?.brandName), id: productItem?.attributes?.publicData?.brandId, locale: locale }}>
              {productItem?.attributes?.publicData?.brandName}
            </NamedLink>
          </p>
        </div>
        <div className={stateCss}>
          {productItem?.attributes?.state}
        </div>
        <NamedLink className={editButtonCss} name="SupplierEditProductPage" params={{ id: productItem.id.uuid, locale: locale }}>
          <div className={css.editProductButtonIcon}>add</div>
        </NamedLink>
        <a onClick={this.onFavouriteButtonClick} className={favouriteButtonCss}>
          <div className={favouriteIconButtonCss}>addToFavourite</div>
        </a>
      </div>
    );
  }
}

ShortProductViewItemComponent.propTypes = {
  productItem: object.isRequired
};

const mapStateToProps = (state) => {
  return {
    favouriteProductIds: state.Favourites.productIds,
    currentUserType: state.user.currentUserType,
    isAuthenticated: state.Auth.isAuthenticated
  };
};

const mapDispatchToProps = (dispatch) => ({
  onDisplayRegistrationConfirmDialog: () => dispatch(displayRegistrationConfirmDialog(true, true)),
  onAddOrRemoveFavourite: (product) => dispatch(addOrRemoveFavourites({ product })),
});

const ShortProductViewItem = connect(mapStateToProps, mapDispatchToProps)(ShortProductViewItemComponent);

export default ShortProductViewItem;