import { localePart, storedLocale } from "../components/Wrapper/ClassWrapper";

//const language = sessionStorage.getItem('locale') || localePart();
const language = storedLocale() || localePart();

/*const primaryCategories = [
    {code: 1, name:"Bread & Baked goods"},
    {code: 2, name:"Dairy & Cheese alternatives"},
    {code: 3, name:"Meat & Seafood alternatives"},
    {code: 4, name:"Pantry & Condiments"},
    {code: 5, name:"Sweets & Snacks"},
    {code: 6, name:"Beverages"},
    {code: 0, name:"Other"}
];*/

const primaryCategories = {
  en: [
    { code: 1, name: "Snacks & Sweets" },
    { code: 2, name: "Pantry & Condiments" },
    { code: 3, name: "Beverages" },
    { code: 4, name: "Plant-based Cheese & Dairy" },
    { code: 0, name: "Other" }
  ],
  de: [
    { code: 1, name: "Snacks & Süßigkeiten" },
    { code: 2, name: "Speisekammer" },
    { code: 3, name: "Getränke" },
    { code: 4, name: "Milchprodukt-alternativen" },
    { code: 0, name: "Andere" }
  ]
};

const buyerPrimaryCategories = [
  { code: 1, name: "Physical store" },
  { code: 2, name: "Online shop" },
  { code: 3, name: "Pop-up store" },
  { code: 0, name: "Other" }
];

const getPrimaryCategories = lang => {
  // Add the lnew locale here so that the correct translations will be returned.
  // If locale is unknown or the translation is missing, this will default to english coutnry name.
  const categories = primaryCategories[language] || primaryCategories.en;
  const codes = categories.map(c => {
    //const codes = primaryCategories.map(c => {

    return { code: c.code, name: c.name };
  });
  return codes;
};

export default getPrimaryCategories;

export const getBuyerPrimaryCategories = lang => {
  // Add the lnew locale here so that the correct translations will be returned.
  // If locale is unknown or the translation is missing, this will default to english coutnry name.
  const codes = buyerPrimaryCategories.map(c => {

    return { code: c.code, name: c.name };
  });
  return codes;
};