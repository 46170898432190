import { bool } from "prop-types";
import React, { Component } from "react";
import { ProductViewItem } from "../../components";
import EmptyStateComponent, { EmptyStateOptions } from "../ProductEmptyState/ProductEmptyState";

import css from "./ProductContainer.module.css";

export class ProductContainerComponent extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      products,
      loading,
      rowItemCount,
      showState,
      showEditButton,
      onAddClick,
      align,
      emptyStateOptions,
    } = this.props;

    const countItems = rowItemCount || 3;
    const displayProducts = [];
    let firstRowItem = true;
    for (let i = 0; i < products.length; i++) {
      displayProducts.push(products[i]);

      // TODO: Double check firstRowItem property.
      products[i].firstRowItem = firstRowItem;

      firstRowItem = (i + 1) % countItems === 0;
    }

    const isDisplayEmptyState = !displayProducts.length && !loading;

    return (
      <div
        className={css.productsContainer}
        style={{ justifyContent: align || "left" }}
      >
        {isDisplayEmptyState ? <EmptyStateComponent options={emptyStateOptions} /> : null}
        {displayProducts.map((p) => (
          <ProductViewItem
            key={p.id.uuid}
            productItem={p}
            isFirst={p.firstRowItem}
            showState={showState}
            showEditButton={showEditButton}
            onAddClick={() => onAddClick()}
          />
        ))}
      </div>
    );
  }
}

ProductContainerComponent.defaultProps = {};

ProductContainerComponent.propTypes = {
  emptyStateOptions: EmptyStateOptions,
  loading: bool,
};

export default ProductContainerComponent;
