import React, { Component } from 'react';
import { bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { propTypes } from '../../util/types';
import config from '../../config';
import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  NamedLink
} from '../../components';
import { notificationSettingsLoad, notificationSettingsSave, notificationOptionChange } from '../../ducks/notification.duck';
import tickmark from '../../assets/check2-666561.svg';
import css from './SupplierProfileNotificationsPage.module.css';
/*

    'new-message': true,
    'new-order-requests': true,
    'updates-on-my-orders': true,
    'payment-and-invoices': true
*/
export class SupplierProfileNotificationsPageComponent extends Component {
  constructor(props) {
    super(props);
    this.onClickSave = this.onClickSave.bind(this);
    this.onChangeOption = this.onChangeOption.bind(this);
  }
  onChangeOption(event) {
    this.props.onChangeOption(event.target.name, event.target.checked);
  }
  onClickSave() {
    this.props.onSave(this.props.notificationSettings);
  }
  render() {
    const {
      history,
      intl,
      location,
      notificationSettingsError,
      notificationSettingsInProgress,
      notificationSettings,
    } = this.props;
    const settings = notificationSettings || {};
    return (
      <div className={css.notificationsContainer}>
        <h1 className={css.notificationsTitle}>
          <FormattedMessage id="SupplierNotificationsPage.notificationsTitle" />
        </h1>
        {notificationSettingsInProgress ? (<h1 className={css.notificationsSubtitle}><FormattedMessage id="SupplierNotificationsPage.loading" /></h1>) : (
          <div className={css.pageContent}>
            <table className={css.notificationTable}>
                <thead>
                  <th></th>
                  <th><FormattedMessage id="SupplierNotificationsPage.emailNotifications" /></th>
                  <th><FormattedMessage id="SupplierNotificationsPage.inAppNotifications" /></th>
                </thead>
                <br/>
              <tbody>
                <tr>
                  <td>
                    <label htmlFor='new-message'><FormattedMessage id="SupplierNotificationsPage.newMessages" /></label>
                  </td>
                  <td>
                    {/*<input 
                     id='new-message' 
                     name='new-message-mail' 
                     type="checkbox" value='1' 
                     defaultChecked={settings['new-message-mail']} 
                     onChange={this.onChangeOption} />*/}
                    <div className={css.checkboxBorder}>
                      <img className={css.tickMark} src={tickmark} />
                    </div>
                  </td>
                  <td>
                    {/*<input
                      id='new-message'
                      name='new-message'
                      type="checkbox"
                      value='1'
                      defaultChecked={settings['new-message']}
                    onChange={this.onChangeOption} />*/}
                    <div className={css.checkboxBorder}>
                      <img className={css.tickMark} src={tickmark} />
                    </div>
                  </td>
                </tr>

                <tr>
                  <td>
                    <label htmlFor='new-order-requests'><FormattedMessage id="SupplierNotificationsPage.orderRequests" /></label>
                  </td>
                  <td>
                    {/*<input 
                    id='new-order-requests' 
                    name='new-order-requests-mail' 
                    type="checkbox" value='1' 
                    defaultChecked={settings['new-order-requests-mail']} 
                    onChange={this.onChangeOption} />*/}
                    <div className={css.checkboxBorder}>
                      <img className={css.tickMark} src={tickmark} />
                    </div>
                  </td>
                  <td>
                    {/*<input 
                    id='new-order-requests' 
                    name='new-order-requests' 
                    type="checkbox" value='1' 
                    defaultChecked={settings['new-order-requests']} 
                    onChange={this.onChangeOption} />*/}
                    <div className={css.checkboxBorder}>
                      <img className={css.tickMark} src={tickmark} />
                    </div>
                  </td>
                </tr>

                <tr>
                  <td>
                    <label htmlFor='updates-on-my-orders'><FormattedMessage id="SupplierNotificationsPage.updatesOnMyOrders" /></label>
                  </td>
                  <td>
                    {/*<input 
                    id='updates-on-my-orders' 
                    name='updates-on-my-orders-mail' 
                    type="checkbox" value='1' 
                    defaultChecked={settings['updates-on-my-orders-mail']} 
                    onChange={this.onChangeOption} />*/}
                    <div className={css.checkboxBorder}>
                      <img className={css.tickMark} src={tickmark} />
                    </div>
                  </td>
                  <td>
                    {/*<input 
                    id='updates-on-my-orders' 
                    name='updates-on-my-orders' 
                    type="checkbox" value='1' 
                    defaultChecked={settings['updates-on-my-orders']} 
                    onChange={this.onChangeOption} />*/}
                    <div className={css.checkboxBorder}>
                      <img className={css.tickMark} src={tickmark} />
                    </div>
                  </td>
                </tr>

                <tr>
                  <td>
                    <label htmlFor='payment-and-invoices'><FormattedMessage id="SupplierNotificationsPage.paymentsAndInvoices" /></label>
                  </td>
                  <td>
                    {/*<input 
                  id='payment-and-invoices' 
                  name='payment-and-invoices-mail' 
                  type="checkbox" value='1' 
                  defaultChecked={settings['payment-and-invoices-mail']} 
                    onChange={this.onChangeOption} />*/}
                    <div className={css.checkboxBorder}>
                      <img className={css.tickMark} src={tickmark} />
                    </div>
                  </td>
                  <td>
                    {/*<input 
                    id='payment-and-invoices' 
                    name='payment-and-invoices' 
                    type="checkbox" value='1' 
                    defaultChecked={settings['payment-and-invoices']} 
                    onChange={this.onChangeOption} />*/}
                    <div className={css.checkboxBorder}>
                      <img className={css.tickMark} src={tickmark} />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className={css.pageButtons}>
              <button className={css.buttonSave} /*onClick={this.onClickSave}*/><FormattedMessage id="SupplierNotificationsPage.save" /></button>
            </div>
          </div>)}
      </div>
    );
  }
};

SupplierProfileNotificationsPageComponent.defaultProps = {
  currentUserListing: null,
  currentUserListingFetched: false,
};

SupplierProfileNotificationsPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};
const mapStateToProps = state => {
  return {
    scrollingDisabled: isScrollingDisabled(state),
    notificationSettingsError: state.notifications.notificationSettingsError,
    notificationSettingsInProgress: state.notifications.notificationSettingsInProgress,
    notificationSettings: state.notifications.notificationSettings
  };
};

const mapDispatchToProps = dispatch => ({
  onSave: data => dispatch(notificationSettingsSave(data)),
  onChangeOption: (option, value) => dispatch(notificationOptionChange(option, value))
});

const SupplierProfileNotificationsPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(SupplierProfileNotificationsPageComponent);

SupplierProfileNotificationsPage.loadData = () => (dispatch, getState) => {
  return Promise.all([
    dispatch(notificationSettingsLoad())]);
};

export default SupplierProfileNotificationsPage;