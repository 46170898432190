import classNames from "classnames";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { compose } from "redux";
import { Form as FinalForm } from "react-final-form";
import { FieldTextInput, Form, PrimaryButton } from "../../components";
import { injectIntl, intlShape, FormattedMessage } from "../../util/reactIntl";
import { LabelWithErrorMsg } from "../ContactDetailsForm/ContactDetailsForm";
import css from "./BuyerDeliveryAddressForm.module.css";

export class BuyerDeliveryAddressFormComponent extends Component {
  constructor(props) {
    super(props);
    this.logoFileSelector = React.createRef();
    this.onLogoChange = this.onLogoChange.bind(this);
    this.preSubmit = this.preSubmit.bind(this);
    this.state = { promisePreviewLogo: null };
  }

  readImage(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => resolve(e.target.result);
      reader.onerror = (e) => {
        console.error("Error (", e, `) happened while reading ${file.name}: ${e.target.result}`);
        reject(new Error(`Error reading ${file.name}: ${e.target.result}`));
      };
      reader.readAsDataURL(file);
    });
  }

  preSubmit(form, mode) {
    form.change("handlerType", mode);
  }

  onLogoChange(event) {
    if (event?.stopPropagation)
      event.stopPropagation();
    if (event?.preventDefault)
      event.preventDefault();
    const file = event?.target?.files[0];
    if (!file) {
      return;
    }
    this.setState({ promisePreviewLogo: this.readImage(file) });
    this.props.onSelectLogo(file);
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        render={({ rootClassName, className, handleSubmit, inProgress, currentCompany }) => {
          const classes = classNames(rootClassName, className);
          const companyName = currentCompany?.attributes?.publicData?.deliveryAddress?.companyName;
          const city = currentCompany?.attributes?.publicData?.deliveryAddress?.city;
          const streetAndNumber = currentCompany?.attributes?.publicData?.deliveryAddress?.streetAndNumber;
          const region = currentCompany?.attributes?.publicData?.deliveryAddress?.region;
          const additionalInfo = currentCompany?.attributes?.publicData?.deliveryAddress?.additionalInfo;
          const postCode = currentCompany?.attributes?.publicData?.deliveryAddress?.postCode;
          const country = currentCompany?.attributes?.publicData?.country;

          // ================ general ================ //
          const pageName = "ShoppingCartPage";
          const getInputFormattedMessage = (name) => this.props.intl.formatMessage({ id: `${pageName}.${name}` });

          // ================ companyName ================ //
          const companyNameInputId = "companyName";
          const companyNamePlaceholder = getInputFormattedMessage("companyNamePlaceholder");
          const companyNameLabel = getInputFormattedMessage("companyNameLabel");

          // ================ streetAndNumber ================ //
          const streetAndNumberNameInputId = "streetAndNumber";
          const streetAndNumberNamePlaceholder = getInputFormattedMessage("streetAndNumberPlaceholder");
          const streetAndNumberNameLabel = getInputFormattedMessage("streetAndNumberLabel");

          // ================ additionalInfo ================ //
          const additionalInfoNameInputId = "additionalInfo";
          const additionalInfoNamePlaceholder = getInputFormattedMessage("additionalInfoPlaceholder");
          const additionalInfoNameLabel = getInputFormattedMessage("additionalInfoLabel");

          // ================ city ================ //
          const cityNameInputId = "city";
          const cityNamePlaceholder = getInputFormattedMessage("cityPlaceholder");
          const cityNameLabel = getInputFormattedMessage("cityLabel");

          // ================ postCode ================ //
          const postCodeNameInputId = "postCode";
          const postCodeNamePlaceholder = getInputFormattedMessage("postCodePlaceholder");
          const postCodeNameLabel = getInputFormattedMessage("postCodeLabel");

          // ================ region ================ //
          const regionNameInputId = "region";
          const regionNamePlaceholder = getInputFormattedMessage("regionPlaceholder");
          const regionNameLabel = getInputFormattedMessage("regionLabel");

          // ================ country ================ //
          const countryNameInputId = "country";
          const countryNameLabel = getInputFormattedMessage("countryLabel");

          return (
            <Form className={classes} onSubmit={handleSubmit}>
              <LabelWithErrorMsg htmlFor={companyNameInputId} labelText={companyNameLabel}>
                <FieldTextInput
                  type="text"
                  name={companyNameInputId}
                  id={companyNameInputId}
                  placeholder={companyNamePlaceholder}
                  defaultValue={companyName}
                />
              </LabelWithErrorMsg>
              <div className={css.formTwoColumnsPart}>
                <div className={css.formFirstColumn}>
                  <div className={css.formField}>
                    <LabelWithErrorMsg htmlFor={streetAndNumberNameInputId} labelText={streetAndNumberNameLabel}>
                      <FieldTextInput
                        type="text"
                        name={streetAndNumberNameInputId}
                        id={streetAndNumberNameInputId}
                        placeholder={streetAndNumberNamePlaceholder}
                        defaultValue={streetAndNumber}
                      />
                    </LabelWithErrorMsg>
                  </div>
                  <div className={css.formField}>
                    <LabelWithErrorMsg htmlFor={cityNameInputId} labelText={cityNameLabel}>
                      <FieldTextInput
                        type="text"
                        name={cityNameInputId}
                        id={cityNameInputId}
                        placeholder={cityNamePlaceholder}
                        defaultValue={city}
                      />
                    </LabelWithErrorMsg>
                  </div>
                  <div className={css.formField}>
                    <LabelWithErrorMsg htmlFor={regionNameInputId} labelText={regionNameLabel}>
                      <FieldTextInput
                        type="text"
                        name={regionNameInputId}
                        id={regionNameInputId}
                        placeholder={regionNamePlaceholder}
                        defaultValue={region}
                      />
                    </LabelWithErrorMsg>
                  </div>
                </div>
                <div className={css.formSecondColumn}>
                  <div className={css.formField}>
                    <LabelWithErrorMsg htmlFor={additionalInfoNameInputId} labelText={additionalInfoNameLabel}>
                      <FieldTextInput
                        type="text"
                        name={additionalInfoNameInputId}
                        id={additionalInfoNameInputId}
                        placeholder={additionalInfoNamePlaceholder}
                        defaultValue={additionalInfo}
                      />
                    </LabelWithErrorMsg>
                  </div>
                  <div className={css.formField}>
                    <LabelWithErrorMsg htmlFor={postCodeNameInputId} labelText={postCodeNameLabel}>
                      <FieldTextInput
                        type="text"
                        name={postCodeNameInputId}
                        id={postCodeNameInputId}
                        placeholder={postCodeNamePlaceholder}
                        defaultValue={postCode}
                      />
                    </LabelWithErrorMsg>
                  </div>
                  <div className={css.formField}>
                    <LabelWithErrorMsg htmlFor={countryNameInputId} labelText={countryNameLabel}>
                      <FieldTextInput
                        readOnly={true}
                        type="text"
                        name={countryNameInputId}
                        id={countryNameInputId}
                        defaultValue={country}
                      />
                    </LabelWithErrorMsg>
                  </div>
                </div>
              </div>
              <div className={css.formButtonsPart}>
                <PrimaryButton
                  type="submit"
                  className={css.submitButton}
                  inProgress={inProgress}
                >
                  <FormattedMessage id="BuyerAddressPage.saveDetails" />
                </PrimaryButton>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

BuyerDeliveryAddressFormComponent.defaultProps = { inProgress: false };

const { bool } = PropTypes;

BuyerDeliveryAddressFormComponent.propTypes = {
  inProgress: bool,
  // from injectIntl
  intl: intlShape.isRequired,
};

const BuyerDeliveryAddressForm = compose(injectIntl)(BuyerDeliveryAddressFormComponent);

BuyerDeliveryAddressForm.displayName = "BuyerDeliveryAddressForm";

export default BuyerDeliveryAddressForm;