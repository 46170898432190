import React, { useContext, useEffect, useState } from 'react';
import { bool, func, number, object, string } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { Link } from "react-router-dom";
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import { getProductCategories } from '../../translations/productDictionaries';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { displayRegistrationConfirmDialog } from '../../ducks/Modal.duck';
import { shoppingCartClearAddedProduct } from '../../ducks/shopping-cart.duck';
import {
  AvatarLarge,
  InlineTextButton,
  NamedLink,
  Logo,
  NotificationBadge,
  OwnListingLink,
  Menu,
  MenuContent,
  MenuItem,
  MenuLabel,
  RegistrationConfirmDialog
} from '../../components';
import { TopbarSearchForm } from '../../forms';
import { Context, storedLocale } from '../Wrapper/ClassWrapper';

import css from './TopbarMobileMenu.module.css';

function formatPrice(price) {
  const msrp = parseFloat(price);
  return (Math.round(msrp * 100) / 100).toFixed(2);
}

const TopbarMobileMenuComponent = props => {
  const {
    isAuthenticated,
    currentPage,
    currentUserHasListings,
    currentUserListing,
    currentUserListingFetched,
    currentUser,
    notificationCount,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
    productCategoryCode,
    onDisplayRegistrationConfirmDialog,
    rootClassName,
    className,
    intl,
    currentUserType,
    addedProduct,
    onShoppingCartClearAddedProduct,
    shoppingCartProductCount,
    shoppingCart,
    onlyLogo
  } = props;

  const user = ensureCurrentUser(currentUser);
  const isAuthenticatedOrJustHydrated = isAuthenticated;

  const isBuyerUserType = currentUserType === "buyer";
  const isSupplierUserType = currentUserType === "supplier";

  // 16.07.23 Vegshelf freeze: commented out Topbar links as part of Vegshelf freeze

  /*const adminCategorySectionLink = currentUser?.attributes?.profile?.privateData?.enableCategorySectionAdmin === true ?
    (
      <NamedLink className={css.adminSectionsLink} name="CategorySectionPage">
        <FormattedMessage id="TopbarDesktop.adminCategorySection" />
      </NamedLink>
    ) : null
    ;

  const adminRegistrationsLink = currentUser?.attributes?.profile?.privateData?.enableRegistrationRequestsModerator === true ?
    (
      <NamedLink className={css.adminSectionsLink} name="SupplierRegistrationModeratorPage">
        <FormattedMessage id="TopbarDesktop.adminRegistrations" />
      </NamedLink>
    ) : null
    ;*/

  /* 21.07.23 Vegshelf freeze: added Login link so login page is still accessible */
  const loginLink = 
    <NamedLink name="LoginPage" className={css.loginLink}>
      <span>
        <FormattedMessage id="TopbarDesktop.login" />
      </span>
    </NamedLink>
  ;

  /* 16.07.23 Vegshelf freeze: removed links as part of Vegshelf freeze, adedd registrationConfirmDialog instead */
  const applyToSellLink = (
    <NamedLink name="ApplyToSellPage" className={css.signupLink}>
      <span>
        <FormattedMessage id="TopbarDesktop.applyToSell" />
      </span>
    </NamedLink>
  );

  /* 16.07.23 Vegshelf freeze: removed links as part of Vegshelf freeze, adedd registrationConfirmDialog instead */
  const registerToShopLink = (
    <a className={css.registerToShopLink} onClick={() => onDisplayRegistrationConfirmDialog()}>
      <span>
        <FormattedMessage id="TopbarDesktop.registerToShop" />
      </span>
    </a>
  );

  // 16.07.23 Vegshelf freeze: commented out Topbar links as part of Vegshelf freeze
  /*const ordersLink =
    <NamedLink name={isBuyerUserType ? "BuyerApprovedOrdersPage" : "SupplierApprovedOrdersPage"} className={css.profileSettingsLink}>
      <span>
        <FormattedMessage id="TopbarMobileMenu.ordersLink" />
      </span>
    </NamedLink>
    ;

  const messagesLink =
    <NamedLink name={isBuyerUserType ? "BuyerMessagesPage" : "SupplierMessagesPage"} className={css.profileSettingsLink}>
      <span>
        <FormattedMessage id="TopbarMobileMenu.messagesLink" />
      </span>
    </NamedLink>
    ;

  const favouritesLink = isBuyerUserType ? (
    <NamedLink name="BuyerMyFavouritesPage" className={css.profileSettingsLink}>
      <span>
        <FormattedMessage id="TopbarMobileMenu.favouritesLink" />
      </span>
    </NamedLink>
  ) : null
    ;

  const companyPageLink =
    <NamedLink name={isBuyerUserType ? "BuyerProfilePage" : "SupplierProfilePage"} className={css.profileSettingsLink}>
      <span>
        <FormattedMessage id={isBuyerUserType ? "TopbarDesktop.buyerCompanyPage" : "TopbarDesktop.supplierLinkCompanyPage"} />
      </span>
    </NamedLink>
    ;

  const invoicingPageLink =
    <NamedLink name={isBuyerUserType ? "BuyerInvoicingPage" : "SupplierOrderInvoicesPage"} className={css.profileSettingsLink}>
      <span>
        <FormattedMessage id="TopbarDesktop.buyerInvoicing" />
      </span>
    </NamedLink>
    ;

  const productListingsLink = isSupplierUserType ? (
    <NamedLink name="SupplierProductsPage" className={css.profileSettingsLink}>
      <span>
        <FormattedMessage id="TopbarDesktop.supplierLinkProductListings" />
      </span>
    </NamedLink>
  ) : null
    ;

  const settingsPageLink = isBuyerUserType ? (
    <NamedLink className={css.profileSettingsLink} name="BuyerSettingsPage">
      <FormattedMessage id="TopbarDesktop.buyerSettings" />
    </NamedLink>
  ) : (
    <NamedLink name="SupplierSettingsPage" className={css.profileSettingsLink}>
      <span>
        <FormattedMessage id="TopbarDesktop.supplierLinkSettings" />
      </span>
    </NamedLink>
  );

  const helpLink =
    <NamedLink name="ContactUsPage" className={css.profileSettingsLink}>
      <span>
        <FormattedMessage id="TopbarDesktop.supplierLinkHelp" />
      </span>
    </NamedLink>
  ;

  const logoutLink =
    <InlineTextButton className={css.profileSettingsLink} onClick={onLogout}>
      <FormattedMessage id="TopbarDesktop.logout" />
    </InlineTextButton>
  ;*/

  const isBlogPage = location.pathname.startsWith("/en/blog") || location.pathname.startsWith("/de/blog");

  // 16.07.23 Vegshelf freeze: commented out Topbar links as part of Vegshelf freeze
  /*const shoppingCartLink = isBuyerUserType ? (
    <div className={css.shoppingButton}>
      <Menu isOpen={!!addedProduct} onToggleActive={(s) => {
        !s && onShoppingCartClearAddedProduct()
      }}>
        <MenuLabel>
          <NamedLink
            className={css.shoppingCartMainMenuLink}
            name="ShoppingCartPage">
            <div className={css.shoppingCartIcon}>&nbsp;
              {shoppingCartProductCount ? (
                <div className={css.shoppingCartNotification}>{shoppingCartProductCount}</div>
              ) : null}
            </div>
          </NamedLink>
        </MenuLabel>
        <MenuContent className={css.addedProductNotification}>
        </MenuContent>
      </Menu>
    </div>
  ) : null
    ;*/

  const [languageChanged, setLanguageChanged] = useState(false);
  const context = useContext(Context);
  const locale = storedLocale() || context.locale;

  useEffect(() => {
    sessionStorage.setItem('locale', locale);
    //localStorage.setItem('locale', locale);
    const newPathname = window.location.pathname.replace(/^\/[a-z]{2}/, `/${locale}`);
    window.history.replaceState({}, null, newPathname);
    if (!languageChanged) {
      setLanguageChanged(true);
    } else {
      window.location.reload();
    }
  }, [locale]);

  // 16.07.23 Vegshelf freeze: removed links as part of Vegshelf freeze
  const shortLinks = getProductCategories().slice(0, 4).map((c) => ({
    name: c.name,
    params: { productCategoryCode: c.categoryCode, locale: locale },
  }));

  const shortKeyListItem = shortLinkItem => {
    const className = shortLinkItem.params.productCategoryCode !== productCategoryCode
      ? css.transitionItem : css.selectedTransitionItem;

    return (
      <li
        key={shortLinkItem.params.productCategoryCode}
        className={className}>
        { /* 16.07.23 Vegshelf freeze: removed links as part of Vegshelf freeze, adedd registrationConfirmDialog instead */}
        <a className={css.shortLink} params={shortLinkItem.params} onClick={() => onDisplayRegistrationConfirmDialog(true)}>
          {shortLinkItem.name}
        </a>
      </li>
    );
  };

  const shortLinkNodes = shortLinks.map(shortKeyListItem);

  return (
    <div className={css.root}>
      { /* 16.07.23 Vegshelf freeze: removed links as part of Vegshelf freeze, adedd registrationConfirmDialog instead */}
      <>
        <nav className={css.actionLinkContainer}>
          <div>&nbsp;</div>
          {loginLink}
          {applyToSellLink}
          {registerToShopLink}
        </nav>
        <ul className={css.shortLinks}>
          <li key="new" className={"new" !== productCategoryCode ? css.transitionItem : css.selectedTransitionItem}>
            <a className={css.shortLink} name="NewSearchProductsPage" onClick={onDisplayRegistrationConfirmDialog}>
              <FormattedMessage id="TopbarDesktop.newBrandsCategory" />
            </a>
          </li>
          {shortLinkNodes}
          {onlyLogo || isBlogPage ? null :
            (<Context.Consumer>
              {({ selectLanguage }) => (
                <div className={css.languageButtonContainer}>
                  <button className={css.buttonEng} value='en' onClick={selectLanguage}>EN</button>
                  <button className={css.buttonDe} value='de' onClick={selectLanguage}>DE</button>
                </div>
              )}
            </Context.Consumer>)}
        </ul>
      </>
      <RegistrationConfirmDialog />
    </div>
  );
};

TopbarMobileMenuComponent.defaultProps = {
  currentUser: null,
  notificationCount: 0,
  currentPage: null,
  currentUserListing: null,
  currentUserListingFetched: false,
  initialSearchFormValues: {},
  rootClassName: null,
  className: null,
  currentUserType: null
};

TopbarMobileMenuComponent.propTypes = {
  isAuthenticated: bool.isRequired,
  currentUserHasListings: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
  currentUser: propTypes.currentUser,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
  // 16.07.23 Vegshelf freeze: commented out as not required (error shown in console)
  //onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  rootClassName: string,
  className: string,
  // 16.07.23 Vegshelf freeze: commented out as not required (error shown in console)
  //intl: intlShape.isRequired,
  currentUserType: string
};

const mapStateToProps = (state) => {
  const { currentProductCategory } = state.user;
  const { shoppingCartProductCount, shoppingCart, addedProduct } = state.ShoppingCartStore;

  return {
    currentProductCategory,
    shoppingCartProductCount,
    shoppingCart,
    addedProduct,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onDisplayRegistrationConfirmDialog: () => dispatch(displayRegistrationConfirmDialog(true, true)),
  onShoppingCartClearAddedProduct: () => dispatch(shoppingCartClearAddedProduct()),
});

const TopbarMobileMenu = compose(
  connect(mapStateToProps, mapDispatchToProps),
)(TopbarMobileMenuComponent);

export default TopbarMobileMenu;
