import classNames from "classnames";
import { bool, object } from "prop-types";
import React, { Component } from "react";
import { Form as FinalForm } from "react-final-form";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import facebookImage from "../../assets/VegshelfFacebook-1200x630.png";
import twitterImage from "../../assets/VegshelfTwitter-600x314.png";
import {
  FieldTextInput,
  Footer,
  Form,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  NamedLink,
  Page,
} from "../../components";
import EmptyStateComponent from "../../components/ProductEmptyState/ProductEmptyState";
import { Select } from "../../components/Select/Select";
import config from "../../config";
import { BuyerPaymentDetailsPage, TopbarContainer } from "../../containers";
import {
  clearFetchedOrders,
  defineTheLongestDeliveryPeriod,
  goToStep,
  MINIMAL_ORDER_FOR_FREE_SHIPPING,
  resetPaymentData,
  shoppingCartDeleteProduct,
  shoppingCartSave,
  shoppingCartSaveSuccess,
  shoppingCartUpdateProduct,
} from "../../ducks/shopping-cart.duck";
import { isScrollingDisabled } from "../../ducks/UI.duck";
import { LabelWithErrorMsg } from "../../forms/ContactDetailsForm/ContactDetailsForm";
import { injectIntl, intlShape } from "../../util/reactIntl";
import { propTypes } from "../../util/types";
import * as validators from "../../util/validators";
import css from "./ShoppingCartPage.module.css";
import { FormattedMessage } from "react-intl";
import { deletePaymentMethod, savePaymentMethod } from "../../ducks/paymentMethods.duck";
import { ensureCurrentUser, ensurePaymentMethodCard, ensureStripeCustomer } from "../../util/data";
import { confirmCardPayment, handleCardSetup, handleCardSetupClearError } from "../../ducks/stripe.duck";
import { createStripeSetupIntent, stripeCustomer } from "../BuyerPaymentDetailsPage/BuyerPaymentDetailsPage.duck";
import PaymentMethodsForm from "../../forms/PaymentMethodsFormShoppingCart/PaymentMethodsForm";
import { chargeOrderTransition } from "../../ducks/transition.duck";
import { slugify } from "../../util/urlTrimming";
import { localePart, storedLocale } from "../../components/Wrapper/ClassWrapper";
import { pathByRouteName } from "../../util/routes";
import routeConfiguration from "../../routeConfiguration";

const locale = storedLocale() || localePart();

function formatPrice(price) {
  const msrp = parseFloat(price);
  return (Math.round(msrp * 100) / 100).toFixed(2);
}

export class ShoppingCartPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { stepIndex: 0, termsError: "", termsValue: false, isSubmitting: false, };
    this.onDeleteProductClick = this.onDeleteProductClick.bind(this);
    this.onChangeQuantity = this.onChangeQuantity.bind(this);
    this.onCreateOrder = this.onCreateOrder.bind(this);
    this.renderProductsStep = this.renderProductsStep.bind(this);
    this.renderDeliveryStep = this.renderDeliveryStep.bind(this);
    this.renderConfirmStep = this.renderConfirmStep.bind(this);
    this.onNextStep = this.onNextStep.bind(this);
    this.onGoToStep = this.onGoToStep.bind(this);
    this.onDeliveryFieldChange = this.onDeliveryFieldChange.bind(this);
    this.onConfirmOrder = this.onConfirmOrder.bind(this);
    this.onSaveDeliveryData = this.onSaveDeliveryData.bind(this);
    this.onSavePaymentData = this.onSavePaymentData.bind(this);
    this.preSubmit = this.preSubmit.bind(this);
    this.setTermsError = this.setTermsError.bind(this);
    this.handleComponentSubmit = this.handleComponentSubmit.bind(this);
    this.getClientSecret = this.getClientSecret.bind(this);
    this.getPaymentParams = this.getPaymentParams.bind(this);
  }

  setIsSubmitting(value) {
    this.setState({ ...this.state, isSubmitting: value });
  }

  getClientSecret(setupIntent) {
    return setupIntent && setupIntent.attributes ? setupIntent.attributes.clientSecret : null;
  };

  getPaymentParams(currentUser, formValues) {
    const { name, addressLine1: line1, addressLine2: line2, postal: postal_code, state, city, country } = formValues;
    const addressMaybe = line1 && postal_code ? { address: { city, country, line1, line2, postal_code, state } } : {};
    const billing_details = { name, email: ensureCurrentUser(currentUser).attributes.email, ...addressMaybe };

    return ({ payment_method_data: { billing_details } });
  };

  async handleComponentSubmit(params) {
    this.setIsSubmitting(true);
    const ensuredCurrentUser = ensureCurrentUser(this.props.currentUser);
    const stripeCustomer = ensuredCurrentUser.stripeCustomer;
    const { stripe, card, formValues } = params;

    return await this.props.onCreateSetupIntent()
      .then(async (setupIntent) => {

        // const stripeParams = {
        //   stripe,
        //   card,
        //   setupIntentClientSecret: this.getClientSecret(setupIntent),
        //   paymentParams: this.getPaymentParams(this.props.currentUser, formValues),
        // };

        // Creating payment_method manually (instead of onHandleCardSetup()) to avoid firing 3DS confirmation too early.
        const cardResult = await stripe.createPaymentMethod({
          type: "card",
          card,
          billing_details: {
            name: this.getPaymentParams(this.props.currentUser, formValues).payment_method_data.billing_details.name,
            email: this.getPaymentParams(this.props.currentUser, formValues).payment_method_data.billing_details.email,
          },
        });
        const { paymentMethod: { id: payment_method, card: cardDetails } } = cardResult;

        // const { setupIntent: { payment_method } } = cardResult;
        // const cardResult = await this.props.onHandleCardSetup(stripeParams); // attaching the card to a setup intent
        return ({ payment_method, cardDetails });
      })
      .then(async ({ payment_method, cardDetails }) => {
        // Note: stripe.handleCardSetup might return an error inside successful call (200),
        // but those are rejected in thunk functions.
        if (!payment_method) return;

        // Attaching card to a stripe customer.
        const savedCard = await this.props.onSavePaymentMethod(stripeCustomer, payment_method);
        return ({ savedCard, payment_method, cardDetails });
      })
      .then(async ({ payment_method, cardDetails }) => {
        if (!payment_method) return;
        // Creating the buyer's transaction (order).
        return await this.props.onShoppingCartSave(payment_method, cardDetails);
      })
      .then(async (orders) => {
        // Completing 3DS. Need to retrieve EXACTLY paymentIntentClientSecret from every supplier-order.
        await Promise.all(orders.map(async (order) => {
          const { attributes: { protectedData: { stripePaymentIntents: { default: { stripePaymentIntentClientSecret } } } } } = order;
          const confirmResult = await this.props.onConfirmCardSetup({ stripe, stripePaymentIntentClientSecret });
        }));

        // Single supplier approach. (Working without any error)
        // const { attributes: { protectedData: { stripePaymentIntents: { default: { stripePaymentIntentClientSecret } } } } } = orders[0];
        // const confirmResult = await this.props.onConfirmCardSetup({ stripe, stripePaymentIntentClientSecret });
        // console.log({ confirmResult });

        return orders;
      })
      .then(async (orders) => {
        // Executing the next transition to every sub-order (for charging it).
        await Promise.all(orders.map(async ({ id: { uuid: id } }) => await this.props.onChargeOrderTransition({ id })));
      })
      .then(() => {
        // Moving the "Thank you" step.
        this.props.onShoppingCartSaveSuccess();
      })
      .then(async () => {
        // Removing attached payment method.
        await this.props.deletePaymentMethod();
      })
      .catch((e) => {
        console.error({ e });
        throw e;
      })
      .finally(() => {
        // Disabling loading spinner on the submit_button.
        this.setIsSubmitting(false);
      });
  };

  setTermsError(errorValue, termsValue) {
    return this.setState((prev) => ({ ...prev, termsError: errorValue, termsValue }));
  }

  onConfirmOrder(event) {
    let newFieldValue = { valid: event.target.checked };
    const currentStepIndex = this.props.shoppingCart?.currentStep || 0;
    this.props.onGoToStep(currentStepIndex, newFieldValue);
    this.setTermsError("", event.target.checked);
  }

  onSaveDeliveryData(event) {
    let newFieldValue = { saveDeliveryData: event.target.checked };
    const currentStepIndex = this.props.shoppingCart?.currentStep || 0;
    this.props.onGoToStep(currentStepIndex, newFieldValue);
  }

  onSavePaymentData(event) {
    let newFieldValue = { savePaymentData: event.target.checked };
    const currentStepIndex = this.props.shoppingCart?.currentStep || 0;
    this.props.onGoToStep(currentStepIndex, newFieldValue);
  }

  onChangeQuantity(product, event) {
    this.props.onShoppingCartUpdateProduct({ id: product.id, count: parseFloat(event.target.value) });
  }

  onDeleteProductClick(product) {
    this.props.onShoppingCartDeleteProduct({ id: product.id });
  }

  onDeliveryFieldChange(values) {
    const currentStepIndex = this.props.shoppingCart?.currentStep || 0;
    this.props.onGoToStep(currentStepIndex, values);
  }

  onCreateOrder() {
    const requiredFieldMessage = this.props.intl.formatMessage({ id: "ShoppingCartPage.requiredField" });

    if (this.props.shoppingCart.confirmData.valid) {
      this.setTermsError("");
      this.props.onShoppingCartSave();
    } else {
      this.setTermsError(requiredFieldMessage);
    }
  }

  onGoToStep(idx) {
    const currentStepIndex = this.props.shoppingCart?.currentStep || 0;
    if (idx < currentStepIndex) this.props.onGoToStep(idx);
  }

  onNextStep() {
    const currentStepIndex = this.props.shoppingCart?.currentStep || 0;
    this.props.onGoToStep(currentStepIndex + 1);
  }

  preSubmit(form) {
    const errors = form.getState().errors;
    const values = form.getState().values;

    if (!Object.keys(errors).length) {
      this.onDeliveryFieldChange(values);
      // TODO: Call old onShoppingCartSave() method.
      this.onNextStep();
    }
  }

  // Function added to make sure page scrolls to top if cart is empty
  componentDidUpdate() {
    if (this.props.shoppingCart?.validBrands?.length === 0) {
      window.scrollTo(0, 0);
    }
  };

  render() {
    const { intl, scrollingDisabled, shoppingCart, shoppingCartSaved, orders } = this.props;
    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage({ id: "ShoppingCartPage.schemaTitle" }, { siteTitle });
    const schemaDescription = intl.formatMessage({ id: "ShoppingCartPage.schemaDescription" }, { siteTitle });
    const schemaImage = `${config.canonicalRootURL}${facebookImage}`;
    const brands = shoppingCart?.validBrands || [];
    const brandsAmount = brands.length > 1 ? `(${brands?.length} ${intl.formatMessage({ id: "ShoppingCartPage.proceedCheckoutButtonBrands" })})` : `(${intl.formatMessage({ id: "ShoppingCartPage.proceedCheckoutButton1Brand" })})`;
    

    const shippingCosts = brands.reduce((acc, { brandTotal, cancelled, shippingFee }) => {
      const shippingCosts = cancelled || brandTotal >= MINIMAL_ORDER_FOR_FREE_SHIPPING ? 0 : shippingFee;
      acc += shippingCosts;
      return acc;
    }, 0);
    const rawVAT = brands.reduce((acc, { cancelled, brandVAT: totalBrandVAT }) => {
      const brandVAT = cancelled ? 0 : totalBrandVAT;
      acc += brandVAT;
      return acc;
    }, 0);
    const VAT = formatPrice(rawVAT);
    const subTotalExclVAT = formatPrice(shoppingCart?.cartInfo?.subTotal || 0);
    const rawSubTotalExclVATWithShippingFee = shoppingCart?.cartInfo?.subTotal + shippingCosts || 0;
    const subTotalExclVATWithShippingFee = formatPrice(rawSubTotalExclVATWithShippingFee);
    const subTotalInclVAT = formatPrice(rawSubTotalExclVATWithShippingFee + rawVAT || 0);

    const proceedToCheckoutText = intl.formatMessage({id: 'ShoppingCartPage.proceedCheckout'},{quantity: brandsAmount});
    const proceedToPaymentText = intl.formatMessage({id: 'ShoppingCartPage.proceedPayment'});
    const confirmOrderText = intl.formatMessage({id: 'ShoppingCartPage.confirmOrder'});

    const newSearchProductsPageName = 'NewSearchProductsPage';
    const localeParams = {locale};

    const steps = [{
      stepName: <FormattedMessage id="ShoppingCartPage.step.CART" />,
      title: "Shopping cart",
      content: this.renderProductsStep,
      buttonText: proceedToCheckoutText,
      stepComplete: shoppingCart?.brandValid,
      iconDoneClass: css.productsStepDone,
      iconCurrentClass: css.productsStepCurrent,
      iconEmptyClass: css.productsStepEmpty,
      storeFieldName: "brands",
    }, {
      stepName: <FormattedMessage id="ShoppingCartPage.step.DELIVERY" />,
      title: "Delivery address",
      content: this.renderDeliveryStep,
      buttonText: proceedToPaymentText,
      stepComplete: shoppingCart?.deliveryData?.valid,
      iconDoneClass: css.deliveryStepDone,
      iconCurrentClass: css.deliveryStepCurrent,
      iconEmptyClass: css.deliveryStepEmpty,
      storeFieldName: "deliveryData",
    }, {
      stepName: <FormattedMessage id="ShoppingCartPage.step.PAYMENT" />,
      title: "Payment",
      content: this.renderConfirmStep,
      buttonText: confirmOrderText,
      stepComplete: shoppingCart?.confirmData?.valid,
      iconDoneClass: css.confirmStepDone,
      iconCurrentClass: css.confirmStepCurrent,
      iconEmptyClass: css.confirmStepEmpty,
      storeFieldName: "confirmData",
    },];
    let currentStepIndex = shoppingCart?.currentStep || 0;
    const currentStep = steps[currentStepIndex];
    if (shoppingCartSaved) {
      currentStepIndex = 5;
    }
    const emptyStateOptions = {
      containerOffsetClassName: "cartOffset",
      iconStylesClassName: "cartIcon",
      titleMessageId: "ShoppingCartPage.noSearchResultTitle",
      textMessageId: "ShoppingCartPage.noSearchResultText",
      buttonMessageId: "ShoppingCartPage.buttonText",
      //buttonLinkAddress: "/new/products",
      buttonLinkAddress: pathByRouteName(newSearchProductsPageName, routeConfiguration(), localeParams),
    };

    const shortPageContainer = currentStepIndex > 0 && currentStepIndex !== 5;

    return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      robots='noindex'
      facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      twitterImages={[{ url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },]}
      schema={{
        "@context": "http://schema.org",
        "@type": "WebPage",
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div className={shortPageContainer ? css.pageContainerShort : css.pageContainer} >
            <div className={css.pageContainerSection}>
              {!shoppingCart?.brands.length && !shoppingCartSaved ?
                <div className={css.pageContent}>
                  <EmptyStateComponent options={emptyStateOptions} />
                </div>
                :
                <div className={css.pageContent}>
                  <div className={css.shoppingCartStepLine}>
                  </div>
                  <div className={css.shoppingCartSteps}>

                    {steps.map((s, idx) => {
                      const iconCss = classNames(css.stepIcon, {
                        [s.iconDoneClass]: idx < currentStepIndex,
                        [s.iconCurrentClass]: idx === currentStepIndex,
                        [s.iconEmptyClass]: idx > currentStepIndex,
                      });
                      const nodeCss = classNames(css.stepItem);

                      return (
                        <div key={s.stepName} className={nodeCss}>
                          <h3 onClick={() => this.onGoToStep(idx)}>
                            {s.stepName}
                          </h3>
                          <div className={iconCss} onClick={() => this.onGoToStep(idx)}>
                            &nbsp;
                          </div>
                        </div>);
                    })}
                  </div>
                  {shoppingCartSaved ?
                    (
                      <div className={css.orderCreated}>
                        <h1>
                          <FormattedMessage id="ShoppingCartPage.thankYou.title1" />
                          <br />
                          <FormattedMessage id="ShoppingCartPage.thankYou.title2" />
                        </h1>
                        {orders.length ?
                          <div className={css.orderDetails}>
                            <p className={css.orderDetailsTotalPrice}>
                              <FormattedMessage id="ShoppingCartPage.thankYou.orderTotal" />&nbsp;
                              {orders[0].attributes.protectedData.cart.cartInfo.orderTotalInclVAT.toFixed(2)}
                            </p>
                            {orders.map((order) => {
                              const { id, attributes: { protectedData: { brandInfo: { brandName, products } } } } = order;
                              const deliveryTime = defineTheLongestDeliveryPeriod(products);

                              return (
                                <div key={id.uuid} className={css.orderDetailsItem}>
                                  <p className={css.orderDetailsItemBrandName}>{brandName}</p>
                                  <p className={css.orderDetailsItemDeliveryTime}>{deliveryTime}</p>
                                  <p className={css.orderDetailsItemId}>
                                    <FormattedMessage id="ShoppingCartPage.thankYou.orderNumber" />{id.uuid}</p>
                                </div>
                              );
                            })}
                          </div>
                          :
                          null}
                        <h3>
                          <FormattedMessage id="OrderThankYouPage.orderConfirmationStatus" />
                        </h3>
                        <h4>
                          <FormattedMessage id="ShoppingCartPage.thankYou.needHelp" />&nbsp;
                          <a href="mailto:hello@vegshelf.com" target="_blank">
                            <FormattedMessage id="ShoppingCartPage.thankYou.contactUs" />
                          </a>
                        </h4>
                        <h2>
                          <NamedLink
                            name="LandingPage"
                            className={css.goToButton}
                            onClick={this.props.clearFetchedOrders}
                          >
                            <FormattedMessage id="ShoppingCartPage.thankYou.continueShopping" />
                          </NamedLink>
                        </h2>
                      </div>
                    )
                    :
                    (
                      <div>
                        <div className={css.shoppingCartContent}>
                          {currentStep.content()}
                          <div className={css.cartPanel}>
                            <div className={css.cartInfo}>
                              <h1>
                                <FormattedMessage id="ShoppingCartPage.cartPanel.orderSummary" />
                              </h1>
                              <div className={css.brandPriceBlock}>
                                <div key={`brandTotalPrice_Products`} className={css.cartProperty}>
                                  <div className={css.PropertyLabel}>
                                    <FormattedMessage id="ShoppingCartPage.summaryTitle" />
                                  </div>
                                  <div className={css.PropertyValue}>
                                    {subTotalExclVAT} €
                                  </div>
                                </div>
                                <div className={css.cartProperty}>
                                  <div className={css.PropertyLabel}>
                                    <FormattedMessage id="ShoppingCartPage.cartPanel.shippingFee" />
                                  </div>
                                  <div className={css.PropertyValue}>
                                    {shippingCosts.toFixed(2)} €
                                  </div>
                                </div>
                              </div>
                              <div className={css.cartProperty}>
                                <div className={css.PropertyLabel}>
                                  <FormattedMessage id="ShoppingCartPage.cartPanel.subtotalExclVAT" />
                                </div>
                                <div className={css.PropertyValue}>
                                  {subTotalExclVATWithShippingFee} €
                                </div>
                              </div>
                              <div className={css.cartProperty}>
                                <div className={css.PropertyLabel}>
                                  <FormattedMessage id="ShoppingCartPage.cartPanel.VAT" />
                                </div>
                                <div className={css.PropertyValue}>
                                  {VAT} €
                                </div>
                              </div>
                              <div className={css.cartPropertyTotal}>
                                <div className={css.PropertyLabel}>
                                  <FormattedMessage id="ShoppingCartPage.cartPanel.totalInclVAT" />
                                </div>
                                <div className={css.PropertyValue}>
                                  {subTotalInclVAT} €
                                </div>
                              </div>
                            </div>
                            {currentStepIndex === 0 ?
                              (
                                <button
                                  className={css.createOrderButton}
                                  disabled={!shoppingCart?.cartInfo?.valid}
                                  onClick={() => (this.onNextStep())}
                                >
                                  {currentStep.buttonText}
                                </button>
                              )
                              :
                              null}
                          </div>
                        </div>
                        {currentStepIndex === 0 ?
                          (
                            <button
                              className={css.mobileCreateOrderButton}
                              disabled={!shoppingCart?.cartInfo?.valid}
                              onClick={() => (this.onNextStep())}
                            >
                              {currentStep.buttonText}
                            </button>
                          )
                          :
                          null}
                      </div>
                    )}
                </div>}
            </div>
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>);
  }

  renderProductsStep() {
    const validBrands = [...this.props.shoppingCart?.validBrands || []];
    const invalidBrands = [...this.props.shoppingCart?.invalidBrands || []];

    return (<div className={css.brands}>
      <div className={css.formTitle}>
        <FormattedMessage id="ShoppingCartPage.cartSummary" />
      </div>
      {validBrands.map((brand) => (this.renderBrandItem(brand)))}
      {invalidBrands.length ? (<h1 className={css.invalidItemsHeader}><FormattedMessage id="ShoppingCartPage.brandUnderMinimum" /></h1>) : null}
      {invalidBrands.map((brand) => (this.renderBrandItem(brand)))}
    </div>);
  }

  renderBrandItem(brand) {
    const priceCss = classNames({ [css.invalidPriceStyle]: !brand.valid });
    const longestShippingMessage = defineTheLongestDeliveryPeriod(brand.products);
    const brandShippingCosts = brand.brandTotal >= MINIMAL_ORDER_FOR_FREE_SHIPPING ? 0 : brand.shippingFee || 0;

    return (<div key={brand?.brandId || -1} className={css.brandItem}>
      <div className={css.brandItemHeader}>
        <div className={css.brandHeaderMainColumn}>
          <h2 className={css.brandTitle}>
            {brand?.brandId ? (<>
              <NamedLink name="SupplierDetailsPage" params={{ brand: slugify(brand.brandName), id: brand.brandId, locale: locale }}>
                {brand.brandName}
              </NamedLink>
              &nbsp; | &nbsp;
            </>) : null}
            <span className={priceCss}>€&nbsp;{formatPrice(brand.brandTotal)}<FormattedMessage id="ShoppingCartPage.summaryExclVAT" /></span>
          </h2>
        </div>
        {!brand.valid && brand.brandId ? (
          <NamedLink name="SupplierDetailsPage" className={css.shopBrandButton} params={{ brand: slugify(brand.brandName), id: brand.brandId, locale: locale }}>
            <FormattedMessage id="ShoppingCartPage.shopBrand" />
          </NamedLink>) : null}
      </div>
      <div className={css.productContainer}>
        <div className={css.productItemsContainer}>
          {brand.products.map((product) => {
            const chooseStep = product?.unitsOfPackage || 1;
            const chooseQuantityItems = [];
            const currentValue = parseFloat(product.count);
            for (let i = 1; i < 11 || i <= currentValue; i++) {
              chooseQuantityItems.push(chooseStep * i);
            }
            const style = {};
            if (product.mainImageUrl) {
              style.backgroundImage = `url('${product.mainImageUrl}')`;
            }
            const customOnClick = (product) => (e) => this.onChangeQuantity(product, e);
            const completeProductName = `${product.productName}, ${product.weight}${product.weightUnit}`;

            return (<div key={product.id} className={css.productItem}>
              <div className={css.productLogo} style={style}>&nbsp;</div>
              <div className={css.productDetails}>
                <div className={css.productNameRow}>
                  <h1 className={css.productName}>
                    <NamedLink name="ProductDetailsPage" params={{ product: slugify(product.productName), id: product.id, locale: locale }}>
                      {completeProductName}
                    </NamedLink>
                  </h1>
                  <button className={css.deleteProductButton} onClick={() => this.onDeleteProductClick(product)} />
                </div>
                <div className={css.productKeyRow}>
                  <FormattedMessage id="ShoppingCartPage.productID" />{product.id}
                </div>
                <div className={css.productPriceRow}>
                  <Select
                    items={chooseQuantityItems}
                    defaultValue={currentValue}
                    customOnClick={customOnClick(product)}
                    displaySelectedValue={(value) => (value)}
                  />
                  <div className={css.productPrice}>€ {formatPrice(product.totalPrice)}</div>
                </div>
              </div>
            </div>);
          })}
        </div>
        <div className={css.shippingFeeContainer}>
          <span className={css.brandShippingFee}>
            <FormattedMessage id="ShoppingCartPage.shipping" />€ {brand.cancelled && brandShippingCosts !== 0 ? "-" : ""}{brandShippingCosts.toFixed(2)}<FormattedMessage id="ShoppingCartPage.summaryExclVAT" />
          </span>
          {!brand.cancelled ? <span className={css.brandDeliveryTime}>{longestShippingMessage}</span> : null}
        </div>
      </div>
    </div>);
  }

  renderDeliveryStep() {
    const deliveryData = this.props.shoppingCart?.deliveryData || {};

    return (<FinalForm
      onSubmit={this.preSubmit}
      render={(formRenderProps) => {
        const { handleSubmit, form } = formRenderProps;

        // ================ general ================ //
        const hasError = (field) => {
          return (form.getState().touched[field] && form.getState().errors.hasOwnProperty(field)) || false;
        };
        const pageName = "ShoppingCartPage";
        const getInputFormattedMessage = (name) => this.props.intl.formatMessage({ id: `${pageName}.${name}` });
        const requiredFieldFormattedMessage = getInputFormattedMessage("requiredField");
        const requiredField = validators.required(requiredFieldFormattedMessage);

        // ================ companyName ================ //
        const companyNameInputId = "companyName";
        const companyNamePlaceholder = getInputFormattedMessage("companyNamePlaceholder");
        const companyNameLabel = getInputFormattedMessage("companyNameLabel");
        const isCompanyNameError = hasError(companyNameInputId);

        // ================ streetAndNumber ================ //
        const streetAndNumberNameInputId = "streetAndNumber";
        const streetAndNumberNamePlaceholder = getInputFormattedMessage("streetAndNumberPlaceholder");
        const streetAndNumberNameLabel = getInputFormattedMessage("streetAndNumberLabel");
        const isStreetAndNumberError = hasError(streetAndNumberNameInputId);

        // ================ additionalInfo ================ //
        const additionalInfoNameInputId = "additionalInfo";
        const additionalInfoNamePlaceholder = getInputFormattedMessage("additionalInfoPlaceholder");
        const additionalInfoNameLabel = getInputFormattedMessage("additionalInfoLabel");
        const isAdditionalInfoError = hasError(additionalInfoNameInputId);

        // ================ city ================ //
        const cityNameInputId = "city";
        const cityNamePlaceholder = getInputFormattedMessage("cityPlaceholder");
        const cityNameLabel = getInputFormattedMessage("cityLabel");
        const isCityError = hasError(cityNameInputId);

        // ================ postCode ================ //
        const postCodeNameInputId = "postCode";
        const postCodeNamePlaceholder = getInputFormattedMessage("postCodePlaceholder");
        const postCodeNameLabel = getInputFormattedMessage("postCodeLabel");
        const isPostCodeError = hasError(postCodeNameInputId);

        // ================ region ================ //
        const regionNameInputId = "region";
        const regionNamePlaceholder = getInputFormattedMessage("regionPlaceholder");
        const regionNameLabel = getInputFormattedMessage("regionLabel");
        const isRegionError = hasError(regionNameInputId);

        // ================ country ================ //
        const countryNameInputId = "country";
        const countryNameLabel = getInputFormattedMessage("countryLabel");

        const isSubmitDisabled = isCompanyNameError || isStreetAndNumberError || isAdditionalInfoError || isCityError || isPostCodeError || isRegionError;

        return (<Form onSubmit={handleSubmit} className={css.deliveryDetailForm}>
          <div className={css.brands}>
            <div className={css.formTitle}>
              <FormattedMessage id="ShoppingCartPage.deliveryAddress" />
            </div>
            <div className={css.formDelivery}>
              <div>
                <LabelWithErrorMsg
                  className={css.inputLabelMargin}
                  isRequired
                  htmlFor={companyNameInputId}
                  labelText={companyNameLabel}
                  errorMsg={requiredFieldFormattedMessage}
                  isError={isCompanyNameError}
                >
                  <FieldTextInput
                    type="text"
                    name={companyNameInputId}
                    id={companyNameInputId}
                    placeholder={companyNamePlaceholder}
                    validate={requiredField}
                    defaultValue={deliveryData.companyName}
                  />
                </LabelWithErrorMsg>
              </div>
              <div className={css.twoRowFields}>
                <div className={css.fieldInput}>
                  <LabelWithErrorMsg
                    className={css.inputLabelMargin}
                    isRequired
                    htmlFor={streetAndNumberNameInputId}
                    labelText={streetAndNumberNameLabel}
                    errorMsg={requiredFieldFormattedMessage}
                    isError={isStreetAndNumberError}
                  >
                    <FieldTextInput
                      type="text"
                      name={streetAndNumberNameInputId}
                      id={streetAndNumberNameInputId}
                      placeholder={streetAndNumberNamePlaceholder}
                      validate={requiredField}
                      defaultValue={deliveryData.streetAndNumber}
                    />
                  </LabelWithErrorMsg>
                </div>
                <div className={css.fieldInput}>
                  <LabelWithErrorMsg
                    className={css.inputLabelMargin}
                    isRequired={false}
                    htmlFor={additionalInfoNameInputId}
                    labelText={additionalInfoNameLabel}
                    errorMsg={requiredFieldFormattedMessage}
                    isError={isAdditionalInfoError}
                  >
                    <FieldTextInput
                      type="text"
                      name={additionalInfoNameInputId}
                      id={additionalInfoNameInputId}
                      placeholder={additionalInfoNamePlaceholder}
                      defaultValue={deliveryData.additionalInfo}
                    />
                  </LabelWithErrorMsg>
                </div>
              </div>
              <div className={css.twoRowFields}>
                <div className={css.fieldInput}>
                  <LabelWithErrorMsg
                    className={css.inputLabelMargin}
                    isRequired
                    htmlFor={cityNameInputId}
                    labelText={cityNameLabel}
                    errorMsg={requiredFieldFormattedMessage}
                    isError={isCityError}
                  >
                    <FieldTextInput
                      type="text"
                      name={cityNameInputId}
                      id={cityNameInputId}
                      placeholder={cityNamePlaceholder}
                      validate={requiredField}
                      defaultValue={deliveryData.city}
                    />
                  </LabelWithErrorMsg>
                </div>
                <div className={css.fieldInput}>
                  <LabelWithErrorMsg
                    className={css.inputLabelMargin}
                    isRequired
                    htmlFor={postCodeNameInputId}
                    labelText={postCodeNameLabel}
                    errorMsg={requiredFieldFormattedMessage}
                    isError={isPostCodeError}
                  >
                    <FieldTextInput
                      type="text"
                      name={postCodeNameInputId}
                      id={postCodeNameInputId}
                      placeholder={postCodeNamePlaceholder}
                      validate={requiredField}
                      defaultValue={deliveryData.postCode}
                    />
                  </LabelWithErrorMsg>
                </div>
              </div>
              <div className={css.twoRowFields}>
                <div className={css.fieldInput}>
                  <LabelWithErrorMsg
                    className={css.inputLabelMargin}
                    isRequired={false}
                    htmlFor={regionNameInputId}
                    labelText={regionNameLabel}
                    errorMsg={requiredFieldFormattedMessage}
                    isError={isRegionError}
                  >
                    <FieldTextInput
                      type="text"
                      name={regionNameInputId}
                      id={regionNameInputId}
                      placeholder={regionNamePlaceholder}
                      defaultValue={deliveryData.region}
                    />
                  </LabelWithErrorMsg>
                </div>
                <div className={css.fieldInput}>
                  <LabelWithErrorMsg
                    className={css.inputLabelMargin}
                    isRequired={false}
                    htmlFor={countryNameInputId}
                    labelText={countryNameLabel}
                    errorMsg={requiredFieldFormattedMessage}
                    isError={false}
                  >
                    <FieldTextInput
                      readOnly={true}
                      type="text"
                      name={countryNameInputId}
                      id={countryNameInputId}
                      defaultValue={deliveryData.country}
                    />
                  </LabelWithErrorMsg>
                </div>
              </div>
            </div>
            <div>
              <button
                type="submit"
                className={css.goToNextButton}
                onClick={() => this.preSubmit(form)}
                disabled={isSubmitDisabled}
              >
                <FormattedMessage id="ShoppingCartPage.proceedPayment" />
              </button>
            </div>
          </div>
        </Form>);
      }}
    />);
  }

  renderConfirmStep() {
    const {
      currentUser,
      addPaymentMethodError,
      deletePaymentMethodError,
      createStripeCustomerError,
      handleCardSetupError,
      shoppingCart,
      handleCardSetupClearError,
      // deletePaymentMethod,
      // fetchStripeCustomer,
      // resetPaymentData,
    } = this.props;


    const deliveryData = shoppingCart?.deliveryData || {};
    const paymentData = shoppingCart?.paymentData || {};
    const currentBillingData = this.props?.buyerCurrentCompany?.attributes?.publicData;

    const hasDefaultPaymentMethod = currentUser && ensureStripeCustomer(currentUser.stripeCustomer).attributes.stripeCustomerId && ensurePaymentMethodCard(currentUser.stripeCustomer.defaultPaymentMethod).id;

    return (<div className={css.brands}>
      <div className={css.formTitle}>
        <FormattedMessage id="ShoppingCartPage.confirmationAndPayment" />
      </div>
      <div className={css.previewItemData}>
        <div className={css.previewItemFirstColumn}>
          <h1 className={css.previewItemTitle}>
            <FormattedMessage id="ShoppingCartPage.deliveryAddress" />
          </h1>
          <div className={css.previewItemValue}>{deliveryData.companyName}</div>
          <div className={css.previewItemValue}>{deliveryData.streetAndNumber}</div>
          <div className={css.previewItemValue}>{deliveryData.additionalInfo}</div>
          <div className={css.previewItemValue}>{`${deliveryData.postCode} ${deliveryData.city}`}</div>
          <div className={css.previewItemValue}>{deliveryData.region}</div>
          <div className={css.previewItemValue}>{deliveryData.country}</div>
          <div className={css.confirmRow}>
            <input
              value="true"
              type="checkbox"
              name="saveDeviveryData"
              id="saveDeviveryData"
              onChange={(e) => this.onSaveDeliveryData(e)}
            />
            <label htmlFor="saveDeviveryData">
              <FormattedMessage id="ShoppingCartPage.saveAddress" />
            </label>
          </div>
        </div>
        <div className={css.previewItemSecondColumn}>
          <a className={css.previewNavigateToTab} onClick={() => this.onGoToStep(1)}>Edit</a>
        </div>
      </div>
      <div className={css.previewItemSeparator}>&nbsp;</div>
      <div className={css.previewItemData}>
        <div className={css.previewItemFirstColumn}>
          <div className={css.previewItemValue}>
            <div className={css.previewItemFirstColumn}>
              <h1 className={css.previewItemTitle}>
                <FormattedMessage id="ShoppingCartPage.paymentDetails" />
              </h1>
              <div className={css.previewItemValue}>
                <PaymentMethodsForm
                  formId="PaymentMethodsForm"
                  onSubmit={this.handleComponentSubmit}
                  handleRemovePaymentMethod={this.handleRemovePaymentMethod}
                  hasDefaultPaymentMethod={hasDefaultPaymentMethod}
                  addPaymentMethodError={addPaymentMethodError}
                  deletePaymentMethodError={deletePaymentMethodError}
                  createStripeCustomerError={createStripeCustomerError}
                  handleCardSetupError={handleCardSetupError}
                  inProgress={this.state.isSubmitting}
                  componentMode={true}
                  onCancelHandler={handleCardSetupClearError}
                  currentBillingData={currentBillingData}
                  paymentData={paymentData}
                  onSavePaymentData={this.onSavePaymentData}
                  onConfirmOrder={this.onConfirmOrder}
                  termsValue={this.state.termsValue}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>);
  }
}

ShoppingCartPageComponent.defaultProps = {
  currentUserListing: null, currentUserListingFetched: false,
};

ShoppingCartPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool, // from withRouter
  history: object.isRequired,
  location: object.isRequired, // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = (state) => {
  const {
    shoppingCart, shoppingCartSaved, orders, shoppingCartSaveInProgress, saveCreditCardOnUnmount,
  } = state.ShoppingCartStore;
  const { currentUser } = state.user;
  const { buyerCurrentCompany } = state.buyerCompany;

  return {
    scrollingDisabled: isScrollingDisabled(state),
    shoppingCart,
    shoppingCartSaved,
    orders,
    currentUser,
    buyerCurrentCompany,
    shoppingCartSaveInProgress,
    saveCreditCardOnUnmount,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onShoppingCartDeleteProduct: (values) => dispatch(shoppingCartDeleteProduct(values)),
  onShoppingCartUpdateProduct: (values) => dispatch(shoppingCartUpdateProduct(values)),
  onShoppingCartSave: (payment_method, card) => dispatch(shoppingCartSave(payment_method, card)),
  onGoToStep: (stepIndex, savedData) => dispatch(goToStep(stepIndex, savedData || {})),
  clearFetchedOrders: () => dispatch(clearFetchedOrders()),
  deletePaymentMethod: () => dispatch(deletePaymentMethod()),
  resetPaymentData: () => dispatch(resetPaymentData()),
  handleCardSetupClearError: () => dispatch(handleCardSetupClearError()),
  onCreateSetupIntent: (params) => dispatch(createStripeSetupIntent(params)),
  onCreatePaymentIntent: (params) => dispatch(createStripePaymentIntent(params)),
  onHandleCardSetup: (params) => dispatch(handleCardSetup(params)),
  onConfirmCardSetup: (params) => dispatch(confirmCardPayment(params)),
  onChargeOrderTransition: (params) => dispatch(chargeOrderTransition(params)),
  onSavePaymentMethod: (stripeCustomer, newPaymentMethod) => {
    return dispatch(savePaymentMethod(stripeCustomer, newPaymentMethod));
  },
  fetchStripeCustomer: () => dispatch(stripeCustomer()),
  onShoppingCartSaveSuccess: () => dispatch(shoppingCartSaveSuccess()),
});

const ShoppingCartPage = compose(withRouter, connect(mapStateToProps, mapDispatchToProps), injectIntl,)(ShoppingCartPageComponent);

ShoppingCartPage.loadData = () => (dispatch) => {
  /**
   * Contains shoppingCartLoad() request inside.
   */
  return dispatch(BuyerPaymentDetailsPage.loadData());
};

export default ShoppingCartPage;
