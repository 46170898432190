import { storableError } from "../../util/errors";
import { types as sdkTypes } from "../../util/sdkLoader";

const { UUID } = sdkTypes;

// ================ Action types ================ //
export const SUPPLIER_OTHER_PRODUCTS_REQUEST = "app/Supplier/other/products/request";
export const SUPPLIER_OTHER_PRODUCTS_SUCCESS = "app/Supplier/other/products/success";
export const SUPPLIER_OTHER_PRODUCTS_ERROR = "app/Supplier/other/products/error";

export const LISTING_DETAILS_REQUEST = "app/listing/load/details/request";
export const LISTING_DETAILS_SUCCESS = "app/listing/load/details/success";
export const LISTING_DETAILS_ERROR = "app/listing/load/details/error";

export const SUPPLIER_DETAILS_REQUEST = "app/supplier/load/details/request";
export const SUPPLIER_DETAILS_SUCCESS = "app/supplier/load/details/success";
export const SUPPLIER_DETAILS_ERROR = "app/supplier/load/details/error";

// ================ Reducer ================ //
const initialState = {
  supplierOtherProductsError: null,
  supplierOtherProductsProgress: false,
  supplierOtherProducts: null,
  productDetailsError: null,
  productDetailsProgress: false,
  productDetails: null,
  supplierDetailsError: null,
  supplierDetailsProgress: false,
  supplierDetails: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case SUPPLIER_OTHER_PRODUCTS_REQUEST:
      return {
        ...state,
        supplierOtherProductsError: null,
        supplierOtherProductsProgress: true,
        supplierOtherProducts: null,
      };
    case SUPPLIER_OTHER_PRODUCTS_SUCCESS:
      return { ...state, supplierOtherProductsProgress: false, supplierOtherProducts: payload };
    case SUPPLIER_OTHER_PRODUCTS_ERROR:
      return { ...state, supplierOtherProductsProgress: false, supplierOtherProductsError: payload };
    case LISTING_DETAILS_REQUEST:
      return { ...state, productDetailsError: null, productDetailsProgress: true, productDetails: null };
    case LISTING_DETAILS_SUCCESS:
      return { ...state, productDetailsProgress: false, productDetails: payload };
    case LISTING_DETAILS_ERROR:
      return { ...state, productDetailsProgress: false, productDetailsError: payload };
    case SUPPLIER_DETAILS_REQUEST:
      return { ...state, supplierDetailsError: null, supplierDetailsProgress: true, supplierDetails: null };
    case SUPPLIER_DETAILS_SUCCESS:
      return { ...state, supplierDetailsProgress: false, supplierDetails: payload };
    case SUPPLIER_DETAILS_ERROR:
      return { ...state, supplierDetailsProgress: false, supplierDetailsError: payload };
    default:
      return state;
  }
}

// ================ Action creators ================ //
export const loadSupplierOtherProductsRequest = () => ({ type: SUPPLIER_OTHER_PRODUCTS_REQUEST });
export const loadSupplierOtherProductsSuccess = (data) => ({ type: SUPPLIER_OTHER_PRODUCTS_SUCCESS, payload: data });
export const loadSupplierOtherProductsError = error => ({
  type: SUPPLIER_OTHER_PRODUCTS_ERROR,
  payload: error,
  error: true,
});

export const loadProductDetailsRequest = () => ({ type: LISTING_DETAILS_REQUEST });
export const loadProductDetailsSuccess = (data) => ({ type: LISTING_DETAILS_SUCCESS, payload: data });
export const loadProductDetailsError = (error) => ({ type: LISTING_DETAILS_ERROR, payload: error, error: true });

export const loadSupplierDetailsRequest = () => ({ type: SUPPLIER_DETAILS_REQUEST });
export const loadSupplierDetailsSuccess = (data) => ({ type: SUPPLIER_DETAILS_SUCCESS, payload: data });
export const loadSupplierDetailsError = (error) => ({ type: SUPPLIER_DETAILS_ERROR, payload: error, error: true });

// ================ Thunks ================ //
export const loadProductDetails = (params) => (dispatch, getState, sdk) => {
  dispatch(loadProductDetailsRequest());
  const { id } = params;
  const request = params.supplierMode
    ? sdk.ownListings.show({ id: new UUID(id) }) : sdk.listings.show({ id: new UUID(id) });

  return request
    .then((resp) => {
      dispatch(loadProductDetailsSuccess(resp.data.data));
      if (resp?.data?.data?.attributes?.publicData) {
        dispatch(loadSupplierDetails({ id: resp?.data?.data?.attributes?.publicData.brandId }));
      }
    })
    .catch((e) => {
      dispatch(loadProductDetailsError(storableError(e)));
    });
};
export const loadSupplierDetails = (params) => (dispatch, getState, sdk) => {
  dispatch(loadSupplierDetailsRequest());
  const { id } = params;

  return sdk.listings.show({ id: new UUID(id) })
    .then((resp) => {
      dispatch(loadSupplierDetailsSuccess(resp.data.data));
      if (resp?.data?.data?.attributes?.publicData) {
        dispatch(loadSupplierOtherProducts({ id: id }));
      }
    })
    .catch((e) => {
      dispatch(loadSupplierDetailsError(storableError(e)));
    });
};

export const loadSupplierOtherProducts = (params) => (dispatch, getState, sdk) => {
  dispatch(loadSupplierOtherProductsRequest());
  const { id } = params;
  const filter = { pub_dataType: "product", pub_brandId: id };

  return sdk.listings
    .query(filter)
    .then((response) => {
      dispatch(loadSupplierOtherProductsSuccess(response?.data));
      return response;
    })
    .catch((e) => {
      return dispatch(loadSupplierOtherProductsError(e));
    });
};
