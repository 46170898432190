import { bool } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import LoaderComponent from "../../components/Loader/Loader";
import { buyerUpdateDeliveryAddress } from "../../ducks/buyer-company.duck";
import { isScrollingDisabled } from "../../ducks/UI.duck";
import { BuyerDeliveryAddressForm } from "../../forms";
import { injectIntl, FormattedMessage } from "../../util/reactIntl";
import css from "./BuyerProfileDeliveryAddressPage.module.css";

export class BuyerProfileDeliveryAddressPageComponent extends Component {
  constructor(props) {
    super(props);
    this.fileLogo = null;
    this.submitInProgress = false;
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(values) {
    this.props.onBuyerUpdateCompany({ ...values });
  }

  render() {
    const { buyerCurrentCompany, buyerUpdateCompanyInProgress } = this.props;

    return (
      <div className={css.deliveryAddressContainer}>
        <h1 className={css.deliveryAddressTitle}>
          <FormattedMessage id="BuyerAddressPage.deliveryAddress" />
        </h1>
        {buyerCurrentCompany ?
          <BuyerDeliveryAddressForm
            currentCompany={buyerCurrentCompany}
            inProgress={buyerUpdateCompanyInProgress}
            onSubmit={this.onSubmit}
          />
          :
          <LoaderComponent />
        }
      </div>
    );
  }
}

BuyerProfileDeliveryAddressPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
};

const mapStateToProps = (state) => {
  const { currentUser } = state.user;
  const { buyerCurrentCompany, buyerUpdateCompany, buyerUpdateCompanyInProgress } = state.buyerCompany;

  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
    buyerCurrentCompany,
    buyerUpdateCompany,
    buyerUpdateCompanyInProgress,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onBuyerUpdateCompany: (data) => dispatch(buyerUpdateDeliveryAddress(data)),
});

const BuyerProfileDeliveryAddressPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
)(BuyerProfileDeliveryAddressPageComponent);

export default BuyerProfileDeliveryAddressPage;