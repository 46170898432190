import { bool, object } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import facebookImage from "../../assets/VegshelfFacebook-1200x630.png";
import twitterImage from "../../assets/VegshelfTwitter-600x314.png";
import {
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Page,
  TabNav,
} from "../../components";
import LoaderComponent from "../../components/Loader/Loader";
import config from "../../config";
import { BuyerApprovedOrdersPage, TopbarContainer } from "../../containers";
import { isScrollingDisabled } from "../../ducks/UI.duck";
import { FormattedMessage, injectIntl, intlShape } from "../../util/reactIntl";
import { propTypes } from "../../util/types";
import css from "./BuyerOrdersAndRequestsPage.module.css";

export class BuyerOrdersAndRequestsPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { stepIndex: 0 };
  }

  render() {
    const { intl, scrollingDisabled } = this.props;
    let { tab } = this.props;
    tab = tab || "orders";
    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage({ id: "BuyerOrdersAndRequestsPage.schemaTitle" }, { siteTitle });
    const schemaDescription = intl.formatMessage({ id: "BuyerOrdersAndRequestsPage.schemaDescription" }, { siteTitle });
    const schemaImage = `${config.canonicalRootURL}${facebookImage}`;
    const pages = {
      "orders": (<BuyerApprovedOrdersPage {...this.props} />),
    };

    const tabs = [
      {
        text: (
          <span>
            <FormattedMessage id="BuyerOrderTabPage.orderTabOrders" />
          </span>
        ),
        selected: tab === "orders",
        linkProps: {
          name: "BuyerApprovedOrdersPage",
        },
      },
    ];

    return (
      <Page
        className={css.root}
        scrollingDisabled={scrollingDisabled}
        contentType="website"
        description={schemaDescription}
        title={schemaTitle}
        robots='noindex'
        facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
        twitterImages={[
          { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
        ]}
        schema={{
          "@context": "http://schema.org",
          "@type": "WebPage",
          description: schemaDescription,
          name: schemaTitle,
          image: [schemaImage],
        }}
      >
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div className={css.ordersPageContainer}>
              <div className={css.ordersSection}>
                <TabNav rootClassName={css.tabs} tabRootClassName={css.tab} tabs={tabs} />
                <div className={css.pageContent}>
                  {!this.props.orders ?
                    this.props.ordersLoadInProgress ?
                      <LoaderComponent />
                      :
                      null
                    :
                    pages[tab]
                  }
                </div>
              </div>
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

BuyerOrdersAndRequestsPageComponent.defaultProps = {
  currentUserListing: null,
  currentUserListingFetched: false,
};

BuyerOrdersAndRequestsPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
  // from withRouter
  history: object.isRequired,
  location: object.isRequired,
  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = (state) => {
  const {
    orderRequestsLoadError,
    orderRequestsLoadInProgress,
    orderRequests,
    ordersLoadError,
    ordersLoadInProgress,
    orders,
  } = state.BuyerOrdersAndRequestsPage;

  return {
    scrollingDisabled: isScrollingDisabled(state),
    orderRequestsLoadError,
    orderRequestsLoadInProgress,
    orderRequests,
    ordersLoadError,
    ordersLoadInProgress,
    orders,
  };
};

const mapDispatchToProps = (/*dispatch*/) => ({});

const BuyerOrdersAndRequestsPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
)(BuyerOrdersAndRequestsPageComponent);

export default BuyerOrdersAndRequestsPage;
