import { bool, object } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import facebookImage from "../../assets/VegshelfFacebook-1200x630.png";
import twitterImage from "../../assets/VegshelfTwitter-600x314.png";
import {
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Page,
  ProductContainer,
} from "../../components";
import config from "../../config";
import { TopbarContainer } from "../../containers";
import { isScrollingDisabled } from "../../ducks/UI.duck";
import { injectIntl, intlShape, FormattedMessage } from "../../util/reactIntl";
import { propTypes } from "../../util/types";
import routeConfiguration from "../../routeConfiguration";
import { pathByRouteName } from "../../util/routes";
import { storedLocale, localePart } from "../../components/Wrapper/ClassWrapper";
import css from "./BuyerMyFavouritesPage.module.css";

const locale = storedLocale() || localePart();

export class BuyerMyFavouritesPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { stepIndex: 0 };
  }

  render() {
    const {
      // history,
      intl,
      // location,
      scrollingDisabled,
      formattedProducts,
    } = this.props;

    const products = formattedProducts ? formattedProducts?.map((product) => ({
      ...product, attributes: {
        ...product.attributes,
        publicData: {
          ...product.attributes.publicData,
          productImages: [
            {
              url: product.attributes?.publicData?.mainImageUrl,
            },
          ],
        },
      },
    })) : [];
    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage({ id: "BuyerMyFavouritesPage.schemaTitle" }, { siteTitle });
    const schemaDescription = intl.formatMessage({ id: "BuyerMyFavouritesPage.schemaDescription" }, { siteTitle });
    const schemaImage = `${config.canonicalRootURL}${facebookImage}`;
    const newSearchProductsPageName = 'NewSearchProductsPage';
    const localeParams = {locale};

    const emptyStateOptions = {
      containerOffsetClassName: "buyersFavoriteOffset",
      iconStylesClassName: "emojiNeutralIcon",
      titleMessageId: "BuyerMyFavouritesPage.noSearchResultTitle",
      textMessageId: "BuyerMyFavouritesPage.noSearchResultText",
      buttonMessageId: "BuyerMyFavouritesPage.buttonText",
      //buttonLinkAddress: "/new/products",
      buttonLinkAddress: pathByRouteName(newSearchProductsPageName, routeConfiguration(), localeParams),
    };

    return (
      <Page
        className={css.root}
        scrollingDisabled={scrollingDisabled}
        contentType="website"
        description={schemaDescription}
        title={schemaTitle}
        robots='noindex'
        facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
        twitterImages={[
          { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
        ]}
        schema={{
          "@context": "http://schema.org",
          "@type": "WebPage",
          description: schemaDescription,
          name: schemaTitle,
          image: [schemaImage],
        }}
      >
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain className={css.flexWrapper}>
            <div className={css.pageContainer}>
              <div className={css.pageHeader}>
                <h1>
                  <FormattedMessage id="BuyerMyFavouritesPage.myFavourites" />
                </h1>
                <h2>
                  <FormattedMessage id="BuyerMyFavouritesPage.saveFavouriteProducts" />
                </h2>
                <h5>{(formattedProducts || []).length}<FormattedMessage id="BuyerMyFavouritesPage.saved" /></h5>
              </div>
              <div className={css.productContainer}>
                <ProductContainer products={products} rowItemCount={3} emptyStateOptions={emptyStateOptions} />
              </div>
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

BuyerMyFavouritesPageComponent.defaultProps = {
  currentUserListing: null,
  currentUserListingFetched: false,
};

BuyerMyFavouritesPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
  // from withRouter
  history: object.isRequired,
  location: object.isRequired,
  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = (state) => {
  //const { supplierRegistrationError, supplierRegistrationInProgress, supplierRegistered } = state.BuyerMyFavouritesPage;

  return {
    scrollingDisabled: isScrollingDisabled(state),
    formattedProducts: state.Favourites.formattedProducts,
  };
};

const mapDispatchToProps = (dispatch) => ({});

const BuyerMyFavouritesPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
)(BuyerMyFavouritesPageComponent);

export default BuyerMyFavouritesPage;