import { storableError } from "../../util/errors";

export const ORDERS_PER_PAGE = 100;
// TODO: Variable for requests page pagination.
// const REQUESTS_PER_PAGE = 10;

// ================ Action types ================ //
export const SUPPLIER_ORDER_REQUESTS_LOAD = "app/Supplier/orders/requests";
export const SUPPLIER_ORDER_REQUESTS_SUCCESS = "app/Supplier/orders/requests/success";
export const SUPPLIER_ORDER_REQUESTS_ERROR = "app/Supplier/orders/requests/error";
export const SUPPLIER_ORDERS_LOAD = "app/Supplier/orders";
export const SUPPLIER_ORDERS_SUCCESS = "app/Supplier/orders/success";
export const SUPPLIER_ORDERS_ERROR = "app/Supplier/orders/error";

// ================ Reducer ================ //
const initialState = {
  orderRequestsLoadError: null,
  orderRequestsLoadInProgress: false,
  orderRequests: null,
  ordersLoadError: null,
  ordersLoadInProgress: false,
  orders: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case SUPPLIER_ORDER_REQUESTS_LOAD:
      return { ...state, orderRequestsLoadError: null, orderRequestsLoadInProgress: true, orderRequests: null };
    case SUPPLIER_ORDER_REQUESTS_SUCCESS:
      return { ...state, orderRequestsLoadInProgress: false, orderRequests: payload };
    case SUPPLIER_ORDER_REQUESTS_ERROR:
      return { ...state, orderRequestsLoadInProgress: false, orderRequestsLoadError: payload };
    case SUPPLIER_ORDERS_LOAD:
      return { ...state, ordersLoadError: null, ordersLoadInProgress: true, orders: null };
    case SUPPLIER_ORDERS_SUCCESS:
      return { ...state, ordersLoadInProgress: false, orders: payload };
    case SUPPLIER_ORDERS_ERROR:
      return { ...state, ordersLoadInProgress: false, ordersLoadError: payload };
    default:
      return state;
  }
}

// ================ Action creators ================ //
export const supplierRequestsLoad = () => ({ type: SUPPLIER_ORDER_REQUESTS_LOAD });
export const supplierRequestsLoadSuccess = (data) => ({ type: SUPPLIER_ORDER_REQUESTS_SUCCESS, payload: data });
export const supplierRequestsLoadError = (error) => ({
  type: SUPPLIER_ORDER_REQUESTS_ERROR,
  payload: error,
  error: true,
});

export const supplierOrdersLoad = () => ({ type: SUPPLIER_ORDERS_LOAD });
export const supplierOrdersLoadSuccess = (data) => ({ type: SUPPLIER_ORDERS_SUCCESS, payload: data });
export const supplierOrdersLoadError = (error) => ({
  type: SUPPLIER_ORDERS_ERROR,
  payload: error,
  error: true,
});

// ================ Thunks ================ //
export const LoadRequests = () => (dispatch, getState, sdk) => {
  dispatch(supplierRequestsLoad());

  return sdk.transactions
    .query({
      only: "sale",
      lastTransitions: ["transition/request-order"],
    })
    .then((res) => {
      dispatch(supplierRequestsLoadSuccess(res.data));
    })
    .catch((e) => {
      dispatch(supplierRequestsLoadError(storableError(storableError(e))));
      // This is thrown so that form can be cleared after a timeout on changePassword submit handler
      throw e;
    });
};

export const LoadOrders = (params) => (dispatch, getState, sdk) => {
  dispatch(supplierOrdersLoad());

  return sdk.transactions
    .query({
      perPage: ORDERS_PER_PAGE,
      page: params?.pageIndex || 1,
      only: "sale",
      lastTransitions: [
        "transition/confirm-order",
        "transition/shipped-order",
        "transition/delivered-order",
        "transition/paid-order",
      ],
    })
    .then((res) => {
      dispatch(supplierOrdersLoadSuccess(res.data));
    })
    .catch((e) => {
      dispatch(supplierOrdersLoadError(storableError(storableError(e))));
      // This is thrown so that form can be cleared
      // after a timeout on changePassword submit handler
      throw e;
    });
};
