import React, { Component } from 'react';
import { bool } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import config from '../../config';
import {
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Page,
  TabNav,
} from '../../components';
import { RegistrationRequestsPage, TopbarContainer } from '../../containers';
import {
  approveRequest,
  deleteRequest,
  loadBuyerRequests,
  loadSupplierRequests,
  rejectRequest
} from './RegistrationModeratorPage.duck';
import { localePart, storedLocale } from '../../components/Wrapper/ClassWrapper';

import facebookImage from '../../assets/VegshelfFacebook-1200x630.png';
import twitterImage from '../../assets/VegshelfTwitter-600x314.png';
import css from './RegistrationModeratorPage.module.css';

export class RegistrationModeratorPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { stepIndex: 0 };
    this.newSupplier = null;
  }

  render() {
    const {
      intl,
      scrollingDisabled,
      suppliersRegistrationRequests,
      buyersRegistrationRequests,
      onApprove,
      onReject,
      onDelete
    } = this.props;
    let { tab } = this.props;
    tab = tab || 'suppliers';
    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage({ id: 'RegistrationModeratorPage.schemaTitle' }, { siteTitle });
    const schemaDescription = intl.formatMessage({ id: 'RegistrationModeratorPage.schemaDescription' }, { siteTitle });
    const schemaImage = `${config.canonicalRootURL}${facebookImage}`;
    const locale = storedLocale() || localePart();
    const pages = {
      'suppliers': (
        <RegistrationRequestsPage
          title="Registration requests"
          requests={suppliersRegistrationRequests}
          onApprove={onApprove}
          onReject={onReject}
          onDelete={onDelete}
        />
      ),
      'buyers': (
        <RegistrationRequestsPage
          title="Registration requests"
          requests={buyersRegistrationRequests}
          onApprove={onApprove}
          onReject={onReject}
          onDelete={onDelete}
        />
      ),
    };
    const tabs = [
      {
        text: (
          <span>
            Suppliers
          </span>
        ),
        selected: tab === 'suppliers',
        linkProps: {
          name: 'SupplierRegistrationModeratorPage',
          params: { locale: locale }
        },
      },
      {
        text: (
          <span>
            Buyers
          </span>
        ),
        selected: tab === 'buyers',
        linkProps: {
          name: 'BuyerRegistrationModeratorPage',
          params: { locale: locale }
        },
      }
    ];

    return (
      <Page
        className={css.root}
        scrollingDisabled={scrollingDisabled}
        contentType="website"
        description={schemaDescription}
        title={schemaTitle}
        robots='noindex'
        facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
        twitterImages={[
          { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
        ]}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'WebPage',
          description: schemaDescription,
          name: schemaTitle,
          image: [schemaImage],
        }}
      >
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div className={css.registrationsPageContainer}>
              <div className={css.registrationSection}>
                <TabNav
                  rootClassName={css.tabs}
                  tabRootClassName={css.tab}
                  tabs={tabs} />
                <div className={css.pageContainer}>
                  {pages[tab]}
                </div>
              </div>
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

RegistrationModeratorPageComponent.defaultProps = {
  currentUserListing: null,
  currentUserListingFetched: false,
};

RegistrationModeratorPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  // from injectIntl
  intl: intlShape.isRequired,
};
const mapStateToProps = (state) => {
  return {
    scrollingDisabled: isScrollingDisabled(state),
    ...state.RegistrationModeratorPage
  };
};

const mapDispatchToProps = (dispatch) => ({
  onApprove: (key) => dispatch(approveRequest(key)),
  onReject: (key, comment) => dispatch(rejectRequest(key, comment)),
  onDelete: (key) => dispatch(deleteRequest(key))
});

const RegistrationModeratorPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(RegistrationModeratorPageComponent);

RegistrationModeratorPage.loadDataForSuppliers = () => (dispatch) => {
  return Promise.all([dispatch(loadSupplierRequests())]);
};

RegistrationModeratorPage.loadDataForBuyers = () => (dispatch) => {
  return Promise.all([dispatch(loadBuyerRequests())]);
};

export default RegistrationModeratorPage;
