import { bool, object } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import facebookImage from "../../assets/VegshelfFacebook-1200x630.png";
import twitterImage from "../../assets/VegshelfTwitter-600x314.png";
import {
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  NamedLink,
  Page,
  ShortProductViewItem,
} from "../../components";
import config from "../../config";
import { TopbarContainer } from "../../containers";
import { isScrollingDisabled } from "../../ducks/UI.duck";
import { FormattedMessage, injectIntl, intlShape } from "../../util/reactIntl";
import { propTypes } from "../../util/types";
import { loadSections } from "./NewSearchProductsPage.duck";
import { slugify } from "../../util/urlTrimming";
import css from "./NewSearchProductsPage.module.css";
import arrowLeft from '../../assets/arrow-left-000000.svg';
import arrowRight from '../../assets/arrow-right-000000.svg';
import classNames from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';
import LoaderComponent from "../../components/Loader/Loader";
import { localePart, storedLocale } from "../../components/Wrapper/ClassWrapper";

const locale = storedLocale() || localePart();

export class NewSearchProductsPageComponent extends Component {
  constructor(props) {
    super(props);
    this.scrollBoxes = {};
    this.onScrollToLeft = this.onScrollToLeft.bind(this);
    this.onScrollToRight = this.onScrollToRight.bind(this);
  }

  scrollToLeft(node, offset, step, stepTime) {
    node.scrollLeft -= step;
    offset -= step;
    if (offset > 0) {
      setTimeout(() => this.scrollToLeft(node, offset, step, stepTime), stepTime);
    }
  }

  scrollToRight(node, offset, step, stepTime) {
    node.scrollLeft += step;
    offset -= step;
    if (offset > 0) {
      setTimeout(() => this.scrollToRight(node, offset, step, stepTime), stepTime);
    }
  }

  onScrollToLeft(key) {
    this.scrollToLeft(this.scrollBoxes[key], 295, 30, 30);
  }

  onScrollToRight(key) {
    this.scrollToRight(this.scrollBoxes[key], 295, 30, 30);
  }

  render() {
    const {
      intl,
      scrollingDisabled,
      categorySections,
      isAuthenticated,
      currentUserType,
      supplierCurrentCompany,
      categorySectionsInProgress,
    } = this.props;

    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage({ id: "NewSearchProductsPage.schemaTitle" }, { siteTitle });
    const schemaDescription = intl.formatMessage({ id: "NewSearchProductsPage.schemaDescription" }, { siteTitle });
    const schemaImage = `${config.canonicalRootURL}${facebookImage}`;
    const viewSections = categorySections || [];
    viewSections.sort((f, s) => {
      if (f.order < s.order) return -1;
      if (f.order > s.order) return 1;
      return 0;
    });

    return (
      <Page
        className={css.root}
        scrollingDisabled={scrollingDisabled}
        contentType="website"
        description={schemaDescription}
        title={schemaTitle}
        robots='noindex'
        facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
        twitterImages={[
          { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
        ]}
        schema={{
          "@context": "http://schema.org",
          "@type": "WebPage",
          description: schemaDescription,
          name: schemaTitle,
          image: [schemaImage],
        }}
      >
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer productCategoryCode={"new"} />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain>
            {categorySectionsInProgress ?
              <div className={css.loaderContainer}>
                <LoaderComponent />
              </div>
              :
              <div className={css.pageContainer}>
                <div className={css.pageHeader}>
                  <h1 className={css.pageTitle}>
                    <FormattedMessage id="NewProductPage.pageTitle1" />
                    <i><FormattedMessage id="NewProductPage.pageTitle2" /></i>
                    <FormattedMessage id="NewProductPage.pageTitle3" />
                  </h1>
                </div>
                <div className={css.pageContent}>
                  <div className={css.sectionContainer}>
                    {viewSections.map((s) => {
                      const viewProducts = s.products || [];
                      const isOwnSupplierCompany = s.brandId === supplierCurrentCompany?.id?.uuid;

                      return (
                        <div key={s.key + "_sectionItem"} className={css.sectionItem}>
                          <div className={css.sectionLogoBlock} style={{ backgroundColor: s.color }}>
                            <div className={css.sectionNameAndImageContainer}>
                              <div className={css.sectionName}>{s.title}</div>
                              <div className={css.sectionLogo} style={{ backgroundImage: `url('${s.brandMainImage}')` }}>&nbsp;</div>
                            </div>
                            <div className={css.sectionBrandAndProductsBlock}>
                              <div className={css.sectionBrandBlock}>
                                {isOwnSupplierCompany ?
                                  <NamedLink name="SupplierProfilePage" className={css.noUnderline}>
                                    <div className={css.brandLogo} style={{ backgroundImage: `url('${s.brandLogo}')` }}>
                                      {!s.brandLogo && s.brandName.charAt(0)}
                                    </div>
                                  </NamedLink>
                                  :
                                  <NamedLink name="SupplierDetailsPage" params={{ brand: slugify(s.brandName), id: s.brandId, locale: locale }} className={css.noUnderline}>
                                    <div className={css.brandLogo} style={{ backgroundImage: `url('${s.brandLogo}')` }}>
                                      {!s.brandLogo && s.brandName.charAt(0)}
                                    </div>
                                  </NamedLink>
                                }
                                <div className={css.brandInfo}>
                                  {isOwnSupplierCompany ?
                                    <NamedLink name="SupplierProfilePage">
                                      <h1 className={css.brandName}>{s.brandName}</h1>
                                    </NamedLink>
                                    :
                                    <NamedLink name="SupplierDetailsPage" params={{ brand: slugify(s.brandName), id: s.brandId, locale: locale }}>
                                      <h1 className={css.brandName}>{s.brandName}</h1>
                                    </NamedLink>
                                  }
                                  <h2 className={css.brandAddress}>
                                    {s.brandCity}, {s.brandCountry ? `${s.brandCountry} ` : null}
                                  </h2>
                                </div>
                              </div>
                              <div className={css.productsBlock}>
                                {viewProducts.length ?
                                  <Swiper
                                    simulateTouch={false}
                                    spaceBetween={0}
                                    slidesPerView="auto"
                                    setWrapperSize
                                    navigation={{
                                      nextEl: '.swiper-button-next',
                                      prevEl: '.swiper-button-prev',
                                      disabledClass: css.hiddenArrowBtn,
                                    }}
                                    speed={500}
                                    watchOverflow
                                  >
                                    <div className={classNames(css.resourceLeftButton, 'swiper-button-prev')}>
                                      <img alt="arrowLeft" src={arrowLeft} />
                                    </div>
                                    {viewProducts.map((p, i) => (
                                      <SwiperSlide key={i} className={css.resourceItem}>
                                        <ShortProductViewItem key={p.id.uuid} productItem={p} />
                                      </SwiperSlide>
                                    ))}
                                    <div className={classNames(css.resourceRightButton, 'swiper-button-next')}>
                                      <img alt="arrowRight" src={arrowRight} />
                                    </div>
                                  </Swiper>
                                  : null
                                }
                              </div>
                              <div className={css.sectionButtons}>
                                {isAuthenticated && currentUserType === "buyer" && s.brandId ?
                                  <NamedLink
                                    name="BuyerSendMessagePage"
                                    className={css.buttonStyle}
                                    params={{ receiver: s.brandId, title: "Question", locale: locale }}>
                                    <FormattedMessage id="NewProductPage.messageBrandButton" />
                                  </NamedLink>
                                  : null}
                                {s.brandId ? (
                                  <NamedLink
                                    name="SupplierDetailsPage"
                                    params={{ brand: slugify(s.brandName), id: s.brandId, locale: locale }}
                                    className={css.buttonStyle}>
                                    <FormattedMessage id="NewProductPage.companyPageButton" />
                                  </NamedLink>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            }
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

NewSearchProductsPageComponent.defaultProps = {
  currentUserListing: null,
  currentUserListingFetched: false,
};

NewSearchProductsPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
  // from withRouter
  history: object.isRequired,
  location: object.isRequired,
  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = (state) => {
  const { isAuthenticated } = state.Auth;
  const { currentUserType } = state.user;
  const { supplierCurrentCompany } = state.supplierCompany;

  return {
    scrollingDisabled: isScrollingDisabled(state),
    ...state.NewSearchProductsPage,
    isAuthenticated,
    currentUserType,
    supplierCurrentCompany,
  };
};

const mapDispatchToProps = (/*dispatch*/) => ({
  // onSearchProducts: values => dispatch(searchProducts(values)),
});

const NewSearchProductsPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
)(NewSearchProductsPageComponent);

NewSearchProductsPage.loadData = () => (dispatch/*, getState, sdk*/) => {
  return Promise.all([dispatch(loadSections())]);
};

export default NewSearchProductsPage;