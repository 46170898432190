import { format } from "date-fns";

const FONOA_API_KEY = process.env.REACT_APP_FONOA_API_KEY;

export const fonoaInitialGetOptions = {
  method: 'GET',
  headers: {
    Accept: 'application/json',
    'Ocp-Apim-Subscription-Key': FONOA_API_KEY,
  }
};

export const fonoaInitialPostOptions = {
  method: 'POST',
  headers: {
    Accept: 'application/json',
    'Ocp-Apim-Subscription-Key': FONOA_API_KEY,
    'Content-Type': 'application/json',
  },
};

//-------- Invoicing --------//

// Fonoa testing invoicing endpoint
//const invoicesRootEndpoint = "https://api-demo.fonoa.com/Invoicing/v1/Invoices";

// Fonoa production invoicing endpoint
const invoicesRootEndpoint = "https://api.fonoa.com/Invoicing/v1/Invoices";

export const fakeInvoiceBody = {
  meta: {
    language_code: 'en',
    // Germany for the beginning
    country_code: 'de',
    business_model: 'B2B',
    tax_type: 'VAT',
    currency_code: "EUR",
    invoice_type: 'debit',
    invoice_number: 0,
    pdf_response: true,
    html_response: false,
    logo: "https://storage.googleapis.com/vegshelf-logo/Vegshelf2021-black-01.png"
  },
  issuer: {
    // TODO: Vegshelf admin account id
    external_uid: process.env.REACT_APP_FONOA_API_VEGSHELF_ADMIN_KEY,
    company_name: 'Vegshelf UG (haftungsbeschränkt)',
    address: 'Company ID: Amtsgericht Düsseldorf, HRB 88669 / Leopoldstraße 49, 40211 Düsseldorf, NRW',
    country: 'Germany',
    tin: 'DE328398511',
    pin: 'Amtsgericht Düsseldorf, HRB 88669'
  },
  supplier: {
    company_name: 'Supplier Company’s Legal Name',
    address: 'Test street 27, 34122 Hamburg',
    country: 'Germany',
    company_in_vat_regime: true,
    pin: 'Amtsgericht Düsseldorf HRB 12345',
    tin: 'BE12345678',
    trade_name: 'Supplier Brand Name',
  },
  customer: {
    company_name: 'Buyer Company’s Legal Name',
    address: 'Test street 49 40211 Berlin',
    country: 'Belgium',
    pin: 'Amtsgericht Düsseldorf HRB 88669 ',
    tin: 'DE12345678',
    trade_name: 'Buyer Store Name',
  },
  invoice: {
    delivery_date: '2021-02-01T11:53:57+00:00',
    payment_type: 'CreditCard',
    items: [
      {
        item_number: 1,
        product_name: 'Test Product 1, 200g',
        quantity: 1,
        unit_of_measure: 'units',
        unit_price: 100,
        tax_rate: 21,
        net_price: 100,
      },
    ],
    total_net_amount: 100,
    discount: 0,
    total_tax_amount: 21,
    total_amount: 121,
    note: ""
  }
};

export const createInvoice = async ({ body }) => {
  const options = {
    ...fonoaInitialPostOptions,
    body: JSON.stringify(body)
  };

  return fetch(invoicesRootEndpoint, options)
    .then((response) => response.json())
    .then((response) => response)
    .catch((err) => err);
};

export const getAllInvoices = async ({ customer_external_uid, supplier_external_uid, currentPage = 1 }) => {
  // const PAGE_SIZE = 25;
  const PAGE_SIZE = 5;
  const staticSortingParameters = "&ordering_field=created&ordering_direction=desc";
  const paginationParameters = `page_size=${PAGE_SIZE}&page=${currentPage}`;
  const filterParameters = supplier_external_uid ?
    `&supplier_external_uid=${supplier_external_uid}` : customer_external_uid ?
    `&customer_external_uid=${customer_external_uid}` : null;
  const finalURL = `${invoicesRootEndpoint}?${paginationParameters}${filterParameters}${staticSortingParameters}`;

  // Will be displayed whole list of invoices. Should be available just for Admin.
  if (!filterParameters) return;

  return fetch(finalURL, fonoaInitialGetOptions)
    .then((response) => response.json())
    .then((response) => response)
    .catch((err) => err);
};

export const getInvoice = async (args) => {
  const { url } = args;

  return fetch(url, fonoaInitialGetOptions)
    .then((response) => response.json())
    .then((response) => response);
};

//-------- Onboarding Companies --------//

// Fonoa testing company onboarding endpoint
//const onboardingCompanyRootEndpoint = "https://api-demo.fonoa.com/Onboarding/v1/Companies";

// Fonoa production company onboarding endpoint
const onboardingCompanyRootEndpoint = "https://api.fonoa.com/Onboarding/v1/Companies";

export const fakeOnboardCompanyBody = {
  meta: { country_code: 'de', language_code: 'en' },
  general: {
    company_name: 'Vitalii Sokolov Inc.',
    company_number: 'de123456789',
    email: 'office@companyx.de',
    website: 'www.companyx.de',
    representative_name: 'VS',
    representative_email: 'klaus@companyx.de'
  },
  specific: { any_country_specific_field: '1' },
  location: {
    country_code: 'de',
    province: 'Bavaria',
    city: 'Munich',
    postal_code: '80331',
    street: 'Street in Munich',
    number: '12'
  },
  tax_info: { tin: 'de987654321', vat_number: 'de123456789' }
};

export const onboardCompany = ({ body }) => {
  const options = {
    ...fonoaInitialPostOptions,
    body: JSON.stringify(body)
  };

  return fetch(onboardingCompanyRootEndpoint, options)
    .then((response) => response.json())
    .then((response) => response)
    .catch((err) => err);
};

export const getCompany = ({ url }) => {
  return fetch(url, fonoaInitialGetOptions)
    .then(response => response.json())
    .then(response => console.log(response))
    .catch(err => console.error(err));
};

//-------- Onboarding Individuals --------//

// Fonoa testing individual onboarding endpoint
//const onboardingIndividualRootEndpoint = "https://api-demo.fonoa.com/Onboarding/v1/Individuals";

// Fonoa production individual onboarding endpoint
const onboardingIndividualRootEndpoint = "https://api.fonoa.com/Onboarding/v1/Individuals";

export const fakeOnboardIndividualBody = {
  meta: {
    country_code: 'de',
    language_code: 'de',
    is_operator: 'false',
  },
  general: {
    full_name: 'Klaus Bernard',
    email: 'klaus@companyx.de',
    pin: '001122334',
  },
  specific: {
    any_country_specific_field: '1',
  },
  location: {
    country_code: 'de',
    province: 'Bavaria',
    city: 'Munich',
    postal_code: '80331',
    street: 'Street in Munich',
    number: '12'
  },
  tax_info: {
    tin: 'de123456789',
  },
};

export const onboardIndividual = ({ body }) => {
  const options = {
    ...fonoaInitialPostOptions,
    body: JSON.stringify(body)
  };

  return fetch(onboardingIndividualRootEndpoint, options)
    .then((response) => response.json())
    .then((response) => response)
    .catch((err) => err);
};

export const getIndividual = ({ url }) => {
  return fetch(url, fonoaInitialGetOptions)
    .then(response => response.json())
    .then(response => console.log(response))
    .catch(err => console.error(err));
};

export async function getPDF(url) {
  try {
    const result = await getInvoice({ url });

    if (!result.base64_pdf) {
      console.error("base64_pdf is undefined");
    }

    return result.base64_pdf;
  } catch (e) {
    console.error(e);
  }
}

export async function openPDF(args) {
  try {
    const { url, pdfTitle } = args;

    const base64_pdf = await getPDF(url);

    let pdfWindow = window.open("");
    pdfWindow.document.write(
      "<iframe width='100%' height='100%' src='data:application/pdf;base64, " + encodeURI(base64_pdf) + "'></iframe>"
    );
    pdfWindow.document.title = pdfTitle;
  } catch (e) {
    console.error(e);
  }
}

export async function downloadPDF(args) {
  try {
    const { url, pdfTitle } = args;

    const base64_pdf = await getPDF(url);

    const linkSource = `data:application/pdf;base64,${base64_pdf}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = pdfTitle;
    downloadLink.click();
  } catch (e) {
    console.error(e);
  }
}
