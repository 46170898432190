import { bool, string } from "prop-types";
import React from "react";
import { compose } from "redux";
import { injectIntl, intlShape } from "../../util/reactIntl";
import { NamedLink } from "../../components";
import css from "./DeleteAccountPage.module.css";

const InlineLinkComponent = ({ href, text, withDot }) => (
  <span>
    <a href={href} target="_blank" className={`${css.descriptionText} ${css.inlineLink}`}>
      {text}{withDot && "."}
    </a>
  </span>
);

InlineLinkComponent.propTypes = {
  href: string.isRequired,
  text: string.isRequired,
  withDot: bool,
};

export const DeleteAccountPageComponent = ({ intl }) => {
  const title = intl.formatMessage({ id: 'DeleteAccountPage.title' });
  const email = intl.formatMessage({ id: 'DeleteAccountPage.email' });
  const emailDescription = intl.formatMessage({ id: 'DeleteAccountPage.emailDescription' });
  const mainDescription = intl.formatMessage({ id: 'DeleteAccountPage.mainDescription' });
  const termsDescription = intl.formatMessage({ id: 'DeleteAccountPage.termsDescription' });
  const buyerTerms = intl.formatMessage({ id: 'DeleteAccountPage.buyerTermsLink' });
  const andText = intl.formatMessage({ id: 'DeleteAccountPage.andText' });
  const supplierTerms = intl.formatMessage({ id: 'DeleteAccountPage.supplierTermsLink' });
  const termsText = intl.formatMessage({ id: 'DeleteAccountPage.termsText' });
  const buttonText = intl.formatMessage({ id: 'DeleteAccountPage.buttonText' });

  return (
    <div className={css.background}>
      <h2 className={css.title}>{title}</h2>
      <p className={`${css.descriptionText} ${css.mailDescription}`}>
        {emailDescription}
        <InlineLinkComponent href={`mailto:${email}`} text={email} withDot />
      </p>
      <p className={`${css.descriptionText} ${css.mainDescription}`}>
        {mainDescription}
      </p>
      <p className={`${css.descriptionText} ${css.termsDescription}`}>
        {termsDescription}
        <NamedLink name="BuyerTermsPage" className={css.inlineLink} >
          {buyerTerms}
        </NamedLink>
        {andText}
        <NamedLink name="SupplierTermsPage" className={css.inlineLink} >
          {supplierTerms}
        </NamedLink>
        {termsText}
      </p>
      <div className={css.deleteButton}>
        <a className={css.deleteButtonText} href={`mailto:${email}`} target="_blank">{buttonText}</a>
      </div>
    </div>
  );
};

DeleteAccountPageComponent.propTypes = {
  intl: intlShape.isRequired,
};

const DeleteAccountPage = compose(injectIntl)(DeleteAccountPageComponent);

DeleteAccountPage.displayName = 'DeleteAccountPage';

export default DeleteAccountPage;
