import React, { Component } from 'react';
import { bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { propTypes } from '../../util/types';

import css from './CookiePolicyPage.module.css';

export class CookiePolicyPageComponent extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {
            history,
            intl
        } = this.props;
        return (
            <div className={css.vegshelfInformationContainer}>
                <div className={css.vegshelfInformationBox1}>
                    <div className={css.cookiePolicyButtonContainer}>
                        <button
                            className={css.cookiePolicyButton}
                            onClick={() => window.location.reload(false)}>
                            <FormattedMessage id="CookiePolicyPage.reloadPageButton" />
                        </button>
                    </div>
                    <script
                        id="CookieDeclaration"
                        src="https://consent.cookiebot.com/c20fb1a2-c80f-4c42-9b82-70aa266ea7a9/cd.js"
                        type="text/javascript"
                        async
                        runScripts>
                    </script>
                </div>
            </div>
        );
    }
};

CookiePolicyPageComponent.defaultProps = {
    currentUserListing: null,
    currentUserListingFetched: false,
};

CookiePolicyPageComponent.propTypes = {
    scrollingDisabled: bool.isRequired,
    currentUserListing: propTypes.ownListing,
    currentUserListingFetched: bool,

    // from withRouter
    history: object.isRequired,
    location: object.isRequired,

    // from injectIntl
    intl: intlShape.isRequired,
};
const mapStateToProps = state => {
    const { currentUser } = state.user;
    return {
        scrollingDisabled: isScrollingDisabled(state),
        currentUser
    };
};

const CookiePolicyPage = compose(
    withRouter,
    connect(mapStateToProps),
    injectIntl
)(CookiePolicyPageComponent);

export default CookiePolicyPage;