import React from 'react';
const RegistrationRequestSent = props => {
    const { name } = props;
    return (<div>
       Hey {name},
       <br/>
       <br/>
       Thank you for registering on Vegshelf wholesale marketplace!
       <br/>
       Our team will now check your submission and get back to you within 2-3 working days regarding the next steps of your onboarding.
       <br/>
       <br/>
       Meanwhile, let's hang out on social - <a href="https://www.instagram.com/vegshelf/">Instagram</a> 👋 or <a href="https://www.linkedin.com/company/vegshelf/">Linkedin</a> 💼
       <br/>
       <br/>
       Kind regards,
       <br/>
       Baiba Soika
       <br/>
       CEO and Co-founder
    </div>);
};
export default RegistrationRequestSent;