import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { NamedLink } from '../../components';
import { displayRegistrationConfirmDialog } from '../../ducks/Modal.duck';
import { compose } from 'redux';
import { connect } from 'react-redux';

import css from './SectionGrowWholesaleBusiness.module.css';
import { divide } from 'lodash';

const SectionGrowWholesaleBusinessComponent = props => {
  const { rootClassName, className, onDisplayRegistrationConfirmDialog } = props;

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      <div className={css.growWholesaleBusinessSection}>
        <h3 className={css.sectionTitle}>
          <FormattedMessage id="SectionGrowWholesaleBusiness.sectionTitle" />
        </h3>
        <div>
          <div className={css.inlineContainer}>
            <div className={css.step}>
              <div className={css.blockNumber}>1
              </div>
              <p className={css.blockText}>
                <FormattedMessage id="SectionGrowWholesaleBusiness.part1Text" />
              </p>
            </div>
            <div className={css.step}>
              <div className={css.blockNumber}>2
              </div>
              <p className={css.blockText}>
                <FormattedMessage id="SectionGrowWholesaleBusiness.part2Text" />
              </p>
            </div>
            <div className={css.step}>
              <div className={css.blockNumber}>3
              </div>
              <p className={css.blockText}>
                <FormattedMessage id="SectionGrowWholesaleBusiness.part3Text" />
              </p>
            </div>
          </div>
          <div className={css.signUpSection}>
            <div className={css.buttonSection}>
              <a onClick={() => onDisplayRegistrationConfirmDialog()} className={css.signUpButton} >
                <FormattedMessage id="SectionGrowWholesaleBusiness.signupButton" />
              </a>
            </div>
            <p className={css.moreInfo}>
              <FormattedMessage id="SectionGrowWholesaleBusiness.moreInfo" /> <NamedLink className={css.loginLink} name="ApplyToSellPage"><FormattedMessage id="SectionGrowWholesaleBusiness.signUpLink" /></NamedLink>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

SectionGrowWholesaleBusinessComponent.defaultProps = {
  rootClassName: null,
  className: null,
};

SectionGrowWholesaleBusinessComponent.propTypes = {
  rootClassName: string,
  className: string,
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  return {
    isAuthenticated
  };
};
const mapDispatchToProps = dispatch => ({
  onDisplayRegistrationConfirmDialog: (isBuyer) => dispatch(displayRegistrationConfirmDialog(true, true)),
});

const SectionGrowWholesaleBusiness = compose(
  connect(mapStateToProps, mapDispatchToProps),
)(SectionGrowWholesaleBusinessComponent);

export default SectionGrowWholesaleBusiness;