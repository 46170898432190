import React, { Component } from 'react';
import { bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import {
  buyerCreateCompany,
  buyerDeleteCompanyCover,
  buyerDeleteCompanyImage,
  buyerUpdateCompany,
  buyerUpdateCompanyCover,
  buyerUpdateCompanyImage,
  buyerUpdateCompanyLogo
} from '../../ducks/buyer-company.duck';
import { propTypes } from '../../util/types';
import config from '../../config';
import {
  Footer,
  InlineTextButton,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Menu,
  MenuContent,
  MenuItem,
  MenuLabel,
  Page
} from '../../components';
import { TopbarContainer } from '../../containers';
import { BuyerCompanyForm } from '../../forms';
import { getBuyerImportants } from '../../translations/numberOfStores';
import facebookImage from '../../assets/VegshelfFacebook-1200x630.png';
import twitterImage from '../../assets/VegshelfTwitter-600x314.png';
import css from './BuyerProfilePage.module.css';
import classNames from 'classnames';

export class BuyerProfilePageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { stepIndex: 0, displayUpdateForm: false };
    this.coverFileSelector = React.createRef();
    this.avatarFileSelector = React.createRef();
    this.storeImageSelector = {};
    this.onCoverChange = this.onCoverChange.bind(this);
    this.onAvatarChange = this.onAvatarChange.bind(this);
    this.onDeleteCoverImage = this.onDeleteCoverImage.bind(this);
    this.onStoreImageChange = this.onStoreImageChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onDeleteImage = this.onDeleteImage.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.buyerUpdateCompanyInProgress !== this.props.buyerUpdateCompanyInProgress && nextProps.buyerUpdateCompany
    ) {
      this.setState({ displayUpdateForm: false });
    }
  }

  onSubmit(values) {
    this.props.onBuyerUpdateCompany({ ...values });
  }

  onCoverChange(event) {
    event.stopPropagation();
    event.preventDefault();
    const file = event.target.files[0];
    if (!file) {
      return;
    }
    const tempId = `${file.name}_${Date.now()}`;
    this.props.onBuyerUpdateCompanyCover({ id: tempId, file });
  }

  onStoreImageChange(event, imageKey, isMain) {
    event.stopPropagation();
    event.preventDefault();
    const file = event.target.files[0];
    if (!file) {
      return;
    }
    this.props.onBuyerUpdateCompanyImage({ id: imageKey, file, isMain });
  }

  onAvatarChange(event) {
    event.stopPropagation();
    event.preventDefault();
    const file = event.target.files[0];
    if (!file) {
      return;
    }
    const tempId = `${file.name}_${Date.now()}`;
    this.props.onBuyerLogo({ id: tempId, file });
  }

  onDeleteCoverImage(event) {
    event.stopPropagation();
    event.preventDefault();
    this.props.onBuyerDeleteCompanyCover();
  }

  onDeleteImage(event, idx) {
    event.stopPropagation();
    event.preventDefault();
    this.props.onBuyerDeleteCompanyImage(idx);
  }

  render() {
    const { intl, scrollingDisabled, buyerCurrentCompany, } = this.props;
    const siteTitle = config.siteTitle;
    const companyData = buyerCurrentCompany?.attributes;
    const companyDetails = companyData?.publicData;
    const storeImages = companyDetails?.storeImages ? [...companyDetails?.storeImages] : [];
    let hasFirst = !!storeImages[0]?.isMain;
    for (let i = storeImages.length - 1; i < 2; i++) {
      if (hasFirst) {
        storeImages.push({ id: 0 - i });
      } else {
        storeImages.splice(0, 0, { id: 0 - i });
        hasFirst = true;
      }
    }
    const images = storeImages.map((img, idx) => {
      const coverStyles = {};
      let textId = '';
      let classIcon = '';
      if (img?.url) {
        coverStyles.backgroundImage = `url('${img?.url}')`;
        textId = 'BuyerProfilePage.changeImageButtonLabel';
        classIcon = css.changeImageButtonIcon;
      } else {
        coverStyles.backgroundColor = `#f8f8e8`;
        textId = 'BuyerProfilePage.addImageButtonLabel';
        classIcon = css.addImageButtonIcon;
      }
      return (
        <div
          className={classNames(css.imageItem, { [css.mainShopImage]: idx === 0, [css.secondShopImage]: idx > 0 })}
          style={coverStyles} key={img.id}
        >
          <input
            id="storeImageFileSelector"
            type="file"
            ref={(ref) => this.storeImageSelector[img.id] = ref}
            style={{ display: 'none' }}
            onChange={(e) => this.onStoreImageChange(e, img.id, idx === 0)}
            accept="image/*"
          />
          {!img?.url ? (
            <a
              className={css.addImageButton}
              onClick={() => {
                this.storeImageSelector[img.id].click();
                return false
              }}
            >
              <div className={classIcon}>add</div>
              <FormattedMessage className={css.addImageButtonLabel} id={textId} />
            </a>
          ) : null}
          {img?.url && (
            <div className={css.editImageMenu}>
              <Menu>
                <MenuLabel className={css.coverMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
                  <a className={css.editCoverLink}>
                    <div className={css.updateImageButton}>Change</div>
                  </a>
                </MenuLabel>
                <MenuContent className={css.imageMenuContent}>
                  <MenuItem key="selectCoverImage">
                    <InlineTextButton
                      rootClassName={css.menuItemFirst}
                      onClick={() => {
                        this.storeImageSelector[img.id].click();
                        return false
                      }}
                    >
                      <FormattedMessage id="BuyerProfilePage.changeImageMenuItem" />
                    </InlineTextButton>
                  </MenuItem>
                  <MenuItem key="DeleteCoverImage">
                    <InlineTextButton
                      rootClassName={css.menuItemSecond}
                      onClick={(e) => {
                        this.onDeleteImage(e, img.id);
                      }}
                    >
                      <FormattedMessage id="UserFields.coverPhotoDelete" />
                    </InlineTextButton>
                  </MenuItem>
                </MenuContent>
              </Menu>
            </div>
          )}
        </div>
      );
    });
    const schemaTitle = intl.formatMessage({ id: 'BuyerProfilePage.schemaTitle' }, { siteTitle });
    const schemaDescription = intl.formatMessage({ id: 'BuyerProfilePage.schemaDescription' }, { siteTitle });
    const schemaImage = `${config.canonicalRootURL}${facebookImage}`;
    const coverStyles = {};
    const companyLogo = {};
    const hasLogo = !!companyDetails?.logoUrl;
    if (companyDetails?.coverUrl) {
      coverStyles.backgroundImage = `url('${companyDetails?.coverUrl}')`;
    } else {
      coverStyles.backgroundColor = `#f8f8e8`;
    }
    if (companyDetails?.logoUrl) {
      companyLogo.backgroundImage = `url('${companyDetails?.logoUrl}')`;
    } else {
      companyLogo.backgroundColor = `#ffffff`;
    }

    const dictionaries = getBuyerImportants();
    const properties = companyDetails?.buyerImportants ? dictionaries.filter(
      l => companyDetails?.buyerImportants.indexOf(l.code) > -1) : [];

    return (
      <Page
        className={css.root}
        scrollingDisabled={scrollingDisabled}
        contentType="website"
        description={schemaDescription}
        title={schemaTitle}
        robots='noindex'
        facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
        twitterImages={[
          { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
        ]}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'WebPage',
          description: schemaDescription,
          name: schemaTitle,
          image: [schemaImage],
        }}
      >
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div className={css.profileCompanyContainer}>
              <input id="coverFileSelector"
                type="file"
                ref={(ref) => this.coverFileSelector = ref}
                style={{ display: 'none' }}
                onChange={this.onCoverChange}
                accept="image/*"
              />
              <input id="avatarFileSelector"
                type="file"
                ref={(ref) => this.avatarFileSelector = ref}
                style={{ display: 'none' }}
                onChange={this.onAvatarChange}
                accept="image/*"
              />
              <div className={css.coverContainer}>
                <div className={css.coverPhotoContainer} style={coverStyles}>
                </div>
              </div>
              <div className={css.editingContainer}>
                <div className={css.editCoverPanel}>
                  <Menu>
                    <MenuLabel className={css.coverMenuLabel}
                      isOpenClassName={css.profileMenuIsOpen}>
                      <a className={css.editCoverLink}>
                        <div className={css.updateImageButton}>Change</div>
                      </a>
                    </MenuLabel>
                    <MenuContent className={css.profileMenuContent}>
                      <MenuItem key="selectCoverImage">
                        <InlineTextButton
                          rootClassName={css.menuItemFirst}
                          onClick={() => {
                            this.coverFileSelector.click();
                          }}
                        >
                          <FormattedMessage id="UserFields.coverPhotoUpload" />
                          <div className={css.menuItemFirstHelperText}>
                            <FormattedMessage id="BuyerProfilePage.changeCoverImageInfo" />
                          </div>
                        </InlineTextButton>
                      </MenuItem>
                      <MenuItem key="DeleteCoverImage">
                        <InlineTextButton
                          rootClassName={css.menuItemSecond}
                          onClick={(e) => {
                            this.onDeleteCoverImage(e);
                          }}
                        >
                          <FormattedMessage id="UserFields.coverPhotoDelete" />
                        </InlineTextButton>
                      </MenuItem>
                    </MenuContent>
                  </Menu>
                </div>
              </div>
              <div className={css.profileCompanySection}>
                <div className={css.companyInfoPanel}>
                  <div className={css.companyInfoFirstColumn}>
                    <div className={css.companyLogo} style={companyLogo}>
                      <div className={classNames(css.editCompanyLogoPanel, { [css.hasLogo]: hasLogo })}>
                        <a
                          className={classNames(css.editCompanyLogoLink, { [css.hasLogo]: hasLogo })}
                          onClick={() => {
                            this.avatarFileSelector.click();
                          }}
                        >
                          <div className={classNames(css.updateImageButtonPlain, { [css.hasLogo]: hasLogo })}>Change</div>
                          <FormattedMessage id="UserFields.logoUpload" />
                        </a>
                      </div>
                    </div>
                    <div className={css.companyName}>{companyDetails?.brandName}</div>
                    <div className={css.companyLocation}>{companyDetails?.city}, {companyDetails?.country}</div>
                    <div className={css.companyPropertyRowContainer}>
                      <div className={css.companyPropertyRow}>
                        <span className={css.companyFieldLabel}>
                          <FormattedMessage id="UserFields.typeOfStoreColon" />
                        </span>
                        <span className={css.companyFieldValue}>{companyDetails?.typeOfBusiness}</span>
                      </div>
                      <div className={css.companyPropertyRow}>
                        <span className={css.companyFieldLabel}>
                          <FormattedMessage id="UserFields.numberOfStoresColon" />
                        </span>
                        <span className={css.companyFieldValue}>{companyDetails?.numberOfStores}</span>
                      </div>
                      <div className={css.companyPropertyRow}>
                        <span className={css.companyFieldLabel}>
                          <FormattedMessage id="UserFields.websiteColon" />
                        </span>
                        <a target="_blank" href={companyDetails?.website}>
                          <span className={css.companyFieldValueWebsite}>{companyDetails?.website}</span>
                        </a>
                      </div>
                      <div className={css.companyPropertyRow}>
                        <span className={css.companyFieldLabel}>
                          <FormattedMessage id="UserFields.yearEstablishedColon" />
                        </span>
                        <span className={css.companyFieldValue}>{companyDetails?.establishedIn}</span>
                      </div>
                    </div>
                  </div>
                  <div className={css.companyInfoSecondColumn}>
                    <div className={css.editCompanyInfo}>
                      <a className={css.editCompanyLink} onClick={() => this.setState({ displayUpdateForm: true })}>
                        <div className={css.editCompanyButton}>Change</div>
                        <FormattedMessage id="UserFields.editCompanyInfo" />
                      </a>
                    </div>
                    <div className={css.companyDescriptionLabel}>
                      <FormattedMessage id="BuyerProfilePage.LabelCompanyDescription" />
                    </div>
                    <div className={css.companyDescriptionValue}>
                      {companyDetails?.description || (<span className={css.emptyDescription}>
                        <FormattedMessage id="BuyerProfilePage.EmptyLabelCompanyDescription" /></span>)}
                    </div>
                    <div className={css.companyImportantsLabel}>
                      <FormattedMessage id="BuyerProfilePage.LabelWhatsImportantToUsAndOurCustomers" />
                    </div>
                    <div className={css.companyImportants}>
                      {(properties || []).map((i) => (
                        <div className={css.companyImportantItem} key={i.code}>{i.name}</div>))}
                    </div>
                  </div>
                </div>
                <div className={css.imagesContainer}>
                  {images}
                </div>
                {this.renderUpdateForm()}
              </div>
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }

  renderUpdateForm() {
    const {
      buyerCurrentCompany,
      buyerUpdateCompanyInProgress
    } = this.props;
    const { displayUpdateForm } = this.state;
    return (displayUpdateForm && (<div className={css.previewDialogContainer}>
      <div className={css.previewDialog}>
        <div className={css.dialogHeader}>
          <button className={css.closeButton} onClick={() => this.setState({ displayUpdateForm: false })} />
          <h1 className={css.previewDialogTitle}>
            <FormattedMessage id="UserFields.editCompanyInfo" />
          </h1>
        </div>
        <div className={css.previewDialogContent}>
          <BuyerCompanyForm
            currentCompany={buyerCurrentCompany}
            inProgress={buyerUpdateCompanyInProgress}
            onSubmit={this.onSubmit} />
        </div>
      </div>
    </div>));
  }
};

BuyerProfilePageComponent.defaultProps = {
  currentUserListing: null,
  currentUserListingFetched: false,
};

BuyerProfilePageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const {
    buyerCurrentCompany,
    buyerUpdateCompany,
    buyerUpdateCompanyInProgress
  } = state.buyerCompany;
  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
    buyerCurrentCompany,
    buyerUpdateCompany,
    buyerUpdateCompanyInProgress,
  };
};

const mapDispatchToProps = dispatch => ({
  onBuyerCreateCompany: data => dispatch(buyerCreateCompany(data)),
  onBuyerLogo: values => dispatch(buyerUpdateCompanyLogo(values)),
  onBuyerUpdateCompanyCover: values => dispatch(buyerUpdateCompanyCover(values)),
  onBuyerDeleteCompanyCover: values => dispatch(buyerDeleteCompanyCover(values)),
  onBuyerUpdateCompanyImage: values => dispatch(buyerUpdateCompanyImage(values)),
  onBuyerDeleteCompanyImage: index => dispatch(buyerDeleteCompanyImage(index)),
  onBuyerUpdateCompany: data => dispatch(buyerUpdateCompany(data)),
});

const BuyerProfilePage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(BuyerProfilePageComponent);

export default BuyerProfilePage;