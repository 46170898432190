import classNames from "classnames";
import { bool, func, number, string } from "prop-types";
import React from "react";
import { FormattedMessage } from '../../util/reactIntl';
import css from "./CountryShippingFee.module.css";

const CountryShippingFee = (props) => {
  const { error, name, description, shippingDescription, isShip, shippingFee, onChangeFee, onChangeIsShip, shippingFeePlaceholder } = props;

  return (
    <div className={css.container}>
      <p className={css.name}>{name}</p>
      <p className={css.description}>{description}</p>
      <div className={css.radioButtonsContainer}>
        <input
          id={`isShip:${isShip}`}
          type="radio"
          name={name}
          className={css.radioButton}
          onChange={onChangeIsShip}
          checked={isShip}
          value={true}
        />
        <label className={css.radioLabel} htmlFor={`isShip:${isShip}`}>
          <FormattedMessage id="StripePayoutPage.shipCountry.Yes" />
        </label>
        <input
          id={`isShip:${!isShip}`}
          type="radio"
          name={name}
          className={css.radioButton}
          onChange={onChangeIsShip}
          checked={!isShip}
          value={false}
        />
        <label className={css.radioLabel} htmlFor={`isShip:${!isShip}`}>
          <FormattedMessage id="StripePayoutPage.shipCountry.No" />
        </label>
      </div>
      <p className={classNames(css.description, { [css.disabledDescription]: !isShip })}>
        {shippingDescription}
        <span className={css.error}>
          &nbsp;*&nbsp;{error}
        </span>
      </p>
      <div className={css.shippingFeeInputContainer}>
        <input
          type="number"
          step="0.1"
          className={classNames(
            css.shippingFeeInput,
            { [css.shippingFeeInputDisabled]: !isShip, [css.shippingFeeInputError]: !!error },
          )}
          name={name}
          onChange={onChangeFee}
          placeholder={shippingFeePlaceholder}
          disabled={!isShip}
          value={shippingFee}
          min={0}
          max={15}
        />
          <span className={css.exclVatText}>€<FormattedMessage id="StripePayoutPage.shipCountry.exclVAT" /></span>
      </div>
    </div>
  );
};

CountryShippingFee.propTypes = {
  name: string.isRequired,
  error: string,
  description: string.isRequired,
  shippingDescription: string.isRequired,
  isShip: bool.isRequired,
  shippingFee: number | string,
  onChangeFee: func.isRequired,
  onChangeIsShip: func.isRequired,
};

export default CountryShippingFee;
