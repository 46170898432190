import React, { Component } from 'react';
import { bool } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import config from '../../config';
import { displayRegistrationConfirmDialog } from '../../ducks/Modal.duck';
import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
} from '../../components';

import { TopbarContainer } from '../../containers';
import { FormattedMessage } from '../../util/reactIntl';

import facebookImage from '../../assets/VegshelfFacebook-1200x630.png';
import twitterImage from '../../assets/VegshelfTwitter-600x314.png';
import css from './AboutUsPage.module.css';

import dwnrw from '../../assets/dwnrw.png';
import eitfood from '../../assets/eit-food.png';
import ytili from '../../assets/ytili-young-transatlantic-innovation-leaders-initiative-fellowship-program.png';
import gmf from '../../assets/german-marshall-fund-of-united-states.png';
import starbuzz from '../../assets/starbuzz.png';
import balpro from '../../assets/balpro.png';
import foodhubnrw from '../../assets/foodhub-nrw.png';

import vegshelfFounders from '../../assets/founders-vegshelf.png';
import baibaVegshelf from '../../assets/baiba-soika-vegshelf.png';
import marcisVegshelf from '../../assets/marcis-lakstigala-vegshelf.png';

import foodhack from '../../assets/foodhack.png';
import foodtech500 from '../../assets/foodtech500-forward-fooding.png';
import gruendenNRW from '../../assets/gruenden-nrw-podcast.png';
import theFoodFightPodcast from '../../assets/the-food-fight-podcast-eit-food.png';

export class AboutUsPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { categoryIndex: 0, planIndex: 1, blogIndex: 0, FAQIndex: -1 };
  }
  render() {
    const {
      intl,
      scrollingDisabled,
      params,
    } = this.props;

    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage({ id: 'AboutUsPage.schemaTitle' }, { siteTitle });
    const schemaDescription = intl.formatMessage({ id: 'AboutUsPage.schemaDescription' }, { siteTitle });
    const schemaImage = `${config.canonicalRootURL}${facebookImage}`;

    return (
      <Page
        className={css.root}
        scrollingDisabled={scrollingDisabled}
        contentType="website"
        description={schemaDescription}
        title={schemaTitle}
        robots='index'
        facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
        twitterImages={[
          { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
        ]}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'WebPage',
          description: schemaDescription,
          name: schemaTitle,
          image: [schemaImage],
        }}
      >
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer />
            <div className={css.pageHeaderContainer}>
              <div className={css.pageHeaderSection}>
                <h1 className={css.sectionMainTitle}>
                  <FormattedMessage id="AboutUsPage.pageTitle" />
                </h1>
                <div className={css.joinUsButtonContainer}>
                  <a className={css.joinUsButton} href="https://www.linkedin.com/company/vegshelf/" target="_blank">
                    <FormattedMessage id="AboutUsPage.followOurJourney" />
                  </a>
                </div>
                <p className={css.joinUsText}>
                  <FormattedMessage id="AboutUsPage.pageSubtitle1" />
                  <br></br>
                  <FormattedMessage id="AboutUsPage.pageSubtitle2" /><a href="mailto:hello@vegshelf.com" target="_blank">hello@vegshelf.com</a>.
                </p>
              </div>
            </div>
          </LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div className={css.aboutUsContainer}>
              <div className={css.aboutUsContainerOne}>
              </div>
              <div className={css.aboutUsContainerTwo}>
                <div className={css.aboutUsSectionText}>
                  <h2 className={css.aboutUsSectionTitle}>
                    <FormattedMessage id="AboutUsPage.missionTitle" />
                  </h2>
                  <p className={css.aboutUsSectionSubtitle}>
                    <FormattedMessage id="AboutUsPage.missionText" />
                  </p>
                  <h2 className={css.aboutUsSectionTitle}>
                    <FormattedMessage id="AboutUsPage.buildingTitle" />
                  </h2>
                  <p className={css.aboutUsSectionSubtitle}>
                    <FormattedMessage id="AboutUsPage.buildingText" />
                  </p>
                </div>
              </div>
            </div>
            <div className={css.investorsContainer}>
              <h3 className={css.investorsTitle}>
                <FormattedMessage id="AboutUsPage.InvestorsAndSupporters" />
              </h3>
              <div className={css.investors}>
                <div className={css.singleInvestor}>
                  <img className={css.investorLogo1} src={dwnrw} alt="Digitale Wirtschaft" />
                </div>
                <div className={css.singleInvestor}>
                  <img className={css.investorLogo2} src={eitfood} alt="EIT Food" />
                </div>
                <div className={css.singleInvestor}>
                  <img className={css.investorLogo3} src={ytili} alt="YTILI - Young Transatlantic Innovation Leaders Initiative Fellowship Program" />
                </div>
                <div className={css.singleInvestor}>
                  <img className={css.investorLogo4} src={gmf} alt="German Marshall Fund of the United States" />
                </div>
                <div className={css.singleInvestor}>
                  <img className={css.investorLogo5} src={starbuzz} alt="Starbuzz Accelerator" />
                </div>
                <div className={css.singleInvestor}>
                  <img className={css.investorLogo6} src={balpro} alt="Verband für Alternative Proteinquellen e.V. - BALPRO " />
                </div>
                <div className={css.singleInvestor}>
                  <img className={css.investorLogo7} src={foodhubnrw} alt="FoodHub NRW" />
                </div>
              </div>
            </div>
            <div className={css.foundersContainer}>
              <div className={css.foundersSection}>
                <div className={css.foundersTextandImageSection}>
                  <div className={css.foundersText}>
                    <h2 className={css.foundersTitle}>
                      <FormattedMessage id="AboutUsPage.foundersTitle" />
                    </h2>
                    <p className={css.foundersSubtitle}>
                      <FormattedMessage id="AboutUsPage.foundersMessage1" />
                      <br></br>
                      <br></br>
                      <FormattedMessage id="AboutUsPage.foundersMessage2" />
                      <br></br>
                      <br></br>
                      <FormattedMessage id="AboutUsPage.foundersMessage3" />
                    </p>
                  </div>
                  <img className={css.foundersImage} src={vegshelfFounders} alt="Vegshelf founders - Baiba Soika and Marcis Lakstigala" />
                </div>
              </div>
            </div>
            <div className={css.eachFounderContainer}>
              <div className={css.eachFounderSection}>
                <div className={css.baibaSection}>
                  <img className={css.baibaImage} src={baibaVegshelf} alt="Baiba Soika - CEO and Co-founder of Vegshelf" />
                  <div className={css.founderTexts}>
                    <p className={css.founderTitle}>
                      <FormattedMessage id="AboutUsPage.baibaSoika" />
                    </p>
                    <p className={css.founderPosition}>
                      <FormattedMessage id="AboutUsPage.coFounderCEO" />
                    </p>
                    <div className={css.linkedinLogoButton}>
                      <a className={css.linkedinLogo} href="https://www.linkedin.com/in/baibasoika/" target="_blank"></a>
                    </div>
                    <p className={css.founderSubtitle}>
                      <FormattedMessage id="AboutUsPage.baibaBio1" />
                      <br></br>
                      <br></br>
                      <FormattedMessage id="AboutUsPage.baibaBio2" />
                    </p>
                    <p className={css.founderQuote}>
                      <i>“<FormattedMessage id="AboutUsPage.baibaBio3" />”</i>
                    </p>
                  </div>
                </div>
                <div className={css.marcisSection}>
                  <img className={css.marcisImage} src={marcisVegshelf} alt="Marcis Lakstigala - CTO and Co-founder of Vegshelf" />
                  <div className={css.founderTexts}>
                    <p className={css.founderTitle}>
                      <FormattedMessage id="AboutUsPage.marcisLakstigala" />
                    </p>
                    <p className={css.founderPosition}>
                      <FormattedMessage id="AboutUsPage.coFounderCTO" />
                    </p>
                    <div className={css.linkedinLogoButton}>
                      <a className={css.linkedinLogo} href="https://www.linkedin.com/in/marcis-lakstigala/" target="_blank"></a>
                    </div>
                    <p className={css.founderSubtitle}>
                      <FormattedMessage id="AboutUsPage.marcisBio1" />
                      <br></br>
                      <br></br>
                      <FormattedMessage id="AboutUsPage.marcisBio2" />
                    </p>
                    <p className={css.founderQuote}>
                      <i>“<FormattedMessage id="AboutUsPage.marcisBio3" />”</i>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className={css.mediaAwardsContainer}>
              <h3 className={css.mediaAwardsTitle}>
                <FormattedMessage id="AboutUsPage.mediaAndAwards" />
              </h3>
              <div className={css.mediaAwards}>
                <div className={css.singleMediaAward}>
                  <a href="https://foodhack.global/articles/90-female-foodtech-founders-leaders-investors" target="_blank">
                    <img className={css.mediaAwardLogo1} src={foodhack} alt="Foodhack - Global Community for Food Innovators" />
                  </a>
                </div>
                <div className={css.singleMediaAward}>
                  <a href="https://forwardfooding.com/foodtech500/2020/company/vegshelf/" target="_blank">
                    <img className={css.mediaAwardLogo2} src={foodtech500} alt="FoodTech 500 by Forward Fooding" />
                  </a>
                </div>
                <div className={css.singleMediaAward}>
                  <a href="https://open.spotify.com/episode/7a6tmIugWp4rtJFJ6YLa14" target="_blank">
                    <img className={css.mediaAwardLogo3} src={gruendenNRW} alt="Gruenden NRW Podcast by Ministerium für Wirtschaft, Industrie, Klimaschutz und Energie des Landes Nordrhein-Westfalen" />
                  </a>
                </div>
                <div className={css.singleMediaAward}>
                  <a href="https://thefoodfight.podigee.io/54-spotlight-vegshelf-on-plant-based-food-availability" target="_blank">
                    <img className={css.mediaAwardLogo4} src={theFoodFightPodcast} alt="The Food Fight Podcast by EIT Food" />
                  </a>
                </div>
              </div>
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page >
    );
  }
};

AboutUsPageComponent.defaultProps = {
};

AboutUsPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired
};
const mapStateToProps = state => {

  return {
    scrollingDisabled: isScrollingDisabled(state)
  };
};

const mapDispatchToProps = dispatch => ({
  onShowRegistrationConfirmDialog: () => dispatch(displayRegistrationConfirmDialog(true, false))
});

const AboutUsPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(AboutUsPageComponent);

export default AboutUsPage;