import React, { Component } from 'react';
import { array, bool, func, number, shape, string } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import pickBy from 'lodash/pickBy';
import classNames from 'classnames';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { withViewport } from '../../util/contextHelpers';
import { parse, stringify } from '../../util/urlHelpers';
import { createResourceLocatorString, pathByRouteName } from '../../util/routes';
import { propTypes } from '../../util/types';
import { localePart, storedLocale } from '../Wrapper/ClassWrapper';

import {
  LimitedAccessBanner,
  Logo,
  Modal,
  TopbarDesktop,
  TopbarMobileMenu,
  Button,
  RegistrationConfirmDialog
} from '../../components';
import MenuIcon from './MenuIcon';
import { TopbarSearchForm } from '../../forms';
import css from './Topbar.module.css';
import NamedLink from "../NamedLink/NamedLink";
import arrowLeft from "../../assets/arrow-left-000000.svg";

//const locale = sessionStorage.getItem('locale') || localePart();
const locale = storedLocale() || localePart();

const MAX_MOBILE_SCREEN_WIDTH = 768;
const routesListWhereTopbarMessageDisabled = [
  //'/supplier-registration',
  //'/buyer-registration',
  //'/recover-password',
  //'/account/change-password',
  //'/reset-password',
  //'/access-denied',
  //'/check-access/%2F',
  `/${locale}/supplier-registration`,
  `/${locale}/buyer-registration`,
  `/${locale}/recover-password`,
  `/${locale}/account/change-password`,
  `/${locale}/reset-password`,
  `/${locale}/access-denied`,
  `/${locale}/check-access/%2F`,
];

const isPageNotBlacklisted = (pageRoute) => (!routesListWhereTopbarMessageDisabled.includes(pageRoute));

const redirectToURLWithModalState = (props, modalStateParam) => {
  const { history, location } = props;
  const { pathname, search, state } = location;
  const searchString = `?${stringify({ [modalStateParam]: 'open', ...parse(search) })}`;
  history.push(`${pathname}${searchString}`, state);
};

const redirectToURLWithoutModalState = (props, modalStateParam) => {
  const { history, location } = props;
  const { pathname, search, state } = location;
  const queryParams = pickBy(parse(search), (v, k) => {
    return k !== modalStateParam;
  });
  const stringified = stringify(queryParams);
  const searchString = stringified ? `?${stringified}` : '';
  history.push(`${pathname}${searchString}`, state);
};

const GenericError = (props) => {
  const { show } = props;
  const classes = classNames(css.genericError, { [css.genericErrorVisible]: show });

  return (
    <div className={classes}>
      <div className={css.genericErrorContent}>
        <p className={css.genericErrorText}>
          <FormattedMessage id="Topbar.genericError" />
        </p>
      </div>
    </div>
  );
};

GenericError.propTypes = {
  show: bool.isRequired,
};

class TopbarComponent extends Component {
  constructor(props) {
    super(props);
    this.handleMobileMenuOpen = this.handleMobileMenuOpen.bind(this);
    this.handleMobileMenuClose = this.handleMobileMenuClose.bind(this);
    this.handleMobileSearchOpen = this.handleMobileSearchOpen.bind(this);
    this.handleMobileSearchClose = this.handleMobileSearchClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
  }

  handleMobileMenuOpen() {
    redirectToURLWithModalState(this.props, 'mobilemenu');
  }

  handleMobileMenuClose() {
    redirectToURLWithoutModalState(this.props, 'mobilemenu');
  }

  handleMobileSearchOpen() {
    redirectToURLWithModalState(this.props, 'mobilesearch');
  }

  handleMobileSearchClose() {
    redirectToURLWithoutModalState(this.props, 'mobilesearch');
  }

  handleSubmit(values) {
    const { search } = values;
    const { history } = this.props;
    const searchParams = { search: search, locale: locale };
    history.push(createResourceLocatorString('SearchProductsPage', routeConfiguration(), searchParams));
  }

  handleLogout() {
    const { onLogout, history } = this.props;
    onLogout()
      .then(() => {
        //const path = pathByRouteName('LandingPage', routeConfiguration());
        const name = 'LandingPage';
        const params = { locale };
        const path = pathByRouteName(name, routeConfiguration(), params);
        // In production we ensure that data is really lost,
        // but in development mode we use stored values for debugging
        if (config.dev) {
          history.push(path);
        } else if (typeof window !== 'undefined') {
          window.location = path;
        }
      });
  }

  render() {
    const {
      className,
      rootClassName,
      desktopClassName,
      mobileRootClassName,
      mobileClassName,
      isAuthenticated,
      authScopes,
      authInProgress,
      currentUser,
      currentUserHasListings,
      currentUserListing,
      currentUserListingFetched,
      // currentUserHasOrders,
      currentUserType,
      currentPage,
      notificationCount,
      viewport,
      intl,
      location,
      onManageDisableScrolling,
      // onResendVerificationEmail,
      // sendVerificationEmailInProgress,
      // sendVerificationEmailError,
      productCategoryCode,
      showGenericError,
      onlyLogo,
      searchInputValue,
      currentStep,
      isNotEmptyCart,
    } = this.props;

    const { mobilemenu, mobilesearch/*, address, origin, bounds*/ } = parse(
      location.search,
      {
        latlng: ['origin'],
        latlngBounds: ['bounds'],
      },
    );

    // const notificationDot = notificationCount > 0 ? <div className={css.notificationDot}/> : null;
    const isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;
    const isMobileMenuOpen = isMobileLayout && mobilemenu === 'open';

    const mobileMenu = (
      <TopbarMobileMenu
        isAuthenticated={isAuthenticated}
        currentUserHasListings={currentUserHasListings}
        currentUserListing={currentUserListing}
        currentUserListingFetched={currentUserListingFetched}
        currentUser={currentUser}
        onLogout={this.handleLogout}
        notificationCount={notificationCount}
        currentPage={currentPage}
        currentUserType={currentUserType}
        onlyLogo={onlyLogo}
      />
    );

    // Only render current search if full place object is available in the URL params
    // const locationFieldsPresent = config.sortSearchByDistance ? address && origin && bounds : address && bounds;
    const initialSearchFormValues = { search: searchInputValue };
    const classes = classNames(rootClassName || css.root, className);

    const isSecurePaymentStep = currentStep !== 0 && isNotEmptyCart;
    const isBuyerUserType = currentUserType === "buyer";
    const isSupplierUserType = currentUserType === "supplier";
    //const isShoppingCartPath = this.props.location.pathname.startsWith("/shopping/cart");
    const isShoppingCartPath = this.props.location.pathname.startsWith("/en/shopping/cart") || this.props.location.pathname.startsWith("/de/shopping/cart");
    const isDisplaySecureTopBar = isBuyerUserType && isSecurePaymentStep && isShoppingCartPath;
    const isMobileUserRegistered = isBuyerUserType || isSupplierUserType;

    //const isAboutUsPage = this.props.location.pathname.startsWith("/about-us");
    //const isApplyToSellPage = this.props.location.pathname.startsWith("/apply-to-sell");
    const isAboutUsPage = this.props.location.pathname.startsWith("/en/about-us") || this.props.location.pathname.startsWith("/de/about-us");
    const isApplyToSellPage = location.pathname.startsWith('/en/apply-to-sell') || location.pathname.startsWith('/de/apply-to-sell');
    //const isBlogPage = this.props.location.pathname.startsWith("/blog");
    const isBlogPage = this.props.location.pathname.startsWith("/en/blog") || this.props.location.pathname.startsWith("/de/blog");
    const isCustomTopbar = false;//isAboutUsPage || isApplyToSellPage || isBlogPage;

    const unFixedTopbar = isAboutUsPage || isApplyToSellPage || isBlogPage || onlyLogo;
    const fixedTopbarCss = unFixedTopbar ? css.desktopTopbar : css.desktopTopbarFixed;

    const gourmiegoodswebsite = (
      <a className={css.linkFormat} href="https://www.gourmiegoods.com" target="_blank" >
        <FormattedMessage id="TopbarDesktop.message.gourmieGoodsWebsite" />
      </a>
    )

    return (
      <div className={classes}>
        <LimitedAccessBanner
          isAuthenticated={isAuthenticated}
          authScopes={authScopes}
          currentUser={currentUser}
          onLogout={this.handleLogout}
          currentPage={currentPage}
        />
        {isDisplaySecureTopBar ? (
          <div>
            <p className={css.messageDesktopTopbar}>
              <FormattedMessage id={"TopbarDesktop.message.secure"} />
              <a href="tel:+4917621954363" target="_blank"><FormattedMessage id="TopbarDesktop.messagePhone" />
              </a>
            </p>
            <div className={css.secureDesktopTopbarContainer}>
              <NamedLink name="LandingPage">
                <div className={css.backToMarketplaceSection}>
                  <img className={css.arrow} src={arrowLeft} alt="" />
                  <p className={css.backToMarketplaceText}>
                    <FormattedMessage id="TopbarDesktop.backToMarketplace" />
                  </p>
                </div>
              </NamedLink>
              <NamedLink name="LandingPage">
                <Logo format="desktop" className={css.logo} alt={intl.formatMessage({ id: 'TopbarDesktop.logo' })} />
              </NamedLink>
              <p className={css.securePaymentText}>
                <FormattedMessage id="TopbarDesktop.securePayment" />
              </p>
            </div>
          </div>
        ) : (
          <>
            {/* added the section for Mobile Topbar */}
            <div className={css.messageMobileTopbar}>
              <FormattedMessage id={`TopbarDesktop.message.mobile.${currentUserType}`} values={{ gourmiegoodswebsite }} />&nbsp;
              {isBuyerUserType ?
                <FormattedMessage id="TopbarDesktop.messagePhone" />
                : isSupplierUserType ?
                  <a href="mailto:hello@vegshelf.com" target="_blank"><FormattedMessage id="TopbarDesktop.messageEmail" />
                  </a>
                  : null}
            </div>
            <div className={isAboutUsPage ? css.mobileAboutUsPage : (isApplyToSellPage ? css.mobileApplyToSellPage : (isBlogPage ? css.mobileAboutUsPage : css.mobileContainer))}>
              <Button
                rootClassName={isMobileUserRegistered ? (isSupplierUserType ? css.mobileMenuSupplier : css.mobileMenuBuyer) : css.mobileMenu}
                onClick={this.handleMobileMenuOpen}
                title={intl.formatMessage({ id: 'Topbar.menuIcon' })}
              >
                <MenuIcon className={css.mobileMenuIcon} />
                {/*notificationDot*/}
              </Button>
              <div className={css.mobileLogoAndSearchContainer}>
                <NamedLink name="LandingPage">
                  <Logo
                    format="desktop"
                    className={css.mobileLogo}
                  />
                </NamedLink>
                {isCustomTopbar ? null :
                  (<div className={css.searchContainerMobile}>
                    <TopbarSearchForm
                      className={css.searchFormMobile}
                      desktopInputRoot={css.mobileSearchPadding}
                      onSubmit={this.handleSubmit}
                      initialValues={initialSearchFormValues}
                    />
                  </div>)}
              </div>
              <RegistrationConfirmDialog />
            </div>
            {/* end of the added section */}
            <div className={fixedTopbarCss}>
              {isPageNotBlacklisted(location.pathname) && (
                <p className={css.messageDesktopTopbar}>
                  <FormattedMessage id={`TopbarDesktop.message.${currentUserType}`} values={{ gourmiegoodswebsite }} />
                  &nbsp;
                  {isBuyerUserType ?
                    <a href="tel:+4917621954363" target="_blank"><FormattedMessage id="TopbarDesktop.messagePhone" />
                    </a>
                    : isSupplierUserType ?
                      <a href="mailto:hello@vegshelf.com" target="_blank"><FormattedMessage id="TopbarDesktop.messageEmail" />
                      </a>
                      : null}
                </p>
              )}
              <TopbarDesktop
                className={desktopClassName}
                currentUserHasListings={currentUserHasListings}
                currentUserListing={currentUserListing}
                currentUserListingFetched={currentUserListingFetched}
                currentUser={currentUser}
                currentUserType={currentUserType}
                currentPage={currentPage}
                initialSearchFormValues={initialSearchFormValues}
                intl={intl}
                productCategoryCode={productCategoryCode}
                isAuthenticated={isAuthenticated}
                notificationCount={notificationCount}
                onLogout={this.handleLogout}
                onSearchSubmit={this.handleSubmit}
                onlyLogo={onlyLogo}
                location={location}
              />
            </div>
          </>
        )}
        <Modal
          id="TopbarMobileMenu"
          isOpen={isMobileMenuOpen}
          onClose={this.handleMobileMenuClose}
          usePortal
          onManageDisableScrolling={onManageDisableScrolling}
        >
          {authInProgress ? null : mobileMenu}
        </Modal>
        <GenericError show={showGenericError} />
      </div>
    );
  }
}

TopbarComponent.defaultProps = {
  className: null,
  rootClassName: null,
  desktopClassName: null,
  mobileRootClassName: null,
  mobileClassName: null,
  notificationCount: 0,
  currentUser: null,
  currentUserHasOrders: null,
  currentPage: null,
  sendVerificationEmailError: null,
  authScopes: [],
  currentUserType: string,
};

TopbarComponent.propTypes = {
  className: string,
  rootClassName: string,
  desktopClassName: string,
  mobileRootClassName: string,
  mobileClassName: string,
  isAuthenticated: bool.isRequired,
  authScopes: array,
  authInProgress: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentUserHasListings: bool.isRequired,
  currentUserHasOrders: bool,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onResendVerificationEmail: func.isRequired,
  sendVerificationEmailInProgress: bool.isRequired,
  sendVerificationEmailError: propTypes.error,
  showGenericError: bool.isRequired,
  currentUserType: string,
  // These are passed from Page to keep Topbar rendering aware of location changes
  history: shape({ push: func.isRequired }).isRequired,
  location: shape({ search: string.isRequired }).isRequired,
  // from withViewport
  viewport: shape({ width: number.isRequired, height: number.isRequired }).isRequired,
  // from injectIntl
  intl: intlShape.isRequired,
};

const Topbar = compose(
  withViewport,
  injectIntl
)(TopbarComponent);

Topbar.displayName = 'Topbar';

export default Topbar;