import classNames from "classnames";
import { bool, object } from "prop-types";
import React, { Component } from "react";
import { TransitionStates } from "../../ducks/transition.duck";
import { FormattedMessage } from "../../util/reactIntl";
import ButtonLoaderComponent from "../ButtonLoader/ButtonLoader";
import CalendarDialog from "../CalendarDialog/CalendarDialog";
import NamedLink from "../NamedLink/NamedLink";
import css from "./TransactionViewItem.module.css";
import { MINIMAL_ORDER_FOR_FREE_SHIPPING } from "../../ducks/shopping-cart.duck";
import { createInvoice, fakeInvoiceBody as invoiceBody } from "../../util/invoicing";
import { getInvoiceNumber } from "../../util/api";
import { format } from "date-fns";
import { slugify } from "../../util/urlTrimming";
import { localePart, storedLocale } from "../Wrapper/ClassWrapper";

const locale = storedLocale() || localePart();

export const DownloadOrderInvoiceSection = ({ disabled, brands }) => (
  <div className={css.downloadInvoiceContainer}>
    <h4 className={css.downloadInvoiceTitle}>
      <FormattedMessage id="DownloadOrderInvoiceSection.title" />
    </h4>
    {brands.map((brand) => (
      <button key={brand} className={css.downloadInvoiceButton} disabled={disabled}>
        <FormattedMessage id="DownloadOrderInvoiceSection.buttonText" values={{ brand }} />
      </button>
    ))}
  </div>
);

const manageShippingText = <FormattedMessage id="SupplierOrderPage.manageShipping" />;
const selectTheDateText = <FormattedMessage id="SupplierOrderPage.selectTheDate" />;

DownloadOrderInvoiceSection.propTypes = {
  disabled: bool.isRequired,
};

const PLACEHOLDER_IMAGE_VALUE = "placeholderImage";

function transformValueToPriceFormat(value) {
  return Number(value.toFixed(2));
}

function formattedDate(date, separator = "/") {
  if (!date) {
    return null;
  }
  let year = date.getFullYear();
  let month = (1 + date.getMonth()).toString().padStart(2, "0");
  let day = date.getDate().toString().padStart(2, "0");

  return day + separator + month + separator + year;
}

function formatPrice(price) {
  const msrp = parseFloat(price);
  return (Math.round(msrp * 100) / 100).toFixed(2);
}

function expiredFormatDate(date) {
  let expiredDate = new Date(date.valueOf());
  expiredDate.setDate(expiredDate.getDate() + 3);
  const today = new Date();
  const diffMs = (expiredDate - today);
  const diffHrs = Math.floor(diffMs / 3600000); // hours
  const diffMins = Math.round((diffMs % 3600000) / 60000); // minutes
  const timeRemainingText = <FormattedMessage id="SupplierOrderPage.timeToConfirmOrReject" values={{ diffHrs, diffMins }} />;
  return timeRemainingText;
}

function paidFormatDateMessage(date) {
  let expiredDate = new Date(date.valueOf());
  expiredDate.setDate(expiredDate.getDate() + 3);
  const today = new Date();
  const diffMs = (expiredDate - today);
  const diffHrs = Math.floor(diffMs / 3600000); // hours
  const diffMins = Math.round((diffMs % 3600000) / 60000); // minutes
  const timeToPaidText = <FormattedMessage id="SupplierOrderPage.timeToPaid" values={{ diffHrs, diffMins }} />;
  return timeToPaidText;
}

const OrderPreview = ({ images }) => {
  const isSingleProductOrder = images.length === 1;
  const MAX_IMAGES_QUANTITY = 4;

  if (!isSingleProductOrder) {
    return (
      <div className={css.orderPreviewContainer}>
        {images.splice(0, MAX_IMAGES_QUANTITY).map((src, index) => {
          const isPlaceholder = src === PLACEHOLDER_IMAGE_VALUE;

          return !isPlaceholder ? (
            <img key={src} className={css.orderPreviewItem} src={src} alt={src} />
          ) : (
            <div key={`${src}-${index}`} className={css.orderPreviewItemPlaceholder} />
          );
        })}
      </div>
    );
  }

  return (<div className={css.transactionLogo} style={{ "backgroundImage": `url('${images[0]}')` }} />);
};

export class TransactionViewItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shippedDate: new Date(),
      displayShippingDaySelector: false,
      displayDeliveredConfirm: false,
      displayConfirmRequest: false,
      displayRejectRequest: false,
    };
    this.onConfirmClick = this.onConfirmClick.bind(this);
    this.onRejectClick = this.onRejectClick.bind(this);
    this.onUpdateClick = this.onUpdateClick.bind(this);
    this.onShippedClick = this.onShippedClick.bind(this);
    this.onShowDeliveredClick = this.onShowDeliveredClick.bind(this);
    this.onDeliveredClick = this.onDeliveredClick.bind(this);
    this.onHideDeliveredClick = this.onHideDeliveredClick.bind(this);
    this.onConfirmRequestClick = this.onConfirmRequestClick.bind(this);
    this.onHideConfirmRequestClick = this.onHideConfirmRequestClick.bind(this);
    this.onConfirmRejectClick = this.onConfirmRejectClick.bind(this);
    this.onHideConfirmRejectClick = this.onHideConfirmRejectClick.bind(this);
    this.createSupplierToBuyerInvoice = this.createSupplierToBuyerInvoice.bind(this);
    this.createVegshelfToSupplierInvoice = this.createVegshelfToSupplierInvoice.bind(this);
  }

  onUpdateClick(transitionName) {
    this.props.onTransitionClick(this.props.transactionItem, transitionName);
  }

  onRejectClick() {
    this.setState({ ...this.state, displayRejectRequest: true });
  }

  async onShippedClick() {
    await this.props.onTransitionClick(this.props.transactionItem, TransitionStates.shipped, this.state.shippedDate);
    /**
     * @description Displaying state for update modal.
     * @type {boolean} - isSuccessfulShippingUpdate
     */
    const isSuccessfulShippingUpdate = (!!this.props.transitionError || this.props.transitionInProgress);
    this.setState({ ...this.state, displayShippingDaySelector: isSuccessfulShippingUpdate });
  }

  onShowDeliveredClick(canChange) {
    if (!canChange) {
      return;
    }
    this.setState({ ...this.state, displayDeliveredConfirm: true });
  }

  onHideDeliveredClick() {
    this.setState({ ...this.state, displayDeliveredConfirm: false });
  }

  async onDeliveredClick() {
    await this.props.onTransitionClick(this.props.transactionItem, TransitionStates.delivered, this.state.shippedDate);
    const isSuccessfulShippingUpdate = (!!this.props.transitionError || this.props.transitionInProgress);
    this.setState({ ...this.state, displayDeliveredConfirm: isSuccessfulShippingUpdate });
    const isPilotUser = this.props.transactionItem.supplierInvoiceData?.pilotUser;
    
    // Original Vegshelf to supplier invoice creation function changed for subscription based model pilot
    /*await this.createVegshelfToSupplierInvoice({
      ...this.props.transactionItem.attributes.protectedData,
      supplierInvoiceData: { ...this.props.transactionItem.supplierInvoiceData },
      orderDate: format(this.props.transactionItem.attributes.createdAt, "d MMMM y"),
      orderId: this.props.transactionItem.id.uuid,
    });*/

    // Vegshelf to supplier invoice created only if Supplier is not part of subscription based model pilot
    if (!isPilotUser) {
      await this.createVegshelfToSupplierInvoice({
        ...this.props.transactionItem.attributes.protectedData,
        supplierInvoiceData: { ...this.props.transactionItem.supplierInvoiceData },
        orderDate: format(this.props.transactionItem.attributes.createdAt, "d MMMM y"),
        orderId: this.props.transactionItem.id.uuid,
      });
    }
  }

  async createVegshelfToSupplierInvoice(orderData) {
    // TODO: Need to be refactored.
    // Creating invoice only for current supplier.
    const {
      supplierInvoiceData,
      cart: { vegshelfCommission: { net_price, tax_rate, total_tax_amount, commissionPercentage } }
    } = orderData;
    const brand = orderData.cart.validBrands.find((b) => b.brandId === supplierInvoiceData.companyId);

    if (brand) {
      const { brandCountryCode, brandId, brandName } = brand;
      const invoice_number = await getInvoiceNumber().then(({ data: { lastInvoiceNumber } }) => lastInvoiceNumber);
      const crossBoardTerms = brandCountryCode !== "Germany"
        ? "\n \n Invoice subject to the reverse charge mechanism - VAT to be accounted for by the customer." : "";
      const product_name = `Commission for the order nr.
      ${orderData.orderId}
      placed on ${orderData.orderDate}
      (${Math.abs(commissionPercentage)}% of total net order of EUR ${formatPrice(orderData.brandInfo.brandTotal)})
      `;

      const totalTaxAmount = transformValueToPriceFormat(net_price * (tax_rate / 100));
      const totalAmount = transformValueToPriceFormat(totalTaxAmount + net_price);

      const body = {
        meta: { ...invoiceBody.meta, invoice_number },
        supplier: {
          company_name: 'Vegshelf UG (haftungsbeschränkt)',
          address: 'Leopoldstraße 49, 40211 Düsseldorf, NRW',
          country: 'Germany',
          tin: 'DE328398511',
          pin: 'Amtsgericht Düsseldorf, HRB 88669',
          external_uid: process.env.REACT_APP_FONOA_API_VEGSHELF_ADMIN_KEY,
        },
        customer: {
          ...invoiceBody.supplier,
          external_uid: brandId,
          company_name: orderData.supplierInvoiceData.companyName,
          address: `${orderData.supplierInvoiceData.address},
            ${orderData.supplierInvoiceData.postcode} ${orderData.supplierInvoiceData.city}`,
          country: brandCountryCode,
          pin: orderData.supplierInvoiceData.pin,
          tin: orderData.supplierInvoiceData.tin,
          trade_name: brandName,
        },
        invoice: {
          delivery_date: new Date().toISOString().replace(/\.\d{3}Z$/, "+00:00"),
          payment_type: "Commission from transaction",
          items: [
            {
              item_number: 1,
              product_name,
              quantity: 1,
              unit_of_measure: "Pcs",
              unit_price: net_price,
              tax_rate,
              net_price,
            }
          ],
          discount: 0,
          total_tax_amount: totalTaxAmount,
          total_net_amount: net_price,
          total_amount: totalAmount,
          note: crossBoardTerms,
        },
      };

      return await createInvoice({ body });
    }
  }

  onConfirmClick() {
    this.setState({ ...this.state, displayConfirmRequest: true });
  }

  onHideConfirmRequestClick() {
    this.setState({ ...this.state, displayConfirmRequest: false });
  }

  async onConfirmRequestClick() {
    await this.props.onTransitionClick(this.props.transactionItem, TransitionStates.confirmed)
      .then(async () => {
        await this.createSupplierToBuyerInvoice({
          ...this.props.transactionItem.attributes.protectedData,
          supplierInvoiceData: { ...this.props.transactionItem.supplierInvoiceData },
          orderDate: format(this.props.transactionItem.attributes.createdAt, "d MMMM y"),
          orderId: this.props.transactionItem.id.uuid,
        });
        const isSuccessfulOrderConfirming = (!!this.props.transitionError || this.props.transitionInProgress);
        this.setState({ ...this.state, displayConfirmRequest: isSuccessfulOrderConfirming });
      });
  }

  async createSupplierToBuyerInvoice(orderData) {
    // TODO: Need to be refactored.
    // Creating invoice only for current supplier.
    const brand = orderData.cart.validBrands.find((b) => b.brandId === orderData.supplierInvoiceData.companyId);
    if (brand) {
      const { brandTotal, brandVAT, products, shippingFee, brandCountryCode, brandId, brandName } = brand;
      const invoice_number = await getInvoiceNumber().then(({ data: { lastInvoiceNumber } }) => lastInvoiceNumber);
      const isDomesticTransaction = brandCountryCode === orderData.country;
      const crossBoardTerms = !isDomesticTransaction
        ? "\n \n Invoice subject to the reverse charge mechanism - VAT to be accounted for by the customer." : "";
      const paymentTypeLast4Digits = `**** ${orderData.cart.paymentData.card.last4}`;
      const paymentTypeBrand =
        orderData.cart.paymentData.card.brand.charAt(0).toUpperCase() + orderData.cart.paymentData.card.brand.slice(1);
      const payment_type = `${paymentTypeBrand} ${paymentTypeLast4Digits}`;
      const taxRateGroups = products.reduce((groups, { VATItems: tax_rate, totalPrice }) => {
        if (groups.hasOwnProperty(tax_rate)) {
          groups[tax_rate].quantity++;
          groups[tax_rate].totalGroupPrice += totalPrice;
          return groups;
        }
        groups[tax_rate] = { tax_rate, quantity: 1, totalGroupPrice: totalPrice };
        return groups;
      }, {});

      // Variable includes special formatting for keeping lines wrapping.
      // In case of extended markup value we are using billing address country [orderData.country] first
      // and passing [deliveryData.country] separately below - to match consistent layout.
      const emptyAdditionalInfo = orderData.cart.deliveryData.additionalInfo === undefined;
      const emptyRegion = orderData.cart.deliveryData.region === undefined;
      const additionalInfo = emptyAdditionalInfo ? "" : (`\n${orderData.cart.deliveryData.additionalInfo},`);
      const regionInfo = emptyRegion ? "" : (`\n${orderData.cart.deliveryData.region},`);

      const billingAndDeliveryAddresses = `
        <b>Billing address:</b>
        ${orderData.address},
        ${orderData.postcode} ${orderData.city}
        ${orderData.country} <br/>
        <b>Delivery address:</b>
        ${orderData.cart.deliveryData.companyName}
        ${orderData.cart.deliveryData.streetAndNumber},`+additionalInfo+
        `
        ${orderData.cart.deliveryData.postCode} ${orderData.cart.deliveryData.city}`+regionInfo+
        `
        ${orderData.cart.deliveryData.country}

      `;

      const orderShippingFee = brandTotal > MINIMAL_ORDER_FOR_FREE_SHIPPING ? 0 : shippingFee;
      const total_net_amount = transformValueToPriceFormat(brandTotal + orderShippingFee);
      const total_amount = transformValueToPriceFormat(brandTotal + brandVAT + orderShippingFee);
      const productsShippingFees = Object.values(taxRateGroups);
      const freeShippingFeeDetails = [{
        quantity: 1,
        tax_rate: 0,
        totalGroupPrice: 0,
      }];
      const actualShippingFees = brandTotal > MINIMAL_ORDER_FOR_FREE_SHIPPING
        ? freeShippingFeeDetails : productsShippingFees;

      const frenchCompany = brandCountryCode === "France";
      const countryBasedPin = frenchCompany ? (`${orderData.supplierInvoiceData.pin}\n RCS Number: ${orderData.supplierInvoiceData.companyRCSnumber}\n Share capital: ${orderData.supplierInvoiceData.companyShareCapital}`) : (orderData.supplierInvoiceData.pin);

      const body = {
        meta: { ...invoiceBody.meta, invoice_number },
        issuer: { ...invoiceBody.issuer },
        supplier: {
          ...invoiceBody.supplier,
          external_uid: brandId,
          company_name: orderData.supplierInvoiceData.companyName,
          address: `${orderData.supplierInvoiceData.address},
            ${orderData.supplierInvoiceData.postcode} ${orderData.supplierInvoiceData.city}`,
          country: brandCountryCode,
          // company registration number
          //pin: orderData.supplierInvoiceData.pin,
          pin: countryBasedPin,
          // company vat number
          tin: orderData.supplierInvoiceData.tin,
          trade_name: brandName,
        },
        customer: {
          ...invoiceBody.customer,
          external_uid: orderData.companyId,
          company_name: orderData.companyLegalName,
          address: billingAndDeliveryAddresses,
          country: ` `,
          // company registration number
          pin: orderData.pin,
          // company vat number
          tin: orderData.tin,
          trade_name: orderData.companyName,
        },
        invoice: {
          ...invoiceBody.invoice,
          payment_type,
          delivery_date: new Date().toISOString().replace(/\.\d{3}Z$/, "+00:00"),
          items: [
            // brand products
            ...products.map((p, index) => ({
              item_number: index + 1,
              product_name: `${p.productName}, \n Product ID: ${p.id}`,
              quantity: p.count,
              unit_of_measure: 'pcs',
              unit_price: transformValueToPriceFormat(p.price),
              tax_rate: isDomesticTransaction ? transformValueToPriceFormat(p.VATItems) : 0,
              net_price: transformValueToPriceFormat(p.price * p.count),
            })),
            // shipping fees
            ...actualShippingFees.map((taxGroup, index) => ({
              item_number: products.length + 1 + index,
              product_name: `Shipping fee (VAT ${isDomesticTransaction ? taxGroup.tax_rate : 0}%)`,
              quantity: 1,
              unit_of_measure: 'pcs',
              unit_price: transformValueToPriceFormat((taxGroup.totalGroupPrice / brandTotal) * shippingFee),
              tax_rate: isDomesticTransaction ? taxGroup.tax_rate : 0,
              net_price: transformValueToPriceFormat((taxGroup.totalGroupPrice / brandTotal) * shippingFee),
            })),
          ],
          discount: 0,
          total_net_amount,
          total_tax_amount: transformValueToPriceFormat(brandVAT),
          total_amount,
          note: `Order date: ${orderData.orderDate} \n Order number: ${orderData.orderId} ${crossBoardTerms}`,
        },
      };

      return await createInvoice({ body });
    }
  }

  onConfirmRejectClick() {
    this.props.onResetExpandedOrderClick();
    this.setState({ ...this.state, displayRejectRequest: false });
    this.props.onTransitionClick(this.props.transactionItem, TransitionStates.rejected);
  }

  onHideConfirmRejectClick() {
    this.setState({ ...this.state, displayRejectRequest: false });
  }

  render() {
    const { transactionItem, showDetails, onShowDetailsClick, canChangeStatus } = this.props;
    const state = TransitionStates.parseState(transactionItem.attributes.lastTransition);
    const isPending = state === "pending";
    const detailsButtonText = isPending && canChangeStatus ? <FormattedMessage id="SupplierOrderPage.viewOrderRequest" /> : <FormattedMessage id="SupplierOrderPage.viewOrder" />;
    const trData = transactionItem?.attributes?.protectedData || {};
    const details = trData?.brandInfo || {};
    const products = details.products;
    const mainUrl = products[0]?.mainImageUrl;
    const styleLogo = {};
    const stateCss = classNames(css.state, css[state]);
    const deliveryData = trData?.cart?.deliveryData || {};
    if (mainUrl) {
      styleLogo.backgroundImage = `url('${mainUrl}')`;
    }
    const confirmed = TransitionStates.stepFinished(state, "confirmed");
    const shipped = TransitionStates.stepFinished(state, "shipped");
    const delivered = TransitionStates.stepFinished(state, "delivered");
    const paid = TransitionStates.stepFinished(state, "paid");
    const nextState = TransitionStates.nextStep(state);
    const confirmedNodeCss = classNames(
      css.transitionItem,
      { [css.transitionItemConfirmed]: confirmed, [css.transitionItemPending]: !confirmed },
    );
    const shippedNodeCss = classNames(
      css.transitionItem,
      { [css.transitionItemShipped]: shipped, [css.transitionItemShippedActive]: nextState === "shipped" },
    );
    const deliveredNodeCss = classNames(
      css.transitionItem,
      { [css.transitionItemDelivered]: delivered, [css.transitionItemDeliveredActive]: nextState === "delivered" },
    );
    const paidNodeCss = classNames(css.transitionItem, { [css.transitionItemPaid]: paid });
    let totalCount = 0;
    products.forEach((p) => {
      totalCount += p.count;
    });
    const confirmDate = formattedDate(TransitionStates.getTransitionDate(transactionItem, TransitionStates.confirmed),
      ".");
    let shippedDate = trData?.shippedDate ? new Date(Date.parse(trData?.shippedDate)) : null;
    shippedDate = formattedDate(shippedDate, ".");
    const deliveredRawDate = TransitionStates.getTransitionDate(transactionItem, TransitionStates.delivered);
    const deliveredDate = formattedDate(TransitionStates.getTransitionDate(transactionItem, TransitionStates.delivered),
      ".");
    const paidDate = formattedDate(TransitionStates.getTransitionDate(transactionItem, TransitionStates.paid), ".");
    let canChangeDeliveryState = true;
    if (trData?.shippedDate && nextState === "delivered") {
      /**
       * @description Date should be calculated from the picked shipping date, not from the update action date.
       * @type {Date} - tempDate
       */
      let tempDate = new Date(TransitionStates.getTransitionDate(transactionItem, TransitionStates.shipped));
      // TODO: Commented for QA test.
      const ONE_DAY_IN_MILLISECONDS = 86400000;
      tempDate = new Date(tempDate.getTime() + ONE_DAY_IN_MILLISECONDS);
      const ONE_MINUTE_IN_MILLISECONDS = 60000;
      //tempDate = new Date(tempDate.getTime() + ONE_MINUTE_IN_MILLISECONDS);
      let currentDate = new Date();
      canChangeDeliveryState = currentDate > tempDate;
    }
    const updateDeliveryButtonCss = classNames(
      css.transitionButton,
      { [css.disableTransitionButton]: !canChangeDeliveryState },
    );

    const [first, second, third, forth] = products;
    const orderPreviewImages = products.length > 1
      ? [
        first?.mainImageUrl || PLACEHOLDER_IMAGE_VALUE,
        second?.mainImageUrl || PLACEHOLDER_IMAGE_VALUE,
        third?.mainImageUrl || PLACEHOLDER_IMAGE_VALUE,
        forth?.mainImageUrl || PLACEHOLDER_IMAGE_VALUE,
      ] : [first?.mainImageUrl];

    const { brandTotal, shippingFee = 0.00, brandVAT } = details;
    const freeShippingPrice = 0.00;
    const formattedBrandTotal = formatPrice(brandTotal) || freeShippingPrice;
    const rawVAT = Number(brandVAT) || freeShippingPrice;
    const VAT = formatPrice(rawVAT);
    const isFreeShipping = details.brandTotal >= MINIMAL_ORDER_FOR_FREE_SHIPPING;
    const actualShippingPrice = !isFreeShipping ? shippingFee : freeShippingPrice;
    const formattedShippingPrice = formatPrice(actualShippingPrice) || freeShippingPrice;
    const totalExclVAT = formatPrice(!isFreeShipping ? (brandTotal + actualShippingPrice) : brandTotal);
    const totalAmount = formatPrice(brandTotal + actualShippingPrice + rawVAT) || freeShippingPrice;

    return (
      <div className={classNames(css.transactionItem, { [css.showDetailsEnable]: showDetails })}>
        <div className={css.shortDataContainer}>
          <OrderPreview images={orderPreviewImages} />
          <div className={css.orderShortInfo}>
            <div className={css.dataRow}>
              <div className={css.orderDate}>
                {formattedDate(transactionItem.attributes.createdAt)}
              </div>
              <NamedLink name="BuyerDetailsPage" params={{ id: trData.companyId, locale: locale  }}>
                <div className={css.buyerStoreName}>
                  {trData.companyName}
                </div>
              </NamedLink>
            </div>
            <div className={css.dataRow}>
              <div className={css.orderNumber}>
                <FormattedMessage id="SupplierOrderPage.orderNumber" />&nbsp;{transactionItem.id.uuid}
              </div>
              <div className={css.orderTotal}>
                {totalAmount} €
              </div>
            </div>
            <div className={css.dataRow}>
              <div className={css.orderVolume}>
                {totalCount}<FormattedMessage id="SupplierOrderPage.units" />
              </div>
              <div className={css.stateContainer}>
                <div className={stateCss}>{state}</div>
              </div>
            </div>
            <div className={css.transactionItemState}>
              <a className={css.showDetailsButton}
                onClick={() => onShowDetailsClick(transactionItem)}>{detailsButtonText}</a>
              {isPending ? (
                <div className={classNames(css.expireTime, { [css.expireTimeDE]: locale === "de" })}>
                  {expiredFormatDate(transactionItem.attributes.createdAt)}
                </div>
              ) : null}
            </div>
          </div>
        </div>
        {showDetails ? (
          <div className={css.orderDetailsContainer}>
            <div className={css.orderDetailsRow}>
              <div>
                <div className={css.previewItemTitle}>
                  <FormattedMessage id="SupplierOrderPage.deliveryDetails" />
                </div>
                <div className={css.previewItemValue}>{deliveryData.companyName}</div>
                <div className={css.previewItemValue}>{deliveryData.streetAndNumber}</div>
                <div className={css.previewItemValue}>{deliveryData.additionalInfo}</div>
                <div className={css.previewItemValue}>{`${deliveryData.postCode} ${deliveryData.city}`}</div>
                <div className={css.previewItemValue}>{deliveryData.region}</div>
                <div className={css.previewItemValue}>{deliveryData.country}</div>
                {canChangeStatus ? (
                  <NamedLink
                    name="SupplierSendMessagePage"
                    className={css.sendCompanyButton}
                    params={{ receiver: trData?.companyId, title: `Order: ${transactionItem.id.uuid}`, locale: locale }}
                  >
                    <FormattedMessage id="SupplierOrderPage.contactBuyer" />
                  </NamedLink>
                ) : (
                  <NamedLink
                    name="BuyerSendMessagePage"
                    className={css.sendCompanyButton}
                    params={{ receiver: details?.brandId, title: `Order: ${transactionItem.id.uuid}`, locale: locale }}
                  >
                    <FormattedMessage id="SupplierOrderPage.contactBrand" />
                  </NamedLink>
                )}
              </div>
              <div className={css.orderSummary}>
                <h4 className={css.orderSummaryTitle}><FormattedMessage id="OrderSummary.orderSummaryTitle" /></h4>
                <div className={css.priceItemRow}>
                  <div className={css.priceLabel}>{totalCount} <FormattedMessage
                    id="OrderSummary.orderSummaryProducts" /></div>
                  <div className={css.priceValue}>{formattedBrandTotal} €</div>
                </div>
                <div className={css.priceItemRow}>
                  <div className={css.priceLabel}><FormattedMessage id="OrderSummary.orderSummaryShipping" /></div>
                  <div className={css.priceValue}>{formattedShippingPrice} €</div>
                </div>
                <div className={css.detailsSeparatorBlack}>&nbsp;</div>
                <div className={css.priceItemRow}>
                  <div className={css.priceLabel}><FormattedMessage id="OrderSummary.orderSummaryItemSubtotalExclVat" />
                  </div>
                  <div className={css.priceValue}>{totalExclVAT} €</div>
                </div>
                <div className={css.priceItemRow}>
                  <div className={css.priceLabel}><FormattedMessage id="OrderSummary.orderSummaryVAT" /></div>
                  <div className={css.priceValue}>{VAT} €</div>
                </div>
                <div className={css.detailsSeparatorBlack}>&nbsp;</div>
                <div className={css.priceSummaryItem}>
                  <div className={css.priceSummaryLabel}><FormattedMessage
                    id="OrderSummary.orderSummaryOrderTotalInclVat" /></div>
                  <div className={css.priceSummaryValue}>{totalAmount} €</div>
                </div>
              </div>
            </div>
            <div className={css.orderProgressBarContainer}>
              <div className={confirmedNodeCss}>
                <h5 className={css.stepName}>
                  <FormattedMessage id="SupplierOrderPage.confirmed" />
                </h5>
                <div className={css.stepIcon}>&nbsp;</div>
                <h5 className={css.stepName}>{confirmDate}</h5>
                {canChangeStatus ? (
                  <div className={css.confirmButton} onClick={() => this.onConfirmClick()}>
                    <FormattedMessage id="SupplierOrderPage.confirm" />
                  </div>
                ) : null}
                {canChangeStatus ? (
                  <div className={css.rejectButton} onClick={() => this.onRejectClick()}>
                    <FormattedMessage id="SupplierOrderPage.reject" />
                  </div>
                ) : null}
                {this.state.displayConfirmRequest ? (
                  <div className={css.modalWindow}>
                    <div className={css.modalWindowContent}>
                      <div className={css.crossContainer}>
                        <button className={css.closeButton} onClick={() => this.onHideConfirmRequestClick()} />
                      </div>
                      <div className={css.modalTitle}>
                        <FormattedMessage id="SupplierOrderPage.confirmOrderRequest" />
                      </div>
                      <div className={css.modalSubTitle}>
                        <FormattedMessage id="SupplierOrderPage.confirmingRequestInfo1" />
                        <br></br>
                        <FormattedMessage id="SupplierOrderPage.confirmingRequestInfo2" />
                      </div>
                      <div className={css.modalButtons}>
                      <button
                          className={css.modalSecondaryButton}
                          onClick={() => this.onHideConfirmRequestClick()}
                        >
                          <FormattedMessage id="SupplierOrderPage.goBack" />
                        </button>
                        <button
                          className={css.modalPrimaryButton}
                          onClick={() => this.onConfirmRequestClick()}
                        >
                          {this.props.transitionInProgress ? <ButtonLoaderComponent /> : <FormattedMessage id="SupplierOrderPage.confirmOrder" />}
                        </button>
                      </div>
                    </div>
                  </div>
                ) : null}
                {this.state.displayRejectRequest ? (
                  <div className={css.modalWindow}>
                    <div className={css.modalWindowContent}>
                      <div className={css.crossContainer}>
                        <button className={css.closeButton} onClick={() => this.onHideConfirmRejectClick()} />
                      </div>
                      <div className={css.modalTitle}>
                        <FormattedMessage id="SupplierOrderPage.rejectRequest" />
                      </div>
                      <div className={css.modalSubTitle}>
                        <FormattedMessage id="SupplierOrderPage.rejectingRequestInfo1" />
                        <br></br>
                        <FormattedMessage id="SupplierOrderPage.rejectingRequestInfo2" />
                      </div>
                      <div className={css.modalButtons}>
                      <button
                          className={css.modalSecondaryButton}
                          onClick={() => this.onHideConfirmRejectClick()}>
                          <FormattedMessage id="SupplierOrderPage.goBack" />
                        </button>
                        <button className={css.modalPrimaryButton} 
                        onClick={() => this.onConfirmRejectClick()}>
                          <FormattedMessage id="SupplierOrderPage.confirmRejection" />
                        </button>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
              <div className={shippedNodeCss}>
                <h5 className={css.stepName}>
                  <FormattedMessage id="SupplierOrderPage.shipped" />
                </h5>
                <div className={css.progressAxis}>&nbsp;</div>
                <div className={css.stepIcon}>&nbsp;</div>
                <h5 className={css.stepName}>{shippedDate}</h5>
                {canChangeStatus ? (
                  <a
                    className={css.transitionButton}
                    onClick={() => this.setState({ ...this.state, displayShippingDaySelector: true })}
                  >
                    <FormattedMessage id="SupplierOrderPage.update" />
                  </a>
                ) : null}
                {this.state.displayShippingDaySelector ? (
                  <CalendarDialog
                    selectedDate={this.state.shippedDate}
                    title={manageShippingText}
                    subTitle={selectTheDateText}
                    onGoBackClick={() => this.setState({ ...this.state, displayShippingDaySelector: false })}
                    onUpdateClick={() => this.onShippedClick()}
                    onDateChange={(d) => this.setState({ ...this.state, shippedDate: d })}
                    transitionInProgress={this.props.transitionInProgress}
                  />
                ) : null}
              </div>
              <div className={deliveredNodeCss}>
                <h5 className={css.stepName}>
                  <FormattedMessage id="SupplierOrderPage.delivered" />
                </h5>
                <div className={css.progressAxis}>&nbsp;</div>
                <div className={css.stepIcon}>&nbsp;</div>
                <h5 className={css.stepName}>{deliveredDate}</h5>
                {canChangeStatus ? (
                  <a
                    className={updateDeliveryButtonCss}
                    onClick={() => this.onShowDeliveredClick(canChangeDeliveryState)}
                  >
                    <FormattedMessage id="SupplierOrderPage.update" />
                  </a>
                ) : null}
                {this.state.displayDeliveredConfirm ? (
                  <div className={css.modalWindow}>
                    <div className={css.modalWindowContent}>
                      <div className={css.closeButton} onClick={() => this.onHideDeliveredClick()} />
                      <h1 className={css.modalTitle}>
                        <FormattedMessage id="SupplierOrderPage.isOrderDelivered" />
                      </h1>
                      <h2 className={css.modalSubTitle}>
                        <FormattedMessage id="SupplierOrderPage.confirmOrderReceived" />
                      </h2>
                      <div className={css.modalButtons}>
                        <button className={css.modalSecondaryButton} onClick={() => this.onHideDeliveredClick()}>
                          <FormattedMessage id="SupplierOrderPage.goBack" />
                        </button>
                        <button className={css.modalPrimaryButton} onClick={() => this.onDeliveredClick()}>
                          {this.props.transitionInProgress ? <ButtonLoaderComponent /> : <FormattedMessage id="SupplierOrderPage.orderDelivered" />}
                        </button>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
              <div className={paidNodeCss}>
                <h5 className={css.stepName}>
                  <FormattedMessage id="SupplierOrderPage.paid" />
                </h5>
                <div className={css.progressAxis}>&nbsp;</div>
                <div className={css.stepIcon}>&nbsp;</div>
                <h5 className={css.stepName}>{paidDate}</h5>
                {nextState === "paid" && !paidDate && (
                  <h5 className={css.paidExpiration}>{paidFormatDateMessage(deliveredRawDate)}</h5>
                )}
              </div>
            </div>
            <div>
              <h4 className={css.detailsBlockTitle}>
                {totalCount}<FormattedMessage id="SupplierOrderPage.products" />
              </h4>
              {products.map((p) => (
                <div key={p.id} className={css.productItem}>
                  <NamedLink name="ProductDetailsPage" params={{ product: slugify(p.productName), id: p.id, locale: locale }}>
                    <div className={css.productItemLogo} style={{ backgroundImage: `url('${p.mainImageUrl}')` }}>
                      &nbsp;
                    </div>
                  </NamedLink>
                  <div className={css.productItemDetails}>
                    <NamedLink name="ProductDetailsPage" params={{ product: slugify(p.productName), id: p.id, locale: locale }}>
                      <h4 className={css.productItemTitle}>{p.productName}</h4>
                    </NamedLink>
                    <div className={css.productItemField}>
                      <div className={css.fieldLabel}>
                        <FormattedMessage id="SupplierOrderPage.productID" />
                      </div>
                      <div className={css.fieldValue}>{p.id}</div>
                    </div>
                    <div className={css.productItemField}>
                      <div className={css.fieldLabel}>
                        <FormattedMessage id="SupplierOrderPage.quantity" />
                      </div>
                      <div className={css.fieldValue}>{p.count}</div>
                    </div>
                    <div className={css.productItemPriceRow}>
                      <div className={css.productItemField}>
                        <div className={css.fieldLabel}>
                          <FormattedMessage id="SupplierOrderPage.pricePerUnit" />
                        </div>
                        <div className={css.fieldValue}>{formatPrice(p.price)} €</div>
                      </div>
                      <div className={css.totalPrice}>
                        <div className={css.totalPriceLabel}>
                          <FormattedMessage id="SupplierOrderPage.total" />
                        </div>
                        <div className={css.totalPriceValue}>{formatPrice(p.totalPrice)} €</div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              {/*TODO: Temporary hidden!*/}
              {/*<DownloadOrderInvoiceSection disabled={!confirmed} brands={[" "]}/>*/}
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

TransactionViewItem.propTypes = {
  transactionItem: object.isRequired,
};

export default TransactionViewItem;
