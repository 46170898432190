import config from "../config";
import { getLeadTimes, LEAD_TIMES_PREFIX } from "../translations/productDictionaries";
import { initiatePrivileged } from "../util/api";
import { types as sdkTypes } from "../util/sdkLoader";
import { buyerUpdateDeliveryAddress } from "./buyer-company.duck";
import { sendNewOrderNotification } from "./notification.duck";
import { format, add, getDay } from "date-fns";
import { calculateBrandShippingFee } from "../components/BuyerTransactionViewItem/BuyerTransactionViewItem";
import { deletePaymentMethod } from "./paymentMethods.duck";
import { TransitionStates } from "./transition.duck";
import { ar } from "date-fns/locale";
import BigNumber from "bignumber.js";
import { toNumber } from "lodash";
import { storedLocale, localePart } from "../components/Wrapper/ClassWrapper";

const { UUID } = sdkTypes;
const CURRENCY = config.currency;
const SESSION_STORE_KEY = "buyer-shopping-cart";
const PROVIDER_COMMISSION_PERCENTAGE_FIRST = -15;
const PROVIDER_COMMISSION_PERCENTAGE_SECOND = -10;
// Additional commissions added for subscription based model pilot
const PILOT_PROVIDER_COMMISSION_PERCENTAGE_FIRST = 0;
const PILOT_PROVIDER_COMMISSION_PERCENTAGE_SECOND = 0;

export const ShoppingCartSteps = { Products: 0, DeliveryData: 1, PaymentData: 2, ConfirmOrder: 3 };
export const MINIMAL_ORDER_FOR_FREE_SHIPPING = 250;

// ================ Action types ================ //
export const SHOPPING_CART_LOAD_REQUEST = "app/buyer/shopping/cart/load";
export const SHOPPING_CART_LOAD_REQUEST_SUCCESS = "app/buyer/shopping/cart/load/success";
export const SHOPPING_CART_SAVE_REQUEST = "app/buyer/shopping/cart/save";
export const SHOPPING_CART_SAVE_SUCCESS = "app/buyer/shopping/cart/save/success";
export const SHOPPING_CART_SAVE_ERROR = "app/buyer/shopping/cart/save/error";
export const SHOPPING_CART_ADD_PRODUCT = "app/buyer/shopping/cart/add/product";
export const SHOPPING_CART_CLEAR_ADDED_PRODUCT = "app/buyer/shopping/cart/clear/added/product";
export const SAVE_ORDERS = "app/buyer/shopping/cart/save/orders";
export const CLEAR_FETCHED_ORDERS = "app/buyer/shopping/cart/clear/orders";

// ================ Reducer ================ //
const initialState = {
  shoppingCart: null,
  shoppingCartProductCount: 0,
  shoppingCartSaveInProgress: false,
  shoppingCartSaved: false,
  orders: [],
  shoppingCartSavedWithError: false,
  addedProduct: null,
  saveCreditCardOnUnmount: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case CLEAR_FETCHED_ORDERS:
      return { ...state, orders: [] };
    case SAVE_ORDERS:
      return { ...state, orders: [...state.orders, payload] };
    case SHOPPING_CART_LOAD_REQUEST:
      return {
        ...state,
        shoppingCart: null,
        shoppingCartProductCount: 0,
        shoppingCartSaveInProgress: true,
        shoppingCartSaved: false,
        shoppingCartSavedWithError: false,
      };
    case SHOPPING_CART_ADD_PRODUCT:
      return { ...state, addedProduct: payload };
    case SHOPPING_CART_CLEAR_ADDED_PRODUCT:
      return { ...state, addedProduct: null };
    case SHOPPING_CART_LOAD_REQUEST_SUCCESS:
      const data = normalizeShoppingCart(payload);
      localStorage.setItem(SESSION_STORE_KEY, JSON.stringify(data));
      return {
        ...state,
        shoppingCart: { ...data?.shoppingData },
        shoppingCartProductCount: data?.shoppingCartProductCount || 0,
        shoppingCartSaveInProgress: false,
        saveCreditCardOnUnmount: data?.shoppingData?.confirmData?.savePaymentData,
      };
    case SHOPPING_CART_SAVE_REQUEST:
      return {
        ...state,
        shoppingCartSaveInProgress: true,
        shoppingCartSaved: false,
        shoppingCartSavedWithError: false,
      };
    case SHOPPING_CART_SAVE_SUCCESS:
      localStorage.setItem(SESSION_STORE_KEY, null);
      return {
        ...state,
        shoppingCart: null,
        shoppingCartProductCount: 0,
        shoppingCartSaveInProgress: false,
        shoppingCartSaved: true,
        shoppingCartSavedWithError: false,
      };
    case SHOPPING_CART_SAVE_ERROR:
      return {
        ...state,
        shoppingCartSaveInProgress: false,
        shoppingCartSaved: false,
        shoppingCartSavedWithError: true,
      };
    default:
      return state;
  }
}

// ================ Action creators ================ //
const shoppingCartLoadRequest = () => ({ type: SHOPPING_CART_LOAD_REQUEST });
const shoppingCartAddedProduct = (product) => ({ type: SHOPPING_CART_ADD_PRODUCT, payload: product });
export const shoppingCartClearAddedProduct = () => ({ type: SHOPPING_CART_CLEAR_ADDED_PRODUCT });
const shoppingCartLoaded = (shoppingData) => ({ type: SHOPPING_CART_LOAD_REQUEST_SUCCESS, payload: shoppingData });
const shoppingCartSaveRequest = () => ({ type: SHOPPING_CART_SAVE_REQUEST });
export const shoppingCartSaveSuccess = () => ({ type: SHOPPING_CART_SAVE_SUCCESS });
const shoppingCartSaveError = () => ({ type: SHOPPING_CART_SAVE_ERROR });
const saveOrder = (order) => ({ type: SAVE_ORDERS, payload: order });
export const clearFetchedOrders = () => ({ type: CLEAR_FETCHED_ORDERS });

// ================ Thunks ================ //
const saveCartToStore = (data = {}) => (dispatch, getState) => {
  data.currentCompany = getState().user?.currentUser?.attributes?.publicData;
  data.currentUser = getState().user?.currentUser;

  return dispatch(shoppingCartLoaded(data));
};

export const goToStep = (stepIndex, savedData) => (dispatch, getState) => {
  const currentShoppingData = { ...(getState().ShoppingCartStore.shoppingCart || {}) };
  const shoppingCartSaved = getState().ShoppingCartStore.shoppingCartSaved;
  const fetchedOrders = getState().ShoppingCartStore.orders;

  /**
   * @description Clear previous cart details (fetched supplier's orders).
   */
  if (!shoppingCartSaved && fetchedOrders.length) {
    dispatch(clearFetchedOrders());
  }

  if (currentShoppingData.currentStep === ShoppingCartSteps.DeliveryData) {
    currentShoppingData.deliveryData = { ...currentShoppingData.deliveryData, ...savedData };
  }
  if (currentShoppingData.currentStep === ShoppingCartSteps.PaymentData) {
    currentShoppingData.paymentData = { ...currentShoppingData.paymentData, ...savedData };
    currentShoppingData.confirmData = { ...currentShoppingData.confirmData };
  }
  if (currentShoppingData.currentStep === ShoppingCartSteps.ConfirmOrder) {
    currentShoppingData.confirmData = {
      ...currentShoppingData.confirmData,
      ...currentShoppingData.paymentData,
      ...savedData,
    };
  }

  currentShoppingData.currentStep = stepIndex;

  return dispatch(saveCartToStore(currentShoppingData));
};

export const resetPaymentData = () => (dispatch, getState) => {
  const currentShoppingData = { ...(getState().ShoppingCartStore.shoppingCart || {}) };

  currentShoppingData.currentStep = 2;
  currentShoppingData.confirmData = { ...currentShoppingData.confirmData, valid: false };
  currentShoppingData.confirmData = { ...currentShoppingData.paymentData, valid: false };

  return dispatch(saveCartToStore(currentShoppingData));
};

export const shoppingCartLoad = () => (dispatch) => {
  dispatch(shoppingCartLoadRequest());
  const dataJSON = localStorage.getItem(SESSION_STORE_KEY)
    || JSON.stringify({ shoppingData: null, shoppingCartProductCount: 0 });
  const data = JSON.parse(dataJSON);

  return dispatch(saveCartToStore(data?.shoppingData));
};

export const shoppingCartAddProduct = (params) => (dispatch, getState) => {
  const {
    id,
    productName,
    price,
    unitsOfPackage,
    mainImageUrl,
    count,
    brandId,
    brandName,
    bardImageUrl,
    shippingDays,
    shippingFee,
    productShippingDays,
    productShippingDaysCode,
    VATItems,
    brandCountryCode,
    deliveryData,
    currentUserCountry,
    weight,
    weightUnit,
    // Additional param added for subscription based model pilot
    // Param defined in ProductDetailsPage.js
    pilotUser,
  } = params;

  const currentShoppingData = { ...(getState().ShoppingCartStore.shoppingCart || {}) };

  const newProduct = {
    id,
    productName,
    price,
    unitsOfPackage,
    mainImageUrl,
    count,
    VATItems: parseFloat(VATItems || "0"),
    // TO DO: does product VAT rate needs to be big number?
    //VATItems: new BigNumber(VATItems || "0").toPrecision(2),
    productShippingDays,
    productShippingDaysCode,
    weight,
    weightUnit,
    // Additional object value added for subscription based model pilot
    // pilotUser added to product data
    pilotUser,
  };

  let brandExists = false;
  currentShoppingData.brands = currentShoppingData?.brands || [];
  currentShoppingData.deliveryData = deliveryData;
  currentShoppingData.currentStep = 0;
  const currentBrands = currentShoppingData?.brands;

  for (let i = 0; i < currentBrands.length; i++) {
    if (currentBrands[i].brandId !== brandId) {
      continue;
    }
    brandExists = true;
    let productExists = false;
    for (let j = 0; j < currentBrands[i].products.length; j++) {
      if (currentBrands[i].products[j].id === id) {
        currentBrands[i].products[j].count += count;
        currentBrands[i].products[j].shippingFee = shippingFee;
        productExists = true;
      }
    }
    if (!productExists) {
      currentBrands[i].products.push(newProduct);
      currentBrands[i].currentUserCountry = currentUserCountry;
    }
  }

  if (!brandExists) {
    currentBrands.push({
      brandId,
      brandName,
      bardImageUrl,
      shippingDays,
      shippingFee,
      products: [newProduct],
      brandTotal: 0,
      brandCountryCode,
      currentUserCountry,
      // Additional value added for subscription based model pilot
      // pilotUser pushed to currentBrands object
      pilotUser
    });
  }

  dispatch(shoppingCartAddedProduct(true));

  setTimeout(() => {
    dispatch(shoppingCartClearAddedProduct());
  }, 5000);

  return dispatch(saveCartToStore(currentShoppingData));
};

export const shoppingCartDeleteProduct = ({ id }) => (dispatch, getState) => {
  const currentShoppingData = { ...getState().ShoppingCartStore.shoppingCart };
  const currentBrands = currentShoppingData?.brands;
  for (let i = 0; i < currentBrands.length; i++) {
    currentBrands[i].products = currentBrands[i].products.filter((p) => (p.id !== id));
  }

  return dispatch(saveCartToStore(currentShoppingData));
};

export const shoppingCartUpdateProduct = ({ id, count }) => (dispatch, getState) => {
  const currentShoppingData = { ...getState().ShoppingCartStore.shoppingCart };
  const currentBrands = currentShoppingData?.brands;
  for (let i = 0; i < currentBrands.length; i++) {
    for (let j = 0; j < currentBrands[i].products.length; j++) {
      if (currentBrands[i].products[j].id === id) {
        currentBrands[i].products[j].count = count;
      }
    }
  }

  return dispatch(saveCartToStore(currentShoppingData));
};

export function calcShipsDates(shipsCode) {
  const shipsItem = getLeadTimes().filter((x) => (x.name === shipsCode))[0];
  const minDays = shipsItem?.minDays ?? 0;
  const maxDays = shipsItem?.maxDays ?? 2;
  const estimatedShipDays = calcShipDates(minDays, maxDays);
  const language = storedLocale() || localePart();
  const german = language === "de";

  if (german) {
    return `Versendet direkt von der Marke (${minDays} - ${maxDays} Tage). Voraussichtliche Lieferung: ${estimatedShipDays}`;
  } else {
    return `Ships directly from brand (${minDays} - ${maxDays} days). Estimated delivery: ${estimatedShipDays}`;
  }
}

const DAY_MONTH_SHORT = "d LLL";
const YEAR = "y";
const SATURDAY_INDEX = 6;
const SUNDAY_INDEX = 0;

/**
 * @description Moves forward delivery date according the business week days.
 * @param {number} weekDayIndex
 * @param {Date} date
 * @returns {Date}
 */
function moveForwardDeliveryDate({ weekDayIndex, date }) {
  switch (weekDayIndex) {
    case SATURDAY_INDEX:
      return add(date, { days: 2 });
    case SUNDAY_INDEX:
      return add(date, { days: 1 });
    default:
      return date;
  }
}

function calcShipDates(minDays, maxDays) {
  let minDate = add(new Date(), { days: minDays });
  const minDateIndex = getDay(minDate);
  minDate = moveForwardDeliveryDate({ weekDayIndex: minDateIndex, date: minDate });
  let maxDate = add(minDate, { days: maxDays - minDays });
  const maxDateIndex = getDay(maxDate);
  maxDate = moveForwardDeliveryDate({ weekDayIndex: maxDateIndex, date: maxDate });

  const firstPart = format(minDate, DAY_MONTH_SHORT);
  const secondPart = format(maxDate, DAY_MONTH_SHORT);
  const thirdPart = format(maxDate, YEAR);
  return `${firstPart} - ${secondPart}, ${thirdPart}`;
}

export function defineTheLongestDeliveryPeriod(brandProducts) {
  const productShippingPeriods = brandProducts.map(({ productShippingDays, productShippingDaysCode }) => ({
    shipsCode: productShippingDays,
    productShippingDaysCode,
    codeNumber: Number(productShippingDaysCode?.split(LEAD_TIMES_PREFIX)[1]),
  }));

  const longestPeriod = productShippingPeriods.reduce((prev, current) => {
    return (prev.codeNumber > current.codeNumber) ? prev : current;
  });

  return longestPeriod.shipsCode ? calcShipsDates(longestPeriod.shipsCode) : null;
}

/**
 * Static German VAT value.
 * @type {number}
 */
const GERMAN_VAT = 19;

function calcVegshelfVat(supplierCountry) {
  if (supplierCountry === "Germany") {
    return GERMAN_VAT;
  }

  return 0;
}

/**
 * The formula provided by a Marketplace Documentation - Logic & Rules.
 * https://docs.google.com/presentation/d/19ZGsmEMSfMB07XLr7FuPs5IeBpdCsg2tEo0psGg5a7k/edit#slide=id.gc5e8c00303_0_1014
 * @param brandItem
 * @returns {number}
 */
function calcBrandVAT({ brandTotal, products, shippingFee }) {
  const brandVAT = products.reduce((acc, { totalPrice, VATItems }) => {
    /**
     * The formula provided by a Marketplace Documentation - Logic & Rules.
     * https://docs.google.com/presentation/d/19ZGsmEMSfMB07XLr7FuPs5IeBpdCsg2tEo0psGg5a7k/edit#slide=id.gf2f4f94f15_0_160
     * @type {number}
     */
    const productsVAT = new BigNumber(totalPrice * (VATItems / 100)).decimalPlaces(2).toNumber();
    /**
     * The formula provided by a Marketplace Documentation - Logic & Rules.
     * https://docs.google.com/presentation/d/19ZGsmEMSfMB07XLr7FuPs5IeBpdCsg2tEo0psGg5a7k/edit#slide=id.gf2f4f94f15_0_215
     * @type {number}
     */
    const sippingFeeVAT = brandTotal < MINIMAL_ORDER_FOR_FREE_SHIPPING
      ? new BigNumber((totalPrice / brandTotal) * (VATItems / 100) * shippingFee).decimalPlaces(2).toNumber() : 0;
    /**
     * The formula provided by a Marketplace Documentation - Logic & Rules.
     * https://docs.google.com/presentation/d/19ZGsmEMSfMB07XLr7FuPs5IeBpdCsg2tEo0psGg5a7k/edit#slide=id.gcc1c2d9b3a_0_42
     * @type {number}
     */
    const totalBrandVAT = productsVAT + sippingFeeVAT;

    // acc.productsVAT += Number((productsVAT).toFixed(2));
    // acc.sippingFeeVAT += Number((sippingFeeVAT).toFixed(2));
    // acc.totalBrandVAT += Number((totalBrandVAT).toFixed(2));
    acc.productsVAT += productsVAT;
    acc.sippingFeeVAT += sippingFeeVAT;
    acc.totalBrandVAT += totalBrandVAT;

    return acc;
  }, { productsVAT: 0, sippingFeeVAT: 0, totalBrandVAT: 0 });

  // SHIPPING FEE VAT CALCULATION AS CALCULATED IN INVOICES
  /* The following calculation calculate the shipping fee VAT amount in the same way as in invoices - by
  adding products of same VATs into groups and using this group total value for calculating the fractional 
  shipping fee VAT amount (instead of using each product amount for calculating the fraction shipping fee
    VAT amount) */

  const shippingFeeVatGroups = products.reduce((groups, { VATItems: productVatTaxRate, totalPrice },) => {
    if (groups.hasOwnProperty(productVatTaxRate)) {
      groups[productVatTaxRate].quantity++;
      groups[productVatTaxRate].totalGroupPrice += totalPrice;
      return groups;
    }
    groups[productVatTaxRate] = { productVatTaxRate, quantity: 1, totalGroupPrice: totalPrice };
    return groups;
  }, {});

  const shippingFeeVatGroupsOrdered = Object.values(shippingFeeVatGroups);

  const shippingFeeVatAmount = shippingFeeVatGroupsOrdered.map((taxesGroup) => new BigNumber((taxesGroup.totalGroupPrice / brandTotal) * shippingFee * (taxesGroup.productVatTaxRate / 100)).decimalPlaces(2).toNumber());

  const initialValues = 0;

  const totalShippingFeeVatAmount = brandTotal < MINIMAL_ORDER_FOR_FREE_SHIPPING
    ? new BigNumber(shippingFeeVatAmount.reduce(
      (previousValues, currentValues) => previousValues + currentValues,
      initialValues
    )).decimalPlaces(2).toNumber() : 0;

  // END OF SHIPPING FEE CALCULATION AS CALCULATED IN INVOICES

  return new BigNumber(brandVAT.totalBrandVAT - brandVAT.sippingFeeVAT + totalShippingFeeVatAmount).decimalPlaces(2).toNumber();
}

export function normalizeShoppingCart(data) {
  const cart = {
    shoppingData: {
      brands: [], invalidBrands: [], validBrands: [],
      cartInfo: {
        // TODO: Would be better to have more descriptive name. ( for ex.: [orderTotalExclVAT])
        subTotal: 0,
        // TODO: Would be better to remove this variable, because we have orderTotalInclVAT on the next steps.
        //  Or rename it! (for ex.: orderTotalExclVATInclShippingCosts)
        orderTotal: 0,
        // TODO: Would be better to have more descriptive name. ( for ex.: [orderTotalShippingCosts])
        shippingCosts: 0,
        // TODO: Looks like unused variable.
        vatShipping: 0,
        valid: true,
      },
    }, shoppingCartProductCount: 0,
  };
  const cartData = cart.shoppingData;
  const currentBrands = data?.brands?.map((b) => ({ ...b })) || [];
  cart.shoppingData.deliveryData = data.deliveryData || initDeliveryData(data.currentCompany);
  cart.shoppingData.paymentData = data.paymentData || { valid: false };
  cart.shoppingData.confirmData = data.confirmData || { valid: false };
  cart.shoppingData.currentStep = data.currentStep || 0;
  let brandsDataValid = true;
  let vat = 0;
  let vatVegshelf = 0;
  let vatShipping = 0;
  let key = `scid__`;

  for (let i = 0; i < currentBrands.length; i++) {
    const shippingFee = currentBrands[i]?.shippingFee;

    const brandItem = {
      brandId: currentBrands[i].brandId,
      brandName: currentBrands[i].brandName,
      bardImageUrl: currentBrands[i].bardImageUrl,
      shippingFee: Number(shippingFee),
      shippingDays: currentBrands[i].shippingDays,
      shippingDaysMessage: calcShipsDates(currentBrands[i].shippingDays),
      transaction: currentBrands[i].transaction,
      transactionState: currentBrands[i].transactionState,
      cancelled: currentBrands[i].cancelled,
      products: [],
      brandTotal: 0,
      valid: true,
      // TODO: Next two fields use the same values (capitalized country name) but have the different name patterns,
      //  what can confuse for the first view. (country | countryCode)
      brandCountryCode: currentBrands[i].brandCountryCode,
      currentUserCountry: currentBrands[i].currentUserCountry,
      vatVegshelf: 0,
      // TODO: Would be better to have more descriptive name. ( for ex.: [totalBrandProducts])
      productCount: 0,
      brandVAT: 0,
      // Additional value added for subscription based model pilot
      // pilotUser added to brandItem from currentBrands to track pilotUser status in Flex console
      pilotUser: currentBrands[i].pilotUser,
    };

    key += `__brandId_${brandItem.brandId}`;
    for (let j = 0; j < currentBrands[i].products.length; j++) {
      const productItem = {
        id: currentBrands[i].products[j].id,
        productName: currentBrands[i].products[j].productName,
        price: parseFloat(currentBrands[i].products[j].price),
        unitsOfPackage: parseFloat(currentBrands[i].products[j].unitsOfPackage),
        mainImageUrl: currentBrands[i].products[j].mainImageUrl,
        count: currentBrands[i].products[j].count,
        VATItems: currentBrands[i].products[j].VATItems,
        totalPrice: new BigNumber(currentBrands[i].products[j].count * currentBrands[i].products[j].price).decimalPlaces(2).toNumber(),
        productShippingDays: currentBrands[i].products[j].productShippingDays,
        productShippingDaysCode: currentBrands[i].products[j].productShippingDaysCode,
        weight: currentBrands[i].products[j].weight,
        weightUnit: currentBrands[i].products[j].weightUnit,
      };
      brandItem.products.push(productItem);
      brandItem.brandTotal += productItem.totalPrice;
      brandItem.productCount += productItem.count;
    }

    if (currentBrands[i].brandCountryCode === currentBrands[i].currentUserCountry) {
      const { products, brandTotal, shippingFee } = brandItem;
      brandItem.brandVAT = calcBrandVAT({ products, brandTotal, shippingFee });
    }

    if (brandItem.brandTotal < 100) {
      brandItem.valid = false;
    }

    cart.shoppingCartProductCount += brandItem.productCount;

    if (brandItem.products.length > 0) {
      cartData.brands.push(brandItem);
      if (brandItem.valid) {
        cartData.validBrands.push(brandItem);
        cartData.cartInfo.subTotal += brandItem.cancelled ? 0 : brandItem.brandTotal;
        const vatVegshelfValue = calcVegshelfVat(currentBrands[i].brandCountryCode);

        if (vatVegshelfValue) {
          brandItem.vatVegshelf = vatVegshelfValue;
          vatVegshelf += brandItem.brandTotal * (parseFloat(`${vatVegshelfValue}`) / 100);
        }

        vat += brandItem.cancelled ? 0 : brandItem.brandVAT;
      } else
        cartData.invalidBrands.push(brandItem);
    }

    // Test code for calculating shipping fee VAT amount as in invoices

    const taxRateGroups = brandItem.products.reduce((groups, { VATItems: taxRate, totalPrice },) => {
      if (groups.hasOwnProperty(taxRate)) {
        groups[taxRate].quantity++;
        groups[taxRate].totalGroupPrice += totalPrice;
        return groups;
      }
      groups[taxRate] = { taxRate, quantity: 1, totalGroupPrice: totalPrice };
      return groups;
    }, {});

    const productAmountsAndTaxGroups = Object.values(taxRateGroups);

    cartData.productAmountsAndTaxGroups = productAmountsAndTaxGroups;

    cartData.shippingFeesPerTaxGroup = productAmountsAndTaxGroups.map((taxGroup) => new BigNumber((taxGroup.totalGroupPrice / brandItem.brandTotal) * shippingFee * (taxGroup.taxRate / 100)).decimalPlaces(2).toNumber());

    const initialValue = 0;

    const shippingFeeVat = brandItem.brandTotal < MINIMAL_ORDER_FOR_FREE_SHIPPING
      ? new BigNumber(cartData.shippingFeesPerTaxGroup.reduce(
        (previousValue, currentValue) => previousValue + currentValue,
        initialValue
      )).decimalPlaces(2).toNumber() : 0;

    cartData.shippingFeeVat = shippingFeeVat;

    // End of test code for calculating shipping fee VAT amount as in invoices
  }

  /**
   * @description Shipping costs.
   */
  cartData.cartInfo.shippingCosts = cartData.validBrands.reduce((acc, { brandTotal, shippingFee }) => {
    acc += calculateBrandShippingFee({ brandTotal, shippingFee });
    return acc;
  }, 0);

  for (let i = 0; i < cartData.validBrands.length; i++) {
    const brandItem = cartData.validBrands[i];

    if (brandItem.cancelled) {
      cartData.cartInfo.shippingCosts -= brandItem.shippingFee;
    }

    if (brandItem.brandCountryCode === brandItem.currentUserCountry && !brandItem.cancelled) {
      vatShipping += brandItem.products.reduce((c, item) => {
        return parseFloat((c + ((item.totalPrice / brandItem.brandTotal) * (item.VATItems / 100)) * brandItem.shippingFee).toFixed(2))
      }, 0);
    }
  }

  cartData.cartInfo.vatShipping = vatShipping;

  brandsDataValid = cartData.validBrands.length > 0;
  validateBrands(cart.shoppingData, brandsDataValid);
  validateDeliveryData(
    cart.shoppingData,
    cartData.brands.length < 1 ? null : cartData.brands[0].currentUserCountry,
  );
  validatePaymentData(cart.shoppingData, data.currentUser);
  validateConfirm(cart.shoppingData);
  cartData.key = key;
  cart.date = new Date();
  cartData.cartInfo.orderVAT = cartData.validBrands.reduce((acc, { cancelled, brandVAT }) => {
    acc += cancelled ? 0 : brandVAT;
    return acc;
  }, 0);
  cartData.cartInfo.orderTotal = new BigNumber(cartData.cartInfo.subTotal + cartData.cartInfo.shippingCosts).decimalPlaces(2).toNumber();
  cartData.cartInfo.orderTotalInclVAT = new BigNumber(cartData.cartInfo.orderTotal + cartData.cartInfo.orderVAT).decimalPlaces(2).toNumber();

  return cart;
}

function validateBrands(data, state) {
  data.brandValid = state;
  if (data.currentStep === ShoppingCartSteps.Products) {
    data.cartInfo.valid = state;
  }
}

function validateConfirm(data) {
  if (data.currentStep === ShoppingCartSteps.ConfirmOrder) {
    data.cartInfo.valid = data.confirmData.valid;
  }
}

function initDeliveryData(currentCompany) {
  if (!currentCompany) {
    return null;
  }

  return {
    companyName: currentCompany?.companyName,
    country: currentCompany?.country,
    streetAndNumber: currentCompany?.streetAndNumber,
    city: currentCompany?.city,
    postCode: currentCompany?.postcode,
    additionalInfo: "",
    valid: false,
  };
}

function validateDeliveryData(data, currentUserCountry) {
  let state = true;
  data.deliveryData = data.deliveryData || { valid: false, country: currentUserCountry };

  if (data.deliveryData) {
    if (!data.deliveryData.companyName) {
      state = false;
    }
    if (currentUserCountry) {
      data.deliveryData.country = currentUserCountry;
    }
    if (!data.deliveryData.streetAndNumber) {
      state = false;
    }
    if (!data.deliveryData.city) {
      state = false;
    }
    if (!data.deliveryData.postCode) {
      state = false;
    }
    data.deliveryData.valid = state;
  }
  if (data.currentStep === ShoppingCartSteps.DeliveryData) {
    data.cartInfo.valid = state;
  }
}

function validatePaymentData(data, currentUser) {
  let state = !!currentUser?.stripeCustomer?.defaultPaymentMethod?.attributes?.stripePaymentMethodId;
  let card = currentUser?.stripeCustomer?.defaultPaymentMethod?.attributes?.card;
  data.paymentData.valid = state;
  data.paymentData.card = card;
  if (data.currentStep === ShoppingCartSteps.PaymentData) {
    data.cartInfo.valid = state;
  }
}

export const shoppingCartSave = (payment_method, card) => (dispatch, getState, sdk) => {
  dispatch(shoppingCartSaveRequest());
  const state = getState();
  const currentUser = state.user?.currentUser;
  const shoppingCart = {
    ...state.ShoppingCartStore.shoppingCart,
    paymentData: {
      ...state.ShoppingCartStore.shoppingCart.paymentData,
      card,
    }
  };
  const currentCompany = state.buyerCompany.buyerCurrentCompany;
  const suppliers = currentUser?.attributes?.profile?.protectedData?.suppliers || [];
  const locale = storedLocale() || localePart();

  return createTransactions(shoppingCart, currentCompany, suppliers, sdk, payment_method, dispatch)
    .then((res) => {
      if (shoppingCart.confirmData.saveDeliveryData) {
        dispatch(buyerUpdateDeliveryAddress(shoppingCart.deliveryData));
      }

      // TODO: Remove.
      if (!shoppingCart.confirmData.savePaymentData) {
        // dispatch(deletePaymentMethod());
      }

      // TODO: Move to chargeOrderTransaction action.
      for (let i = 0; i < shoppingCart?.brands.length; i++) {
        dispatch(sendNewOrderNotification(shoppingCart?.brands[i], shoppingCart?.cartInfo, locale));
      }

      // TODO: Remove.
      // dispatch(shoppingCartSaveSuccess());
      return res;
    })
    .catch(() => dispatch(shoppingCartSaveError()));
};

async function createTransactions(shoppingCart, currentCompany, suppliers, sdk, payment_method, dispatch) {
  const currentBrands = shoppingCart?.validBrands;
  const newSuppliers = [...suppliers];

  const uniqueBuyerOrderId = currentBrands.reduce((accId, currentBrand) => {
    return `${accId}_|_brandId_${currentBrand.brandId}`;
  }, `${new Date().toISOString()}_companyId_${currentCompany.id.uuid}`);

  const orderDate = currentBrands.reduce((accId) => {
    return `${accId}`;
  }, `${new Date().toLocaleString("en-GB", { timeZone: "Europe/Berlin" }).split(',')[0]}`);

  const createdOrders = [];
  for (let i = 0; i < currentBrands.length; i++) {
    const brand = currentBrands[i];
    const shippingCosts = currentBrands[i].shippingFee;
    const transactionInput = {
      shoppingCart,
      currentCompany,
      suppliers,
      brand,
      shippingCosts,
      sdk,
      payment_method,
      uniqueBuyerOrderId,
      orderDate,
    };

    const order = await createBrandTransaction(transactionInput);
    const orderId = order?.data?.data?.id?.uuid;
    const fetchedOrderById = await sdk.transactions.show({ id: orderId });
    if (fetchedOrderById.data) {
      createdOrders.push(fetchedOrderById.data.data);
      dispatch(saveOrder(fetchedOrderById.data.data));
    }

    if (newSuppliers.indexOf(brand.brandId) < 0) {
      newSuppliers.push(brand.brandId);
    }
  }

  const companyItem = { protectedData: { suppliers: newSuppliers } };

  await sdk.currentUser.updateProfile(companyItem);

  return createdOrders;
}

async function createBrandTransaction(params) {
  const { shoppingCart, currentCompany, suppliers, brand, shippingCosts, payment_method, uniqueBuyerOrderId, orderDate } = params;
  const { lineItems, vegshelfCommission } = createLineItems(suppliers, brand);
  const bodyParams = {
    // TODO: Double check!
    // transition: "transition/request-order",
    transition: TransitionStates.pending_non_authorized_order,
    processAlias: config.bookingProcessAlias,
    params: {
      lineItems,
      listingId: new UUID(brand.products[0].id),
      paymentMethod: payment_method,
      protectedData: {
        shippingCosts,
        brandInfo: brand,
        total: brand.brandTotal,
        cart: {
          ...shoppingCart,
          uniqueBuyerOrderId,
          orderDate,
          vegshelfCommission,
        },
        companyId: currentCompany.id.uuid,
        companyName: currentCompany?.attributes?.publicData?.companyName,
        companyLegalName: currentCompany?.attributes?.publicData?.companyLegalName,
        trade_name: currentCompany?.attributes?.publicData?.brandName,
        companyLogo: currentCompany?.attributes?.publicData?.logoUrl,
        address: currentCompany?.attributes?.publicData?.streetAndNumber,
        country: currentCompany?.attributes?.publicData?.country,
        pin: currentCompany?.attributes?.publicData?.companyRegistrationNumber,
        tin: currentCompany?.attributes?.publicData?.companyVATnumber,
        city: currentCompany?.attributes?.publicData?.city,
        postcode: currentCompany?.attributes?.publicData?.postcode,
      },
    },
  };

  return await initiatePrivileged({ bodyParams });
}

function createLineItems(suppliers, brand) {
  const products = brand.products.map((product) => {
    return ({
      code: `line-item/${product.id}`,
      unitPrice: { amount: parseFloat(product.price) * 100, currency: CURRENCY },
      quantity: parseFloat(product.count),
      includeFor: ["customer", "provider"],
    });
  });
  const totalPrice = calculateTotalFromLineItems(products);
  const isFirstBuy = suppliers.indexOf(brand.brandId) < 0;
  
  // Additional value added for subscription based model pilot
  // additional validator to check if user is part of pilot
  const isPilotUser = brand.pilotUser === true;

  // redefining commissions to ensure subscription based model pilot and fee based models works in parallel
  const firstCommission = isPilotUser ? PILOT_PROVIDER_COMMISSION_PERCENTAGE_FIRST : PROVIDER_COMMISSION_PERCENTAGE_FIRST;
  const secondCommission = isPilotUser ? PILOT_PROVIDER_COMMISSION_PERCENTAGE_SECOND : PROVIDER_COMMISSION_PERCENTAGE_SECOND;
  
  // Replaced commission calculation to ensure subscription based model pilot and fee based models works in parallel
  //const commissionPercentage = isFirstBuy ? PROVIDER_COMMISSION_PERCENTAGE_FIRST : PROVIDER_COMMISSION_PERCENTAGE_SECOND;
  const commissionPercentage = isFirstBuy ? firstCommission : secondCommission;
  
  const vatVegshelfValue = new BigNumber((totalPrice.amount / 100) * (-commissionPercentage / 100)).decimalPlaces(2).toNumber();

  /**
   * VegShelf order commission.
   */
  const providerCommission = {
    code: "line-item/provider-commission",
    unitPrice: totalPrice,
    percentage: commissionPercentage,
    includeFor: ["provider"],
  };

  /**
   * VegShelf  order commission VAT.
   */
  const providerCommissionVAT = {
    code: `line-item/provider-commission-${brand.brandCountryCode}-vat`,
    unitPrice: { amount: (vatVegshelfValue) * 100, currency: CURRENCY },
    percentage: -brand.vatVegshelf,
    includeFor: ["provider"],
  };

  const lineItems = [...products, providerCommission, providerCommissionVAT];

  const brandShippingFee = (totalPrice.amount / 100) < MINIMAL_ORDER_FOR_FREE_SHIPPING ? brand.shippingFee : 0;

  lineItems.push({
    code: "line-item/brand-shipping-fee",
    unitPrice: { amount: (brandShippingFee) * 100, currency: CURRENCY },
    quantity: 1,
    includeFor: ["customer", "provider"],
  });

  lineItems.push({
    code: "line-item/brand-vat",
    unitPrice: { amount: new BigNumber(brand.brandVAT * 100).decimalPlaces(2).toNumber(), currency: CURRENCY },
    quantity: 1,
    includeFor: ["customer", "provider"],
  });

  const vegshelfCommissionNetPrice = new BigNumber(totalPrice.amount * (-commissionPercentage / 100) / 100).decimalPlaces(2).toNumber();
  const vegshelfCommissionTaxAmount = new BigNumber(vegshelfCommissionNetPrice * (brand.vatVegshelf / 100) / 100).decimalPlaces(2).toNumber();

  return {
    lineItems,
    // Data for the commission invoice generation.
    vegshelfCommission: {
      commissionPercentage: Math.abs(commissionPercentage),
      net_price: vegshelfCommissionNetPrice,
      tax_rate: brand.vatVegshelf,
      total_tax_amount: vegshelfCommissionTaxAmount,
    },
  };
}

function calculateTotalFromLineItems(lines) {
  let sum = 0;
  for (let i = 0; i < lines.length; i++) {
    sum += calculateLineTotal(lines[i]);
  }

  return { amount: sum, currency: CURRENCY };
}

function calculateLineTotal(lineItem) {
  const { unitPrice, quantity, percentage } = lineItem;

  if (quantity) {
    return unitPrice.amount * quantity;
  } else if (percentage) {
    return unitPrice.amount * (percentage / 100);
  }

  return 0;
}
