/**
 * Note: This form is using card from Stripe Elements https://stripe.com/docs/stripe-js#elements
 * Card is not a Final Form field so it's not available trough Final Form.
 * It's also handled separately in handleSubmit function.
 */
import classNames from "classnames";
import { func, object, string } from "prop-types";
import React, { Component } from "react";
import { Form as FinalForm } from "react-final-form";
import { FieldTextInput, Form, PrimaryButton } from "../../components";
import config from "../../config";
import { FormattedMessage, injectIntl, intlShape } from "../../util/reactIntl";
import * as validators from "../../util/validators";
import { LabelWithErrorMsg } from "../ContactDetailsForm/ContactDetailsForm";
import css from "./PaymentMethodsForm.module.css";

/**
 * Translate a Stripe API error object.
 *
 * To keep up with possible keys from the Stripe API, see:
 *
 * https://stripe.com/docs/api#errors
 *
 * Note that at least at moment, the above link doesn't list all the
 * error codes that the API returns.
 *
 * @param {Object} intl - react-intl object from injectIntl
 * @param {Object} stripeError - error object from Stripe API
 *
 * @return {String} translation message for the specific Stripe error,
 * or the given error message (not translated) if the specific error
 * type/code is not defined in the translations
 *
 */
const stripeErrorTranslation = (intl, stripeError) => {
  const { message, code, type } = stripeError;

  if (!code || !type) {
    /**
     * Not a proper Stripe error object
     */
    return intl.formatMessage({ id: "PaymentMethodsForm.genericError" });
  }

  const translationId = type === "validation_error"
    ? `PaymentMethodsForm.stripe.validation_error.${code}` : `PaymentMethodsForm.stripe.${type}`;

  return intl.formatMessage({
    id: translationId,
    defaultMessage: message,
  });
};

const stripeElementsOptions = {
  fonts: [
    {
      cssSrc: 'https://fonts.googleapis.com/css?family=Lato:400',
    },
  ],
};

const cardStyles = {
  base: {
    fontFamily: "'Lato', Helvetica, Arial, sans-serif", 
    fontSize: "15px",
    fontSmoothing: "antialiased",
    lineHeight: "21px",
    letterSpacing: "0",
    color: "#282828",
    "::placeholder": {
      color: "#a3a3a3",
    },
  },
};

/**
 *
 * @type {{cardValueValid: boolean, error: null}}
 */
const initialState = {
  error: null,
  cardValueValid: false,
};

/**
 * Payment methods form that asks for credit card info using Stripe Elements.
 *
 * When the card is valid and the user submits the form, a request is
 * sent to the Stripe API to handle card setup. `stripe.handleCardSetup`
 * may ask more details from cardholder if 3D security steps are needed.
 *
 * See: https://stripe.com/docs/payments/payment-intents
 *      https://stripe.com/docs/elements
 */
class PaymentMethodsForm extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.handleCardValueChange = this.handleCardValueChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.paymentForm = this.paymentForm.bind(this);
    this.stripe = null;
  }

  handleCardValueChange(event) {
    const { intl } = this.props;
    const { error, complete, elementType, empty } = event;

    this.setState((prev) => {
      let errorMsg = null;
      if (!complete) {
        errorMsg = error && stripeErrorTranslation(intl, error);

        if (elementType === "postalCode" && empty) {
          errorMsg = intl.formatMessage({ id: "BuyerCompanyForm.requiredField" });
        }
      }

      return ({ ...prev, [elementType]: errorMsg });
    });
  }

  componentDidMount() {
    if (!window.Stripe) {
      throw new Error("Stripe must be loaded for PaymentMethodsForm");
    }

    if (config.stripe.publishableKey) {
      this.stripe = window.Stripe(config.stripe.publishableKey);
      const elements = this.stripe.elements(stripeElementsOptions);

      this.card = elements.create("cardNumber", { style: cardStyles });
      this.card && this.card.mount(this.cardContainer);
      this.card.addEventListener("change", this.handleCardValueChange);

      this.cardExpiry = elements.create("cardExpiry", { style: cardStyles });
      this.cardExpiry && this.cardExpiry.mount(this.cardExpiryContainer);
      this.cardExpiry.addEventListener("change", this.handleCardValueChange);

      this.cardCvc = elements.create("cardCvc", { style: cardStyles });
      this.cardCvc && this.cardCvc.mount(this.cardCvcContainer);
      this.cardCvc.addEventListener("change", this.handleCardValueChange);

      this.postalCode = elements.create("postalCode", { style: cardStyles });
      this.postalCode && this.postalCode.mount(this.postalCodeContainer);
      this.postalCode.addEventListener("change", this.handleCardValueChange);

      /**
       * EventListener is the only way to simulate breakpoints with Stripe.
       */
      window.addEventListener("resize", () => {
        if (window.innerWidth < 1024) {
          this.card.update({ style: { base: { fontSize: "18px", lineHeight: "24px" } } });
        } else {
          this.card.update({ style: { base: { fontSize: "20px", lineHeight: "32px" } } });
        }
      });

    }
  }

  async handleSubmit(values) {
    const { onSubmit, inProgress, formId } = this.props;

    const cardErrorMessage = this.state["cardNumber"];
    const cardExpiryErrorMessage = this.state["cardExpiry"];
    const cardCvcErrorMessage = this.state["cardCvc"];
    const postalCodeErrorMessage = this.state["postalCode"];
    const cardInputsNeedsAttention = cardErrorMessage
      || cardExpiryErrorMessage
      || cardCvcErrorMessage
      || postalCodeErrorMessage;


    if (inProgress || cardInputsNeedsAttention) {
      /**
       * Already submitting or card value incomplete/invalid
       */
      return;
    }

    const params = {
      stripe: this.stripe,
      card: this.card,
      cardExpiry: this.cardExpiry,
      cardCvc: this.cardCvc,
      postalCode: this.postalCode,
      formValues: values,
      formId,
    };

    return await onSubmit(params);
  }

  paymentForm(formRenderProps) {
    const {
      className,
      rootClassName,
      inProgress: submitInProgress,
      intl,
      // invalid,
      handleSubmit,
      addPaymentMethodError,
      deletePaymentMethodError,
      createStripeCustomerError,
      handleCardSetupError,
      form,
      // componentMode,
      currentBillingData,
      termsValue,
    } = formRenderProps;

    /**
     * @description Stripe public API key.
     * @type {string}
     */
    const isStripeKeyExists = config.stripe.publishableKey;

    // ================ general ================ //
    const pageName = "BuyerCompanyForm";
    const hasError = (field) => form.getState().touched[field] && form.getState().errors.hasOwnProperty(field) || false;
    const getInputFormattedMessage = (name, options) => intl.formatMessage({ id: `${pageName}.${name}` }, options);
    const requiredFieldFormattedMessage = getInputFormattedMessage("requiredField");
    const requiredField = validators.required(requiredFieldFormattedMessage);

    // ================ card ================ //
    const cardInputId = "cardNumber";
    const cardLabel = getInputFormattedMessage(`${cardInputId}Label`);
    const cardErrorMessage = this.state[cardInputId];
    const cardClasses = classNames(css.card, {
      [css.cardSuccess]: !cardErrorMessage,
      [css.cardError]: cardErrorMessage,
    });

    // ================ cardExpiry ================ //
    const cardExpiryInputId = "cardExpiry";
    const cardExpiryLabel = getInputFormattedMessage(`${cardExpiryInputId}Label`);
    const cardExpiryErrorMessage = this.state[cardExpiryInputId];
    const cardExpiryClasses = classNames(css.card, {
      [css.cardSuccess]: !cardExpiryErrorMessage,
      [css.cardError]: cardExpiryErrorMessage,
    });

    // ================ cardCvc ================ //
    const cardCvcInputId = "cardCvc";
    const cardCvcLabel = getInputFormattedMessage(`${cardCvcInputId}Label`);
    const cardCvcErrorMessage = this.state[cardCvcInputId];
    const cardCvcClasses = classNames(css.card, {
      [css.cardSuccess]: !cardCvcErrorMessage,
      [css.cardError]: cardCvcErrorMessage,
    });

    // ================ postalCode ================ //
    const postalCodeInputId = "postalCode";
    const postalCodeLabel = getInputFormattedMessage(`${postalCodeInputId}Label`);
    const postalCodeErrorMessage = this.state[postalCodeInputId];
    const postalCodeClasses = classNames(css.card, {
      [css.cardSuccess]: !postalCodeErrorMessage,
      [css.cardError]: postalCodeErrorMessage,
    });

    // ================ name ================ //
    const nameInputId = "name";
    const nameLabel = getInputFormattedMessage(`${nameInputId}Label`);
    const namePlaceholder = getInputFormattedMessage(`${nameInputId}Placeholder`);
    const nameAutoComplete = "cc-name";
    const isNameError = hasError(nameInputId);
    const nameOnUnmount = () => form.change(nameInputId);

    const classes = classNames(rootClassName || css.root, className);

    const hasErrors = addPaymentMethodError
      || deletePaymentMethodError
      || createStripeCustomerError
      || handleCardSetupError;

    const errorMessage = intl.formatMessage({ id: "PaymentMethodsForm.genericError" });
    const missingStripeKey = intl.formatMessage({ id: "PayoutDetailsForm.missingStripeKey" });

    return isStripeKeyExists ? (
      <Form className={classes} onSubmit={handleSubmit}>
        <div className={css.formField}>
          <LabelWithErrorMsg
            className={css.inputLabelMargin}
            isRequired
            htmlFor={cardInputId}
            labelText={cardLabel}
            errorMsg={cardErrorMessage}
            isError={!!cardErrorMessage}
          >
            <div
              id={cardInputId}
              className={cardClasses}
              ref={(el) => {
                this.cardContainer = el
              }}
            />
          </LabelWithErrorMsg>
        </div>
        <div className={css.smallCardFields}>
          <div className={css.expiryField}>
            <LabelWithErrorMsg
              className={css.inputLabelMargin}
              isRequired
              htmlFor={cardExpiryInputId}
              labelText={cardExpiryLabel}
              errorMsg={cardExpiryErrorMessage}
              isError={!!cardExpiryErrorMessage}
            >
              <div
                className={cardExpiryClasses}
                ref={(el) => {
                  this.cardExpiryContainer = el
                }}
              />
            </LabelWithErrorMsg>
          </div>
          <div className={css.cvcField}>
            <LabelWithErrorMsg
              className={css.inputLabelMargin}
              isRequired
              htmlFor={cardCvcInputId}
              labelText={cardCvcLabel}
              errorMsg={cardCvcErrorMessage}
              isError={!!cardCvcErrorMessage}
            >
              <div
                className={cardCvcClasses}
                ref={(el) => {
                  this.cardCvcContainer = el
                }}
              />
            </LabelWithErrorMsg>
          </div>
          <div className={css.postalCodeField}>
            <LabelWithErrorMsg
              className={css.inputLabelMargin}
              isRequired
              htmlFor={postalCodeInputId}
              labelText={postalCodeLabel}
              errorMsg={postalCodeErrorMessage}
              isError={!!postalCodeErrorMessage}
            >
              <div
                className={postalCodeClasses}
                ref={(el) => {
                  this.postalCodeContainer = el
                }}
              />
            </LabelWithErrorMsg>
          </div>
        </div>
        <div className={css.cardHoldersNameField}>
          <div className={css.formField}>
            <LabelWithErrorMsg
              className={css.inputLabelMargin}
              isRequired
              htmlFor={nameInputId}
              labelText={nameLabel}
              errorMsg={requiredFieldFormattedMessage}
              isError={isNameError}
            >
              <FieldTextInput
                type="text"
                name={nameInputId}
                id={nameInputId}
                placeholder={namePlaceholder}
                autoComplete={nameAutoComplete}
                validate={requiredField}
                onUnmount={nameOnUnmount}
              />
            </LabelWithErrorMsg>
          </div>
        </div>
        <div className={css.submitContainer}>
          {hasErrors ?
            <p className={css.errorMessage}>{hasErrors.message || errorMessage}</p>
            : null
          }
        </div>
        <div className={css.previewItemSeparator}>&nbsp;</div>
        <div className={css.previewItemData}>
          <div className={css.previewItemFirstColumn}>
            <h1 className={css.previewItemTitle}>
              <FormattedMessage id="PaymentMethodsForm.billingDetails" />
            </h1>
            <div className={css.previewItemValue}>{currentBillingData?.companyLegalName}</div>
            <div className={css.previewItemValue}>{currentBillingData?.streetAndNumber}</div>
            <div className={css.previewItemValue}>{currentBillingData?.city}</div>
            <div className={css.previewItemValue}>{currentBillingData?.postcode}, {currentBillingData?.country}</div>
          </div>
        </div>
        <div className={css.confirmButtonContainer}>
          {this.state.termsError ?
            <p className={css.termsError}>{this.state.termsError}</p>
            : null
          }
          <div className={css.confirmRow}>
            <input
              type="checkbox"
              name="confirmOrder"
              id="confirmOrder"
              value="true"
              onChange={(e) => this.props.onConfirmOrder(e)}
            />
            <label htmlFor="confirmOrder">
              <span className={css.fieldRequired}>*</span>
                <FormattedMessage id="PaymentMethodsForm.infoText" />
            </label>
          </div>
          <div className={css.confirmButtonContainer}>
            <PrimaryButton
              type="submit"
              className={css.confirmButton}
              disabled={submitInProgress || !termsValue}
              inProgress={submitInProgress}
            >
              <FormattedMessage id="PaymentMethodsForm.confirmAndPay" />
            </PrimaryButton>
          </div>
        </div>
      </Form>
    ) : (
      <div className={css.missingStripeKey}>
        {missingStripeKey}
      </div>
    );
  }

  render() {
    const { onSubmit, ...rest } = this.props;
    return <FinalForm onSubmit={this.handleSubmit} {...rest} render={this.paymentForm}/>;
  }
}

PaymentMethodsForm.defaultProps = {
  className: null,
  rootClassName: null,
  inProgress: false,
  onSubmit: null,
  addPaymentMethodError: null,
  deletePaymentMethodError: null,
  createStripeCustomerError: null,
  handleCardSetupError: null,
  form: null,
};

PaymentMethodsForm.propTypes = {
  formId: string,
  intl: intlShape.isRequired,
  onSubmit: func,
  addPaymentMethodError: object,
  deletePaymentMethodError: object,
  createStripeCustomerError: object,
  handleCardSetupError: object,
  form: object,
};

export default injectIntl(PaymentMethodsForm);
