import React, { Component } from 'react';
import { bool } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import config from '../../config';
import { displayRegistrationConfirmDialog } from '../../ducks/Modal.duck';
import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  NamedLink,
} from '../../components';

import { TopbarContainer } from '..';
import { FormattedMessage } from '../../util/reactIntl';

import facebookImage from '../../assets/VegshelfFacebook-1200x630.png';
import twitterImage from '../../assets/VegshelfTwitter-600x314.png';
import css from './BlogPage.module.css';
import article4Thumbnail from '../../assets/vegan-food-and-beverage-products-square.png';
import article3Thumbnail from '../../assets/independent-grocery-shop-square.png';
import article2Thumbnail from '../../assets/vegan-products-in-coffee-shops-square.png';
import article1Thumbnail from '../../assets/vegan-products-in-retail-square.png';

export class BlogPageComponent extends Component {
  constructor(props) {
    super(props);
    //this.state = { categoryIndex: 0, planIndex: 1, blogIndex: 0, FAQIndex: -1 };
  }
  render() {
    const {
      intl,
      scrollingDisabled,
      params,
    } = this.props;

    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage({ id: 'BlogPage.schemaTitle' }, { siteTitle });
    const schemaDescription = intl.formatMessage({ id: 'BlogPage.schemaDescription' }, { siteTitle });
    const schemaImage = `${config.canonicalRootURL}${facebookImage}`;

    return (
      <Page
        className={css.root}
        scrollingDisabled={scrollingDisabled}
        contentType="website"
        description={schemaDescription}
        title={schemaTitle}
        robots='index'
        facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
        twitterImages={[
          { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
        ]}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'WebPage',
          description: schemaDescription,
          name: schemaTitle,
          image: [schemaImage],
        }}
      >
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div className={css.blogHeroContainer}>
              <div className={css.blogHeroSection}>
                <div className={css.latestBlogTextandImageSection}>
                  <div className={css.latestBlogText}>
                    <h1 className={css.latestBlogTitle}>
                      <FormattedMessage id="BlogMainPage.latestBlogTitle" />
                    </h1>
                    <p className={css.latestBlogSubtitle}>
                      <FormattedMessage id="BlogMainPage.latestBlogSubtitle" />
                    </p>
                    <div className={css.blogDateAuthorSection}>
                      <p className={css.blogDate}>
                        <FormattedMessage id="Blog4.date" />&nbsp; | &nbsp;
                      </p>
                      <p className={css.blogAuthor}>
                        <FormattedMessage id="BlogPage.authorBaiba" />
                      </p>
                    </div>
                    <div className={css.readLatestArticleButtonSection}>
                      <NamedLink className={css.readLatestArticleButton} name="BlogArticle4">
                        <FormattedMessage id="BlogMainPage.readLatestArticleButton" />
                      </NamedLink>
                    </div>
                  </div>
                  <div className={css.latestThumbnailImageContainer}>
                  <NamedLink name="BlogArticle4">
                    <img className={css.latestBlogImage} src={article4Thumbnail} alt="Where to source vegan food and beverage products for your business" />
                  </NamedLink>
                  </div>
                </div>
              </div>
            </div>
            <div className={css.blogGridViewContainer}>
              <div className={css.blogGridView}>


                <div className={css.singleBlogThumbnail}>
                  <NamedLink name="BlogArticle3">
                    <img className={css.singleBlogThumbnailImage} src={article3Thumbnail} alt="Why independent retail is the perfect sales channel for new food and beverage brands" />
                    <h2 className={css.singleBlogTitle}>
                      <FormattedMessage id="Blog3.title" />
                    </h2>
                  </NamedLink>
                  <div className={css.blogDateAuthorSection}>
                    <p className={css.blogDate}>
                      <FormattedMessage id="Blog3.date" />&nbsp; | &nbsp;
                    </p>
                    <p className={css.blogAuthor}>
                      <FormattedMessage id="BlogPage.authorBaiba" />
                    </p>
                  </div>
                  <div className={css.readSingleArticleButtonSection}>
                    <NamedLink className={css.readSingleArticleButton} name="BlogArticle3">
                      <FormattedMessage id="BlogMainPage.readSingleArticleButton" />
                    </NamedLink>
                  </div>
                </div>


                <div className={css.singleBlogThumbnail}>
                  <NamedLink name="BlogArticle2">
                    <img className={css.singleBlogThumbnailImage} src={article2Thumbnail} alt="Why coffee shops need to offer more vegan food and drinks" />
                    <h2 className={css.singleBlogTitle}>
                      <FormattedMessage id="Blog2.title" />
                    </h2>
                  </NamedLink>
                  <div className={css.blogDateAuthorSection}>
                    <p className={css.blogDate}>
                      <FormattedMessage id="Blog2.date" />&nbsp; | &nbsp;
                    </p>
                    <p className={css.blogAuthor}>
                      <FormattedMessage id="BlogPage.authorBaiba" />
                    </p>
                  </div>
                  <div className={css.readSingleArticleButtonSection}>
                    <NamedLink className={css.readSingleArticleButton} name="BlogArticle2">
                      <FormattedMessage id="BlogMainPage.readSingleArticleButton" />
                    </NamedLink>
                  </div>
                </div>


                <div className={css.singleBlogThumbnail}>
                  <NamedLink name="BlogArticle1">
                    <img className={css.singleBlogThumbnailImage} src={article1Thumbnail} alt="Why retailers need more vegan products in assortment" />
                    <h2 className={css.singleBlogTitle}>
                      <FormattedMessage id="Blog1.title" />
                    </h2>
                  </NamedLink>
                  <div className={css.blogDateAuthorSection}>
                    <p className={css.blogDate}>
                      <FormattedMessage id="Blog1.date" />&nbsp; | &nbsp;
                    </p>
                    <p className={css.blogAuthor}>
                      <FormattedMessage id="BlogPage.authorBaiba" />
                    </p>
                  </div>
                  <div className={css.readSingleArticleButtonSection}>
                    <NamedLink className={css.readSingleArticleButton} name="BlogArticle1">
                      <FormattedMessage id="BlogMainPage.readSingleArticleButton" />
                    </NamedLink>
                  </div>
                </div>
              </div>
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page >
    );
  }
};

BlogPageComponent.defaultProps = {
};

BlogPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired
};
const mapStateToProps = state => {

  return {
    scrollingDisabled: isScrollingDisabled(state)
  };
};

const mapDispatchToProps = dispatch => ({
  onShowRegistrationConfirmDialog: () => dispatch(displayRegistrationConfirmDialog(true, false))
});

const BlogPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(BlogPageComponent);

export default BlogPage;