import { bool, object } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";

import facebookImage from "../../assets/VegshelfFacebook-1200x630.png";
import twitterImage from "../../assets/VegshelfTwitter-600x314.png";
import {
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  NamedLink,
  Page,
  ProductContainer,
} from "../../components";
import LoaderComponent from "../../components/Loader/Loader";
import config from "../../config";
import { TopbarContainer } from "../../containers";
import { displayRegistrationConfirmDialog } from "../../ducks/Modal.duck";
import {
  parseListingState,
  supplierCreateCompany,
  supplierDeleteCompanyCover,
  supplierUpdateCompanyCover,
  supplierUpdateCompanyLogo,
} from "../../ducks/supplier-company.duck";
import { isScrollingDisabled } from "../../ducks/UI.duck";
import { FormattedMessage, injectIntl, intlShape } from "../../util/reactIntl";
import { propTypes } from "../../util/types";
import { loadSupplierDetails } from "../ProductDetailsPage/ProductDetailsPage.duck";
import { storedLocale, localePart } from "../../components/Wrapper/ClassWrapper";
import css from "./SupplierDetailsPage.module.css";

const locale = storedLocale() || localePart();

export const shippedCountriesMsg = (countries) => {
  return countries.reduce((acc, curr) => {
    const firstCountrySeparator = !acc ? "" : ", ";
    if (curr.isShip) {
      acc = acc + `${firstCountrySeparator}${curr.name}`;
    }

    return acc;
  }, "");
};

export class SupplierDetailsPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { viewLastIndex: 6 };
    this.newSupplier = null;
    this.onShowAllClick = this.onShowAllClick.bind(this);
  }

  onShowAllClick() {
    this.setState({ ...this.state, viewLastIndex: this.state.viewLastIndex + 6 });
  }

  render() {
    const {
      intl,
      scrollingDisabled,
      supplierDetails,
      supplierDetailsProgress,
      supplierOtherProductsProgress,
      supplierOtherProducts,
      isAuthenticated,
      currentUserType,
      onShowRegistrationConfirmDialog,
    } = this.props;

    const siteTitle = config.siteTitle;
    const companyData = supplierDetails?.attributes;
    const companyDetails = companyData?.publicData;
    const shippingCountries = shippedCountriesMsg(companyData?.publicData?.shippingFeeCountries || []);
    const supplierBrandName = companyDetails?.brandName;
    const schemaTitle = intl.formatMessage({ id: "SupplierDetailsPage.schemaTitle" }, { supplierBrandName, siteTitle });
    const schemaDescription = intl.formatMessage({ id: "SupplierDetailsPage.schemaDescription" }, { supplierBrandName, siteTitle });
    const schemaImage = `${config.canonicalRootURL}${facebookImage}`;
    const coverStyles = {};
    const companyLogo = {};
    if (companyDetails?.coverUrl) {
      coverStyles.backgroundImage = `url('${companyDetails?.coverUrl}')`;
    } else {
      coverStyles.backgroundColor = `#fcfaf7`;
    }
    if (companyDetails?.logoUrl) {
      companyLogo.backgroundImage = `url('${companyDetails?.logoUrl}')`;
    } else {
      companyLogo.backgroundColor = `#ffffff`;
    }
    let products = supplierOtherProducts?.data || [];
    products = products || [];
    products = [
      ...products.filter((p) => (parseListingState(p) === "published")),
      ...products.filter((p) => (parseListingState(p) === "outOfStock")),
    ];
    const lastIndex = isAuthenticated ? this.state.viewLastIndex : 3;
    const showExpandButton = lastIndex < products.length;

    products = products.slice(0, lastIndex);

    const emptyStateOptions = {
      containerOffsetClassName: "searchOffset",
      iconStylesClassName: "searchIcon",
      titleMessageId: "SearchProductsPage.noSearchResultTitle",
      textMessageId: "SearchProductsPage.noSearchResultText",
    };

    const loading = supplierDetailsProgress || supplierOtherProductsProgress;

    return (
      <Page
        className={css.root}
        scrollingDisabled={scrollingDisabled}
        contentType="website"
        description={schemaDescription}
        title={schemaTitle}
        robots='index'
        facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
        twitterImages={[
          { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
        ]}
        schema={{
          "@context": "http://schema.org",
          "@type": "WebPage",
          description: schemaDescription,
          name: schemaTitle,
          image: [schemaImage],
        }}
      >
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain>
            {loading ?
              <div className={css.loaderContainer}>
                <LoaderComponent />
              </div>
              :
              <div className={css.profileCompanyContainer}>
                <div className={css.coverContainer}>
                  <div className={css.coverPhotoContainer} style={coverStyles}>&nbsp;
                  </div>
                </div>
                <div className={css.profileCompanySection}>
                  <div className={css.companyInfoPanel}>
                    <div className={css.companyInfoFirstColumn}>
                      <div className={css.companyLogo} style={companyLogo}>
                        {!companyDetails?.logoUrl ?
                          <span className={css.companyLogoInitials}>
                            {companyDetails?.companyName.charAt(0)}
                          </span>
                          : null
                        }
                      </div>
                      <h1 className={css.companyName}>{companyDetails?.brandName}</h1>
                      <h2 className={css.companyLocation}>{companyDetails?.city}, {companyDetails?.country}</h2>
                      <div className={css.companyPropertyRow}>
                        <p className={css.companyFieldLabel}>
                          <FormattedMessage id="SupplierDetailsPage.LabelLeadTime" />
                        </p>
                        <span className={css.companyFieldValue}>{companyDetails?.leadTime}</span>
                        <span className={css.companyFieldValueShipping}>{shippingCountries && ` to ${shippingCountries}`}</span>
                      </div>
                      <div className={css.companyPropertyRow}>
                        <p className={css.companyFieldLabel}>
                          <FormattedMessage id="SupplierDetailsPage.LabelMOQ" />
                        </p>
                        <span className={css.companyFieldValue}>€{companyDetails?.moq}</span>
                      </div>
                      <div className={css.companyPropertyRow}>
                        <p className={css.companyFieldLabel}>
                          <FormattedMessage id="UserFields.numberOfStockistsColon" />
                        </p>
                        <span className={css.companyFieldValue}>{companyDetails?.numberOfStores}</span>
                      </div>
                      <div className={css.companyPropertyRow}>
                        <p className={css.companyFieldLabel}>
                          <FormattedMessage id="UserFields.yearEstablishedColon" />
                        </p>
                        <span className={css.companyFieldValue}>{companyDetails?.establishedIn}</span>
                      </div>
                      <div className={css.companyPropertyRow}>
                        <p className={css.companyFieldLabel}>
                          <FormattedMessage id="UserFields.instagramColon" />
                        </p>
                        <a href={`https://www.instagram.com/${companyDetails?.instagram}`} target="_blank">
                          <span className={css.companyFieldValueIG}>{companyDetails?.instagram}</span>
                        </a>
                      </div>
                    </div>
                    <div className={css.companyInfoSecondColumn}>
                      <div className={css.editCompanyInfo}>
                        {isAuthenticated && currentUserType === "buyer" && supplierDetails?.id?.uuid ? (
                          <NamedLink
                            name="BuyerSendMessagePage"
                            className={css.contactBrandButton}
                            params={{ receiver: supplierDetails?.id?.uuid, title: "Question", locale: locale }}
                          >
                            <FormattedMessage id="SupplierDetailsPage.messageBrand" />
                          </NamedLink>) : null}
                        {/*TODO: Communication supplier-to-supplier temporary disabled.*/}
                        {/*{isAuthenticated && currentUserType === "supplier" && supplierDetails?.id?.uuid ? (*/}
                        {/*  <NamedLink*/}
                        {/*    name="SupplierSendMessagePage"*/}
                        {/*    className={css.contactBrandButton}*/}
                        {/*    params={{ receiver: supplierDetails?.id?.uuid, title: "Question" }}*/}
                        {/*  >*/}
                        {/*    Message brand*/}
                        {/*  </NamedLink>) : null}*/}
                        {!isAuthenticated ? (
                          <a onClick={onShowRegistrationConfirmDialog} className={css.contactBrandButton}>
                            <FormattedMessage id="SupplierDetailsPage.visitorButton" />
                          </a>
                        ) : null}
                      </div>
                      <h3 className={css.companyDescriptionLabel}>
                        <FormattedMessage id="SupplierDetailsPage.LabelCompanyDescription" />
                      </h3>
                      <p className={css.companyDescriptionValue}>
                        {companyDetails?.description || (<span className={css.emptyDescription}><FormattedMessage
                          id="SupplierProfilePage.PublicEmptyLabelCompanyDescription" /></span>)}
                      </p>
                    </div>
                  </div>

                  <div className={css.productContainer}>
                    <ProductContainer
                      products={products}
                      rowItemCount={3}
                      align="left"
                      emptyStateOptions={emptyStateOptions}
                      loading={loading}
                    />
                  </div>
                  {showExpandButton && !isAuthenticated ? (
                    <div className={css.showAllProduct}>
                      <a onClick={onShowRegistrationConfirmDialog} className={css.showAllButton}>
                        <FormattedMessage id="SupplierDetailsPage.viewMoreProducts" />
                      </a>
                    </div>
                  ) : null}
                  {showExpandButton && isAuthenticated ? (
                    <div className={css.showAllProduct}>
                      <a onClick={this.onShowAllClick} className={css.showAllButton}>
                        <FormattedMessage id="SupplierDetailsPage.viewMoreProducts" />
                      </a>
                    </div>
                  ) : null}
                </div>
              </div>
            }
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

SupplierDetailsPageComponent.defaultProps = {
  currentUserListing: null,
  currentUserListingFetched: false,
};

SupplierDetailsPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
  // from withRouter
  history: object.isRequired,
  location: object.isRequired,
  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = (state) => {
  const { currentUser, currentUserType, currentUserListing } = state.user;
  const {
    supplierOtherProductsError,
    supplierOtherProductsProgress,
    supplierOtherProducts,
    supplierDetailsError,
    supplierDetailsProgress,
    supplierDetails,
  } = state.ProductDetailsPage;
  const { isAuthenticated } = state.Auth;

  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
    supplierOtherProductsError,
    supplierOtherProductsProgress,
    supplierOtherProducts,
    supplierDetailsError,
    supplierDetailsProgress,
    supplierDetails,
    isAuthenticated,
    currentUserType,
    currentUserListing,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onSupplierCreateCompany: (data) => dispatch(supplierCreateCompany(data)),
  onSupplierLogo: (values) => dispatch(supplierUpdateCompanyLogo(values)),
  onSupplierUpdateCompanyCover: (values) => dispatch(supplierUpdateCompanyCover(values)),
  onSupplierDeleteCompanyCover: (values) => dispatch(supplierDeleteCompanyCover(values)),
  onShowRegistrationConfirmDialog: () => dispatch(displayRegistrationConfirmDialog(true, true)),
});

const SupplierDetailsPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
)(SupplierDetailsPageComponent);

SupplierDetailsPage.loadData = (args) => (dispatch /*getState*/) => {
  return dispatch(loadSupplierDetails({ id: args.id }));
};

SupplierDetailsPage.setInitialValues = (/*values*/) => {
};

export default SupplierDetailsPage;
