import { storableError } from '../util/errors';

import { types as sdkTypes } from '../util/sdkLoader';
import { denormalisedResponseEntities } from '../util/data';
import { util as sdkUtil } from '../util/sdkLoader';
import { currentUserShowSuccess } from './user.duck';
import config from '../config';
import { sendMail } from '../util/api';
// ================ Action types ================ //

export const SEND_MAIL_REQUEST = 'app/send/mail/request';
export const SEND_MAIL_SUCCESS = 'app/send/mail/request/success';
export const SEND_MAIL_ERROR = 'app/send/mail/request/error';

// ================ Reducer ================ //

const initialState = {
  mailTemplate: null,
  templateData: null,
  sendInProgress: false,
  sendError: null
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SEND_MAIL_REQUEST:
        return {
            ...state,
            mailTemplate: payload.template,
            templateData: payload.data,
            sendInProgress: true,
            sendError: null
        };
    case SEND_MAIL_SUCCESS:
        return { ...state, sendInProgress: false };
    case SEND_MAIL_ERROR:
        return { ...state, sendInProgress: false, sendError: payload };
    default:
      return state;
  }
}

// ================ Action creators ================ //
export const sendMailRequest = (template, data) => ({ type: SEND_MAIL_REQUEST, payload: {template, data} });
export const sendMailSuccess = () => ({ type: SEND_MAIL_SUCCESS });
export const sendMailError = error => ({
  type: SEND_MAIL_ERROR,
  payload: error,
  error: true,
});
// ================ Thunks ================ //

export const send = params => (dispatch, getState, sdk) => {
  const { 
      subject,
      template,
      data,
      to } = params;
      dispatch(sendMailRequest(template, data));
      setTimeout(() => {
        const mailNode = document.querySelector('#mail-template-node');
        sendMail(to, subject, mailNode.innerHTML).then(res => {
        dispatch(sendMailSuccess());
        }).catch(()=>{
          dispatch(sendMailError());
        });
      }, 300);
};
