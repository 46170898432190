import React, { useState } from 'react';
import { bool, string } from 'prop-types';
import classNames from 'classnames';
/* Swiper components */
import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react';
SwiperCore.use([Navigation]);
/* Swiper components */
import { propTypes } from '../../util/types';
import { FormattedMessage } from '../../util/reactIntl';

import imageAvatarReference1 from '../../assets/denis-guendes-amanase.png';
import companyLogoReference1 from '../../assets/amanase-logo.png';
import imageAvatarReference2 from '../../assets/miki-furue-mila-the-vegan.png';
import companyLogoReference2 from '../../assets/mila-the-vegan-logo.png';

import css from './SectionFeedback.module.css';
import arrowLeft from '../../assets/arrow-left-000000.svg';
import arrowRight from '../../assets/arrow-right-000000.svg';

const SectionFeedback = props => {
  const { rootClassName, className } = props;
  const messages = [
    {
      companyLogo: companyLogoReference1,
      companyName: 'Amanase GmbH',
      authorPhoto: imageAvatarReference1,
      authorName: 'Denis Gündes',
      authorDescription: <FormattedMessage id="SectionFeedback.authorDescription1" />,
      comment: <FormattedMessage id="SectionFeedback.Comment1" />
    },
    {
      companyLogo: companyLogoReference2,
      companyName: 'Mila The Vegan',
      authorPhoto: imageAvatarReference2,
      authorName: 'Miki Furue',
      authorDescription: <FormattedMessage id="SectionFeedback.authorDescription2" />,
      comment: <FormattedMessage id="SectionFeedback.Comment2" />
    }
  ];
  return (
    <div className={css.feedbackContainer}>
      <Swiper
        setWrapperSize
        simulateTouch={false}
        //setWrapperSize
        className={css.test}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }}
        loop
        loopedSlides={messages.length}
        slidesPerView={1}
        speed={300}
      >
        <div className={classNames(css.leftButton, 'swiper-button-prev')}>
          <img src={arrowLeft} alt=""></img>
        </div>
        {messages.map((item, i) => {
          return (
            <SwiperSlide key={i} className={css.commentContainer}>
              <img className={css.companyLogo} src={item.companyLogo} alt={item.companyName} />
              <p className={css.comment}>"{item.comment}"</p>
              <div className={css.authorBox}>
                <img className={css.avatar} src={item.authorPhoto} alt={item.authorName} />
                <div className={css.authorInfo}>
                  <p className={css.authorName}>{item.authorName},</p>
                  <p className={css.authorName}>{item.authorDescription}</p>
                </div>
              </div>
            </SwiperSlide>
          );
        })}
        <div className={classNames(css.rightButton, 'swiper-button-next')}>
          <img src={arrowRight} alt=""></img>
        </div>
        <div className={css.buttonContainer}>
          <div className={classNames(css.leftButtonMobile, 'swiper-button-prev')}>
            <img src={arrowLeft} alt=""></img>
          </div>
          <div className={classNames(css.rightButtonMobile, 'swiper-button-next')}>
            <img src={arrowRight} alt=""></img>
          </div>
        </div>
      </Swiper>
    </div>
  );
};

SectionFeedback.defaultProps = {
  rootClassName: null,
  className: null,
  currentUserListing: null,
  currentUserListingFetched: false,
};

SectionFeedback.propTypes = {
  rootClassName: string,
  className: string,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
};

export default SectionFeedback;