import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import circleIcon from "../../assets/check-circle-green.svg"
import css from './IconSuccess.module.css';

const IconSuccess = props => {
  const { rootClassName, className, fillColor } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <img src={circleIcon} className={css.circleIcon}></img>
  );
};

IconSuccess.defaultProps = {
  rootClassName: null,
  className: null,
  fillColor: null,
};

IconSuccess.propTypes = {
  rootClassName: string,
  className: string,
  fillColor: string,
};

export default IconSuccess;
