import { storableError } from '../../util/errors';
import { supplierLoadCompanyProducts } from '../../ducks/supplier-company.duck';
import { types as sdkTypes, util as sdkUtil } from '../../util/sdkLoader';

const { UUID } = sdkTypes;

// ================ Action types ================ //
export const SUPPLIER_LISTING_SAVE_DRAFT_REQUEST = 'app/Supplier/save/listing/draft';
export const SUPPLIER_LISTING_SAVE_DRAFT_SUCCESS = 'app/Supplier/save/listing/draft/success';
export const SUPPLIER_LISTING_SAVE_DRAFT_ERROR = 'app/Supplier/save/listing/draft/error';

export const SUPPLIER_LISTING_GET_REQUEST = 'app/Supplier/listing/load';
export const SUPPLIER_LISTING_GET_SUCCESS = 'app/Supplier/listing/load/success';
export const SUPPLIER_LISTING_GET_ERROR = 'app/Supplier/listing/load/error';

export const SUPPLIER_LISTING_PUBLISH_REQUEST = 'app/Supplier/listing/publish';
export const SUPPLIER_LISTING_PUBLISH_SUCCESS = 'app/Supplier/listing/draft/publish';
export const SUPPLIER_LISTING_PUBLISH_ERROR = 'app/Supplier/listing/draft/publish';


export const SUPPLIER_LISTING_RESET_REQUEST = 'app/Supplier/listing/reset';

export const SHOW_NOTIFICATION = 'app/Supplier/product/SHOW_NOTIFICATION';
export const HIDE_NOTIFICATION = 'app/Supplier/product/HIDE_NOTIFICATION';

// ================ Reducer ================ //
const initialState = {
  supplierListingLoadError: null,
  supplierListingLoadProgress: false,
  supplierListingLoad: false,
  supplierListingSaveDraftError: null,
  supplierListingSaveDraftProgress: false,
  supplierListingSavedDraft: false,
  supplierListingPublishError: null,
  supplierListingPublishProgress: false,
  supplierListingPublished: false,
  supplierEditedListing: null,
  notificationText: '',
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SUPPLIER_LISTING_GET_REQUEST:
      return {
        ...state,
        supplierListingLoadProgress: true,
        supplierListingLoadError: null,
        supplierListingLoad: false,
        supplierEditedListing: null
      };
    case SUPPLIER_LISTING_RESET_REQUEST:
      return { ...state, supplierEditedListing: null };
    case SUPPLIER_LISTING_GET_SUCCESS:
      return {
        ...state,
        supplierListingLoadProgress: false,
        supplierListingLoad: true,
        supplierEditedListing: payload
      };
    case SUPPLIER_LISTING_GET_ERROR:
      return { ...state, supplierListingLoadProgress: false, supplierListingLoadError: payload };
    case SUPPLIER_LISTING_SAVE_DRAFT_REQUEST:
      return {
        ...state,
        supplierListingSaveDraftProgress: true,
        supplierListingSaveDraftError: null,
        supplierListingSavedDraft: false
      };
    case SUPPLIER_LISTING_SAVE_DRAFT_SUCCESS:
      return { ...state, supplierListingSaveDraftProgress: false, supplierListingSavedDraft: true };
    case SUPPLIER_LISTING_SAVE_DRAFT_ERROR:
      return { ...state, supplierListingSaveDraftProgress: false, supplierListingSaveDraftError: payload };
    case SUPPLIER_LISTING_PUBLISH_REQUEST:
      return {
        ...state,
        supplierListingPublishProgress: true,
        supplierListingPublishError: null,
        supplierListingPublished: false
      };
    case SUPPLIER_LISTING_PUBLISH_SUCCESS:
      return {
        ...state,
        supplierListingPublishProgress: false,
        supplierListingPublished: true,
        supplierEditedListing: payload
      };
    case SUPPLIER_LISTING_PUBLISH_ERROR:
      return { ...state, supplierListingPublishProgress: false, supplierListingPublishError: payload };
    case HIDE_NOTIFICATION:
      return { ...state, notificationText: '' };
    case SHOW_NOTIFICATION:
      return { ...state, notificationText: payload };
    default:
      return state;
  }
}

// ================ Action creators ================ //
export const listingSaveDraftRequest = () => ({ type: SUPPLIER_LISTING_SAVE_DRAFT_REQUEST });
export const listingSaveDraftSuccess = () => ({ type: SUPPLIER_LISTING_SAVE_DRAFT_SUCCESS });
export const listingSaveDraftError = error => ({
  type: SUPPLIER_LISTING_SAVE_DRAFT_ERROR,
  payload: error,
  error: true,
});

export const resetListing = () => ({ type: SUPPLIER_LISTING_RESET_REQUEST });
export const loadListingRequest = () => ({ type: SUPPLIER_LISTING_GET_REQUEST });
export const loadListingSuccess = (data) => ({ type: SUPPLIER_LISTING_GET_SUCCESS, payload: data });
export const loadListingError = error => ({
  type: SUPPLIER_LISTING_GET_ERROR,
  payload: error,
  error: true,
});

export const listingPublishRequest = () => ({ type: SUPPLIER_LISTING_PUBLISH_REQUEST });
export const listingPublishSuccess = () => ({ type: SUPPLIER_LISTING_PUBLISH_SUCCESS });
export const listingPublishError = error => ({
  type: SUPPLIER_LISTING_PUBLISH_ERROR,
  payload: error,
  error: true,
});

export const showNotification = (notificationText) => ({ type: SHOW_NOTIFICATION, payload: notificationText })
export const hideNotification = () => ({ type: HIDE_NOTIFICATION })

// ================ Thunks ================ //
export const loadListing = params => (dispatch, getState, sdk) => {
  dispatch(loadListingRequest());
  const { id } = params;
  return sdk.ownListings.show({ id: new UUID(id) })
    .then((resp) => {
      dispatch(loadListingSuccess(resp.data.data));
    })
    .catch(e => {
      dispatch(loadListingError(storableError(e)));
    });
};
export const publishListing = params => (dispatch, getState, sdk) => {
  dispatch(listingPublishRequest());
  const listing = mapToListing(params);
  const currentListing = getState().SupplierAddProductPage.supplierEditedListing;
  const currentImages = currentListing?.attributes?.publicData?.productImages || [];
  const currentCompany = getState().supplierCompany.supplierCurrentCompany;
  listing.publicData.brandId = currentCompany?.id?.uuid;
  listing.publicData.brandName = currentCompany?.attributes?.title;
  listing.publicData.location = currentCompany?.attributes?.publicData?.country;
  const queryParams = {
    expand: true,
    include: ['author', 'images'],
    'fields.image': ['variants.listing-square'],
  };
  updateListingImages(sdk, currentImages, listing?.publicData?.productImages || [])
    .then((images) => {
      listing.publicData.productImages = images;
      listing.images = images.map(i => (new UUID(i.id)));
      const updateRequest = (listing.id ? sdk.ownListings.update(listing) : sdk.ownListings.createDraft(listing, queryParams));
      updateRequest.then(resp => {
          return sdk.ownListings.publishDraft({ id: resp.data.data.id })
            .then((resp) => {
              dispatch(listingPublishSuccess());
              dispatch(supplierLoadCompanyProducts());
              dispatch(resetListing());
            })
            .catch(e => {
              dispatch(listingPublishError(storableError(e)));
            });
        })
        .catch(e => {
          dispatch(listingPublishError(storableError(e)));
        });
    })
    .catch(e => {
      dispatch(listingPublishError(storableError(e)));
    });
};
export const saveDraftListing = params => (dispatch, getState, sdk) => {
  dispatch(listingSaveDraftRequest());
  if (params.notificationText) {
    dispatch(showNotification(params.notificationText))
    setTimeout(() => {
      dispatch(hideNotification())
    }, 6000)
  }
  const listing = mapToListing(params);
  const currentCompany = getState().supplierCompany.supplierCurrentCompany;
  listing.publicData.brandId = currentCompany?.id?.uuid;
  listing.publicData.brandName = currentCompany?.attributes?.title;
  listing.publicData.location = currentCompany?.attributes?.publicData?.country;
  const currentListing = getState().SupplierAddProductPage.supplierEditedListing;
  const currentImages = currentListing?.attributes?.publicData?.productImages || [];
  const queryParams = {
    expand: true,
    include: ['author', 'images'],
    'fields.image': ['variants.listing-square'],
  };
  updateListingImages(sdk, currentImages, listing?.publicData?.productImages || [])
    .then((images) => {
      listing.publicData.productImages = images;
      listing.images = images.map(i => (new UUID(i.id)));
      const updateRequest = (listing.id ? sdk.ownListings.update(listing) : sdk.ownListings.createDraft(listing, queryParams));
      updateRequest.then(response => {
          dispatch(listingSaveDraftSuccess());
          dispatch(supplierLoadCompanyProducts());
          dispatch(resetListing());
          if (params.notificationText) {
            dispatch(showNotification(params.notificationText))
            setTimeout(() => {
              dispatch(hideNotification())
            }, 6000)
          }
        })
        .catch(e => {
          dispatch(listingSaveDraftError(storableError(e)));
        });
    })
    .catch(e => {
      dispatch(listingSaveDraftError(storableError(e)));
    });
};
export const savePending = params => (dispatch, getState, sdk) => {
  dispatch(listingSaveDraftRequest());
  const listing = mapToListing(params);
  const currentCompany = getState().supplierCompany.supplierCurrentCompany;
  listing.publicData.brandId = currentCompany?.id?.uuid;
  listing.publicData.brandName = currentCompany?.attributes?.title;
  listing.publicData.location = currentCompany?.attributes?.publicData?.country;
  const currentListing = getState().SupplierAddProductPage.supplierEditedListing;
  const currentImages = currentListing?.attributes?.publicData?.productImages || [];

  const queryParams = {
    expand: true,
    include: ['author', 'images'],
    'fields.image': ['variants.listing-square'],
  };
  updateListingImages(sdk, currentImages, listing?.publicData?.productImages || [])
    .then((images) => {
      listing.publicData.productImages = images;
      listing.images = images.map(i => (new UUID(i.id)));
      const updateRequest = (listing.id ? sdk.ownListings.update(listing) : sdk.ownListings.create(listing, queryParams));
      updateRequest.then(response => {
          dispatch(listingSaveDraftSuccess());
          dispatch(supplierLoadCompanyProducts());
          dispatch(resetListing());
        })
        .catch(e => {
          dispatch(listingSaveDraftError(storableError(e)));
        });
    })
    .catch(e => {
      dispatch(listingSaveDraftError(storableError(e)));
    });
};

export const updateListingImages = async (sdk, currentProductImages, productImages) => {
  let resultImages = [...currentProductImages];
  const newImages = productImages.filter(img => (img.isNew && !img.deleted));
  const existsIds = resultImages.map(img => (img.id));
  const deletedIds = productImages.filter(img => (img.deleted)).map(img => (img.id));
  deletedIds.sort((f, s) => {
    return f - s;
  });
  const queryParams = {
    expand: true,
    'fields.image': ['variants.listing-square'],
    'imageVariant.listing-square': sdkUtil.objectQueryString({
      w: 1024,
      h: 1024,
      fit: 'crop',
    }),
  };
  for (let i = 0; i < newImages.length; i++) {
    const img = newImages[i];
    const responseUpload = await sdk.images.upload({ image: img.file }, queryParams);
    const imgId = responseUpload.data.data.id.uuid;
    const imgUrl = responseUpload.data.data.attributes.variants['listing-square'].url;
    const updateIndex = existsIds.indexOf(img.id);
    if (updateIndex > -1) {
      resultImages[updateIndex].id = imgId;
      resultImages[updateIndex].url = imgUrl;
      resultImages[updateIndex].isMain = img.isMain;
    } else {
      resultImages.push({ id: imgId, url: imgUrl, isMain: img.isMain });
    }
  }

  resultImages = resultImages.filter(img => deletedIds.indexOf(img.id) < 0);
  resultImages.sort((a, b) => (a.isMain ? -1 : 1));
  return resultImages;
};

export const mapToListing = (listingData) => {
  function randomNumberFromInterval(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min)
  }
  const randomNumber = randomNumberFromInterval(100000000, 999999999)
  const result = {
    title: listingData.productName,
    description: listingData.productDescription,
    price: { amount: parseFloat(listingData.wholesalePrice), currency: "EUR" },
    publicData: {
      dataType: "product",
      eanOrGtinNumber: listingData.eanOrGtinNumber,
      freeSamples: listingData.freeSamples,
      ingredientList: listingData.ingredientList,
      packWeightUnit: listingData.packWeightUnit,
      packWeightUnitCustom: listingData.packWeightUnitCustom,
      weightUnitCustom: listingData.weightUnitCustom,
      languageOfProductLabel: listingData.languageOfProductLabel,
      leadTime: listingData.leadTime,
      packagingUnitCount: listingData.packagingUnitCount,
      productDescription: listingData.productDescription,
      productName: listingData.productName,
      properties: listingData.properties,
      requiredTemperature: listingData.requiredTemperature,
      rootCategory: listingData.rootCategory,
      shelfLife: listingData.shelfLife,
      subCategory: listingData.subCategory,
      suggestedPrice: listingData.suggestedPrice,
      weight: listingData.weight,
      weightUnit: listingData.weightUnit,
      wholesalePrice: listingData.wholesalePrice,
      vat: listingData.vat,
      certification: listingData.certification,
      productImages: listingData.productImages,
      updateAt: (new Date()).toISOString(),
      randomSortingNumber: randomNumber
    }
  };

  if (listingData.id) {
    result.id = listingData.id;
  }

  return result;
};
export const mapToFormData = (listing) => {
  const listingData = listing?.attributes?.publicData;
  return {
    id: listing?.id,
    state: listing?.attributes?.state,
    eanOrGtinNumber: listingData?.eanOrGtinNumber,
    freeSamples: listingData?.freeSamples,
    ingredientList: listingData?.ingredientList,
    languageOfProductLabel: listingData?.languageOfProductLabel,
    leadTime: listingData?.leadTime,
    packagingUnitCount: listingData?.packagingUnitCount,
    productDescription: listingData?.productDescription,
    productName: listingData?.productName,
    properties: listingData?.properties,
    requiredTemperature: listingData?.requiredTemperature,
    rootCategory: listingData?.rootCategory,
    shelfLife: listingData?.shelfLife,
    subCategory: listingData?.subCategory,
    suggestedPrice: listingData?.suggestedPrice,
    weight: listingData?.weight,
    weightUnit: listingData?.weightUnit,
    packWeightUnit: listingData?.packWeightUnit,
    packWeightUnitCustom: listingData?.packWeightUnitCustom,
    weightUnitCustom: listingData?.weightUnitCustom,
    wholesalePrice: listingData?.wholesalePrice,
    vat: listingData?.vat,
    certification: listingData?.certification,
    productImages: listingData?.productImages || [{
      id: -1,
      file: null,
      url: null,
      isEmpty: true,
      isMain: true
    }, { id: -2, file: null, url: null, isEmpty: true }]
  };
};
