const SUPPLIER_INVOICING_TABS = {
  order: "order",
  commission: "commission",
};

// Additional object added for subscription based model pilot
// Subscription based model pilot to have only order invoice tab for the Supplier
const SUPPLIER_INVOICING_TAB = {
  order: "order",
};

Object.freeze(SUPPLIER_INVOICING_TABS);

const SUPPLIER_INVOICING_TAB_MESSAGES = {
  [SUPPLIER_INVOICING_TABS.order]: "SupplierInvoicingPage.settingsTabOrder",
  [SUPPLIER_INVOICING_TABS.commission]: "SupplierInvoicingPage.settingsTabCommission",
};

Object.freeze(SUPPLIER_INVOICING_TAB_MESSAGES);

const SUPPLIER_INVOICING_TAB_LINK_NAMES = {
  [SUPPLIER_INVOICING_TABS.order]: "SupplierOrderInvoicesPage",
  [SUPPLIER_INVOICING_TABS.commission]: "SupplierCommissionInvoicesPage",
};

Object.freeze(SUPPLIER_INVOICING_TAB_LINK_NAMES);

export {
  SUPPLIER_INVOICING_TABS,
  SUPPLIER_INVOICING_TAB,
  SUPPLIER_INVOICING_TAB_MESSAGES,
  SUPPLIER_INVOICING_TAB_LINK_NAMES,
};
