import classNames from "classnames";
import isEqual from "lodash/isEqual";
import { any, bool, func, number, string } from "prop-types";
import React, { Component, useEffect, useMemo, useRef, useState } from "react";
import { Form as FinalForm } from "react-final-form";
import { compose } from "redux";
import {
  FieldPhoneNumberInput,
  FieldSelect,
  FieldTextInput,
  Form,
  PrimaryButton,
  SecondaryButton,
} from "../../components";
import getCountryCodes from "../../translations/countryCodes";
import { ensureCurrentUser } from "../../util/data";
import { isChangeEmailWrongPassword, isTooManyEmailVerificationRequestsError } from "../../util/errors";
import { FormattedMessage, injectIntl, intlShape } from "../../util/reactIntl";
import { propTypes } from "../../util/types";
import * as validators from "../../util/validators";
import { PasswordInputField } from "../PasswordChangeForm/PasswordChangeForm";
import css from "./ContactDetailsForm.module.css";

/**
 * SHOW_EMAIL_SENT_TIMEOUT
 * @type {number}
 */
const SHOW_EMAIL_SENT_TIMEOUT = 2000;

/**
 * TODO: Move to the reusable functions.
 * @param formId
 * @param {string} inputName
 * @returns {string}
 */
export const defineInputId = ({ formId, inputName }) => formId ? `${formId}.${inputName}` : `${inputName}`;

/**
 * TODO: Move to the reusable components.
 * @param {Object} props
 * @param {string} props.htmlFor to which input related
 * @param {string} props.labelText label value
 * @param {string} props.errorMsg validation error message
 * @param {bool} props.isRequired displaying a mandatory symbol *
 * @param {bool} props.isError displaying an error message
 * @param {JSX.Element | any} props.children usually related input
 * @returns {JSX.Element}
 * @constructor
 */
export const LabelWithErrorMsg = ({ htmlFor, labelText, errorMsg, isRequired, isError, className, children }) => {
  return (
    <>
      <label className={classNames(css.customLabel, css.customLabelText, className)} htmlFor={htmlFor}>
        <span>{labelText}</span>
        {isRequired && <span className={css.mandatoryField}>*</span>}
        {isError && <span className={css.errorMsg}>{errorMsg}</span>}
      </label>
      {children}
    </>
  );
};

LabelWithErrorMsg.propTypes = {
  htmlFor: string.isRequired,
  labelText: string.isRequired,
  errorMsg: string,
  isRequired: bool,
  isError: bool,
  children: any.isRequired,
  className: string,
};

/**
 * TODO: Move to the reusable hooks.
 * @param {MutableRefObject} ref
 * @param {function} handler
 */
export function useOutsideClick(ref, handler) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        handler();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, handler]);
}

/**
 * TODO: Move to the reusable components.
 * @param {function} hideModal
 * @param {boolean} isVisible
 * @param {JSX.Element | HTML.Element} children - markup
 * @returns {JSX.Element|null}
 * @constructor
 */
export const ModalWindow = ({ hideModal, isVisible, children }) => {
  const containerRef = useRef(null);
  const closeModal = () => hideModal();
  useOutsideClick(containerRef, closeModal);

  if (!isVisible) return null;

  return (
    <div className={css.modalBackground}>
      <div ref={containerRef} className={css.fixedPosition}>
        {children}
      </div>
    </div>
  );
};

ModalWindow.propTypes = {
  isVisible: bool.isRequired,
  hideModal: func.isRequired,
  children: any.isRequired,
};

class ContactDetailsFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showVerificationEmailSentMessage: false,
      showResetPasswordMessage: false,
      callCode: props.initialValues.phoneCode,
    };
    this.emailSentTimeoutId = null;
    this.handleResendVerificationEmail = this.handleResendVerificationEmail.bind(this);
    this.handleResetPassword = this.handleResetPassword.bind(this);
    this.onChangeCountry = this.onChangeCountry.bind(this);
    this.submittedValues = {};
  }

  componentWillUnmount() {
    window.clearTimeout(this.emailSentTimeoutId);
  }

  handleResendVerificationEmail() {
    this.setState({ showVerificationEmailSentMessage: true });

    this.props.onResendVerificationEmail().then(() => {
      // show "verification email sent" text for a bit longer.
      this.emailSentTimeoutId = window.setTimeout(() => {
        this.setState({ showVerificationEmailSentMessage: false });
      }, SHOW_EMAIL_SENT_TIMEOUT);
    });
  }

  handleResetPassword() {
    this.setState({ showResetPasswordMessage: true });
    const email = this.props.currentUser.attributes.email;
    this.props.onResetPassword(email);
  }

  onChangeCountry(event) {
    this.setState({ ...this.state, countryEnable: event === "Other" });
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        render={(fieldRenderProps) => {
          const {
            rootClassName,
            className,
            savePhoneNumberError,
            currentUser,
            formId,
            handleSubmit,
            inProgress,
            intl,
            modified,
            sendVerificationEmailError,
            sendVerificationEmailInProgress,
            resetPasswordInProgress,
            values,
            form,
          } = fieldRenderProps;

          /**
           * @param {Object} options
           * @param {string} options.cssClassName - actual CSS class
           * @param {string} options.messageId - defined message ID
           * @param {Object | undefined} options.values - additional message options
           * @param {function | undefined} options.onClick - additional message options
           * @returns {JSX.Element}
           */
          const inlineFormattedMessageComponent = (options) => {
            const { cssClassName, messageId, values = {}, onClick } = options;
            const formattedMessage = intl.formatMessage({ id: messageId, values });

            if (formattedMessage !== messageId) {
              return (
                <span className={cssClassName} onClick={onClick}>
                  <FormattedMessage id={messageId} value={values} />
                </span>
              );
            }
          };

          const requiredFieldFormattedMessage = intl.formatMessage({ id: "RequiredField.warningText" });

          const { email, phoneNumber, firstName, lastName, position } = values;

          const user = ensureCurrentUser(currentUser);

          const {
            email: currentEmail,
            emailVerified,
            pendingEmail,
            profile: {
              firstName: currentFirstName,
              lastName: currentLastName,
              protectedData: {
                position: currentPosition,
                phoneNumber: currentPhoneNumber,
              },
            },
          } = user.attributes;

          // ================ FIRSTNAME ================ //
          const firstNameInputId = defineInputId({ formId, inputName: "firstName" });
          const firstNamePlaceholder = intl.formatMessage({ id: "ContactDetailsForm.firstNamePlaceholder" });
          const firstNameLabel = intl.formatMessage({ id: "UserFields.name" });
          const firstNameChanged = currentFirstName !== firstName;
          const isFirstNameError = firstNameChanged && !values.firstName;

          // ================ LASTNAME ================ //
          const lastNameInputId = defineInputId({ formId, inputName: "lastName" });
          const lastNamePlaceholder = intl.formatMessage({ id: "ContactDetailsForm.lastNamePlaceholder" });
          const lastNameLabel = intl.formatMessage({ id: "UserFields.surname" });
          const lastNameChanged = currentLastName !== lastName;
          const isLastNameError = lastNameChanged && !values.lastName;

          // ================ POSITION ================ //
          const positionInputId = defineInputId({ formId, inputName: "position" });
          const positionPlaceholder = intl.formatMessage({ id: "ContactDetailsForm.positionPlaceholder" });
          const positionLabel = intl.formatMessage({ id: "UserFields.position" });
          const positionChanged = currentPosition !== position;
          const isPositionError = positionChanged && !values.position;

          // ================ PHONE ================ //
          const phoneNumberInputId = defineInputId({ formId, inputName: "phoneNumber" });
          const phoneNumberPlaceholder = intl.formatMessage({ id: "ContactDetailsForm.phonePlaceholder" });
          const phoneNumberLabel = intl.formatMessage({ id: "UserFields.phoneNumber" });
          const phoneNumberChanged = currentPhoneNumber !== phoneNumber;
          const isPhoneNumberError = phoneNumberChanged && !values.phoneNumber;
          const countries = [...getCountryCodes(), { code: "", callCode: "+", name: "Other" }];
          const onChangeCountryCode = (e) => this.setState({ ...this.state, callCode: e });

          // ================ EMAIL ================ //
          const emailInputId = defineInputId({ formId, inputName: "email" });
          const emailLabel = intl.formatMessage({ id: "UserFields.email" });
          const emailPlaceholder = currentEmail || "";
          const emailChanged = currentEmail !== email;
          const emailRequired = validators.required(requiredFieldFormattedMessage);
          const emailValid = validators.emailFormatValid(intl.formatMessage({ id: "ContactDetailsForm.emailInvalid" }));
          const isEmailValid = validators.EMAIL_RE.test(values.email);
          const isEmailError = emailChanged && (!values.email || !isEmailValid);
          const emailErrorMsg = isEmailError && !values.email
            ? requiredFieldFormattedMessage : intl.formatMessage({ id: "ContactDetailsForm.emailInvalid" });
          const tooManyEmailVerificationRequests = isTooManyEmailVerificationRequestsError(sendVerificationEmailError);

          let resendEmailMessage;
          if (tooManyEmailVerificationRequests) {
            resendEmailMessage = inlineFormattedMessageComponent({
              cssClassName: css.tooMany,
              messageId: "ContactDetailsForm.tooManyEmailVerificationRequests",
            });
          } else if (sendVerificationEmailInProgress || this.state.showVerificationEmailSentMessage) {
            resendEmailMessage = inlineFormattedMessageComponent({
              cssClassName: css.emailSent,
              messageId: "ContactDetailsForm.emailSent",
            });
          } else {
            resendEmailMessage = inlineFormattedMessageComponent({
              cssClassName: css.helperLink,
              messageId: "ContactDetailsForm.resendEmailVerificationText",
              onClick: this.handleResendVerificationEmail,
            });
          }

          // Email status info: PENDING | VERIFIED | UNVERIFIED (aka changed unverified email)
          let emailVerifiedInfo = null;
          if (emailVerified && !pendingEmail && !emailChanged) {
            // Current email is verified and there's no pending unverified email
            emailVerifiedInfo = inlineFormattedMessageComponent({
              cssClassName: css.emailVerified,
              messageId: "ContactDetailsForm.emailVerified",
            });
          } else if (!emailVerified && !pendingEmail) {
            // Current email is unverified. This is the email given in sign up form
            emailVerifiedInfo = (
              <span className={css.emailUnverified}>
                <FormattedMessage
                  id="ContactDetailsForm.emailUnverified"
                  values={{ resendEmailMessage }}
                />
              </span>
            );
          } else if (pendingEmail) {
            // Current email has been tried to change, but the new address is not yet verified
            const pendingEmailStyled = <span className={css.emailStyle}>{pendingEmail}</span>;
            const pendingEmailCheckInbox = (
              <span className={css.checkInbox}>
                <FormattedMessage
                  id="ContactDetailsForm.pendingEmailCheckInbox"
                  values={{ pendingEmail: pendingEmailStyled }}
                />
              </span>
            );

            emailVerifiedInfo = (
              <span className={css.pendingEmailUnverified}>
                <FormattedMessage
                  id="ContactDetailsForm.pendingEmailUnverified"
                  values={{ pendingEmailCheckInbox, resendEmailMessage }}
                />
              </span>
            );
          }

          // ================ PASSWORD ================ //
          const confirmPasswordClasses = classNames(css.confirmChangesSection, css.confirmChangesSectionVisible);
          const passwordInputId = defineInputId({ formId, inputName: "currentPassword" });
          const passwordLabel = intl.formatMessage({ id: "ContactDetailsForm.passwordLabel" });
          const passwordPlaceholder = intl.formatMessage({ id: "ContactDetailsForm.passwordPlaceholder" });
          const isPasswordEmpty = !values.currentPassword;
          const passwordTouched = modified["currentPassword"];
          const [submitError, setSubmitError] = useState(null);
          const incorrectPasswordError = submitError?.status === 403;
          const alreadyUsedEmailError = submitError?.status === 409;
          const isPasswordError = passwordTouched && (isPasswordEmpty) || incorrectPasswordError;
          const passwordIncorrectMessage = intl.formatMessage({ id: "PasswordResetForm.incorrectPassword" });
          const passwordErrorMsg = useMemo(() => {
            if (incorrectPasswordError) {
              return passwordIncorrectMessage;
            } else if (isPasswordEmpty && passwordTouched) {
              return requiredFieldFormattedMessage;
            }

            return "";
          }, [passwordTouched,
            incorrectPasswordError,
            passwordIncorrectMessage,
            isPasswordEmpty,
            requiredFieldFormattedMessage]);

          const passwordRequired = validators.requiredStringNoTrim(requiredFieldFormattedMessage);
          const passwordValidators = validators.composeValidators(passwordRequired);
          const passwordFailedMessage = intl.formatMessage({ id: "ContactDetailsForm.passwordFailed" });
          const passwordErrorText = isChangeEmailWrongPassword(incorrectPasswordError) ? passwordFailedMessage : null;

          // ================ GENERIC ERROR ================ //
          const isGenericEmailError = submitError && !(emailErrorMsg || passwordErrorText);
          let genericError = null;
          if (isGenericEmailError && savePhoneNumberError) {
            genericError = inlineFormattedMessageComponent({
              cssClassName: css.error,
              messageId: "ContactDetailsForm.genericFailure",
            });
          } else if (isGenericEmailError) {
            genericError = inlineFormattedMessageComponent({
              cssClassName: css.error,
              messageId: "ContactDetailsForm.genericEmailFailure",
            });
          } else if (savePhoneNumberError) {
            genericError = inlineFormattedMessageComponent({
              cssClassName: css.error,
              messageId: "ContactDetailsForm.genericPhoneNumberFailure",
            });
          }

          const sendPasswordLink = (
            <span className={css.helperLink} onClick={this.handleResetPassword} role="button">
              <FormattedMessage id="ContactDetailsForm.resetPasswordLinkText" />
            </span>
          );

          const resendPasswordLink = (
            <span className={css.helperLink} onClick={this.handleResetPassword} role="button">
              <FormattedMessage id="ContactDetailsForm.resendPasswordLinkText" />
            </span>
          );

          const resetPasswordLink = this.state.showResetPasswordMessage || resetPasswordInProgress
            ? <>
              <FormattedMessage
                id="ContactDetailsForm.resetPasswordLinkSent"
                values={{ email: <span className={css.emailStyle}>{currentUser.attributes.email}</span> }}
              />
              {" "}{resendPasswordLink}
            </> : sendPasswordLink;

          const genericAPIErrorMessage = alreadyUsedEmailError
            ? intl.formatMessage({ id: "ContactDetailsForm.emailAlreadyInUse" }) : "";

          // ================ GENERAL ================ //
          const classes = classNames(rootClassName || css.root, className);
          const submittedOnce = Object.keys(this.submittedValues).length > 0;
          const isPristineSinceLastSubmit = submittedOnce && isEqual(values, this.submittedValues) && !submitError;
          const isAnyFieldChanged = emailChanged || phoneNumberChanged || positionChanged || firstNameChanged || lastNameChanged;
          const isAnyFieldError = isEmailError || isPhoneNumberError || isPositionError || isFirstNameError || isLastNameError;
          const submitDisabled = isPristineSinceLastSubmit || inProgress || !isAnyFieldChanged || isAnyFieldError;
          const confirmDisabled = isPasswordEmpty;

          // ================ MODAL WINDOW LOGIC ================ //
          const [modalVisible, setModalVisible] = useState(false);

          const showModal = () => {
            values.currentPassword = "";
            setModalVisible(true);
          };

          const hideModal = () => {
            values.currentPassword = "";
            setModalVisible(false);
            setSubmitError(null);
          };

          const silentSubmit = (e) => {
            this.submittedValues = values;
            return handleSubmit(e);
          };

          const onConfirmPassword = (e) => {
            e.preventDefault();
            showModal();
          };

          const formSubmit = (e) => {
            setSubmitError(null);
            this.submittedValues = values;
            return handleSubmit(e)
              .then((res) => {
                if (res?.data?.errors?.length > 0) {
                  setSubmitError(res.data.errors[0]);
                } else {
                  hideModal();
                  form.reset();
                }
              });
          };

          const actualFormSubmit = emailChanged ? onConfirmPassword : silentSubmit;

          const confirmChangesModalMarkUp = (
            <div className={css.modalContainer}>
              <div className={css.crossButton} onClick={hideModal}></div>
              <div className={confirmPasswordClasses}>
                <h3 className={css.confirmChangesTitle}>
                  <FormattedMessage id="ContactDetailsForm.confirmChangesTitle" />
                </h3>
                <p className={css.confirmChangesInfo}>
                  <FormattedMessage id="ContactDetailsForm.confirmChangesInfo" />
                </p>
                <div className={css.inputContainer}>
                  <PasswordInputField
                    name="currentPassword"
                    passwordInputId={passwordInputId}
                    passwordLabel={passwordLabel}
                    passwordPlaceholder={passwordPlaceholder}
                    passwordErrorMsg={passwordErrorMsg}
                    isPasswordError={isPasswordError}
                    passwordValidators={passwordValidators}
                  />
                  <p className={css.resetPasswordInfo}>
                    <FormattedMessage id="ContactDetailsForm.resetPasswordInfo" values={{ resetPasswordLink }} />
                  </p>
                  <span className={css.genericError}>{genericAPIErrorMessage}
                  </span>
                </div>
                <div className={css.buttonContainer}>
                <SecondaryButton className={css.backButton} onClick={hideModal}>
                    <FormattedMessage id="PasswordChangeForm.goBack" />
                  </SecondaryButton>
                  <PrimaryButton
                    disabled={confirmDisabled}
                    className={css.confirmButton}
                    onClick={formSubmit}
                  >
                    <FormattedMessage id="PasswordChangeForm.confirmChanges" />
                  </PrimaryButton>
                </div>
              </div>
            </div>
          );

          // ================ MARKUP ================ //
          return (
            <Form className={classes} onSubmit={actualFormSubmit}>
              <ModalWindow hideModal={hideModal} isVisible={modalVisible} children={confirmChangesModalMarkUp} />
              <div className={css.accountDetailsSection}>
                <div className={`${css.inputColumn} ${css.leftInputColumn}`}>
                  <LabelWithErrorMsg
                    isRequired
                    htmlFor={firstNameInputId}
                    labelText={firstNameLabel}
                    errorMsg={requiredFieldFormattedMessage}
                    isError={isFirstNameError}
                  >
                    <FieldTextInput
                      className={css.inputMargin}
                      type="text"
                      name="firstName"
                      id={firstNameInputId}
                      placeholder={firstNamePlaceholder}
                      validate={validators.composeValidators()}
                    />
                  </LabelWithErrorMsg>
                  <LabelWithErrorMsg
                    isRequired
                    htmlFor={positionInputId}
                    labelText={positionLabel}
                    errorMsg={requiredFieldFormattedMessage}
                    isError={isPositionError}
                  >
                    <FieldTextInput
                      className={css.inputMargin}
                      type="text"
                      name="position"
                      id={positionInputId}
                      placeholder={positionPlaceholder}
                      validate={validators.composeValidators()}
                    />
                  </LabelWithErrorMsg>
                  <LabelWithErrorMsg
                    isRequired
                    htmlFor={emailInputId}
                    labelText={emailLabel}
                    errorMsg={emailErrorMsg}
                    isError={isEmailError}
                  >
                    <FieldTextInput
                      className={css.inputMargin}
                      type="email"
                      name="email"
                      id={emailInputId}
                      placeholder={emailPlaceholder}
                      validate={validators.composeValidators(emailRequired, emailValid)}
                    />
                  </LabelWithErrorMsg>
                </div>
                <div className={`${css.inputColumn} ${css.rightInputColumn}`}>
                  <LabelWithErrorMsg
                    isRequired
                    htmlFor={lastNameInputId}
                    labelText={lastNameLabel}
                    errorMsg={requiredFieldFormattedMessage}
                    isError={isLastNameError}
                  >
                    <FieldTextInput
                      className={css.inputMargin}
                      type="text"
                      name="lastName"
                      id={lastNameInputId}
                      placeholder={lastNamePlaceholder}
                      validate={validators.composeValidators()}
                    />
                  </LabelWithErrorMsg>
                  <LabelWithErrorMsg
                    isRequired
                    htmlFor={phoneNumberInputId}
                    labelText={phoneNumberLabel}
                    errorMsg={requiredFieldFormattedMessage}
                    isError={isPhoneNumberError}
                  >
                    <div className={css.fieldPhoneInput}>
                      <FieldSelect
                        id={`${phoneNumberInputId}-select`}
                        name="phoneCode"
                        viewError={isPhoneNumberError}
                        className={css.phoneCode}
                        onChange={onChangeCountryCode}
                        defaultValue={this.state.callCode}
                      >
                        {countries.map(({ callCode, name }) => {
                          const isSelected = phoneNumber.startsWith(callCode);

                          return (
                            <option key={`phoneCodeSelect:${callCode}`} value={callCode} defaultValue={isSelected}>
                              {name}
                            </option>
                          );
                        })}
                      </FieldSelect>
                      <div className={css.fieldWithCountry}>
                        <FieldPhoneNumberInput
                          id={`${phoneNumberInputId}-input`}
                          name="phoneNumber"
                          placeholder={phoneNumberPlaceholder}
                          className={classNames(
                            css.phoneCodeInput,
                            { [css.emptyPhoneCode]: !this.state.callCode || this.state.callCode === "+" },
                          )}
                          validate={validators.required()}
                        />
                        <div className={css.inputCodePlaceholder}>{this?.state?.callCode}</div>
                      </div>
                    </div>
                  </LabelWithErrorMsg>
                </div>
              </div>
              {emailVerifiedInfo}
              <div className={css.bottomWrapper}>
                {genericError}
                <PrimaryButton className={css.saveChangesButton}
                  type="submit"
                  // inProgress={inProgress}
                  disabled={submitDisabled}
                >
                  <FormattedMessage id="ContactDetailsForm.saveChanges" />
                </PrimaryButton>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

ContactDetailsFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  formId: null,
  saveEmailError: null,
  savePhoneNumberError: null,
  inProgress: false,
  sendVerificationEmailError: null,
  sendVerificationEmailInProgress: false,
  email: null,
  phoneNumber: null,
  resetPasswordInProgress: false,
  resetPasswordError: null,
};

ContactDetailsFormComponent.propTypes = {
  intl: intlShape.isRequired,
  rootClassName: string,
  className: string,
  formId: string,
  inProgress: bool,
  ready: bool.isRequired,
  sendVerificationEmailInProgress: bool,
  resetPasswordInProgress: bool,
  onResendVerificationEmail: func.isRequired,
  saveEmailError: propTypes.error,
  sendVerificationEmailError: propTypes.error,
  savePhoneNumberError: propTypes.error,
  resetPasswordError: propTypes.error,
};

const ContactDetailsForm = compose(injectIntl)(ContactDetailsFormComponent);

ContactDetailsForm.displayName = "ContactDetailsForm";

export default ContactDetailsForm;
