// Add here the translations of the country names using key "<language_code>: 'transalation'" e.g. fi: 'Afganistan'
// prettier-ignore
const countryCodes = [
//{ code: "AT", callCode: "+43", name: "Austria" },
  { code: "BE", callCode: "+32", name: "Belgium" },
// {code: 'BG', callCode:'+359', name:'Bulgaria'},
// {code: 'HR', callCode:'+385', name:'Croatia'},
// {code: 'CY', callCode:'+357', name:'Cyprus'},
// {code: 'CZ', callCode:'+420', name:'Czech Republic'},
// {code: 'DK', callCode:'+45', name:'Denmark'},
// {code: 'EE', callCode:'+372', name:'Estonia'},
// {code: 'FI', callCode:'+358', name:'Finland'},
  {code: 'FR', callCode:'+33', name:'France'},
  { code: "DE", callCode: "+49", name: "Germany" },
// {code: 'GR', callCode:'+30', name:'Greece'},
// {code: 'HU', callCode:'+36', name:'Hungary'},
// {code: 'IS', callCode:'+354', name:'Iceland'},
// {code: 'IE', callCode:'+353', name:'Ireland'},
// {code: 'IT', callCode:'+39', name:'Italy'},
// {code: 'LV', callCode:'+371', name:'Latvia'},
// {code: 'LI', callCode:'+423', name:'Liechtenstein'},
// {code: 'LT', callCode:'+370', name:'Lithuania'},
// {code: 'LU', callCode:'+352', name:'Luxembourg'},
// {code: 'MT', callCode:'+356', name:'Malta'},
  { code: "NL", callCode: "+31", name: "Netherlands" },
// {code: 'NO', callCode:'+47', name:'Norway'},
// {code: 'PL', callCode:'+48', name:'Poland'},
// {code: 'PT', callCode:'+351', name:'Portugal'},
// {code: 'RO', callCode:'+40', name:'Romania'},
// {code: 'SK', callCode:'+421', name:'Slovakia'},
// {code: 'SI', callCode:'+386', name:'Slovenia'},
// {code: 'ES', callCode:'+34', name:'Spain'},
// {code: 'SE', callCode:'+46', name:'Sweden'},
// {code: 'CH', callCode:'+41', name:'Switzerland'},
// {code: 'GB', callCode:'+44', name:'UK'}
];

/**
 * @description Add the new locale here so that the correct translations will be returned.
 * If locale is unknown or the translation is missing, this will default to english country name.
 * @returns {{code: string, callCode: string, name: string}[]}
 */
const getCountryCodes = () => countryCodes.map(({ code, name, callCode }) => ({ code, name, callCode }));

export default getCountryCodes;
