import { localePart, storedLocale } from "../components/Wrapper/ClassWrapper";

const language = storedLocale() || localePart();

const aboutVegshelfCategories = {
  en: [
    { code: 1, name: "Social media" },
    { code: 2, name: "Another brand" },
    { code: 3, name: "Retailer" },
    { code: 4, name: "Vegshelf team" },
    { code: 5, name: "Advertisement" },
    { code: 0, name: "Other" }
  ],
  de: [
    { code: 1, name: "Soziale Medien" },
    { code: 2, name: "Eine andere Marke" },
    { code: 3, name: "Einzelhändler" },
    { code: 4, name: "Vegshelf-Team" },
    { code: 5, name: "Anzeige" },
    { code: 0, name: "Andere" }
  ]
};

const aboutVegshelfCategoriesBuyer = {
  en: [
    { code: 1, name: "Social media" },
    { code: 2, name: "Supplier" },
    { code: 3, name: "Retailer" },
    { code: 4, name: "Vegshelf team" },
    { code: 5, name: "Advertisement" },
    { code: 0, name: "Other" }
  ],
  de: [
    { code: 1, name: "Soziale Medien" },
    { code: 2, name: "Lieferant" },
    { code: 3, name: "Einzelhändler" },
    { code: 4, name: "Vegshelf-Team" },
    { code: 5, name: "Anzeige" },
    { code: 0, name: "Andere" }
  ]
};

const getAboutVegshelfCategories = lang => {
  // Add the lnew locale here so that the correct translations will be returned.
  // If locale is unknown or the translation is missing, this will default to english coutnry name.
  const supplierAbout = aboutVegshelfCategories[language] || aboutVegshelfCategories.en;
  const codes = supplierAbout.map(c => {

    return { code: c.code, name: c.name };
  });
  return codes;
};

export default getAboutVegshelfCategories;

export const getAboutVegshelfCategoriesBuyer = lang => {
  // Add the lnew locale here so that the correct translations will be returned.
  // If locale is unknown or the translation is missing, this will default to english coutnry name.
  const buyerAbout = aboutVegshelfCategoriesBuyer[language] || aboutVegshelfCategoriesBuyer.en;
  const codes = buyerAbout.map(c => {

    return { code: c.code, name: c.name };
  });
  return codes;
};







