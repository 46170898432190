import React, { Component, useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import * as validators from '../../util/validators';
import { Form, PrimaryButton, FieldTextInput } from '../../components';
import { defineInputId } from "../ContactDetailsForm/ContactDetailsForm";
import { PasswordInputField } from "../PasswordChangeForm/PasswordChangeForm";

import css from './ConfirmRegistrationForm.module.css';

export class ConfirmRegistrationFormComponent extends Component {

  constructor(props) {
    super(props);
    this.state = { password: '' };
    this.password = React.createRef();
    this.confirmPasswordValidator = this.confirmPasswordValidator.bind(this);
  }

  confirmPasswordValidator(value) {
    return this.password.current.value === value ? undefined : 'Passwords don’t match';
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        render={(formRenderProps) => {
          const {
            rootClassName,
            className,
            formId,
            handleSubmit,
            idp,
            values,
            email,
            errors,
            submitFailed,
            touched,
            modified,
            intl,
          } = formRenderProps;

          const classes = classNames(rootClassName || css.root, className);
          const requiredFieldMessage = intl.formatMessage({ id: "RequiredField.warningText" });
          const passwordHelpTextMessage = intl.formatMessage({ id: "ConfirmRegistrationForm.passwordHelpText" });
          const passwordMinLength = validators.minLength(passwordHelpTextMessage, validators.PASSWORD_MIN_LENGTH);
          const passwordMaxLength = validators.maxLength('*', validators.PASSWORD_MAX_LENGTH);

          // ================ EMAIL ================ //
          const emailInputName = "email";
          const emailInputId = defineInputId({ formId, inputName: emailInputName });
          /**
           * @description Email's value transformed to the lower case - to avoid case sensitivity.
           * @type {boolean}
           */
          const emailInvalidValue = values[emailInputName]?.toLowerCase() !== email && touched[emailInputName];
          const emailRequired = validators.required(requiredFieldMessage);
          const emailValid = validators.emailFormatValid('Please enter a valid e-mail address');
          const hasError = (field) => errors[field] && (submitFailed || touched[field]);
          const isEmailError = emailInvalidValue || hasError(emailInputName);

          // ================ PASSWORDS ERROR STATE ================ //
          const initialErrorsState = { newPasswordErrorMsg: "", repeatPasswordErrorMsg: "" };
          const [{ newPasswordErrorMsg, repeatPasswordErrorMsg }, setErrors] = useState(initialErrorsState);

          const passwordRequiredMessage = intl.formatMessage({ id: "RequiredField.warningText" });
          const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);

          // ================ NEW PASSWORD ================ //
          const newPasswordName = "password";
          const newPasswordInputId = defineInputId({ formId, inputName: newPasswordName });
          const newPasswordLabel = intl.formatMessage({ id: "PasswordChangeForm.newPasswordLabel" });
          const newPasswordPlaceholder = intl.formatMessage({ id: "PasswordChangeForm.newPasswordPlaceholder" });
          const newPasswordHelpText = intl.formatMessage({ id: "ConfirmRegistrationForm.passwordHelpText" });
          const passwordMinLengthMessage = intl.formatMessage(
            { id: "PasswordChangeForm.passwordTooShort" },
            { minLength: validators.PASSWORD_MIN_LENGTH },
          );
          const newPasswordRequired = validators.composeValidators(
            passwordRequired,
            passwordMinLength,
            passwordMaxLength,
          );
          const isNewPasswordEmpty = !values.password;
          const isNewPasswordTooShort = values.password?.length < validators.PASSWORD_MIN_LENGTH;
          const newPasswordTouched = modified[newPasswordName];
          const isNewPasswordError = newPasswordTouched && (isNewPasswordEmpty || isNewPasswordTooShort);
          useEffect(() => {
            if (newPasswordTouched) {
              if (isNewPasswordEmpty) {
                setErrors((prev) => ({
                  ...prev, newPasswordErrorMsg: passwordRequiredMessage,
                }));
              } else if (isNewPasswordTooShort) {
                setErrors((prev) => ({
                  ...prev, newPasswordErrorMsg: passwordMinLengthMessage,
                }));
              }
            } else {
              setErrors((prev) => ({
                ...prev, newPasswordErrorMsg: "",
              }));
            }
          }, [
            isNewPasswordError,
            isNewPasswordEmpty,
            passwordRequiredMessage,
            newPasswordTouched,
            isNewPasswordTooShort,
          ]);

          // ================ REPEAT PASSWORD ================ //
          const repeatPasswordName = "confirmPassword";
          const repeatPasswordInputId = defineInputId({ formId, inputName: repeatPasswordName });
          const repeatPasswordLabel = intl.formatMessage({ id: "PasswordChangeForm.repeatNewPasswordLabel" });
          const repeatPasswordPlaceholder = intl.formatMessage({ id: "PasswordChangeForm.newPasswordPlaceholder" });
          const repeatPasswordMatchedMessage = intl.formatMessage({ id: "PasswordChangeForm.repeatPasswordMatch" });
          const isRepeatPasswordEmpty = !values[repeatPasswordName];
          const isRepeatPasswordMatches = values[repeatPasswordName] === values.password;
          const repeatPasswordTouched = modified[repeatPasswordName];
          const isRepeatPasswordError = repeatPasswordTouched && (isRepeatPasswordEmpty || !isRepeatPasswordMatches);
          useEffect(() => {
            if (repeatPasswordTouched) {
              if (isRepeatPasswordEmpty) {
                setErrors((prev) => ({
                  ...prev, repeatPasswordErrorMsg: passwordRequiredMessage,
                }));
              } else if (!isRepeatPasswordMatches) {
                setErrors((prev) => ({
                  ...prev, repeatPasswordErrorMsg: repeatPasswordMatchedMessage,
                }));
              }
            } else {
              setErrors((prev) => ({
                ...prev, repeatPasswordErrorMsg: "",
              }));
            }
          }, [
            isRepeatPasswordError,
            passwordRequiredMessage,
            repeatPasswordTouched,
            isRepeatPasswordEmpty,
          ]);

          const submitDisabled = !values[emailInputName]
            || !values[newPasswordName]
            || !values[repeatPasswordName]
            || isEmailError
            || isNewPasswordError
            || isRepeatPasswordError;

          return (
            <Form className={classes} onSubmit={handleSubmit}>
              <div className={css.formFields}>
                <div className={css.formRow}>
                  <div className={css.formField}>
                    <label htmlFor={emailInputId}>
                      <FormattedMessage id="UserFields.email" />
                      <span className={css.requiredMarker}>*</span>
                      {hasError(emailInputName) && (<span className={css.errorMessage}>{errors[emailInputName]}</span>)}
                      {
                        (!hasError(emailInputName) && emailInvalidValue)
                        && (<span className={css.errorMessage}><FormattedMessage id="ConfirmRegistrationForm.emailDoesNotMatch" /></span>)
                      }
                    </label>
                    <div>
                      <FieldTextInput
                        type="text"
                        id={emailInputId}
                        name={emailInputName}
                        validate={validators.composeValidators(emailRequired, emailValid)}
                      />
                    </div>
                  </div>
                </div>
                <PasswordInputField
                  name={newPasswordName}
                  passwordInputId={newPasswordInputId}
                  passwordLabel={newPasswordLabel}
                  passwordPlaceholder={newPasswordPlaceholder}
                  passwordErrorMsg={newPasswordErrorMsg}
                  isPasswordError={isNewPasswordError}
                  passwordValidators={newPasswordRequired}
                  helpText={newPasswordHelpText}
                />
                <PasswordInputField
                  name={repeatPasswordName}
                  passwordInputId={repeatPasswordInputId}
                  passwordLabel={repeatPasswordLabel}
                  passwordPlaceholder={repeatPasswordPlaceholder}
                  passwordErrorMsg={repeatPasswordErrorMsg}
                  isPasswordError={isRepeatPasswordError}
                  passwordValidators={passwordRequired}
                />
              </div>
              <div className={css.bottomWrapper}>
                <PrimaryButton
                  type="submit"
                  className={css.submitButton}
                  disabled={submitDisabled}
                >
                  <FormattedMessage id="ConfirmRegistrationForm.buttonApplyNow" values={{ idp: idp }} />
                </PrimaryButton>
              </div>
            </Form>
          );
        }}
      />
    )
  }
}

ConfirmRegistrationFormComponent.defaultProps = { inProgress: false };

const { bool } = PropTypes;

ConfirmRegistrationFormComponent.propTypes = {
  inProgress: bool,
  intl: intlShape.isRequired,
};

const ConfirmRegistrationForm = compose(injectIntl)(ConfirmRegistrationFormComponent);

ConfirmRegistrationForm.displayName = 'ConfirmRegistrationForm';

export default ConfirmRegistrationForm;