import React, { Component } from 'react';
import { bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { propTypes } from '../../util/types';
import config from '../../config';
import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  NamedLink,
  MailchimpForSuppliers,
  NamedRedirect
} from '../../components';
import { TopbarContainer } from '../../containers';
import { SupplierRegistrationForm, ConfirmRegistrationForm } from '../../forms';

import facebookImage from '../../assets/VegshelfFacebook-1200x630.png';
import twitterImage from '../../assets/VegshelfTwitter-600x314.png';
import css from './SupplierRegistrationFormPage.module.css';
import { supplierRegistration } from './SupplierRegistrationFormPage.duck';
import { validedEmailAddressClear } from '../../ducks/Modal.duck';

export class SupplierRegistrationFormPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { sent: false };
    this.toBuyer = false;
    this.newSupplier = null;
  }
  componentWillUnmount() {
    if (this.toBuyer) {
      return;
    }

    this.props.onValidedEmailAddressClear();
  }
  render() {
    const {
      history,
      intl,
      location,
      onSupplierRegistration,
      scrollingDisabled,
      supplierRegistrationRequestError,
      supplierRegistrationRequestInProgress,
      supplierRequestSent,
      validedEmail,
    } = this.props;

    const showThanksScreen = supplierRequestSent === true && this.state.sent === true;
    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage({ id: 'SupplierRegistrationFormPage.schemaTitle' }, { siteTitle });
    const schemaDescription = intl.formatMessage({ id: 'SupplierRegistrationFormPage.schemaDescription' }, { siteTitle });
    const schemaImage = `${config.canonicalRootURL}${facebookImage}`;
    const onSubmit = values => {
      const newSupplier = { ...values, businessEMail: validedEmail.toLowerCase(), iAgreeToReceive: !!values.iAgreeToReceive.length };
      onSupplierRegistration(newSupplier);
      this.setState({ ...this.state, sent: true })
    };

    if (validedEmail === null && !showThanksScreen && !this.toBuyer) {
      //return (<Redirect to='/' />);
      return (<NamedRedirect name="LandingPage" />)
    }
    return (
      <Page
        className={css.root}
        scrollingDisabled={scrollingDisabled}
        contentType="website"
        description={schemaDescription}
        title={schemaTitle}
        robots='noindex'
        facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
        twitterImages={[
          { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
        ]}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'WebPage',
          description: schemaDescription,
          name: schemaTitle,
          image: [schemaImage],
        }}
      >
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer onlyLogo="true" />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain className={css.layoutWrapperMain}>
            <div className={css.formContainer}>
              <div className={css.formPanel}>
                {!showThanksScreen ? (<> <h1 className={css.formTitle}>
                  <FormattedMessage id="SupplierRegistrationFormPage.step1FormTitle" />
                </h1>
                  <p className={css.formDescription}>
                    <FormattedMessage id="SupplierRegistrationFormPage.step1FormDescription" />
                  </p>
                  <h4 className={css.goToRegistrationBuyer}>
                    <FormattedMessage id="SupplierRegistrationFormPage.step1LinkIntro" />
                    <NamedLink name='BuyerRegistrationPage' onClick={() => { this.toBuyer = true; }}>
                      <FormattedMessage id="SupplierRegistrationFormPage.step1LinkText" />
                    </NamedLink>
                  </h4>
                  <div className={css.fieldsContainer}>
                    <SupplierRegistrationForm
                      className={css.form}
                      email={validedEmail}
                      inProgress={supplierRegistrationRequestInProgress}
                      supplierRegistered={supplierRequestSent}
                      onSubmit={onSubmit}
                    />
                  </div></>) : (<div className={css.thanksScreenBlock}>
                    <h1>
                      <FormattedMessage id="SupplierRegistrationFormPage.thankYouForRegister" />
                    </h1>
                    <p>
                      <FormattedMessage id="SupplierRegistrationFormPage.yourInfoReceived" />
                    </p>
                    <div className={css.thanksButtons}>
                      <NamedLink name="LandingPage" className={css.goToHomeButton}>
                        <FormattedMessage id="SupplierRegistrationFormPage.backToHomepage" />
                      </NamedLink>
                    </div>
                    <div className={css.mediaSection}>
                      <div className={css.firstColumnMediaData}>
                        <h3 className={css.mediaSectionTitle}>
                          <FormattedMessage id="SupplierRegistrationFormPage.joinSupplierCommunity" />
                        </h3>
                        <MailchimpForSuppliers />
                      </div>
                      <div className={css.secondColumnMediaData}>
                        <h3 className={css.mediaSectionTitle}>
                          <FormattedMessage id="SupplierRegistrationFormPage.favouriteSocial" />
                        </h3>
                        <div className={css.mediaButtons}>
                          <a className={css.mediaLinkedIn} href="https://www.linkedin.com/company/vegshelf/" target="_blank"></a>
                          <a className={css.mediaTwitter} href="https://twitter.com/vegshelf" target="_blank"></a>
                          <a className={css.mediaFacebook} href="https://www.facebook.com/vegshelf/" target="_blank"></a>
                          <a className={css.mediaInstagram} href="https://www.instagram.com/vegshelf/" target="_blank"></a>
                          <a className={css.mediaTiktok} href="https://www.tiktok.com/@vegshelf" target="_blank"></a>
                        </div>
                      </div>
                    </div>
                  </div>)}
              </div>
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

SupplierRegistrationFormPageComponent.defaultProps = {
  currentUserListing: null,
  currentUserListingFetched: false,
};

SupplierRegistrationFormPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};
const mapStateToProps = state => {
  return {
    scrollingDisabled: isScrollingDisabled(state),
    ...state.SupplierRegistrationFormPage,
    validedEmail: state.Modals.validedEmail,
  };
};

const mapDispatchToProps = dispatch => ({
  onSupplierRegistration: values => dispatch(supplierRegistration(values)),
  onValidedEmailAddressClear: () => dispatch(validedEmailAddressClear()),
});

const SupplierRegistrationFormPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(SupplierRegistrationFormPageComponent);

export default SupplierRegistrationFormPage;
