import React, { Component } from 'react';
import { bool } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import classNames from 'classnames';
import config from '../../config';
import ReactTypingEffect from 'react-typing-effect';
import { displayRegistrationConfirmDialog } from '../../ducks/Modal.duck';
import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  NamedLink,
  RegistrationConfirmDialog,
  MailchimpForSuppliers
} from '../../components';

import { TopbarContainer } from '../../containers';

import facebookImage from '../../assets/VegshelfFacebook-1200x630.png';
import twitterImage from '../../assets/VegshelfTwitter-600x314.png';
import css from './ApplyToSellPage.module.css';

import referenceCompanyLogo1 from '../../assets/cwye-logo.png';
import referenceCompanyLogo2 from '../../assets/superbon-logo.png';
import referenceCompanyLogo3 from '../../assets/roy-kombucha.png';
import referenceCompanyLogo4 from '../../assets/yofi-logo.png';

import referenceCategoryImage1 from '../../assets/cwye-unique-condiments-sauces-vegshelf.png';
import referenceCategoryImage2 from '../../assets/superbon-potato-crisps-vegshelf.png';
import referenceCategoryImage3 from '../../assets/roy-kombucha-fermented-tea.png';
import referenceCategoryImage4 from '../../assets/yofi-plant-based-milk-vegshelf.png';

import Feature_1 from '../../assets/your-online-b2b-store-vegshelf-marketplace.png';
import Feature_2 from '../../assets/customized-sales-region-vegshelf-marketplace.png';
import Feature_3 from '../../assets/messages-chat-with-b2b-buyers-vegshelf-marketplace.png';
import Feature_4 from '../../assets/stock-inventory-management-vegshelf-marketplace.png';
import Feature_5 from '../../assets/simple-order-account-management-vegshelf-marketplace.png';
import Feature_6 from '../../assets/automated-invoice-management-vegshelf-marketplace.png';

import Blog_1 from '../../assets/vegshelf-blog-independent-retail-for-emerging-cpg.png';
import Blog_2 from '../../assets/vegshelf-blog-where-source-vegan-products.png';
import Blog_3 from '../../assets/vegshelf-blog-grocery-shops-need-more-vegan-products.png';
import Blog_4 from '../../assets/vegshelf-blog-coffee-shops-need-more-vegan-products.png';

import arrowLeft from '../../assets/arrow-left-000000.svg';
import arrowRight from '../../assets/arrow-right-000000.svg';
import arrowLeftWhite from '../../assets/arrow-left-ffffff.svg';
import arrowRightWhite from '../../assets/arrow-right-ffffff.svg';

import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react';
SwiperCore.use([Navigation]);

const email = <a href="mailto:hello@vegshelf.com" target="_blank">hello@vegshelf.com</a>;

export class ApplyToSellPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { categoryIndex: 0, planIndex: 1, blogIndex: 0, FAQIndex: -1 };
  }
  render() {
    const {
      intl,
      scrollingDisabled,
      params,
      onShowRegistrationConfirmDialog
    } = this.props;

    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage({ id: 'ApplyToSellPage.schemaTitle' }, { siteTitle });
    const schemaDescription = intl.formatMessage({ id: 'ApplyToSellPage.schemaDescription' }, { siteTitle });
    const schemaImage = `${config.canonicalRootURL}${facebookImage}`;
    const categoriesBlocks = [
      {
        name: 'Condiments',
        titleImageUrl: referenceCompanyLogo1,
        companyName: <FormattedMessage id="SupplierLandingPage.categories.companyName1" />,
        comment: <FormattedMessage id="SupplierLandingPage.categories.comment1" />,
        categoryAltText: 'Amsterdam-based condiment brand, Celebrate What You Eat',
        authorSubTitle: <FormattedMessage id="SupplierLandingPage.categories.condiments" />,
        categoryImageUrl: referenceCategoryImage1
      },
      {
        name: 'Snacks',
        titleImageUrl: referenceCompanyLogo2,
        companyName: <FormattedMessage id="SupplierLandingPage.categories.companyName2" />,
        comment: <FormattedMessage id="SupplierLandingPage.categories.comment2" />,
        categoryAltText: 'Brussels-based potato crisps brand, Superbon',
        authorSubTitle: <FormattedMessage id="SupplierLandingPage.categories.snacks" />,
        categoryImageUrl: referenceCategoryImage2
      },
      {
        name: 'Beverages',
        titleImageUrl: referenceCompanyLogo3,
        companyName: <FormattedMessage id="SupplierLandingPage.categories.companyName3" />,
        comment: <FormattedMessage id="SupplierLandingPage.categories.comment3" />,
        categoryAltText: 'Berlin-based kombucha brand, ROY Kombucha',
        authorSubTitle: <FormattedMessage id="SupplierLandingPage.categories.beverages" />,
        categoryImageUrl: referenceCategoryImage3
      },
      {
        name: 'Plant-based milk',
        titleImageUrl: referenceCompanyLogo4,
        companyName: <FormattedMessage id="SupplierLandingPage.categories.companyName4" />,
        comment: <FormattedMessage id="SupplierLandingPage.categories.comment4" />,
        categoryAltText: 'Paris-based plant-based milk brand, Yofi',
        authorSubTitle: <FormattedMessage id="SupplierLandingPage.categories.dairyAlternatives" />,
        categoryImageUrl: referenceCategoryImage4
      },
    ];
    const orderPlans = [
      {
        title: <FormattedMessage id="SupplierLandingPage.orderPlans.title1" />,
        decription: <FormattedMessage id="SupplierLandingPage.orderPlans.description1" />,
        imageUrl: Feature_6
      },
      {
        title: <FormattedMessage id="SupplierLandingPage.orderPlans.title2" />,
        decription: <FormattedMessage id="SupplierLandingPage.orderPlans.description2" />,
        imageUrl: Feature_3
      },
      {
        title: <FormattedMessage id="SupplierLandingPage.orderPlans.title3" />,
        decription: <FormattedMessage id="SupplierLandingPage.orderPlans.description3" />,
        imageUrl: Feature_5
      },
      {
        title: <FormattedMessage id="SupplierLandingPage.orderPlans.title4" />,
        decription: <FormattedMessage id="SupplierLandingPage.orderPlans.description4" />,
        imageUrl: Feature_2
      },
      {
        title: <FormattedMessage id="SupplierLandingPage.orderPlans.title5" />,
        decription: <FormattedMessage id="SupplierLandingPage.orderPlans.description5" />,
        imageUrl: Feature_4
      },
      {
        title: <FormattedMessage id="SupplierLandingPage.orderPlans.title6" />,
        decription: <FormattedMessage id="SupplierLandingPage.orderPlans.description6" />,
        imageUrl: Feature_1
      }
    ];

    const blogs = [
      {
        imageUrl: Blog_1,
        imageAltText: "Why independent retail is perfect for new brands",
        pageLink: "BlogArticle3"
      },
      {
        imageUrl: Blog_2,
        imageAltText: "Where to source vegan food and beverage products",
        pageLink: "BlogArticle4"
      },
      {
        imageUrl: Blog_3,
        imageAltText: "Why retailers need more vegan products in assortment",
        pageLink: "BlogArticle1"
      },
      {
        imageUrl: Blog_4,
        imageAltText: "Why coffee shops need to offer vegan food and drinks",
        pageLink: "BlogArticle2"
      }
    ];


    const FAQ = [
      {
        question: <FormattedMessage id="SupplierLandingPage.faq.q1" />,
        answer: <FormattedMessage id="SupplierLandingPage.faq.a1" />
      },
      {
        question: <FormattedMessage id="SupplierLandingPage.faq.q2" />,
        answer: <FormattedMessage id="SupplierLandingPage.faq.a2" />
      },
      {
        question: <FormattedMessage id="SupplierLandingPage.faq.q3" />,
        answer: <FormattedMessage id="SupplierLandingPage.faq.a3" />
      },
      {
        question: <FormattedMessage id="SupplierLandingPage.faq.q4" />,
        answer: <FormattedMessage id="SupplierLandingPage.faq.a4" />
      },
      {
        question: <FormattedMessage id="SupplierLandingPage.faq.q5" />,
        answer: <FormattedMessage id="SupplierLandingPage.faq.a5" />
      },
      {
        question: <FormattedMessage id="SupplierLandingPage.faq.q6" />,
        answer: <FormattedMessage id="SupplierLandingPage.faq.a6" />
      },
      {
        question: <FormattedMessage id="SupplierLandingPage.faq.q7" />,
        answer: <FormattedMessage id="SupplierLandingPage.faq.a7" />
      },
      {
        question: <FormattedMessage id="SupplierLandingPage.faq.q8" />,
        answer: <FormattedMessage id="SupplierLandingPage.faq.a8" />
      },
      {
        question: <FormattedMessage id="SupplierLandingPage.faq.q9" />,
        answer: <FormattedMessage id="SupplierLandingPage.faq.a9" />
      },
      {
        question: <FormattedMessage id="SupplierLandingPage.faq.q10" />,
        answer: <FormattedMessage id="SupplierLandingPage.faq.a10" />
      },
      {
        question: <FormattedMessage id="SupplierLandingPage.faq.q11" />,
        answer: <FormattedMessage id="SupplierLandingPage.faq.a11" />
      },
      {
        question: <FormattedMessage id="SupplierLandingPage.faq.q12" />,
        answer: <FormattedMessage id="SupplierLandingPage.faq.a12" />
      },
      {
        question: <FormattedMessage id="SupplierLandingPage.faq.q13" />,
        answer: <FormattedMessage id="SupplierLandingPage.faq.a13" />
      },
      {
        question: <FormattedMessage id="SupplierLandingPage.faq.q14" />,
        answer: <FormattedMessage id="SupplierLandingPage.faq.a14" values={{ email }} />
      }
    ];
    //const currentCategory = categoriesBlocks[this.state.categoryIndex];
    return (
      <Page
        className={css.root}
        scrollingDisabled={scrollingDisabled}
        contentType="website"
        description={schemaDescription}
        title={schemaTitle}
        robots='index'
        facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
        twitterImages={[
          { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
        ]}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'WebPage',
          description: schemaDescription,
          name: schemaTitle,
          image: [schemaImage],
        }}
      >
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer />
            <div className={css.pageHeaderContainer}>
              <div className={css.pageHeaderSection}>
                <RegistrationConfirmDialog />
                <h1 className={css.sectionBothMainTitles}>
                  <div className={css.sectionMainTitle}>
                    <FormattedMessage id="SupplierLandingPage.headerTitle" />
                  </div>
                  <div className={css.sectionMainTitle}>
                    <ReactTypingEffect
                      eraseDelay="1000"
                      speed="100"
                      typingDelay="1000"
                      eraseSpeed="70"
                      text={[
                        `${intl.formatMessage({ id: 'SupplierLandingPage.headerStoreType1' })}`,
                        `${intl.formatMessage({ id: 'SupplierLandingPage.headerStoreType2' })}`,
                        `${intl.formatMessage({ id: 'SupplierLandingPage.headerStoreType3' })}`,
                        `${intl.formatMessage({ id: 'SupplierLandingPage.headerStoreType4' })}`,
                        `${intl.formatMessage({ id: 'SupplierLandingPage.headerStoreType5' })}`,
                        `${intl.formatMessage({ id: 'SupplierLandingPage.headerStoreType6' })}`,
                        `${intl.formatMessage({ id: 'SupplierLandingPage.headerStoreType7' })}`,
                      ]}
                    />
                  </div>
                </h1>
                <h2 className={css.sectionSubTitle}>
                  <FormattedMessage id="ApplyToSellPage.headerSection.subTitle" />
                </h2>
                <div className={css.applyToSellButtonContainer}>
                  <a className={css.applyToSellButton} onClick={onShowRegistrationConfirmDialog}>
                    <FormattedMessage id="ApplyToSellPage.headerSection.applyToSell" />
                  </a>
                </div>
                <p className={css.loginText}>
                  <FormattedMessage id="ApplyToSellPage.headerSection.alreadyAnAccount" />
                  {/* 21.07.23 Vegshelf freeze: added Login link so login page is still accessible */}
                  <NamedLink className={css.loginLink} name="LoginPage">
                    <FormattedMessage id="ApplyToSellPage.headerSection.logIn" />
                  </NamedLink>
                </p>
              </div>
            </div>
          </LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div className={css.heroImageSection}>
              <div className={css.descImages}>
                <div className={css.rootImage1}>&nbsp;</div>
                <div className={css.rootImage2}>&nbsp;</div>
                <div className={css.rootImage3}>&nbsp;</div>
                <div className={css.rootImage4}>&nbsp;</div>
                <div className={css.rootImage5}>&nbsp;</div>
              </div>
            </div>
            <div className={css.uspContainer}>
              <div className={css.usps}>
                <div className={css.singleUsp}>
                  <div className={css.stepIcon1}></div>
                  <h3 className={css.stepTitle}>
                    <FormattedMessage id="ApplyToSellPage.valueProposition1" />
                  </h3>
                </div>

                <div className={css.singleUsp}>
                  <div className={css.stepIcon2}></div>
                  <h3 className={css.stepTitle}>
                    <FormattedMessage id="ApplyToSellPage.valueProposition2" />
                  </h3>
                </div>

                <div className={css.singleUsp}>
                  <div className={css.stepIcon3}></div>
                  <h3 className={css.stepTitle}>
                    <FormattedMessage id="ApplyToSellPage.valueProposition3" />
                  </h3>
                </div>

                <div className={css.singleUsp}>
                  <div className={css.stepIcon4}></div>
                  <h3 className={css.stepTitle}>
                    <FormattedMessage id="ApplyToSellPage.valueProposition4" />
                  </h3>
                </div>
              </div>
            </div>
            <div className={css.keyUSPContainer}>
              <div className={css.keyUSPSection}>
                <div className={css.infoSection}>
                  <div className={css.infoSectionDataLeft}>
                    <h2 className={css.infoSectionTitle}>
                      <FormattedMessage id="SupplierLandingPage.infoSection.title1" />
                    </h2>
                    <p className={css.infoSectionText}>
                      <FormattedMessage id="SupplierLandingPage.infoSection.text1" />
                    </p>
                  </div>
                  <div className={css.infoSectionImage1}>&nbsp;</div>
                </div>
                <div className={css.infoSection}>
                  <div className={css.infoSectionImage2}>&nbsp;</div>
                  <div className={css.infoSectionDataRight}>
                    <h2 className={css.infoSectionTitle}>
                      <FormattedMessage id="SupplierLandingPage.infoSection.title2" />
                    </h2>
                    <p className={css.infoSectionText}>
                      <FormattedMessage id="SupplierLandingPage.infoSection.text2" />
                    </p>
                  </div>
                </div>
                <div className={css.infoSection}>
                  <div className={css.infoSectionDataLeft}>
                    <h2 className={css.infoSectionTitle}>
                      <FormattedMessage id="SupplierLandingPage.infoSection.title3" />
                    </h2>
                    <p className={css.infoSectionText}>
                      <FormattedMessage id="SupplierLandingPage.infoSection.text3" />
                    </p>
                  </div>
                  <div className={css.infoSectionImage3}>&nbsp;</div>
                </div>
                <div className={css.registerSection}>
                  <a className={css.registerSectionLink} onClick={onShowRegistrationConfirmDialog}>
                    <FormattedMessage id="ApplyToSellPage.infoSection.register" />
                  </a>
                </div>
              </div>
            </div>
            <div className={css.categorySection}>
              <h2>
                <FormattedMessage id="SupplierLandingPage.categoriesTitle" />
              </h2>
              <div className={css.categoryParent}>
                <div className={css.categoryContainer}>
                  <Swiper
                    setWrapperSize
                    simulateTouch={false}
                    className={css.test}
                    navigation={{
                      nextEl: '.swiper-button-next',
                      prevEl: '.swiper-button-prev',
                    }}
                    loop
                    loopedSlides={categoriesBlocks.length}
                    slidesPerView={1}
                    speed={300}
                  >
                    <div className={classNames(css.leftButton, 'swiper-button-prev')}>
                      <img src={arrowLeft} alt=""></img>
                    </div>
                    {categoriesBlocks.map((c, i) => {
                      return (
                        <SwiperSlide key={i} className={css.commentContainer}>
                          <div className={css.currentCategoryDetails}>
                            <div className={css.categoryInfo}>
                              <img src={c.titleImageUrl} className={css.commentCompanyLogo} alt={c.companyName} />
                              <p className={css.categoryComment}>
                                {c.comment}
                              </p>
                              <div className={css.commentAuthorSection}>
                                <div className={css.authorInfo}>
                                  <p>{c.authorSubTitle}</p>
                                </div>
                              </div>
                            </div>
                            <img src={c.categoryImageUrl} className={css.referenceImage} alt={c.categoryAltText} />
                          </div>
                        </SwiperSlide>
                      );
                    })}
                    <div className={classNames(css.rightButton, 'swiper-button-next')}>
                      <img src={arrowRight} alt=""></img>
                    </div>
                    <div className={css.buttonContainerBrands}>
                      <div className={classNames(css.leftButtonMobile, 'swiper-button-prev')}>
                        <img src={arrowLeft} alt=""></img>
                      </div>
                      <div className={classNames(css.rightButtonMobile, 'swiper-button-next')}>
                        <img src={arrowRight} alt=""></img>
                      </div>
                    </div>
                  </Swiper>
                </div>
              </div>
            </div>
            <div className={css.inviteSectionContainer}>
              <div className={css.inviteSectionContainerOne}>
              </div>
              <div className={css.inviteSectionContainerTwo}>
                <div className={css.inviteSectionText}>
                  <h2 className={css.inviteSectionDataTitle}>
                    <FormattedMessage id="ApplyToSellPage.inviteSection.title" />
                  </h2>
                  <p className={css.inviteSectionDataSubtitle}>
                    <FormattedMessage id="ApplyToSellPage.inviteSection.subTitle" />
                  </p>
                  <div className={css.inviteSectionButtons}>
                    <a className={css.inviteSectionLink} onClick={onShowRegistrationConfirmDialog}>
                      <FormattedMessage id="ApplyToSellPage.inviteSection.joinNow" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className={css.orderDescriptionSectionContainer}>
              <div className={css.orderDescriptionSection}>
                <h2>
                  <FormattedMessage id="SupplierLandingPage.orderDescription.title" />
                </h2>
                <p>
                  <FormattedMessage id="SupplierLandingPage.orderDescription.subTitle" />
                </p>
                <div className={css.supplierUSPContainer}>
                  <Swiper
                    setWrapperSize
                    simulateTouch={false}
                    className={css.test}
                    navigation={{
                      nextEl: '.swiper-button-next',
                      prevEl: '.swiper-button-prev',
                    }}
                    loop
                    loopedSlides={orderPlans.length}
                    slidesPerView="auto"
                    speed={300}
                    spaceBetween={50}
                  >
                    {orderPlans.map((p, i) => {
                      return (
                        <SwiperSlide key={i} className={css.screenItem}>
                          <div>{p.title}</div>
                          <p>{p.decription}</p>
                          <img src={p.imageUrl} alt={p.title + ' - ' + p.decription} />
                        </SwiperSlide>
                      );
                    })}
                    <div className={css.buttonContainer}>
                      <div className={classNames(css.swiperLeft, 'swiper-button-prev')}>
                        <img src={arrowLeft} alt=""></img>
                      </div>
                      <div className={classNames(css.swiperRight, 'swiper-button-next')}>
                        <img src={arrowRight} alt=""></img>
                      </div>
                    </div>
                  </Swiper>
                </div>
              </div>
            </div>
            <div className={css.FAQSection}>
              <h3 className={css.sectionFAQTitle}>
                <FormattedMessage id="SupplierLandingPage.faq.title" />
              </h3>
              <div className={css.questionContainer}>
                {FAQ.map((f, i) => {
                  const itemCss = classNames(css.questionItem, {
                    [css.selectedQuestion]: this.state.FAQIndex === i
                  });
                  return (
                    <div key={i} className={itemCss}>
                      <p
                        className={css.question}
                        onClick={() => {
                          this.setState({ ...this.state, FAQIndex: (this.state.FAQIndex === i ? -1 : i) })
                        }}
                      >
                        {f.question}
                      </p>
                      <p className={css.answer}>{f.answer}</p>
                    </div>
                  )
                })}
              </div>
            </div>
            <div className={css.growWholessaleBusinessContainer}>
              <div className={css.growWholesaleBusinessSection}>
                <h3 className={css.sectionTitle}>
                  <FormattedMessage id="SuplierSectionGrowWholesaleBusiness.sectionTitle" />
                </h3>
                <div className={css.inlineContainer}>
                  <div className={css.step}>
                    <div className={css.blockNumber}>1
                    </div>
                    <p className={css.blockText}>
                      <FormattedMessage id="SuplierSectionGrowWholesaleBusiness.part1Text" />
                    </p>
                  </div>
                  <div className={css.step}>
                    <div className={css.blockNumber}>2
                    </div>
                    <p className={css.blockText}>
                      <FormattedMessage id="SuplierSectionGrowWholesaleBusiness.part2Text" />
                    </p>
                  </div>
                  <div className={css.step}>
                    <div className={css.blockNumber}>3
                    </div>
                    <p className={css.blockText}>
                      <FormattedMessage id="SuplierSectionGrowWholesaleBusiness.part3Text" />
                    </p>
                  </div>
                </div>
                <div className={css.buttonSection}>
                  <a className={css.signUpButton} onClick={onShowRegistrationConfirmDialog}>
                    <FormattedMessage id="SuplierSectionGrowWholesaleBusiness.signupButton" />
                  </a>
                </div>
              </div>
            </div>
            <div className={css.resourceContainer}>
              <div className={css.resourcesSection}>
                <div className={css.resourceInfo}>
                  <p className={css.resourceInfoBlogTitle}>
                    <FormattedMessage id="SupplierLandingPage.resourceSection.title" />
                  </p>
                  <div className={css.firstTextPartContainer}>
                    <h3 className={css.resourceInfoBlogSubTitle}>
                      <FormattedMessage id="SupplierLandingPage.resourceSection.subTitle1" />
                      <i><FormattedMessage id="SupplierLandingPage.resourceSection.subTitle2" /></i>
                    </h3>
                  </div>
                  <p className={css.resourceInfoBlogText}>
                    <FormattedMessage id="SupplierLandingPage.resourceSection.infoText" />
                  </p>
                  <div className={css.resourceButtons}>
                    <NamedLink className={css.resourcesReadMoreButton} name="BlogPage">
                      <FormattedMessage id="BuyerLandingPage.blogButton" />
                    </NamedLink>
                  </div>
                </div>
                <div className={css.resourceItems}>
                  <Swiper
                    simulateTouch={false}
                    setWrapperSize
                    navigation={{
                      nextEl: '.swiper-button-next',
                      prevEl: '.swiper-button-prev',
                    }}
                    spaceBetween={30}
                    loop
                    loopedSlides={blogs.length}
                    slidesPerView="auto"
                    speed={500}
                  >
                    {blogs.map((item, i) => {
                      return (
                        <SwiperSlide key={i} className={css.resourceItem}>
                          <NamedLink name={item.pageLink}>
                            <img src={item.imageUrl} className={css.resourceImage} alt={item.imageAltText} />
                          </NamedLink>
                        </SwiperSlide>
                      );
                    })}
                    <div className={css.swiperButtonContainer}>
                      <div className={classNames(css.resourceLeftButton, 'swiper-button-prev')}>
                        <img src={arrowLeft} alt=""></img>
                      </div>
                      <div className={classNames(css.resourceRightButton, 'swiper-button-next')}>
                        <img src={arrowRight} alt=""></img>
                      </div>
                    </div>
                  </Swiper>
                </div>
              </div>
            </div>
            <div className={css.mediaSectionContainer}>
              <div className={css.mediaSection}>
                <div className={css.firstColumnMediaData}>
                  <p className={css.mediaSectionTitle}>
                    <FormattedMessage id="SupplierLandingPage.socialMedia" />
                  </p>
                  <h3 className={css.mediaDescription}>
                    <FormattedMessage id="SupplierLandingPage.letsHangOut" />
                  </h3>
                  <div className={css.mediaButtons}>
                    <a className={css.mediaLinkedIn} href="https://www.linkedin.com/company/vegshelf/" target="_blank"></a>
                    <a className={css.mediaTwitter} href="https://twitter.com/vegshelf" target="_blank"></a>
                    <a className={css.mediaFacebook} href="https://www.facebook.com/vegshelf/" target="_blank"></a>
                    <a className={css.mediaInstagram} href="https://www.instagram.com/vegshelf/" target="_blank"></a>
                    <a className={css.mediaTiktok} href="https://www.tiktok.com/@vegshelf" target="_blank"></a>
                  </div>
                </div>
                <div className={css.secondColumnMediaData}>
                  <p className={css.mediaSectionTitle}>
                    <FormattedMessage id="ApplyToSellPage.vegshelfNewsletter" />
                  </p>
                  <h3 className={css.mediaDescriptionRight}>
                    <FormattedMessage id="ApplyToSellPage.joinCommunity" />
                  </h3>
                  <MailchimpForSuppliers />
                </div>
              </div>
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page >
    );
  }
};

ApplyToSellPageComponent.defaultProps = {
};

ApplyToSellPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired
};
const mapStateToProps = state => {

  return {
    scrollingDisabled: isScrollingDisabled(state)
  };
};

const mapDispatchToProps = dispatch => ({
  onShowRegistrationConfirmDialog: () => dispatch(displayRegistrationConfirmDialog(true, false))
});

const ApplyToSellPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(ApplyToSellPageComponent);

export default ApplyToSellPage;