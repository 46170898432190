import React, { Component } from 'react';
import Mailchimp from 'react-mailchimp-form';
import { FormattedMessage } from '../../util/reactIntl';

import css from './MailchimpForSuppliers.module.css';

class MailchimpForSuppliers extends Component {
    render() {
        return (
            <Mailchimp
                action={process.env.REACT_APP_SUPPLIER_KEY}
                fields={[
                    {
                        name: 'EMAIL',
                        placeholder: 'Email',
                        type: 'email',
                        required: true
                    }
                ]}
                messages={
                    {
                        sending: <FormattedMessage id="MailchimpForBuyers.messageSending" />,
                        success: <FormattedMessage id="MailchimpForBuyers.messageThankYou" />,
                        error: <FormattedMessage id="MailchimpForBuyers.messageError" />,
                        empty: <FormattedMessage id="MailchimpForBuyers.messageWriteAnEmail" />,
                        duplicate: <FormattedMessage id="MailchimpForBuyers.messageTooManyAttempts" />,
                        button: <FormattedMessage id="MailchimpForBuyers.signMeUp" />
                    }
                }
                className={css.supplierForm}
            />
        );
    }
}

export default MailchimpForSuppliers;