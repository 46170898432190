import React, { Component } from 'react';
import { bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { propTypes } from '../../util/types';

import css from './HowItWorksForBuyersPage.module.css';

const email = <a href="mailto:hello@vegshelf.com" target="_blank">hello@vegshelf.com</a>;

export class HowItWorksForBuyersPageComponent extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      history,
      intl
    } = this.props;
    return (
      <div className={css.vegshelfInformationContainer}>
        <div className={css.vegshelfInformationBox1}>
          <h1 className={css.pageTitle}>
            <FormattedMessage id="HowItWorksPage.Buyers.PageTitle" />
          </h1>
          <h2 className={css.mainTitle}>
            <FormattedMessage id="HowItWorksPage.Buyers.Title1" />
          </h2>
          <p className={css.bodyText}>
            <FormattedMessage id="HowItWorksPage.Buyers.Body1" />
          </p>
          <h2 className={css.mainTitle}>
            <FormattedMessage id="HowItWorksPage.Buyers.Title2" />
          </h2>
          <p className={css.bodyText}>
            <FormattedMessage id="HowItWorksPage.Buyers.Body2" />
          </p>
          <h2 className={css.mainTitle}>
            <FormattedMessage id="HowItWorksPage.Buyers.Title3" />
          </h2>
          <p className={css.bodyText}>
            <FormattedMessage id="HowItWorksPage.Buyers.Body3" />
          </p>
          <h2 className={css.mainTitle}>
            <FormattedMessage id="HowItWorksPage.Buyers.Title4" />
          </h2>
          <p className={css.bodyText}>
            <FormattedMessage id="HowItWorksPage.Buyers.Body4" />
          </p>
          <h2 className={css.mainTitle}>
            <FormattedMessage id="HowItWorksPage.Buyers.Title5" />
          </h2>
          <p className={css.bodyText}>
            <FormattedMessage id="HowItWorksPage.Buyers.Body5" values={{ email }} />
          </p>
          <h2 className={css.mainTitle}>
            <FormattedMessage id="HowItWorksPage.Buyers.Title6" />
          </h2>
          <p className={css.bodyText}>
            <FormattedMessage id="HowItWorksPage.Buyers.Body6" values={{ email }} />
          </p>
          <h2 className={css.mainTitle}>
            <FormattedMessage id="HowItWorksPage.Buyers.FinalTitle" />
          </h2>
          <p className={css.bodyText}>
            <FormattedMessage id="HowItWorksPage.Buyers.FinalBody" values={{ email }} />
          </p>
        </div>
      </div>
    );
  }
};

HowItWorksForBuyersPageComponent.defaultProps = {
  currentUserListing: null,
  currentUserListingFetched: false,
};

HowItWorksForBuyersPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};
const mapStateToProps = state => {
  const { currentUser } = state.user;
  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser
  };
};

const HowItWorksForBuyersPage = compose(
  withRouter,
  connect(mapStateToProps),
  injectIntl
)(HowItWorksForBuyersPageComponent);

export default HowItWorksForBuyersPage;