import { getCategorySections } from "../../util/api";

// ================ Action types ================ //
export const NEW_SECTIONS_LOAD_REQUEST = "app/new/sections/request";
export const NEW_SECTIONS_LOAD_LOAD_SUCCESS = "app/new/sections/success";
export const NEW_SECTIONS_PRODUCTS_LOAD_SUCCESS = "app/new/sections/products/success";
export const NEW_SECTIONS_LOAD_ERROR = "app/new/sections/error";

export const LOAD_SECTION_PRODUCTS_REQUEST = "app/new/section/products/request";
export const LOAD_SECTION_PRODUCTS_SUCCESS = "app/new/section/products/success";
export const LOAD_SECTION_PRODUCTS_ERROR = "app/new/section/products/error";

// ================ Reducer ================ //
const initialState = {
  categorySections: null,
  categorySectionsInProgress: false,
  categorySectionsError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case NEW_SECTIONS_LOAD_REQUEST:
      return {
        ...state,
        categorySections: null,
        categorySectionsInProgress: true,
        categorySectionsError: null,
      };
    case NEW_SECTIONS_LOAD_LOAD_SUCCESS:
      return { ...state, categorySections: payload };
    case NEW_SECTIONS_PRODUCTS_LOAD_SUCCESS:
      return { ...state, categorySectionsInProgress: false, };
    case NEW_SECTIONS_LOAD_ERROR:
      return { ...state, categorySectionsInProgress: false, categorySectionsError: payload };
    case LOAD_SECTION_PRODUCTS_REQUEST:
      return {
        ...state,
        categorySections: state.categorySections.map((s) => {
          if (s.key !== payload.key) {
            return s;
          } else {
            return { ...s, productLoadInProgress: true, products: null, error: null };
          }
        }),
      };
    case LOAD_SECTION_PRODUCTS_SUCCESS:
      return {
        ...state,
        categorySections: state.categorySections.map((s) => {
          if (s.key !== payload.key) {
            return s;
          } else {
            return { ...s, productLoadInProgress: false, products: payload.products, error: null };
          }
        }),
      };
    case LOAD_SECTION_PRODUCTS_ERROR:
      return {
        ...state,
        categorySections: state.categorySections.map((s) => {
          if (s.key !== payload.key) {
            return s;
          } else {
            return { ...s, productLoadInProgress: false, products: null, error: payload.errorData };
          }
        }),
      };
    default:
      return state;
  }
}

// ================ Action creators ================ //
export const loadSectionsRequest = () => ({ type: NEW_SECTIONS_LOAD_REQUEST });
export const loadSectionsSuccess = (sections) => ({ type: NEW_SECTIONS_LOAD_LOAD_SUCCESS, payload: sections });
export const loadSectionsProductsSuccess = () => ({ type: NEW_SECTIONS_PRODUCTS_LOAD_SUCCESS });
export const loadSectionsError = error => ({
  type: NEW_SECTIONS_LOAD_ERROR,
  payload: error,
  error: true,
});

export const loadProductsToSectionRequest = (key) => ({ type: LOAD_SECTION_PRODUCTS_REQUEST, payload: { key } });
export const loadProductsToSectionSuccess = (key, products) => ({
  type: LOAD_SECTION_PRODUCTS_SUCCESS,
  payload: { key, products },
});
export const loadProductsToSectionError = (key, error) => ({
  type: LOAD_SECTION_PRODUCTS_ERROR,
  payload: { key, errorData: error },
  error: true,
});

// ================ Thunks ================ //
export const loadSections = () => (dispatch/*, getState, sdk*/) => {
  dispatch(loadSectionsRequest());

  return getCategorySections()
    .then(async (response) => {
      const items = (response?.data || []).filter((x) => (x.visible));
      dispatch(loadSectionsSuccess(items));
      for (let i = 0; i < items.length; i++) {
        const section = items[i];
        // Await added to not disable pre-loader before all sections will get their products data.
        await dispatch(loadSection(section));
      }
      dispatch(loadSectionsProductsSuccess());
      return response;
    })
    .catch((e) => {
      return dispatch(loadSectionsError(e));
    });
};

const loadSection = (section) => (dispatch, getState, sdk) => {
  if (!section.brandId) {
    return;
  }
  dispatch(loadProductsToSectionRequest(section.key));

  const filter = { pub_dataType: "product", pub_brandId: section.brandId };

  return sdk.listings
    .query(filter)
    .then((response) => {
      dispatch(loadProductsToSectionSuccess(section.key, response?.data?.data));
      return response;
    })
    .catch(e => {
      return dispatch(loadProductsToSectionError(section.key, e));
    });
};
