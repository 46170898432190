import React, { Component } from 'react';

// Approach from https://support.trustpilot.com/hc/en-us/articles/115011421468--Add-a-TrustBox-widget-to-a-single-page-application
//

const TrustBox = ({ trustBoxRef, theme, locale }) => (
    <div
        ref={trustBoxRef} // We need a reference to this element to load the TrustBox in componentDidMount.
        className="trustpilot-widget"
        data-locale={locale}
        data-template-id="5419b6a8b0d04a076446a9ad"
        data-businessunit-id="642b2db9a46fd1147094890a"
        data-style-height="40px"
        data-style-width="100%"
        data-theme={theme}
        data-min-review-count="10"
        data-without-reviews-preferred-string-id="1"
        data-style-alignment="center"
    >
        <a
            href="https://uk.trustpilot.com/review/vegshelf.com"
            target="_blank"
            rel="noopener noreferrer"
        >
        </a>
    </div>
);

class TrustBoxContainer extends Component {
    constructor(props) {
        super(props);
        this.trustBoxRef = React.createRef();
    }
    componentDidMount() {
        // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
        // If it's not, it means the script you pasted into <head /> isn't loaded just yet.
        // When it is, it will automatically load the TrustBox.
        if (window.Trustpilot) {
            window.Trustpilot.loadFromElement(this.trustBoxRef.current, true);
        }
    }
    render() {
        const { theme, locale } = this.props;
        return <TrustBox trustBoxRef={this.trustBoxRef} theme={theme} locale={locale} />;
    }
}

export default TrustBoxContainer;