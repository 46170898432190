import React, { Component } from 'react';
import { bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter,Redirect } from 'react-router-dom';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { propTypes } from '../../util/types';
import config from '../../config';
import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
} from '../../components';
import { TopbarContainer } from '../../containers';

import facebookImage from '../../assets/VegshelfFacebook-1200x630.png';
import twitterImage from '../../assets/VegshelfTwitter-600x314.png';
import css from './CheckClientIdPage.module.css';
import {checkAccess} from './CheckClientIdPage.duck'

export class CheckClientIdPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { stepIndex: 0 };
    this.onCheckClick = this.onCheckClick.bind(this);
  }
  onCheckClick(){
    this.props.onCheckAccess({accessKey : this.accessKeyInput.value});
  }
  render(){
    const {
      history,
      intl,
      location,
      onBuyerRegistration,
      scrollingDisabled,
      canCheckAccess, hasAccess, ValidationError,
      params
    } = this.props;
    
    if(ValidationError === false){
      return <Redirect to={(params?.returnPath ? decodeURIComponent(params.returnPath) : '/')} />
    }
    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage({ id: 'CheckClientIdPage.schemaTitle' }, { siteTitle });
    const schemaDescription = intl.formatMessage({ id: 'CheckClientIdPage.schemaDescription' });
    const schemaImage = `${config.canonicalRootURL}${facebookImage}`;
  
    return (
      <Page
        className={css.root}
        scrollingDisabled={scrollingDisabled}
        contentType="website"
        description={schemaDescription}
        title={schemaTitle}
        facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
        twitterImages={[
          { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
        ]}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'WebPage',
          description: schemaDescription,
          name: schemaTitle,
          image: [schemaImage],
        }}
      >
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer onlyLogo="true" />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div className={css.pageContainer}>
             <div className={css.clientForm}>
                <label htmlFor="clientIdInput">Access key:</label>
                <div className={css.filed}>
                  <input type="text" id="clientIdInput" name="clientIdInput" 
                ref={(ref) => this.accessKeyInput = ref}/>
                </div>
                <button className={css.checkButton} onClick={()=>{this.onCheckClick();}}>Check access</button>
                {ValidationError === true ? (<div className={css.invalidAccessKey}>Wrong access key!</div>):null}
             </div>
            </div>
          </LayoutWrapperMain>
        </LayoutSingleColumn>
      </Page>
    );
  }
};

CheckClientIdPageComponent.defaultProps = {
  currentUserListing: null,
  currentUserListingFetched: false,
};

CheckClientIdPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};
const mapStateToProps = state => {
  const { canCheckAccess, hasAccess, ValidationError } = state.CheckClientIdPage; 

  return {
    scrollingDisabled: isScrollingDisabled(state),
    canCheckAccess, hasAccess, ValidationError
  };
};

const mapDispatchToProps = dispatch => ({
  onCheckAccess: values => dispatch(checkAccess(values)),
});

const CheckClientIdPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(CheckClientIdPageComponent);

export default CheckClientIdPage;
