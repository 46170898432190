import { getCategorySections, saveCategorySections } from '../../util/api'

// ================ Action types ================ //
export const CATEGORY_SECTIONS_LOAD_REQUEST = 'app/category/sections/request';
export const CATEGORY_SECTIONS_LOAD_SUCCESS = 'app/category/sections/success';
export const CATEGORY_SECTIONS_LOAD_ERROR = 'app/category/sections/error';

export const CATEGORY_SECTIONS_SAVE_REQUEST = 'app/category/sections/save/request';
export const CATEGORY_SECTIONS_SAVE_SUCCESS = 'app/category/sections/save/success';
export const CATEGORY_SECTIONS_SAVE_ERROR = 'app/category/sections/save/error';

export const SUPPLIERS_LOAD_REQUEST = 'app/suppliers/list/load/request';
export const SUPPLIERS_LOAD_SUCCESS = 'app/suppliers/list/load/success';
export const SUPPLIERS_LOAD_ERROR = 'app/suppliers/list/load/error';

export const CATEGORY_SECTIONS_ADD = 'app/category/sections/add';
export const CATEGORY_SECTIONS_DELETE = 'app/category/sections/delete';
export const CATEGORY_SECTIONS_UPDATE = 'app/category/sections/update';

// ================ Reducer ================ //
const initialState = {
  categorySections: null,
  categorySectionsInProgress: false,
  categorySectionsError: null,
  saveCategorySectionsInProgress: false,
  saveCategorySectionsError: null,
  suppliers: null,
  suppliersInProgress: false,
  suppliersError: null,
  hasChanges: false
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SUPPLIERS_LOAD_REQUEST:
      return { ...state, suppliers: null, suppliersInProgress: true, suppliersError: null };
    case SUPPLIERS_LOAD_SUCCESS:
      return { ...state, suppliersInProgress: false, suppliers: payload };
    case SUPPLIERS_LOAD_ERROR:
      return { ...state, suppliersInProgress: false, suppliersError: payload };
    case CATEGORY_SECTIONS_LOAD_REQUEST:
      return {
        ...state,
        categorySections: null,
        categorySectionsInProgress: true,
        categorySectionsError: null,
        hasChanges: false,
      };
    case CATEGORY_SECTIONS_LOAD_SUCCESS:
      return { ...state, categorySectionsInProgress: false, categorySections: payload };
    case CATEGORY_SECTIONS_LOAD_ERROR:
      return { ...state, categorySectionsInProgress: false, categorySectionsError: payload };
    case CATEGORY_SECTIONS_SAVE_REQUEST:
      return { ...state, saveCategorySectionsInProgress: true, saveCategorySectionsError: null };
    case CATEGORY_SECTIONS_SAVE_SUCCESS:
      return { ...state, saveCategorySectionsInProgress: false, hasChanges: false };
    case CATEGORY_SECTIONS_SAVE_ERROR:
      return { ...state, saveCategorySectionsInProgress: false, saveCategorySectionsError: payload };
    case CATEGORY_SECTIONS_ADD:
      return { ...state, hasChanges: true, categorySections: [payload, ...(state.categorySections || [])] };
    case CATEGORY_SECTIONS_DELETE:
      return {
        ...state,
        hasChanges: true,
        categorySections: state.categorySections.filter(x => x.key !== payload.key)
      };
    case CATEGORY_SECTIONS_UPDATE:
      return {
        ...state,
        hasChanges: true,
        categorySections: state.categorySections.map(x => {
          if (x.key !== payload.key) {
            return x;
          } else {
            return payload;
          }
        })
      };
    default:
      return state;
  }
}

// ================ Action creators ================ //
export const categorySectionLoadRequest = () => ({ type: CATEGORY_SECTIONS_LOAD_REQUEST });
export const categorySectionLoadSuccess = (categories) => ({
  type: CATEGORY_SECTIONS_LOAD_SUCCESS,
  payload: categories
});
export const categorySectionLoadError = (error) => ({
  type: CATEGORY_SECTIONS_LOAD_ERROR,
  payload: error,
  error: true,
});

export const categorySectionAdd = (category) => ({ type: CATEGORY_SECTIONS_ADD, payload: category });
export const categorySectionDelete = (category) => ({ type: CATEGORY_SECTIONS_DELETE, payload: category });
export const categorySectionUpdate = (category) => ({ type: CATEGORY_SECTIONS_UPDATE, payload: category });

export const suppliersLoadRequest = () => ({ type: SUPPLIERS_LOAD_REQUEST });
export const suppliersLoadSuccess = (suppliers) => ({ type: SUPPLIERS_LOAD_SUCCESS, payload: suppliers });
export const suppliersLoadError = (error) => ({ type: SUPPLIERS_LOAD_ERROR, payload: error, error: true, });

export const categorySectionSaveRequest = () => ({ type: CATEGORY_SECTIONS_SAVE_REQUEST });
export const categorySectionSaveSuccess = () => ({ type: CATEGORY_SECTIONS_SAVE_SUCCESS });
export const categorySectionSaveError = (error) => ({
  type: CATEGORY_SECTIONS_SAVE_ERROR,
  payload: error,
  error: true,
});

// ================ Thunks ================ //
export const categorySectionLoad = () => (dispatch) => {
  dispatch(categorySectionLoadRequest());
  return getCategorySections()
    .then((response) => {
      dispatch(categorySectionLoadSuccess(response?.data));
      return response;
    })
    .catch((e) => {
      return dispatch(categorySectionLoadError(e));
    });
};

export const categorySectionSave = () => (dispatch, getState) => {
  dispatch(categorySectionSaveRequest());

  return saveCategorySections(getState().CategorySectionPage.categorySections)
    .then(() => {
      return dispatch(categorySectionSaveSuccess());
    })
    .catch((e) => {
      return dispatch(categorySectionSaveError(e));
    });
};

export const suppliersLoad = () => (dispatch, getState, sdk) => {
  dispatch(suppliersLoadRequest());
  return sdk.listings
    .query({ pub_dataType: 'company', include: ['author'] })
    .then((res) => {
      return dispatch(suppliersLoadSuccess(res.data.data));
    })
    .catch((e) => {
      return dispatch(suppliersLoadError(e));
    });
};
