import { types as sdkTypes } from '../util/sdkLoader';
import { sendNewMessageNotification } from './notification.duck';
import { storedLocale, localePart } from "../components/Wrapper/ClassWrapper";

const { UUID } = sdkTypes;

// ================ Action types ================ //
export const MESSAGES_LOAD_CONTACTS_REQUEST = 'app/transaction/messages/contacts/request';
export const MESSAGES_LOAD_CONTACTS_SUCCESS = 'app/transaction/messages/contacts/success';
export const MESSAGES_LOAD_CONTACTS_ERROR = 'app/transaction/messages/contacts/error';
export const MESSAGE_SEND_REQUEST = 'app/transaction/messages/send/request';
export const MESSAGE_SEND_SUCCESS = 'app/transaction/messages/send/success';
export const MESSAGE_SEND_ERROR = 'app/transaction/messages/send/error';
export const MESSAGES_LOAD_ITEMS_REQUEST = 'app/transaction/messages/items/request';
export const MESSAGES_LOAD_ITEMS_SUCCESS = 'app/transaction/messages/items/success';
export const MESSAGES_LOAD_ITEMS_ERROR = 'app/transaction/messages/items/error';

// ================ Reducer ================ //
const initialState = {
  viewMessageType: null,
  messageContacts: null,
  messageContactsInProgress: true,
  messageContactsError: null,
  activeContact: null,
  activeContactMessages: null,
  activeContactMessagesInProgress: false,
  activeContactMessagesError: null,
  sendingMessage: false,
  sendingWithError: null
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case MESSAGES_LOAD_CONTACTS_REQUEST:
      return {
        ...state,
        messageContactsError: null,
        messageContactsInProgress: true,
        messageContacts: null,
        viewMessageType: payload.type
      };
    case MESSAGES_LOAD_CONTACTS_SUCCESS:
      return { ...state, messageContactsInProgress: false, messageContacts: payload };
    case MESSAGES_LOAD_CONTACTS_ERROR:
      return { ...state, messageContactsInProgress: false, messageContactsError: payload };
    case MESSAGE_SEND_REQUEST:
      return { ...state, sendingMessage: true, sendingWithError: null };
    case MESSAGE_SEND_SUCCESS:
      return { ...state, sendingMessage: false };
    case MESSAGE_SEND_ERROR:
      return { ...state, sendingMessage: false, sendingWithError: payload };
    case MESSAGES_LOAD_ITEMS_REQUEST:
      return {
        ...state,
        activeContactMessages: null,
        activeContactMessagesInProgress: true,
        activeContactMessagesError: null,
        activeContact: payload.contact
      };
    case MESSAGES_LOAD_ITEMS_SUCCESS:
      return { ...state, activeContactMessagesInProgress: false, activeContactMessages: payload };
    case MESSAGES_LOAD_ITEMS_ERROR:
      return { ...state, activeContactMessagesInProgress: false, activeContactMessagesError: payload };
    default:
      return state;
  }
}

// ================ Action creators ================ //
export const loadMessageContactsRequest = (idViewer, type) => ({
  type: MESSAGES_LOAD_CONTACTS_REQUEST,
  payload: { idViewer, type: type }
});
export const loadMessageContactsSuccess = (contacts) => ({ type: MESSAGES_LOAD_CONTACTS_SUCCESS, payload: contacts });
export const loadMessageContactsError = (error) => ({
  type: MESSAGES_LOAD_CONTACTS_ERROR,
  payload: error,
  error: true,
});

export const sendMessageRequest = () => ({ type: MESSAGE_SEND_REQUEST });
export const sendMessageSuccess = () => ({ type: MESSAGE_SEND_SUCCESS });
export const sendMessageError = (error) => ({ type: MESSAGE_SEND_ERROR, payload: error, error: true });

export const loadMessageRequest = (contact) => ({ type: MESSAGES_LOAD_ITEMS_REQUEST, payload: { contact: contact } });
export const loadMessageSuccess = (messages) => ({ type: MESSAGES_LOAD_ITEMS_SUCCESS, payload: messages });
export const loadMessageError = (error) => ({ type: MESSAGES_LOAD_ITEMS_ERROR, payload: error, error: true });

// ================ Thunks ================ //
export const loadContacts = (params) => async (dispatch, getState, sdk) => {
  const {
    idViewer,
    createMessageData,
    type
  } = params;
  dispatch(loadMessageContactsRequest(idViewer, type));
  const currentCompany = (await sdk.listings.show({ id: idViewer }))?.data?.data;
  const newContactData = await initContactData(sdk, currentCompany, createMessageData);
  return sdk.transactions.query({
    lastTransitions: ["transition/message", "transition/update-message-transaction", 'transition/expire-updated-transaction',
      'transition/update-message-transaction-customer', 'transition/expire-updated-transaction-customer']
  }).then(response => {
      let allMessages = (response?.data?.data || []).map((t) => (transactionToContactItem(currentCompany?.id?.uuid, t)));
      allMessages = fillContactsList(allMessages, currentCompany, newContactData);
      const selectedItem = allMessages[0];
      if (selectedItem && createMessageData) {
        // selectedItem.newMessageData = JSON.parse(createSubjectMessage('new', createMessageData.subject).content);
      }
      dispatch(loadMessageContactsSuccess(allMessages));
      dispatch(loadMessages({ contact: selectedItem }));

      return response;
    })
    .catch(e => {
      return dispatch(loadMessageContactsError(e));
    });
};
export const loadMessages = (params) => async (dispatch, getState, sdk) => {
  const { contact } = params;
  if (!contact) {
    dispatch(loadMessageSuccess([]));
    return;
  }
  const activeCompany = (await sdk.listings.show({ id: contact.receiverId }))?.data?.data;
  const companyDetails = activeCompany?.attributes?.publicData;
  contact.title = activeCompany?.attributes?.title;
  contact.city = companyDetails?.city;
  contact.country = companyDetails?.country;
  contact.logoUrl = companyDetails?.logoUrl;
  contact.typeOfBusiness = companyDetails?.typeOfBusiness;
  dispatch(loadMessageRequest(contact));
  if (contact.id == 'new') {
    dispatch(loadMessageSuccess(parseGroups([contact.newMessageData])));
    return;
  }

  return sdk.messages.query({
    transactionId: new UUID(contact.id)
  }).then(res => {
      let messages = res.data.data.map((x) => (JSON.parse(x.attributes.content)));
      if (contact.newMessageData && contact.newMessageData.content !== 'Re') {
        messages.splice(0, 0, contact.newMessageData);
      }
      dispatch(loadMessageSuccess(parseGroups(messages)));
    })
    .catch(e => {
      return dispatch(loadMessageError(e));
    });
};
export const sendMessage = (params) => async (dispatch, getState, sdk) => {
  const { message } = params;
  const currentContact = getState().messageStore.activeContact;
  const viewMessageType = getState().messageStore.viewMessageType;
  const messageContacts = getState().messageStore.messageContacts;
  const locale = storedLocale() || localePart();

  if (!currentContact) {
    return;
  }
  const messageItem = createTextMessage(currentContact.id, message, currentContact.viewerUserTitle);
  let transaction = createTransactionModel("transition/message", currentContact.sender, currentContact.receiver,
    JSON.parse(messageItem.content), currentContact.receiverId);
  transaction.processAlias = "vegshelf-message/release-1";
  dispatch(sendMessageRequest());
  currentContact.lastMessageType = 'message';
  currentContact.lastMessageContent = message;
  currentContact.lastMessageAuthor = currentContact.viewerUserTitle;
  currentContact.lastMessageDate = new Date();
  if (currentContact.id == 'new') {
    transaction.params.listingId = new UUID(currentContact.receiverId);
    let res = await sdk.transactions.initiate(transaction, { expand: true });
    messageItem.transactionId = res.data.data.id;
    currentContact.id = res.data.data.id.uuid;
    await sdk.messages.send(messageItem, { expand: true });
    currentContact.newMessageData = null;
    dispatch(sendNewMessageNotification(currentContact.senderId, currentContact.receiverId, currentContact.viewerUserTitle, message, locale));
    return dispatch(loadMessages({ contact: { ...currentContact } }));
  }

  // TODO: Very weird behavior. Works differently for different accounts.
  const transition = viewMessageType === 'buyer'
    ? 'transition/update-message-transaction-customer' : 'transition/update-message-transaction';

  if (currentContact.newMessageData) {
    await sdk.messages.send(messageItem, { expand: true });
    currentContact.newMessageData = null;
    await sdk.transactions.transition({
      id: new UUID(currentContact.id),
      transition: transition,
      params: {
        protectedData: { lastMessage: JSON.parse(messageItem.content) }
      }
    });
    dispatch(sendNewMessageNotification(currentContact.senderId, currentContact.receiverId, currentContact.viewerUserTitle, message, locale));
    return dispatch(loadMessages({ contact: { ...currentContact } }));
  }

  await sdk.messages.send(messageItem, { expand: true });
  await sdk.transactions.transition({
    id: new UUID(currentContact.id),
    transition: transition,
    params: {
      protectedData: { lastMessage: JSON.parse(messageItem.content) }
    }
  });
  dispatch(sendNewMessageNotification(currentContact.senderId, currentContact.receiverId, currentContact.viewerUserTitle, message, locale));
  return dispatch(loadMessages({ contact: { ...currentContact } }));
};

const fillContactsList = (allMessages, currentCompany, newContactData) => {
  allMessages = allMessages || [];
  allMessages.sort((fm, sm) => {
    if (fm.lastMessageDate > sm.lastMessageDate) return -1;
    if (fm.lastMessageDate < sm.lastMessageDate) return 1;
    return 0;
  });
  if (!newContactData) {
    return allMessages;
  }
  const formatedNewItem = transactionToContactItem(currentCompany?.id?.uuid, newContactData);
  const existsItems = allMessages.filter(t => {
    return t.senderId == formatedNewItem.senderId && t.receiverId == formatedNewItem.receiverId;
  });
  if (existsItems.length < 1) {
    allMessages.splice(0, 0, formatedNewItem);
  } else {
    allMessages = [...existsItems, ...allMessages.filter(x => (existsItems.indexOf(x) < 0))];
  }
  return allMessages;
};

const initContactData = async (sdk, currentCompany, createMessageData) => {
  if (createMessageData == null) {
    return null;
  }
  const receiverCompany = (await sdk.listings.show({ id: createMessageData.receiverId }))?.data?.data;
  return {
    attributes: {
      protectedData: {
        sender: companyToContact(currentCompany),
        receiver: companyToContact(receiverCompany),
        lastMessage: null, //JSON.parse(createSubjectMessage('new', createMessageData.subject).content)
      }
    }
  };
};

const transactionToContactItem = (companyViewerId, transaction) => {
  const id = transaction?.id?.uuid || 'new';
  const details = transaction?.attributes?.protectedData || {};
  const viewerUser = details?.sender?.id == companyViewerId ? details?.sender || {} : details.receiver || {};
  const displayedUser = details?.sender?.id != companyViewerId ? details?.sender || {} : details.receiver || {};
  const lastMessage = details?.lastMessage;
  return {
    id,
    title: displayedUser?.title,
    subTitle: displayedUser?.subTitle,
    city: displayedUser?.city,
    country: displayedUser?.country,
    logoUrl: displayedUser?.logoUrl,
    currentLogoUrl: viewerUser?.logoUrl,
    lastMessageType: lastMessage?.type,
    lastMessageContent: lastMessage?.content,
    lastMessageAuthor: lastMessage?.author,
    lastMessageDate: lastMessage?.date ? new Date(Date.parse(lastMessage?.date)) : new Date(),
    createdContactDate: transaction?.createdAt || new Date(),
    sender: details?.sender,
    receiver: details?.receiver,
    senderId: viewerUser?.id,
    viewerUserTitle: viewerUser?.title,
    receiverId: displayedUser?.id,
    typeOfBusiness: displayedUser?.displayedUser,
  };
};

const companyToContact = (currentCompany) => {
  const companyDetails = currentCompany?.attributes?.publicData;
  return {
    id: currentCompany?.id?.uuid,
    title: currentCompany?.attributes?.title,
    city: companyDetails?.city,
    country: companyDetails?.country,
    logoUrl: companyDetails?.logoUrl,
    typeOfBusiness: companyDetails?.typeOfBusiness,
  };
};

const createTransactionModel = (transitionType, sender, receiver, message, productId) => {
  return {
    transition: transitionType,
    params: {
      protectedData: {
        sender: sender,
        receiver: receiver,
        lastMessage: message,
        productId: productId
      }
    }
  }
};

const toMessage = (id, content) => {
  return {
    transactionId: new UUID(id),
    content: JSON.stringify(content)
  };
};

const createSubjectMessage = (id, content) => {
  return {
    transactionId: new UUID(id),
    content: JSON.stringify({ type: 'subject', content: content, date: (new Date()).toISOString() })
  };
};

const createTextMessage = (id, content, author) => {
  return {
    transactionId: id ? new UUID(id) : null,
    content: JSON.stringify({ type: 'message', content: content, author: author, date: (new Date()).toISOString() })
  };
};

function parseGroups(messages, newMessage) {
  let items = [];
  if (!messages || messages.length <= 0) {
    if (newMessage) {
      messages = [newMessage];
    } else {
      return items;
    }
  }
  messages.sort((f, s) => {
    const fDate = new Date(Date.parse(f.date));
    const sDate = new Date(Date.parse(s.date));
    if (fDate < sDate) return -1;
    if (fDate > sDate) return 0;
    return 0
  });
  if (!messages[0]) {
    return items;
  }
  let groupDate = new Date(Date.parse(messages[0].date));
  groupDate.setHours(0, 0, 0, 0);
  items.push({ type: 'group', content: null, date: groupDate });
  for (let i = 0; i < messages.length; i++) {
    const msg = messages[i];
    if (msg.type === 'subject') {
      continue;
    }
    const msgDate = new Date(Date.parse(msg.date));
    msgDate.setHours(0, 0, 0, 0);
    if (msgDate.valueOf() != groupDate.valueOf()) {
      groupDate = msgDate;
      items.push({ type: 'group', content: null, date: groupDate });
    }

    items.push({ ...msg, date: new Date(Date.parse(msg.date)) });
  }

  return items;
}
