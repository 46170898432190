import classNames from "classnames";
import React, { useState } from "react";
import css from "./Select.module.css";
import chevronDown from '../../assets/chevron-down-000000.svg';

export const Select = ({ items, defaultValue, displaySelectedValue, customOnClick, disabled }) => {
  const [selectedValue, setSelectedValue] = useState(defaultValue);
  const [isOpened, setIsOpened] = useState(false);

  return (
    <div
      tabIndex="0"
      onBlur={e => {
        if (!e.relatedTarget || !e.relatedTarget.classList.contains('content')) {
          setIsOpened(false);
        }
      }}
      className={css.customSelect}
      onClick={() => {
        if (disabled) return;
        setIsOpened((prev) => !prev);
      }}
    >
      <div className={classNames(css.selectHeader, disabled && css.isDisabled)}>
        {selectedValue}
        <img src={chevronDown}></img>
      </div>
      <ul className={classNames(css.list, isOpened && css.isOpened)}>
        {items.map((item) => {
          return (
            <li
              className={css.listItem}
              key={item}
              value={item}
              onClick={(e) => {
                customOnClick && customOnClick(e);
                setSelectedValue(displaySelectedValue(item));
              }}
            >
              {displaySelectedValue(item)}
            </li>
          );
        })}
      </ul>
    </div>
  );
};