import { types as sdkTypes } from "../util/sdkLoader";

const { UUID } = sdkTypes;

// ================ Action types ================ //
export const PRODUCTS_FAVOURITE_SAVE_REQUEST = "app/favourite/product/save";
export const PRODUCTS_FAVOURITE_SAVE_SUCCESS = "app/favourite/product/save/success";
export const PRODUCTS_FAVOURITE_SAVE_ERROR = "app/favourite/product/save/error";
export const PRODUCTS_FAVOURITE_LOAD_REQUEST = "app/favourite/product/load";

// ================ Initial State ================ //
const initialState = {
  products: null,
  productIds: null,
  formattedProducts: null,
  saveProductToFavouritesInProgress: false,
  saveProductToFavouritesError: null,
};

// ================ Reducer ================ //
export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case PRODUCTS_FAVOURITE_SAVE_REQUEST:
      return { ...state, saveProductToFavouritesInProgress: true, saveProductToFavouritesError: null };
    case PRODUCTS_FAVOURITE_SAVE_SUCCESS:
      return { ...state, saveProductToFavouritesInProgress: false };
    case PRODUCTS_FAVOURITE_SAVE_ERROR:
      return { ...state, saveProductToFavouritesInProgress: false, saveProductToFavouritesError: payload };
    case PRODUCTS_FAVOURITE_LOAD_REQUEST:
      return {
        ...state,
        products: payload,
        productIds: payload?.map((x) => (x.id)),
        formattedProducts: payload?.map((x) => (favouriteToProduct(x))),
      };
    default:
      return state;
  }
}

// ================ Action creators ================ //
export const saveProductToFavouritesRequest = () => ({ type: PRODUCTS_FAVOURITE_SAVE_REQUEST });
export const saveProductToFavouritesSuccess = () => ({ type: PRODUCTS_FAVOURITE_SAVE_SUCCESS });
export const saveProductToFavouritesError = (error) => ({
  type: PRODUCTS_FAVOURITE_SAVE_ERROR,
  payload: error,
  error: true,
});

export const loadFavourites = (products) => ({ type: PRODUCTS_FAVOURITE_LOAD_REQUEST, payload: products });

// ================ Thunks ================ //
export const fetchFavourites = (productIds) => async (dispatch, getState, sdk) => {
  /**
   * @description Array of requests for each favorite product.
   * @type {Array<Promise>}
   */
  const promises = productIds.map(({ id }) => {
    return sdk.listings
      .show({ id: new UUID(id) })
      .then((res) => res.data.data);
  });

  const result = await Promise.allSettled(promises);

  dispatch(loadFavourites(
    result.reduce((acc, { value, status }) => {
      if (status === "fulfilled") {
        acc.push(productToFavourite(value));
      }

      return acc;
    }, []),
  ));
};

export const addOrRemoveFavourites = (params) => async (dispatch, getState, sdk) => {
  dispatch(saveProductToFavouritesRequest());
  const { product } = params;

  let currentProducts = [...(getState().Favourites?.products || [])];
  const newProducts = currentProducts.filter((x) => x.id !== product.id.uuid);

  if (newProducts.length === currentProducts.length) {
    newProducts.push(productToFavourite(product));
  }

  const companyItem = { protectedData: { favourites: newProducts } };

  try {
    await sdk.currentUser.updateProfile(companyItem);
    dispatch(loadFavourites(newProducts));
    dispatch(saveProductToFavouritesSuccess());
  } catch (ex) {
    dispatch(saveProductToFavouritesError(ex));
  }
};

// ================ Data parsers ================ //
const productToFavourite = (product) => {

  return {
    id: product.id.uuid,
    url: product.attributes.publicData.mainImageUrl || product.attributes.publicData?.productImages[0]?.url,
    wp: product?.attributes?.publicData?.wholesalePrice,
    sp: product?.attributes?.publicData?.suggestedPrice,
    n: product?.attributes?.title,
    bId: product?.attributes?.publicData?.brandId,
    bn: product?.attributes?.publicData?.brandName,
    s: product?.attributes?.state,
    puc: product?.attributes?.publicData?.packagingUnitCount,
    lb: product?.attributes?.publicData?.lockBuy,
  };
};

const favouriteToProduct = (f) => {

  return {
    id: {
      uuid: f.id,
    },
    attributes: {
      title: f.n,
      state: f.s,
      publicData: {
        mainImageUrl: f.url,
        wholesalePrice: f.wp,
        suggestedPrice: f.sp,
        brandId: f.bId,
        brandName: f.bn,
        packagingUnitCount: f.puc,
        lockBuy: f.lb,
      },
    },
  };
};
