import classNames from "classnames";
import range from "lodash/range";
import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "../../util/reactIntl";
import css from "./PaginationButtons.module.css";

const getPageNumbersArray = (page, totalPages) => {
  // Create array of numbers: [1, 2, 3, 4, ..., totalPages]
  const numbersFrom1ToTotalPages = range(1, totalPages + 1);
  return numbersFrom1ToTotalPages
    .filter((v) => {
      // Filter numbers that are next to current page and pick also first and last page
      // E.g. [1, 4, 5, 6, 9], where current page = 5 and totalPages = 9.
      return v === 1 || Math.abs(v - page) <= 1 || v === totalPages;
    })
    .reduce((newArray, p) => {
      // Create a new array where gaps between consecutive numbers is filled with ellipsis character
      // E.g. [1, '…', 4, 5, 6, '…', 9], where current page = 5 and totalPages = 9.
      const isFirstPageOrNextToCurrentPage = p === 1 || newArray[newArray.length - 1] + 1 === p;
      return isFirstPageOrNextToCurrentPage ? newArray.concat([p]) : newArray.concat(["\u2026", p]);
    }, []);
};

let pgKey = 0;

const paginationGapKey = () => {
  pgKey += 1;
  return pgKey;
};

export class PaginationButtons extends Component {
  constructor(props) {
    super(props);
    this.onPrevPageClick = this.onPrevPageClick.bind(this);
    this.onNextPageClick = this.onNextPageClick.bind(this);
  }

  onPrevPageClick() {
    if (this.props.currentPage <= 1) {
      return;
    }
    this.props.onPageChange(this.props.currentPage - 1);
  }

  onNextPageClick() {
    if (this.props.currentPage >= this.props.totalPages) {
      return;
    }
    this.props.onPageChange(this.props.currentPage + 1);
  }

  render() {
    const { totalPages, currentPage, intl, onPageChange } = this.props;
    const pages = getPageNumbersArray(currentPage || 1, totalPages || 1);
    const prevClasses = classNames(css.pageNavButton, { [css.disabledButton]: currentPage === 1 });
    const nextClasses = classNames(css.pageNavButton, { [css.disabledButton]: currentPage === totalPages });
    const pageNumbersNavLinks = pages.map((v) => {
      const isCurrentPage = v === currentPage;
      const pageClassNames = classNames(css.toPageLink, { [css.currentPage]: isCurrentPage });

      return (typeof v === "number") ? (
        <a
          key={v}
          className={pageClassNames}
          title={intl.formatMessage({ id: "PaginationLinks.toPage" }, { page: v })}
          onClick={() => onPageChange(v)}
        >
          {v}
        </a>
      ) : (
        <span key={`pagination_gap_${paginationGapKey()}`}>{v}</span>
      );
    });

    const isHidePrevButton = this.props.currentPage === 1;
    const isHideNextButton = this.props.currentPage === this.props.totalPages;

    return (
      <div className={css.paginationContainer}>
        <div className={css.prev}>
          {isHidePrevButton ? null :
            <a className={prevClasses} onClick={() => this.onPrevPageClick()}>
              <FormattedMessage id="SearchProductsPage.PreviousButtonLabel" />
            </a>
          }
        </div>
        <div className={css.pageNumbers}>
          <div className={css.pages}>{pageNumbersNavLinks}</div>
        </div>
        <div className={css.next}>
          {isHideNextButton ? null :
            <a className={nextClasses} onClick={() => this.onNextPageClick()}>
              <FormattedMessage id="SearchProductsPage.NextButtonLabel" />
            </a>
          }
        </div>
      </div>
    );
  }
}

PaginationButtons.propTypes = {};

export default injectIntl(PaginationButtons);
