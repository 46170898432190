import { bool, object, string } from "prop-types";
import React from "react";
import { FieldTextInput } from "../../components";
import { LabelWithErrorMsg } from "../../forms/ContactDetailsForm/ContactDetailsForm";
import getCountryCodes from "../../translations/countryCodes";
import { intlShape } from "../../util/reactIntl";
import * as validators from "../../util/validators";
import FieldSelect from "../FieldSelect/FieldSelect";
import css from "./StripePaymentAddress.module.css";

const StripePaymentAddress = (props) => {
  const { className, intl, disabled, form, card } = props;

  // ================ general ================ //
  // Use tha language set in config.locale to get the correct translations of the country names
  const countryCodes = getCountryCodes();
  const pageName = "StripePaymentAddress";
  const handleOnChange = (event) => {
    const value = event.target.value;
    form.change("postal", value);
    card.update({ value: { postalCode: value } });
  };
  const hasError = (field) => form.getState().touched[field] && form.getState().errors.hasOwnProperty(field) || false;
  const getInputFormattedMessage = (name, options) => intl.formatMessage({ id: `${pageName}.${name}` }, options);
  const requiredFieldFormattedMessage = getInputFormattedMessage("requiredField");
  const requiredField = validators.required(requiredFieldFormattedMessage);

  // ================ address ================ //
  const addressInputId = "address";
  const addressLabel = getInputFormattedMessage(`${addressInputId}Label`);
  const addressPlaceholder = getInputFormattedMessage(`${addressInputId}Placeholder`);
  const addressAutoComplete = "billing address-line1";
  const isAddressLine1Error = hasError(addressInputId);
  const addressOnUnmount = () => form.change(addressInputId);

  // ================ additionalInfo ================ //
  const additionalInfoInputId = "additionalInfo";
  const additionalInfoLabel = getInputFormattedMessage(`${additionalInfoInputId}Label`);
  const additionalInfoPlaceholder = getInputFormattedMessage(`${additionalInfoInputId}Placeholder`);
  const additionalInfoAutoComplete = "billing address-line2";
  const isAddressLine2Error = hasError(additionalInfoInputId);
  const additionalInfoOnUnmount = () => form.change(additionalInfoInputId);

  // ================ city ================ //
  const cityInputId = "city";
  const cityLabel = getInputFormattedMessage(`${cityInputId}Label`);
  const cityPlaceholder = getInputFormattedMessage(`${cityInputId}Placeholder`);
  const cityAutoComplete = "billing address-line2";
  const isCityError = hasError(cityInputId);
  const cityOnUnmount = () => form.change(cityInputId);

  // ================ postalCode ================ //
  const postalCodeInputId = "postal";
  const postalCodeLabel = getInputFormattedMessage(`${postalCodeInputId}Label`);
  const postalCodePlaceholder = getInputFormattedMessage(`${postalCodeInputId}Placeholder`);
  const postalCodeAutoComplete = "billing postal-code";
  const isPostalCodeError = hasError(postalCodeInputId);
  const postalCodeOnUnmount = () => form.change(postalCodeInputId);

  // ================ state ================ //
  const stateInputId = "state";
  const stateLabel = getInputFormattedMessage(`${stateInputId}Label`);
  const statePlaceholder = getInputFormattedMessage(`${stateInputId}Placeholder`);
  const stateAutoComplete = "billing address-level1";
  const isStateError = hasError(stateInputId);
  const stateOnUnmount = () => form.change(stateInputId);

  // ================ country ================ //
  const countryInputId = "country";
  const countryLabel = getInputFormattedMessage(`${countryInputId}Label`);
  const countryPlaceholder = getInputFormattedMessage(`${countryInputId}Placeholder`);
  const isCountryError = hasError(countryInputId);

  return (
    <div className={className || css.root}>
      <div className={css.formRow}>
        <div className={css.formField}>
          <LabelWithErrorMsg
            className={css.inputLabelMargin}
            isRequired
            htmlFor={addressInputId}
            labelText={addressLabel}
            errorMsg={requiredFieldFormattedMessage}
            isError={isAddressLine1Error}
          >
            <FieldTextInput
              type="text"
              name={addressInputId}
              id={addressInputId}
              disabled={disabled}
              placeholder={addressPlaceholder}
              autoComplete={addressAutoComplete}
              validate={requiredField}
              onUnmount={addressOnUnmount}
            />
          </LabelWithErrorMsg>
        </div>
        <div className={css.formField}>
          <LabelWithErrorMsg
            className={css.inputLabelMargin}
            isRequired={false}
            htmlFor={additionalInfoInputId}
            labelText={additionalInfoLabel}
            errorMsg={requiredFieldFormattedMessage}
            isError={isAddressLine2Error}
          >
            <FieldTextInput
              type="text"
              name={additionalInfoInputId}
              id={additionalInfoInputId}
              disabled={disabled}
              placeholder={additionalInfoPlaceholder}
              autoComplete={additionalInfoAutoComplete}
              onUnmount={additionalInfoOnUnmount}
            />
          </LabelWithErrorMsg>
        </div>
      </div>
      <div className={css.formRow}>
        <div className={css.formField}>
          <LabelWithErrorMsg
            className={css.inputLabelMargin}
            isRequired
            htmlFor={cityInputId}
            labelText={cityLabel}
            errorMsg={requiredFieldFormattedMessage}
            isError={isCityError}
          >
            <FieldTextInput
              type="text"
              name={cityInputId}
              id={cityInputId}
              disabled={disabled}
              placeholder={cityPlaceholder}
              autoComplete={cityAutoComplete}
              validate={requiredField}
              onUnmount={cityOnUnmount}
            />
          </LabelWithErrorMsg>
        </div>
        <div className={css.formField}>
          <LabelWithErrorMsg
            className={css.inputLabelMargin}
            isRequired
            htmlFor={postalCodeInputId}
            labelText={postalCodeLabel}
            errorMsg={requiredFieldFormattedMessage}
            isError={isPostalCodeError}
          >
            <FieldTextInput
              type="text"
              name={postalCodeInputId}
              id={postalCodeInputId}
              disabled={disabled}
              placeholder={postalCodePlaceholder}
              autoComplete={postalCodeAutoComplete}
              validate={requiredField}
              onUnmount={postalCodeOnUnmount}
              onChange={(event) => handleOnChange(event)}
            />
          </LabelWithErrorMsg>
        </div>
      </div>
      <div className={css.formRow}>
        <div className={css.formField}>
          <LabelWithErrorMsg
            className={css.inputLabelMargin}
            isRequired={false}
            htmlFor={stateInputId}
            labelText={stateLabel}
            errorMsg={requiredFieldFormattedMessage}
            isError={isStateError}
          >
            <FieldTextInput
              type="text"
              name={stateInputId}
              id={stateInputId}
              disabled={disabled}
              placeholder={statePlaceholder}
              autoComplete={stateAutoComplete}
              onUnmount={stateOnUnmount}
            />
          </LabelWithErrorMsg>
        </div>
        <div className={css.formField}>
          <LabelWithErrorMsg
            className={css.inputLabelMargin}
            isRequired
            htmlFor={countryInputId}
            labelText={countryLabel}
            errorMsg={requiredFieldFormattedMessage}
            isError={isCountryError}
          >
            <FieldSelect
              id={countryInputId}
              name={countryInputId}
              disabled={disabled}
              className={css.field}
              validate={requiredField}
            >
              <option disabled value="">{countryPlaceholder}</option>
              {countryCodes.map(({ code, name }) => (
                <option key={code} value={code}>
                  {name}
                </option>
              ))}
            </FieldSelect>
          </LabelWithErrorMsg>
        </div>
      </div>
    </div>
  );
};

StripePaymentAddress.defaultProps = {
  country: null,
  disabled: false,
  fieldId: null,
};

StripePaymentAddress.propTypes = {
  country: string,
  disabled: bool,
  form: object.isRequired,
  fieldId: string,
  // from injectIntl
  intl: intlShape.isRequired,
};

export default StripePaymentAddress;
