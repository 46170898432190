import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import * as validators from '../../util/validators';
import { FieldCheckbox, FieldSelect, FieldTextInput, Form, PrimaryButton } from '../../components';
import { getBuyerBusinessTypes, getBuyerImportants, getBuyerNumberOfStores } from '../../translations/numberOfStores';

import css from './BuyerCompanyForm.module.css';

export class BuyerCompanyFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { promisePreviewLogo: null };
  }

  render() {

    return (
      <FinalForm
        {...this.props}
        render={formRenderProps => {
          const {
            rootClassName,
            className,
            formId,
            handleSubmit,
            inProgress,
            invalid,
            intl,
            idp,
            form,
            values,
            touched,
            submitFailed,
            currentCompany
          } = formRenderProps;
          const numberOfStores = getBuyerNumberOfStores();
          const classes = classNames(rootClassName || css.root, className);
          const submitInProgress = inProgress;
          const submitDisabled = (submitFailed && invalid) || submitInProgress;
          const required = validators.required('*');
          const buyerImportants = getBuyerImportants();
          const businessTypes = getBuyerBusinessTypes();
          const hasError = (field) => {
            return (values[field] === undefined || (values[field] instanceof Array && !values[field].length)) && (submitFailed || touched[field]);
          };

          return (
            <Form className={classes} onSubmit={handleSubmit}>
              <div className={css.formFields}>
                <div className={css.formTwoColumnsPart}>

                  <div className={css.formFirstColumn}>

                    <div className={css.formField}>
                      <label
                        htmlFor={formId ? `${formId}.brandName` : 'brandName'}>
                        <FormattedMessage id="UserFields.storeName" />
                        <span className={css.requiredMarker}>*</span>
                        {hasError('brandName') && (<span className={css.errorMessage}><FormattedMessage
                          id="RequiredField.warningText" /></span>)}
                      </label>
                      <div className={css.fieldInputContainer}>
                        <FieldTextInput
                          type="text"
                          id={formId ? `${formId}.brandName` : 'brandName'}
                          name="brandName"
                          defaultValue={currentCompany?.attributes?.publicData?.brandName}
                          validate={required}
                        />
                      </div>
                    </div>

                    <div className={css.formField}>
                      <label
                        htmlFor={formId ? `${formId}.city` : 'city'}>
                        <FormattedMessage id="UserFields.city" />
                        <span className={css.requiredMarker}>*</span>
                        {hasError('city') && (<span className={css.errorMessage}><FormattedMessage
                          id="RequiredField.warningText" /></span>)}
                      </label>
                      <div className={css.fieldInputContainer}>
                        <FieldTextInput
                          type="text"
                          id={formId ? `${formId}.city` : 'city'}
                          name="city"
                          defaultValue={currentCompany?.attributes?.publicData?.city}
                          validate={required}
                        />
                      </div>
                    </div>

                    <div className={css.formField}>
                      <label
                        htmlFor={formId ? `${formId}.country` : 'country'}>
                        <FormattedMessage id="UserFields.country" />
                      </label>
                      <div className={css.fieldInputContainer}>

                        <FieldTextInput
                          type="text"
                          id={formId ? `${formId}.country` : 'country'}
                          name="country"
                          defaultValue={currentCompany?.attributes?.publicData?.country}
                          readOnly={true}
                        />
                      </div>
                    </div>

                    <div className={css.formField}>
                      <label
                        htmlFor={formId ? `${formId}.establishedIn` : 'establishedIn'}>
                        <FormattedMessage id="UserFields.yearEstablished" />
                        <span className={css.requiredMarker}>*</span>
                        {hasError('establishedIn') && (<span className={css.errorMessage}><FormattedMessage
                          id="RequiredField.warningText" /></span>)}
                      </label>
                      <div className={css.fieldInputContainer}>
                        <FieldTextInput
                          type="text"
                          id={formId ? `${formId}.establishedIn` : 'establishedIn'}
                          name="establishedIn"
                          defaultValue={currentCompany?.attributes?.publicData?.establishedIn}
                          validate={required}
                        />
                      </div>
                    </div>

                  </div>
                  <div className={css.formSecondColumn}>

                    <div className={css.formField}>
                      <label
                        htmlFor={formId ? `${formId}.typeOfBusiness` : 'typeOfBusiness'}>
                        <FormattedMessage id="UserFields.typeOfStore" />
                        <span className={css.requiredMarker}>*</span>
                        {hasError('typeOfBusiness') && (<span className={css.errorMessage}><FormattedMessage
                          id="RequiredField.warningText" /></span>)}
                      </label>
                      <div className={css.fieldInputContainer}>
                        <FieldSelect
                          id={formId ? `${formId}.typeOfBusiness` : 'typeOfBusiness'}
                          name="typeOfBusiness"
                          className={css.field}
                          validate={validators.required('*')}
                          defaultValue={currentCompany?.attributes?.publicData?.typeOfBusiness || businessTypes[0].name}
                        >
                          {businessTypes.map(c => {
                            return (
                              <option key={c.name} value={c.name}>
                                {c.name}
                              </option>
                            );
                          })}
                        </FieldSelect>
                      </div>
                    </div>
                    <div className={css.formField}>
                      <label
                        htmlFor={formId ? `${formId}.numberOfStores` : 'numberOfStores'}>
                        <FormattedMessage id="UserFields.numberOfStores" />
                        <span className={css.requiredMarker}>*</span>
                        {hasError('numberOfStores') && (<span className={css.errorMessage}><FormattedMessage
                          id="RequiredField.warningText" /></span>)}
                      </label>
                      <div className={css.fieldInputContainer}>
                        <FieldSelect
                          id={formId ? `${formId}.numberOfStores` : 'numberOfStores'}
                          name="numberOfStores"
                          className={css.field}
                          validate={validators.required('*')}
                          defaultValue={currentCompany?.attributes?.publicData?.numberOfStores || numberOfStores[0].name}
                        >
                          {numberOfStores.map(c => {
                            return (
                              <option key={c.name} value={c.name}>
                                {c.name}
                              </option>
                            );
                          })}
                        </FieldSelect>
                      </div>
                    </div>

                    <div className={css.formField}>
                      <label
                        htmlFor={formId ? `${formId}.website` : 'website'}>
                        <FormattedMessage id="UserFields.website" />
                        <span className={css.requiredMarker}>*</span>
                        {hasError('website') && (<span className={css.errorMessage}><FormattedMessage
                          id="RequiredField.warningText" /></span>)}
                      </label>
                      <div className={css.fieldInputContainer}>
                        <div className={css.placeholderContainer}>
                          <div className={css.httpPlaceHolder}>https://</div>
                          <FieldTextInput
                            type="text"
                            id={formId ? `${formId}.website` : 'website'}
                            name="website" className={css.websiteInput}
                            defaultValue={currentCompany?.attributes?.publicData?.website}
                            validate={required}
                          />
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
                <div className={css.formSingleColumnPart}>

                  <div className={css.propertiesContainer}>
                    <h4 className={css.propertyLabel}>
                      <FormattedMessage id="BuyerCompanyForm.labelCustomers" />
                      <span className={css.requiredMarker}>*</span>
                      <span className={css.labelDescriptionMaxSize}>
                        <FormattedMessage id="BuyerCompanyForm.labelSelectThatApply" />
                      </span>
                      {hasError('buyerImportants') && (<span className={css.errorMessage}><FormattedMessage id="RequiredField.warningText" /></span>)}
                    </h4>
                    {buyerImportants.map(p => {
                      return (<div key={p.code} className={css.propertyItem}>

                        <FieldCheckbox
                          id={formId ? `${formId}.buyerImportants${p.code}` : `buyerImportants${p.code}`}
                          name="buyerImportants" 
                          value={p.code}
                          defaultValue={currentCompany?.attributes?.publicData?.buyerImportants} />
                        <label
                          htmlFor={formId ? `${formId}.buyerImportants${p.code}` : `buyerImportants${p.code}`}>
                          {p.name}
                        </label>

                      </div>);
                    })}
                  </div>
                </div>

                <div className={css.formSingleColumnPart}>
                  <div className={css.formField}>
                    <label
                      htmlFor={formId ? `${formId}.description` : 'description'}>
                      <FormattedMessage id="CompanyDescription.text" />
                      <span className={css.requiredMarker}>*</span>
                      <span className={css.labelDescriptionMaxSize}><FormattedMessage
                        id="BuyerCompanyForm.labelCompanyDescriptionSize" /></span>
                      {hasError('description') && (
                        <span className={css.errorMessage}><FormattedMessage id="RequiredField.warningText" /></span>)}
                    </label>
                    <div className={css.fieldInputContainer}>
                      <FieldTextInput className={css.descriptionInput}
                        type="textarea"
                        id={formId ? `${formId}.description` : 'description'}
                        name="description"
                        maxLength='850'
                        defaultValue={currentCompany?.attributes?.publicData?.description}
                        validate={required}
                      />
                    </div>
                  </div>
                </div>
                <div className={css.formButtonsPart}>
                  <PrimaryButton
                    type="submit"
                    className={css.submitButton}
                    inProgress={submitInProgress}
                    disabled={submitDisabled}
                  >
                    <FormattedMessage id="BuyerCompanyForm.SaveChangesButton" />
                  </PrimaryButton>
                </div>
              </div>
            </Form>
          );
        }}
      />
    )
  }
}

BuyerCompanyFormComponent.defaultProps = { inProgress: false };

const { bool } = PropTypes;

BuyerCompanyFormComponent.propTypes = {
  inProgress: bool,
  // from injectIntl
  intl: intlShape.isRequired,
};

const BuyerCompanyForm = compose(injectIntl)(BuyerCompanyFormComponent);

BuyerCompanyForm.displayName = 'BuyerCompanyForm';

export default BuyerCompanyForm;