import { bool, object } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import facebookImage from "../../assets/VegshelfFacebook-1200x630.png";
import twitterImage from "../../assets/VegshelfTwitter-600x314.png";
import {
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Page,
} from "../../components";
import config from "../../config";
import { TopbarContainer } from "../../containers";
import { isScrollingDisabled } from "../../ducks/UI.duck";
import { ConfirmRegistrationForm } from "../../forms";
import { FormattedMessage, injectIntl, intlShape } from "../../util/reactIntl";
import { propTypes } from "../../util/types";
import { loadRequest, registration } from "./RegistrationCompletePage.duck";
import { localePart, storedLocale } from "../../components/Wrapper/ClassWrapper";
import css from "./RegistrationCompletePage.module.css";

const locale = storedLocale() || localePart();

export class RegistrationCompletePageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { submitted: false };
  }

  render() {
    const {
      intl,
      registrationRequest,
      onRegistration,
      scrollingDisabled,
      registrationRequestLoadInProgress,
      registrationInProgress,
      registered,
      isAuthenticated,
      signupError,
    } = this.props;

    if (registered === true && this.state.submitted === true && !signupError && isAuthenticated) {
      window.location.href = "/" + locale;
    }
    let errorMessage = signupError?.message;
    if (signupError?.apiErrors?.length > 0) {
      errorMessage = signupError?.apiErrors[0]?.title;
    }
    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage({ id: "RegistrationCompletePage.schemaTitle" }, { siteTitle });
    const schemaDescription = intl.formatMessage({ id: "RegistrationCompletePage.schemaDescription" }, { siteTitle });
    const schemaImage = `${config.canonicalRootURL}${facebookImage}`;

    const onSubmit = (values) => {
      const passwordAndMail = { ...values, email: values.email.toLowerCase() };
      onRegistration(passwordAndMail);
      this.setState({ ...this.state, submitted: true });
    };

    return (
      <Page
        className={css.root}
        scrollingDisabled={scrollingDisabled}
        contentType="website"
        description={schemaDescription}
        title={schemaTitle}
        robots='noindex'
        facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
        twitterImages={[
          { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
        ]}
        schema={{
          "@context": "http://schema.org",
          "@type": "WebPage",
          description: schemaDescription,
          name: schemaTitle,
          image: [schemaImage],
        }}
      >
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer onlyLogo="true" />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain className={css.layoutWrapperMain}>
            <div className={css.formContainer}>
              <div className={css.formPanel}>
                {registrationRequest ? (
                  <>
                    <h1 className={css.formTitle}>
                      <FormattedMessage
                        id={registrationRequest.type === "buyer" ?
                          ("BuyerRegistrationFormPage.step2FormTitle")
                          :
                          ("SupplierRegistrationFormPage.step2FormTitle")
                        }
                      />
                    </h1>
                    <p className={css.formDescription}>
                      <FormattedMessage
                        id={registrationRequest.type === "buyer" ?
                          ("BuyerRegistrationFormPage.step2FormDescription")
                          :
                          ("SupplierRegistrationFormPage.step2FormDescription")
                        }
                      />
                    </p>
                    <div>
                      <div>
                        <h3 className={css.errorMessage}>
                          {errorMessage ?
                            (<FormattedMessage id="RegistrationCompletePage.accountExists" />)
                            :
                            (<span>&nbsp;</span>)
                          }
                        </h3>
                        <ConfirmRegistrationForm
                          email={registrationRequest?.data?.businessEMail}
                          inProgress={registrationInProgress}
                          onSubmit={onSubmit}
                        />
                      </div>

                    </div>
                  </>
                ) : (
                  <div>
                    <h1 className={css.formTitle}>
                      {registrationRequestLoadInProgress ?
                        (<FormattedMessage id="RegistrationCompletePage.loading" />)
                        :
                        (<FormattedMessage id="RegistrationCompletePage.registrationNotFound" />)
                      }
                    </h1>
                  </div>
                )}
              </div>
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

RegistrationCompletePageComponent.defaultProps = {
  currentUserListing: null,
  currentUserListingFetched: false,
};

RegistrationCompletePageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
  // from withRouter
  history: object.isRequired,
  location: object.isRequired,
  // from injectIntl
  intl: intlShape.isRequired,
};
const mapStateToProps = (state) => {

  return {
    scrollingDisabled: isScrollingDisabled(state),
    ...state.RegistrationCompletePage,
    signupError: state.Auth.signupError,
    isAuthenticated: state.Auth.isAuthenticated,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onRegistration: (values) => dispatch(registration(values)),
});

const RegistrationCompletePage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
)(RegistrationCompletePageComponent);

RegistrationCompletePage.loadData = (args) => (dispatch, /*getState*/) => {
  return dispatch(loadRequest(args.key));
};

export default RegistrationCompletePage;
