import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import * as validators from '../../util/validators';
import { types as sdkTypes } from '../../util/sdkLoader';
import { Form, SecondaryButton, PrimaryButton, FieldTextInput, FieldCheckbox, FieldSelect, NamedLink } from '../../components';
import ProductDictionaries from '../../translations/productDictionaries';

import css from './SupplierAddProductForm.module.css';
import { isEmpty } from 'lodash';

function getTopCoordinate(el) {
  const rect = el.getBoundingClientRect();
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  // Can be removed if not needed
  const additionalMargin = 50;
  return { top: rect.top + scrollTop - additionalMargin }
}

export class SupplierAddProductFormComponent extends Component {

  constructor(props) {
    super(props);
    this.state = { subCategories: null, images: null, weightUnitEnable: undefined, packWeightUnitEnable: undefined, saveDraft: false, firstSubmit: false };
    this.onChangeCategory = this.onChangeCategory.bind(this);
    this.onDeleteImage = this.onDeleteImage.bind(this);
    this.onChangeImage = this.onChangeImage.bind(this);
    this.onAddImage = this.onAddImage.bind(this);
    this.preSubmit = this.preSubmit.bind(this);
    this.onChangeWeightUnit = this.onChangeWeightUnit.bind(this);
    this.onChangePackWeightUnit = this.onChangePackWeightUnit.bind(this);
    this.onChangeDraftRequiredField = this.onChangeDraftRequiredField.bind(this);
    this.dictionaries = ProductDictionaries();
    this.productImageSelector = {};
    this.newImageId = -10;
  }
  onChangeWeightUnit(event) {
    this.setState({ ... this.state, weightUnitEnable: ['Other', 'Andere'].includes(event) });
  }
  onChangePackWeightUnit(event) {
    this.setState({ ... this.state, packWeightUnitEnable: event == 'put5' });
  }
  onChangeDraftRequiredField(value) {
    this.setState({ ...this.state, saveDraft: !!value });
  }
  onChangeCategory(selectedValue) {
    const selectedItem = this.dictionaries.categories.filter(x => (selectedValue == x.categoryCode))[0];
    this.setState({ ... this.state, subCategories: selectedItem.subCategories });
  }
  onDeleteImage(id, isMainImage) {
    let productImages = [...(this.state.images || this.props.currentListing?.productImages)];
    const image = productImages.filter(img => img.id == id)[0];
    if (!image.isEmpty) {
      image.deleted = true;
    } else {
      productImages = productImages.filter(img => img.id != id);
    }
    if (isMainImage && productImages.length > 0) {
      productImages[0].isMain = true;
    }
    this.setState({ images: productImages });
  }
  async onChangeImage(event, id, isMainImage) {
    event.stopPropagation();
    event.preventDefault();
    var file = event.target.files[0];
    if (!file) {
      return;
    }
    const productImages = [...(this.state.images || this.props.currentListing?.productImages)];
    let image = productImages.filter(img => img.id == id)[0];
    if (!image) {
      image = { id: id };
      productImages.push(image);
    }
    image.isEmpty = false;
    image.isNew = true;
    image.isMain = isMainImage;
    image.file = file;
    image.url = await this.readImage(file);
    this.setState({ ... this.state, images: productImages });
  }
  onAddImage() {
    const productImages = [...(this.state.images || this.props.currentListing?.productImages)];
    productImages.push({ id: this.newImageId--, file: null, url: null, isEmpty: true });
    this.setState({ ... this.state, images: productImages });
  }
  preSubmit(form, mode) {
    this.setState({ currentHandlerType: mode, firstSubmit: true }, () => {
      const productImages = [...(this.state.images || this.props.currentListing?.productImages)];
      form.change("handlerType", mode);
      // Save empty array for correct validation
      if (productImages.every(img => img.deleted)) {
        form.change("productImages", [])
      } else {
        form.change("productImages", productImages.filter(img => { return !img.isEmpty; }))
      }
      const id = this?.props?.currentListing?.id;
      if (id) {
        form.change('id', id);
      }
      setTimeout(() => {
        const labelWithValidationErrorElem = document.getElementsByClassName('labelWithError')[0];

        if (labelWithValidationErrorElem) {
          window.scrollTo({ top: getTopCoordinate(labelWithValidationErrorElem).top, behavior: 'smooth' });
        }
      }, 1)
    })
  }
  render() {
    return (
      <FinalForm
        {...this.props}
        render={formRenderProps => {
          const {
            rootClassName,
            className,
            formId,
            handleSubmit,
            inProgress,
            invalid,
            intl,
            form,
            idp,
            currentListing,
            invalidImages,
            values,
            submitFailed,
            touched,
            disableForm
          } = formRenderProps;
          const {
            categories,
            freeSamples,
            productProperties,
            requiredTemperatures,
            leadTimes,
            packUnitTypes,
            unitTypes } = this.dictionaries;
          const classes = classNames(rootClassName || css.root, className);
          const submitInProgress = inProgress;
          const submitDisabled = (submitFailed && invalid) || submitInProgress;
          let defaultSubCategories = categories[0].subCategories;
          if (currentListing.rootCategory) {
            const selectedItem = this.dictionaries.categories.filter(x => (currentListing.rootCategory == x.categoryCode))[0];
            if (selectedItem)
              defaultSubCategories = selectedItem.subCategories;
          }
          const invalidField = (field) => (values[field] === undefined || (values[field] instanceof Array && !values[field].length));
          const invalidNumberField = (field) => {
            return values[field] === '' || values[field] === undefined;
          }
          const subCategories = this.state.subCategories || defaultSubCategories;
          const productImages = (this.state.images || currentListing.productImages).filter(img => { return !img.deleted; });
          const isNew = !!!currentListing.id;
          if (productImages.length < 3) {
            productImages.push({ id: this.newImageId--, file: null, url: null, isEmpty: true, isLast: true });
          }
          const canChangeVAT = isNew || currentListing?.state == 'draft' || currentListing?.state == 'pendingApproval';
          // const invalidMainImages = (!(productImages.length && productImages.filter(img => (!img.isEmpty)).length)) && submitFailed;
          // const mainImage = productImages.find(img => img.isMain)

          const pleaseSelectText = intl.formatMessage({ id: "BuyerRegistrationForm.optionPleaseSelect" });

          const invalidMainImage = () => {
            const mainImage = productImages.find(img => img.isMain);

            if (!this.state.firstSubmit) {
              return;
            }

            if (this.state.firstSubmit && !mainImage) {
              return true;
            } else if (this.state.firstSubmit && mainImage.isEmpty) {
              return true;
            }
          };
          const hasError = field => {
            if (this.state.currentHandlerType === undefined) return false;

            if (this.state.currentHandlerType === 'draft') {
              if (field === 'productName') {
                return invalidField(field) && (submitFailed || touched[field]);
              }
              return false;
            }
            if (field === 'wholesalePrice' || field === 'suggestedPrice' || field === 'vat') {
              return invalidNumberField(field);
            }
            return invalidField(field) && (submitFailed || touched[field]);
          };
          return (
            <Form className={classes} onSubmit={handleSubmit}>
              <div className={css.formFields}>
                <div className={css.formRow}>
                  <div className={css.formField}>
                    <label
                      htmlFor={formId ? `${formId}.rootCategory` : 'rootCategory'}>
                      <FormattedMessage id="SupplierAddProductForm.labelRootCategory" />
                      <span className={css.requiredMarker}>*</span>
                      {hasError('rootCategory') && (<span className={`${css.errorMessage} labelWithError`}><FormattedMessage id="RequiredField.warningText" /></span>)}
                    </label>
                    <div className={css.fieldInputContainer}>
                      <FieldSelect
                        id={formId ? `${formId}.rootCategory` : 'rootCategory'}
                        name="rootCategory"
                        className={css.field}
                        validate={(this.state.currentHandlerType === 'publish' || this.state.currentHandlerType === 'update' || this.state.currentHandlerType === 'pending') && validators.required('*')}
                        onChange={this.onChangeCategory}
                        defaultValue={currentListing.rootCategory}
                      >
                        <option value="" disabled={true}>{pleaseSelectText}</option>
                        {categories.map(c => {
                          return (
                            <option key={c.categoryCode} value={c.categoryCode}>
                              {c.name}
                            </option>
                          );
                        })}
                      </FieldSelect>
                    </div>
                  </div>
                  <div className={css.formField}>
                    <label
                      htmlFor={formId ? `${formId}.subCategory` : 'subCategory'}>
                      <FormattedMessage id="SupplierAddProductForm.labelSubCategory" />
                      <span className={css.requiredMarker}>*</span>
                      {hasError('subCategory') && (<span className={`${css.errorMessage} labelWithError`}><FormattedMessage id="RequiredField.warningText" /></span>)}
                    </label>
                    <div className={css.fieldInputContainer}>
                      <FieldSelect
                        id={formId ? `${formId}.subCategory` : 'subCategory'}
                        name="subCategory"
                        className={css.field}
                        validate={(this.state.currentHandlerType === 'publish' || this.state.currentHandlerType === 'update' || this.state.currentHandlerType === 'pending') && validators.required('*')}
                        defaultValue={currentListing.subCategory}
                      >
                        <option value="" disabled={true}>{pleaseSelectText}</option>
                        {subCategories.map(c => {
                          return (
                            <option key={c.categoryCode} value={c.categoryCode}>
                              {c.name}
                            </option>
                          );
                        })}
                      </FieldSelect>
                    </div>
                  </div>
                </div>
                <div className={css.formRow}>
                  <div className={css.formField}>
                    <label
                      htmlFor={formId ? `${formId}.productName` : 'productName'}>
                      <FormattedMessage id="ProductDetails.name" />
                      <span className={css.requiredMarker}>*</span>
                      {hasError('productName') && (<span className={`${css.errorMessage} labelWithError`}><FormattedMessage id="RequiredField.warningText" /></span>)}
                    </label>
                    <div className={css.fieldInputContainer}>
                      <FieldTextInput
                        type="text"
                        id={formId ? `${formId}.productName` : 'productName'}
                        name="productName"
                        defaultValue={currentListing.productName}
                        validate={validators.required('*')}
                        maxLength='45'
                      />
                    </div>
                  </div>
                  <div className={css.formField}>
                    <label
                      htmlFor={formId ? `${formId}.weight` : 'weight'}>
                      <FormattedMessage id="ProductDetails.netWeight" />
                      <span className={css.requiredMarker}>*</span>
                      {hasError('weight') && (<span className={`${css.errorMessage} labelWithError`}><FormattedMessage id="RequiredField.warningText" /></span>)}
                    </label>
                    <div className={css.fieldInputWithSelect}>
                      <FieldTextInput
                        id={formId ? `${formId}.weight` : 'weight'}
                        name="weight"
                        type="number"
                        defaultValue={currentListing.weight}
                        min='1'
                        validate={(this.state.currentHandlerType === 'publish' || this.state.currentHandlerType === 'update' || this.state.currentHandlerType === 'pending') && validators.required('*')}
                      />
                      <div className={css.unitTypeSelect}>
                        <FieldSelect
                          id={formId ? `${formId}.weightUnit` : 'weightUnit'}
                          name="weightUnit"
                          onChange={this.onChangeWeightUnit}
                          className={css.typeInput}
                          validate={(this.state.currentHandlerType === 'publish' || this.state.currentHandlerType === 'update' || this.state.currentHandlerType === 'pending') && validators.required('*')}
                          defaultValue={currentListing.weightUnit}
                        >
                          <option value="" disabled={true}>{pleaseSelectText}</option>
                          {unitTypes.map(c => {
                            return (
                              <option key={c.name} value={c.name}>
                                {c.name}
                              </option>
                            );
                          })}
                        </FieldSelect>
                        {this.state.weightUnitEnable || (this.state.weightUnitEnable === undefined && ['Other', 'Andere'].includes(currentListing.weightUnit)) ? (
                          <FieldTextInput
                            type="text"
                            id={formId ? `${formId}.weightUnitCustom` : 'weightUnitCustom'}
                            name="weightUnitCustom" className={css.customUnitInput}
                            validate={(this.state.currentHandlerType === 'publish' || this.state.currentHandlerType === 'update' || this.state.currentHandlerType === 'pending') && validators.required('*')}
                            defaultValue={currentListing.weightUnitCustom}
                          />) : null}
                      </div>
                    </div>
                  </div>
                </div>
                <div className={css.formRow}>
                  <div className={css.formField}>
                    <label
                      htmlFor={formId ? `${formId}.wholesalePrice` : 'wholesalePrice'}>
                      <FormattedMessage id="SupplierAddProductForm.labelWholesalePrice" />
                      <span className={css.requiredMarker}>*</span>
                      {hasError('wholesalePrice') && (<span className={`${css.errorMessage} labelWithError`}><FormattedMessage id="RequiredField.warningText" /></span>)}
                    </label>
                    <div className={css.fieldInputContainer}>
                      <FieldTextInput
                        type="number"
                        id={formId ? `${formId}.wholesalePrice` : 'wholesalePrice'}
                        name="wholesalePrice"
                        min="0.01"
                        step="0.01"
                        defaultValue={+currentListing.wholesalePrice === 0 ? '' : currentListing.wholesalePrice}
                        validate={(this.state.currentHandlerType === 'publish' || this.state.currentHandlerType === 'update' || this.state.currentHandlerType === 'pending') && validators.required('*')}
                      />
                      <div className={css.textPlaceHolder}>€</div>
                    </div>
                  </div>
                  <div className={css.formField}>
                    <label
                      htmlFor={formId ? `${formId}.suggestedPrice` : 'suggestedPrice'}>
                      <FormattedMessage id="SupplierAddProductForm.labelSuggestedPrice" />
                      <span className={css.requiredMarker}>*</span>
                      {hasError('suggestedPrice') && (<span className={`${css.errorMessage} labelWithError`}><FormattedMessage id="RequiredField.warningText" /></span>)}
                    </label>
                    <div className={css.fieldInputContainer}>
                      <FieldTextInput
                        type="number"
                        id={formId ? `${formId}.suggestedPrice` : 'suggestedPrice'}
                        name="suggestedPrice"
                        min="0.01"
                        step="0.01"
                        defaultValue={+currentListing.suggestedPrice === 0 ? '' : currentListing.suggestedPrice}
                        validate={(this.state.currentHandlerType === 'publish' || this.state.currentHandlerType === 'update' || this.state.currentHandlerType === 'pending') && validators.required('*')}
                      />
                      <div className={css.textPlaceHolder}>€</div>
                    </div>
                  </div>
                </div>
                <div className={css.formRow}>
                  <div className={css.formField}>
                    <label
                      htmlFor={formId ? `${formId}.packagingUnitCount` : 'packagingUnitCount'}>
                      <FormattedMessage id="SupplierAddProductForm.labelPackagingUnitCount" />
                      <span className={css.requiredMarker}>*</span>
                      {hasError('packagingUnitCount') && (<span className={`${css.errorMessage} labelWithError`}><FormattedMessage id="RequiredField.warningText" /></span>)}
                    </label>
                    <div className={css.fieldInputWithSelect}>
                      <FieldTextInput
                        type="number"
                        id={formId ? `${formId}.packagingUnitCount` : 'packagingUnitCount'}
                        name="packagingUnitCount"
                        min='1'
                        defaultValue={currentListing.packagingUnitCount}
                        validate={(this.state.currentHandlerType === 'publish' || this.state.currentHandlerType === 'update' || this.state.currentHandlerType === 'pending') && validators.required('*')}
                      />
                      <div className={css.unitTypeSelect}>
                        <FieldSelect
                          id={formId ? `${formId}.packWeightUnit` : 'packWeightUnit'}
                          name="packWeightUnit"
                          className={css.typeInput}
                          onChange={this.onChangePackWeightUnit}
                          defaultValue={currentListing.packWeightUnit}
                          validate={(this.state.currentHandlerType === 'publish' || this.state.currentHandlerType === 'update' || this.state.currentHandlerType === 'pending') && validators.required('*')}
                        >
                          <option value="" disabled={true}>{pleaseSelectText}</option>
                          {packUnitTypes.map(c => {
                            return (
                              <option key={c.name} value={c.code}>
                                {c.name}
                              </option>
                            );
                          })}
                        </FieldSelect>
                        {this.state.packWeightUnitEnable || (this.state.packWeightUnitEnable === undefined && currentListing.packWeightUnit === 'put5') ? (
                          <FieldTextInput
                            type="text"
                            id={formId ? `${formId}.packWeightUnitCustom` : 'packWeightUnitCustom'}
                            name="packWeightUnitCustom" className={css.customUnitInput}
                            defaultValue={currentListing.packWeightUnitCustom}
                            validate={(this.state.currentHandlerType === 'publish' || this.state.currentHandlerType === 'update' || this.state.currentHandlerType === 'pending') && validators.required('*')}
                          />) : null}
                      </div>
                    </div>
                  </div>
                  <div className={css.formField}>
                    <label
                      htmlFor={formId ? `${formId}.leadTime` : 'leadTime'}>
                      <FormattedMessage id="SupplierAddProductForm.labelLeadTime" />
                      <span className={css.requiredMarker}>*</span>
                      {hasError('leadTime') && (<span className={`${css.errorMessage} labelWithError`}><FormattedMessage id="RequiredField.warningText" /></span>)}
                    </label>
                    <div className={css.fieldInputContainer}>
                      <FieldSelect
                        id={formId ? `${formId}.leadTime` : 'leadTime'}
                        name="leadTime"
                        className={css.field}
                        validate={(this.state.currentHandlerType === 'publish' || this.state.currentHandlerType === 'update' || this.state.currentHandlerType === 'pending') && validators.required('*')}
                        defaultValue={currentListing.leadTime}
                      >
                        <option value="" disabled={true}>{pleaseSelectText}</option>
                        {leadTimes.map(c => {
                          return (
                            <option key={c.name} value={c.code}>
                              {c.name}
                            </option>
                          );
                        })}
                      </FieldSelect>
                    </div>
                  </div>
                </div>
                <div className={css.propertiesContainer}>
                  <h4 className={css.propertyLabel}><FormattedMessage id="SupplierAddProductForm.labelProperties" />
                    <span className={css.requiredMarker}>*</span>
                    {hasError('properties') && (<span className={`${css.errorMessage} labelWithError`}><FormattedMessage id="RequiredField.warningText" /></span>)}
                  </h4>
                  {productProperties.map(p => {
                    return (<div key={p.code} className={css.propertyItem}>

                      <FieldCheckbox
                        id={formId ? `${formId}.property${p.code}` : `property${p.code}`}
                        validate={(this.state.currentHandlerType === 'publish' || this.state.currentHandlerType === 'update' || this.state.currentHandlerType === 'pending') && validators.required('*')}
                        name="properties"
                        value={p.code}
                        defaultValue={currentListing.properties} />
                      <label
                        htmlFor={formId ? `${formId}.property${p.code}` : `property${p.code}`}>
                        {p.name}
                      </label>

                    </div>);
                  })}
                </div>
                <div className={css.formFieldFullRow}>
                  <label
                    htmlFor={formId ? `${formId}.ingredientList` : 'ingredientList'}>
                    <FormattedMessage id="ProductDetails.ingredients" />
                    <span className={css.requiredMarker}>*</span>
                    {hasError('ingredientList') && (<span className={`${css.errorMessage} labelWithError`}><FormattedMessage id="RequiredField.warningText" /></span>)}
                  </label>
                  <div className={css.fieldInputContainer}>
                    <FieldTextInput
                      type="text"
                      id={formId ? `${formId}.ingredientList` : 'ingredientList'}
                      name="ingredientList"
                      defaultValue={currentListing.ingredientList}
                      validate={(this.state.currentHandlerType === 'publish' || this.state.currentHandlerType === 'update' || this.state.currentHandlerType === 'pending') && validators.required('*')}
                    />
                  </div>
                </div>
                <div className={css.formRow}>
                  <div className={css.formField}>
                    <label
                      htmlFor={formId ? `${formId}.certification` : 'certification'}>
                      <FormattedMessage id="ProductDetails.certification" />
                      <span className={css.requiredMarker}>*</span>
                      {hasError('certification') && (<span className={`${css.errorMessage} labelWithError`}><FormattedMessage id="RequiredField.warningText" /></span>)}
                    </label>
                    <div className={css.fieldInputContainer}>
                      <FieldTextInput
                        type="text"
                        id={formId ? `${formId}.certification` : 'certification'}
                        name="certification"
                        defaultValue={currentListing.certification}
                        validate={(this.state.currentHandlerType === 'publish' || this.state.currentHandlerType === 'update' || this.state.currentHandlerType === 'pending') && validators.required('*')}
                      />
                    </div>
                  </div>
                  <div className={css.formField}>
                    <label
                      htmlFor={formId ? `${formId}.eanOrGtinNumber` : 'eanOrGtinNumber'}>
                      <FormattedMessage id="ProductDetails.gtin" />
                      <span className={css.requiredMarker}>*</span>
                      {hasError('eanOrGtinNumber') && (<span className={`${css.errorMessage} labelWithError`}><FormattedMessage id="RequiredField.warningText" /></span>)}
                    </label>
                    <div className={css.fieldInputContainer}>
                      <FieldTextInput
                        type="text"
                        id={formId ? `${formId}.eanOrGtinNumber` : 'eanOrGtinNumber'}
                        name="eanOrGtinNumber"
                        defaultValue={currentListing.eanOrGtinNumber}
                        validate={(this.state.currentHandlerType === 'publish' || this.state.currentHandlerType === 'update' || this.state.currentHandlerType === 'pending') && validators.required('*')}
                      />
                    </div>
                  </div>
                </div>
                <div className={css.formRow}>
                  <div className={css.formField}>
                    <label
                      htmlFor={formId ? `${formId}.shelfLife` : 'shelfLife'}>
                      <FormattedMessage id="ProductDetails.shelfLife" />
                      <span className={css.requiredMarker}>*</span>
                      {hasError('shelfLife') && (<span className={`${css.errorMessage} labelWithError`}><FormattedMessage id="RequiredField.warningText" /></span>)}
                    </label>
                    <div className={css.fieldInputContainer}>
                      <FieldTextInput
                        type="number"
                        min="0.1"
                        step="0.1"
                        id={formId ? `${formId}.shelfLife` : 'shelfLife'}
                        name="shelfLife"
                        defaultValue={currentListing.shelfLife}
                        validate={(this.state.currentHandlerType === 'publish' || this.state.currentHandlerType === 'update' || this.state.currentHandlerType === 'pending') && validators.required('*')}
                      />
                      <div className={css.textPlaceHolder}><FormattedMessage id="ProductDetails.shelfLifeMonths" /></div>
                    </div>
                  </div>
                  <div className={css.formField}>
                    <label
                      htmlFor={formId ? `${formId}.requiredTemperature` : 'requiredTemperature'}>
                      <FormattedMessage id="ProductDetails.storageTemperature" />
                      <span className={css.requiredMarker}>*</span>
                      {hasError('requiredTemperature') && (<span className={`${css.errorMessage} labelWithError`}><FormattedMessage id="RequiredField.warningText" /></span>)}
                    </label>
                    <div className={css.fieldInputContainer}>
                      <FieldSelect
                        id={formId ? `${formId}.requiredTemperature` : 'requiredTemperature'}
                        name="requiredTemperature"
                        className={css.field}
                        validate={(this.state.currentHandlerType === 'publish' || this.state.currentHandlerType === 'update' || this.state.currentHandlerType === 'pending') && validators.required('*')}
                        defaultValue={currentListing.requiredTemperature}
                      >
                        <option value="" disabled={true}>{pleaseSelectText}</option>
                        {requiredTemperatures.map(c => {
                          return (
                            <option key={c.name} value={c.code}>
                              {c.name}
                            </option>
                          );
                        })}
                      </FieldSelect>
                    </div>
                  </div>
                </div>
                <div className={css.formFieldFullRow}>
                  <label
                    htmlFor={formId ? `${formId}.languageOfProductLabel` : 'languageOfProductLabel'}>
                    <FormattedMessage id="ProductDetails.labelLanguage" />
                    <span className={css.requiredMarker}>*</span>
                    {hasError('languageOfProductLabel') && (<span className={`${css.errorMessage} labelWithError`}><FormattedMessage id="RequiredField.warningText" /></span>)}
                  </label>
                  <div className={css.fieldInputContainer}>
                    <FieldTextInput
                      type="text"
                      id={formId ? `${formId}.languageOfProductLabel` : 'languageOfProductLabel'}
                      name="languageOfProductLabel"
                      defaultValue={currentListing.languageOfProductLabel}
                      validate={(this.state.currentHandlerType === 'publish' || this.state.currentHandlerType === 'update' || this.state.currentHandlerType === 'pending') && validators.required('*')}
                    />
                  </div>
                </div>
                <div className={css.formFieldFullRow}>
                  <label
                    htmlFor={formId ? `${formId}.productDescription` : 'productDescription'}>
                    <FormattedMessage id="ProductDetails.productDescription" />
                    <span className={css.requiredMarker}>*</span>
                    <span className={css.labelDescriptionMaxSize}>
                      <FormattedMessage id="SupplierAddProductForm.labelProductDescriptionSize" />
                    </span>
                    {hasError('productDescription') && (<span className={`${css.errorMessage} labelWithError`}><FormattedMessage id="RequiredField.warningText" /></span>)}
                  </label>
                  <div className={css.fieldInputContainer}>
                    <FieldTextInput
                      type="textarea"
                      id={formId ? `${formId}.productDescription` : 'productDescription'}
                      name="productDescription"
                      maxLength='500'
                      defaultValue={currentListing.productDescription}
                      validate={(this.state.currentHandlerType === 'publish' || this.state.currentHandlerType === 'update' || this.state.currentHandlerType === 'pending') && validators.required('*')}
                    />
                  </div>
                </div>
                <div className={css.formRow}>
                  <div className={css.formField}>
                    <label
                      htmlFor={formId ? `${formId}.freeSamples` : 'freeSamples'}>
                      <FormattedMessage id="SupplierAddProductForm.labelFreeSamplesAvailableUponRequest" />
                      <span className={css.requiredMarker}>*</span>
                      {hasError('freeSamples') && (<span className={`${css.errorMessage} labelWithError`}><FormattedMessage id="RequiredField.warningText" /></span>)}
                    </label>
                    <div className={css.fieldInputContainer}>
                      <FieldSelect
                        id={formId ? `${formId}.freeSamples` : 'freeSamples'}
                        name="freeSamples"
                        className={css.field}
                        validate={(this.state.currentHandlerType === 'publish' || this.state.currentHandlerType === 'update' || this.state.currentHandlerType === 'pending') && validators.required('*')}
                        defaultValue={currentListing.freeSamples}
                      >
                        <option value="" disabled={true}>{pleaseSelectText}</option>
                        {freeSamples.map(c => {
                          return (
                            <option key={c.name} value={c.name}>
                              {c.name}
                            </option>
                          );
                        })}
                      </FieldSelect>
                    </div>
                  </div>
                  <div className={css.formField}>
                    <label
                      htmlFor={formId ? `${formId}.vat` : 'vat'}>
                      <FormattedMessage id="SupplierAddProductForm.labelVAT" />
                      <span className={css.requiredMarker}>*</span>
                      {hasError('vat') && (<span className={`${css.errorMessage} labelWithError`}><FormattedMessage id="RequiredField.warningText" /></span>)}
                    </label>
                    <div className={css.fieldInputContainer}>
                      <FieldTextInput
                        type="number"
                        id={formId ? `${formId}.vat` : 'vat'}
                        name="vat"
                        min="0"
                        step="0.1"
                        defaultValue={+currentListing.vat === 0 ? '' : currentListing.vat} readOnly={!canChangeVAT}
                        validate={(this.state.currentHandlerType === 'publish' || this.state.currentHandlerType === 'update' || this.state.currentHandlerType === 'pending') && validators.required('*')}
                      />
                    </div>
                  </div>
                </div>
                <div className={css.formField}>
                  <label>&nbsp;
                    {hasError('iAgreeToReceive') && (<span className={`${css.errorMessage} labelWithError`}><FormattedMessage id="RequiredField.warningText" /></span>)}
                  </label>
                </div>
                <div className={css.agreeRow}>
                  <FieldCheckbox id={formId ? `${formId}.iAgreeToReceive` : 'iAgreeToReceive'}
                    validate={(this.state.currentHandlerType === 'publish' || this.state.currentHandlerType === 'update' || this.state.currentHandlerType === 'pending') && validators.requiredFieldArrayCheckbox('*')} hasError={hasError('iAgreeToReceive')}
                    name="iAgreeToReceive" value="iAgreeToReceive" />
                  <label
                    htmlFor={formId ? `${formId}.iAgreeToReceive` : 'iAgreeToReceive'}>
                    <span className={css.requiredMarker}>*</span>
                    <FormattedMessage id="BuyerCompanyForm.labelConfirm" />
                  </label>
                </div>
                <div className={css.productImages}>
                  <h4 className={css.imagesLabel}>
                    <FormattedMessage id="SupplierAddProductForm.labelUploadProductPhotos" /><span className={css.requiredMarker}>*</span>
                    {this.state.currentHandlerType !== 'draft' && invalidMainImage() && (<span className={`${css.errorMessage} labelWithError`}><FormattedMessage id="RequiredField.warningText" /></span>)}
                  </h4>
                  <span className={css.labelImageMaxSize}>
                    <FormattedMessage id="SupplierAddProductForm.labelImageDescriptionSize" />
                  </span>
                  <div className={css.imageContainer}>
                    {productImages.map((img, idx) => {
                      let stylePreview = {};
                      const isMainImage = idx === 0;
                      if (img.url) {
                        stylePreview.backgroundImage = `url(${img.url})`;
                      }
                      const rootItemCss = classNames(css.imageItem, { [css.invalidImage]: invalidMainImage() && isMainImage, [css.hasImage]: !img.isEmpty })
                      return (<div key={img.id} className={rootItemCss} style={stylePreview}>
                        <input id="productImageFileSelector"
                          type="file"
                          ref={(ref) => this.productImageSelector[img.id] = ref}
                          style={{ display: 'none' }}
                          onChange={(e) => this.onChangeImage(e, img.id, isMainImage)}
                          name={`image${idx}`}
                          accept="image/*"
                        />
                        {img.isEmpty && (<a className={css.changeImage} onClick={() => { this.productImageSelector[img.id].click(); }}>
                          <div className={css.addImageButtonIcon}>&nbsp;</div>
                          {isMainImage ? (<FormattedMessage id="SupplierAddProductForm.labelSelectMainImage" />) : (img.isLast ? (<FormattedMessage id="SupplierAddProductForm.labelAddMore" />) : <FormattedMessage id="SupplierAddProductForm.labelSelectImage" />)}
                        </a>)}
                        {(!img.isEmpty ? (<a className={css.deleteImage} onClick={() => { this.onDeleteImage(img.id, isMainImage); }}></a>) : null)}
                      </div>);
                    })}
                  </div>
                </div>
              </div>
              <div className={css.bottomWrapper}>
                <NamedLink name="SupplierProfilePage" className={css.cancelButton}>
                  <FormattedMessage id="SupplierAddProductForm.buttonCancel" />
                </NamedLink>
                {/* Save as draft  */}
                {isNew || currentListing?.state == 'draft' ? (<PrimaryButton onClick={() => {
                  this.setState({ currentHandlerType: 'draft' }, () => this.preSubmit(form, "draft"))
                }} spinnerClassName={css.spinner} type="submit" className={css.cancelButton} inProgress={values.handlerType === 'draft' && submitInProgress}>
                  {disableForm && <i className="bi bi-exclamation-circle"></i>} <FormattedMessage id="SupplierAddProductForm.buttonSaveDraft" values={{ idp: idp }} />
                </PrimaryButton>) : null}

                {/* Save changes & Submit for publishing  */}
                {currentListing?.state == 'pendingApproval' ? (<PrimaryButton onClick={() => {
                  this.setState({ currentHandlerType: 'pending' }, () => this.preSubmit(form, "pending"))
                }} type="submit" className={css.submitButton} inProgress={submitInProgress}>
                  {disableForm && <i className="bi bi-exclamation-circle"></i>} <FormattedMessage id="SupplierAddProductForm.buttonPending" values={{ idp: idp }} />
                </PrimaryButton>) : null}

                {/* Save changes  */}
                {!isNew && !canChangeVAT ? (<PrimaryButton onClick={() => {
                  this.setState({ currentHandlerType: 'update' }, () => this.preSubmit(form, "update"))
                }} type="submit" className={css.submitButton} inProgress={submitInProgress}>
                  {disableForm && <i className="bi bi-exclamation-circle"></i>} <FormattedMessage id="SupplierAddProductForm.buttonUpdate" values={{ idp: idp }} />
                </PrimaryButton>) : null}

                {/* Submit for publishing  */}
                {isNew || currentListing?.state == 'draft' ? (<PrimaryButton onClick={() => {
                  this.setState({ currentHandlerType: 'publish' }, () => this.preSubmit(form, "publish"))
                }} type="submit" className={css.submitButton} inProgress={values.handlerType === 'publish' && submitInProgress}>
                  {disableForm && <i className="bi bi-exclamation-circle"></i>} <FormattedMessage id="SupplierAddProductForm.buttonSubmitForPublishing" values={{ idp: idp }} />
                </PrimaryButton>) : null}
              </div>
            </Form>
          );
        }}
      />
    );
  }

  readImage(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = e => resolve(e.target.result);
      reader.onerror = e => {
        // eslint-disable-next-line
        console.error('Error (', e, `) happened while reading ${file.name}: ${e.target.result}`);
        reject(new Error(`Error reading ${file.name}: ${e.target.result}`));
      };
      reader.readAsDataURL(file);
    });
  }
};

SupplierAddProductFormComponent.defaultProps = { inProgress: false };

const { bool, func } = PropTypes;

SupplierAddProductFormComponent.propTypes = {
  inProgress: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SupplierAddProductForm = compose(injectIntl)(SupplierAddProductFormComponent);
SupplierAddProductForm.displayName = 'SupplierAddProductForm';

export default SupplierAddProductForm;
