import React, { useContext, useEffect, useState } from 'react';
import { bool, object, number, string, func, shape, array } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape, FormattedMessage } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { propTypes } from '../../util/types';
import config from '../../config';
import classNames from 'classnames';
import { pathByRouteName } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import ReactTypingEffect from 'react-typing-effect';
import { logout, authenticationInProgress } from '../../ducks/Auth.duck';
import { parse, stringify } from '../../util/urlHelpers';
import { withViewport } from '../../util/contextHelpers';
import { manageDisableScrolling } from '../../ducks/UI.duck';
import pickBy from 'lodash/pickBy';
import { TopbarContainer } from "../../containers";
import { localePart, storedLocale, Context } from '../../components/Wrapper/ClassWrapper';

import {
  Page,
  NamedLink,
  Logo,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  InlineTextButton,
  Avatar,
  MailchimpForSuppliers,
  Button,
  Modal,
  TopbarMobileMenu
} from '../../components';

import MenuIcon from '../../components/Topbar/MenuIcon';

import facebookImage from '../../assets/VegshelfFacebook-1200x630.png';
import twitterImage from '../../assets/VegshelfTwitter-600x314.png';
import css from './SupplierLandingPage.module.css';

import referenceCompanyLogo1 from '../../assets/cwye-logo.png';
import referenceCompanyLogo2 from '../../assets/superbon-logo.png';
import referenceCompanyLogo3 from '../../assets/roy-kombucha.png';
import referenceCompanyLogo4 from '../../assets/yofi-logo.png';

import referenceCategoryImage1 from '../../assets/cwye-unique-condiments-sauces-vegshelf.png';
import referenceCategoryImage2 from '../../assets/superbon-potato-crisps-vegshelf.png';
import referenceCategoryImage3 from '../../assets/roy-kombucha-fermented-tea.png';
import referenceCategoryImage4 from '../../assets/yofi-plant-based-milk-vegshelf.png';

import Feature_1 from '../../assets/your-online-b2b-store-vegshelf-marketplace.png';
import Feature_2 from '../../assets/customized-sales-region-vegshelf-marketplace.png';
import Feature_3 from '../../assets/messages-chat-with-b2b-buyers-vegshelf-marketplace.png';
import Feature_4 from '../../assets/stock-inventory-management-vegshelf-marketplace.png';
import Feature_5 from '../../assets/simple-order-account-management-vegshelf-marketplace.png';
import Feature_6 from '../../assets/automated-invoice-management-vegshelf-marketplace.png';

import Blog_1 from '../../assets/vegshelf-blog-independent-retail-for-emerging-cpg.png';
import Blog_2 from '../../assets/vegshelf-blog-where-source-vegan-products.png';
import Blog_3 from '../../assets/vegshelf-blog-grocery-shops-need-more-vegan-products.png';
import Blog_4 from '../../assets/vegshelf-blog-coffee-shops-need-more-vegan-products.png';

import arrowLeft from '../../assets/arrow-left-000000.svg';
import arrowRight from '../../assets/arrow-right-000000.svg';
import arrowLeftWhite from '../../assets/arrow-left-ffffff.svg';
import arrowRightWhite from '../../assets/arrow-right-ffffff.svg';

import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react';
SwiperCore.use([Navigation]);

//const locale = sessionStorage.getItem('locale') || localePart();
//const locale = storedLocale() || localePart();

const email = <a href="mailto:hello@vegshelf.com" target="_blank">hello@vegshelf.com</a>;

const MAX_MOBILE_SCREEN_WIDTH = 768;

const GenericError = (props) => {
  const { show } = props;
  const classes = classNames(css.genericError, { [css.genericErrorVisible]: show });

  return (
    <div className={classes}>
      <div className={css.genericErrorContent}>
        <p className={css.genericErrorText}>
          <FormattedMessage id="Topbar.genericError" />
        </p>
      </div>
    </div>
  );
};

GenericError.propTypes = {
  show: bool.isRequired,
};

const redirectToURLWithModalState = (props, modalStateParam) => {
  const { history, location } = props;
  const { pathname, search, state } = location;
  const searchString = `?${stringify({ [modalStateParam]: 'open', ...parse(search) })}`;
  history.push(`${pathname}${searchString}`, state);
};

const redirectToURLWithoutModalState = (props, modalStateParam) => {
  const { history, location } = props;
  const { pathname, search, state } = location;
  const queryParams = pickBy(parse(search), (v, k) => {
    return k !== modalStateParam;
  });
  const stringified = stringify(queryParams);
  const searchString = stringified ? `?${stringified}` : '';
  history.push(`${pathname}${searchString}`, state);
};

export const SupplierLandingPageComponent = props => {
  const [categoryIndex, setCategoryIndex] = useState(0);
  const [planIndex, setPlanIndex] = useState(1);
  const [blogIndex, setBlogIndex] = useState(0);
  const [FAQIndex, setFAQIndex] = useState(-1);
  const {
    history,
    intl,
    location,
    scrollingDisabled,
    currentUserListing,
    currentUserListingFetched,
    currentUser,
    viewport,
    isAuthenticated,
    currentUserHasListings,
    notificationCount,
    currentPage,
    currentUserType,
    authInProgress,
    onManageDisableScrolling,
    showGenericError,
    rootClassName,
    className,
  } = props;

  const { mobilemenu } = parse(
    location.search,
    {
      latlng: ['origin'],
      latlngBounds: ['bounds'],
    },
  );

  const [languageChanged, setLanguageChanged] = useState(false);
  const context = useContext(Context);
  const locale = storedLocale() || context.locale;

  useEffect(() => {
    sessionStorage.setItem('locale', locale);
    const newPathname = window.location.pathname.replace(/^\/[a-z]{2}/, `/${locale}`);
    window.history.replaceState({}, null, newPathname);
    if (!languageChanged) {
      setLanguageChanged(true);
    } else {
      window.location.reload();
    }
  }, [locale]);

  // const notificationDot = notificationCount > 0 ? <div className={css.notificationDot}/> : null;
  const isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;
  const isMobileMenuOpen = isMobileLayout && mobilemenu === 'open';
  const classes = classNames(rootClassName || css.root, className);


  const isBuyerUserType = currentUserType === "buyer";
  const isSupplierUserType = currentUserType === "supplier";
  const isMobileUserRegistered = isBuyerUserType || isSupplierUserType;

  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'SupplierLandingPage.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'SupplierLandingPage.schemaDescription' }, { siteTitle });
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;

  const handleMobileMenuOpen = () => {
    redirectToURLWithModalState(props, 'mobilemenu');
  }

  const handleMobileMenuClose = () => {
    redirectToURLWithoutModalState(props, 'mobilemenu');
  }

  const handleLogout = () => {
    const { onLogout, history } = props;
    onLogout().then(() => {
      //const path = pathByRouteName('LandingPage', routeConfiguration());
      const name = 'LandingPage';
      const params = { locale };
      const path = pathByRouteName(name, routeConfiguration(), params);
      // In production we ensure that data is really lost,
      // but in development mode we use stored values for debugging
      if (config.dev) {
        history.push(path);
      } else if (typeof window !== 'undefined') {
        window.location = path;
      }

      console.log('logged out'); // eslint-disable-line
    });
  }

  const mobileMenu = (
    <TopbarMobileMenu
      isAuthenticated={isAuthenticated}
      currentUserHasListings={currentUserHasListings}
      currentUserListing={currentUserListing}
      currentUserListingFetched={currentUserListingFetched}
      currentUser={currentUser}
      onLogout={handleLogout}
      notificationCount={notificationCount}
      currentPage={currentPage}
      currentUserType={currentUserType}
    />
  );

  const categoriesBlocks = [
    {
      name: 'Condiments',
      titleImageUrl: referenceCompanyLogo1,
      companyName: <FormattedMessage id="SupplierLandingPage.categories.companyName1" />,
      comment: <FormattedMessage id="SupplierLandingPage.categories.comment1" />,
      categoryAltText: 'Amsterdam-based condiment brand, Celebrate What You Eat',
      authorSubTitle: <FormattedMessage id="SupplierLandingPage.categories.condiments" />,
      categoryImageUrl: referenceCategoryImage1
    },
    {
      name: 'Snacks',
      titleImageUrl: referenceCompanyLogo2,
      companyName: <FormattedMessage id="SupplierLandingPage.categories.companyName2" />,
      comment: <FormattedMessage id="SupplierLandingPage.categories.comment2" />,
      categoryAltText: 'Brussels-based potato crisps brand, Superbon',
      authorSubTitle: <FormattedMessage id="SupplierLandingPage.categories.snacks" />,
      categoryImageUrl: referenceCategoryImage2
    },
    {
      name: 'Beverages',
      titleImageUrl: referenceCompanyLogo3,
      companyName: <FormattedMessage id="SupplierLandingPage.categories.companyName3" />,
      comment: <FormattedMessage id="SupplierLandingPage.categories.comment3" />,
      categoryAltText: 'Berlin-based kombucha brand, ROY Kombucha',
      authorSubTitle: <FormattedMessage id="SupplierLandingPage.categories.beverages" />,
      categoryImageUrl: referenceCategoryImage3
    },
    {
      name: 'Plant-based milk',
      titleImageUrl: referenceCompanyLogo4,
      companyName: <FormattedMessage id="SupplierLandingPage.categories.companyName4" />,
      comment: <FormattedMessage id="SupplierLandingPage.categories.comment4" />,
      categoryAltText: 'Paris-based plant-based milk brand, Yofi',
      authorSubTitle: <FormattedMessage id="SupplierLandingPage.categories.dairyAlternatives" />,
      categoryImageUrl: referenceCategoryImage4
    },
  ];
  const orderPlans = [
    {
      title: <FormattedMessage id="SupplierLandingPage.orderPlans.title1" />,
      decription: <FormattedMessage id="SupplierLandingPage.orderPlans.description1" />,
      imageUrl: Feature_6
    },
    {
      title: <FormattedMessage id="SupplierLandingPage.orderPlans.title2" />,
      decription: <FormattedMessage id="SupplierLandingPage.orderPlans.description2" />,
      imageUrl: Feature_3
    },
    {
      title: <FormattedMessage id="SupplierLandingPage.orderPlans.title3" />,
      decription: <FormattedMessage id="SupplierLandingPage.orderPlans.description3" />,
      imageUrl: Feature_5
    },
    {
      title: <FormattedMessage id="SupplierLandingPage.orderPlans.title4" />,
      decription: <FormattedMessage id="SupplierLandingPage.orderPlans.description4" />,
      imageUrl: Feature_2
    },
    {
      title: <FormattedMessage id="SupplierLandingPage.orderPlans.title5" />,
      decription: <FormattedMessage id="SupplierLandingPage.orderPlans.description5" />,
      imageUrl: Feature_4
    },
    {
      title: <FormattedMessage id="SupplierLandingPage.orderPlans.title6" />,
      decription: <FormattedMessage id="SupplierLandingPage.orderPlans.description6" />,
      imageUrl: Feature_1
    }
  ];

  const blogs = [
    {
      imageUrl: Blog_1,
      imageAltText: "Why independent retail is perfect for new brands",
      pageLink: "BlogArticle3"
    },
    {
      imageUrl: Blog_2,
      imageAltText: "Where to source vegan food and beverage products",
      pageLink: "BlogArticle4"
    },
    {
      imageUrl: Blog_3,
      imageAltText: "Why retailers need more vegan products in assortment",
      pageLink: "BlogArticle1"
    },
    {
      imageUrl: Blog_4,
      imageAltText: "Why coffee shops need to offer vegan food and drinks",
      pageLink: "BlogArticle2"
    }
  ];

  const FAQ = [
    {
      question: <FormattedMessage id="SupplierLandingPage.faq.q1" />,
      answer: <FormattedMessage id="SupplierLandingPage.faq.a1" />
    },
    {
      question: <FormattedMessage id="SupplierLandingPage.faq.q2" />,
      answer: <FormattedMessage id="SupplierLandingPage.faq.a2" />
    },
    {
      question: <FormattedMessage id="SupplierLandingPage.faq.q3" />,
      answer: <FormattedMessage id="SupplierLandingPage.faq.a3" />
    },
    {
      question: <FormattedMessage id="SupplierLandingPage.faq.q4" />,
      answer: <FormattedMessage id="SupplierLandingPage.faq.a4" />
    },
    {
      question: <FormattedMessage id="SupplierLandingPage.faq.q5" />,
      answer: <FormattedMessage id="SupplierLandingPage.faq.a5" />
    },
    {
      question: <FormattedMessage id="SupplierLandingPage.faq.q6" />,
      answer: <FormattedMessage id="SupplierLandingPage.faq.a6" />
    },
    {
      question: <FormattedMessage id="SupplierLandingPage.faq.q7" />,
      answer: <FormattedMessage id="SupplierLandingPage.faq.a7" />
    },
    {
      question: <FormattedMessage id="SupplierLandingPage.faq.q8" />,
      answer: <FormattedMessage id="SupplierLandingPage.faq.a8" />
    },
    {
      question: <FormattedMessage id="SupplierLandingPage.faq.q9" />,
      answer: <FormattedMessage id="SupplierLandingPage.faq.a9" />
    },
    {
      question: <FormattedMessage id="SupplierLandingPage.faq.q10" />,
      answer: <FormattedMessage id="SupplierLandingPage.faq.a10" />
    },
    {
      question: <FormattedMessage id="SupplierLandingPage.faq.q11" />,
      answer: <FormattedMessage id="SupplierLandingPage.faq.a11" />
    },
    {
      question: <FormattedMessage id="SupplierLandingPage.faq.q12" />,
      answer: <FormattedMessage id="SupplierLandingPage.faq.a12" />
    },
    {
      question: <FormattedMessage id="SupplierLandingPage.faq.q13" />,
      answer: <FormattedMessage id="SupplierLandingPage.faq.a13" />
    },
    {
      question: <FormattedMessage id="SupplierLandingPage.faq.q14" />,
      answer: <FormattedMessage id="SupplierLandingPage.faq.a14" values={{ email }} />
    }
  ];

  const currentCategory = categoriesBlocks[categoryIndex];

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      robots='noindex'
      facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <p className={css.topbarMessage}>
            <FormattedMessage id="TopbarDesktop.message.supplier" />
            &nbsp;
            <a href="mailto:hello@vegshelf.com" target="_blank"><FormattedMessage id="TopbarDesktop.messageEmail" />
            </a>
          </p>
          <div className={css.pageHeaderContainer}>
            <div className={css.mobileContainer}>
              <Button
                rootClassName={isMobileUserRegistered ? (isSupplierUserType ? css.mobileMenuSupplier : css.mobileMenuBuyer) : css.mobileMenu}
                onClick={handleMobileMenuOpen}
                title={intl.formatMessage({ id: 'Topbar.menuIcon' })}
              >
                <MenuIcon className={css.mobileMenuIcon} />
                {/*notificationDot*/}
              </Button>
              <div className={css.mobileLogoAndSearchContainer}>
                <NamedLink name="LandingPage">
                  <Logo
                    format="desktop"
                    className={css.mobileLogo}
                  //alt={intl.formatMessage({ id: 'TopbarDesktop.logo' })}
                  />
                </NamedLink>
              </div>
            </div>
            <Modal
              id="TopbarMobileMenu"
              isOpen={isMobileMenuOpen}
              onClose={handleMobileMenuClose}
              usePortal
              onManageDisableScrolling={onManageDisableScrolling}
            >
              {authInProgress ? null : mobileMenu}
            </Modal>
            <GenericError show={showGenericError} />
            <div className={css.logoAndMenuContainer}>
              <NamedLink className={css.logoIcon} name="LandingPage">
                <Logo
                  format="desktop"
                  className={css.logo}
                  alt={intl.formatMessage({ id: 'TopbarDesktop.logo' })}
                />
              </NamedLink>
              <Context.Consumer>
                {({ selectLanguage }) => (
                  <div className={css.languageButtonContainer}>
                    <button value='en' onClick={selectLanguage}>EN</button>
                    /
                    <button value='de' onClick={selectLanguage}>DE</button>
                  </div>
                )}
              </Context.Consumer>
              <div className={css.supplierTopMenu}>
                {currentUser?.attributes?.profile?.privateData?.enableCategorySectionAdmin === true ? (
                  <NamedLink className={css.categorySections}
                    name="CategorySectionPage"
                  >
                    <span className={css.menuItemBorder} />
                    <FormattedMessage id="TopbarDesktop.adminCategorySection" />
                  </NamedLink>) : null}
                {currentUser?.attributes?.profile?.privateData?.enableRegistrationRequestsModerator === true ? (
                  <NamedLink className={css.categorySections}
                    name="SupplierRegistrationModeratorPage"
                  >
                    <span className={css.menuItemBorder} />
                    <FormattedMessage id="TopbarDesktop.adminRegistrations" />
                  </NamedLink>) : null}
                <NamedLink
                  className={css.ordersMainMenuLink}
                  name="SupplierApprovedOrdersPage">
                  <FormattedMessage id="TopbarDesktop.supplierLinkOrders" />
                </NamedLink>
                <NamedLink
                  className={css.messagesMainMenuLink}
                  name="SupplierMessagesPage">
                </NamedLink>
                <Menu>
                  <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
                    <Avatar className={css.avatar} user={currentUser} disableProfileLink />
                  </MenuLabel>
                  <MenuContent className={css.profileMenuContent}>
                    <MenuItem key="SupplierProfilePage">
                      <NamedLink
                        className={css.profileSettingsLink}
                        name="SupplierProfilePage"
                      >
                        <span className={css.menuItemBorder} />
                        <FormattedMessage id="TopbarDesktop.supplierLinkCompanyPage" />
                      </NamedLink>
                    </MenuItem>
                    <MenuItem key="SupplierOrderInvoicesPage">
                      <NamedLink
                        className={css.profileSettingsLink}
                        name="SupplierOrderInvoicesPage"
                      >
                        <span className={css.menuItemBorder} />
                        <FormattedMessage id="TopbarDesktop.buyerInvoicing" />
                      </NamedLink>
                    </MenuItem>
                    <MenuItem key="SupplierProductsPage">
                      <NamedLink
                        className={css.profileSettingsLink}
                        name="SupplierProductsPage"
                      >
                        <span className={css.menuItemBorder} />
                        <FormattedMessage id="TopbarDesktop.supplierLinkProductListings" />
                      </NamedLink>
                    </MenuItem>
                    <MenuItem key="SupplierSettingsPage">
                      <NamedLink
                        className={css.profileSettingsLink}
                        name="SupplierSettingsPage"
                      >
                        <span className={css.menuItemBorder} />
                        <FormattedMessage id="TopbarDesktop.supplierLinkSettings" />
                      </NamedLink>
                    </MenuItem>
                    <MenuItem key="SupplierHelpPage">
                      <NamedLink
                        className={css.profileSettingsLink}
                        name="SupplierSettingsPage"
                      >
                        <span className={css.menuItemBorder} />
                        <FormattedMessage id="TopbarDesktop.supplierLinkHelp" />
                      </NamedLink>
                    </MenuItem>
                    <MenuItem key="logout">
                      <InlineTextButton rootClassName={css.logoutButton} onClick={handleLogout}>
                        <span className={css.menuItemBorder} />
                        <FormattedMessage id="TopbarDesktop.logout" />
                      </InlineTextButton>
                    </MenuItem>
                  </MenuContent>
                </Menu>
              </div>
            </div>
            <div className={css.pageHeaderSection}>
              <div className={css.sectionMainTitle}>
                <FormattedMessage id="SupplierLandingPage.headerTitle" />
              </div>
              <div className={css.sectionMainTitle}>
                <ReactTypingEffect
                  eraseDelay="1000"
                  speed="100"
                  typingDelay="1000"
                  eraseSpeed="70"
                  text={[
                    `${intl.formatMessage({ id: 'SupplierLandingPage.headerStoreType1' })}`,
                    `${intl.formatMessage({ id: 'SupplierLandingPage.headerStoreType2' })}`,
                    `${intl.formatMessage({ id: 'SupplierLandingPage.headerStoreType3' })}`,
                    `${intl.formatMessage({ id: 'SupplierLandingPage.headerStoreType4' })}`,
                    `${intl.formatMessage({ id: 'SupplierLandingPage.headerStoreType5' })}`,
                    `${intl.formatMessage({ id: 'SupplierLandingPage.headerStoreType6' })}`,
                    `${intl.formatMessage({ id: 'SupplierLandingPage.headerStoreType7' })}`,
                  ]}
                />
              </div>
              <div className={css.sectionSubTitle}>
                <FormattedMessage id="SupplierLandingPage.headerSubtitle" />
              </div>
              <div className={css.heroStartNowButtonContainer}>
                <NamedLink className={css.heroStartNowButton} name="SupplierProfilePage">
                  <FormattedMessage id="SupplierLandingPage.toYourCompany" />
                </NamedLink>
              </div>
            </div>
          </div>
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div className={css.mainDescriptionSection}>
            <div className={css.descImages}>
              <div className={css.rootImage1}>&nbsp;</div>
              <div className={css.rootImage2}>&nbsp;</div>
              <div className={css.rootImage3}>&nbsp;</div>
              <div className={css.rootImage4}>&nbsp;</div>
              <div className={css.rootImage5}>&nbsp;</div>
            </div>
          </div>
          <div className={css.uspContainer}>
            <div className={css.usps}>
              <div className={css.singleUsp}>
                <div className={css.stepIcon1}></div>
                <div className={css.stepTitle}>
                  <FormattedMessage id="ApplyToSellPage.valueProposition1" />
                </div>
              </div>

              <div className={css.singleUsp}>
                <div className={css.stepIcon2}></div>
                <div className={css.stepTitle}>
                  <FormattedMessage id="ApplyToSellPage.valueProposition2" />
                </div>
              </div>

              <div className={css.singleUsp}>
                <div className={css.stepIcon3}></div>
                <div className={css.stepTitle}>
                  <FormattedMessage id="ApplyToSellPage.valueProposition3" />
                </div>
              </div>

              <div className={css.singleUsp}>
                <div className={css.stepIcon4}></div>
                <div className={css.stepTitle}>
                  <FormattedMessage id="ApplyToSellPage.valueProposition4" />
                </div>
              </div>
            </div>
          </div>
          <div className={css.keyUSPContainer}>
            <div className={css.keyUSPSection}>
              <div className={css.infoSection}>
                <div className={css.infoSectionDataLeft}>
                  <div className={css.infoSectionTitle}>
                    <FormattedMessage id="SupplierLandingPage.infoSection.title1" />
                  </div>
                  <div className={css.infoSectionText}>
                    <FormattedMessage id="SupplierLandingPage.infoSection.text1" />
                  </div>
                </div>
                <div className={css.infoSectionImage1}>&nbsp;</div>
              </div>
              <div className={css.infoSection}>
                <div className={css.infoSectionImage2}>&nbsp;</div>
                <div className={css.infoSectionDataRight}>
                  <div className={css.infoSectionTitle}>
                    <FormattedMessage id="SupplierLandingPage.infoSection.title2" />
                  </div>
                  <div className={css.infoSectionText}>
                    <FormattedMessage id="SupplierLandingPage.infoSection.text2" />
                  </div>
                </div>
              </div>
              <div className={css.infoSection}>
                <div className={css.infoSectionDataLeft}>
                  <div className={css.infoSectionTitle}>
                    <FormattedMessage id="SupplierLandingPage.infoSection.title3" />
                  </div>
                  <div className={css.infoSectionText}>
                    <FormattedMessage id="SupplierLandingPage.infoSection.text3" />
                  </div>
                </div>
                <div className={css.infoSectionImage3}>&nbsp;</div>
              </div>
              <div className={css.registerSection}>
                <NamedLink className={css.registerSectionLink} name="SupplierProfilePage">
                  <FormattedMessage id="SupplierLandingPage.infoSection.useNow" />
                </NamedLink>
              </div>
            </div>
          </div>
          <div className={css.categorySection}>
            <h1>
              <FormattedMessage id="SupplierLandingPage.categoriesTitle" />
            </h1>
            <div className={css.categoryParent}>
              <div className={css.categoryContainer}>
                <Swiper
                  setWrapperSize
                  simulateTouch={false}
                  className={css.test}
                  navigation={{
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                  }}
                  loop
                  loopedSlides={categoriesBlocks.length}
                  slidesPerView={1}
                  speed={300}
                >
                  <div className={classNames(css.leftButton, 'swiper-button-prev')}>
                    <img src={arrowLeft} alt="" ></img>
                  </div>
                  {categoriesBlocks.map((c, i) => {
                    return (
                      <SwiperSlide key={i} className={css.commentContainer}>
                        <div className={css.currentCategoryDetails}>
                          <div className={css.categoryInfo}>
                            <img src={c.titleImageUrl} className={css.commentCompanyLogo} alt={c.companyName} />
                            <p className={css.categoryComment}>
                              {c.comment}
                            </p>
                            <div className={css.commentAuthorSection}>
                              {/*<img src={c.authorLogoUrl} className={css.authorLogo} />*/}
                              <div className={css.authorInfo}>
                                {/*<h1>{c.authorTitle},</h1>*/}
                                <h2>{c.authorSubTitle}</h2>
                              </div>
                            </div>
                          </div>
                          <img src={c.categoryImageUrl} className={css.referenceImage} />
                        </div>
                      </SwiperSlide>
                    );
                  })}
                  <div className={classNames(css.rightButton, 'swiper-button-next')}>
                    <img src={arrowRight} alt="" ></img>
                  </div>
                  <div className={css.buttonContainerBrands}>
                    <div className={classNames(css.leftButtonMobile, 'swiper-button-prev')}>
                      <img src={arrowLeft} alt="" ></img>
                    </div>
                    <div className={classNames(css.rightButtonMobile, 'swiper-button-next')}>
                      <img src={arrowRight} alt="" ></img>
                    </div>
                  </div>
                </Swiper>
              </div>
            </div>
          </div>
          <div className={css.inviteSectionContainer}>
            <div className={css.inviteSectionContainerOne}>
            </div>

            <div className={css.inviteSectionContainerTwo}>
              <div className={css.inviteSectionText}>
                <h1 className={css.inviteSectionDataTitle}>
                  <FormattedMessage id="SupplierLandingPage.inviteSection.title" />
                </h1>
                <h2 className={css.inviteSectionDataSubtitle}>
                  <FormattedMessage id="SupplierLandingPage.inviteSection.subTitle" />
                </h2>
                <div className={css.inviteSectionButtons}>
                  <a href="mailto:?subject=Register on Vegshelf&body=Hey, have a look at this new marketplace, Vegshelf (www.vegshelf.com), where you can discover and order wholesale from us and other amazing up-and-coming food & beverage brands across Europe! By the way, at no additional cost for wholesale buyers." target="_blank"
                    className={css.inviteSectionLink}>
                    <FormattedMessage id="SupplierLandingPage.inviteSection.inviteStockists" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className={css.orderDescriptionSectionContainer}>
            <div className={css.orderDescriptionSection}>
              <h1>
                <FormattedMessage id="SupplierLandingPage.orderDescription.title" />
              </h1>
              <p>
                <FormattedMessage id="SupplierLandingPage.orderDescription.subTitle" />
              </p>
              <div className={css.supplierUSPContainer}>
                <Swiper
                  setWrapperSize
                  simulateTouch={false}
                  className={css.test}
                  navigation={{
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                  }}
                  loop
                  loopedSlides={orderPlans.length}
                  slidesPerView="auto"
                  speed={300}
                  spaceBetween={50}
                >
                  {orderPlans.map((p, i) => {
                    return (
                      <SwiperSlide key={i} className={css.screenItem}>
                        <h1>{p.title}</h1>
                        <p>{p.decription}</p>
                        <img src={p.imageUrl} alt={p.title} />
                      </SwiperSlide>
                    );
                  })}
                  <div className={css.buttonContainer}>
                    <div className={classNames(css.swiperLeft, 'swiper-button-prev')}>
                      <img src={arrowLeft} alt=""></img>
                    </div>
                    <div className={classNames(css.swiperRight, 'swiper-button-next')}>
                      <img src={arrowRight} alt=""></img>
                    </div>
                  </div>
                </Swiper>
              </div>
            </div>
          </div>
          <div className={css.FAQSection}>
            <h1 className={css.sectionFAQTitle}>
              <FormattedMessage id="SupplierLandingPage.faq.title" />
            </h1>
            <div className={css.questionContainer}>
              {FAQ.map((f, i) => {
                const itemCss = classNames(css.questionItem, { [css.selectedQuestion]: FAQIndex === i });
                return (<div key={i} className={itemCss}>
                  <div className={css.question} onClick={() => { setFAQIndex((FAQIndex === i ? -1 : i)) }}>{f.question}</div>
                  <div className={css.answer}>{f.answer}</div>
                </div>)
              })}
            </div>
          </div>
          <div className={css.haveQuestionBlock}>
            <div classname={css.haveQuestionContainer}>
              <h1>
                <FormattedMessage id="SupplierLandingPage.haveAQuestion" />
              </h1>
              <p>
                <FormattedMessage id="SupplierLandingPage.emailAway" />
              </p>
              <div className={css.haveQuestionButtonContainer}>
                <NamedLink className={css.sendEmailLink} name="ContactUsPage">
                  <FormattedMessage id="SupplierLandingPage.contactUs" />
                </NamedLink>
              </div>
            </div>
          </div>
          <div className={css.resourceContainer}>
            <div className={css.resourcesSection}>
              <div className={css.resourceInfo}>
                <div className={css.resourceInfoBlogTitle}>
                  <FormattedMessage id="SupplierLandingPage.resourceSection.title" />
                </div>
                <div className={css.firstTextPartContainer}>
                  <div className={css.resourceInfoBlogSubTitle}>
                    <FormattedMessage id="SupplierLandingPage.resourceSection.subTitle1" />
                    <i><FormattedMessage id="SupplierLandingPage.resourceSection.subTitle2" /></i>
                  </div>
                </div>
                <div className={css.resourceInfoBlogText}>
                  <FormattedMessage id="SupplierLandingPage.resourceSection.infoText" />
                </div>
                <div className={css.resourceButtons}>
                  <NamedLink className={css.resourcesReadMoreButton} name="BlogPage">
                    <FormattedMessage id="BuyerLandingPage.blogButton" />
                  </NamedLink>
                </div>
              </div>
              <div className={css.resourceItems}>
                <Swiper
                  simulateTouch={false}
                  setWrapperSize
                  navigation={{
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                  }}
                  spaceBetween={30}
                  loop
                  loopedSlides={blogs.length}
                  slidesPerView="auto"
                  speed={500}
                >
                  {blogs.map((item, i) => {
                    return (
                      <SwiperSlide key={i} className={css.resourceItem}>
                        <NamedLink name={item.pageLink}>
                          <img src={item.imageUrl} className={css.resourceImage} alt={item.imageAltText} />
                        </NamedLink>
                      </SwiperSlide>
                    );
                  })}
                  <div className={css.swiperButtonContainer}>
                    <div className={classNames(css.resourceLeftButton, 'swiper-button-prev')}>
                      <img src={arrowLeft} alt=""></img>
                    </div>
                    <div className={classNames(css.resourceRightButton, 'swiper-button-next')}>
                      <img src={arrowRight} alt=""></img>
                    </div>
                  </div>
                </Swiper>
              </div>
            </div>
          </div>
          <div className={css.mediaSectionContainer}>
            <div className={css.mediaSection}>
              <h3 className={css.mediaSectionTitle}>
                <FormattedMessage id="SupplierLandingPage.socialMedia" />
              </h3>
              <div className={css.mediaDescription}>
                <FormattedMessage id="SupplierLandingPage.letsHangOut" />
              </div>
              <div className={css.mediaButtons}>
                <a className={css.mediaLinkedIn} href="https://www.linkedin.com/company/vegshelf/" target="_blank"></a>
                <a className={css.mediaTwitter} href="https://twitter.com/vegshelf" target="_blank"></a>
                <a className={css.mediaFacebook} href="https://www.facebook.com/vegshelf/" target="_blank"></a>
                <a className={css.mediaInstagram} href="https://www.instagram.com/vegshelf/" target="_blank"></a>
                <a className={css.mediaTiktok} href="https://www.tiktok.com/@vegshelf" target="_blank"></a>
              </div>
            </div>
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page >
  );
};

SupplierLandingPageComponent.defaultProps = {
  currentUserListing: null,
  currentUserListingFetched: false,
  notificationCount: 0,
  currentPage: null,
  currentUserType: string,
  rootClassName: null,
  className: null,
};

SupplierLandingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
  // from withRouter
  history: object.isRequired,
  location: object.isRequired,
  // from injectIntl
  intl: intlShape.isRequired,
  isAuthenticated: bool.isRequired,
  currentUserHasListings: bool.isRequired,
  notificationCount: number,
  currentPage: string,
  currentUserType: string,
  authInProgress: bool.isRequired,
  onManageDisableScrolling: func.isRequired,
  showGenericError: bool.isRequired,
  viewport: shape({ width: number.isRequired, height: number.isRequired }).isRequired,
  rootClassName: string,
  className: string,
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  const {
    currentUser,
    currentUserType,
    currentUserHasListings,
    currentUserListing,
    currentUserListingFetched } = state.user;

  return {
    scrollingDisabled: isScrollingDisabled(state),
    isAuthenticated,
    currentUserListing,
    currentUserListingFetched,
    currentUser,
    currentUserType,
    currentUserHasListings
  };
};
const mapDispatchToProps = dispatch => ({
  onLogout: historyPush => dispatch(logout(historyPush)),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const SupplierLandingPage = compose(
  withRouter,
  withViewport,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(SupplierLandingPageComponent);

export default SupplierLandingPage;
