import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import * as validators from '../../util/validators';
import getCountryCodes from '../../translations/countryCodes';
import { getAboutVegshelfCategoriesBuyer } from '../../translations/aboutVegshelfCategories';
import getAnnualSales from '../../translations/annualSales';
import { getBuyerBusinessTypes, getBuyerImportants, getBuyerNumberOfStores } from '../../translations/numberOfStores';
import {
  FieldCheckbox,
  FieldPhoneNumberInput,
  FieldSelect,
  FieldTextInput,
  Form,
  PrimaryButton,
} from '../../components';
import { localePart, storedLocale } from '../../components/Wrapper/ClassWrapper';

import css from './BuyerRegistrationForm.module.css';

const KEY_CODE_ENTER = 13;

export class BuyerRegistrationFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { businessTypeEnable: false, aboutTypeEnable: false, countryEnable: false };
    this.onChangeAbout = this.onChangeAbout.bind(this);
    this.onChangeTypeOfBusiness = this.onChangeTypeOfBusiness.bind(this);
    this.onChangeCountry = this.onChangeCountry.bind(this);
  }

  onChangeCountry(event) {
    this.setState({ ...this.state, countryEnable: event === 'Other' });
  }

  onChangeTypeOfBusiness(event) {
    this.setState({ ...this.state, businessTypeEnable: event === 'Other' });
  }

  onChangeAbout(event) {
    this.setState({ ...this.state, aboutTypeEnable: event === 'Other' });

  }

  render() {
    return (
      <FinalForm
        {...this.props}
        render={formRenderProps => {
          const {
            rootClassName,
            className,
            formId,
            handleSubmit,
            inProgress,
            // invalid,
            intl,
            // idp,
            values,
            submitFailed,
            touched,
            email,
          } = formRenderProps;
          const classes = classNames(rootClassName || css.root, className);
          const submitInProgress = inProgress;
          const countries = [...getCountryCodes(), { code: '', callCode: '', name: 'Other' }];
          const vatCodes = [...countries];
          const aboutVegshelfCategoriesBuyer = getAboutVegshelfCategoriesBuyer();
          const annualSales = getAnnualSales();
          const numberOfStores = getBuyerNumberOfStores();
          const businessTypes = getBuyerBusinessTypes();
          const buyerImportants = getBuyerImportants();
          const hasError = (field) => {
            return (values[field] === undefined || (values[field] instanceof Array && !values[field].length))
              && (submitFailed || touched[field]);
          };
          const pleaseSelectText = intl.formatMessage({ id: "BuyerRegistrationForm.optionPleaseSelect" });
          //const locale = sessionStorage.getItem('locale') || localePart();
          const locale = storedLocale() || localePart();

          return (
            <Form className={classes} onSubmit={handleSubmit}>
              <div className={css.formFields}>
                <div className={css.formRow}>
                  <div className={css.formField}>
                    <label htmlFor={formId ? `${formId}.companyName` : 'companyName'}>
                      <FormattedMessage id="UserFields.storeName" />
                      <span className={css.requiredMarker}>*</span>
                      {hasError('companyName') && (
                        <span className={css.errorMessage}><FormattedMessage id="RequiredField.warningText" /></span>
                      )}
                    </label>
                    <div>
                      <FieldTextInput
                        type="text"
                        id={formId ? `${formId}.companyName` : 'companyName'}
                        name="companyName"
                        validate={validators.required('*')}
                      />
                    </div>
                  </div>
                  <div className={css.formField}>
                    <label htmlFor={formId ? `${formId}.website` : 'website'}>
                      <FormattedMessage id="UserFields.website" />
                      <span className={css.requiredMarker}>*</span>
                      {hasError('website') && (
                        <span className={css.errorMessage}><FormattedMessage id="RequiredField.warningText" /></span>
                      )}
                    </label>
                    <div>
                      <div className={css.placeholderContainer}>
                        <div className={css.httpPlaceHolder}>https://</div>
                        <FieldTextInput
                          type="text"
                          id={formId ? `${formId}.website` : 'website'}
                          name="website" className={css.websiteInput}
                          validate={validators.composeValidators(validators.required('*'), validators.validBusinessURL('*'))}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={css.formRow}>
                  <div className={css.formField}>
                    <label htmlFor={formId ? `${formId}.firstName` : 'firstName'}>
                      <FormattedMessage id="UserFields.name" />
                      <span className={css.requiredMarker}>*</span>
                      {hasError('firstName') && (<span className={css.errorMessage}><FormattedMessage id="RequiredField.warningText" /></span>)}
                    </label>
                    <div>
                      <FieldTextInput
                        type="text"
                        id={formId ? `${formId}.firstName` : 'firstName'}
                        name="firstName"
                        validate={validators.required('*')}
                      />
                    </div>
                  </div>
                  <div className={css.formField}>
                    <label htmlFor={formId ? `${formId}.lastName` : 'lastName'}>
                      <FormattedMessage id="UserFields.surname" />
                      <span className={css.requiredMarker}>*</span>
                      {hasError('lastName') && (
                        <span className={css.errorMessage}><FormattedMessage id="RequiredField.warningText" /></span>
                      )}
                    </label>
                    <div>
                      <FieldTextInput
                        type="text"
                        id={formId ? `${formId}.lastName` : 'lastName'}
                        name="lastName"
                        validate={validators.required('*')}
                      />
                    </div>
                  </div>
                </div>
                <div className={css.formRow}>
                  <div className={css.formField}>
                    <label htmlFor={formId ? `${formId}.position` : 'position'}>
                      <FormattedMessage id="UserFields.position" />
                      <span className={css.requiredMarker}>*</span>
                      {hasError('position') && (<span className={css.errorMessage}><FormattedMessage id="RequiredField.warningText" /></span>)}
                    </label>
                    <div>
                      <FieldTextInput
                        type="text"
                        id={formId ? `${formId}.position` : 'position'}
                        name="position"
                        validate={validators.required('*')}
                      />
                    </div>
                  </div>
                  <div className={css.formField}>
                    <label htmlFor={formId ? `${formId}.phoneNumber` : 'phoneNumber'}>
                      <FormattedMessage id="UserFields.phoneNumber" />
                      <span className={css.requiredMarker}>*</span>
                      {hasError('phoneNumber') && (<span className={css.errorMessage}><FormattedMessage id="RequiredField.warningText" /></span>)}
                    </label>
                    <div className={css.fieldPhoneInput}>
                      <FieldSelect
                        id={formId ? `${formId}.phoneCode` : 'phoneCode'}
                        name="phoneCode"
                        className={css.phoneCode} viewError={hasError('phoneNumber')}
                        onChange={(e) => this.setState({ ...this.state, callCode: e })}
                      >
                        {countries.map((c) => {
                          return (
                            <option key={`callCodes${c.name}`} value={c.callCode}>
                              {c.name}
                            </option>
                          );
                        })}
                      </FieldSelect>
                      <div className={css.fieldWithCountry}>
                        <FieldPhoneNumberInput
                          id={formId ? `${formId}.phoneNumber` : 'phoneNumber'}
                          name="phoneNumber"
                          className={classNames(css.phoneCodeInput, { [css.emptyPhoneCode]: !this.state.callCode })}
                          validate={validators.required('*')}
                        />
                        <div className={css.inputCodePlaceholder}>{this?.state?.callCode}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={css.formRow}>
                  <div className={css.formField}>
                    <label htmlFor={formId ? `${formId}.businessEMail` : 'businessEMail'}>
                      <FormattedMessage id="UserFields.email" />
                      <span className={css.requiredMarker}>*</span>
                    </label>
                    <div>
                      <FieldTextInput
                        type="email"
                        id={formId ? `${formId}.businessEMail` : 'businessEMail'}
                        name="businessEMail"
                        defaultValue={email}
                        readOnly={true}
                      />
                    </div>
                  </div>
                  <div className={css.formField}>
                    <label htmlFor={formId ? `${formId}.companyLegalName` : 'companyLegalName'}>
                      <FormattedMessage id="UserFields.companyName" />
                      <span className={css.requiredMarker}>*</span>
                      {hasError('companyLegalName') && (<span className={css.errorMessage}><FormattedMessage id="RequiredField.warningText" /></span>)}
                    </label>
                    <div>
                      <FieldTextInput
                        type="text"
                        id={formId ? `${formId}.companyLegalName` : 'companyLegalName'}
                        name="companyLegalName"
                        validate={validators.required('*')}
                      />
                    </div>
                  </div>
                </div>
                <div className={css.formRow}>
                  <div className={css.formField}>
                    <label htmlFor={formId ? `${formId}.companyRegistrationNumber` : 'companyRegistrationNumber'}>
                      <FormattedMessage id="UserFields.companyRegNr" />
                      <span className={css.requiredMarker}>*</span>
                      {hasError('companyRegistrationNumber') && (<span className={css.errorMessage}><FormattedMessage id="RequiredField.warningText" /></span>)}
                    </label>
                    <div>
                      <FieldTextInput
                        type="text"
                        id={formId ? `${formId}.companyRegistrationNumber` : 'companyRegistrationNumber'}
                        name="companyRegistrationNumber"
                        validate={validators.required('*')}
                      />
                    </div>
                  </div>
                  <div className={css.formField}>
                    <label htmlFor={formId ? `${formId}.companyVATnumber` : 'companyVATnumber'}>
                      <FormattedMessage id="UserFields.companyVatNr" />
                      <span className={css.requiredMarker}>*</span>
                      {hasError('companyVATnumber') && (<span className={css.errorMessage}><FormattedMessage id="RequiredField.warningText" /></span>)}
                    </label>
                    <div className={css.fieldPhoneInput}>
                      <FieldSelect
                        id={formId ? `${formId}.companyVATcode` : 'companyVATcode'}
                        name="companyVATcode"
                        viewError={hasError('companyVATnumber')}
                        className={css.vatCode} 
                        onChange={(e) => this.setState({ ...this.state, vatCode: e })}
                      >
                        {vatCodes.map((c) => {
                          return (
                            <option key={`vatCode${c.name}`} value={c.code}>
                              {c.name}
                            </option>
                          );
                        })}
                      </FieldSelect>
                      <div className={css.fieldWithCountry}>
                        <FieldTextInput
                          type="text"
                          className={classNames(css.phoneCodeInput, { [css.emptyPhoneCode]: !this.state.vatCode })}
                          id={formId ? `${formId}.companyVATnumber` : 'companyVATnumber'}
                          name="companyVATnumber"
                          validate={validators.required('*')}
                        />
                        <div className={css.inputCodePlaceholder}>{this?.state?.vatCode}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={css.formRow}>
                  <div className={css.formField}>
                    <label htmlFor={formId ? `${formId}.streetAndNumber` : 'streetAndNumber'}>
                      <FormattedMessage id="UserFields.streetAndNumber" />
                      <span className={css.requiredMarker}>*</span>
                      {hasError('streetAndNumber') && (<span className={css.errorMessage}><FormattedMessage id="RequiredField.warningText" /></span>)}
                    </label>
                    <div>
                      <FieldTextInput
                        type="text"
                        id={formId ? `${formId}.streetAndNumber` : 'streetAndNumber'}
                        name="streetAndNumber"
                        validate={validators.required('*')}
                      />
                    </div>
                  </div>
                  <div className={css.formField}>
                    <label htmlFor={formId ? `${formId}.city` : 'city'}>
                      <FormattedMessage id="UserFields.city" />
                      <span className={css.requiredMarker}>*</span>
                      {hasError('city') && (<span className={css.errorMessage}><FormattedMessage id="RequiredField.warningText" /></span>)}
                    </label>
                    <div>
                      <FieldTextInput
                        type="text"
                        id={formId ? `${formId}.city` : 'city'}
                        name="city"
                        validate={validators.required('*')}
                      />
                    </div>
                  </div>
                </div>
                <div className={css.formRow}>
                  <div className={css.formField}>
                    <label
                      htmlFor={formId ? `${formId}.postcode` : 'postcode'}>
                      <FormattedMessage id="UserFields.zipCode" />
                      <span className={css.requiredMarker}>*</span>
                      {hasError('postcode') && (<span className={css.errorMessage}><FormattedMessage id="RequiredField.warningText" /></span>)}
                    </label>
                    <div>
                      <FieldTextInput
                        type="text"
                        id={formId ? `${formId}.postcode` : 'postcode'}
                        name="postcode"
                        validate={validators.required('*')}
                      />
                    </div>
                  </div>
                  <div className={css.formField}>
                    <label htmlFor={formId ? `${formId}.country` : 'country'}>
                      <FormattedMessage id="UserFields.country" />
                      <span className={css.requiredMarker}>*</span>
                      {(hasError('country') || (this.state.countryEnable && hasError('countryCustom'))) && (<span className={css.errorMessage}><FormattedMessage id="RequiredField.warningText" /></span>)}
                    </label>
                    <div>
                      <FieldSelect
                        id={formId ? `${formId}.country` : 'country'}
                        name="country"
                        onChange={this.onChangeCountry}
                        className={css.country}
                        viewError={(hasError('country') || (this.state.countryEnable && hasError('countryCustom')))}
                        validate={validators.required('*')}
                      >
                        <option value="" disabled={true}>{pleaseSelectText}</option>
                        {countries.map((c) => {
                          return (
                            <option key={`countrySelect${c.name}`} value={c.name}>
                              {c.name}
                            </option>
                          );
                        })}
                      </FieldSelect>
                      {this.state.countryEnable ? (
                        <FieldTextInput
                          type="text"
                          id={formId ? `${formId}.countryCustom` : 'countryCustom'}
                          name="countryCustom"
                          validate={validators.required('*')}
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className={css.formRow}>
                  <div className={css.formField}>
                    <label htmlFor={formId ? `${formId}.establishedIn` : 'establishedIn'}>
                      <FormattedMessage id="UserFields.yearEstablished" />
                      <span className={css.requiredMarker}>*</span>
                      {hasError('establishedIn') && (<span className={css.errorMessage}><FormattedMessage id="RequiredField.warningText" /></span>)}
                    </label>
                    <div>
                      <FieldTextInput
                        type="text"
                        id={formId ? `${formId}.establishedIn` : 'establishedIn'}
                        name="establishedIn"
                        validate={validators.required('*')}
                      />
                    </div>
                  </div>
                  <div className={css.formField}>
                    <label htmlFor={formId ? `${formId}.annualSales` : 'annualSales'}>
                      <FormattedMessage id="UserFields.annualSales" />
                      <span className={css.requiredMarker}>*</span>
                      {hasError('annualSales') && (<span className={css.errorMessage}><FormattedMessage id="RequiredField.warningText" /></span>)}
                    </label>
                    <div>
                      <FieldSelect
                        id={formId ? `${formId}.annualSales` : 'annualSales'}
                        name="annualSales"
                        className={css.annualSales}
                        validate={validators.required('*')}
                      >
                        <option value="" disabled={true}>{pleaseSelectText}</option>
                        {annualSales.map((c) => {
                          return (
                            <option key={`annualSalesSelect${c.name}`} value={c.name}>
                              {c.name}
                            </option>
                          );
                        })}
                      </FieldSelect>
                    </div>
                  </div>
                </div>
                <div className={css.formRow}>
                  <div className={css.formField}>
                    <label htmlFor={formId ? `${formId}.typeOfBusiness` : 'typeOfBusiness'}>
                      <FormattedMessage id="UserFields.typeOfStore" />
                      <span className={css.requiredMarker}>*</span>
                      {(hasError('typeOfBusiness') || (this.state.businessTypeEnable && hasError('typeOfBusinessCustom'))) && (<span className={css.errorMessage}><FormattedMessage id="RequiredField.warningText" /></span>)}
                    </label>
                    <div>
                      <FieldSelect
                        id={formId ? `${formId}.typeOfBusiness` : 'typeOfBusiness'}
                        name="typeOfBusiness"
                        className={css.businessType}
                        validate={validators.required('*')}
                        viewError={(hasError('typeOfBusiness') || (this.state.businessTypeEnable && hasError('typeOfBusinessCustom')))}
                        onChange={this.onChangeTypeOfBusiness}
                      >
                        <option value="" disabled={true}>{pleaseSelectText}</option>
                        {businessTypes.map((c) => {
                          return (
                            <option key={`typeOfBusinessSelect${c.code}`} value={c.name}>
                              {c.name}
                            </option>
                          );
                        })}
                      </FieldSelect>
                      {this.state.businessTypeEnable ? (
                        <FieldTextInput
                          type="text"
                          id={formId ? `${formId}.typeOfBusinessCustom` : 'typeOfBusinessCustom'}
                          name="typeOfBusinessCustom"
                          validate={validators.required('*')}
                        />
                      ) : null}
                    </div>
                  </div>
                  <div className={css.formField}>
                    <label htmlFor={formId ? `${formId}.numberOfStoresYouWorkWith` : 'numberOfStoresYouWorkWith'}>
                      <FormattedMessage id="UserFields.numberOfStores" />
                      <span className={css.requiredMarker}>*</span>
                      {hasError('numberOfStoresYouWorkWith') && (<span className={css.errorMessage}><FormattedMessage id="RequiredField.warningText" /></span>)}
                    </label>
                    <div>
                      <FieldSelect
                        id={formId ? `${formId}.numberOfStoresYouWorkWith` : 'numberOfStoresYouWorkWith'}
                        name="numberOfStoresYouWorkWith"
                        className={css.salesOutlets}
                        validate={validators.required('*')}
                      >
                        <option value="" disabled={true}>{pleaseSelectText}</option>
                        {numberOfStores.map((c) => {
                          return (
                            <option key={`numOfStoresSelect${c.name}`} value={c.name}>
                              {c.name}
                            </option>
                          );
                        })}
                      </FieldSelect>
                    </div>
                  </div>
                </div>
                <div className={css.formRow}>
                  <div className={css.propertiesContainer}>
                    <h4 className={css.propertyLabel}>
                      <FormattedMessage id="BuyerRegistrationForm.labelBuyerImportants" />
                      <span className={css.requiredMarker}>*</span>&nbsp;&nbsp;&nbsp;
                      {hasError('buyerImportants') && (<span className={css.errorMessage}><FormattedMessage id="RequiredField.warningText" /></span>)}
                    </h4>
                    {buyerImportants.map((p) => {
                      return (
                        <div key={p.code} className={css.propertyItem}>
                          <FieldCheckbox
                            id={formId ? `${formId}.buyerImportants${p.code}` : `buyerImportants${p.code}`}
                            validate={validators.requiredFieldArrayCheckbox('*')}
                            name="buyerImportants" 
                            value={p.code}
                          />
                          <label htmlFor={formId ? `${formId}.buyerImportants${p.code}` : `buyerImportants${p.code}`}>
                            {p.name}
                          </label>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className={css.formRow}>
                  <div className={css.formFullRowField}>
                    <label htmlFor={formId ? `${formId}.howDidYouHearAboutVegshelf` : 'howDidYouHearAboutVegshelf'}>
                      <FormattedMessage id="UserFields.howDidYouHearAboutUs" />
                      <span className={css.requiredMarker}>*</span>
                      {(
                        hasError('howDidYouHearAboutVegshelf')
                        || (this.state.aboutTypeEnable
                          && hasError('howDidYouHearAboutVegshelfCustom'))
                      ) && (
                          <span className={css.errorMessage}><FormattedMessage id="RequiredField.warningText" /></span>
                        )}
                    </label>
                    <div>
                      <FieldSelect
                        id={formId ? `${formId}.howDidYouHearAboutVegshelf` : 'howDidYouHearAboutVegshelf'}
                        name="howDidYouHearAboutVegshelf"
                        className={css.hearAboutVegshelf}
                        viewError={(hasError('howDidYouHearAboutVegshelf') || (this.state.aboutTypeEnable && hasError('howDidYouHearAboutVegshelfCustom')))}
                        validate={validators.required('*')} 
                        onChange={this.onChangeAbout}
                      >
                        <option value="" disabled={true}>{pleaseSelectText}</option>
                        {aboutVegshelfCategoriesBuyer.map((c) => (
                          <option key={`howDidYouHearAboutVegshelf${c.name}`} value={c.name}>
                            {c.name}
                          </option>
                        ))}
                      </FieldSelect>
                      {this.state.aboutTypeEnable ? (
                        <FieldTextInput
                          type="text"
                          id={formId ? `${formId}.howDidYouHearAboutVegshelfCustom` : 'howDidYouHearAboutVegshelfCustom'}
                          name="howDidYouHearAboutVegshelfCustom"
                          validate={validators.required('*')}
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className={css.agreeRow}>
                <FieldCheckbox
                  id={formId ? `${formId}.iAgreeToReceive` : 'iAgreeToReceive'}
                  validate={validators.requiredFieldArrayCheckbox('*')}
                  hasError={hasError('iAgreeToReceive')}
                  name="iAgreeToReceive" value="iAgreeToReceive"
                />
                <label htmlFor={formId ? `${formId}.iAgreeToReceive` : 'iAgreeToReceive'}>
                  <span className={css.requiredMarker}>*</span>
                  <FormattedMessage id="BuyerRegistrationForm.labelIAgreeToReceiveCommunicationFromVegshelf" />
                  <span>
                    <a className={css.agreeRowLinks} href={"/" + locale + "/company/buyer-terms"} /*"https://www.vegshelf.com/company/buyer-terms"*/ >
                      <FormattedMessage id="BuyerRegistrationForm.linkBuyerTerms" />
                    </a>
                  </span>
                  <FormattedMessage id="BuyerRegistrationForm.And" />
                  <span>
                    <a className={css.agreeRowLinks} href={"/" + locale + "/company/privacy-policy"} /*href="https://www.vegshelf.com/company/privacy-policy"*/ >
                      <FormattedMessage id="BuyerRegistrationForm.linkPrivacyPolicy" />
                    </a>
                  </span>
                  .
                </label>
              </div>
              <div className={css.bottomWrapper}>
                <PrimaryButton
                  type="submit" className={css.submitButton}
                  inProgress={submitInProgress}
                  disabled={submitInProgress}
                >
                  <FormattedMessage id="BuyerRegistrationForm.buttonRegisterNow" />
                </PrimaryButton>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

BuyerRegistrationFormComponent.defaultProps = { inProgress: false };

const { bool } = PropTypes;

BuyerRegistrationFormComponent.propTypes = {
  inProgress: bool,
  // from injectIntl
  intl: intlShape.isRequired,
};

const BuyerRegistrationForm = compose(injectIntl)(BuyerRegistrationFormComponent);

BuyerRegistrationForm.displayName = 'BuyerRegistrationForm';

export default BuyerRegistrationForm;