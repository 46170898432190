import {
  LoadRequests,
  LoadOrders,
} from "../containers/SupplierOrdersAndRequestsPage/SupplierOrdersAndRequestsPage.duck";
import { types as sdkTypes } from "../util/sdkLoader";
import { sendUpdatesOnMyOrdersNotification } from "./notification.duck";
import { storedLocale, localePart } from "../components/Wrapper/ClassWrapper";

const { UUID } = sdkTypes;

export const TransitionStates = {
  pending_non_authorized_order: "transition/pending-non-authorized-order",
  pending: "transition/request-order",
  confirmed: "transition/confirm-order",
  shipped: "transition/shipped-order",
  delivered: "transition/delivered-order",
  paid: "transition/paid-order",
  canceled: "transition/cancel",
  rejected: "transition/reject",
  expired: "transition/expire-confirm",
  sortedStates: ["pending", "confirmed", "shipped", "delivered", "paid"],
  parseState: function(transition) {
    for (let key in TransitionStates) {
      if (TransitionStates[key] === transition) {
        return key;
      }
    }
  },

  parseBuyerState: function(transition) {
    const buyerStateMap = {
      pending: "ORDER PLACED",
      confirmed: "IN PREPARATION",
      shipped: "IN TRANSIT",
      delivered: "DELIVERED",
      paid: "COMPLETED",
      canceled: "CANCELLED",
      rejected: "CANCELLED",
      expired: "CANCELLED",
    };
    const key = this.parseState(transition);
    return buyerStateMap[key];
  },
  stepFinished: function(currentState, state) {
    const currentStateIndex = TransitionStates.sortedStates.indexOf(currentState);
    const stateIndex = TransitionStates.sortedStates.indexOf(state);
    return stateIndex <= currentStateIndex;
  },
  nextStep: function(state) {
    let stateIndex = TransitionStates.sortedStates.indexOf(state) + 1;
    if (stateIndex >= TransitionStates.sortedStates.length) {
      stateIndex = TransitionStates.sortedStates.length - 1;
    }
    return TransitionStates.sortedStates[stateIndex];
  },
  getTransitionDate: function(transactionItem, transition) {
    const transitions = transactionItem?.attributes?.transitions || [];
    const resultTransitions = transitions.filter(t => {
      return t.transition === transition;
    });
    if (resultTransitions.length === 0) {
      return null;
    }

    return resultTransitions[0].createdAt;
  },
};

// ================ Action types ================ //
export const TRANSACTION_TRANSITION_REQUEST = "app/transaction/transition/request";
export const TRANSACTION_TRANSITION_SUCCESS = "app/transaction/transition/success";
export const TRANSACTION_TRANSITION_ERROR = "app/transaction/transition/error";

// ================ Reducer ================ //
const initialState = {
  transitionError: null,
  transitionInProgress: false,
  transitionTransaction: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case TRANSACTION_TRANSITION_REQUEST:
      return {
        ...state,
        transitionError: null,
        transitionInProgress: true,
        transitionTransaction: null,
      };
    case TRANSACTION_TRANSITION_SUCCESS:
      return { ...state, transitionInProgress: false, transitionTransaction: payload };
    case TRANSACTION_TRANSITION_ERROR:
      return { ...state, transitionInProgress: false, transitionError: payload };
    default:
      return state;
  }
}

// ================ Action creators ================ //
export const transitionRequest = () => ({ type: TRANSACTION_TRANSITION_REQUEST });
export const transitionSuccess = (transaction) => ({ type: TRANSACTION_TRANSITION_SUCCESS, payload: transaction });
export const transitionError = (error) => ({ type: TRANSACTION_TRANSITION_ERROR, payload: error, error: true });

// ================ Thunks ================ //
export const transition = (params) => (dispatch, getState, sdk) => {
  dispatch(transitionRequest());
  const { id, transitionName, args, customerId, brand, newStatus, pageIndex } = params;
  const locale = storedLocale() || localePart();

  return sdk.transactions.transition(
    { id: new UUID(id), transition: transitionName, params: args || {} },
    { expand: true },
  )
    .then((response) => {
      dispatch(LoadRequests({ pageIndex }));
      dispatch(LoadOrders({ pageIndex }));
      dispatch(sendUpdatesOnMyOrdersNotification(customerId, brand, newStatus, id, locale));
      dispatch(transitionSuccess(response?.data));

      return response;
    })
    .catch((e) => {
      return dispatch(transitionError(e));
    });
};

export const chargeOrderTransition = (params) => (dispatch, getState, sdk) => {
  dispatch(transitionRequest());
  const { id, args, customerId, brand, newStatus } = params;

  return sdk.transactions.transition(
      {
        id: new UUID(id),
        transition: TransitionStates.pending,
        params: args || {},
      },
      { expand: true },
    )
    .then((response) => {
      // TODO: Needs to be updated.
      // dispatch(sendUpdatesOnMyOrdersNotification(customerId, brand, newStatus));
      // dispatch(transitionSuccess(response?.data));

      return response;
    })
    .catch((e) => {
      return dispatch(transitionError(e));
    });
};
