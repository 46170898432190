import React, { Component } from 'react';
import { bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { propTypes } from '../../util/types';
import config from '../../config';
import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  NamedLink,
  NamedRedirect,
} from '../../components';
import {
  SupplierAddProductForm
} from '../../forms';
import { TopbarContainer } from '../../containers';

import facebookImage from '../../assets/VegshelfFacebook-1200x630.png';
import twitterImage from '../../assets/VegshelfTwitter-600x314.png';
import css from './SupplierAddProductPage.module.css';
import { loadListing, mapToFormData, saveDraftListing, publishListing, resetListing, savePending } from './SupplierAddProductPage.duck';
import arrowLeft from "../../assets/arrow-left-000000.svg";

// Check if there's requirements on selected type: 'past_due', 'currently_due' etc.
const hasRequirements = (stripeAccountData, requirementType) =>
  stripeAccountData != null &&
  stripeAccountData.requirements &&
  Array.isArray(stripeAccountData.requirements[requirementType]) &&
  stripeAccountData.requirements[requirementType].length > 0;
export class SupplierAddProductPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { invalidImages: false, submitPress: false };
    this.newSupplier = null;
    this.onSubmitProduct = this.onSubmitProduct.bind(this);
  }
  componentWillUnmount() {
    this.props.onReset();
  }
  onSubmitProduct(values) {
    if (values.handlerType == 'draft') {
      this.props.onSaveDraft(values);
      this.setState({ invalidImages: false, submitPress: true });
      return;
    }
    if (!values.productImages || values.productImages.length < 1) {
      this.setState({ invalidImages: true });
      return;
    }
    this.setState({ invalidImages: false, submitPress: true });

    if (values.handlerType == 'publish') {
      this.props.onPublish(values);
      return;
    }
    if (values.handlerType == 'update') {
      this.props.onUpdate(values);
      return;
    }
    if (values.handlerType == 'pending') {
      this.props.onSavePending(values);
      return;
    }
  }
  render() {
    const {
      history,
      intl,
      location,
      scrollingDisabled,
      supplierListingLoadError,
      supplierListingLoadProgress,
      supplierListingLoad,
      supplierListingSaveDraftError,
      supplierListingSaveDraftProgress,
      supplierListingSavedDraft,
      supplierListingPublishError,
      supplierListingPublishProgress,
      supplierListingPublished,
      supplierEditedListing,
      stripeAccount,
    } = this.props;

    var currentListing = mapToFormData(supplierEditedListing);
    const success = supplierListingSavedDraft || supplierListingPublished;
    if(success && this.state.submitPress){
      //return <Redirect to='/supplier-profile-page' />
      return <NamedRedirect name="SupplierProfilePage" />
    }
    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage({
      id: currentListing?.id
        ? 'SupplierAddProductPage.schemaTitleEdit'
        : 'SupplierAddProductPage.schemaTitleAdd'
    }, { siteTitle });

    const schemaDescription = intl.formatMessage({
      id: currentListing?.id
        ? 'SupplierAddProductPage.schemaDescriptionEdit'
        : 'SupplierAddProductPage.schemaDescriptionAdd'
    }, { siteTitle });
    const schemaImage = `${config.canonicalRootURL}${facebookImage}`;
    const inProgress = supplierListingSaveDraftProgress || supplierListingPublishProgress || supplierListingLoadProgress;
    const stripeAccountData = stripeAccount?.attributes?.stripeAccountData;
    const requirementsMissing =
      stripeAccount &&
      (hasRequirements(stripeAccountData, 'past_due') ||
        hasRequirements(stripeAccountData, 'currently_due'));
    const stripeConnected = !!stripeAccount && !!stripeAccount.id && !requirementsMissing;


    const renderComponentWithOrWithoutLoader = () => {
      const formComponent = (
        <>
          <h1 className={css.formTitle}>
            <FormattedMessage
              id={
                currentListing?.id
                  ? 'SupplierAddProductPage.formTitleUpdate'
                  : 'SupplierAddProductPage.formTitle'
              }
            />
          </h1>
          <SupplierAddProductForm
            onReset={this.props.onReset}
            currentListing={currentListing}
            invalidImages={this.state.invalidImages}
            inProgress={inProgress}
            disableForm={!stripeConnected}
            onSubmit={this.onSubmitProduct}
          />
        </>
      );

      if (history.location.pathname.includes('/add-product')) {
        return formComponent;
      }

      return supplierListingLoad ? formComponent : <div className={css.loader}><FormattedMessage id="SupplierAddProductPage.loading" /></div>;
    };

    return (
      <Page
        className={css.root}
        scrollingDisabled={scrollingDisabled}
        contentType="website"
        description={schemaDescription}
        title={schemaTitle}
        robots='noindex'
        facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
        twitterImages={[
          { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
        ]}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'WebPage',
          description: schemaDescription,
          name: schemaTitle,
          image: [schemaImage],
        }}
      >
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div className={css.pageContainer}>
              <div className={css.pageWrapper}>
                <NamedLink name="SupplierProfilePage">
                  <div className={css.backToMarketplaceSection}>
                    <img className={css.arrow} src={arrowLeft} alt="" />
                    <p className={css.backToMarketplaceText}>
                      <FormattedMessage id="SupplierAddProductForm.buttonBack" />
                    </p>
                  </div>
                </NamedLink>
                <div className={css.formPanel}>
                  {/* TODO: remove after successful testing */}
                  {/* {!stripeConnected && (
                  <div className={css.stripeConnectedBlock}>
                    <i class="bi bi-exclamation-circle"></i> &nbsp; To create products, you need to{' '}
                    <NamedLink name="SupplierProfilePayoutDetailsPage">set up a payout</NamedLink>.
                  </div>
                )} */}
                  {renderComponentWithOrWithoutLoader()}
                </div>
                <div className={css.placeHolder}></div>
              </div>
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page >
    );
  }
};

SupplierAddProductPageComponent.defaultProps = {
  currentUserListing: null,
  currentUserListingFetched: false,
};

SupplierAddProductPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};
const mapStateToProps = state => {
  const { supplierListingLoadError,
    supplierListingLoadProgress,
    supplierListingLoad,
    supplierListingSaveDraftError,
    supplierListingSaveDraftProgress,
    supplierListingSavedDraft,
    supplierListingPublishError,
    supplierListingPublishProgress,
    supplierListingPublished,
    supplierEditedListing } = state.SupplierAddProductPage;
  const { stripeAccount } = state.stripeConnectAccount;
  return {
    scrollingDisabled: isScrollingDisabled(state),
    supplierListingLoadError,
    supplierListingLoadProgress,
    supplierListingLoad,
    supplierListingSaveDraftError,
    supplierListingSaveDraftProgress,
    supplierListingSavedDraft,
    supplierListingPublishError,
    supplierListingPublishProgress,
    supplierListingPublished,
    supplierEditedListing,
    stripeAccount,
  };
};

const mapDispatchToProps = dispatch => ({
  onSaveDraft: values => dispatch(saveDraftListing(values)),
  onPublish: values => dispatch(publishListing(values)),
  onUpdate: values => dispatch(saveDraftListing(values)),
  onSavePending: values => dispatch(savePending(values)),
  onReset: () => dispatch(resetListing()),
});

const SupplierAddProductPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(SupplierAddProductPageComponent);


SupplierAddProductPage.loadData = (args) => (dispatch, getState) => {
  if (!args?.id) {
    return Promise.all([dispatch(resetListing())]);
  }
  return dispatch(loadListing({ id: args.id }));
};
SupplierAddProductPage.setInitialValues = (values) => {
};

export default SupplierAddProductPage;
