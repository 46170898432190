import { bool, func } from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { isScrollingDisabled } from "../../ducks/UI.duck";
import { fetchCurrentUser, sendVerificationEmail } from "../../ducks/user.duck";
import { ContactDetailsForm } from "../../forms";
import { ensureCurrentUser } from "../../util/data";
import { injectIntl, intlShape } from "../../util/reactIntl";
import { propTypes } from "../../util/types";
import { resetPassword, saveContactDetails, saveContactDetailsClear } from "./ContactDetailsPage.duck";
import css from "./ContactDetailsPage.module.css";
import getCountryCodes from "../../translations/countryCodes";

export const ContactDetailsPageComponent = ({
  saveEmailError,
  savePhoneNumberError,
  saveContactDetailsInProgress,
  currentUser,
  contactDetailsChanged,
  onChange,
  sendVerificationEmailInProgress,
  sendVerificationEmailError,
  onResendVerificationEmail,
  onSubmitContactDetails,
  onResetPassword,
  resetPasswordInProgress,
  resetPasswordError,
  intl,
}) => {
  const user = ensureCurrentUser(currentUser);
  const currentEmail = user.attributes.email || '';
  const protectedData = user.attributes.profile.protectedData || {};
  const currentPosition = protectedData?.position || '';
  const currentFirstName = user.attributes.profile.firstName || '';
  const currentLastName = user.attributes.profile.lastName || '';
  const phoneCodesList = getCountryCodes();
  const draftPhoneCode = protectedData?.phoneNumber?.slice(0, 3) || '';
  const isPhoneCodeExists = phoneCodesList.find((c) => c.callCode === draftPhoneCode);

  /**
   * @description extracted phoneCode from the phoneNumber
   * @type {string|string}
   */
  const currentPhoneCode = isPhoneCodeExists ? isPhoneCodeExists.callCode : "+";
  /**
   * @description phoneNumber without phoneCode
   * @type {string|string}
   */
  const currentPhoneNumber = !isPhoneCodeExists
    ? protectedData?.phoneNumber?.substring(1) : protectedData?.phoneNumber?.substring(3) || '';
  const formInitialValues = {
    email: currentEmail,
    phoneCode: currentPhoneCode,
    phoneNumber: currentPhoneNumber,
    firstName: currentFirstName,
    lastName: currentLastName,
    position: currentPosition,
    currentPassword: "",
  };

  const onSubmitDetailsForm = (values) => onSubmitContactDetails({
    ...values,
    currentEmail,
    currentPhoneCode,
    currentPhoneNumber,
    currentFirstName,
    currentLastName,
    currentPosition,
  });

  const contactDetailsForm = currentUser?.id ? (
    <ContactDetailsForm
      initialValues={formInitialValues}
      saveEmailError={saveEmailError}
      savePhoneNumberError={savePhoneNumberError}
      currentUser={currentUser}
      onResendVerificationEmail={onResendVerificationEmail}
      onResetPassword={onResetPassword}
      onSubmit={onSubmitDetailsForm}
      onChange={onChange}
      inProgress={saveContactDetailsInProgress}
      ready={contactDetailsChanged}
      sendVerificationEmailInProgress={sendVerificationEmailInProgress}
      sendVerificationEmailError={sendVerificationEmailError}
      resetPasswordInProgress={resetPasswordInProgress}
      resetPasswordError={resetPasswordError}
    />
  ) : null;

  const pageTitle = intl.formatMessage({ id: "ContactDetailsPage.heading" });

  return (
    <div className={css.content}>
      <h1 className={css.title}>{pageTitle}</h1>
      {contactDetailsForm}
    </div>
  );
};

ContactDetailsPageComponent.defaultProps = {
  saveEmailError: null,
  savePhoneNumberError: null,
  currentUser: null,
  sendVerificationEmailError: null,
  resetPasswordInProgress: false,
  resetPasswordError: null,
};

ContactDetailsPageComponent.propTypes = {
  saveEmailError: propTypes.error,
  savePhoneNumberError: propTypes.error,
  saveContactDetailsInProgress: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentUserListing: propTypes.ownListing,
  contactDetailsChanged: bool.isRequired,
  onChange: func.isRequired,
  onSubmitContactDetails: func.isRequired,
  scrollingDisabled: bool.isRequired,
  sendVerificationEmailInProgress: bool.isRequired,
  sendVerificationEmailError: propTypes.error,
  onResendVerificationEmail: func.isRequired,
  resetPasswordInProgress: bool,
  resetPasswordError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = (state) => {
  // TopBar needs user info.
  const { currentUser, currentUserListing, sendVerificationEmailInProgress, sendVerificationEmailError } = state.user;
  const {
    saveEmailError,
    savePhoneNumberError,
    saveContactDetailsInProgress,
    contactDetailsChanged,
    resetPasswordInProgress,
    resetPasswordError,
  } = state.ContactDetailsPage;

  return {
    saveEmailError,
    savePhoneNumberError,
    saveContactDetailsInProgress,
    currentUser,
    currentUserListing,
    contactDetailsChanged,
    scrollingDisabled: isScrollingDisabled(state),
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
    resetPasswordInProgress,
    resetPasswordError,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onChange: () => dispatch(saveContactDetailsClear()),
  onResendVerificationEmail: () => dispatch(sendVerificationEmail()),
  onSubmitContactDetails: (values) => dispatch(saveContactDetails(values)),
  onResetPassword: (values) => dispatch(resetPassword(values)),
});

const ContactDetailsPage = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(ContactDetailsPageComponent);

ContactDetailsPage.loadData = () => {
  // Since verify email happens in separate tab, current user's data might be updated
  return fetchCurrentUser();
};

export default ContactDetailsPage;
