import React, { useState } from 'react';
import { bool, string } from 'prop-types';
import classNames from 'classnames';
/* Swiper components */
import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react';
SwiperCore.use([Navigation]);
/* Swiper components */
import { propTypes } from '../../util/types';
import { FormattedMessage } from '../../util/reactIntl';

import ImageStore1 from '../../assets/independent-retailers.png';
import ImageStore2 from '../../assets/pop-up-stores.png';
import ImageStore3 from '../../assets/gift-lifestyle-shops.png';
import ImageStore4 from '../../assets/hotels-fitness-studios.png';
import ImageStore5 from '../../assets/organic-stores.png';
import ImageStore6 from '../../assets/coffee-shops.png';
import ImageStore7 from '../../assets/vegan-subscription-boxes.png';
import ImageStore8 from '../../assets/vegan-specialty-stores.png';

import css from './SectionStores.module.css';
import arrowLeft from '../../assets/arrow-left-000000.svg';
import arrowRight from '../../assets/arrow-right-000000.svg';

const SectionStores = props => {
  const { rootClassName, className } = props;
  const brandsSlideOne = [
    {
      id: 1,
      link: <FormattedMessage id="SectionStores.independent" />,
      image: ImageStore1,
    },
    {
      id: 2,
      link: <FormattedMessage id="SectionStores.coffee" />,
      image: ImageStore6,
    },
    {
      id: 3,
      link: <FormattedMessage id="SectionStores.giftAndLifestyle" />,
      image: ImageStore3,
    },
    {
      id: 4,
      link: <FormattedMessage id="SectionStores.hotelsAndFitness" />,
      image: ImageStore4,
    },
  ];

  const brandItems = item => {
    return (
      <div key={item.id} className={css.brandItem}>
        {/*<a className={css.brandLink} >*/}
        <img src={item.image} className={css.brandImage} alt={item.link} />
        {/*</a>*/}
        <p className={css.brandTitle}>
          {/*<a className={css.brandNameLink} >*/}
          {item.link}
          <br />
          {/*</a>{' '}*/}
          {item.subText}
        </p>
      </div>
    );
  };

  const brandsNodesOne = brandsSlideOne.map(brandItems);
  const brandsSlideTwo = [
    {
      id: 1,
      link: <FormattedMessage id="SectionStores.organic" />,
      image: ImageStore5,
    },
    {
      id: 2,
      link: <FormattedMessage id="SectionStores.popUp" />,
      image: ImageStore2,
    },
    {
      id: 3,
      link: <FormattedMessage id="SectionStores.subscription" />,
      image: ImageStore7,
    },
    {
      id: 4,
      link: <FormattedMessage id="SectionStores.specialty" />,
      image: ImageStore8,
    },
  ];

  const brandsNodesTwo = brandsSlideTwo.map(brandItems);

  return (
    <div className={css.storeContainer}>
      <h2 className={css.sectionTitle}><FormattedMessage id="SectionStores.sectionTitle" /></h2>
      <p className={css.sectionSubTitle}><FormattedMessage id="SectionStores.sectionSubtitle" /></p>
      <Swiper
        setWrapperSize
        simulateTouch={false}
        className={css.test}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }}
        loop
        slidesPerView={1}
        speed={300}
      >
        <SwiperSlide className={css.commentContainer}>
          <div className={css.brandNodes}>
            {brandsNodesOne}
          </div>
        </SwiperSlide>
        <SwiperSlide className={css.commentContainer}>
          <div className={css.brandNodes}>
            {brandsNodesTwo}
          </div>
        </SwiperSlide>
        <div className={css.buttonContainer}>
          <div className={classNames(css.leftButton, 'swiper-button-prev')}>
            <img src={arrowLeft} alt=""></img>
          </div>
          <div className={classNames(css.rightButton, 'swiper-button-next')}>
            <img src={arrowRight} alt=""></img>
          </div>
        </div>
      </Swiper>
    </div>
  );
};

SectionStores.defaultProps = {
  rootClassName: null,
  className: null,
  currentUserListing: null,
  currentUserListingFetched: false,
};

SectionStores.propTypes = {
  rootClassName: string,
  className: string,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
};

export default SectionStores;