import React, { useState } from 'react';
import { bool, string, shape, number, object } from 'prop-types';
import classNames from 'classnames';
/* Swiper components */
import SwiperCore, { Autoplay, Navigation } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react';
SwiperCore.use([Navigation, Autoplay]);
/* Swiper components */
import { propTypes } from '../../util/types';
import { FormattedMessage } from '../../util/reactIntl';
import { NamedLink } from '../../components';
import { slugify } from "../../util/urlTrimming";

import ImageStore1 from '../../assets/product-image-1.png';
import ImageStore2 from '../../assets/product-image-2.png';
import ImageStore3 from '../../assets/product-image-3.png';
import ImageStore4 from '../../assets/product-image-4.png';
import ImageStore5 from '../../assets/product-image-5.png';
import ImageStore6 from '../../assets/product-image-6.png';
import ImageStore7 from '../../assets/product-image-7.png';
import ImageStore8 from '../../assets/product-image-8.png';
import ImageStore9 from '../../assets/product-image-9.png';
import ImageStore10 from '../../assets/product-image-10.png';
import ImageStore11 from '../../assets/product-image-11.png';
import ImageStore12 from '../../assets/product-image-12.png';


import css from './SectionProducts.module.css';
import arrowLeft from '../../assets/arrow-left-000000.svg';
import arrowRight from '../../assets/arrow-right-000000.svg';
import { withViewport } from '../../util/contextHelpers';
import { compose } from 'underscore';

const MAX_MOBILE_SCREEN_WIDTH = 768;

const SectionProductsComponent = props => {
  const { rootClassName, className, viewport, product } = props;
  /*const brandsSlideOne = [
    {
      id: 1,
      subText: <NamedLink name="ProductDetailsPage" params={{ product: slugify(product.productName), id: product.id }}><FormattedMessage id="SectionProducts.product1" /></NamedLink>,
      image: ImageStore1,
    },
    {
      id: 2,
      subText: <NamedLink name="ProductDetailsPage" params={{ product: slugify(product.productName), id: product.id }}><FormattedMessage id="SectionProducts.product2" /></NamedLink>,
      image: ImageStore2,
    },
    {
      id: 3,
      subText: <NamedLink name="ProductDetailsPage" params={{ product: slugify(product.productName), id: product.id }}><FormattedMessage id="SectionProducts.product3" /></NamedLink>,
      image: ImageStore3,
    },
    {
      id: 4,
      subText: <NamedLink name="ProductDetailsPage" params={{ product: slugify(product.productName), id: product.id }}><FormattedMessage id="SectionProducts.product4" /></NamedLink>,
      image: ImageStore4,
    },
    {
      id: 5,
      subText: <NamedLink name="ProductDetailsPage" params={{ product: slugify(product.productName), id: product.id }}><FormattedMessage id="SectionProducts.product5" /></NamedLink>,
      image: ImageStore5,
    },
    {
      id: 6,
      subText: <NamedLink name="ProductDetailsPage" params={{ product: slugify(product.productName), id: product.id }}><FormattedMessage id="SectionProducts.product6" /></NamedLink>,
      image: ImageStore6,
    },
  ];*/

  const brandsSlideOne = [
    {
      id: 1,
      subText: <FormattedMessage id="SectionProducts.product1" />,
      image: ImageStore1,
    },
    {
      id: 2,
      subText: <FormattedMessage id="SectionProducts.product2" />,
      image: ImageStore2,
    },
    {
      id: 3,
      subText: <FormattedMessage id="SectionProducts.product3" />,
      image: ImageStore3,
    },
    {
      id: 4,
      subText: <FormattedMessage id="SectionProducts.product4" />,
      image: ImageStore4,
    },
    {
      id: 5,
      subText: <FormattedMessage id="SectionProducts.product5" />,
      image: ImageStore5,
    },
    {
      id: 6,
      subText: <FormattedMessage id="SectionProducts.product6" />,
      image: ImageStore6,
    },
  ];

  const brandItems = item => {
    return (
      <div key={item.id} className={css.brandItem}>
        <img src={item.image} className={css.brandImage} alt={item.link} />
        <p className={css.brandTitle}>
          {item.subText}
        </p>
      </div>
    );
  };

  const brandsNodesOne = brandsSlideOne.map(brandItems);
  const brandsSlideTwo = [
    {
      id: 1,
      subText: <FormattedMessage id="SectionProducts.product7" />,
      image: ImageStore7,
    },
    {
      id: 2,
      subText: <FormattedMessage id="SectionProducts.product8" />,
      image: ImageStore8,
    },
    {
      id: 3,
      subText: <FormattedMessage id="SectionProducts.product9" />,
      image: ImageStore9,
    },
    {
      id: 4,
      subText: <FormattedMessage id="SectionProducts.product10" />,
      image: ImageStore10,
    },
    {
      id: 5,
      subText: <FormattedMessage id="SectionProducts.product11" />,
      image: ImageStore11,
    },
    {
      id: 6,
      subText: <FormattedMessage id="SectionProducts.product12" />,
      image: ImageStore12,
    },
  ];

  const brandsSlideOneMobile = [
    {
      id: 1,
      subText: <FormattedMessage id="SectionProducts.product1" />,
      image: ImageStore1,
    },
    {
      id: 2,
      subText: <FormattedMessage id="SectionProducts.product2" />,
      image: ImageStore2,
    },
  ];

  const brandsSlideTwoMobile = [
    {
      id: 1,
      subText: <FormattedMessage id="SectionProducts.product3" />,
      image: ImageStore3,
    },
    {
      id: 2,
      subText: <FormattedMessage id="SectionProducts.product4" />,
      image: ImageStore4,
    },
  ];

  const brandsSlideThreeMobile = [
    {
      id: 1,
      subText: <FormattedMessage id="SectionProducts.product5" />,
      image: ImageStore5,
    },
    {
      id: 2,
      subText: <FormattedMessage id="SectionProducts.product6" />,
      image: ImageStore6,
    },
  ];

  const brandsSlideFourMobile = [
    {
      id: 1,
      subText: <FormattedMessage id="SectionProducts.product7" />,
      image: ImageStore7,
    },
    {
      id: 2,
      subText: <FormattedMessage id="SectionProducts.product8" />,
      image: ImageStore8,
    },
  ];

  const brandsSlideFiveMobile = [
    {
      id: 1,
      subText: <FormattedMessage id="SectionProducts.product9" />,
      image: ImageStore9,
    },
    {
      id: 2,
      subText: <FormattedMessage id="SectionProducts.product10" />,
      image: ImageStore10,
    },
  ];

  const brandsSlideSixMobile = [
    {
      id: 1,
      subText: <FormattedMessage id="SectionProducts.product11" />,
      image: ImageStore11,
    },
    {
      id: 2,
      subText: <FormattedMessage id="SectionProducts.product12" />,
      image: ImageStore12,
    },
  ];

  const brandsNodesTwo = brandsSlideTwo.map(brandItems);
  const brandsNodesMobileOne = brandsSlideOneMobile.map(brandItems);
  const brandsNodesMobileTwo = brandsSlideTwoMobile.map(brandItems);
  const brandsNodesMobileThree = brandsSlideThreeMobile.map(brandItems);
  const brandsNodesMobileFour = brandsSlideFourMobile.map(brandItems);
  const brandsNodesMobileFive = brandsSlideFiveMobile.map(brandItems);
  const brandsNodesMobileSix = brandsSlideSixMobile.map(brandItems);

  const isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;

  return (
    <div className={css.productContainer}>
      <h2 className={css.sectionTitle}><FormattedMessage id="SectionProducts.sectionTitle" /></h2>
      <Swiper
        setWrapperSize
        simulateTouch={false}
        className={css.test}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }}
        loop={true}
        slidesPerView={1}
        speed={60000}
        autoplay={{
          delay: 1,
          disableOnInteraction: false,
        }}
      >
        {isMobileLayout ? (
          <>
            <SwiperSlide className={css.commentContainer}>
              <div className={css.brandNodes}>
                {brandsNodesMobileOne}
              </div>
            </SwiperSlide>
            <SwiperSlide className={css.commentContainer}>
              <div className={css.brandNodes}>
                {brandsNodesMobileTwo}
              </div>
            </SwiperSlide>
            <SwiperSlide className={css.commentContainer}>
              <div className={css.brandNodes}>
                {brandsNodesMobileThree}
              </div>
            </SwiperSlide>
            <SwiperSlide className={css.commentContainer}>
              <div className={css.brandNodes}>
                {brandsNodesMobileFour}
              </div>
            </SwiperSlide>
            <SwiperSlide className={css.commentContainer}>
              <div className={css.brandNodes}>
                {brandsNodesMobileFive}
              </div>
            </SwiperSlide>
            <SwiperSlide className={css.commentContainer}>
              <div className={css.brandNodes}>
                {brandsNodesMobileSix}
              </div>
            </SwiperSlide>
          </>
        ) : (
          <>
            <SwiperSlide className={css.commentContainer}>
              <div className={css.brandNodes}>
                {brandsNodesOne}
              </div>
            </SwiperSlide>
            <SwiperSlide className={css.commentContainer}>
              <div className={css.brandNodes}>
                {brandsNodesTwo}
              </div>
            </SwiperSlide>
          </>
        )}
      </Swiper>
    </div>
  );
};

SectionProductsComponent.defaultProps = {
  rootClassName: null,
  className: null,
  currentUserListing: null,
  currentUserListingFetched: false,
};

SectionProductsComponent.propTypes = {
  rootClassName: string,
  className: string,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
  viewport: shape({ width: number.isRequired, height: number.isRequired }).isRequired,

  // 16.07.23 Vegshelf freeze: commented out as not required (error shown in console)
  //product: object.isRequired,
};

const SectionProducts = compose(
  withViewport
)(SectionProductsComponent);

export default SectionProducts;