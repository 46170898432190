import React, { Component } from 'react';
import { IntlProvider } from 'react-intl';
import English from '../../translations/en.json';
import German from '../../translations/de.json';

export const Context = React.createContext();

export const navigatorLanguage = () => {
  if (navigator.language === 'en-US') {
    return "en";
  } else if (navigator.language === 'de-DE') {
    return "de";
  } else {
    return "en";
  }
}

export const addLocaleToPath = routes => {
  return routes.map(route => {
      if (route.path.indexOf(':locale') === -1) {
          route.path = `/:locale${route.path}`;
      }
      return route;
  });
};

//export const updatedRoutes = addLocaleToPath(routeConfiguration());

//export const localePart = queryString.slice(1, 3);

/*let storedLocale = "en";

if (typeof sessionStorage !== 'undefined') {
  if (sessionStorage) {
    storedLocale = sessionStorage.getItem('locale');
  } else {
    storedLocale = "en";
  }
};*/
/*export const storedLocale = () => {
  if (typeof sessionStorage !== 'undefined') {
    if (sessionStorage) {
    return sessionStorage.getItem('locale');
    } else {
      return "en";
    }
  };
};*/

export const storedLocale = () => {
  return typeof sessionStorage !== 'undefined' && sessionStorage ? sessionStorage.getItem('locale') : "en";
};

/*export const localePart = () => {
  let queryString = "en";
  if (typeof window !== 'undefined') {
    if (window) {
      queryString = window.location.pathname;
    } else {
      queryString = "";
    }
  };
  //const queryString = window.location.pathname;
  const lang = queryString.slice(1, 3);
  if (lang === 'en') {
    return "en";
  } else if (lang === 'de') {
    return "de";
  } else {
    return "en";
  }
}*/

export const localePart = () => {
  let queryString = "";
  if (typeof window !== 'undefined') {
    queryString = window.location.pathname;
  }

  const lang = queryString.slice(1, 3);
  if (lang === 'en') {
    return "en";
  } else if (lang === 'de') {
    return "de";
  } else {
    return "en";
  }
};

class ClassWrapper extends Component {
  constructor(props) {
    super(props);
    //this.state = {
    //locale:  sessionStorage.getItem('locale') || /*this.getLocale(navigator.language)*/this.setLocale(window.location.pathname) || this.getLocale("en"),
    //messages: this.getMessages(navigator.language),
    //locale:  this.getLocale("en-US"),
    //messages: this.getMessages('en'),
    //};
    let locale = "en";
    if (typeof sessionStorage !== 'undefined') {
      locale = sessionStorage.getItem('locale') || "";
    }
    if (locale === "") {
      if (typeof window !== 'undefined') {
        locale = this.setLocale(window.location.pathname) || "en";
      } else {
        locale = "en";
      }
    }
    this.state = {
      locale: locale,
      messages: this.getMessages(locale),
    };
  }

  componentDidMount() {
    this.setState({
      locale:  sessionStorage.getItem('locale') || this.setLocale(window.location.pathname),
      messages: this.getMessages(this.state.locale),
    });
  }

  getMessages = (locale) => {
    if (locale === 'en') {
      return English;
    } else if (locale === 'de') {
      return German;
    } else {
      return English;
    }
  }

  getLocale = (locale) => {
    if (locale === 'en-US') {
      return "en";
    } else if (locale === 'de-DE') {
      return "de";
    } else {
      return "en";
    }
  }

  setLocale = (locale) => {
    //const queryString = window.location.pathname;
    const lang = locale.slice(1, 3);
    if (lang === 'en') {
      return "en";
    } else if (lang === 'de') {
      return "de";
    } else {
      return "en";
    }
  }

  selectLanguage = (e) => {
    const newLocale = e.target.value;
    this.setState({
      locale: newLocale,
      messages: this.getMessages(newLocale),
    });
    sessionStorage.setItem('locale', newLocale);
  }

  render() {
    const { children } = this.props;
    const { locale, messages } = this.state;

    return (
      <Context.Provider value={{ locale, selectLanguage: this.selectLanguage }}>
        <IntlProvider messages={messages} locale={locale}>
          {/*children*/}
          {React.Children.map(children, child => {
          return React.cloneElement(child, {
            locale: this.state.locale,
          });
        })}
        </IntlProvider>
      </Context.Provider>
    );
  }
}

export default ClassWrapper;
