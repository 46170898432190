import config from '../../config'
import { buyerOrdersReset } from "../BuyerOrdersAndRequestsPage/BuyerOrdersAndRequestsPage.duck";

// ================ Action types ================ //
export const CHECK_ACCESS_REQUEST = 'app/check/access';
export const CHECK_ACCESS_SUCCESS = 'app/check/access/success';
export const CHECK_ACCESS_ERROR = 'app/check/access.error';

// ================ Reducer ================ //
/**
 * @description Disabling access-checking in the development mode.
 * (redirecting for entering access-key and back if it valid)
 * @type {boolean}
 */
const isDevelopmentMode = process.env.NODE_ENV === "development";

const initialState = {
  canCheckAccess: config.canCheckAccess,
  hasAccess: false,
  ValidationError: null
};

export default function reducer(state = initialState, action = {}) {
  const { type } = action;

  switch (type) {
    case CHECK_ACCESS_REQUEST:
      return { ...state, canCheckAccess: config.canCheckAccess, hasAccess: false, ValidationError: null };
    case CHECK_ACCESS_SUCCESS:
      return { ...state, hasAccess: true, ValidationError: false };
    case CHECK_ACCESS_ERROR:
      return { ...state, ValidationError: true };
    default:
      return state;
  }
}

// ================ Action creators ================ //
export const checkAccessRequest = () => ({ type: CHECK_ACCESS_REQUEST });
export const checkAccessSuccess = () => ({ type: CHECK_ACCESS_SUCCESS });
export const checkAccessError = () => ({ type: CHECK_ACCESS_ERROR });

// ================ Thunks ================ //
export const checkAccess = params => (dispatch) => {
  dispatch(checkAccessRequest());
  // Calling orders resetting to avoid data duplication during merging process.
  dispatch(buyerOrdersReset());
  const { accessKey } = params;
  if(accessKey === config.sdk.clientId){
    dispatch(checkAccessSuccess());
  } else{
    dispatch(checkAccessError());
  }
};
