import React, { Component } from 'react';
import { bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { propTypes } from '../../util/types';
import config from '../../config';
import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  TabNav,
} from '../../components';
import {
  TopbarContainer,
  ImprintPage,
  BuyerTermsPage,
  SupplierTermsPage,
  PrivacyPolicyPage,
  ContactUsPage,
  CookiePolicyPage
} from '..';
import { localePart, storedLocale } from '../../components/Wrapper/ClassWrapper';

import facebookImage from '../../assets/VegshelfFacebook-1200x630.png';
import twitterImage from '../../assets/VegshelfTwitter-600x314.png';
import css from './Company.module.css';

export class CompanyComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { stepIndex: 0 };
  }
  render() {
    const {
      intl,
      scrollingDisabled,
    } = this.props;
    let { tab } = this.props;
    tab = tab || 'imprint';
    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage({ id: 'CompanyPage.schemaTitle' }, { siteTitle });
    const schemaDescription = intl.formatMessage({ id: 'CompanyPage.schemaDescription' }, { siteTitle });
    const schemaImage = `${config.canonicalRootURL}${facebookImage}`;
    //const locale = sessionStorage.getItem('locale') || localePart();
    const locale = storedLocale() || localePart();
    var pages = {
      'imprint': (<ImprintPage {...this.props} />),
      'buyer-terms': (<BuyerTermsPage {...this.props} />),
      'supplier-terms': (<SupplierTermsPage {...this.props} />),
      'privacy-policy': (<PrivacyPolicyPage {...this.props} />),
      'cookie-policy': (<CookiePolicyPage {...this.props} />),
      'contact-us': (<ContactUsPage {...this.props} />)
    };

    const tabs = [
      {
        text: (
          <span>
            <FormattedMessage id="Company.Imprint" />
          </span>
        ),
        selected: tab == 'imprint',
        linkProps: {
          name: 'ImprintPage',
          //params: { tab: 'imprint' }
          params: { tab: 'imprint', locale: locale }
        },
      },
      {
        text: (
          <span>
            <FormattedMessage id="Company.BuyerTerms" />
          </span>
        ),
        selected: tab == 'buyer-terms',
        linkProps: {
          name: 'BuyerTermsPage',
          //params: { tab: 'buyer-terms' }
          params: { tab: 'buyer-terms', locale: locale }
        },
      },
      {
        text: (
          <span>
            <FormattedMessage id="Company.SupplierTerms" />
          </span>
        ),
        selected: tab == 'supplier-terms',
        linkProps: {
          name: 'SupplierTermsPage',
          //params: { tab: 'supplier-terms' }
          params: { tab: 'supplier-terms', locale: locale }
        },
      },
      {
        text: (
          <span>
            <FormattedMessage id="Company.PrivacyPolicy" />
          </span>
        ),
        selected: tab == 'privacy-policy',
        linkProps: {
          name: 'PrivacyPolicyPage',
          //params: { tab: 'privacy-policy' }
          params: { tab: 'privacy-policy', locale: locale }
        },
      },
      {
        text: (
          <span>
            <FormattedMessage id="Company.CookiePolicy" />
          </span>
        ),
        selected: tab == 'cookie-policy',
        linkProps: {
          name: 'CookiePolicyPage',
          //params: { tab: 'cookie-policy' }
          params: { tab: 'cookie-policy', locale: locale }
        },
      },
      {
        text: (
          <span>
            <FormattedMessage id="Company.ContactUs" />
          </span>
        ),
        selected: tab == 'contact-us',
        linkProps: {
          name: 'ContactUsPage',
          //params: { tab: 'contact-us' }
          params: { tab: 'contact-us', locale: locale }
        },
      }
    ];
    const nav = <TabNav rootClassName={css.tabs} tabRootClassName={css.tab} tabs={tabs} />;

    return (
      <Page
        className={css.root}
        scrollingDisabled={scrollingDisabled}
        contentType="website"
        description={schemaDescription}
        title={schemaTitle}
        robots='index'
        facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
        twitterImages={[
          { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
        ]}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'WebPage',
          description: schemaDescription,
          name: schemaTitle,
          image: [schemaImage],
        }}
      >
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div className={css.aboutVegshelfContainer}>
              <div className={css.tabContainer}>
                {nav}
              </div>
              <div className={css.informationContainer}>
                {pages[tab]}
              </div>
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
};

CompanyComponent.defaultProps = {
  currentUserListing: null,
  currentUserListingFetched: false,
};

CompanyComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};
const mapStateToProps = state => {
  const { currentUser } = state.user;
  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser
  };
};



const Company = compose(
  withRouter,
  connect(mapStateToProps),
  injectIntl
)(CompanyComponent);

export default Company;
