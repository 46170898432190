// This function is for adding brand name, product name, etc. to URL

export const slugify = string => {
    // Trim whitespace from the beginning and end of the stringing
    string = string.trim();
  
    // Convert the stringing to lowercase
    string = string.toLowerCase();

    // Replace & with and
    string = string.replace(/&/g, 'and');
  
    // Remove accents and special characters
    string = string.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  
    // Replace invalid characters with an empty stringing
    string = string.replace(/[^a-z0-9 -]/g, '');
  
    // Replace whitespace and multiple dashes with a single dash
    string = string.replace(/\s+/g, '-').replace(/-+/g, '-');
  
    return string;
  };