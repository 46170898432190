import { bool, object } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import facebookImage from "../../assets/VegshelfFacebook-1200x630.png";
import twitterImage from "../../assets/VegshelfTwitter-600x314.png";
import {
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Page,
  TabNav,
} from "../../components";
import config from "../../config";
import { SupplierApprovedOrdersPage, SupplierOrderRequestsPage, TopbarContainer } from "../../containers";
import { isScrollingDisabled } from "../../ducks/UI.duck";
import { FormattedMessage, injectIntl, intlShape } from "../../util/reactIntl";
import { propTypes } from "../../util/types";
import { localePart, storedLocale } from '../../components/Wrapper/ClassWrapper';
import css from "./SupplierOrdersAndRequestsPage.module.css";

export class SupplierOrdersAndRequestsPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { stepIndex: 0 };
    this.newSupplier = null;
  }

  render() {
    const { intl, scrollingDisabled } = this.props;
    let { tab } = this.props;
    tab = tab || "orders";
    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage({ id: "SupplierOrdersAndRequestsPage.schemaTitle" }, { siteTitle });
    const schemaDescription = intl.formatMessage({ id: "SupplierOrdersAndRequestsPage.schemaDescription" }, { siteTitle });
    const schemaImage = `${config.canonicalRootURL}${facebookImage}`;
    const locale = storedLocale() || localePart();
    const pages = {
      "orders": (<SupplierApprovedOrdersPage {...this.props} />),
      "requests": (<SupplierOrderRequestsPage  {...this.props} />),
    };
    const tabs = [
      {
        text: (
          <span>
            <FormattedMessage id="SupplierOrderTabPage.orderTabOrders" />
          </span>
        ),
        selected: tab === "orders",
        linkProps: {
          name: "SupplierApprovedOrdersPage",
          params: { locale: locale }
        },
      },
      {
        text: (
          <span>
            <FormattedMessage id="SupplierOrderTabPage.orderTabRequests" />
          </span>
        ),
        selected: tab === "requests",
        linkProps: {
          name: "SupplierOrderRequestsPage",
          params: { locale: locale }
        },
      },
    ];

    return (
      <Page
        className={css.root}
        scrollingDisabled={scrollingDisabled}
        contentType="website"
        description={schemaDescription}
        title={schemaTitle}
        robots='noindex'
        facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
        twitterImages={[
          { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
        ]}
        schema={{
          "@context": "http://schema.org",
          "@type": "WebPage",
          description: schemaDescription,
          name: schemaTitle,
          image: [schemaImage],
        }}
      >
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div className={css.ordersPageContainer}>
              <div className={css.ordersSection}>
                <TabNav 
                rootClassName={css.tabs} 
                tabRootClassName={css.tab} 
                tabs={tabs} />
                <div className={css.pageContainer}>
                  <div className={css.pageContent}>
                    {pages[tab]}
                  </div>
                </div>
              </div>
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

SupplierOrdersAndRequestsPageComponent.defaultProps = {
  currentUserListing: null,
  currentUserListingFetched: false,
};

SupplierOrdersAndRequestsPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
  // from withRouter
  history: object.isRequired,
  location: object.isRequired,
  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = (state) => {
  const {
    orderRequestsLoadError,
    orderRequestsLoadInProgress,
    orderRequests,
    ordersLoadError,
    ordersLoadInProgress,
    orders,
  } = state.SupplierOrdersAndRequestsPage;

  return {
    scrollingDisabled: isScrollingDisabled(state),
    orderRequestsLoadError,
    orderRequestsLoadInProgress,
    orderRequests,
    ordersLoadError,
    ordersLoadInProgress,
    orders,
  };
};

const mapDispatchToProps = (/*dispatch*/) => ({});

const SupplierOrdersAndRequestsPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
)(SupplierOrdersAndRequestsPageComponent);

export default SupplierOrdersAndRequestsPage;
