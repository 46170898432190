export const tableColumns = [
  { key: "invoiceDate", name: "Invoice date" },
  { key: "invoiceNumber", name: "Invoice nr." },
  { key: "supplier", name: "Supplier" },
  { key: "status", name: "Status" },
  { key: "view/print", name: " " },
  { key: "download", name: " " },
];

export const fakeInvoice = {
  orderDate: "Oct 13, 2021",
  invoiceNumber: "10000135",
  invoiceHref: "/",
  customer: "Supplier Company’s Legal Name (Supplier Brand Name)",
  orderNumber: "61313f0c-9d3e-47c0-8e1c-bbfa85db6e97",
  paymentMethod: "****6600",
  status: "Paid",
  total: "€3638.00",
};
