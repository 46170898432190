import React from 'react';
import { NamedLink }from '../components';
import config from '../config';
import { localePart,storedLocale } from '../components/Wrapper/ClassWrapper';

const RegistrationApproved = props => {
    const { name, code } = props;
    const language = storedLocale() || localePart();
    const rootURL = config.canonicalRootURL;
    const root = rootURL ? rootURL.replace(/\/$/, '') : '';
    const url = `${root}/${language}/registration-request/${code}`;
    return (<div>
       Hey {name},
       <br/>
       <br/>
       Welcome to Vegshelf - your registration on our online wholesale marketplace & exclusive community has been approved!
       <br/>
       Follow the link below to set up your password and log in to your Vegshelf account:
       <br/>
       <a href={url}>Set up password</a>
       <br/>
       <br/>
       Best regards,
       <br/>
       The Vegshelf team
    </div>);
};
export default RegistrationApproved;