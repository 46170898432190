import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Switch, Route, withRouter } from 'react-router-dom';
import { NotFoundPage } from './containers';
import { NamedRedirect } from './components';
import { locationChanged } from './ducks/Routing.duck';
import { propTypes } from './util/types';
import * as log from './util/log';
import { canonicalRoutePath } from './util/routes';
import routeConfiguration from './routeConfiguration';
import { logout } from "./ducks/Auth.duck";
import { localePart, storedLocale } from './components/Wrapper/ClassWrapper';

const locale = storedLocale() || localePart();

const { arrayOf, bool, object, func, shape, string } = PropTypes;
// const ONE_MINUTE_IN_MILLISECONDS = 60000;
// const FIVE_MINUTES_IN_MILLISECONDS = 300000;
const THIRTY_MINUTES_IN_MILLISECONDS = 1800000;
const LOGOUT_TIMER_ID = "LOGOUT_TIMER_ID";

const canShowComponent = (props) => {
  const { isAuthenticated, route } = props;
  const { auth } = route;

  return (!auth || isAuthenticated);
};

const callLoadData = (props) => {
  const { match, location, route, dispatch, logoutInProgress } = props;
  const { loadData, name } = route;
  const shouldLoadData =
    typeof loadData === 'function' && canShowComponent(props) && !logoutInProgress;

  if (shouldLoadData) {
    dispatch(loadData(match.params, location.search))
      .then(() => {
        // eslint-disable-next-line no-console
        console.log(`loadData success for ${name} route`);
      })
      .catch(e => {
        log.error(e, 'load-data-failed', { routeName: name });
      });
  }
};

const setPageScrollPosition = (location) => {
  if (!location.hash) {
    // No hash, scroll to top
    window.scroll({
      top: 0,
      left: 0,
    });
  } else {
    const el = document.querySelector(location.hash);
    if (el) {
      // Found element with the given fragment identifier, scrolling
      // to that element.
      //
      // NOTE: This isn't foolproof. It works when navigating within
      // the application between pages and within a single page. It
      // also works with the initial page load. However, it doesn't
      // seem work work properly when refreshing the page, at least
      // not in Chrome.
      //
      // TODO: investigate why the scrolling fails on refresh
      el.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      });
    }
  }
};

const handleLocationChanged = (dispatch, location) => {
  setPageScrollPosition(location);
  const path = canonicalRoutePath(routeConfiguration(), location);
  dispatch(locationChanged(location, path));
};

/**
 * RouteComponentRenderer handles loadData calls on client-side.
 * It also checks authentication and redirects unauthenticated users
 * away from routes that are for authenticated users only
 * (aka "auth: true" is set in routeConfiguration.js)
 *
 * This component is a container: it needs to be connected to Redux.
 */
class RouteComponentRenderer extends Component {
  constructor(props) {
    super(props);
    this.logoutTimerAction = this.logoutTimerAction.bind(this);
  }

  logoutTimerAction() {
    const sessionStorageTimerId = sessionStorage.getItem(LOGOUT_TIMER_ID);

    if (sessionStorageTimerId) {
      clearTimeout(Number(sessionStorageTimerId));
      sessionStorage.removeItem(LOGOUT_TIMER_ID);
    }

    if (this.props.isAuthenticated) {
      const logoutTimerId = setTimeout(() => {
        if (!this.props.logoutInProgress) {
          this.props.dispatch(logout()).then(() => window.location.assign("/" + locale));
        }
      }, THIRTY_MINUTES_IN_MILLISECONDS);

      sessionStorage.setItem(LOGOUT_TIMER_ID, logoutTimerId.toString());
    }
  }

  componentDidMount() {
    // Calling loadData on initial rendering (on client side).
    callLoadData(this.props);
    handleLocationChanged(this.props.dispatch, this.props.location);
    this.logoutTimerAction();
  }

  componentDidUpdate(prevProps) {
    // Call for handleLocationChanged affects store/state and it generates an unnecessary update.
    if (prevProps.location !== this.props.location) {
      // Calling loadData after initial rendering (on client side).
      // This makes it possible to use loadData as default client side data loading technique.
      // However it is better to fetch data before location change to avoid "Loading data" state.
      callLoadData(this.props);
      handleLocationChanged(this.props.dispatch, this.props.location);
      console.log("Updating");
      this.logoutTimerAction();
    }
  }

  render() {
    const {
      route,
      match,
      location,
      staticContext,
      currentUserType,
      currentUser,
      canCheckAccess,
      hasAccess,
      categoryAccess,
    } = this.props;
    const { component: RouteComponent, authPage = 'LoginPage', name } = route;
    const { authUserType } = route;
    const { categoriesUserType } = route;
    const { supplierRegistrationCheck } = route;
    const { buyerRegistrationCheck } = route;
    let canShow = canShowComponent(this.props);
    let redirectName = authPage;
    let urlParams = match.params;
    if (!canShow) {
      staticContext.unauthorized = true;
    }
    if (authUserType) {
      canShow = canShow && (!currentUser || authUserType == currentUserType);
      redirectName = "AccessDeniedPage";
    }
    if (categoriesUserType) {
      canShow = canShow && (currentUser?.attributes?.profile?.privateData?.enableCategorySectionAdmin === true);
      redirectName = "AccessDeniedPage";
    }
    if (supplierRegistrationCheck) {
      canShow = canShow && (currentUser?.attributes?.profile?.privateData?.enableRegistrationRequestsModerator === true);
      redirectName = "AccessDeniedPage";
    }
    if (buyerRegistrationCheck) {
      canShow = canShow && (currentUser?.attributes?.profile?.privateData?.enableRegistrationRequestsModerator === true);
      redirectName = "AccessDeniedPage";
    }
    if (canCheckAccess === true && hasAccess === false && name != 'CheckClientIdPage') {
      canShow = false;
      redirectName = "CheckClientIdPage";
      urlParams = { returnPath: encodeURIComponent(location.pathname) };
    }
    return canShow ? (
      <RouteComponent params={urlParams} location={location}/>
    ) : (
      <NamedRedirect
        name={redirectName} params={urlParams}
        state={{ from: `${location.pathname}${location.search}${location.hash}` }}
      />
    );
  }
}

RouteComponentRenderer.defaultProps = { staticContext: {} };

RouteComponentRenderer.propTypes = {
  isAuthenticated: bool.isRequired,
  logoutInProgress: bool.isRequired,
  route: propTypes.route.isRequired,
  match: shape({
    params: object.isRequired,
    url: string.isRequired,
  }).isRequired,
  location: shape({
    search: string.isRequired,
  }).isRequired,
  staticContext: object,
  dispatch: func.isRequired,
  currentUserType: string,
  currentUser: propTypes.currentUser,
  categoryAccess: bool
};

const mapStateToProps = (state) => {
  const { isAuthenticated, logoutInProgress } = state.Auth;
  const { canCheckAccess, hasAccess } = state.CheckClientIdPage;
  const { currentUser, currentUserType, categoryAccess } = state.user;

  return { isAuthenticated, logoutInProgress, currentUserType, currentUser, canCheckAccess, hasAccess, categoryAccess };
};

const RouteComponentContainer = compose(connect(mapStateToProps))(RouteComponentRenderer);

/**
 * Routes component creates React Router rendering setup.
 * It needs routeConfiguration (named as "routes") through props.
 * Using that configuration it creates navigation on top of page-level
 * components. Essentially, it's something like:
 * <Switch>
 *   <Route render={pageA} />
 *   <Route render={pageB} />
 * </Switch>
 */
const Routes = (props, context) => {
  const { isAuthenticated, logoutInProgress, routes } = props;

  const toRouteComponent = (route) => {
    const renderProps = {
      isAuthenticated,
      logoutInProgress,
      route,
    };

    // By default, our routes are exact.
    // https://reacttraining.com/react-router/web/api/Route/exact-bool
    const isExact = route.exact != null ? route.exact : true;

    return (
      <Route
        key={route.name}
        path={route.path}
        exact={isExact}
        render={matchProps => (
          <RouteComponentContainer
            {...renderProps}
            match={matchProps.match}
            location={matchProps.location}
            staticContext={matchProps.staticContext}
          />
        )}
      />
    );
  };

  // N.B. routes prop within React Router needs to stay the same,
  // so that React is is not re-rendering page component.
  // That's why we pass-in props.routes instead of calling routeConfiguration here.
  return (
    <Switch>
      {routes.map(toRouteComponent)}
      <Route component={NotFoundPage}/>
    </Switch>
  );
};

Routes.propTypes = {
  routes: arrayOf(propTypes.route).isRequired,
};

export default withRouter(Routes);
