import classNames from "classnames";
import { bool, object } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { TopbarContainer } from "..";
import facebookImage from "../../assets/VegshelfFacebook-1200x630.png";
import twitterImage from "../../assets/VegshelfTwitter-600x314.png";
import emptyStateImage from "../../assets/emoji-neutral-ebe8e7.svg";
import addProductIconWhite from "../../assets/plus-ffffff.svg";
import defaultImage from "../../assets/image-ebe8e7.svg";
import {
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Menu,
  MenuContent,
  MenuItem,
  MenuLabel,
  NamedLink,
  Page,
  TabNav,
} from "../../components";
import LoaderComponent from "../../components/Loader/Loader";
import EmptyStateComponent from "../../components/ProductEmptyState/ProductEmptyState";
import config from "../../config";
import {
  hideNotification as hideNotificationSupplierCompany,
  parseListingState,
  ProductStates,
  ProductStatesTranslates,
  supplierLoadCompanyProducts,
  updateProductState,
} from "../../ducks/supplier-company.duck";
import { isScrollingDisabled } from "../../ducks/UI.duck";
import ProductDictionaries from "../../translations/productDictionaries";
import { FormattedMessage, injectIntl, intlShape } from "../../util/reactIntl";
import { propTypes } from "../../util/types";
import {
  hideNotification as hideNotificationSupplierAddProductPage,
  saveDraftListing,
} from "../SupplierAddProductPage/SupplierAddProductPage.duck";
import { pathByRouteName } from "../../util/routes";
import routeConfiguration from "../../routeConfiguration";
import { localePart, storedLocale } from "../../components/Wrapper/ClassWrapper";
import css from "./SupplierProductsPage.module.css";

const locale = storedLocale() || localePart();

function formattedDate(date) {
  if (!date) {
    return null;
  }
  let year = date.getFullYear();
  let month = (1 + date.getMonth()).toString().padStart(2, "0");
  let day = date.getDate().toString().padStart(2, "0");

  return day + "." + month + "." + year;
}

// Check if there's requirements on selected type: 'past_due', 'currently_due' etc.
const hasRequirements = (stripeAccountData, requirementType) => {
  return stripeAccountData != null
    && stripeAccountData.requirements
    && Array.isArray(stripeAccountData.requirements[requirementType])
    && stripeAccountData.requirements[requirementType].length > 0;
};

export class SupplierProductsPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showConfirm: false,
      activeProductId: null,
      newProductState: null,
      modalTitle: null,
      modalMessage: null,
      currentState: null,
      createdDateSortEnable: false,
      updateDateSortEnable: false,
      createdDateAsc: false,
      updateDateAsc: false,
      sortCriteria: null,
      viewStatus: null,
      displaySetupPayoutModal: false,
      isOpen: false,
      notificationText: "",
      isDuplicateAction: false,
    };

    this.newSupplier = null;
    this.onOutOfStock = this.onOutOfStock.bind(this);
    this.duplicateProduct = this.duplicateProduct.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.onSortCreatedDate = this.onSortCreatedDate.bind(this);
    this.onSortUpdatedDate = this.onSortUpdatedDate.bind(this);
    this.onStatusFilter = this.onStatusFilter.bind(this);
    this.onAddProduct = this.onAddProduct.bind(this);
    this.resetStatusFilter = this.resetStatusFilter.bind(this);
    this.toggleStatusMenu = this.toggleStatusMenu.bind(this);
    this.atLeastOneShippingCountry = this.props.supplierCurrentCompany?.attributes?.publicData?.shippingFeeCountries
      ?.find((country) => country.isShip);
  }

  toggleStatusMenu(isOpen) {
    this.setState({ isOpen });
  }

  onStatusFilter(state) {
    const newState = state === this.state.viewStatus ? null : state;
    this.setState({ ...this.state, viewStatus: newState });
  }

  resetStatusFilter() {
    this.setState({ viewStatus: null });
  }

  onSortCreatedDate() {
    const ascDirection = !this.state.createdDateAsc;
    let criteria = null;
    if (ascDirection) {
      criteria = (f, s) => {
        const fValue = f.attributes.createdAt;
        const sValue = s.attributes.createdAt;
        if (fValue < sValue) return -1;
        if (fValue > sValue) return 1;
        return 0;
      };
    } else {
      criteria = (f, s) => {
        const fValue = s.attributes.createdAt;
        const sValue = f.attributes.createdAt;
        if (fValue < sValue) return -1;
        if (fValue > sValue) return 1;
        return 0;
      };
    }
    this.setState({
      ...this.state, createdDateSortEnable: true, createdDateAsc: ascDirection,
      updateDateSortEnable: false, updateDateAsc: false, sortCriteria: criteria,
    });
  }

  onSortUpdatedDate() {
    const ascDirection = !this.state.updateDateAsc;
    let criteria = null;
    if (ascDirection) {
      criteria = (f, s) => {
        const fValue = f?.attributes?.publicData?.updateAt ? new Date(
          Date.parse(f?.attributes?.publicData?.updateAt)) : f.attributes.createdAt;
        const sValue = s?.attributes?.publicData?.updateAt ? new Date(
          Date.parse(s?.attributes?.publicData?.updateAt)) : s.attributes.createdAt;
        if (fValue < sValue) return -1;
        if (fValue > sValue) return 1;
        return 0;
      };
    } else {
      criteria = (f, s) => {
        const sValue = f?.attributes?.publicData?.updateAt ? new Date(
          Date.parse(f?.attributes?.publicData?.updateAt)) : f.attributes.createdAt;
        const fValue = s?.attributes?.publicData?.updateAt ? new Date(
          Date.parse(s?.attributes?.publicData?.updateAt)) : s.attributes.createdAt;
        if (fValue < sValue) return -1;
        if (fValue > sValue) return 1;
        return 0;
      };
    }
    this.setState({
      ...this.state, updateDateSortEnable: true, updateDateAsc: ascDirection,
      createdDateSortEnable: false, createdDateAsc: false, sortCriteria: criteria,
    });
  }

  onAddProduct(stripeConnected) {
    const addProductPageName = "SupplierAddProductPage";
    const params = { locale }
    const addProductPagePath = pathByRouteName(addProductPageName, routeConfiguration(), params)

    if (stripeConnected && this.atLeastOneShippingCountry) {
      //this.props.history.push("/supplier/add-product");
      this.props.history.push(addProductPagePath);
    } else {
      this.setState({ displaySetupPayoutModal: true });
    }
  }

  duplicateProduct(product) {
    this.setState({ notificationText: <FormattedMessage id="SupplierProductsPage.copyListing.notification" />, isDuplicateAction: true }, () => {
      this.props.onSaveDraft({ ...product.attributes.publicData, notificationText: this.state.notificationText });
    });
  }

  onOutOfStock(productId, currentState) {
    this.setState({
      showConfirm: true,
      activeProductId: productId,
      newProductState: ProductStates.outOfStock,
      currentState: currentState,
      modalTitle: <FormattedMessage id="SupplierProductsPage.outOfStock.title" />,
      modalMessage: <FormattedMessage id="SupplierProductsPage.outOfStock.message" />,
      notificationText: <FormattedMessage id="SupplierProductsPage.outOfStock.notification" />,
    });
  }

  onMarkAvailable(productId, currentState) {
    this.setState({
      showConfirm: true,
      activeProductId: productId,
      newProductState: ProductStates.published,
      currentState: currentState,
      modalTitle: <FormattedMessage id="SupplierProductsPage.available.title" />,
      modalMessage: <FormattedMessage id="SupplierProductsPage.available.message" />,
      notificationText: <FormattedMessage id="SupplierProductsPage.available.notification" />,
    });
  }

  onDelete(productId, currentState) {
    this.setState({
      showConfirm: true,
      activeProductId: productId,
      newProductState: ProductStates.closed,
      modalTitle: <FormattedMessage id="SupplierProductsPage.delete.title" />,
      currentState: currentState,
      modalMessage: <FormattedMessage id="SupplierProductsPage.delete.message" />,
      notificationText: <FormattedMessage id="SupplierProductsPage.delete.notification" />,
      actionType: "delete",
    });
  }

  onCancel() {
    this.setState({
      ...this.state, showConfirm: false,
      activeProductId: null,
      newProductState: null,
      modalTitle: null,
      modalMessage: null,
      currentState: null,
    });
  }

  onConfirm() {
    this.props.onHideNotificationSupplierCompany();
    this.props.onHideNotificationSupplierAddProductPage();

    this.props.onUpdateProductState({
      productId: this.state.activeProductId,
      state: this.state.newProductState,
      currentState: this.state.currentState,
      notificationText: this.state.notificationText,
    });

    this.onCancel();
  }

  componentDidUpdate() {
    const notificationElement = document.getElementById("notificationMessage");
    if (notificationElement !== null) {
      const { top } = notificationElement.getBoundingClientRect();
      if (top < 0) {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    }
  }

  /*   componentDidMount() {
      window.onclick = (e) => {
        this.setState({ isOpen: false })
      }
    }
   */

  render() {
    const {
      // history,
      intl,
      // location,
      // onSupplierRegistration,
      scrollingDisabled,
      // supplierCurrentCompany,
      supplierCompanyProducts,
      supplierLoadCompanyProductsInProgress,
      stripeAccount,
    } = this.props;

    const dictionaries = ProductDictionaries();
    const categoryDictionary = {};
    dictionaries.categories.forEach(c => {
      categoryDictionary[c.categoryCode] = c.name;
    });

    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage({ id: "SupplierProductsPage.schemaTitle" }, { siteTitle });
    const schemaDescription = intl.formatMessage({ id: "SupplierProductsPage.schemaDescription" }, { siteTitle });
    const schemaImage = `${config.canonicalRootURL}${facebookImage}`;
    let displayItems = [...(supplierCompanyProducts || [])];
    if (this.state.sortCriteria) {
      displayItems.sort(this.state.sortCriteria);
    }
    if (this.state.viewStatus !== null) {
      displayItems = displayItems.filter(p => {
        return parseListingState(p) === this.state.viewStatus;
      });
    }
    const tabs = [
      {
        text: (
          <span>
            <FormattedMessage id="SupplierProductsPage.products" />
          </span>
        ),
        selected: true,
        linkProps: {
          name: "SupplierProductsPage",
        },
      },
    ];
    const hasAnyProducts = displayItems.length > 0;
    const stripeAccountData = stripeAccount?.attributes?.stripeAccountData;
    const requirementsMissing =
      stripeAccount &&
      (hasRequirements(stripeAccountData, "past_due") ||
        hasRequirements(stripeAccountData, "currently_due"));
    const stripeConnected = !!stripeAccount && !!stripeAccount.id && !requirementsMissing;

    const notificationText = this.props.notificationTextSupplierCompany || this.props.notificationTextSupplierAddProductPage;
    const supplierCompanyHasNoProducts = Array.isArray(supplierCompanyProducts) && !supplierCompanyProducts.length;
    const renderNotFoundMessageOrLoader = () => {
      if (supplierCompanyHasNoProducts) {
        /**
         * We are not using a shared component here because of very rare behavior - the onClick function.
         */
        return (
          <div className={css.emptyDataContainer}>
            <img src={emptyStateImage}></img>
            <p className={css.emptyDataTitle}>
              <FormattedMessage id="SupplierProductsPage.createFirstProductResultTitle" />
            </p>
            <p className={css.emptyDataSubTitle}>
              <FormattedMessage id="SupplierProductsPage.createFirstProductResultText" />
            </p>
            <a
              name="SupplierAddProductPage"
              onClick={() => this.onAddProduct(stripeConnected)}
              className={css.addProductButton}
            >
              <img className={css.addProductIconWhite} src={addProductIconWhite}></img>
              <FormattedMessage id="SupplierProductsPage.buttonText" />
            </a>
          </div>
        );
      }
      if (!hasAnyProducts && !supplierLoadCompanyProductsInProgress) {
        const emptyStateOptions = {
          containerOffsetClassName: "listingsOffset",
          iconStylesClassName: "searchIcon",
          titleMessageId: "SupplierProductsPage.noSearchResultTitle",
          textMessageId: "SupplierProductsPage.noSearchResultText",
        };

        return (
          <EmptyStateComponent options={emptyStateOptions} />
        );
      }

      if (hasAnyProducts) return null;

      //return <LoaderComponent className={css.preloader}/>;
    };

    return (
      <Page
        className={css.root}
        scrollingDisabled={scrollingDisabled}
        contentType="website"
        description={schemaDescription}
        title={schemaTitle}
        robots='noindex'
        facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
        twitterImages={[
          { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
        ]}
        schema={{
          "@context": "http://schema.org",
          "@type": "WebPage",
          description: schemaDescription,
          name: schemaTitle,
          image: [schemaImage],
        }}
      >
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div id="profileSettingsContainer" className={css.pageContainer}>
              <div className={css.notificationContainer}>
                {notificationText && (
                  <div id="notificationMessage" className={css.notificationMessageWrapper}>
                    <p>{notificationText}</p>
                  </div>
                )}
              </div>
              <div className={css.productListingsSection}>
                <div onClick={() => this.setState({ isOpen: false })}>
                  <TabNav
                    rootClassName={css.tabs}
                    tabRootClassName={css.tab}
                    tabs={tabs}
                    onClick={this.resetStatusFilter}
                  />
                </div>
                <div className={css.pageContent}>
                  {!supplierCompanyHasNoProducts && (
                    <table className={css.productTable}>
                      <thead>
                        <tr>
                          <th>&nbsp;</th>
                          <th>
                            <FormattedMessage id="SupplierProductsPage.productName" />
                          </th>
                          <th>
                            <a onClick={this.onSortCreatedDate}>
                              <FormattedMessage id="SupplierProductsPage.created" /><span className={css.sortIcon}>&nbsp;</span>
                            </a>
                          </th>
                          <th>
                            <a onClick={this.onSortUpdatedDate}>
                              <FormattedMessage id="SupplierProductsPage.lastUpdated" /><span className={css.sortIcon}>&nbsp;</span>
                            </a>
                          </th>
                          <th>
                            <FormattedMessage id="SupplierProductsPage.category" />
                          </th>
                          <th>
                            <Menu isOpen={this.state.isOpen} onToggleActive={this.toggleStatusMenu}>
                              <MenuLabel>
                                <span className={css.filter}>
                                  <FormattedMessage id="SupplierProductsPage.status" /><span className={css.filterIcon}>&nbsp;</span>
                                </span>
                              </MenuLabel>
                              <MenuContent className={css.filterContent}>
                                <MenuItem key="published" onClick={() => this.toggleStatusMenu(false)}>
                                  <h3
                                    className={classNames(
                                      css.filterStateItem,
                                      { [css.activeStateFilter]: this.viewStatus === ProductStates.published },
                                    )}
                                    onClick={() => this.onStatusFilter(ProductStates.published)}
                                  >
                                    <FormattedMessage id="SupplierProductsPage.published" />
                                  </h3>
                                </MenuItem>
                                <MenuItem key="outOfStock" onClick={() => this.toggleStatusMenu(false)}>
                                  <h3
                                    className={classNames(
                                      css.filterStateItem,
                                      { [css.activeStateFilter]: this.viewStatus === ProductStates.outOfStock },
                                    )}
                                    onClick={() => this.onStatusFilter(ProductStates.outOfStock)}>
                                    <FormattedMessage id="SupplierProductsPage.outOfStock" />
                                  </h3>
                                </MenuItem>
                                <MenuItem key="draft" onClick={() => this.toggleStatusMenu(false)}>
                                  <h3
                                    className={classNames(
                                      css.filterStateItem,
                                      { [css.activeStateFilter]: this.viewStatus === ProductStates.draft },
                                    )}
                                    onClick={() => this.onStatusFilter(ProductStates.draft)}
                                  >
                                    <FormattedMessage id="SupplierProductsPage.draft" />
                                  </h3>
                                </MenuItem>
                                <MenuItem key="pending" onClick={() => this.toggleStatusMenu(false)}>
                                  <h3
                                    className={classNames(
                                      css.filterStateItem,
                                      { [css.activeStateFilter]: this.viewStatus === ProductStates.pending },
                                    )}
                                    onClick={() => this.onStatusFilter(ProductStates.pending)}
                                  >
                                    <FormattedMessage id="SupplierProductsPage.pending" />
                                  </h3>
                                </MenuItem>
                              </MenuContent>
                            </Menu>
                          </th>
                          <th>&nbsp;</th>
                        </tr>
                      </thead>
                      <tbody>
                        {displayItems.map((product) => {
                          let image;
                          const images = product?.attributes?.publicData?.productImages;
                          if (images && images.length > 0) {
                            image = (
                              <div
                                className={css.productImage}
                                style={{ backgroundImage: `url('${images[0].url}')` }}
                              >
                                &pbsp;
                              </div>
                            );
                          } else {
                            image = (
                              <img className={css.defaultImage}
                                src={defaultImage}>
                              </img>
                            );
                          }
                          const state = parseListingState(product);
                          const stateCss = classNames(css.state, css[state]);
                          const category = categoryDictionary[product?.attributes?.publicData?.rootCategory] || "-";
                          const productState = ProductStatesTranslates[state];

                          return (
                            <tr height="100px" key={product.id.uuid}>
                              <td className={css.imageColumn}>{image}</td>
                              <td>{product?.attributes?.title}</td>
                              <td>{formattedDate(product?.attributes?.createdAt)}</td>
                              <td>{formattedDate(product?.attributes?.publicData?.updateAt ? new Date(
                                Date.parse(
                                  product?.attributes?.publicData?.updateAt)) : product?.attributes?.createdAt)}</td>
                              <td>{category}</td>
                              <td>
                                <div className={stateCss}>{productState}</div>
                              </td>
                              <td>
                                <Menu>
                                  <MenuLabel
                                    className={css.manageListingMenuLabel}
                                    isOpenClassName={css.manageListingMenuIsOpen}
                                  >
                                    <FormattedMessage id="SupplierProductsPage.manage" />
                                  </MenuLabel>
                                  <MenuContent className={css.manageListingMenuContent}>
                                    <MenuItem
                                      className={css.menuItem}
                                      key="Add"
                                    >
                                      <NamedLink name="SupplierEditProductPage" params={{ id: product.id.uuid, locale: locale }}>
                                        <FormattedMessage id="SupplierProductsPage.edit" />
                                      </NamedLink>
                                    </MenuItem>
                                    {productState === "out of stock" ? (
                                      <MenuItem
                                        key="Available"
                                        className={classNames(
                                          css.menuItem,
                                          ((product.attributes.state === "draft" || product.attributes.state === "pendingApproval") && css.disabled),
                                        )}
                                        onClick={() => {
                                          if (product.attributes.state === "draft" || product.attributes.state === "pendingApproval") return;
                                          this.onMarkAvailable(product.id.uuid, state);
                                        }}
                                      >
                                        <FormattedMessage id="SupplierProductsPage.markAvailable" />
                                      </MenuItem>
                                    ) : (
                                      <MenuItem
                                        key="Out of stock"
                                        className={classNames(
                                          css.menuItem,
                                          ((product.attributes.state === "draft" || product.attributes.state === "pendingApproval") && css.disabled),
                                        )}
                                        onClick={() => {
                                          if (product.attributes.state === "draft" || product.attributes.state === "pendingApproval") return;
                                          this.onOutOfStock(product.id.uuid, state);
                                        }}
                                      >
                                        <FormattedMessage id="SupplierProductsPage.markOutOfStock" />
                                      </MenuItem>
                                    )}
                                    <MenuItem
                                      className={css.menuItem}
                                      key="Duplicate"
                                      onClick={() => this.duplicateProduct(product, state)}
                                    >
                                      <FormattedMessage id="SupplierProductsPage.duplicate" />
                                    </MenuItem>
                                    <MenuItem
                                      key="Delete"
                                      className={classNames(
                                        css.menuItem,
                                        (product.attributes.state === "pendingApproval" && css.disabled),
                                      )}
                                      onClick={() => {
                                        if (product.attributes.state === "pendingApproval") return;
                                        this.onDelete(product.id.uuid, state);
                                      }}
                                    >
                                      <FormattedMessage id="SupplierProductsPage.delete" />
                                    </MenuItem>
                                  </MenuContent>
                                </Menu>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  )}
                  {renderNotFoundMessageOrLoader()}
                  {this.state.showConfirm ? (
                    <div className={css.confirmDialogContainer}>
                      <div className={css.confirmDialog}>
                        <button className={css.closeXButtonWrapper} autoFocus onClick={() => this.onCancel()} />
                        <h1 className={css.confirmDialogTitle}>{this.state.modalTitle}</h1>
                        <p className={css.confirmDialogContent}>{this.state.modalMessage}</p>
                        <div className={css.confirmDialogButtons}>
                          <a className={css.confirmDialogCancelButton} onClick={() => this.onCancel()}>
                            <FormattedMessage id="SupplierProductsPage.cancel" />
                          </a>
                          <a className={css.confirmDialogConfirmButton} onClick={() => this.onConfirm()}>
                            <FormattedMessage id="SupplierProductsPage.confirm" />
                          </a>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  {this.renderConfirmPayout(intl)}
                </div>
              </div>
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }

  renderConfirmPayout(intl) {
    const { displaySetupPayoutModal } = this.state;
    const setupPaymentSettingsTitleMsg = intl.formatMessage({ id: "StripePayoutPage.setupPaymentSettings" });
    const setupPaymentSettingsDescriptionMsg = intl.formatMessage({ id: "StripePayoutPage.setupPaymentSettingsDescription" });

    return (displaySetupPayoutModal && (
      <div className={css.previewDialogContainer}>
        <div className={css.previewSmallDialog}>
          <div className={css.dialogHeader}>
            <button className={css.closeButton} onClick={() => this.setState({ displaySetupPayoutModal: false })} />
            <h1 className={css.previewDialogTitle}>{setupPaymentSettingsTitleMsg}</h1>
          </div>
          <div className={css.previewDialogContent}>
            <p>{setupPaymentSettingsDescriptionMsg} </p>
            <NamedLink className={css.setupPayoutButton} name="SupplierProfilePayoutDetailsPage">
              <FormattedMessage id="SupplierProductsPage.setUpPayout" />
            </NamedLink>
            <a
              className={css.setupPayoutGoBackButton}
              onClick={() => this.setState({ displaySetupPayoutModal: false })}>
              <FormattedMessage id="SupplierProductsPage.goBack" />
            </a>
          </div>
        </div>
      </div>
    ));
  }
}

SupplierProductsPageComponent.defaultProps = {
  currentUserListing: null,
  currentUserListingFetched: false,
};

SupplierProductsPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
  // from withRouter
  history: object.isRequired,
  location: object.isRequired,
  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = (state) => {
  const {
    supplierCurrentCompany,
    supplierCompanyProducts,
    supplierLoadCompanyProductsInProgress,
  } = state.supplierCompany;
  const notificationTextSupplierCompany = state.supplierCompany.notificationText;
  const {
    supplierListingSavedDraft,
    supplierListingSaveDraftProgress,
    supplierListingPublishProgress,
    supplierListingPublished,
  } = state.SupplierAddProductPage;
  const notificationTextSupplierAddProductPage = state.SupplierAddProductPage.notificationText;
  const { stripeAccount } = state.stripeConnectAccount;

  return {
    scrollingDisabled: isScrollingDisabled(state),
    supplierCurrentCompany,
    supplierCompanyProducts,
    supplierLoadCompanyProductsInProgress,
    supplierListingSavedDraft,
    supplierListingSaveDraftProgress,
    supplierListingPublishProgress,
    supplierListingPublished,
    stripeAccount,
    notificationTextSupplierCompany,
    notificationTextSupplierAddProductPage,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onUpdateProductState: (values) => dispatch(updateProductState(values)),
  onSaveDraft: (values) => dispatch(saveDraftListing(values)),
  onHideNotificationSupplierCompany: () => dispatch(hideNotificationSupplierCompany()),
  onHideNotificationSupplierAddProductPage: () => dispatch(hideNotificationSupplierAddProductPage()),
});

const SupplierProductsPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
)(SupplierProductsPageComponent);

SupplierProductsPage.loadData = () => (dispatch/*, getState*/) => {
  return Promise.all([dispatch(supplierLoadCompanyProducts())]);
};

export default SupplierProductsPage;