// This is duck file is for limiting shopping cart to single brand

// ================ Action types ================ //

export const MODAL_SINGLE_PRODUCT_CART_DIALOG = 'app/CartModal/visible';

// ================ Reducer ================ //

const initialState = {
  showSingleProductCartDialog: false
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case MODAL_SINGLE_PRODUCT_CART_DIALOG:
      return {
        ...state,
        showSingleProductCartDialog: payload.state
      };
    default:
      return state;
  }
}

// ================ Action creators ================ //

export const displaySingleProductCartDialog = (state) => ({ type: MODAL_SINGLE_PRODUCT_CART_DIALOG, payload: { state } });