import pick from "lodash/pick";
import { countriesMocks } from "../../components/CountryShippingFee/CountryShippingSection";
import { createStripeAccount, fetchStripeAccount, updateStripeAccount } from "../../ducks/stripeConnectAccount.duck";
import { fetchCurrentUser } from "../../ducks/user.duck";
import getCountryCodes from "../../translations/countryCodes";

// ================ Action types ================ //
// TODO: Names do not match with the real redux storage name (ProfilePayoutDetailsPage).
export const SET_INITIAL_VALUES = "app/StripePayoutPage/SET_INITIAL_VALUES";
export const SAVE_PAYOUT_DETAILS_REQUEST = "app/StripePayoutPage/SAVE_PAYOUT_DETAILS_REQUEST";
export const SAVE_PAYOUT_DETAILS_SUCCESS = "app/StripePayoutPage/SAVE_PAYOUT_DETAILS_SUCCESS";
export const SAVE_PAYOUT_DETAILS_ERROR = "app/StripePayoutPage/SAVE_PAYOUT_DETAILS_ERROR";

export const SHIPPING_FEE_UPDATE_REQUEST = "app/StripePayoutPage/UPDATE_REQUEST";
export const SHIPPING_FEE_UPDATE_SUCCESS = "app/StripePayoutPage/UPDATE_SUCCESS";
export const SHIPPING_FEE_UPDATE_ERROR = "app/StripePayoutPage/UPDATE_ERROR";

// ================ Reducer ================ //
const initialState = {
  payoutDetailsSaveInProgress: false,
  payoutDetailsSaved: false,
  fromReturnURL: false,
  shippingFeeSaveInProgress: false,
  shippingFeeCountries: countriesMocks,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };
    case SAVE_PAYOUT_DETAILS_REQUEST:
      return { ...state, payoutDetailsSaveInProgress: true };
    case SAVE_PAYOUT_DETAILS_ERROR:
      return { ...state, payoutDetailsSaveInProgress: false };
    case SAVE_PAYOUT_DETAILS_SUCCESS:
      return { ...state, payoutDetailsSaveInProgress: false, payoutDetailsSaved: true };
    case SHIPPING_FEE_UPDATE_REQUEST:
      return { ...state, shippingFeeSaveInProgress: true };
    case SHIPPING_FEE_UPDATE_SUCCESS:
      return { ...state, shippingFeeSaveInProgress: false, shippingFeeCountries: [...payload] };
    case SHIPPING_FEE_UPDATE_ERROR:
      return { ...state, shippingFeeSaveInProgress: false };
    default:
      return state;
  }
}

// ================ Action creators ================ //
export const setInitialValues = (initialValues) => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});
export const savePayoutDetailsRequest = () => ({ type: SAVE_PAYOUT_DETAILS_REQUEST });
export const savePayoutDetailsSuccess = () => ({ type: SAVE_PAYOUT_DETAILS_SUCCESS });
export const savePayoutDetailsError = () => ({ type: SAVE_PAYOUT_DETAILS_ERROR });

export const saveShippingFeeRequest = () => ({ type: SHIPPING_FEE_UPDATE_REQUEST });
export const saveShippingFeeSuccess = (shippingFeeCountries) => ({
  type: SHIPPING_FEE_UPDATE_SUCCESS,
  payload: shippingFeeCountries,
});
export const saveShippingFeeError = () => ({ type: SHIPPING_FEE_UPDATE_ERROR });

// ================ Thunks ================ //
export const savePayoutDetails = (values, isUpdateCall) => (dispatch) => {
  dispatch(savePayoutDetailsRequest());

  const upsertThunk = isUpdateCall ? updateStripeAccount : createStripeAccount;
  const payoutModel = { ...values, accountType: "company" };

  return dispatch(upsertThunk(payoutModel, { expand: true }))
    .then((response) => {
      dispatch(savePayoutDetailsSuccess());
      return response;
    })
    .catch(() => dispatch(savePayoutDetailsError()));
};

export const loadData = () => (dispatch, getState, sdk) => {
  // Clear state so that previously loaded data is not visible in case this page load fails.
  dispatch(setInitialValues());
  dispatch(saveShippingFeeRequest());

  return dispatch(fetchCurrentUser())
    .then(async (response) => {
      const currentUser = getState().user.currentUser;
      const authorId = currentUser.id;
      const shippingFeeCountries = await sdk.listings
        .query({ authorId, pub_dataType: "company" })
        .then((res) => res.data.data[0].attributes.publicData.shippingFeeCountries || countriesMocks);
      dispatch(saveShippingFeeSuccess(shippingFeeCountries));

      if (currentUser && currentUser.stripeAccount) {
        dispatch(fetchStripeAccount());
      }

      return response;
    });
};

export const updateShippingFees = (shippingFeeCountries) => async (dispatch, getState, sdk) => {
  dispatch(saveShippingFeeRequest());
  const currentCompany = getState().supplierCompany.supplierCurrentCompany;
  const { id, attributes: { deleted, createdAt, state, metadata, publicData, ...rest } } = currentCompany;

  const companyItem = {
    id,
    ...rest,
    publicData: {
      ...publicData,
      shippingFeeCountries,
    },
  };

  return sdk.ownListings
    .update(companyItem)
    .then((response) => {
      dispatch(loadData());
      dispatch(saveShippingFeeSuccess(shippingFeeCountries));
      return response;
    })
    .catch(() => {
      dispatch(saveShippingFeeError());
    });
};
