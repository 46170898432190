import { bool, object } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { PaginationButtons, TransactionViewItem } from "../../components";
import LoaderComponent from "../../components/Loader/Loader";
import EmptyStateComponent from "../../components/ProductEmptyState/ProductEmptyState";
import { transition, TransitionStates } from "../../ducks/transition.duck";
import { isScrollingDisabled } from "../../ducks/UI.duck";
import { injectIntl, intlShape } from "../../util/reactIntl";
import { propTypes } from "../../util/types";
import { LoadOrders } from "../SupplierOrdersAndRequestsPage/SupplierOrdersAndRequestsPage.duck";
import css from "./SupplierApprovedOrdersPage.module.css";

const initialOpenedTransactionIndex = { openedTransactionIndex: -1 };

export class SupplierApprovedOrdersPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { ...initialOpenedTransactionIndex, pageIndex: 1 };
    this.newSupplier = null;
    this.onShowDetailsClick = this.onShowDetailsClick.bind(this);
    this.onTransitionClick = this.onTransitionClick.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
  }

  async onTransitionClick(transaction, newTransition, updatedDate) {
    let params = null;
    if (updatedDate) {
      params = { protectedData: { shippedDate: updatedDate.toISOString() } };
    }

    const transitionItem = {
      id: transaction.id.uuid,
      transitionName: newTransition,
      customerId: transaction?.attributes?.protectedData?.companyId,
      brand: transaction?.attributes?.protectedData?.brandInfo,
      newStatus: TransitionStates.parseState(newTransition),
      args: params,
      pageIndex: this.state.pageIndex,
    };
    await this.props.onTransition(transitionItem);
  }

  onShowDetailsClick(itemIndex) {
    let newIndex = itemIndex;
    if (newIndex === this.state.openedTransactionIndex) {
      newIndex = -1;
    }
    this.setState({ ...this.state, openedTransactionIndex: newIndex });
  }

  onPageChange(page) {
    const newState = { ...this.state, pageIndex: page, ...initialOpenedTransactionIndex };
    this.setState(newState);
    this.props.onLoadPage({ ...(this.props.params || {}), ...newState });
  }

  render() {
    const {
      // ordersLoadError,
      ordersLoadInProgress,
      orders,
      transitionError,
      transitionInProgress,
      supplierCurrentCompany,
    } = this.props;

    const viewOrders = [...(orders?.data || [])];
    const hasAnyData = viewOrders.length > 0;

    const emptyStateOptions = {
      containerOffsetClassName: "orderAndRequestOffset",
      iconStylesClassName: "orderAndRequestIcon",
      titleMessageId: "SupplierOrderPage.noSearchResultTitle",
      textMessageId: "SupplierOrderPage.noSearchResultText",
    };

    const currentPage = this.state.pageIndex;
    const totalPages = this.props.orders?.meta?.totalPages || 1;

    return (
      <div className={css.pageWrapper}>
        {!hasAnyData ? (
          <div className={css.emptyDataContainer}>
            {ordersLoadInProgress ?
              <LoaderComponent />
              :
              <EmptyStateComponent options={emptyStateOptions} />
            }
          </div>
        ) : (
          <>
            {/*<h1 className={css.pageHeader}>Orders</h1>*/}
            <div className={css.pageContent}>
              {supplierCurrentCompany && viewOrders.map((r, idx) => {
                const onShowDetailsClick = () => this.onShowDetailsClick(idx);
                const {
                  companyName,
                  companyVATnumber,
                  companyRegistrationNumber,
                  streetAndNumber,
                  city,
                  postcode,
                  companyRCSnumber,
                  companyShareCapital,
                  country,
                  // Additional value added for subscription based model pilot
                  // pilotUser passed to TransactionViewItem
                  pilotUser,
                } = supplierCurrentCompany?.attributes?.publicData;

                return (
                  <TransactionViewItem
                    key={r.id.uuid}
                    transactionItem={{
                      ...r,
                      supplierInvoiceData: {
                        companyId: supplierCurrentCompany?.id.uuid,
                        companyName,
                        address: streetAndNumber,
                        pin: companyRegistrationNumber,
                        tin: companyVATnumber,
                        city,
                        postcode,
                        companyRCSnumber,
                        companyShareCapital,
                        country,
                        // Additional value added for subscription based model pilot
                        // pilotUser passed to TransactionViewItem for invoice creation validation
                        pilotUser,
                      },
                    }}
                    showDetails={this.state.openedTransactionIndex === idx}
                    canChangeStatus={true}
                    onShowDetailsClick={onShowDetailsClick}
                    onTransitionClick={this.onTransitionClick}
                    transitionError={transitionError}
                    transitionInProgress={transitionInProgress}
                  />
                );
              })}
            </div>
            <div className={css.pagination}>
              <PaginationButtons
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={this.onPageChange}
              />
            </div>
          </>
        )}
      </div>
    );
  }
}

SupplierApprovedOrdersPageComponent.defaultProps = {
  currentUserListing: null,
  currentUserListingFetched: false,
};

SupplierApprovedOrdersPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
  // from withRouter
  history: object.isRequired,
  location: object.isRequired,
  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = (state) => {
  // const { supplierRegistrationError, supplierRegistrationInProgress, supplierRegistered } = state.SupplierApprovedOrdersPage;
  const { transitionError, transitionInProgress } = state.Transition;
  const { supplierCurrentCompany } = state.supplierCompany;

  return {
    scrollingDisabled: isScrollingDisabled(state),
    transitionError,
    transitionInProgress,
    supplierCurrentCompany,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onTransition: (values) => dispatch(transition(values)),
  onLoadPage: (values) => dispatch(LoadOrders(values)),
});

const SupplierApprovedOrdersPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
)(SupplierApprovedOrdersPageComponent);

SupplierApprovedOrdersPage.loadData = (params) => (dispatch /*getState*/) => {
  return dispatch(LoadOrders(params));
};

export default SupplierApprovedOrdersPage;
