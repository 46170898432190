import { localePart, storedLocale } from "../components/Wrapper/ClassWrapper";

const language = storedLocale() || localePart();

const numberOfStores = {
  en: [
    { code: 1, name: "I am new to wholesale" },
    { code: 2, name: "1 - 10" },
    { code: 3, name: "11 - 50" },
    { code: 4, name: "51 - 100" },
    { code: 5, name: "101 - 250" },
    { code: 6, name: "251 - 500" },
    { code: 7, name: "501 - 1000" },
    { code: 8, name: "More than 1000" }
  ],
  de: [
    { code: 1, name: "Ich bin neu im Großhandel" },
    { code: 2, name: "1 - 10" },
    { code: 3, name: "11 - 50" },
    { code: 4, name: "51 - 100" },
    { code: 5, name: "101 - 250" },
    { code: 6, name: "251 - 500" },
    { code: 7, name: "501 - 1000" },
    { code: 8, name: "Mehr als 1000" }
  ]
};

const buyerNumberOfStores = {
  en: [
    { code: 1, name: "1" },
    { code: 2, name: "2 - 5" },
    { code: 3, name: "6 - 10" },
    { code: 4, name: "11 - 50" },
    { code: 5, name: "51 - 100" },
    { code: 6, name: "More than 100" }
  ],
  de: [
    { code: 1, name: "1" },
    { code: 2, name: "2 - 5" },
    { code: 3, name: "6 - 10" },
    { code: 4, name: "11 - 50" },
    { code: 5, name: "51 - 100" },
    { code: 6, name: "Mehr als 100" }
  ]
};

const leadTime = {
  en: [
    { code: 1, name: "1 - 2 business days" },
    { code: 2, name: "3 - 5 business days" },
    { code: 3, name: "6 - 10 business days" },
    { code: 4, name: "11 - 15 business days" },
    { code: 5, name: "16 - 20 business days" },
    { code: 6, name: "21 - 30 business days" }
  ],
  de: [
    { code: 1, name: "1 - 2 Werktage" },
    { code: 2, name: "3 - 5 Werktage" },
    { code: 3, name: "6 - 10 Werktage" },
    { code: 4, name: "11 - 15 Werktage" },
    { code: 5, name: "16 - 20 Werktage" },
    { code: 6, name: "21 - 30 Werktage" }
  ]
};

const employees = {
  en: [
    { code: 1, name: "0 - 1 employees" },
    { code: 2, name: "2 - 10 employees" },
    { code: 3, name: "11 - 50 employees" },
    { code: 4, name: "51 - 100 employees" },
    { code: 5, name: "101 - 200 employees" },
    { code: 6, name: "> 200 employees" }
  ],
  de: [
    { code: 1, name: "0 - 1 Mitarbeiter" },
    { code: 2, name: "2 - 10 Mitarbeiter" },
    { code: 3, name: "11 - 50 Mitarbeiter" },
    { code: 4, name: "51 - 100 Mitarbeiter" },
    { code: 5, name: "101 - 200 Mitarbeiter" },
    { code: 6, name: "> 200 Mitarbeiter" }
  ]
};

const buyerImportants = {
  en: [
    { code: 1, name: "Delivery within a week" },
    { code: 2, name: "Long shelf-life" },
    { code: 3, name: "Sustainable packaging" },
    { code: 4, name: "Gluten-free" },
    { code: 5, name: "Made in Europe" },
    { code: 6, name: "Vegan" },
    { code: 7, name: "Hand-made" },
    { code: 8, name: "Not sold in supermarket" },
    { code: 9, name: "Not sold on Amazon" },
    { code: 10, name: "Local" },
    { code: 11, name: "Organic" },
    { code: 12, name: "Popular on Instagram" }
  ],
  de: [
    { code: 1, name: "Lieferung innerhalb einer Woche" },
    { code: 2, name: "Lange Lagerfähigkeit" },
    { code: 3, name: "Nachhaltige Verpackung" },
    { code: 4, name: "Glutenfrei" },
    { code: 5, name: "Hergestellt in Europa" },
    { code: 6, name: "Vegan" },
    { code: 7, name: "Handgemacht" },
    { code: 8, name: "Nicht im Supermarkt erhältlich" },
    { code: 9, name: "Nicht bei Amazon verkauft" },
    { code: 10, name: "Regional" },
    { code: 11, name: "Bio" },
    { code: 12, name: "Beliebt auf Instagram" }
  ]
};

const businessTypes = {
  en: [
    { code: 1, name: "Physical store" },
    { code: 2, name: "Online shop" },
    { code: 3, name: "Pop-up store" },
    { code: 4, name: "Coffee shop" },
    { code: 5, name: "Other" }
  ],
  de: [
    { code: 1, name: "Einzelhändler" },
    { code: 2, name: "Online Shop" },
    { code: 3, name: "Pop-up-Store" },
    { code: 4, name: "Coffee Shop" },
    { code: 5, name: "Andere" }
  ]
};

const getNumberOfStores = lang => {
  // Add the lnew locale here so that the correct translations will be returned.
  // If locale is unknown or the translation is missing, this will default to english coutnry name.
  const stores = numberOfStores[language] || numberOfStores.en;
  const codes = stores.map(c => {

    return { code: c.code, name: c.name };
  });
  return codes;
};

export default getNumberOfStores;

export const getBuyerNumberOfStores = lang => {
  // Add the lnew locale here so that the correct translations will be returned.
  // If locale is unknown or the translation is missing, this will default to english coutnry name.
  const buyerStores = buyerNumberOfStores[language] || buyerNumberOfStores.en;
  const codes = buyerStores.map(c => {

    return { code: c.code, name: c.name };
  });
  return codes;
};

export const getLeadTime = lang => {
  // Add the lnew locale here so that the correct translations will be returned.
  // If locale is unknown or the translation is missing, this will default to english coutnry name.
  const leadTimes = leadTime[language] || leadTime.en;
  const codes = leadTimes.map(c => {

    return { code: c.code, name: c.name };
  });
  return codes;
};

export const getEmployees = lang => {
  // Add the lnew locale here so that the correct translations will be returned.
  // If locale is unknown or the translation is missing, this will default to english coutnry name.
  const employeeNr = employees[language] || employees.en;
  const codes = employeeNr.map(c => {

    return { code: c.code, name: c.name };
  });
  return codes;
};

export const getBuyerImportants = () => {
  const buyerValues = buyerImportants[language] || buyerImportants.en;
  const codes = buyerValues.map(c => {

    return { code: c.code, name: c.name };
  });
  return codes;
};

export const getBuyerBusinessTypes = () => {
  const business = businessTypes[language] || businessTypes.en;
  const codes = business.map(c => {

    return { code: c.code, name: c.name };
  });
  return codes;
};

