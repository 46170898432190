import classNames from "classnames";
import { bool, object } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import facebookImage from "../../assets/VegshelfFacebook-1200x630.png";
import twitterImage from "../../assets/VegshelfTwitter-600x314.png";
import {
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  NamedLink,
  Page,
  TabNav,
} from "../../components";
import EmptyStateComponent from "../../components/ProductEmptyState/ProductEmptyState";
import config from "../../config";
import { TopbarContainer } from "../../containers";
import { loadContacts, loadMessages, sendMessage } from "../../ducks/messages.duck";
import { isScrollingDisabled } from "../../ducks/UI.duck";
import { FormattedMessage, injectIntl, intlShape } from "../../util/reactIntl";
import { propTypes } from "../../util/types";
import { storedLocale, localePart } from "../../components/Wrapper/ClassWrapper";
import css from "./SupplierMessagesPage.module.css";
import LoaderComponent from "../../components/Loader/Loader";

const locale = storedLocale() || localePart();

function formatShortDateString(date) {
  const currentDate = new Date();
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  if (currentDate.getFullYear() === date.getFullYear()) {
    return `${monthNames[date.getMonth()]} ${date.getDate()}`;
  } else {
    return `${monthNames[date.getMonth()]} ${date.getDate()}(${date.getFullYear()})`;
  }
}

export class SupplierMessagesPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { messageText: null };
    this.onSelect = this.onSelect.bind(this);
    this.onMessageChange = this.onMessageChange.bind(this);
    this.onSendClick = this.onSendClick.bind(this);
  }

  onSendClick() {
    this.props.onSendMessage({ message: this.state.messageText });
    this.setState({ ...this.state, messageText: null });
    this.txtMessage.value = "";
  }

  onMessageChange(e) {
    this.setState({ ...this.state, messageText: e.target.value });
  }

  onSelect(contact) {
    if (contact?.id === this.props?.activeContact?.id) {
      return;
    }
    this.props.onSelectActive({ contact: contact });
  }

  scrollToMyRef = () => {
    if (!this.chatContainer) {
      return;
    }

    const scroll =
      (this.chatContainer?.scrollHeight || 0) -
      (this.chatContainer?.clientHeight || 0);
    this.chatContainer.scrollTo(0, scroll);
  };

  componentDidMount() {
    this.scrollToMyRef();
  }

  componentDidUpdate() {
    this.scrollToMyRef();
  }

  render() {
    const {
      // history,
      intl,
      // location,
      scrollingDisabled,
      messageContacts,
      messageContactsInProgress,
      // messageContactsError,
      activeContact,
      activeContactMessages,
      // activeContactMessagesInProgress,
      // activeContactMessagesError,
      // sendingMessage,
      // sendingWithError
    } = this.props;

    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage({ id: "SupplierMessagesPage.schemaTitle" }, { siteTitle });
    const schemaDescription = intl.formatMessage({ id: "SupplierMessagesPage.schemaDescription" }, { siteTitle });
    const schemaImage = `${config.canonicalRootURL}${facebookImage}`;
    const contacts = [...(messageContacts || [])].sort((a, b) => {
      return new Date(b.lastMessageDate) - new Date(a.lastMessageDate);
    });
    const logoActiveStyle = {};
    const canSend = (!!!activeContact) || (!!!this.state.messageText);
    if (activeContact?.logoUrl) {
      logoActiveStyle.backgroundImage = `url('${activeContact.logoUrl}')`;
    }
    const currentUserLogo = {};
    if (activeContact?.currentLogoUrl) {
      currentUserLogo.backgroundImage = `url('${activeContact.currentLogoUrl}')`;
    }

    const messages = [...(activeContactMessages || [])];
    const tabs = [
      {
        text: (
          <span>
            <FormattedMessage id="BuyerMessagesPage.messages" />
          </span>
        ),
        selected: true,
        linkProps: {
          name: "SupplierMessagesPage",
        },
      },
    ];
    const hasAnyData = contacts.length > 0;

    const placeholderText = intl.formatMessage({ id: "BuyerMessagesPage.writeMessage" });

    const emptyStateOptions = {
      containerOffsetClassName: "messagesOffset",
      iconStylesClassName: "messagesIcon",
      titleMessageId: "SupplierMessagesPage.noSearchResultTitle",
      textMessageId: "SupplierMessagesPage.noSearchResultText",
    };

    return (
      <Page
        className={css.root}
        scrollingDisabled={scrollingDisabled}
        contentType="website"
        description={schemaDescription}
        title={schemaTitle}
        robots='noindex'
        facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
        twitterImages={[
          { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
        ]}
        schema={{
          "@context": "http://schema.org",
          "@type": "WebPage",
          description: schemaDescription,
          name: schemaTitle,
          image: [schemaImage],
        }}
      >
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div className={css.pageContainer}>
              <div className={css.messagesSection}>
                <TabNav 
                rootClassName={css.tabs} 
                tabRootClassName={css.tab} 
                tabs={tabs} />
                <div className={css.pageContent}>
                  {messageContactsInProgress ?
                    <LoaderComponent />
                    :
                    hasAnyData ?
                      <div className={css.messagesContainer}>
                        <div className={css.contacts}>
                          {contacts.map((c) => {
                            const logoStyle = {};
                            if (c.logoUrl) {
                              logoStyle.backgroundImage = `url('${c.logoUrl}')`;
                            }
                            const contactCss = classNames(css.contactItem,
                              { [css.contactItemActive]: c.id === activeContact?.id });
                            let msg = c.lastMessageAuthor ? `${c.lastMessageAuthor}: ` : "";
                            if (c.lastMessageContent) msg += c.lastMessageContent;
                            const messageLength = msg.length;
                            msg = msg.substring(0, 40);
                            if (messageLength > 40) {
                              msg += "...";
                            }

                            return (
                              <div key={c.id} className={contactCss} onClick={() => this.onSelect(c)}>
                                <div className={css.contactLogo} style={logoStyle}>
                                  {!c?.logoUrl ?
                                    <span className={css.companyLogoInitials}>
                                      {c.title.charAt(0)}
                                    </span>
                                    : null
                                  }
                                </div>
                                <div className={css.contactInfo}>
                                  <div className={css.contactTitleRow}>
                                    <h5 className={css.contactName}>{c.title}</h5>
                                    <div className={css.contactLastMsgDate}>
                                      {formatShortDateString(c.lastMessageDate)}
                                    </div>
                                  </div>
                                  <div className={css.contactShortMessage}>{msg}</div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                        <div className={css.messages}>
                          <div>
                            <div className={css.mainContactInfo}>
                              <NamedLink
                                name="BuyerDetailsPage"
                                params={{ id: activeContact?.receiverId || "none", locale: locale }}
                                className={css.mainContactLogo}
                                style={logoActiveStyle}
                              >
                                {!activeContact?.logoUrl ?
                                  <span className={css.companyLogoInitials}>
                                    {activeContact?.title.charAt(0)}
                                  </span>
                                  : null
                                }
                              </NamedLink>
                              <NamedLink
                                name="BuyerDetailsPage"
                                params={{ id: activeContact?.receiverId || "none", locale: locale }}
                                className={css.mainContactTitle}
                              >{activeContact?.title}</NamedLink>
                              <h4 className={css.mainContactSubTitle}>
                                {activeContact?.typeOfBusiness}&nbsp;
                                {activeContact?.typeOfBusiness && " | "}&nbsp;
                                {activeContact?.city && `${activeContact?.city}, `}
                                {activeContact?.country}
                              </h4>
                            </div>
                            <div
                              className={css.messageItems}
                              ref={(el) => {
                                this.chatContainer = el;
                              }}
                            >
                              {messages.map((m) => {
                                const keyNode = `${m.type}${m.date.toISOString()}`;
                                if (m.type === "subject") {
                                  return (
                                    <div key={keyNode} className={css.subjectMessageItem}><FormattedMessage id="BuyerMessagesPage.subject" />{m.content}</div>);
                                }
                                if (m.type === "group") {
                                  return (
                                    <div key={keyNode} className={css.groupMessageItem}>
                                      <div className={css.relativeGroupContainer}>
                                        <div className={css.groupDivider} />
                                        <div className={css.groupDate}>
                                          {formatShortDateString(m.date)}
                                        </div>
                                      </div>
                                    </div>
                                  );
                                }
                                return (
                                  <div key={keyNode} className={css.textMessageItem}>
                                    <div
                                      className={css.messageAuthorLogo}
                                      style={m.author === activeContact?.title ? logoActiveStyle : currentUserLogo}
                                    >
                                      {m.author === activeContact?.title ?
                                        <>
                                          {!activeContact?.logoUrl ?
                                            <span>
                                              {m.author.charAt(0)}
                                            </span>
                                            : null
                                          }
                                        </>
                                        :
                                        <>
                                          {!activeContact?.currentLogoUrl ?
                                            <span>
                                              {m.author.charAt(0)}
                                            </span>
                                            : null
                                          }
                                        </>
                                      }
                                    </div>
                                    <div className={css.messageItemContent}>
                                      <h4 className={css.messageItemAuthor}>
                                        {m.author}
                                        <span className={css.messageTime}>
                                          {m.date.toLocaleTimeString("en-US")}
                                        </span>
                                      </h4>
                                      <p className={css.messageItemText}>{m.content}</p>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                          <div className={css.newMessageForm}>
                            <textarea
                              className={css.newMessageInput}
                              maxLength={4000}
                              ref={(el) => {
                                this.txtMessage = el;
                              }}
                              placeholder={placeholderText}
                              onChange={this.onMessageChange}
                            />
                            <div className={css.newMessageButtons}>
                              <button className={css.sendButton} disabled={canSend} onClick={this.onSendClick}>
                                <FormattedMessage id="BuyerMessagesPage.send" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      :
                      <EmptyStateComponent options={emptyStateOptions} />
                  }
                </div>
              </div>
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

SupplierMessagesPageComponent.defaultProps = {
  currentUserListing: null,
  currentUserListingFetched: false,
};

SupplierMessagesPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
  // from withRouter
  history: object.isRequired,
  location: object.isRequired,
  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = (state) => {

  return {
    scrollingDisabled: isScrollingDisabled(state),
    ...state.messageStore,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onSendMessage: (values) => dispatch(sendMessage(values)),
  onSelectActive: (values) => dispatch(loadMessages(values)),
});

const SupplierMessagesPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
)(SupplierMessagesPageComponent);

SupplierMessagesPage.loadData = (args) => (dispatch, getState) => {
  return new Promise((res, rej) => {
    function request() {
      const currentCompanyId = getState()?.supplierCompany?.supplierCurrentCompany?.id?.uuid;
      if (!currentCompanyId) {
        setTimeout(request, 200);
        return;
      }

      const loadModel = { type: "supplier", idViewer: currentCompanyId };
      if (args?.receiver) {
        loadModel.createMessageData = {
          subject: args?.title,
          receiverId: args?.receiver,
        };
      }
      dispatch(loadContacts(loadModel)).then(res, rej);
    }

    request();
  });
};

export default SupplierMessagesPage;
