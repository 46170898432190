import PropTypes from "prop-types";
import React, { useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { FieldTextInput } from "../../components";
import { PrimaryButton } from "../../components/Button/Button";
import { isScrollingDisabled } from "../../ducks/UI.duck";
import { PasswordChangeForm } from "../../forms";
import { LabelWithErrorMsg, ModalWindow } from "../../forms/ContactDetailsForm/ContactDetailsForm";
import { FormattedMessage, injectIntl, intlShape } from "../../util/reactIntl";
import { propTypes } from "../../util/types";
import { changePassword, changePasswordClear, resetPassword } from "./PasswordChangePage.duck";
import css from "./PasswordChangePage.module.css";

export const PasswordChangePageComponent = ({
  changePasswordError,
  changePasswordInProgress,
  currentUser,
  onChange,
  onSubmitChangePassword,
  onResetPassword,
  resetPasswordInProgress,
  resetPasswordError,
  passwordChanged,
  intl,
}) => {
  const pageTitle = intl.formatMessage({ id: "PasswordChangePage.title" });

    // ================ MODAL WINDOW LOGIC ================ //
    const [modalVisible, setModalVisible] = useState(false);
    const showModal = () => {
      // TODO: clear form values
      setModalVisible(true);
    };
    const hideModal = () => {
      // TODO: clear form values
      setModalVisible(false);
    };

  const changePasswordForm = currentUser?.id ?
    <PasswordChangeForm
      changePasswordError={changePasswordError}
      currentUser={currentUser}
      onSubmit={onSubmitChangePassword}
      onChange={onChange}
      onResetPassword={onResetPassword}
      resetPasswordInProgress={resetPasswordInProgress}
      resetPasswordError={resetPasswordError}
      inProgress={changePasswordInProgress}
      ready={passwordChanged}
      hideModal={hideModal}
    /> : <></>;

  return (
    <div className={css.content}>
      <h1 className={css.title}>{pageTitle}</h1>
      <div className={css.wrapper}>
        <span className={css.placeholderText}>
          <FormattedMessage id="PasswordChangePage.passwordPlaceholder" />
        </span>
        <PrimaryButton
          type="submit"
          className={css.changeButton}
          onClick={showModal}
        >
          <FormattedMessage id="PasswordChangePage.buttonText" />
        </PrimaryButton>
      </div>
      <ModalWindow hideModal={hideModal} isVisible={modalVisible} children={changePasswordForm} />
    </div>
  );
};

PasswordChangePageComponent.defaultProps = {
  changePasswordError: null,
  currentUser: null,
  resetPasswordInProgress: false,
  resetPasswordError: null,
};

const { bool, func } = PropTypes;

PasswordChangePageComponent.propTypes = {
  changePasswordError: propTypes.error,
  changePasswordInProgress: bool.isRequired,
  currentUser: propTypes.currentUser,
  onChange: func.isRequired,
  onSubmitChangePassword: func.isRequired,
  passwordChanged: bool.isRequired,
  scrollingDisabled: bool.isRequired,
  resetPasswordInProgress: bool,
  resetPasswordError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = (state) => {
  // Topbar needs user info.
  const {
    changePasswordError,
    changePasswordInProgress,
    passwordChanged,
    resetPasswordInProgress,
    resetPasswordError,
  } = state.PasswordChangePage;
  const { currentUser } = state.user;

  return {
    changePasswordError,
    changePasswordInProgress,
    currentUser,
    passwordChanged,
    scrollingDisabled: isScrollingDisabled(state),
    resetPasswordInProgress,
    resetPasswordError,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onChange: () => dispatch(changePasswordClear()),
  onSubmitChangePassword: (values) => dispatch(changePassword(values)),
  onResetPassword: (values) => dispatch(resetPassword(values)),
});

const PasswordChangePage = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(PasswordChangePageComponent);

export default PasswordChangePage;
