import { bool, object } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import facebookImage from "../../assets/VegshelfFacebook-1200x630.png";
import twitterImage from "../../assets/VegshelfTwitter-600x314.png";
import {
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Page,
} from "../../components";
import config from "../../config";
import { TopbarContainer } from "../../containers";
import { isScrollingDisabled } from "../../ducks/UI.duck";
import { injectIntl, intlShape } from "../../util/reactIntl";
import { propTypes } from "../../util/types";
import css from "./BuyerOrdersPage.module.css";

export class BuyerOrdersPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { stepIndex: 0 };
  }
  render(){
    const {
      // history,
      intl,
      // location,
      scrollingDisabled,
    } = this.props;

    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage({ id: 'BuyerOrdersPage.schemaTitle' }, { siteTitle });
    const schemaDescription = intl.formatMessage({ id: 'BuyerOrdersPage.schemaDescription' });
    const schemaImage = `${config.canonicalRootURL}${facebookImage}`;

    return (
      <Page
        className={css.root}
        scrollingDisabled={scrollingDisabled}
        contentType="website"
        description={schemaDescription}
        title={schemaTitle}
        robots='noindex'
        facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
        twitterImages={[
          { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
        ]}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'WebPage',
          description: schemaDescription,
          name: schemaTitle,
          image: [schemaImage],
        }}
      >
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div className={css.formContainer}>
              <h1>orders page</h1>
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
};

BuyerOrdersPageComponent.defaultProps = {
  currentUserListing: null,
  currentUserListingFetched: false,
};

BuyerOrdersPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
  // from withRouter
  history: object.isRequired,
  location: object.isRequired,
  // from injectIntl
  intl: intlShape.isRequired,
};
const mapStateToProps = state => {
  //const { supplierRegistrationError, supplierRegistrationInProgress, supplierRegistered } = state.BuyerOrdersPage;

  return {
    scrollingDisabled: isScrollingDisabled(state),
   // supplierRegistrationError,
   // supplierRegistrationInProgress,
  //  supplierRegistered
  };
};

const mapDispatchToProps = (/*dispatch*/) => ({});

const BuyerOrdersPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(BuyerOrdersPageComponent);

export default BuyerOrdersPage;
