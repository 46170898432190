import React from "react";

// routeConfiguration needs to initialize containers first
// Otherwise, components will import form container eventually and
// at that point css bundling / imports will happen in wrong order.
import { NamedRedirect } from "./components";
import {
  Company,
  HowItWorksPage,
  AccessDeniedPage,
  ApplyToSellPage,
  AuthenticationPage,
  BuyerApprovedOrdersPage,
  BuyerDetailsPage,
  BuyerInvoicingPage,
  BuyerLandingPage,
  BuyerMessagesPage,
  BuyerMyFavouritesPage,
  BuyerOrderRequestsPage,
  BuyerOrdersAndRequestsPage,
  BuyerOrdersPage,
  BuyerPaymentDetailsPage,
  BuyerProfilePage,
  BuyerRegistrationFormPage,
  BuyerSettingsPage,
  CategorySectionPage,
  CheckClientIdPage,
  CheckoutPage,
  ContactDetailsPage,
  EditListingPage,
  EmailVerificationPage,
  FeatureSearchProductsPage,
  InboxPage,
  LandingPage,
  ListingPage,
  NewSearchProductsPage,
  NotFoundPage,
  PasswordChangePage,
  PasswordRecoveryPage,
  PasswordResetPage,
  PaymentMethodsPage,
  ProductDetailsPage,
  ProfilePage,
  ProfilePayoutDetailsPage,
  ProfileSettingsPage,
  RegistrationCompletePage,
  RegistrationModeratorPage,
  SearchProductsPage,
  ShoppingCartPage,
  StyleguidePage,
  SupplierAddProductPage,
  SupplierApprovedOrdersPage,
  SupplierDetailsPage,
  SupplierInvoicingPage,
  SupplierLandingPage,
  SupplierMessagesPage,
  SupplierOrderRequestsPage,
  SupplierOrdersAndRequestsPage,
  SupplierProductsPage,
  SupplierProfileNotificationsPage,
  SupplierProfilePage,
  SupplierRegistrationFormPage,
  SupplierSettingsPage,
  TransactionPage,
  AboutUsPage,
  BlogPage,
  Blog1Page,
  Blog2Page,
  Blog3Page,
  Blog4Page,
  WhyVeganPage
  // Commented out until function developed further
  //ListingUpdatePage,
} from "./containers";
import { SUPPLIER_INVOICING_TABS } from "./containers/SupplierInvoicingPage/variables";

export const ACCOUNT_SETTINGS_PAGES = [
  'ContactDetailsPage',
  'PasswordChangePage',
  'SupplierSettingsPage',
  'BuyerSettingsPage',
  'PaymentMethodsPage',
];

// https://en.wikipedia.org/wiki/Universally_unique_identifier#Nil_UUID
const draftId = '00000000-0000-0000-0000-000000000000';
const draftSlug = 'draft';

const RedirectToLandingPage = () => <NamedRedirect name="LandingPage" />;
const redirectToImprint = () => <NamedRedirect name="ImprintPage" />;
const redirectToHowItWorks = () => <NamedRedirect name="HowItWorksForSuppliersPage" />;

// NOTE: Most server-side endpoints are prefixed with /api. Requests to those
// endpoints are indented to be handled in the server instead of the browser and
// they will not render the application. So remember to avoid routes starting
// with /api and if you encounter clashing routes see server/index.js if there's
// a conflicting route defined there.

// Our routes are exact by default.
// See behaviour from Routes.js where Route is created.
const routeConfiguration = () => {
  return [
    {
      //path: '/',
      path: '/:locale',
      name: 'LandingPage',
      component: props => <LandingPage {...props} />,
    },

    // 16.07.23 Vegshelf freeze: commented out routes for Vegshelf freeze
    /*{
      //path: '/supplier-main',
      path: '/:locale/supplier-main',
      name: 'SupplierLandingPage',
      component: props => <SupplierLandingPage {...props} />,
      auth: true,
      authPage: 'LoginPage',
      authUserType: 'supplier',
    },
    {
      //path: '/buyer-main',
      path: '/:locale/buyer-main',
      name: 'BuyerLandingPage',
      component: props => <BuyerLandingPage {...props} />,
      auth: true,
      authPage: 'LoginPage',
      authUserType: 'buyer',
    },*/
    {
      //path: '/apply-to-sell',
      path: '/:locale/apply-to-sell',
      name: 'ApplyToSellPage',
      component: props => <ApplyToSellPage {...props} />,
    },
    {
      //path: '/how-it-works',
      path: '/:locale/how-it-works',
      name: 'HowItWorksPage',
      component: redirectToHowItWorks,
    },
    {
      //path: '/how-it-works/for-suppliers',
      path: '/:locale/how-it-works/for-suppliers',
      name: 'HowItWorksForSuppliersPage',
      component: props => <HowItWorksPage tab='for-suppliers' {...props} />,
      //loadData: params => ContactUsPage.loadData(),
    },
    {
      //path: '/how-it-works/for-buyers',
      path: '/:locale/how-it-works/for-buyers',
      name: 'HowItWorksForBuyersPage',
      component: props => <HowItWorksPage tab='for-buyers' {...props} />,
      //loadData: params => ContactUsPage.loadData(),
    },
    {
      //path: '/about-us',
      path: '/:locale/about-us',
      name: 'AboutUsPage',
      component: props => <AboutUsPage {...props} />,
    },
    {
      //path: '/why-plant-based',
      path: '/:locale/why-plant-based',
      name: 'WhyVeganPage',
      component: props => <WhyVeganPage {...props} />,
    },
    {
      //path: '/blog',
      path: '/:locale/blog',
      name: 'BlogPage',
      component: props => <BlogPage {...props} />,
    },
    {
      //path: '/blog/why-shops-need-more-vegan-products-in-assortment',
      path: '/:locale/blog/why-shops-need-more-vegan-products-in-assortment',
      name: 'BlogArticle1',
      component: props => <Blog1Page {...props} />,
    },
    {
      //path: '/blog/why-coffee-shops-need-to-offer-vegan-food-and-drinks',
      path: '/:locale/blog/why-coffee-shops-need-to-offer-vegan-food-and-drinks',
      name: 'BlogArticle2',
      component: props => <Blog2Page {...props} />,
    },
    {
      //path: '/blog/why-independent-retail-is-perfect-for-emerging-brands',
      path: '/:locale/blog/why-independent-retail-is-perfect-for-emerging-brands',
      name: 'BlogArticle3',
      component: props => <Blog3Page {...props} />,
    },
    {
      //path: '/blog/where-to-source-vegan-products-for-business',
      path: '/:locale/blog/where-to-source-vegan-products-for-business',
      name: 'BlogArticle4',
      component: props => <Blog4Page {...props} />,
    },
    {
      //path: '/company',
      path: '/:locale/company',
      name: 'Company',
      component: redirectToImprint,
    },
    {
      //path: '/company/imprint',
      path: '/:locale/company/imprint',
      name: 'ImprintPage',
      component: props => <Company tab='imprint' {...props} />,
      //loadData: params => ContactUsPage.loadData(),
    },
    {
      //path: '/company/cookie-policy',
      path: '/:locale/company/cookie-policy',
      name: 'CookiePolicyPage',
      component: props => <Company tab='cookie-policy' {...props} />,
      //loadData: params => ContactUsPage.loadData(),
    },
    {
      //path: '/company/buyer-terms',
      path: '/:locale/company/buyer-terms',
      name: 'BuyerTermsPage',
      component: props => <Company tab='buyer-terms' {...props} />,
      //loadData: params => ContactUsPage.loadData(),
    },
    {
      //path: '/company/supplier-terms',
      path: '/:locale/company/supplier-terms',
      name: 'SupplierTermsPage',
      component: props => <Company tab='supplier-terms' {...props} />,
      //loadData: params => ContactUsPage.loadData(),
    },
    {
      //path: '/company/contact-us',
      path: '/:locale/company/contact-us',
      name: 'ContactUsPage',
      component: props => <Company tab='contact-us' {...props} />,
      //loadData: params => ContactUsPage.loadData(),
    },
    {
      //path: '/company/privacy-policy',
      path: '/:locale/company/privacy-policy',
      name: 'PrivacyPolicyPage',
      component: props => <Company tab='privacy-policy' {...props} />,
    },
    // TODO - delete page
    /*{
      path: '/l',
      name: 'ListingBasePage',
      component: RedirectToLandingPage,
    },*/
    // Route not used, but listing page part of function in routes.js

    // 16.07.23 Vegshelf freeze: commented out routes for Vegshelf freeze
    /*{
      //path: '/l/:slug/:id',
      path: '/:locale/l/:slug/:id',
      name: 'ListingPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <ListingPage {...props} />,
      loadData: ListingPage.loadData,
    },*/
    // TODO - delete page
    /*{
      path: '/check-access',
      name: 'CheckClientIdPage',
      component: props => <CheckClientIdPage {...props} />
    },*/
    // TODO - delete page
    /*{
      path: '/l/:slug/:id/checkout',
      name: 'CheckoutPage',
      auth: true,
      component: props => <CheckoutPage {...props} />,
      setInitialValues: CheckoutPage.setInitialValues,
    },*/
    // Route not used, but listing page part of function in routes.js

    // 16.07.23 Vegshelf freeze: commented out routes for Vegshelf freeze
    /*{
      //path: '/l/:slug/:id/:variant',
      path: '/:locale/l/:slug/:id/:variant',
      name: 'ListingPageVariant',
      auth: true,
      authPage: 'LoginPage',
      component: props => <ListingPage {...props} />,
      loadData: ListingPage.loadData,
    },
    // Route not used, but should be used as part of canonical path
    {
      //path: '/l/new',
      path: '/:locale/l/new',
      name: 'NewListingPage',
      auth: true,
      authPage: 'LoginPage',
      component: () => (
        <NamedRedirect
          name="EditListingPage"
          params={{ slug: draftSlug, id: draftId, type: 'new', tab: 'description' }}
        />
      ),
    },*/
    // TODO - delete page
    /*{
      path: '/l/:slug/:id/:type/:tab',
      name: 'EditListingPage',
      auth: true,
      component: props => <EditListingPage {...props} allowOnlyOneListing />,
      loadData: EditListingPage.loadData,
    },*/
    // TODO - delete page
    /*{
      path: '/l/:slug/:id/:type/:tab/:returnURLType',
      name: 'EditListingStripeOnboardingPage',
      auth: true,
      component: props => <EditListingPage {...props} />,
      loadData: EditListingPage.loadData,
    },*/
    // Canonical path should be after the `/l/new` path since they
    // conflict and `new` is not a valid listing UUID.
    // Route not used, but listing page part of function in routes.js

    // 16.07.23 Vegshelf freeze: commented out routes for Vegshelf freeze
    /*{
      //path: '/l/:id',
      path: '/:locale/l/:id',
      name: 'ListingPageCanonical',
      auth: true,
      authPage: 'LoginPage',
      component: props => <ListingPage {...props} />,
      loadData: ListingPage.loadData,
    },*/
    // TODO - delete page
    /*{
      path: '/u',
      name: 'ProfileBasePage',
      component: RedirectToLandingPage,
    },*/
    
    // 16.07.23 Vegshelf freeze: commented out routes for Vegshelf freeze
    /*{
      //path: '/u/:id',
      path: '/:locale/u/:id',
      name: 'ProfilePage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <ProfilePage {...props} />,
      loadData: ProfilePage.loadData,
    },*/
    // TODO - delete page
    /*{
      path: '/profile-settings',
      name: 'ProfileSettingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <ProfileSettingsPage {...props} />,
    },*/
    {
      //path: '/access-denied',
      path: '/:locale/access-denied',
      name: 'AccessDeniedPage',
      auth: false,
      component: props => <AccessDeniedPage {...props} />,
    },
    // Note: authenticating with IdP (e.g. Facebook) expects that /login path exists
    // so that in the error case users can be redirected back to the LoginPage
    // In case you change this, remember to update the route in server/api/auth/loginWithIdp.js
        
    // 21.07.23 Vegshelf freeze: added Login link so login page is still accessible
    {
      //path: '/login',
      path: '/:locale/login',
      name: 'LoginPage',
      component: props => <AuthenticationPage {...props} tab="login" />,
    },
    // TODO - delete page
    /*{
      path: '/signup',
      name: 'SignupPage',
      component: props => <AuthenticationPage {...props} tab="signup" />,
    },*/
        
    // 16.07.23 Vegshelf freeze: commented out routes for Vegshelf freeze
    /*{
      //path: '/supplier-registration',
      path: '/:locale/supplier-registration',
      name: 'SupplierRegistrationPage',
      component: props => <SupplierRegistrationFormPage {...props} />,
    },
    {
      //path: '/buyer-registration',
      path: '/:locale/buyer-registration',
      name: 'BuyerRegistrationPage',
      component: props => <BuyerRegistrationFormPage {...props} />,
    },*/
    // TODO - delete page
    /*{
      path: '/confirm',
      name: 'ConfirmPage',
      component: props => <AuthenticationPage {...props} tab="confirm" />,
    },*/
        
    // 21.07.23 Vegshelf freeze: added Login link so login page is still accessible
    {
      //path: '/recover-password',
      path: '/:locale/recover-password',
      name: 'PasswordRecoveryPage',
      component: props => <PasswordRecoveryPage {...props} />,
    },
    // TODO - delete page
    /*{
      path: '/inbox',
      name: 'InboxBasePage',
      auth: true,
      authPage: 'LoginPage',
      component: () => <NamedRedirect name="InboxPage" params={{ tab: 'sales' }} />,
    },*/
        
    // 16.07.23 Vegshelf freeze: commented out routes for Vegshelf freeze
    /*{
      //path: '/category-section',
      path: '/:locale/category-section',
      name: 'CategorySectionPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <CategorySectionPage {...props} />,
      loadData: CategorySectionPage.loadData,
      categoriesUserType: true,
    },*/
    // TODO - delete page
    /*{
      path: '/inbox/:tab',
      name: 'InboxPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <InboxPage {...props} />,
      loadData: InboxPage.loadData,
    },*/
    // TODO - delete page
    /*{
      path: '/order/:id',
      name: 'OrderPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <NamedRedirect name="OrderDetailsPage" params={{ ...props.params }} />,
    },*/
        
    // 16.07.23 Vegshelf freeze: commented out routes for Vegshelf freeze
    /*{
      //path: '/buyer-profile-page',
      path: '/:locale/buyer-profile-page',
      name: 'BuyerProfilePage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <BuyerProfilePage {...props} />,
      authUserType: 'buyer'
    },
    {
      //path: '/buyer-invoicing',
      path: '/:locale/buyer-invoicing',
      name: 'BuyerInvoicingPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <BuyerInvoicingPage tab="invoicing" {...props} />,
      authUserType: 'buyer',
      loadData: BuyerInvoicingPage.loadData
    },
    {
      //path: '/shopping/cart',
      path: '/:locale/shopping/cart',
      name: 'ShoppingCartPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <ShoppingCartPage {...props} />,
      authUserType: 'buyer',
      loadData: ShoppingCartPage.loadData
    },
    {
      //path: '/buyer/my-favourites',
      path: '/:locale/buyer/my-favourites',
      name: 'BuyerMyFavouritesPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <BuyerMyFavouritesPage {...props} />,
      authUserType: 'buyer'
    },
    {
      //path: '/supplier-profile-page',
      path: '/:locale/supplier-profile-page',
      name: 'SupplierProfilePage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <SupplierProfilePage {...props} />,
      authUserType: 'supplier'
    },
    {
      //path: '/supplier-invoicing/order',
      path: '/:locale/supplier-invoicing/order',
      name: 'SupplierOrderInvoicesPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <SupplierInvoicingPage tab="order" {...props} />,
      authUserType: 'supplier',
      loadData: () => SupplierInvoicingPage.loadData({ invoices_type: SUPPLIER_INVOICING_TABS.order }),
    },
    {
      //path: '/supplier-invoicing/commission',
      path: '/:locale/supplier-invoicing/commission',
      name: 'SupplierCommissionInvoicesPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <SupplierInvoicingPage tab="commission" {...props} />,
      authUserType: 'supplier',
      loadData: () => SupplierInvoicingPage.loadData({ invoices_type: SUPPLIER_INVOICING_TABS.commission }),
    },*/
    // Commented out for future implementation
        
    // 16.07.23 Vegshelf freeze: commented out routes for Vegshelf freeze
    /*{
      path: '/buyer-settings/invoicing',
      name: 'BuyerProfileInvoicingPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <BuyerSettingsPage tab='invoicing' {...props} />,
      authUserType: 'buyer'
    },*/
        
    // 16.07.23 Vegshelf freeze: commented out routes for Vegshelf freeze
    /*{
      //path: '/buyer-settings/payment-methods',
      path: '/:locale/buyer-settings/payment-methods',
      name: 'BuyerPaymentDetailsPage',
      auth: true,
      authPage: 'BuyerSettingsPage', // TODO: Duplicates on the line 484.
      component: props => <BuyerSettingsPage tab='payment-details' {...props}/>,
      authUserType: 'buyer',
      loadData: params => BuyerPaymentDetailsPage.loadData({ ...params }),
    },
    {
      //path: '/buyer-settings/delivery-address',
      path: '/:locale/buyer-settings/delivery-address',
      name: 'BuyerProfileDeliveryAddressPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <BuyerSettingsPage tab='delivery-address' {...props}/>,
      authUserType: 'buyer',
    //   loadData: params => BuyerProfileDeliveryAddressPage.loadData({ ...params }),
    },*/
    // Commented out for future implementation
    /*{
      path: '/buyer-settings/payout-details/:returnURLType',
      name: 'BuyerProfilePayoutDetailsUrlPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <BuyerSettingsPage tab='payout-details' {...props} />,
      authUserType: 'buyer',
      loadData: params => ProfilePayoutDetailsPage.loadData({ ...params, supplierMode: false }),
    },*/
        
    // 16.07.23 Vegshelf freeze: commented out routes for Vegshelf freeze
    /*{
      //path: '/buyer-settings/account',
      path: '/:locale/buyer-settings/account',
      name: 'BuyerProfileAccountPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <BuyerSettingsPage tab='account' {...props} />,
      authUserType: 'buyer'
    },
    {
      //path: '/buyer-settings/notifications',
      path: '/:locale/buyer-settings/notifications',
      name: 'BuyerProfileNotificationsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <BuyerSettingsPage tab='notifications' {...props} />,
      loadData: params => SupplierProfileNotificationsPage.loadData(),
      authUserType: 'buyer'
    },
    {
      //path: '/buyer-messages',
      path: '/:locale/buyer-messages',
      name: 'BuyerMessagesPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <BuyerMessagesPage {...props} />,
      authUserType: 'buyer',
      loadData: () => BuyerMessagesPage.loadData()
    },
    {
      //path: '/buyer-send-messages/:receiver/:title',
      path: '/:locale/buyer-send-messages/:receiver/:title',
      name: 'BuyerSendMessagePage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <BuyerMessagesPage {...props} />,
      authUserType: 'buyer',
      loadData: params => BuyerMessagesPage.loadData({ ...params })
    },
    {
      //path: '/supplier-messages',
      path: '/:locale/supplier-messages',
      name: 'SupplierMessagesPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <SupplierMessagesPage {...props} />,
      authUserType: 'supplier',
      loadData: () => SupplierMessagesPage.loadData()
    },
    {
      //path: '/supplier-send-messages/:receiver/:title',
      path: '/:locale/supplier-send-messages/:receiver/:title',
      name: 'SupplierSendMessagePage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <SupplierMessagesPage {...props} />,
      authUserType: 'supplier',
      loadData: params => SupplierMessagesPage.loadData({ ...params })
    },
    {
      //path: '/buyer-orders',
      path: '/:locale/buyer-orders',
      name: 'BuyerOrdersPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <BuyerOrdersPage {...props} />,
      authUserType: 'buyer'
    },
    {
      //path: '/buyer-settings',
      path: '/:locale/buyer-settings',
      name: 'BuyerSettingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <BuyerSettingsPage tab='account' {...props} />,
      authUserType: 'buyer'
    },
    {
      //path: '/supplier-settings/payout-details',
      path: '/:locale/supplier-settings/payout-details',
      name: 'SupplierProfilePayoutDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <SupplierSettingsPage tab='payout-details' {...props} params={{supplierMode: true}} />,
      authUserType: 'supplier',
      loadData: params => ProfilePayoutDetailsPage.loadData({ ...params, supplierMode: true }),
    },
    {
      //path: '/supplier-settings/payout-details/:returnURLType',
      path: '/:locale/supplier-settings/payout-details/:returnURLType',
      name: 'SupplierProfilePayoutDetailsUrlPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <SupplierSettingsPage tab='payout-details' {...props} />,
      authUserType: 'supplier',
      loadData: params => ProfilePayoutDetailsPage.loadData({ ...params, supplierMode: true }),
    },
    {
      //path: '/supplier-settings/account',
      path: '/:locale/supplier-settings/account',
      name: 'SupplierProfileAccountPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <SupplierSettingsPage tab='account' {...props} />,
      authUserType: 'supplier'
    },
    {
      //path: '/supplier-settings/notifications',
      path: '/:locale/supplier-settings/notifications',
      name: 'SupplierProfileNotificationsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <SupplierSettingsPage tab='notifications' {...props} />,
      loadData: params => SupplierProfileNotificationsPage.loadData(),
      authUserType: 'supplier'
    },
    {
      //path: '/supplier-settings/invoicing',
      path: '/:locale/supplier-settings/invoicing',
      name: 'SupplierProfileInvoicingPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <SupplierSettingsPage tab='invoicing' {...props} />,
      authUserType: 'supplier'
    },
    {
      //path: '/supplier-settings',
      path: '/:locale/supplier-settings',
      name: 'SupplierSettingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <SupplierSettingsPage tab='account' {...props} />,
      authUserType: 'supplier'
    },
    {
      //path: '/supplier/add-product',
      path: '/:locale/supplier/add-product',
      name: 'SupplierAddProductPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <SupplierAddProductPage {...props} />,
      loadData: () => SupplierAddProductPage.loadData({ id: null }),
      authUserType: 'supplier'
    },
    {
      //path: '/supplier/product/:id/edit',
      path: '/:locale/supplier/product/:id/edit',
      name: 'SupplierEditProductPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <SupplierAddProductPage {...props} />,
      loadData: params => SupplierAddProductPage.loadData({ ...params }),
      setInitialValues: SupplierAddProductPage.setInitialValues,
      authUserType: 'supplier'
    },
    {
      //path: '/product/:product/:id',
      path: '/:locale/product/:product/:id',
      name: 'ProductDetailsPage',
      //auth: true,
      //authPage: 'LoginPage',
      component: props => <ProductDetailsPage {...props} />,
      loadData: params => ProductDetailsPage.loadData({ ...params })
    },
    {
      //path: '/registration-request/:scode',
      path: '/:locale/registration-request/:scode',
      name: 'RegistrationCompletePage',
      auth: false,
      component: props => <RegistrationCompletePage {...props} />,
      loadData: params => RegistrationCompletePage.loadData({ key:params.scode })
    },
    {
      //path: '/brand/:brand/:id',
      path: '/:locale/brand/:brand/:id',
      name: 'SupplierDetailsPage',
      auth: false,
      component: props => <SupplierDetailsPage {...props} />,
      loadData: params => SupplierDetailsPage.loadData({ ...params })
    },
    {
      //path: '/buyer/:id/details',
      path: '/:locale/buyer/:id/details',
      name: 'BuyerDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      authUserType: 'supplier',
      component: props => <BuyerDetailsPage {...props} />,
      loadData: params => BuyerDetailsPage.loadData({ ...params })
    },
    {
      //path: '/supplier/orders',
      path: '/:locale/supplier/orders',
      name: 'SupplierApprovedOrdersPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <SupplierOrdersAndRequestsPage {...props} tab='orders'/>,
      authUserType: 'supplier',
      loadData: params => SupplierApprovedOrdersPage.loadData({ ...params })
    },
    {
      //path: '/supplier/requests',
      path: '/:locale/supplier/requests',
      name: 'SupplierOrderRequestsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <SupplierOrdersAndRequestsPage {...props} tab='requests'/>,
      authUserType: 'supplier',
      loadData: params => SupplierOrderRequestsPage.loadData({ ...params })
    },
    {
      //path: '/registration-requests/suppliers',
      path: '/:locale/registration-requests/suppliers',
      name: 'SupplierRegistrationModeratorPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <RegistrationModeratorPage {...props} tab='suppliers'/>,
      loadData: params => RegistrationModeratorPage.loadDataForSuppliers({ ...params, dataType: 'suppliers' }),
      supplierRegistrationCheck: true,
    },
    {
      //path: '/registration-requests/buyers',
      path: '/:locale/registration-requests/buyers',
      name: 'BuyerRegistrationModeratorPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <RegistrationModeratorPage {...props} tab='buyers'/>,
      loadData: params => RegistrationModeratorPage.loadDataForBuyers({ ...params, dataType: 'buyers' }),
      buyerRegistrationCheck: true,
    },
    {
      //path: '/buyer/orders',
      path: '/:locale/buyer/orders',
      name: 'BuyerApprovedOrdersPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <BuyerOrdersAndRequestsPage {...props} tab='orders'/>,
      authUserType: 'buyer',
      loadData: params => BuyerApprovedOrdersPage.loadData({ ...params })
    },
    {
      //path: '/buyer/requests',
      path: '/:locale/buyer/requests',
      name: 'BuyerOrderRequestsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <BuyerOrdersAndRequestsPage {...props} tab='requests'/>,
      authUserType: 'buyer',
      loadData: params => BuyerOrderRequestsPage.loadData({ ...params })
    },
    {
      //path: '/supplier/products',
      path: '/:locale/supplier/products',
      name: 'SupplierProductsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <SupplierProductsPage {...props} />,
      authUserType: 'supplier',
      loadData: () => SupplierProductsPage.loadData()
    },*/
    // TODO - delete page
    /*{
      path: '/order/:id/details',
      name: 'OrderDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <TransactionPage {...props} transactionRole="customer" />,
      loadData: params => TransactionPage.loadData({ ...params, transactionRole: 'customer' }),
      setInitialValues: TransactionPage.setInitialValues,
    },*/
    // TODO - delete page
    /*{
      path: '/sale/:id',
      name: 'SalePage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <NamedRedirect name="SaleDetailsPage" params={{ ...props.params }} />,
    },*/
    // TODO - delete page
    /*{
      path: '/sale/:id/details',
      name: 'SaleDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <TransactionPage {...props} transactionRole="provider" />,
      loadData: params => TransactionPage.loadData({ ...params, transactionRole: 'provider' }),
    },*/
    // Route not used, but page part of user settings page
        
    // 16.07.23 Vegshelf freeze: commented out routes for Vegshelf freeze
    /*{
      //path: '/account',
      path: '/:locale/account',
      name: 'AccountSettingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: () => <NamedRedirect name="ContactDetailsPage" />,
    },
    // Route not used, but page part of user settings page
    {
      //path: '/account/contact-details',
      path: '/:locale/account/contact-details',
      name: 'ContactDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <ContactDetailsPage {...props} />,
      loadData: ContactDetailsPage.loadData,
    },*/
    // Route not used, but page part of user settings page
        
    // 16.07.23 Vegshelf freeze: commented out routes for Vegshelf freeze
    /*{
      //path: '/account/change-password',
      path: '/:locale/account/change-password',
      name: 'PasswordChangePage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <PasswordChangePage {...props} />,
    },*/
    // Route not used, but page part of user settings page
        
    // 16.07.23 Vegshelf freeze: commented out routes for Vegshelf freeze
    /*{
      //path: '/account/payment-methods',
      path: '/:locale/account/payment-methods',
      name: 'PaymentMethodsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <PaymentMethodsPage {...props} />,
      loadData: PaymentMethodsPage.loadData,
    },*/
    /*{
      path: '/styleguide',
      name: 'Styleguide',
      component: props => <StyleguidePage {...props} />,
    },*/
    /*{
      path: '/styleguide/g/:group',
      name: 'StyleguideGroup',
      component: props => <StyleguidePage {...props} />,
    },*/
    /*{
      path: '/styleguide/c/:component',
      name: 'StyleguideComponent',
      component: props => <StyleguidePage {...props} />,
    },*/
    /*{
      path: '/styleguide/c/:component/:example',
      name: 'StyleguideComponentExample',
      component: props => <StyleguidePage {...props} />,
    },*/
    /*{
      path: '/styleguide/c/:component/:example/raw',
      name: 'StyleguideComponentExampleRaw',
      component: props => <StyleguidePage raw {...props} />,
    },*/
    {
      //path: '/notfound',
      path: '/:locale/notfound',
      name: 'NotFoundPage',
      component: props => <NotFoundPage {...props} />,
    },
    // Commented out for future implementation
    /*{
      path: '/featured/products',
      name: 'FeatureSearchProductsPage',
      component: props => <FeatureSearchProductsPage {...props} />,
      loadData: FeatureSearchProductsPage.loadData
    },*/
        
    // 16.07.23 Vegshelf freeze: commented out routes for Vegshelf freeze
    /*{
      //path: '/new/products',
      path: '/:locale/new/products',
      name: 'NewSearchProductsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <NewSearchProductsPage {...props} />,
      loadData: NewSearchProductsPage.loadData
    },
    {
      //path: '/categories/:productCategoryCode',
      path: '/:locale/categories/:productCategoryCode',
      name: 'CategoryProductsPage',
      component: props => <SearchProductsPage {...props} />,
      loadData: SearchProductsPage.loadData
    },
    {
      //path: '/categories/:productCategoryCode/:search',
      path: '/:locale/categories/:productCategoryCode/:search',
      name: 'SearchCategoryProductsPagingPage',
      component: props => <SearchProductsPage {...props} />,
      loadData: SearchProductsPage.loadData
    },
    {
      //path: '/categories/:productCategoryCode/:pageIndex/:pageSize',
      path: '/:locale/categories/:productCategoryCode/:pageIndex/:pageSize',
      name: 'CategoryProductsPagingPage',
      component: props => <SearchProductsPage {...props} />,
      loadData: SearchProductsPage.loadData
    },
    {
      //path: '/categories/:productCategoryCode/:pageIndex/:pageSize/:search',
      path: '/:locale/categories/:productCategoryCode/:pageIndex/:pageSize/:search',
      name: 'SearchProductInCategoryPage',
      component: props => <SearchProductsPage {...props} />,
      loadData: SearchProductsPage.loadData
    },
    {
      //path: '/products/:pageIndex/:pageSize/:search',
      path: '/:locale/products/:pageIndex/:pageSize/:search',
      name: 'SearchProductsPagingPage',
      component: props => <SearchProductsPage {...props} />,
      loadData: SearchProductsPage.loadData
    },
    {
      //path: '/products/:pageIndex/:pageSize',
      path: '/:locale/products/:pageIndex/:pageSize',
      name: 'ProductsPage',
      component: props => <SearchProductsPage {...props} />,
      loadData: SearchProductsPage.loadData
    },
    {
      //path: '/products/:search',
      path: '/:locale/products/:search',
      name: 'SearchProductsPage',
      component: props => <SearchProductsPage {...props} />,
      loadData: SearchProductsPage.loadData
    },
    {
      //path: '/supplier/:id/product',
      path: '/:locale/supplier/:id/product',
      name: 'ProductSupplierDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <ProductDetailsPage {...props} />,
      loadData: params => ProductDetailsPage.loadData({ ...params, supplierMode: true }),
      authUserType: 'supplier'
    },*/
    // Commented out until function developed further
    /*
    {
      path: '/listing-update-page',
      name: 'ListingUpdatePage',
      auth: true,
      authPage: 'LoginPage',
      authUserType: 'admin',
      component: props => <ListingUpdatePage {...props} />,
      loadData: ListingUpdatePage.loadData
    },  
    */

    // Do not change this path!
    //
    // The API expects that the application implements /reset-password endpoint
        
    // 16.07.23 Vegshelf freeze: commented out routes for Vegshelf freeze
    /*{
      //path: '/reset-password',
      path: '/:locale/reset-password',
      name: 'PasswordResetPage',
      component: props => <PasswordResetPage {...props} />,
    },*/

    // Do not change this path!
    //
    // The API expects that the application implements /verify-email endpoint
        
    // 16.07.23 Vegshelf freeze: commented out routes for Vegshelf freeze
    /*{
      //path: '/verify-email',
      path: '/:locale/verify-email',
      name: 'EmailVerificationPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <EmailVerificationPage {...props} />,
      loadData: EmailVerificationPage.loadData,
    },*/
  ];
};

export default routeConfiguration;
