import React, { useContext, useEffect, useState } from 'react';
import { bool, func, number, object, string, shape } from 'prop-types';
import { Link, useLocation } from "react-router-dom";
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import { propTypes } from '../../util/types';
import { getProductCategories } from '../../translations/productDictionaries';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  Avatar,
  InlineTextButton,
  Logo,
  Menu,
  MenuContent,
  MenuItem,
  MenuLabel,
  NamedLink,
  RegistrationConfirmDialog
} from '../../components';
import { TopbarSearchForm } from '../../forms';
import { displayRegistrationConfirmDialog } from '../../ducks/Modal.duck';
import { shoppingCartClearAddedProduct } from '../../ducks/shopping-cart.duck';
import { slugify } from "../../util/urlTrimming";
import { Context, storedLocale } from '../Wrapper/ClassWrapper';
import TrustBoxContainer from '../Trustpilot/TrustpilotContainer';
import checkIcon from '../../assets/check-000000.svg';
import css from './TopbarDesktop.module.css';

function formatPrice(price) {
  const msrp = parseFloat(price);
  return (Math.round(msrp * 100) / 100).toFixed(2);
}

const TopbarDesktopComponent = (props) => {
  const {
    className,
    currentUser,
    currentPage,
    rootClassName,
    // currentUserHasListings,
    // currentUserListing,
    // currentUserListingFetched,
    // notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
    onlyLogo,
    currentUserType,
    productCategoryCode,
    shoppingCartProductCount,
    onDisplayRegistrationConfirmDialog,
    onShoppingCartClearAddedProduct,
    addedProduct,
    shoppingCart,
    location
  } = props;

  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);
  const shoppingCartBrands = shoppingCart?.brands || [];
  const shoppingCartProducts = shoppingCartBrands
    .map((b) => (b?.products || []))
    .reduce(function (a, b) {
      return a.concat(b);
    }, []);
  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated;
  const classes = classNames(rootClassName || css.root, className);

  const [languageChanged, setLanguageChanged] = useState(false);
  const context = useContext(Context);
  //const storedLocale = sessionStorage.getItem('locale');
  //const storedLocale = typeof sessionStorage !== 'undefined' ? sessionStorage.getItem('locale') : "en";

  /*let storedLocale = "en";

  if (typeof sessionStorage !== 'undefined') {
    if (sessionStorage) {
      storedLocale = sessionStorage.getItem('locale');
    } else {
      storedLocale = "en";
    }
  };*/

  const locale = storedLocale() || context.locale;
  const englishLocale = locale === "en" ? "en-GB" : "de-DE";

  // 16.07.23 Vegshelf freeze: removed links as part of Vegshelf freeze
  const shortLinks = getProductCategories().slice(0, 4).map((c) => ({
    name: c.name,
    params: { productCategoryCode: c.categoryCode, locale: locale },
  }));

  useEffect(() => {
    sessionStorage.setItem('locale', locale);
    const newPathname = window.location.pathname.replace(/^\/[a-z]{2}/, `/${locale}`);
    window.history.replaceState({}, null, newPathname);
    if (!languageChanged) {
      setLanguageChanged(true);
    } else {
      window.location.reload();
    }
  }, [locale]);

  const shortKeyListItem = shortLinkItem => {
    const className = shortLinkItem.params.productCategoryCode !== productCategoryCode
      ? css.transitionItem : css.selectedTransitionItem;

    return (
      <li
        key={shortLinkItem.params.productCategoryCode}
        className={className}>

        { /* 16.07.23 Vegshelf freeze: removed links as part of Vegshelf freeze, adedd registrationConfirmDialog instead */}

        <a className={css.shortLink} params={shortLinkItem.params} onClick={() => onDisplayRegistrationConfirmDialog(true)}>
          {shortLinkItem.name}
        </a>
      </li>
    );
  };

  const shortLinkNodes = shortLinks.map(shortKeyListItem);

  const search = (
    <div className={css.searchContainer}>
      <TopbarSearchForm
        className={css.searchLink}
        desktopInputRoot={css.topbarSearchWithLeftPadding}
        onSubmit={onSearchSubmit}
        initialValues={initialSearchFormValues}
      />
    </div>
  );

  const currentPageClass = (page) => {
    const isAccountSettingsPage = page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  // 16.07.23 Vegshelf freeze: commented out Topbar links as part of Vegshelf freeze

  /*const isSupplierUserType = currentUserType === "supplier";
  const isBuyerUserType = currentUserType === "buyer";

  const supplierMenu = authenticatedOnClientSide && isSupplierUserType ? (
    <div className={css.buyerTopMenu}>
      <NamedLink className={css.ordersMainMenuLink} name="SupplierApprovedOrdersPage">
        <FormattedMessage id="TopbarDesktop.supplierLinkOrders" />
      </NamedLink>
      <NamedLink className={css.messagesMainMenuLink} name="SupplierMessagesPage">
      </NamedLink>
      <Menu className={css.avatarMenu}>
        <MenuLabel className={css.profileMenuLabel}>
          <Avatar className={css.avatar} user={currentUser} disableProfileLink />
        </MenuLabel>
        <MenuContent className={css.profileMenuContentSupplier}>
          <MenuItem key="SupplierProfilePage">
            <NamedLink
              className={classNames(css.profileSettingsLink, currentPageClass('ProfileSettingsPage'))}
              name="SupplierProfilePage"
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.supplierLinkCompanyPage" />
            </NamedLink>
          </MenuItem>
          <MenuItem key="SupplierOrderInvoicesPage">
            <NamedLink
              className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
              name="SupplierOrderInvoicesPage"
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.buyerInvoicing" />
            </NamedLink>
          </MenuItem>
          <MenuItem key="SupplierProductsPage">
            <NamedLink
              className={classNames(css.profileSettingsLink, currentPageClass('ProfileSettingsPage'))}
              name="SupplierProductsPage"
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.supplierLinkProductListings" />
            </NamedLink>
          </MenuItem>
          <MenuItem key="SupplierSettingsPage">
            <NamedLink
              className={classNames(css.profileSettingsLink, currentPageClass('ProfileSettingsPage'))}
              name="SupplierSettingsPage"
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.supplierLinkSettings" />
            </NamedLink>
          </MenuItem>
          <MenuItem key="SupplierHelpPage">
            <NamedLink
              className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
              name="ContactUsPage"
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.supplierLinkHelp" />
            </NamedLink>
          </MenuItem>
          <MenuItem key="logout">
            <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.logout" />
            </InlineTextButton>
          </MenuItem>
        </MenuContent>
      </Menu>
    </div>
  ) : null;*/

  //const { pathname } = useLocation();
  //const isSelected = pathname === "/buyer/orders";
  //const isSelected = pathname === "/en/buyer/orders" || pathname === "/de/buyer/orders";

  /*const buyerMenu = authenticatedOnClientSide && isBuyerUserType ? (
    <div className={css.buyerTopMenu}>
      <NamedLink
        className={classNames(css.ordersMainMenuLink, { [css.selectedLink]: isSelected })}
        name="BuyerApprovedOrdersPage"
      >
        <FormattedMessage id="TopbarDesktop.buyerOrders" />
      </NamedLink>
      <NamedLink className={css.messagesMainMenuLink} name="BuyerMessagesPage" />
      <NamedLink className={css.favouritesMainMenuLink} name="BuyerMyFavouritesPage" />
      <Menu>
        <MenuLabel className={css.profileMenuLabel}>
          <Avatar className={css.avatar} user={currentUser} disableProfileLink />
        </MenuLabel>
        <MenuContent className={css.profileMenuContentBuyer}>
          <MenuItem key="BuyerProfilePage">
            <NamedLink
              className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
              name="BuyerProfilePage"
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.buyerCompanyPage" />
            </NamedLink>
          </MenuItem>
          <MenuItem key="BuyerInvoicingPage">
            <NamedLink
              className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
              name="BuyerInvoicingPage"
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.buyerInvoicing" />
            </NamedLink>
          </MenuItem>
          <MenuItem key="BuyerSettingsPage">
            <NamedLink
              className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
              name="BuyerSettingsPage"
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.buyerSettings" />
            </NamedLink>
          </MenuItem>
          <MenuItem key="BuyerHelpPage">
            <NamedLink
              className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
              name="ContactUsPage"
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.supplierLinkHelp" />
            </NamedLink>
          </MenuItem>
          <MenuItem key="logout">
            <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.logout" />
            </InlineTextButton>
          </MenuItem>
        </MenuContent>
      </Menu>
      <div className={css.shoppingButton}>
        {!!addedProduct ? (
          <div className={css.bkPopupHandler} onClick={() => onShoppingCartClearAddedProduct()}>&nbsp;</div>
        ) : null}
        <Menu isOpen={!!addedProduct} onToggleActive={(s) => {
          !s && onShoppingCartClearAddedProduct()
        }}>
          <MenuLabel>
            <NamedLink
              className={css.shoppingCartMainMenuLink}
              name="ShoppingCartPage">
              <div className={css.shoppingCartIcon}>&nbsp;
                {shoppingCartProductCount ? (
                  <div className={css.shoppingCartNotification}>{shoppingCartProductCount}</div>
                ) : null}
              </div>
            </NamedLink>
          </MenuLabel>
          <MenuContent className={css.addedProductNotification}>
            <MenuItem key='addedProduct'>
              <div className={css.addedProduct}>
                <h1>
                  <img className={css.checkIcon} src={checkIcon} alt="" />
                  <FormattedMessage id="TopbarDesktop.cart.addedToCart" />
                </h1>
                {shoppingCartProducts.map((p, idx) => {
                  const style = {};
                  if (p.mainImageUrl) {
                    style.backgroundImage = `url('${p.mainImageUrl}')`;
                  }
                  return (
                    <div key={`scp${idx}${p.id}`}>
                      <NamedLink
                        name="ProductDetailsPage"
                        className={css.addedProductDetails}
                        onClick={() => onShoppingCartClearAddedProduct()}
                        params={{ product: slugify(p.productName), id: p.id, locale: locale }}
                      >
                        <div className={css.addedProductPhoto} style={style}>&nbsp;</div>
                        <div className={css.productDetails}>
                          <h1 className={css.productTitle}>
                            {p.productName}
                          </h1>
                          <h2 className={css.productSubTitle}>€{formatPrice(p.totalPrice)}<FormattedMessage id="TopbarDesktop.cart.exclVAT" /></h2>
                          <h2 className={css.productSubTitle}>{p.count}<FormattedMessage id="TopbarDesktop.cart.units" /></h2>
                        </div>
                      </NamedLink>
                    </div>
                  );
                })}
                <div className={css.shoppingCartButtons}>
                  <NamedLink
                    onClick={() => onShoppingCartClearAddedProduct()}
                    className={css.goToShoppingCart}
                    name="ShoppingCartPage"
                  >
                    <FormattedMessage id="TopbarDesktop.cart.goToCart" />
                  </NamedLink>
                </div>
              </div>
            </MenuItem>
          </MenuContent>
        </Menu>
      </div>
    </div>
  ) : null;*/

  /* 21.07.23 Vegshelf freeze: added Login link so login page is still accessible */
  const loginLink = 
    <NamedLink name="LoginPage" className={classNames(css.loginLink, { [css.loginLinkDE]: locale === "de"})}>
      <span>
        <FormattedMessage id="TopbarDesktop.login" />
      </span>
    </NamedLink>
  ;

  /* 16.07.23 Vegshelf freeze: removed links as part of Vegshelf freeze, adedd registrationConfirmDialog instead */
  const registerToShopLink = (
    <a className={classNames(css.registerToShopLink, { [css.registerToShopLinkDE]: locale === "de" })} onClick={() => onDisplayRegistrationConfirmDialog(true)}>
      <span>
        <FormattedMessage id="TopbarDesktop.registerToShop" />
      </span>
    </a>
  );

  //const isAboutUsPage = location.pathname.startsWith("/about-us");
  //const isApplyToSellPage = location.pathname.startsWith("/apply-to-sell");
  const isAboutUsPage = location.pathname.startsWith("/en/about-us") || location.pathname.startsWith("/de/about-us");
  const isApplyToSellPage = location.pathname.startsWith('/en/apply-to-sell') || location.pathname.startsWith('/de/apply-to-sell');
  //const isBlogPage = location.pathname.startsWith("/blog");
  const isBlogPage = location.pathname.startsWith("/en/blog") || location.pathname.startsWith("/de/blog");
  const isCustomTopbar = isAboutUsPage || isApplyToSellPage || isBlogPage;
  const isPasswordResetPage = location.pathname.startsWith("/en/reset-password") || location.pathname.startsWith("/de/reset-password");

  /* 16.07.23 Vegshelf freeze: removed links as part of Vegshelf freeze, adedd registrationConfirmDialog instead */
  const applyToSellLink = isApplyToSellPage ?
    (<a className={classNames(css.signupLink, { [css.signupLinkDE]: locale === "de" })} onClick={() => onDisplayRegistrationConfirmDialog(false)}>
      <span>
        <FormattedMessage id="TopbarDesktop.applyToSell" />
      </span>
    </a>)
    :
    (<NamedLink name="ApplyToSellPage" className={classNames(css.signupLink, { [css.signupLinkDE]: locale === "de" })}>
      <span>
        <FormattedMessage id="TopbarDesktop.applyToSell" />
      </span>
    </NamedLink>
    );

  if (onlyLogo) {
    return (
      <div>
        <nav className={classes}>
          <div className={css.onlyLogoContainer}>
            <NamedLink name="LandingPage">
              <Logo
                format="desktop"
                className={css.logo}
                alt={intl.formatMessage({ id: 'TopbarDesktop.logo' })}
              />
            </NamedLink>
          </div>
        </nav>
      </div>
    );
  }

  return (
    <div>
      <nav className={isAboutUsPage ? css.aboutUsPageDesign : (isApplyToSellPage ? css.applyToSellPage : (isBlogPage ? css.blogPageDesign : classes))}>
        <NamedLink name="LandingPage">
          <Logo
            format="desktop"
            className={css.logo}
            alt={intl.formatMessage({ id: 'TopbarDesktop.logo' })}
          />
        </NamedLink>
        {isCustomTopbar ? <div className={css.searchContainer}></div> : search}
        <div>&nbsp;</div>
        {currentUser?.attributes?.profile?.privateData?.enableCategorySectionAdmin === true ? (
          <NamedLink className={css.categorySections} name="CategorySectionPage">
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.adminCategorySection" />
          </NamedLink>
        ) : null}
        {currentUser?.attributes?.profile?.privateData?.enableRegistrationRequestsModerator === true ? (
          <NamedLink className={css.categorySections} name="SupplierRegistrationModeratorPage">
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.adminRegistrations" />
          </NamedLink>) : null}
        {isPasswordResetPage || isBlogPage ? null : (
          <Context.Consumer>
            {({ selectLanguage }) => (
              <div className={css.languageButtonContainer}>
                <button value='en' onClick={selectLanguage}>EN</button>
                /
                <button value='de' onClick={selectLanguage}>DE</button>
              </div>
            )}
          </Context.Consumer>
        )}
        {/* 16.07.23 Vegshelf freeze: commented out Topbar links as part of Vegshelf freeze */}
        {/*supplierMenu*/}
        {/*buyerMenu*/}
        {loginLink}
        {applyToSellLink}
        {registerToShopLink}
      </nav>
      {isCustomTopbar ? null :
        (
          <div className={css.categoriesContainer}>
            <div className={css.categoryPlaceholder} ></div>
            <ul className={css.shortLinks}>
              {/* 16.07.23 Vegshelf freeze: removed Topbar links as part of Vegshelf freeze */}
              <li key="new" className={"new" !== productCategoryCode ? css.transitionItem : css.selectedTransitionItem}>
                <a className={css.shortLink} name="NewSearchProductsPage" onClick={onDisplayRegistrationConfirmDialog}>
                  <FormattedMessage id="TopbarDesktop.newBrandsCategory" />
                </a>
              </li>
              {shortLinkNodes}
            </ul>
            <div className={classNames(css.trustpilotContainer, { [css.trustpilotContainerDE]: locale === "de" })}>
              <TrustBoxContainer theme="light" locale={englishLocale} />
            </div>
          </div>
        )}
      <RegistrationConfirmDialog />
    </div>
  );
};

TopbarDesktopComponent.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  currentUserListing: null,
  currentUserListingFetched: false,
  currentUserType: null
};

TopbarDesktopComponent.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
  currentUserType: string,
  location: shape({ search: string.isRequired }).isRequired,
};

const mapStateToProps = (state) => {
  const { currentProductCategory } = state.user;
  const { shoppingCartProductCount, shoppingCart, addedProduct } = state.ShoppingCartStore;

  return {
    currentProductCategory,
    shoppingCartProductCount,
    shoppingCart,
    addedProduct,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onDisplayRegistrationConfirmDialog: (isBuyer) => dispatch(displayRegistrationConfirmDialog(true, isBuyer)),
  onShoppingCartClearAddedProduct: () => dispatch(shoppingCartClearAddedProduct()),
});

const TopbarDesktop = compose(
  connect(mapStateToProps, mapDispatchToProps),
)(TopbarDesktopComponent);

export default TopbarDesktop;