import React from 'react';
import { bool, string } from 'prop-types';
import { propTypes } from '../../util/types';
import { FormattedMessage } from '../../util/reactIntl';

import imageSupplier1 from '../../assets/baetter-baking-duesseldorf.png';
import imageSupplier2 from '../../assets/palais-brussels.png';
import imageSupplier3 from '../../assets/just-nosh-berlin.png';
import imageSupplier4 from '../../assets/complete-organics-munich.png';

import css from './SectionDiscoverOurBrands.module.css';

const baetterBakingLink = 'https://www.vegshelf.com/brand/baetter-baking/62f5dbdc-55f9-46b2-a5e1-a70372e512d9';
const palaisLink = 'https://www.vegshelf.com/brand/palais/62627223-0b86-4e52-8686-3584578f3f11';
const justNoshLink = 'https://www.vegshelf.com/brand/just-nosh/6287a1fc-1be1-4b25-b082-1be6569b4454';
const completeOrganicsLink = 'https://www.vegshelf.com/brand/completeorganics/62974f78-5ff5-428e-adeb-7b0bc12ea471';

const SectionDiscoverOurBrands = props => {
  const brands = [
    {
      id: 1,
      link: 'Baetter Baking',
      subText: <FormattedMessage id="SectionDiscoverOurBrands.duesseldorf" />,
      navName: baetterBakingLink,
      image: imageSupplier1,
    },
    {
      id: 2,
      link: "Pa'lais",
      subText: <FormattedMessage id="SectionDiscoverOurBrands.brussels" />,
      navName: palaisLink,
      image: imageSupplier2,
    },
    {
      id: 3,
      link: "Just Nosh",
      subText: <FormattedMessage id="SectionDiscoverOurBrands.berlin" />,
      navName: justNoshLink,
      image: imageSupplier3,
    },
    {
      id: 4,
      link: 'Complete Organics',
      subText: <FormattedMessage id="SectionDiscoverOurBrands.munich" />,
      navName: completeOrganicsLink,
      image: imageSupplier4,
    },
  ];

  // 16.07.23 Vegshelf freeze: removed links to brands as part of Vegshelf freeze

  const brandItem = item => {
    return (
      <div key={item.id} className={css.brandItem}>
        <div className={css.brandLink}>
          <img src={item.image} className={css.brandImage} alt={item.link} />
        </div>
        <div className={css.brandTitle}>
          <div className={css.brandNameLink}>
            {item.link}
          </div>{' '}
          <br />
          <div className={css.fromStyles}>{item.subText}</div>
        </div>
      </div>
    );
  };

  const brandsNodes = brands.map(brandItem);

  return (<div className={css.rootContainer}>
    <h2 className={css.sectionTitle}><FormattedMessage id="SectionDiscoverOurBrands.meetTheBrands" /></h2>
    <p className={css.sectionSubTitle}><FormattedMessage id="SectionDiscoverOurBrands.sectionSubtitle" /></p>
    <div className={css.brandsContainer}>
      {brandsNodes}
    </div>
  </div>
  );
};

SectionDiscoverOurBrands.defaultProps = {
  rootClassName: null,
  className: null,
  currentUserListing: null,
  currentUserListingFetched: false,
};

SectionDiscoverOurBrands.propTypes = {
  rootClassName: string,
  className: string,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
};

export default SectionDiscoverOurBrands;
