import React, { Component } from 'react';
import { bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { propTypes } from '../../util/types';
import config from '../../config';
import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  NamedLink,
} from '../../components';
import { TopbarContainer } from '../../containers';

import facebookImage from '../../assets/VegshelfFacebook-1200x630.png';
import twitterImage from '../../assets/VegshelfTwitter-600x314.png';
import css from './AccessDeniedPage.module.css';

export class AccessDeniedPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { stepIndex: 0 };
    this.newSupplier = null;
  }
  render() {
    const {
      intl,
      scrollingDisabled,
    } = this.props;

    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage({ id: 'AccessDeniedPage.schemaTitle' }, { siteTitle });
    const schemaDescription = intl.formatMessage({ id: 'AccessDeniedPage.schemaDescription' }, { siteTitle });
    const schemaImage = `${config.canonicalRootURL}${facebookImage}`;

    return (
      <Page
        className={css.root}
        scrollingDisabled={scrollingDisabled}
        contentType="website"
        description={schemaDescription}
        title={schemaTitle}
        robots='noindex'
        facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
        twitterImages={[
          { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
        ]}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'WebPage',
          description: schemaDescription,
          name: schemaTitle,
          image: [schemaImage],
        }}
      >
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer onlyLogo="true" />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div className={css.backgroundContainer}>
              <div className={css.mainContainer}>
                <div className={css.mainContainerText}>
                  <FormattedMessage id="AccessDeniedPage.AccessDeniedMessage" />
                </div>
                <div>
                  <NamedLink name="LoginPage" className={css.loginButton}>
                    <FormattedMessage id="TopbarDesktop.login" />
                  </NamedLink>
                </div>
              </div>
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
};

AccessDeniedPageComponent.defaultProps = {
  currentUserListing: null,
  currentUserListingFetched: false,
};

AccessDeniedPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};
const mapStateToProps = state => {

  return {
    scrollingDisabled: isScrollingDisabled(state)
  };
};

const mapDispatchToProps = dispatch => ({
});

const AccessDeniedPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(AccessDeniedPageComponent);

export default AccessDeniedPage;
