import React, { Component } from "react";
import { bool, object } from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { FormattedMessage, injectIntl, intlShape } from "../../util/reactIntl";
import { isScrollingDisabled } from "../../ducks/UI.duck";
import getProductDictionaries from "../../translations/productDictionaries";
import getCountryCodes from "../../translations/countryCodes";
import classNames from "classnames";
import { propTypes } from "../../util/types";
import config from "../../config";
import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  ProductContainer,
  NamedLink,
  PaginationButtons,
} from "../../components";
import { TopbarContainer } from "../../containers";
import { slugify } from "../../util/urlTrimming";

import facebookImage from "../../assets/VegshelfFacebook-1200x630.png";
import twitterImage from "../../assets/VegshelfTwitter-600x314.png";
import css from "./FeatureSearchProductsPage.module.css";
import { loadTopCategoryProducts, searchProducts } from "./FeatureSearchProductsPage.duck";
import { storedLocale, localePart } from "../../components/Wrapper/ClassWrapper";

const locale = storedLocale() || localePart();

export class FeatureSearchProductsPageComponent extends Component {
  constructor(props) {
    super(props);
    this.defaultFilter = {
      pageIndex: 1,
      subCategory: null,
      property: null,
      shipping: null,
      location: null,
      temperature: null,
      price: null,
      hasFilter: false,
    };
    this.state = { ...this.defaultFilter };
    this.searchInterval = null;
    this.onClearFilterClick = this.onClearFilterClick.bind(this);
    this.onChangeFilter = this.onChangeFilter.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
  }

  onClearFilterClick() {
    const filter = { ...this.defaultFilter };
    this.setState(filter);
    this.search(filter);
  }

  onChangeFilter(fieldName, event, multiselect) {
    const filter = { ...this.state, hasFilter: true };
    if (!multiselect) {
      filter[fieldName] = event.target.value;
    } else {
      filter[fieldName] = filter[fieldName] || [];
      if (event.target.checked) {
        filter[fieldName].push(event.target.value);
      } else {
        filter[fieldName] = filter[fieldName].filter((v) => {
          return v !== event.target.value;
        });
      }
    }
    this.setState(filter);
    this.search(filter);
  }

  onPageChange(page) {
    const newState = { ...this.state, pageIndex: page };
    this.setState(newState);
    this.props.onSearchProducts({ ...(this.props.params || {}), ...newState });
  }

  search(filter) {
    if (this.searchInterval) {
      clearTimeout(this.searchInterval);
    }
    setTimeout(() => {
      this.props.onSearchProducts({ ...(this.props.params || {}), ...filter });
      this.searchInterval = null;
    }, 2000);
  }

  componentDidUpdate(prevProps) {
    // Reset filters if /categories subpage is changed
    if (prevProps.params.productCategoryCode !== this.props.params.productCategoryCode) {
      this.onClearFilterClick();
    }
  }

  render() {
    const {
      // history,
      intl,
      // location,
      scrollingDisabled,
      params,
      // topCategoryProductsError,
      // topCategoryProductsInProgress,
      // searchProductsError,
      // searchProductsInProgress,
      topCategoryProducts,
      searchedProducts,
      isAuthenticated,
    } = this.props;

    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage({ id: "SearchProductsPage.schemaTitle" }, { siteTitle });
    const schemaDescription = intl.formatMessage({ id: "SearchProductsPage.schemaDescription" });
    const schemaImage = `${config.canonicalRootURL}${facebookImage}`;
    let category = null;
    let subCategories = [];
    const dictionaries = getProductDictionaries();
    const countries = getCountryCodes();
    if (params.productCategoryCode) {
      category = dictionaries.categories.filter((x) => (x.categoryCode === params.productCategoryCode))[0];
      subCategories = category.subCategories;
    }
    // const currentCategoryName = category ? `in ${category.name}` : null;
    const productsContainerTitle = category ? `${category.name}` : "All Categories";
    const topProducts = topCategoryProducts?.data || [];
    const viewProducts = searchedProducts?.data || [];
    const currentPage = this.state.pageIndex;
    const totalPages = searchedProducts?.meta?.totalPages || 1;
    const totalCount = searchedProducts?.meta?.totalItems || 0;
    const filterCount = searchedProducts?.filterCount || 0;
    const filterTitle = filterCount > 0 ? intl.formatMessage({ id: "SearchProductsPage.filterSelected" },
      { filterCount: filterCount }) : intl.formatMessage({ id: "SearchProductsPage.noFilterUse" });
    const filterFormat = [
      {
        fieldName: "subCategory",
        shortModeFiled: null,
        nameId: "SearchProductsPage.SubCategoryFilterLabel",
        hide: !subCategories || subCategories.length < 1,
        items: subCategories.map(c => {
          return {
            value: c.categoryCode,
            name: c.name,
          };
        }),
      },
      {
        fieldName: "property",
        shortModeFiled: "propertyShowAll",
        nameId: "SearchProductsPage.ValuesFilterLabel",
        multiselect: true,
        items: dictionaries.productProperties.map(c => {
          return {
            value: c.code,
            name: c.name,
          };
        }),
      },
      {
        fieldName: "shipping",
        shortModeFiled: "shippingShowAll",
        nameId: "SearchProductsPage.ShippingFilterLabel",
        multiselect: true,
        items: dictionaries.leadTimes.map(c => {
          return {
            value: c.code.toString(),
            name: c.name,
          };
        }),
      },
      {
        fieldName: "location",
        shortModeFiled: "locationShowAll",
        multiselect: true,
        nameId: "SearchProductsPage.LocationFilterLabel",
        items: countries.map(c => {
          return {
            value: c.name,
            name: c.name,
          };
        }),
      },
      {
        fieldName: "temperature",
        nameId: "SearchProductsPage.TemperatureFilterLabel",
        multiselect: true,
        items: dictionaries.requiredTemperatures.map(c => {
          return {
            value: c.code.toString(),
            name: c.name,
          };
        }),
      },
      {
        fieldName: "price",
        shortModeFiled: "propertyShowAll",
        nameId: "SearchProductsPage.PriceFilterLabel",
        hide: !isAuthenticated,
        items: [
          { name: "0 - 2 €", value: "0,2" },
          { name: "3 - 5 €", value: "3,5" },
          { name: "6 - 10 €", value: "6,10" },
          { name: "11 - 15 €", value: "11,15" },
          { name: "16 - 20 €", value: "16,20" },
          { name: "21 - 25 €", value: "21,25" },
          { name: "> 26 €", value: "26," }],
      },
    ];

    const emptyStateOptions = {
      containerOffsetClassName: "searchOffset",
      iconStylesClassName: "searchIcon",
      titleMessageId: "SearchProductsPage.noSearchResultTitle",
      textMessageId: "SearchProductsPage.noSearchResultText",
    };

    return (
      <Page
        scrollingDisabled={scrollingDisabled}
        contentType="website"
        description={schemaDescription}
        title={schemaTitle}
        robots='noindex'
        facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
        twitterImages={[
          { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
        ]}
        schema={{
          "@context": "http://schema.org",
          "@type": "WebPage",
          description: schemaDescription,
          name: schemaTitle,
          image: [schemaImage],
        }}
      >
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer productCategoryCode={"featured"} searchInputValue={params.search} />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div className={css.pageContent}>
              <div className={css.topProducts}>
                <div className={css.topProductInfo}>
                  <div className={css.newThisWeekLabel}>
                    <FormattedMessage id="SearchProductsPage.newInWeekLabel" /></div>
                  <div className={css.inCategoryLabel}>Featured</div>
                </div>
                <div className={css.topProductContainer}>
                  {topProducts.map((p) => {
                    const style = {};
                    const images = p.attributes.publicData.productImages;
                    if (images && images.length > 0) {
                      style.backgroundImage = `url('${images[0].url}')`;
                    }
                    if (p.attributes.publicData.mainImageUrl) {
                      style.backgroundImage = `url('${p.attributes.publicData.mainImageUrl}')`;
                    }
                    return (
                      <div key={p.id.uuid} className={css.topProductItem}>
                        <NamedLink name="ProductDetailsPage" params={{ product: slugify(p.attributes.title), id: p?.id?.uuid, locale: locale }}>
                          <div className={css.topProductImage} style={style}>
                            &nbsp;
                          </div>
                        </NamedLink>
                        <div className={css.topProductTitle}>
                          <NamedLink name="ProductDetailsPage" params={{ product: slugify(p.attributes.title), id: p?.id?.uuid, locale: locale }}>
                            {p.attributes.title}
                          </NamedLink>
                        </div>
                        <div className={css.topProductDesc}>
                          {p.attributes.description}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className={css.dataContent}>
                <div className={css.productsHeader}>
                  <h1 className={css.containerTitle}>{productsContainerTitle}</h1>
                  <h5 className={css.containerSubTitle}>
                    {totalCount}&nbsp;
                    <FormattedMessage id="SearchProductsPage.productsLabel" />&nbsp;
                    |&nbsp;
                    {filterTitle}
                  </h5>
                  <div>
                    <button
                      disabled={!this.state.hasFilter}
                      className={css.filterClearButton}
                      onClick={this.onClearFilterClick}
                    >
                      <FormattedMessage id="SearchProductsPage.clearAllFiltersLabel" />
                    </button>
                  </div>
                </div>
                <div className={css.gridContainer}>
                  <div className={css.productFilter}>
                    {filterFormat.filter((f) => (!f.hide)).map((filter) => {
                      const hiddenSeeMoreButton = !filter.shortModeFiled;
                      const showAllItems = hiddenSeeMoreButton || this.state[filter.shortModeFiled] === true;
                      const items = showAllItems ? filter.items : filter.items.slice(0, 3);
                      const showMoreText = showAllItems
                        ? "SearchProductsPage.hideLabel" : "SearchProductsPage.seeMoreLabel";
                      const arrowIconCss = classNames(css.arrow, { [css.up]: showAllItems, [css.down]: !showAllItems });

                      return (
                        <div key={filter.fieldName} className={css.filterFieldItem}>
                          <h4 className={css.filterName}>
                            <FormattedMessage id={filter.nameId} /></h4>
                          {items.map((item) => {
                            const key = `${filter.fieldName}_${item.value}`;
                            const checked = filter.multiselect ? (this.state[filter.fieldName] || []).indexOf(
                              item.value.toString()) > -1 : this.state[filter.fieldName] === item.value;
                            return (
                              <div key={key} className={css.filterOption}>
                                <input
                                  type={filter.multiselect ? "checkbox" : "radio"}
                                  value={item.value}
                                  checked={checked}
                                  name={filter.fieldName} id={key}
                                  onChange={(e) => {
                                    this.onChangeFilter(filter.fieldName, e, filter.multiselect);
                                  }}
                                />
                                <label htmlFor={key}>{item.name}</label>
                              </div>
                            );
                          })}
                          {hiddenSeeMoreButton ?
                            null : (
                              <a
                                className={css.seeMoreButton}
                                onClick={() => {
                                  let s = { ...this.state };
                                  s[filter.shortModeFiled] = !s[filter.shortModeFiled];
                                  this.setState(s);
                                }}
                              >
                                <i className={arrowIconCss} />
                                <FormattedMessage id={showMoreText} />
                              </a>
                            )}
                        </div>
                      );
                    })}
                  </div>
                  <div className={css.productGrid}>
                    <ProductContainer products={viewProducts} rowItemCount={3} emptyStateOptions={emptyStateOptions} />
                  </div>
                </div>
                <div className={css.productPager}>
                  <PaginationButtons
                    totalPages={totalPages}
                    currentPage={currentPage}
                    onPageChange={this.onPageChange}
                  />
                </div>
              </div>
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

FeatureSearchProductsPageComponent.defaultProps = {
  currentUserListing: null,
  currentUserListingFetched: false,
};

FeatureSearchProductsPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
  // from withRouter
  history: object.isRequired,
  location: object.isRequired,
  // from injectIntl
  intl: intlShape.isRequired,
};
const mapStateToProps = state => {
  const {
    topCategoryProductsError,
    topCategoryProductsInProgress,
    searchProductsError,
    searchProductsInProgress,
    topCategoryProducts,
    searchedProducts,
  } = state.FeatureSearchProductsPage;
  const { isAuthenticated } = state.Auth;
  return {
    scrollingDisabled: isScrollingDisabled(state),
    topCategoryProductsError,
    topCategoryProductsInProgress,
    searchProductsError,
    searchProductsInProgress,
    topCategoryProducts,
    searchedProducts,
    isAuthenticated,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onSearchProducts: (values) => dispatch(searchProducts(values)),
});

const FeatureSearchProductsPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
)(FeatureSearchProductsPageComponent);

FeatureSearchProductsPage.loadData = (params) => (dispatch/*, getState, sdk*/) => {
  return Promise.all([dispatch(loadTopCategoryProducts(params)), dispatch(searchProducts(params))]);
};

export default FeatureSearchProductsPage;
