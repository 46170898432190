import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { Form, PrimaryButton, FieldTextInput, NamedLink } from '../../components';
import * as validators from '../../util/validators';

import css from './LoginForm.module.css';

const LoginFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        formId,
        handleSubmit,
        inProgress,
        intl,
        invalid,
        errors,
        submitFailed,
        touched,
        customErrorText,
      } = fieldRenderProps;

      const requiredEmailText = intl.formatMessage({ id: 'LoginForm.emailRequired' });
      const requiredValidEmailText = intl.formatMessage({ id: 'LoginForm.emailValidRequired' });
      const emailRequired = validators.required(requiredEmailText);
      const emailValid = validators.emailFormatValid(requiredValidEmailText);
      const passwordRequired = validators.requiredStringNoTrim(requiredEmailText);

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = invalid || submitInProgress;

      const passwordRecoveryLink = (
        <NamedLink name="PasswordRecoveryPage" className={css.recoveryLink}>
          <FormattedMessage id="LoginForm.forgotPassword" />
        </NamedLink>
      );
      const hasError = (field) => {
        return (errors[field]) && (submitFailed || touched[field]);
      };

      return (
        <Form className={classes} onSubmit={handleSubmit}>

          <div className={css.formFields}>
            <div className={css.formRow}>
              <div className={css.errorTextBlock}>
                {touched['email'] ? customErrorText : null}
              </div>
              <div className={css.formField}>
                <label
                  htmlFor={formId ? `${formId}.email` : 'email'}>
                  <FormattedMessage id="LoginForm.emailLabel" />
                  <span className={css.requiredMarker}>*</span>
                  {hasError('email') && (<span className={css.errorMessage}>{errors['email']}</span>)}

                </label>
                <div className={css.fieldInputContainer}>
                  <FieldTextInput
                    type="text"
                    id={formId ? `${formId}.email` : 'email'}
                    name="email"
                    className={css.email}
                    hasError={hasError('email')}
                    validate={validators.composeValidators(emailRequired, emailValid)}
                  />
                </div>
              </div>
            </div>
            <div className={css.formRow}>
              <div className={css.formField}>
                <label
                  htmlFor={formId ? `${formId}.password` : 'password'}>
                  <FormattedMessage id="LoginForm.passwordLabel" />
                  <span className={css.requiredMarker}>*</span>
                  {hasError('password') && (<span className={css.errorMessage}>{errors['password']}</span>)}
                </label>
                <div className={css.fieldInputContainer}>
                  <FieldTextInput
                    type="password"
                    id={formId ? `${formId}.password` : 'password'}
                    name="password"
                    validate={passwordRequired}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={css.bottomWrapper}>
            <p className={css.bottomWrapperText}>
              <span className={css.recoveryLinkInfo}>
                <FormattedMessage
                  id="LoginForm.forgotPasswordInfo"
                  values={{ passwordRecoveryLink }}
                />
              </span>
            </p>
            <PrimaryButton type="submit" inProgress={submitInProgress} className={css.loginButton} disabled={submitDisabled}>
              <FormattedMessage id="LoginForm.logIn" />
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
);

LoginFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  form: null,
  inProgress: false,
};

const { string, bool } = PropTypes;

LoginFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  form: string,
  inProgress: bool,
  intl: intlShape.isRequired,
};

const LoginForm = compose(injectIntl)(LoginFormComponent);
LoginForm.displayName = 'LoginForm';

export default LoginForm;
