import { countriesMocks } from "../../components/CountryShippingFee/CountryShippingSection";
import { storableError } from "../../util/errors";

import { signup } from "../../ducks/Auth.duck";
import { buyerCreateCompany } from "../../ducks/buyer-company.duck";
import { supplierCreateCompany } from "../../ducks/supplier-company.duck";
import { getRegistrationRequest } from "../../util/api";

// ================ Action types ================ //

export const REGISTRATION_REQUEST_LOAD = "app/registration/request/load";
export const REGISTRATION_REQUEST_LOAD_SUCCESS = "app/registration/request/load/success";
export const REGISTRATION_REQUEST_LOAD_ERROR = "app/registration/request/load/error";

export const REGISTRATION_REQUEST = "app/registration/request/save";
export const REGISTRATION_SUCCESS = "app/registration/request/save/success";
export const REGISTRATION_ERROR = "app/registration/request/save/error";

// ================ Reducer ================ //
const initialState = {
  registrationError: null,
  registrationInProgress: false,
  registered: false,
  registrationRequest: null,
  registrationRequestLoadError: null,
  registrationRequestLoadInProgress: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case REGISTRATION_REQUEST:
      return {
        ...state,
        buyerRegistrationInProgress: true,
        registrationError: null,
        buyerRegistered: false,
      };
    case REGISTRATION_SUCCESS:
      return { ...state, registrationInProgress: false, registered: true };
    case REGISTRATION_ERROR:
      return { ...state, registrationInProgress: false, registrationError: payload };
    case REGISTRATION_REQUEST_LOAD:
      return {
        ...state,
        registrationRequestLoadInProgress: true,
        registrationRequestLoadError: null,
        buyerRegistered: false,
      };
    case REGISTRATION_REQUEST_LOAD_SUCCESS:
      return { ...state, registrationRequestLoadInProgress: false, registrationRequest: payload };
    case REGISTRATION_REQUEST_LOAD_ERROR:
      return { ...state, registrationRequestLoadInProgress: false, registrationRequestLoadError: payload };
    default:
      return state;
  }
}

// ================ Action creators ================ //
export const registrationRequest = () => ({ type: REGISTRATION_REQUEST });
export const registrationSuccess = () => ({ type: REGISTRATION_SUCCESS });
export const registrationError = error => ({
  type: REGISTRATION_ERROR,
  payload: error,
  error: true,
});

export const registrationLoadRequest = () => ({ type: REGISTRATION_REQUEST_LOAD });
export const registrationLoadSuccess = (request) => ({ type: REGISTRATION_REQUEST_LOAD_SUCCESS, payload: request });
export const registrationLoadError = error => ({
  type: REGISTRATION_REQUEST_LOAD_ERROR,
  payload: error,
  error: true,
});

// ================ Thunks ================ //
export const loadRequest = key => (dispatch, getState, sdk) => {
  dispatch(registrationLoadRequest());
  return getRegistrationRequest(key).then(res => {
    dispatch(registrationLoadSuccess(res?.data?.request));
  })
    .catch(e => {
      dispatch(registrationLoadError(storableError(storableError(e))));
      throw e;
    });
};

export const registration = params => (dispatch, getState, sdk) => {
  const { password } = params;
  const request = getState().RegistrationCompletePage.registrationRequest;
  const userItem = { ...request.data, password };

  if (request.type === "buyer") {
    dispatch(buyerRegistration(userItem));
  } else {
    dispatch(supplierRegistration(userItem));
  }
};

const buyerRegistration = params => async (dispatch, getState, sdk) => {
  dispatch(registrationRequest());
  const {
    annualSales,
    businessEMail,
    city,
    companyName,
    companyRegistrationNumber,
    companyVATnumber,
    country,
    countryCustom,
    firstName,
    howDidYouHearAboutVegshelf,
    iAgreeToReceive,
    lastName,
    numberOfStoresYouWorkWith,
    phoneNumber,
    postcode,
    primaryCategory,
    streetAndNumber,
    website,
    password,
    phoneCode,
    companyVATcode,
    primaryCategoryCustom,
    howDidYouHearAboutVegshelfCustom,
    position,
    buyerImportants,
    companyLegalName,
    typeOfBusiness,
    establishedIn,
    typeOfBusinessCustom,
  } = params;

  var buyerItem = {
    email: businessEMail,
    firstName: firstName,
    lastName: lastName,
    password: password,
    displayName: `${firstName} ${lastName}`,
    abbreviatedName: companyName.charAt(0),
    bio: companyName,
    //protected data
    annualSales: annualSales,
    businessEmail: businessEMail,
    city: city,
    companyName: companyName,
    companyRegistrationNumber: companyRegistrationNumber,
    companyVATnumber: companyVATcode ? `${companyVATcode}${companyVATnumber}` : companyVATnumber,
    country: countryCustom ? countryCustom : country,
    howDidYouHearAboutVegshelf: howDidYouHearAboutVegshelfCustom || howDidYouHearAboutVegshelf,
    numberOfStoresYouWorkWith: numberOfStoresYouWorkWith,
    phoneNumber: phoneCode ? `${phoneCode} ${phoneNumber}` : phoneNumber,
    postcode: postcode,
    primaryCategory: primaryCategoryCustom || primaryCategory,
    streetAndNumber: streetAndNumber,
    website: website,
    iAgreeToReceive: !iAgreeToReceive && iAgreeToReceive.length > 0,
    buyerImportants: buyerImportants,
    companyLegalName,
    typeOfBusiness: typeOfBusinessCustom || typeOfBusiness,
    establishedIn,
    userType: "buyer",
    position,
  };

  try {
    await dispatch(signup(buyerItem, true));
    await dispatch(buyerCreateCompany({
      ...buyerItem,
      brandName: companyName,
      numberOfStores: numberOfStoresYouWorkWith,
    }));
    dispatch(registrationSuccess());
  } catch (e) {
    dispatch(registrationError(storableError(e)));
  }
};

const supplierRegistration = params => async (dispatch, getState, sdk) => {
  dispatch(registrationRequest());
  const {
    annualSales,
    brandName,
    businessEMail,
    city,
    companyName,
    companyRegistrationNumber,
    companyVATnumber,
    country,
    countryCustom,
    firstName,
    howDidYouHearAboutVegshelf,
    iAgreeToReceive,
    instagram,
    lastName,
    numberOfStoresYouWorkWith,
    phoneNumber,
    postcode,
    primaryCategory,
    streetAndNumber,
    website,
    password,
    phoneCode,
    companyVATcode,
    primaryCategoryCustom,
    howDidYouHearAboutVegshelfCustom,
    position,
    establishedIn,
    numberOfProducts,
    leadTime,
  } = params;

  const supplierItem = {
    email: businessEMail,
    firstName: firstName,
    lastName: lastName,
    password: password,
    displayName: `${firstName} ${lastName}`,
    abbreviatedName: companyName.charAt(0),
    bio: brandName,
    //protected data
    annualSales: annualSales,
    brandName: brandName,
    businessEmail: businessEMail,
    city: city,
    companyName: companyName,
    companyRegistrationNumber: companyRegistrationNumber,
    companyVATnumber: companyVATcode ? `${companyVATcode}${companyVATnumber}` : companyVATnumber,
    country: countryCustom || country,
    howDidYouHearAboutVegshelf: howDidYouHearAboutVegshelfCustom || howDidYouHearAboutVegshelf,
    instagram: instagram,
    numberOfStoresYouWorkWith: numberOfStoresYouWorkWith,
    phoneNumber: `${phoneCode} ${phoneNumber}`,
    postcode: postcode,
    primaryCategory: primaryCategoryCustom || primaryCategory,
    streetAndNumber: streetAndNumber,
    website: website,
    iAgreeToReceive: !iAgreeToReceive && iAgreeToReceive.length > 0,
    position,
    establishedIn,
    numberOfProducts,
    leadTime,
    userType: "supplier",
    shippingFeeCountries: countriesMocks,
    companyRCSnumber: "",
    companyShareCapital: "",
  };

  try {
    await dispatch(signup(supplierItem, true));
    await dispatch(supplierCreateCompany({
      ...supplierItem,
      countries: country,
      empoyees: "",
      numberOfStores: numberOfStoresYouWorkWith,
    }));
    dispatch(registrationSuccess());
  } catch (e) {
    dispatch(registrationError(storableError(e)));
  }
};
