import { approveProduct } from "../util/api";
import { denormalisedResponseEntities } from "../util/data";
import { types as sdkTypes, util as sdkUtil } from "../util/sdkLoader";
import { currentUserShowSuccess } from "./user.duck";
import config from "../config";
import { LoadBuyerInvoices } from "../containers/BuyerInvoicesPage/BuyerInvoicesPage.duck";

const { UUID/*, Money*/ } = sdkTypes;
const CURRENCY = config.currency;

// ================ Action types ================ //
export const BUYER_COMPANY_CREATE_REQUEST = "app/Buyer/Company/CREATE";
export const BUYER_COMPANY_CREATE_SUCCESS = "app/Buyer/Company/CREATE_SUCCESS";
export const BUYER_COMPANY_CREATE_ERROR = "app/Buyer/Company/CREATE_ERROR";

export const BUYER_COMPANY_UPDATE_REQUEST = "app/Buyer/Company/UPDATE";
export const BUYER_COMPANY_UPDATE_SUCCESS = "app/Buyer/Company/UPDATE_SUCCESS";
export const BUYER_COMPANY_UPDATE_ERROR = "app/Buyer/Company/UPDATE_ERROR";

export const BUYER_GET_CURRENT_COMPANY_REQUEST = "app/Buyer/Company/GET";
export const BUYER_GET_CURRENT_COMPANY_SUCCESS = "app/Buyer/Company/GET_SUCCESS";
export const BUYER_GET_CURRENT_COMPANY_ERROR = "app/Buyer/Company/GET_ERROR";

export const BUYER_UPDATE_COMPANY_LOGO_REQUEST = "app/Buyer/Company/logo/UPDATE";
export const BUYER_UPDATE_COMPANY_LOGO_SUCCESS = "app/Buyer/Company/logo/UPDATE_SUCCESS";
export const BUYER_UPDATE_COMPANY_LOGO_ERROR = "app/Buyer/Company/logo/UPDATE_ERROR";

export const BUYER_UPDATE_COMPANY_COVER_REQUEST = "app/Buyer/Company/cover/UPDATE";
export const BUYER_UPDATE_COMPANY_COVER_SUCCESS = "app/Buyer/Company/cover/UPDATE_SUCCESS";
export const BUYER_UPDATE_COMPANY_COVER_ERROR = "app/Buyer/Company/cover/UPDATE_ERROR";

export const BUYER_DELETE_COMPANY_COVER_REQUEST = "app/Buyer/Company/cover/DELETE";
export const BUYER_DELETE_COMPANY_COVER_SUCCESS = "app/Buyer/Company/cover/DELETE_SUCCESS";
export const BUYER_DELETE_COMPANY_COVER_ERROR = "app/Buyer/Company/cover/DELETE_ERROR";

// ================ Reducer ================ //
const initialState = {
  buyerCreateCompanyError: null,
  buyerCreateCompanyInProgress: false,
  buyerCreatedCompany: false,
  buyerCurrentCompany: null,
  buyerGetCurrentCompanyError: null,
  buyerGetCurrentCompanyInProgress: false,
  buyerUpdateCompanyError: null,
  buyerUpdateCompanyInProgress: false,
  buyerUpdateCompany: false,
  buyerUpdateCompanyLogoError: null,
  buyerUpdateCompanyLogoInProgress: false,
  buyerUpdateCompanyLogo: false,
  buyerUpdateCompanyCoverError: null,
  buyerUpdateCompanyCoverInProgress: false,
  buyerUpdateCompanyCover: false,
  buyerDeleteCompanyCoverError: null,
  buyerDeleteCompanyCoverInProgress: false,
  buyerDeleteCompanyCover: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case BUYER_COMPANY_CREATE_REQUEST:
      return {
        ...state,
        buyerCreateCompanyInProgress: true,
        buyerCreateCompanyError: null,
        buyerCreatedCompany: false,
      };
    case BUYER_COMPANY_CREATE_SUCCESS:
      return { ...state, buyerCreateCompanyInProgress: false, buyerCreatedCompany: true };
    case BUYER_COMPANY_CREATE_ERROR:
      return { ...state, buyerCreateCompanyInProgress: false, buyerCreateCompanyError: payload };
    case BUYER_COMPANY_UPDATE_REQUEST:
      return {
        ...state,
        buyerUpdateCompanyInProgress: true,
        buyerUpdateCompanyError: null,
        buyerUpdateCompany: false,
      };
    case BUYER_COMPANY_UPDATE_SUCCESS:
      return { ...state, buyerUpdateCompanyInProgress: false, buyerUpdateCompany: true };
    case BUYER_COMPANY_UPDATE_ERROR:
      return { ...state, buyerUpdateCompanyInProgress: false, buyerUpdateCompanyError: payload };
    case BUYER_GET_CURRENT_COMPANY_REQUEST:
      return {
        ...state,
        buyerGetCurrentCompanyInProgress: true,
        buyerGetCurrentCompanyError: null,
        // TODO: Why we should assign buyerCurrentCompany to null on every [GET] request?
        // buyerCurrentCompany: null,
      };
    case BUYER_GET_CURRENT_COMPANY_SUCCESS:
      return { ...state, buyerGetCurrentCompanyInProgress: false, buyerCurrentCompany: payload };
    case BUYER_GET_CURRENT_COMPANY_ERROR:
      return { ...state, buyerGetCurrentCompanyInProgress: false, buyerGetCurrentCompanyError: payload };
    case BUYER_UPDATE_COMPANY_LOGO_REQUEST:
      return {
        ...state,
        buyerUpdateCompanyLogoInProgress: true,
        buyerUpdateCompanyLogoError: null,
        buyerUpdateCompanyLogo: false,
      };
    case BUYER_UPDATE_COMPANY_LOGO_SUCCESS:
      return { ...state, buyerUpdateCompanyLogoInProgress: false, buyerUpdateCompanyLogo: true };
    case BUYER_UPDATE_COMPANY_LOGO_ERROR:
      return { ...state, buyerUpdateCompanyLogoInProgress: false, buyerUpdateCompanyLogoError: payload };
    case BUYER_UPDATE_COMPANY_COVER_REQUEST:
      return {
        ...state,
        buyerUpdateCompanyCoverInProgress: true,
        buyerUpdateCompanyCoverError: null,
        buyerUpdateCompanyCover: false,
      };
    case BUYER_UPDATE_COMPANY_COVER_SUCCESS:
      return { ...state, buyerUpdateCompanyCoverInProgress: false, buyerUpdateCompanyCover: true };
    case BUYER_UPDATE_COMPANY_COVER_ERROR:
      return { ...state, buyerUpdateCompanyCoverInProgress: false, buyerUpdateCompanyCoverError: payload };
    case BUYER_DELETE_COMPANY_COVER_REQUEST:
      return {
        ...state,
        buyerDeleteCompanyCoverInProgress: true,
        buyerDeleteCompanyCoverError: null,
        buyerDeleteCompanyCover: false,
      };
    case BUYER_DELETE_COMPANY_COVER_SUCCESS:
      return { ...state, buyerDeleteCompanyCoverInProgress: false, buyerDeleteCompanyCover: true };
    case BUYER_DELETE_COMPANY_COVER_ERROR:
      return { ...state, buyerDeleteCompanyCoverInProgress: false, buyerDeleteCompanyCoverError: payload };
    default:
      return state;
  }
}

// ================ Action creators ================ //
export const buyerCompanyCreateRequest = () => ({ type: BUYER_COMPANY_CREATE_REQUEST });
export const buyerCompanyCreateSuccess = () => ({ type: BUYER_COMPANY_CREATE_SUCCESS });
export const buyerCompanyCreateError = (error) => ({
  type: BUYER_COMPANY_CREATE_ERROR,
  payload: error,
  error: true,
});
export const buyerCompanyUpdateRequest = () => ({ type: BUYER_COMPANY_UPDATE_REQUEST });
export const buyerCompanyUpdateSuccess = () => ({ type: BUYER_COMPANY_UPDATE_SUCCESS });
export const buyerCompanyUpdateError = (error) => ({
  type: BUYER_COMPANY_UPDATE_ERROR,
  payload: error,
  error: true,
});
export const buyerCompanyUpdateLogoRequest = () => ({ type: BUYER_UPDATE_COMPANY_LOGO_REQUEST });
export const buyerCompanyUpdateLogoSuccess = () => ({ type: BUYER_UPDATE_COMPANY_LOGO_SUCCESS });
export const buyerCompanyUpdateLogoError = (error) => ({
  type: BUYER_UPDATE_COMPANY_LOGO_ERROR,
  payload: error,
  error: true,
});
export const buyerCompanyUpdateCoverRequest = () => ({ type: BUYER_UPDATE_COMPANY_COVER_REQUEST });
export const buyerCompanyUpdateCoverSuccess = () => ({ type: BUYER_UPDATE_COMPANY_COVER_SUCCESS });
export const buyerCompanyUpdateCoverError = (error) => ({
  type: BUYER_UPDATE_COMPANY_COVER_ERROR,
  payload: error,
  error: true,
});
export const buyerCompanyDeleteCoverRequest = () => ({ type: BUYER_DELETE_COMPANY_COVER_REQUEST });
export const buyerCompanyDeleteCoverSuccess = () => ({ type: BUYER_DELETE_COMPANY_COVER_SUCCESS });
export const buyerCompanyDeleteCoverError = (error) => ({
  type: BUYER_DELETE_COMPANY_COVER_ERROR,
  payload: error,
  error: true,
});
export const buyerGetCompanyRequest = () => ({ type: BUYER_GET_CURRENT_COMPANY_REQUEST });
export const buyerGetCompanySuccess = (company) => ({ type: BUYER_GET_CURRENT_COMPANY_SUCCESS, payload: company });
export const buyerGetCompanyError = (error) => ({
  type: BUYER_GET_CURRENT_COMPANY_ERROR,
  payload: error,
  error: true,
});


// ================ Thunks ================ //
export const buyerCreateCompany = (params) => (dispatch, getState, sdk) => {
  dispatch(buyerCompanyCreateRequest());
  const {
    brandName,
  } = params;

  const companyItem = {
    title: brandName,
    description: brandName,
    price: { amount: 0, currency: "USD" },
    //  state:'published',
    publicData: {
      ...params,
      dataType: "buyer-company",
      moq: 100,
    },
  };

  const queryParams = {
    expand: true,
    include: ["author"],
  };

  return sdk.ownListings
    .create(companyItem, queryParams)
    .then(response => {
      return approveProduct(response?.data?.data.id).then(r => {
          dispatch(buyerLoadCompany());
          dispatch(buyerCompanyCreateSuccess());
          return response;
        })
        .catch(e => {
          return dispatch(buyerCompanyCreateError(e));
        });
    })
    .catch(e => {
      return dispatch(buyerCompanyCreateError(e));
    });
};

export const buyerUpdateDeliveryAddress = (delivaryData) => (dispatch, getState, sdk) => {
  dispatch(buyerCompanyUpdateRequest());

  const currentCompany = getState().buyerCompany.buyerCurrentCompany;
  const companyData = currentCompany?.attributes;
  const companyDetails = companyData?.publicData;
  const newDeliveryData = { ...delivaryData, country: companyDetails.country };
  const newPublicData = { ...companyData?.publicData, deliveryAddress: newDeliveryData };
  const companyItem = {
    id: currentCompany.id,
    title: companyData.title,
    description: companyData.description,
    price: { amount: 0, currency: "EUR" },
    publicData: newPublicData,
  };

  return sdk.ownListings
    .update(companyItem)
    .then(response => {
      dispatch(buyerLoadCompany());
      dispatch(buyerCompanyUpdateSuccess());
      return response;
    })
    .catch(e => {
      return dispatch(buyerCompanyUpdateError(e));
    });
};

export const buyerUpdateCompany = (params) => (dispatch, getState, sdk) => {
  dispatch(buyerCompanyUpdateRequest());

  const {
    annualSales,
    brandName,
    leadTime,
    description,
    establishedIn,
    employees,
    numberOfStores,
    website,
    primaryCategory,
    buyerImportants,
    typeOfBusiness,
    logoImage,
    city,
    companyLegalName,
    companyRegistrationNumber,
    companyVATnumber,
    country,
    postcode,
    streetAndNumber,
  } = params;

  const currentCompany = getState().buyerCompany.buyerCurrentCompany;
  const currentCompanyId = currentCompany.id;
  const companyData = currentCompany?.attributes;
  const companyDetails = companyData?.publicData;
  let companyItem = {
    id: currentCompanyId,
    title: brandName || currentCompany?.attributes.title,
    description: description || currentCompany?.attributes.description,
    price: { amount: 0, currency: CURRENCY },
    publicData: {
      ...companyDetails,
      dataType: "buyer-company",
      annualSales: annualSales || companyDetails.annualSales,
      brandName: brandName || companyDetails.brandName,
      city: city || companyDetails.city,
      companyLegalName: companyLegalName || companyDetails.companyLegalName,
      companyRegistrationNumber: companyRegistrationNumber || companyDetails.companyRegistrationNumber,
      companyVATnumber: companyVATnumber || companyDetails.companyVATnumber,
      country: country || companyDetails.country,
      postcode: postcode || companyDetails.postcode,
      streetAndNumber: streetAndNumber || companyDetails.streetAndNumber,
      leadTime: leadTime || companyDetails.leadTime,
      description: description || companyDetails.description,
      establishedIn: establishedIn || companyDetails.establishedIn,
      employees: employees || companyDetails.employees,
      buyerImportants: buyerImportants || companyDetails.buyerImportants,
      numberOfStores: numberOfStores || companyDetails.numberOfStores,
      website: website || companyDetails.website,
      primaryCategory: primaryCategory || companyDetails.primaryCategory,
      logoImageId: companyDetails.logoImageId,
      logoUrl: companyDetails.logoUrl,
      coverImageId: companyDetails.coverImageId,
      coverUrl: companyDetails.coverUrl,
      typeOfBusiness: typeOfBusiness || companyDetails.typeOfBusiness,
      moq: 100,
    },
  };
  if (!logoImage) {
    return sdk.ownListings
      .update(companyItem)
      .then((response) => {
        dispatch(buyerLoadCompany());
        dispatch(buyerCompanyUpdateSuccess());
        return response;
      })
      .catch(e => {
        return dispatch(buyerCompanyUpdateError(e));
      });
  }
  return dispatch(buyerUpdateCompanyLogo({ file: logoImage, companyData: companyItem }));
};

export const buyerLoadCompany = (id) => (dispatch, getState, sdk) => {
  // TODO: Infinity loader issue is here. BuyerCompanyId is undefined.
  let authorId = id || getState()?.user?.currentUser?.id;
  if (!authorId) {
    return;
  }
  if (authorId && authorId.authorId) {
    authorId = authorId.authorId;
  }
  dispatch(buyerGetCompanyRequest());
  if (id && typeof id === "string" || id instanceof String) {
    return sdk.listings
      .show({ id: new UUID(authorId) })
      .then((response) => {
        dispatch(buyerGetCompanySuccess(response?.data?.data));
        dispatch(LoadBuyerInvoices({ currentPage: 1 }));
        return response;
      })
      .catch((e) => {
        return dispatch(buyerGetCompanyError(e));
      });
  }
  return sdk.listings
    .query({ authorId, pub_dataType: "buyer-company" })
    .then((response) => {
      if (response?.data?.data.length) {
        dispatch(buyerGetCompanySuccess(response?.data?.data[0]));
        dispatch(LoadBuyerInvoices({ currentPage: 1 }));
      }
      return response;
    })
    .catch((e) => {
      return dispatch(buyerGetCompanyError(e));
    });
};

export function buyerUpdateCompanyLogo(actionPayload) {
  return (dispatch, getState, sdk) => {
    dispatch(buyerCompanyUpdateLogoRequest());
    const queryParams = {
      expand: true,
      "fields.image": ["variants.square-small", "variants.square-small2x", "variants.landscape-crop2x"],
    };
    return sdk.images
      .upload({ image: actionPayload.file }, queryParams)
      .then((resp) => {
        const currentCompany = getState().buyerCompany.buyerCurrentCompany;
        const companyData = currentCompany?.attributes;
        const companyDetails = companyData?.publicData;
        const coverId = companyDetails.coverImageId;
        const newLogoId = resp.data.data.id.uuid;
        const logoUrl = resp.data.data.attributes.variants["square-small2x"].url;
        const images = [resp.data.data.id];
        if (coverId) {
          images.push(new UUID(coverId));
        }
        const storeImages = companyDetails?.storeImages || [];
        if (storeImages && storeImages.length > 0) {
          const items = storeImages.map(i => new UUID(i.id));
          images.push(...items);
        }
        let companyItem = actionPayload.companyData ? actionPayload.companyData : {
          id: currentCompany.id,
          publicData: {},
        };
        companyItem.images = images;
        companyItem.publicData.logoImageId = newLogoId;
        companyItem.publicData.logoUrl = logoUrl;
        return sdk.ownListings
          .update(companyItem)
          .then((response) => {
            dispatch(updateProfileImage({ profileImageId: resp.data.data.id, file: actionPayload.file }));
            dispatch(buyerLoadCompany());
            dispatch(buyerCompanyUpdateLogoSuccess());
            return response;
          })
          .catch((e) => {
            if (actionPayload.companyData)
              dispatch(buyerCompanyUpdateError(e));
            return dispatch(buyerCompanyUpdateLogoError(e));
          });
      })
      .catch((e) => {
        if (actionPayload.companyData)
          dispatch(buyerCompanyUpdateError(e));
        return dispatch(buyerCompanyUpdateLogoError(e));
      });
  };
}

export function buyerUpdateCompanyCover(actionPayload) {
  return (dispatch, getState, sdk) => {
    dispatch(buyerCompanyUpdateCoverRequest());
    const queryParams = {
      expand: true,
      "fields.image": ["variants.buyer-cover"],
      "imageVariant.buyer-cover": sdkUtil.objectQueryString({
        w: 1280,
        h: 270,
        fit: "crop",
      }),
    };
    return sdk.images
      .upload({ image: actionPayload.file }, queryParams)
      .then((resp) => {
        const currentCompany = getState().buyerCompany.buyerCurrentCompany;
        const companyData = currentCompany?.attributes;
        const companyDetails = companyData?.publicData;
        const logoId = companyDetails.logoImageId;
        const newCoverId = resp.data.data.id.uuid;
        const coverUrl = resp.data.data.attributes.variants["buyer-cover"].url;
        const images = [resp.data.data.id];
        if (logoId) {
          images.push(new UUID(logoId));
        }
        const storeImages = companyDetails?.storeImages || [];
        if (storeImages && storeImages.length > 0) {
          const items = storeImages.map(i => new UUID(i.id));
          images.push(...items);
        }
        const companyItem = {
          id: currentCompany.id,
          images: images,
          publicData: { coverImageId: newCoverId, coverUrl: coverUrl },
        };
        return sdk.ownListings
          .update(companyItem)
          .then((response) => {
            dispatch(buyerLoadCompany());
            dispatch(buyerCompanyUpdateCoverSuccess());
            return response;
          })
          .catch((e) => {
            return dispatch(buyerCompanyUpdateCoverError(e));
          });
      })
      .catch((e) => dispatch(buyerCompanyUpdateCoverError(e)));
  };
}

const updateProfileImage = actionPayload => {
  return (dispatch, getState, sdk) => {
    const queryParams = {
      expand: true,
      "fields.image": ["variants.square-small", "variants.square-small2x", "variants.landscape-crop2x"],
    };
    return sdk.images
      .upload({ image: actionPayload.file }, queryParams)
      .then((resp) => {

        return sdk.currentUser
          .updateProfile({ profileImageId: resp.data.data.id }, queryParams)
          .then((response) => {
            const entities = denormalisedResponseEntities(response);
            if (entities.length !== 1) {
              throw new Error("Expected a resource in the sdk.currentUser.updateProfile response");
            }
            const currentUser = entities[0];

            // Update current user in state.user.currentUser through user.duck.js
            dispatch(currentUserShowSuccess(currentUser));

            dispatch(buyerCompanyUpdateSuccess());
          })
          .catch((e) => dispatch(buyerCompanyUpdateLogoError(e)));
      })
      .catch((e) => dispatch(buyerCompanyUpdateLogoError(e)));

  };
};

export function buyerDeleteCompanyCover() {
  return (dispatch, getState, sdk) => {
    dispatch(buyerCompanyDeleteCoverRequest());
    const currentCompany = getState().buyerCompany.buyerCurrentCompany;
    const companyData = currentCompany?.attributes;
    const companyDetails = companyData?.publicData;
    const logoId = companyDetails.logoImageId;
    const images = [];
    if (logoId) {
      images.push(new UUID(logoId));
    }
    const companyItem = {
      id: currentCompany.id,
      images: images,
      publicData: { coverImageId: null, coverUrl: null },
    };
    return sdk.ownListings
      .update(companyItem)
      .then((response) => {
        dispatch(buyerLoadCompany());
        dispatch(buyerCompanyDeleteCoverSuccess());
        return response;
      })
      .catch((e) => {
        return dispatch(buyerCompanyDeleteCoverError(e));
      });
  };
}

export function buyerDeleteCompanyImage(idImage) {
  return (dispatch, getState, sdk) => {
    dispatch(buyerCompanyDeleteCoverRequest());
    const currentCompany = getState().buyerCompany.buyerCurrentCompany;
    const companyData = currentCompany?.attributes;
    const companyDetails = companyData?.publicData;
    const logoId = companyDetails.logoImageId;
    const coverId = companyDetails?.coverImageId;
    let storeImages = companyDetails?.storeImages ? [...companyDetails?.storeImages] : [];
    storeImages = storeImages.filter(img => (img.id !== idImage));
    const images = [];
    if (logoId) {
      images.push(new UUID(logoId));
    }
    if (coverId) {
      images.push(new UUID(coverId));
    }
    storeImages.sort((a, b) => (a.isMain ? -1 : 1));
    images.push(...storeImages.map(x => new UUID(x.id)));
    const companyItem = {
      id: currentCompany.id,
      images: images,
      publicData: { storeImages },
    };
    return sdk.ownListings
      .update(companyItem)
      .then((response) => {
        dispatch(buyerLoadCompany());
        dispatch(buyerCompanyDeleteCoverSuccess());
        return response;
      })
      .catch((e) => {
        return dispatch(buyerCompanyDeleteCoverError(e));
      });
  };
}

export function buyerUpdateCompanyImage(actionPayload) {
  return (dispatch, getState, sdk) => {
    dispatch(buyerCompanyUpdateCoverRequest());
    const queryParams = {
      expand: true,
      "fields.image": ["variants.square-small", "variants.default", "variants.landscape-crop2x"],
    };
    return sdk.images
      .upload({ image: actionPayload.file }, queryParams)
      .then((resp) => {
        const currentCompany = getState().buyerCompany.buyerCurrentCompany;
        const companyData = currentCompany?.attributes;
        const companyDetails = companyData?.publicData;
        const logoId = companyDetails?.logoImageId;
        const coverId = companyDetails?.coverImageId;
        let storeImages = companyDetails?.storeImages ? [...companyDetails?.storeImages] : [];
        const newImageId = resp.data.data.id.uuid;
        const newImageUrl = resp.data.data.attributes.variants["default"].url;
        const images = [];
        if (logoId) {
          images.push(new UUID(logoId));
        }
        if (coverId) {
          images.push(new UUID(coverId));
        }
        let changedImage = false;
        if (storeImages && storeImages.length > 0) {
          for (let i = 0; i < storeImages.length; i++) {
            if (storeImages[i].id == actionPayload.id) {
              storeImages[i].id = newImageId;
              storeImages[i].url = newImageUrl;
              changedImage = true;
              break;
            }
          }
        }
        if (!changedImage) {
          if (actionPayload.isMain) {
            storeImages.splice(0, 0, { id: newImageId, url: newImageUrl, isMain: actionPayload.isMain });

          } else {
            storeImages.push({ id: newImageId, url: newImageUrl, isMain: actionPayload.isMain });
          }
        }
        storeImages.sort((a, b) => (a.isMain ? -1 : 1));
        images.push(...storeImages.map(i => new UUID(i.id)));
        const companyItem = {
          id: currentCompany.id,
          images: images,
          publicData: { storeImages: storeImages },
        };
        return sdk.ownListings
          .update(companyItem)
          .then((response) => {
            dispatch(buyerLoadCompany());
            dispatch(buyerCompanyUpdateCoverSuccess());
            return response;
          })
          .catch((e) => {
            return dispatch(buyerCompanyUpdateCoverError(e));
          });
      })
      .catch((e) => dispatch(buyerCompanyUpdateCoverError(e)));
  };
}
