import classNames from "classnames";
import React from "react";
import { DayPicker } from "react-dates";
import { FormattedMessage } from "react-intl";
import ButtonLoaderComponent from "../ButtonLoader/ButtonLoader";
import css from "./CalendarDialog.module.css";

export const CalendarDialog = ({
  title,
  subTitle,
  selectedDate,
  onDateChange,
  onUpdateClick,
  onGoBackClick,
  transitionInProgress,
}) => {
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);

  const renderCell = (args) => {
    let activeDate = args?.day?.toDate();
    let disabled = true;
    let selected = false;
    if (activeDate != null) {
      activeDate.setHours(0, 0, 0, 0);
      const selDate = new Date(selectedDate);
      selDate.setHours(0, 0, 0, 0);
      disabled = activeDate < currentDate;
      selected = selDate.toDateString() === activeDate.toDateString();
    }

    const cellStyle = classNames(css.calendarCell, { [css.disabledCell]: disabled, [css.selectedCell]: selected });
    const handleOnDayClick = () => {
      if (!disabled) {
        args.onDayClick(args.day);
      }
    };

    return (
      <td key={args.key} className={cellStyle} onClick={handleOnDayClick}>
        <span className={css.cellContent}>
          {args?.day?.date()}
        </span>
      </td>
    );
  };

  return (
    <div className={css.modalCalendarContainer}>
      <div className={css.calendarModal}>
        <div className={css.crossButton} onClick={() => onGoBackClick()} />
        <h1 className={css.calendarTitle}>{title}</h1>
        <h2 className={css.calendarSubTitle}>{subTitle}</h2>
        <div className={css.calendarContainer}>
          <DayPicker
            numberOfMonths={1}
            hideKeyboardShortcutsPanel={true}
            isFocused={true}
            enableOutsideDays={false}
            renderCalendarDay={renderCell}
            onDayClick={(e) => {
              onDateChange(e.toDate());
            }}
          />
        </div>
        <div className={css.buttonsWrapper}>
          <button className={css.secondaryButton} onClick={() => onGoBackClick()}><FormattedMessage id="SupplierOrderPage.goBack" /></button>
          <button className={css.primaryButton} onClick={() => onUpdateClick()} disabled={transitionInProgress}>
            {transitionInProgress ? <ButtonLoaderComponent /> : <FormattedMessage id="SupplierOrderPage.update" />}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CalendarDialog;
