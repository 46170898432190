import React, { useState } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { compose } from "redux";
import { FormattedMessage } from '../../util/reactIntl';
import shopIcon from "../../assets/local-shops-germany-france-belgium-netherlands.png";
import brandIcon from "../../assets/best-vegan-food-brands.png";
import logoImage from "../../assets/vegshelf-black.svg";
import { checkUserEmail, displayRegistrationConfirmDialog } from "../../ducks/Modal.duck";
import { ValidateEmailForm } from "../../forms";
import NamedRedirect from "../NamedRedirect/NamedRedirect";
import css from "./RegistrationConfirmDialog.module.css";

import checkIcon from "../../assets/check2-000000.svg";

export const RegistrationConfirmDialogComponent = (props) => {
  const {
    showRegistrationConfirmDialog,
    hideModal,
    registrationModeBuyer,
    onValidedEmailAddress,
    validateEmailInProgress,
    validationEmailHasError,
    validedEmail,
  } = props;

  const buyerSignupButton = <FormattedMessage id="BuyerRegistrationModal.buttonText" />;
  const supplierSignupButton = <FormattedMessage id="SupplierRegistrationModal.buttonText" />;

  if (validedEmail) {
    //return (<Redirect to={(registrationModeBuyer ? "/buyer-registration" : "/supplier-registration")} />);
    return (<NamedRedirect name={(registrationModeBuyer ? "BuyerRegistrationPage" : "SupplierRegistrationPage")} />)
  }

  // 16.07.23 Vegshelf freeze: added state check to show thank you text upon e-mail and name sent
  const [formSubmitted, setFormSubmitted] = useState(false);

  return (
    <>
      {showRegistrationConfirmDialog === true ? (
        <div className={css.registrationConfirmModalContainer} onClick={hideModal}>
          {/* Buyer */}
          {registrationModeBuyer ? (
            <div className={css.registrationConfirmModal} onClick={(e) => e.stopPropagation()}>
              <button className={css.closeButton} onClick={hideModal} />
              <div className={css.modalContent}>
                <div className={css.buyerLeftBlock}>
                  <img className={css.logoImage} src={logoImage} alt="Vegshelf" />
                  <img className={css.shopIcon} src={shopIcon} alt="Sign up to get a full access to the marketplace. It’s easy, fast & free." />
                  <ul>
                    <li>
                      <img className={css.checkIcon} src={checkIcon} alt=""></img><div className={css.modalText}><FormattedMessage id="BuyerRegistrationModal.uspInfo1" /></div>
                    </li>
                    <li>
                      <img className={css.checkIcon} src={checkIcon} alt=""></img><div className={css.modalText}><FormattedMessage id="BuyerRegistrationModal.uspInfo2" /></div>
                    </li>
                    <li>
                      <img className={css.checkIcon} src={checkIcon} alt=""></img><div className={css.modalText}><FormattedMessage id="BuyerRegistrationModal.uspInfo3" /></div>
                    </li>
                  </ul>
                </div>
                <div className={css.rightBlock}>
                  <div className={css.registrationHeader}>
                    <div className={css.rightBlockTitle}>
                      <FormattedMessage id="BuyerRegistrationModal.registrationTitle" />
                    </div>
                    <div className={css.rightBlockParagraph}>
                      <FormattedMessage id="BuyerRegistrationModal.registrationBody1" />
                      <br />
                    </div>
                  </div>

                  {/* 16.07.23 Vegshelf freeze: added formSubmitted to show thank you text upon form submission */}

                  {!formSubmitted ?
                    <>
                      <ValidateEmailForm
                        emailExists={validationEmailHasError}
                        inProgress={validateEmailInProgress}
                        buttonText={buyerSignupButton}
                        hideModal={hideModal}

                        // 16.07.23 Vegshelf freeze: changed checkUserEmail to add firstName as part of freeze changes
                        // 16.07.23 Vegshelf freeze: added state check to show thank you text upon e-mail and name sent
                        onSubmit={({ email, firstName }) => { onValidedEmailAddress(email, firstName); setFormSubmitted(true) }}
                      />
                      <p className={css.terms}>
                        <FormattedMessage id="BuyerRegistrationModal.terms" />
                      </p>
                    </>
                    :
                    <div className={css.registrationHeader}>
                      <div className={css.rightBlockParagraph}>
                        <FormattedMessage id="BuyerRegistrationModal.thankYouForRegistration" />
                        <br />
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
          ) : (
            <div className={css.registrationConfirmModal} onClick={(e) => e.stopPropagation()}>
              {/* Supplier */}
              <button className={css.closeButton} onClick={hideModal} />
              <div className={css.modalContent}>
                <div className={css.supplierLeftBlock}>
                  <img className={css.logoImage} src={logoImage} alt="Vegshelf" />
                  <img className={css.brandIcon} src={brandIcon} alt="Sign up to get a full access to the marketplace. It’s easy, fast & free." />
                  <ul>
                    <li>
                      <img className={css.checkIcon} src={checkIcon} alt=""></img><div className={css.modalText}><FormattedMessage id="SupplierRegistrationModal.uspInfo1" /></div>
                    </li>
                    <li>
                      <img className={css.checkIcon} src={checkIcon} alt=""></img><div className={css.modalText}><FormattedMessage id="SupplierRegistrationModal.uspInfo2" /></div>
                    </li>
                    <li>
                      <img className={css.checkIcon} src={checkIcon} alt=""></img><div className={css.modalText}><FormattedMessage id="SupplierRegistrationModal.uspInfo3" /></div>
                    </li>
                  </ul>
                </div>
                <div className={css.rightBlock}>
                  <div className={css.registrationHeader}>
                    <div className={css.rightBlockTitle}>
                      <FormattedMessage id="SupplierRegistrationModal.registrationTitle" />
                    </div>
                    <div className={css.rightBlockParagraph}>
                      <FormattedMessage id="SupplierRegistrationModal.registrationBody1" />
                      <br />
                    </div>
                  </div>

                  {/* 16.07.23 Vegshelf freeze: added formSubmitted to show thank you text upon form submission */}

                  {!formSubmitted ?
                    <>
                      <ValidateEmailForm
                        emailExists={validationEmailHasError}
                        inProgress={validateEmailInProgress}
                        buttonText={supplierSignupButton}
                        hideModal={hideModal}

                        // 16.07.23 Vegshelf freeze: changed checkUserEmail to add firstName as part of freeze changes
                        // 16.07.23 Vegshelf freeze: added state check to show thank you text upon e-mail and name sent
                        onSubmit={({ email, firstName }) => { onValidedEmailAddress(email, firstName); setFormSubmitted(true) }}
                      />
                      <p className={css.terms}>
                        <FormattedMessage id="BuyerRegistrationModal.terms" />
                      </p>
                    </>
                    :
                    <div className={css.registrationHeader}>
                      <div className={css.rightBlockParagraph}>
                        <FormattedMessage id="BuyerRegistrationModal.thankYouForRegistration" />
                        <br />
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
          )}
        </div>
      ) : null}
    </>
  );
};

const mapStateToProps = (state) => {
  const {
    showRegistrationConfirmDialog,
    validedEmail,
    registrationModeBuyer,
    validateEmailInProgress,
    validationEmailHasError,
  } = state.Modals;

  return {
    showRegistrationConfirmDialog,
    validedEmail,
    registrationModeBuyer,
    validateEmailInProgress,
    validationEmailHasError,
  };
};

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(displayRegistrationConfirmDialog(false)),

  // 16.07.23 Vegshelf freeze: changed checkUserEmail to add firstName as part of freeze changes

  onValidedEmailAddress: (email, firstName) => dispatch(checkUserEmail(email, firstName)),
});

const RegistrationConfirmDialog = compose(
  connect(mapStateToProps, mapDispatchToProps),
)(RegistrationConfirmDialogComponent);

export default RegistrationConfirmDialog;