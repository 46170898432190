import React from 'react';

import css from './LocationAutocompleteInput.module.css';
import glassIcon from '../../assets/search-a3a09b.svg'
const IconHourGlass = () => (
  <img
    className={css.iconSvg}
    width="14"
    height="14"
    src={glassIcon}
    alt="Search"
  />
);

export default IconHourGlass;
