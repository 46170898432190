import classNames from "classnames";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Form as FinalForm } from "react-final-form";
import { compose } from "redux";
import { FieldSelect, FieldTextInput, Form, PrimaryButton } from "../../components";
import getNumberOfStores, { getLeadTime } from "../../translations/numberOfStores";
import { FormattedMessage, injectIntl, intlShape } from "../../util/reactIntl";
import * as validators from "../../util/validators";

import css from "./SupplierCompanyForm.module.css";

export class SupplierCompanyFormComponent extends Component {
  constructor(props) {
    super(props);
    this.preSubmit = this.preSubmit.bind(this);
    this.state = { promisePreviewLogo: null };
  }
  preSubmit(form, mode) {
    form.change("country", this.props.currentCompany?.attributes?.publicData?.country);
    form.change("handlerType", mode);
  }

  render() {
    return (
      <FinalForm{...this.props} render={(formRenderProps) => {
        const {
          rootClassName,
          className,
          formId,
          handleSubmit,
          inProgress,
          invalid,
          values,
          submitFailed,
          form,
          touched,
          currentCompany,
        } = formRenderProps;

        // const countries = getCountryCodes();
        // const annualSales = getAnnualSales();
        const numberOfStores = getNumberOfStores();
        // const employees = getEmployees();
        const leadTimes = getLeadTime();
        const classes = classNames(rootClassName, className);
        const submitInProgress = inProgress;
        const submitDisabled = (submitFailed && invalid) || submitInProgress;
        const required = validators.required("*");
        const hasError = (field) => {
          return (values[field] === undefined || (values[field] instanceof Array && !values[field].length))
            && (submitFailed || touched[field]);
        };

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <div>
              <div className={css.formTwoColumnsPart}>
                <div className={css.formFirstColumn}>
                  <div className={css.formField}>
                    <label htmlFor={formId ? `${formId}.brandName` : "brandName"}>
                      <FormattedMessage id="UserFields.brandName" />
                      <span className={css.requiredMarker}>*</span>
                      {hasError("brandName") && (
                        <span className={css.errorMessage}>
                          <FormattedMessage id="RequiredField.warningText" />
                        </span>
                      )}
                    </label>
                    <div>
                      <FieldTextInput
                        type="text"
                        id={formId ? `${formId}.brandName` : "brandName"}
                        name="brandName"
                        defaultValue={currentCompany?.attributes?.publicData?.brandName}
                        validate={required}
                      />
                    </div>
                  </div>
                  <div className={css.formField}>
                    <label htmlFor={formId ? `${formId}.city` : "city"}>
                      <FormattedMessage id="UserFields.city" />
                      <span className={css.requiredMarker}>*</span>
                      {hasError("city") && (
                        <span className={css.errorMessage}>
                          <FormattedMessage id="RequiredField.warningText" />
                        </span>
                      )}
                    </label>
                    <div>
                      <FieldTextInput
                        type="text"
                        id={formId ? `${formId}.city` : "city"}
                        name="city"
                        defaultValue={currentCompany?.attributes?.publicData?.city}
                        validate={required}
                      />
                    </div>
                  </div>
                  <div className={css.formField}>
                    <label htmlFor={formId ? `${formId}.country` : "country"}>
                      <FormattedMessage id="UserFields.country" />
                    </label>
                    <div>
                      <FieldTextInput
                        type="text"
                        id={formId ? `${formId}.country` : "country"}
                        name="country" className={css.readonlyInput}
                        defaultValue={currentCompany?.attributes?.publicData?.country}
                        readOnly="readonly"
                      />
                    </div>
                  </div>
                  <div className={css.formField}>
                    <label htmlFor={formId ? `${formId}.establishedIn` : "establishedIn"}>
                      <FormattedMessage id="UserFields.yearEstablished" />
                      <span className={css.requiredMarker}>*</span>
                      {hasError("establishedIn") && (
                        <span className={css.errorMessage}>
                          <FormattedMessage id="RequiredField.warningText" />
                        </span>
                      )}
                    </label>
                    <div>
                      <FieldTextInput
                        type="text"
                        id={formId ? `${formId}.establishedIn` : "establishedIn"}
                        name="establishedIn"
                        defaultValue={currentCompany?.attributes?.publicData?.establishedIn}
                        validate={required}
                      />
                    </div>
                  </div>
                </div>
                <div className={css.formSecondColumn}>
                  <div className={css.formField}>
                    <label htmlFor={formId ? `${formId}.leadTime` : "leadTime"}>
                      <FormattedMessage id="UserFields.leadTime" />
                      <span className={css.requiredMarker}>*</span>
                      {hasError("leadTime") && (
                        <span className={css.errorMessage}>
                          <FormattedMessage id="RequiredField.warningText" />
                        </span>
                      )}
                    </label>
                    <div>
                      <FieldSelect
                        id={formId ? `${formId}.leadTime` : "leadTime"}
                        name="leadTime"
                        validate={validators.required("*")}
                        defaultValue={currentCompany?.attributes?.publicData?.leadTime || leadTimes[0].name}
                      >
                        {leadTimes.map((c) => (
                          <option key={c.name} value={c.name}>
                            {c.name}
                          </option>
                        ))}
                      </FieldSelect>
                    </div>
                  </div>
                  <div className={css.formField}>
                    <label htmlFor={formId ? `${formId}.MOQ` : "MOQ"}>
                      <FormattedMessage id="SupplierCompanyForm.labelMinOrderValue" />
                    </label>
                    <div>
                      <FieldTextInput
                        type="text"
                        id={formId ? `${formId}.MOQ` : "MOQ"}
                        name="MOQ" className={css.readonlyInput}
                        defaultValue={currentCompany?.attributes?.publicData?.moq}
                        readOnly="readonly"
                      />
                    </div>
                  </div>

                  <div className={css.formField}>
                    <label htmlFor={formId ? `${formId}.numberOfStores` : "numberOfStores"}>
                      <FormattedMessage id="UserFields.numberOfStockists" />
                      <span className={css.requiredMarker}>*</span>
                      {hasError("numberOfStores") && (
                        <span className={css.errorMessage}>
                          <FormattedMessage id="RequiredField.warningText" />
                        </span>
                      )}
                    </label>
                    <div>
                      <FieldSelect
                        id={formId ? `${formId}.numberOfStores` : "numberOfStores"}
                        name="numberOfStores"
                        validate={validators.required("*")}
                        defaultValue={currentCompany?.attributes?.publicData?.numberOfStores || numberOfStores[0].name}
                      >
                        {numberOfStores.map((c) => (
                          <option key={c.name} value={c.name}>
                            {c.name}
                          </option>
                        ))}
                      </FieldSelect>
                    </div>
                  </div>
                  <div className={css.formField}>
                    <label htmlFor={formId ? `${formId}.instagram` : "instagram"}>
                      <FormattedMessage id="UserFields.instagram" />
                      <span className={css.requiredMarker}>*</span>
                      {hasError("instagram") && (
                        <span className={css.errorMessage}>
                          <FormattedMessage id="RequiredField.warningText" />
                        </span>
                      )}
                    </label>
                    <div>
                      <FieldTextInput
                        type="text"
                        id={formId ? `${formId}.instagram` : "instagram"}
                        name="instagram"
                        defaultValue={currentCompany?.attributes?.publicData?.instagram}
                        validate={required}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className={css.formField}>
                  <label
                    htmlFor={formId ? `${formId}.description` : "description"}>
                    <FormattedMessage id="SupplierCompanyForm.labelCompanyDescription" />
                    <span className={css.requiredMarker}>*</span>
                    <span className={css.labelDescriptionMaxSize}>
                      <FormattedMessage id="SupplierCompanyForm.labelCompanyDescriptionSize" />
                    </span>
                    {hasError("description") && (
                      <span className={css.errorMessage}>
                        <FormattedMessage id="RequiredField.warningText" />
                      </span>
                    )}
                  </label>
                  <div>
                    <FieldTextInput
                      className={css.descriptionInput}
                      type="textarea"
                      id={formId ? `${formId}.description` : "description"}
                      name="description"
                      maxLength="850"
                      defaultValue={currentCompany?.attributes?.publicData?.description}
                      validate={required}
                    />
                  </div>
                </div>
              </div>

              <div className={css.formButtonsPart}>
                <PrimaryButton
                  type="submit"
                  className={css.submitButton}
                  onClick={() => {
                    this.preSubmit(form, "save");
                  }}
                  inProgress={submitInProgress}
                  disabled={submitDisabled}
                >
                  <FormattedMessage id="SupplierCompanyForm.saveChanges" />
                </PrimaryButton>
              </div>
            </div>
          </Form>
        );
      }}
      />
    );
  }
}

SupplierCompanyFormComponent.defaultProps = { inProgress: false };

const { bool } = PropTypes;

SupplierCompanyFormComponent.propTypes = {
  inProgress: bool,
  // from injectIntl
  intl: intlShape.isRequired,
};

const SupplierCompanyForm = compose(injectIntl)(SupplierCompanyFormComponent);

SupplierCompanyForm.displayName = "SupplierCompanyForm";

export default SupplierCompanyForm;
