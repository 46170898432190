import { types as sdkTypes } from '../util/sdkLoader';
import { getNotificationSettings,getNotifications,saveNotificationSettings,sendNotification } from '../util/api'
import routeConfiguration from '../routeConfiguration';
import { pathByRouteName, createResourceLocatorString } from '../util/routes';
import config from '../config';
const { UUID } = sdkTypes;

// ================ Action types ================ //
export const NOTIFICATION_SETTINGS_LOAD_REQUEST = 'app/notification/settings/load/request';
export const NOTIFICATION_SETTINGS_LOAD_SUCCESS = 'app/notification/settings/load/success';
export const NOTIFICATION_SETTINGS_LOAD_ERROR = 'app/notification/settings/load/error';

export const NOTIFICATION_SETTINGS_SAVE_REQUEST = 'app/notification/settings/save/request';
export const NOTIFICATION_SETTINGS_SAVE_SUCCESS = 'app/notification/settings/save/success';
export const NOTIFICATION_SETTINGS_SAVE_ERROR = 'app/notification/settings/save/error';

export const NOTIFICATION_SEND_REQUEST = 'app/notification/send/request';
export const NOTIFICATION_SEND_SUCCESS = 'app/notification/send/success';
export const NOTIFICATION_SEND_ERROR = 'app/notification/send/error';

export const NOTIFICATIONS_LOAD_REQUEST = 'app/notifications/load/request';
export const NOTIFICATIONS_LOAD_SUCCESS = 'app/notifications/load/success';
export const NOTIFICATIONS_LOAD_ERROR = 'app/notifications/load/error';

// ================ Reducer ================ //

const initialState = {
  notificationSettingsError: null,
  notificationSettingsInProgress: false,
  notificationSettings: null,
  notificationSendInProgress: false,
  notificationSendError: null,
  notificationSaveSettingsInProgress: false,
  notificationSaveSettingsError: null,
  notificationLoadInProgress: false,
  notificationLoadError: null,
  notifications: []
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case NOTIFICATION_SETTINGS_LOAD_REQUEST:
        return {
            ...state,
            notificationSettingsError: null,
            notificationSettingsInProgress: true,
            notificationSettings: null,
        };
    case NOTIFICATION_SETTINGS_LOAD_SUCCESS:
        return { ...state, notificationSettingsInProgress: false, notificationSettings: payload };
    case NOTIFICATION_SETTINGS_LOAD_ERROR:
        return { ...state, notificationSettingsInProgress: false, notificationSettingsError: payload };
    case NOTIFICATIONS_LOAD_REQUEST:
        return {
            ...state,
            notificationLoadInProgress: true,
            notificationLoadError: null,
            notifications: []
        };
    case NOTIFICATIONS_LOAD_SUCCESS:
      if(payload?.length === 0 && state?.notifications?.length === 0){
        return state;
      }
        return { ...state, notificationLoadInProgress: false, notifications: payload };
    case NOTIFICATIONS_LOAD_ERROR:
        return { ...state, notificationLoadInProgress: false, notificationLoadError: payload };
    case NOTIFICATION_SETTINGS_SAVE_REQUEST:
        return {
            ...state,
            notificationSaveSettingsInProgress: true,
            notificationSaveSettingsError: null,
        };
    case NOTIFICATION_SETTINGS_SAVE_SUCCESS:
        return { ...state, notificationSaveSettingsInProgress: false };
    case NOTIFICATION_SETTINGS_SAVE_ERROR:
        return { ...state, notificationSaveSettingsInProgress: false, notificationSaveSettingsError: payload };
    case NOTIFICATION_SEND_REQUEST:
        return {
            ...state,
            notificationSendInProgress: false,
            notificationSendError: null,
        };
    case NOTIFICATION_SEND_SUCCESS:
        return { ...state, notificationSendInProgress: false };
    case NOTIFICATION_SEND_ERROR:
        return { ...state, notificationSendInProgress: false, notificationSendError: payload };
    default:
      return state;
  }
}

// ================ Action creators ================ //
export const notificationSettingsLoadRequest = () => ({ type: NOTIFICATION_SETTINGS_LOAD_REQUEST });
export const notificationSettingsLoadSuccess = (settings) => ({ type: NOTIFICATION_SETTINGS_LOAD_SUCCESS, payload: settings });
export const notificationSettingsLoadError = error => ({
  type: NOTIFICATION_SETTINGS_LOAD_ERROR,
  payload: error,
  error: true,
});

export const notificationsLoadRequest = () => ({ type: NOTIFICATIONS_LOAD_REQUEST });
export const notificationsLoadSuccess = (settings) => ({ type: NOTIFICATIONS_LOAD_SUCCESS, payload: settings });
export const notificationsLoadError = error => ({
  type: NOTIFICATIONS_LOAD_ERROR,
  payload: error,
  error: true,
});

export const notificationSettingsSaveRequest = () => ({ type: NOTIFICATION_SETTINGS_SAVE_REQUEST });
export const notificationSettingsSaveLoadSuccess = () => ({ type: NOTIFICATION_SETTINGS_SAVE_SUCCESS });
export const notificationSettingsSaveLoadError = error => ({
  type: NOTIFICATION_SETTINGS_SAVE_ERROR,
  payload: error,
  error: true,
});

export const notificationsSendRequest = () => ({ type: NOTIFICATION_SEND_REQUEST });
export const notificationsSendSuccess = () => ({ type: NOTIFICATION_SEND_SUCCESS });
export const notificationsSendError = error => ({
  type: NOTIFICATION_SEND_ERROR,
  payload: error,
  error: true,
});
// ================ Thunks ================ //

export const notificationSettingsLoad = () => async (dispatch, getState, sdk) => {
  dispatch(notificationSettingsLoadRequest());
  try{
    const response = await getNotificationSettings();
    dispatch(notificationSettingsLoadSuccess(response.data));

  } catch(ex){
    dispatch(notificationSettingsLoadError(ex));
  }
};
export const notificationOptionChange = (option, value) => async (dispatch, getState, sdk) => {
  const currentSettings = getState().notifications.notificationSettings || {};
  let newSettings = {...currentSettings};
  newSettings[option] = value;
  dispatch(notificationSettingsLoadSuccess(newSettings));
};

export const notificationSettingsSave = (settings) => async (dispatch, getState, sdk) => {
  dispatch(notificationSettingsSaveRequest());
  
  try{
    await saveNotificationSettings(settings);
    dispatch(notificationSettingsSaveLoadSuccess());

  } catch(ex){
    dispatch(notificationSettingsSaveLoadError(ex));
  }
};

export const notificationSend = (id, type, message, customData) => (dispatch, getState, sdk) => {
  dispatch(notificationsSendRequest());
  
    sendNotification(id, type, message, customData).then(()=> {
      dispatch(notificationsSendSuccess());
    }).catch((ex)=> {
      dispatch(notificationsSendError(ex));
    });
};
let waiterHandlerKey = null;
export const waitNotifications = () => async (dispatch, getState, sdk) => {
  runWaiter(dispatch);
};
export const stopNotifications = () => {
  if(waiterHandlerKey){
    clearTimeout(waiterHandlerKey);
    waiterHandlerKey = null;
  }

}
const runWaiter = (dispatch) => {
  if(waiterHandlerKey){
    clearTimeout(waiterHandlerKey);
    waiterHandlerKey = null;
  }
  waiterHandlerKey = setTimeout(notificationLoad.bind(null, dispatch), 2000);
};

const notificationLoad = async (dispatch) => {

  //dispatch(notificationsLoadRequest());
  try{
    const response = await getNotifications();
    dispatch(notificationsLoadSuccess(response.data));
    runWaiter(dispatch);
  } catch(ex){
    dispatch(notificationsLoadError(ex));
  }
};
function formatPrice(price){
  const msrp = parseFloat(price);
  return (Math.round(msrp * 100) / 100).toFixed(2);
}
function createUrl(name, params){
  const rootURL = config.canonicalRootURL;
  const routes = routeConfiguration();
  const path = createResourceLocatorString(name,
    routes,
    params,
    {}
  );
  const root = rootURL ? rootURL.replace(/\/$/, '') : '';
  return `${root}${path}`;
}
export const sendNewOrderNotification = (brand, cartInfo, locale) => (dispatch, getState, sdk) =>{
  const customData = {...brand, cartInfo, responseUrl: createUrl('SupplierOrderRequestsPage', {locale}) };
 dispatch(notificationSend(brand.brandId, 'new-order-requests', `New order request: ${brand.products[0].productName} (${formatPrice(cartInfo.orderTotalInclVAT)} €)`, customData));
};
export const sendUpdatesOnMyOrdersNotification = (idCustomer, brand, newStatus, id, locale) => (dispatch, getState, sdk) =>{
  const customData = {...brand, id, newStatus, totalSum: formatPrice(brand.brandTotal + brand.shippingFee + brand.brandVAT), responseUrl: createUrl('BuyerOrderRequestsPage', {locale})};
 dispatch(notificationSend(idCustomer, 'updates-on-my-orders', `Your order "${brand.products[0].productName} (${formatPrice(brand.brandTotal + brand.shippingFee + brand.brandVAT)} €)" is ${newStatus}`, customData));
};
export const sendNewMessageNotification = (idSender, idReceiver, senderName, message, locale) => (dispatch, getState, sdk) =>{
  const isSupplier = getState()?.user?.currentUserType !== 'supplier';
  const customData = { responseUrl: createUrl(isSupplier ? 'SupplierSendMessagePage' : 'BuyerSendMessagePage', {
    title: 'Re',
    receiver: idSender,
    locale
  })};
 dispatch(notificationSend(idReceiver, 'new-message', `${senderName}: ${message}`, customData));
};