// This is modal file is for limiting shopping cart to single brand

import React, { useEffect, useState } from 'react';
import css from './SingleProductCartDialog.module.css';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { displaySingleProductCartDialog } from '../../ducks/CartModal.duck';
import { FormattedMessage } from '../../util/reactIntl';

export const SingleProductCartDialogComponent = props => {
  const {
    showSingleProductCartDialog,
    hideModal,
    onFavouriteButtonClick,
  } = props;

  return (<>
    {showSingleProductCartDialog === true ? (
      <div className={css.singleProductCartModalContainer} onClick={hideModal}>
        <div className={css.singleProductCartModal} onClick={(e) => e.stopPropagation()}>
          <button className={css.closeButton} onClick={hideModal} />
          <div className={css.modalContent}>
            <div className={css.singleProductCartModalTitle}>
              <FormattedMessage id="SingleProductCartModal.modalTitle" />
            </div>
            <div className={css.singleProductCartModalSubTitle1}>
              <FormattedMessage id="SingleProductCartModal.modalSubTitle1Text1" />
              <div className={css.underline}>
                <FormattedMessage id="SingleProductCartModal.modalSubTitle1Text2" />
              </div>
              <FormattedMessage id="SingleProductCartModal.modalSubTitle1Text3" />
            </div>
            <div className={css.singleProductCartModalSubTitle2}>
              <FormattedMessage id="SingleProductCartModal.modalSubTitle2" />
            </div>
            <div className={css.buttonsWrapper}>
              <button className={css.goBackButton} onClick={hideModal}>
                <FormattedMessage id="SingleProductCartModal.goBackButton" />
              </button>
              <button className={css.saveInFavouritesButton} onClick={() => { onFavouriteButtonClick(); hideModal() }}>
                <div className={css.heartIcon}></div>
                <FormattedMessage id="SingleProductCartModal.saveInFavouritesButton" />
              </button>
              {/*<a className={css.registrationButton} onClick={onFavouriteButtonClick}>Register</a>*/}
            </div>
          </div>
        </div>
      </div>) : null}</>
  );
};

const mapStateToProps = state => {
  return {
    showSingleProductCartDialog: state.CartModal.showSingleProductCartDialog
  };
};

const mapDispatchToProps = dispatch => ({
  hideModal: () => dispatch(displaySingleProductCartDialog(false))
});

const SingleProductCartDialog = compose(
  connect(mapStateToProps, mapDispatchToProps),
)(SingleProductCartDialogComponent);

export default SingleProductCartDialog;
