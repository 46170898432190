import classNames from "classnames";
import { bool, object } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import facebookImage from "../../assets/VegshelfFacebook-1200x630.png";
import twitterImage from "../../assets/VegshelfTwitter-600x314.png";
import {
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  NamedLink,
  Page,
} from "../../components";
import config from "../../config";
import { TopbarContainer } from "../../containers";
import { buyerLoadCompany } from "../../ducks/buyer-company.duck";
import { isScrollingDisabled } from "../../ducks/UI.duck";
import { FormattedMessage, injectIntl, intlShape } from "../../util/reactIntl";
import { propTypes } from "../../util/types";
import { storedLocale, localePart } from "../../components/Wrapper/ClassWrapper";
import { getBuyerImportants } from '../../translations/numberOfStores';
import css from "./BuyerDetailsPage.module.css";

const locale = storedLocale() || localePart();

export class BuyerDetailsPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { stepIndex: 0, displayUpdateForm: false };
  }

  render() {
    const { intl, scrollingDisabled, buyerCurrentCompany, isAuthenticated, currentUserType } = this.props;
    const siteTitle = config.siteTitle;
    const companyData = buyerCurrentCompany?.attributes;
    const companyDetails = companyData?.publicData;
    const storeImages = companyDetails?.storeImages ? [...companyDetails?.storeImages] : [];
    let hasFirst = !!storeImages[0]?.isMain;
    for (let i = storeImages.length - 1; i < 2; i++) {
      if (hasFirst) {
        storeImages.push({ id: 0 - i });
      } else {
        storeImages.splice(0, 0, { id: 0 - i });
        hasFirst = true;
      }
    }
    const images = storeImages.map((img, idx) => {
      const coverStyles = {};
      if (img?.url) {
        coverStyles.backgroundImage = `url('${img?.url}')`;
      } else {
        coverStyles.backgroundColor = `#f8f8e8`;
      }

      return (
        <div
          className={classNames(css.imageItem, { [css.mainShopImage]: idx === 0, [css.secondShopImage]: idx > 0 })}
          style={coverStyles} key={img.id}
        >
          &nbsp;
        </div>
      );
    });
    const buyerStoreName = companyDetails?.brandName;
    const schemaTitle = intl.formatMessage({ id: "BuyerDetailsPage.schemaTitle" }, { buyerStoreName, siteTitle });
    const schemaDescription = intl.formatMessage({ id: "BuyerDetailsPage.schemaDescription" }, { buyerStoreName, siteTitle });
    const schemaImage = `${config.canonicalRootURL}${facebookImage}`;
    const coverStyles = {};
    const companyLogo = {};
    const hasLogo = !!companyDetails?.logoUrl;
    if (companyDetails?.coverUrl) {
      coverStyles.backgroundImage = `url('${companyDetails?.coverUrl}')`;
    } else {
      coverStyles.backgroundColor = `#f8f8e8`;
    }
    if (companyDetails?.logoUrl) {
      companyLogo.backgroundImage = `url('${companyDetails?.logoUrl}')`;
    } else {
      companyLogo.backgroundColor = `#ffffff`;
    }

    const dictionaries = getBuyerImportants();
    const properties = companyDetails?.buyerImportants ? dictionaries.filter(
      l => companyDetails?.buyerImportants.indexOf(l.code) > -1) : [];

    return (
      <Page
        className={css.root}
        scrollingDisabled={scrollingDisabled}
        contentType="website"
        description={schemaDescription}
        title={schemaTitle}
        robots='noindex'
        facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
        twitterImages={[
          { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
        ]}
        schema={{
          "@context": "http://schema.org",
          "@type": "WebPage",
          description: schemaDescription,
          name: schemaTitle,
          image: [schemaImage],
        }}
      >
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div className={css.profileCompanyContainer}>
              <div className={css.coverContainer}>
                <div className={css.coverPhotoContainer} style={coverStyles}>&nbsp;
                </div>
              </div>
              <div className={css.profileCompanySection}>
                <div className={css.companyInfoPanel}>
                  <div className={css.companyInfoFirstColumn}>
                    <div className={css.companyLogo} style={companyLogo}>
                      {!hasLogo ?
                        <span className={css.companyLogoInitials}>
                          {companyDetails?.companyName.charAt(0)}
                        </span>
                        : null
                      }
                    </div>
                    <div className={css.companyName}>{companyDetails?.brandName}</div>
                    <div className={css.companyLocation}>{companyDetails?.city}, {companyDetails?.country}</div>
                    <div className={css.companyDetails}>
                      <div className={css.companyProperties}>
                        <div className={css.companyPropertyRow}>
                          <span className={css.companyFieldLabel}>
                            <FormattedMessage id="UserFields.typeOfStoreColon" />
                          </span>
                          <span className={css.companyFieldValue}>{companyDetails?.typeOfBusiness}</span>
                        </div>
                        <div className={css.companyPropertyRow}>
                          <span className={css.companyFieldLabel}>
                            <FormattedMessage id="UserFields.numberOfStoresColon" />
                          </span>
                          <span className={css.companyFieldValue}>{companyDetails?.numberOfStores}</span>
                        </div>
                        <div className={css.companyPropertyRow}>
                          <span className={css.companyFieldLabel}>
                            <FormattedMessage id="UserFields.websiteColon" />
                          </span>
                          <a target="_blank" href={companyDetails?.website}>
                            <span className={css.companyFieldValueWebsite}>{companyDetails?.website}</span>
                          </a>
                        </div>
                        <div className={css.companyPropertyRow}>
                          <span className={css.companyFieldLabel}>
                            <FormattedMessage id="UserFields.yearEstablishedColon" />
                          </span>
                          <span className={css.companyFieldValue}>{companyDetails?.establishedIn}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={css.companyInfoSecondColumn}>
                    <div className={css.editCompanyInfo}>
                      {isAuthenticated && currentUserType === "supplier" && buyerCurrentCompany?.id?.uuid ?
                        <NamedLink
                          name="SupplierSendMessagePage"
                          className={css.contactBrandButton}
                          params={{ receiver: buyerCurrentCompany?.id?.uuid, title: "Question", locale: locale }}
                        >
                          <FormattedMessage id="BuyerProfilePage.messageBuyer" />
                        </NamedLink>
                        : null}
                    </div>
                    <div className={css.companyDescriptionLabel}>
                      <FormattedMessage id="BuyerProfilePage.LabelCompanyDescription" />
                    </div>
                    <div className={css.companyDescriptionValue}>
                      {companyDetails?.description || (<span className={css.emptyDescription}><FormattedMessage
                        id="BuyerProfilePage.PublicEmptyLabelCompanyDescription" /></span>)}
                    </div>
                    <div className={css.companyImportantsLabel}>
                      <FormattedMessage id="BuyerProfilePage.LabelWhatsImportantToUsAndOurCustomers" />
                    </div>
                    <div className={css.companyImportants}>
                      {(properties || []).map((i) => (
                        <div className={css.companyImportantItem} key={i.code}>{i.name}</div>))}
                    </div>
                  </div>
                </div>
                <div className={css.imagesContainer}>{images}</div>
              </div>
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

BuyerDetailsPageComponent.defaultProps = {
  currentUserListing: null,
  currentUserListingFetched: false,
};

BuyerDetailsPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
  // from withRouter
  history: object.isRequired,
  location: object.isRequired,
  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = (state) => {
  const { currentUser, currentUserType } = state.user;
  const { isAuthenticated } = state.Auth;
  const { buyerCurrentCompany, buyerUpdateCompany, buyerUpdateCompanyInProgress } = state.buyerCompany;

  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
    currentUserType,
    buyerCurrentCompany,
    buyerUpdateCompany,
    buyerUpdateCompanyInProgress,
    isAuthenticated,
  };
};

const mapDispatchToProps = () => ({});

const BuyerDetailsPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
)(BuyerDetailsPageComponent);

BuyerDetailsPage.loadData = (args) => (dispatch) => {
  return dispatch(buyerLoadCompany(args.id));
};

export default BuyerDetailsPage;