import { bool, object } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { BuyerTransactionViewItem } from "../../components";
import LoaderComponent from "../../components/Loader/Loader";
import EmptyStateComponent from "../../components/ProductEmptyState/ProductEmptyState";
import { transition } from "../../ducks/transition.duck";
import { isScrollingDisabled } from "../../ducks/UI.duck";
import { injectIntl, intlShape } from "../../util/reactIntl";
import { propTypes } from "../../util/types";
import { buyerOrdersReset, LoadOrders } from "../BuyerOrdersAndRequestsPage/BuyerOrdersAndRequestsPage.duck";
import css from "./BuyerApprovedOrdersPage.module.css";

export class BuyerApprovedOrdersPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openedTransactionIndex: -1,
      pageIndex: this.props.orders?.meta?.pageIndex || 1,
      totalPages: this.props.orders?.meta?.totalPages,
      mounted: false,
      backgroundLoadingId: null,
    };
    this.onShowDetailsClick = this.onShowDetailsClick.bind(this);
    this.onTransitionClick = this.onTransitionClick.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.onChangeFilter = this.onChangeFilter.bind(this);
    this.fetchAllOrders = this.fetchAllOrders.bind(this);
    this.stopBackgroundFetching = this.stopBackgroundFetching.bind(this);
  }

  onTransitionClick(transaction, newTransition) {
    this.props.onTransition({
      id: transaction.id.uuid,
      transitionName: newTransition,
    });
  }

  onShowDetailsClick(itemIndex) {
    return () => {
      let newIndex = itemIndex;
      if (newIndex === this.state.openedTransactionIndex) {
        newIndex = -1;
      }
      this.setState({ ...this.state, openedTransactionIndex: newIndex });
    };
  }

  onPageChange(pageIndex) {
    this.setState((prev) => ({ ...prev, pageIndex }));
    this.props.onLoadPage({ ...(this.props.params || {}), pageIndex });
  }

  onChangeFilter(createdAtStart, createdAtEnd) {
    const newState = { createdAtStart, createdAtEnd };
    this.setState((prev) => ({ prev, ...newState }));
    this.props.onLoadPage({ ...(this.props.params || {}), ...newState });
  }

  stopBackgroundFetching() {
    /**
     * @description Stopping a background loading.
     */
    clearTimeout(this.state.backgroundLoadingId);
    this.setState((prev) => ({ ...prev, backgroundLoadingId: null }));
  }

  async fetchAllOrders() {
    /**
     * @description To be sure that the timer is existed and is fake for the initial scenario.
     * @type {number}
     */
    let timerId = -1;

    for (let i = 2; i < this.state.totalPages + 1; i++) {
      await new Promise((resolve) => {
        if (timerId) {
          timerId = setTimeout(() => resolve(this.onPageChange(i)), 300);
          this.setState((prev) => ({ ...prev, backgroundLoadingId: timerId }));
        }
      });
    }
  }

  async componentDidMount() {
    /**
     * @description Fetching all existing orders page by page.
     */
    await this.fetchAllOrders();
  }

  componentWillUnmount() {
    this.stopBackgroundFetching();
    this.props.onUnmountPage();
  }

  render() {
    const { ordersLoadInProgress, orders, buyerCurrentCompany } = this.props;
    const viewOrders = [...(orders?.carts || [])];
    const { pageIndex, totalPages } = this.state;
    const isLastPage = !ordersLoadInProgress && (pageIndex === totalPages);
    const emptyStateOptions = {
      containerOffsetClassName: "orderAndRequestOffset",
      iconStylesClassName: "orderAndRequestIcon",
      titleMessageId: "BuyerOrderPage.noSearchResultTitle",
      textMessageId: "BuyerOrderPage.noSearchResultText",
    };


    return (
      <div className={css.ordersComponent}>
        {!viewOrders.length && !this.props.ordersLoadInProgress ?
          <div className={css.emptyDataContainer}>
            <EmptyStateComponent options={emptyStateOptions} />
          </div>
          :
          <div>
            <div className={css.orders}>
              {viewOrders.map((r, idx) => {
                const isShowDetails = this.state.openedTransactionIndex === idx;
                const { currentStep, confirmData, ...rest } = r;
                const cart = { ...rest };

                return (
                  <BuyerTransactionViewItem
                    key={r.correlationId}
                    cart={cart}
                    showDetails={isShowDetails}
                    buyerCurrentCompany={buyerCurrentCompany}
                    onShowDetailsClick={this.onShowDetailsClick(idx)}
                  />
                );
              })}
            </div>
            {isLastPage ? null : <LoaderComponent />}
          </div>
        }
      </div>
    );
  }
}

BuyerApprovedOrdersPageComponent.defaultProps = {
  currentUserListing: null,
  currentUserListingFetched: false,
};

BuyerApprovedOrdersPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
  // from withRouter
  history: object.isRequired,
  location: object.isRequired,
  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = (state) => {
  const { buyerCurrentCompany } = state.buyerCompany;

  return {
    scrollingDisabled: isScrollingDisabled(state),
    buyerCurrentCompany,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onTransition: (values) => dispatch(transition(values)),
  onLoadPage: (values) => dispatch(LoadOrders(values)),
  onUnmountPage: () => dispatch(buyerOrdersReset()),
});

const BuyerApprovedOrdersPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
)(BuyerApprovedOrdersPageComponent);

BuyerApprovedOrdersPage.loadData = () => (dispatch) => {
  return dispatch(LoadOrders());
};

export default BuyerApprovedOrdersPage;
