import classNames from "classnames";
import isEqual from "lodash/isEqual";
import PropTypes from "prop-types";
import React, { Component, useEffect, useState } from "react";
import { Form as FinalForm } from "react-final-form";
import { compose } from "redux";
import { FieldTextInput, Form, PrimaryButton, SecondaryButton } from "../../components";
import { ensureCurrentUser } from "../../util/data";
import { FormattedMessage, injectIntl, intlShape } from "../../util/reactIntl";
import { propTypes } from "../../util/types";
import * as validators from "../../util/validators";
import { defineInputId, LabelWithErrorMsg } from "../ContactDetailsForm/ContactDetailsForm";

import css from "./PasswordChangeForm.module.css";

export const PasswordInputField = ({
  passwordInputId,
  passwordLabel,
  passwordErrorMsg,
  isPasswordError,
  passwordPlaceholder,
  passwordValidators,
  name,
  helpText,
}) => {
  const [visiblePassword, setVisiblePassword] = useState(false);
  const onChangeVisibility = () => setVisiblePassword(!visiblePassword);
  const inputType = visiblePassword ? "text" : "password";
  const actualWrapperStyles = classNames(css.inputWrapper, (isPasswordError && css.inputWrapperError));
  const actualIconStyles = visiblePassword ? css.inputTypeText : css.inputTypePassword;

  return (
    <LabelWithErrorMsg
      isRequired
      htmlFor={passwordInputId}
      labelText={passwordLabel}
      errorMsg={passwordErrorMsg}
      isError={!!isPasswordError}
    >
      <div className={actualWrapperStyles}>
        <FieldTextInput
          id={passwordInputId}
          type={inputType}
          name={name}
          autoComplete="current-password"
          placeholder={passwordPlaceholder}
          validate={passwordValidators}
          className={css.inputWithoutBorder}
        />
        <span onClick={onChangeVisibility} className={actualIconStyles} />
      </div>
      {helpText ? <p className={css.inputHelpText}>{helpText}</p> : null}
    </LabelWithErrorMsg>
  );
};

const RESET_TIMEOUT = 800;

class PasswordChangeFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { showResetPasswordMessage: false };
    this.resetTimeoutId = null;
    this.submittedValues = {};
    this.handleResetPassword = this.handleResetPassword.bind(this);
  }

  componentWillUnmount() {
    window.clearTimeout(this.resetTimeoutId);
  }

  handleResetPassword() {
    this.setState({ showResetPasswordMessage: true });
    const email = this.props.currentUser.attributes.email;
    this.props.onResetPassword(email);
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        render={(fieldRenderProps) => {
          const {
            rootClassName,
            className,
            formId,
            changePasswordError,
            currentUser,
            handleSubmit,
            inProgress,
            resetPasswordInProgress,
            intl,
            modified,
            form,
            values,
            hideModal,
          } = fieldRenderProps;

          const user = ensureCurrentUser(currentUser);
          if (!user.id) return null;

          // ================ GENERAL FOR ALL PASSWORDS ================ //
          const passwordRequiredMessage = intl.formatMessage({ id: "RequiredField.warningText" });
          const passwordMinLengthMessage = intl.formatMessage(
            { id: "PasswordChangeForm.passwordTooShort" },
            { minLength: validators.PASSWORD_MIN_LENGTH },
          );
          const passwordMaxLengthMessage = intl.formatMessage(
            { id: "PasswordChangeForm.passwordTooLong" },
            { maxLength: validators.PASSWORD_MAX_LENGTH },
          );
          const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);
          const passwordMinLength = validators.minLength(passwordMinLengthMessage, validators.PASSWORD_MIN_LENGTH);
          const passwordMaxLength = validators.maxLength(passwordMaxLengthMessage, validators.PASSWORD_MAX_LENGTH);

          const initialErrorsState = {
            currentPasswordErrorMsg: "",
            newPasswordErrorMsg: "",
            repeatPasswordErrorMsg: "",
          };
          const [{
            currentPasswordErrorMsg,
            newPasswordErrorMsg,
            repeatPasswordErrorMsg,
          }, setErrors] = useState(initialErrorsState);

          // ================ CURRENT PASSWORD ================ //
          const currentPasswordName = "currentPassword";
          const currentPasswordInputId = defineInputId({ formId, inputName: currentPasswordName });
          const currentPasswordLabel = intl.formatMessage({ id: 'ContactDetailsForm.passwordLabel' });
          const currentPasswordPlaceholder = intl.formatMessage({ id: 'ContactDetailsForm.passwordPlaceholder' });
          const currentPasswordFailedMessage = intl.formatMessage({ id: "PasswordResetForm.incorrectPassword" });
          const currentPasswordTouched = modified[currentPasswordName];
          const isCurrentPasswordEmpty = !values.currentPassword;
          const isCurrentPasswordError = currentPasswordTouched && (isCurrentPasswordEmpty || changePasswordError);
          useEffect(() => {
            if (currentPasswordTouched) {
              if (changePasswordError) {
                setErrors((prev) => ({ ...prev, currentPasswordErrorMsg: currentPasswordFailedMessage }));
              } else if (isCurrentPasswordEmpty) {
                setErrors((prev) => ({ ...prev, currentPasswordErrorMsg: passwordRequiredMessage }));
              }
            } else {
              setErrors((prev) => ({ ...prev, currentPasswordErrorMsg: "" }));
            }
          }, [changePasswordError,
            isCurrentPasswordError,
            isCurrentPasswordEmpty,
            currentPasswordFailedMessage,
            passwordRequiredMessage,
            currentPasswordTouched,
          ]);

          // ================ NEW PASSWORD ================ //
          const newPasswordName = "newPassword";
          const newPasswordInputId = defineInputId({ formId, inputName: newPasswordName });
          const newPasswordLabel = intl.formatMessage({ id: "PasswordChangeForm.newPasswordLabel" });
          const newPasswordPlaceholder = intl.formatMessage({ id: "PasswordChangeForm.newPasswordPlaceholder" });
          const newPasswordHelpText = intl.formatMessage({ id: "ConfirmRegistrationForm.passwordHelpText" });
          const newPasswordRequired = validators.composeValidators(
            passwordRequired,
            passwordMinLength,
            passwordMaxLength,
          );
          const isNewPasswordEmpty = !values.newPassword;
          const isNewPasswordTooShort = values.newPassword?.length < validators.PASSWORD_MIN_LENGTH;
          const newPasswordTouched = modified[newPasswordName];
          const isNewPasswordError = newPasswordTouched && (isNewPasswordEmpty || isNewPasswordTooShort);
          useEffect(() => {
            if (newPasswordTouched) {
              if (isNewPasswordEmpty) {
                setErrors((prev) => ({ ...prev, newPasswordErrorMsg: passwordRequiredMessage }));
              } else if (isNewPasswordTooShort) {
                setErrors((prev) => ({ ...prev, newPasswordErrorMsg: passwordMinLengthMessage }));
              }
            } else {
              setErrors((prev) => ({ ...prev, newPasswordErrorMsg: "" }));
            }
          }, [
            isNewPasswordError,
            isNewPasswordEmpty,
            passwordRequiredMessage,
            newPasswordTouched,
            isNewPasswordTooShort,
          ]);

          // ================ REPEAT PASSWORD ================ //
          const repeatPasswordName = "repeatPassword";
          const repeatPasswordInputId = defineInputId({ formId, inputName: repeatPasswordName });
          const repeatPasswordLabel = intl.formatMessage({ id: "PasswordChangeForm.repeatNewPasswordLabel" });
          const repeatPasswordPlaceholder = intl.formatMessage({ id: "PasswordChangeForm.newPasswordPlaceholder" });
          const repeatPasswordMatchedMessage = intl.formatMessage({ id: "PasswordChangeForm.repeatPasswordMatch" });
          const isRepeatPasswordEmpty = !values.repeatPassword;
          const isRepeatPasswordMatches = values.repeatPassword === values.newPassword;
          const repeatPasswordTouched = modified[repeatPasswordName];
          const isRepeatPasswordError = repeatPasswordTouched && (isRepeatPasswordEmpty || !isRepeatPasswordMatches);
          useEffect(() => {
            if (repeatPasswordTouched) {
              if (isRepeatPasswordEmpty) {
                setErrors((prev) => ({ ...prev, repeatPasswordErrorMsg: passwordRequiredMessage }));
              } else if (!isRepeatPasswordMatches) {
                setErrors((prev) => ({ ...prev, repeatPasswordErrorMsg: repeatPasswordMatchedMessage }));
              }
            } else {
              setErrors((prev) => ({ ...prev, repeatPasswordErrorMsg: "" }));
            }
          }, [
            isRepeatPasswordError,
            currentPasswordFailedMessage,
            passwordRequiredMessage,
            repeatPasswordTouched,
            isRepeatPasswordEmpty,
          ]);

          // ================ FORM SUBMITTING ================ //
          const submittedOnce = Object.keys(this.submittedValues).length > 0;
          const pristineSinceLastSubmit = submittedOnce && isEqual(values, this.submittedValues);
          const classes = classNames(rootClassName || css.root, className);

          const isAllFieldsFilled = !isCurrentPasswordEmpty && !isNewPasswordEmpty && !isRepeatPasswordEmpty;
          const submitDisabled = pristineSinceLastSubmit || !isAllFieldsFilled || inProgress || !isRepeatPasswordMatches;

          const sendPasswordLink = (
            <span className={css.helperLink} onClick={this.handleResetPassword} role="button">
              <FormattedMessage id="PasswordChangeForm.resetPasswordLinkText" />
            </span>
          );
          const resendPasswordLink = (
            <span className={css.helperLink} onClick={this.handleResetPassword} role="button">
              <FormattedMessage id="PasswordChangeForm.resendPasswordLinkText" />
            </span>
          );
          const resetPasswordLink = this.state.showResetPasswordMessage || resetPasswordInProgress
            ? (
              <>
                <FormattedMessage
                  id="PasswordChangeForm.resetPasswordLinkSent"
                  values={{ email: <span className={css.emailStyle}>{currentUser.attributes.email}</span> }}
                />
                {" "}{resendPasswordLink}
              </>
            ) : sendPasswordLink;

          const formSubmit = (e) => {
            this.submittedValues = values;
            handleSubmit(e)
              .then((res) => {
                /**
                 * Supposed that we can catch the only "incorrect password" error here.
                 */
                if (!res) {
                  this.resetTimeoutId = window.setTimeout(form.reset, RESET_TIMEOUT);
                  setErrors(initialErrorsState);
                  hideModal();
                }
              });
          };

          return (
            <Form className={classes} onSubmit={formSubmit}>
              <div className={css.modalContainer}>
                <div className={css.crossButton} onClick={hideModal}></div>
                <h3 className={css.modalTitle}>
                  <FormattedMessage id="ContactDetailsForm.confirmChangesTitle" />
                </h3>
                <div className={css.inputContainer}>
                  <PasswordInputField
                    name={currentPasswordName}
                    passwordInputId={currentPasswordInputId}
                    passwordLabel={currentPasswordLabel}
                    passwordPlaceholder={currentPasswordPlaceholder}
                    passwordErrorMsg={currentPasswordErrorMsg}
                    isPasswordError={isCurrentPasswordError}
                    passwordValidators={passwordRequired}
                  />
                  <p className={css.resetPasswordInfo}>
                    <FormattedMessage id="ContactDetailsForm.resetPasswordInfo" values={{ resetPasswordLink }} />
                  </p>
                  <PasswordInputField
                    name={newPasswordName}
                    passwordInputId={newPasswordInputId}
                    passwordLabel={newPasswordLabel}
                    passwordPlaceholder={newPasswordPlaceholder}
                    passwordErrorMsg={newPasswordErrorMsg}
                    isPasswordError={isNewPasswordError}
                    passwordValidators={newPasswordRequired}
                    helpText={newPasswordHelpText}
                  />
                  <PasswordInputField
                    name={repeatPasswordName}
                    passwordInputId={repeatPasswordInputId}
                    passwordLabel={repeatPasswordLabel}
                    passwordPlaceholder={repeatPasswordPlaceholder}
                    passwordErrorMsg={repeatPasswordErrorMsg}
                    isPasswordError={isRepeatPasswordError}
                    passwordValidators={passwordRequired}
                  />
                </div>
                <div className={css.buttonContainer}>
                  <SecondaryButton className={css.backButton} onClick={hideModal}>
                    <FormattedMessage id="PasswordChangeForm.goBack" />
                  </SecondaryButton>
                  <PrimaryButton
                    className={css.confirmButton}
                    inProgress={inProgress}
                    disabled={submitDisabled}
                    onClick={formSubmit}
                  >
                    <FormattedMessage id="PasswordChangePage.changePassword" />
                  </PrimaryButton>
                </div>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

PasswordChangeFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  changePasswordError: null,
  inProgress: false,
  formId: null,
  resetPasswordInProgress: false,
  resetPasswordError: null,
};

const { bool, string, func } = PropTypes;

PasswordChangeFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  changePasswordError: propTypes.error,
  inProgress: bool,
  intl: intlShape.isRequired,
  ready: bool.isRequired,
  formId: string,
  resetPasswordInProgress: bool,
  resetPasswordError: propTypes.error,
  hideModal: func.isRequired,
};

const PasswordChangeForm = compose(injectIntl)(PasswordChangeFormComponent);

PasswordChangeForm.displayName = "PasswordChangeForm";

export default PasswordChangeForm;
