const BUYER_TABS = {
    "account" : "account",
    "payment-details" : "payment-details",
    "delivery-address" : "delivery-address",
    "notifications" : "notifications",
  };
  
  Object.freeze(BUYER_TABS);
  
  const BUYER_TAB_MESSAGES = {
    [BUYER_TABS["account"]] : "BuyerSettingsPage.settingsTabAccount",
    [BUYER_TABS["payment-details"]] : "BuyerSettingsPage.settingsTabPaymentDetails",
    [BUYER_TABS["delivery-address"]] : "BuyerSettingsPage.settingsTabDeliveryAddress",
    [BUYER_TABS["notifications"]] : "BuyerSettingsPage.settingsTabNotifications",
  };
  
  Object.freeze(BUYER_TAB_MESSAGES);
  
  const BUYER_TAB_LINK_NAMES = {
    [BUYER_TABS["account"]] : "BuyerProfileAccountPage",
    [BUYER_TABS["payment-details"]] : "BuyerPaymentDetailsPage",
    [BUYER_TABS["delivery-address"]] : "BuyerProfileDeliveryAddressPage",
    [BUYER_TABS["notifications"]] : "BuyerProfileNotificationsPage",
  };
  
  Object.freeze(BUYER_TAB_LINK_NAMES);
  
  export {
    BUYER_TABS,
    BUYER_TAB_MESSAGES,
    BUYER_TAB_LINK_NAMES,
  };
  