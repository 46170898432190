import React, { Component } from 'react';
import { bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { propTypes } from '../../util/types';

import css from './ContactUsPage.module.css';

const email = <a href="mailto:hello@vegshelf.com" target="_blank">hello@vegshelf.com</a>;

export class ContactUsPageComponent extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      history,
      intl
    } = this.props;
    return (
      <div className={css.vegshelfInformationContainer}>
        <div className={css.vegshelfInformationBox1}>
          <h1 className={css.mainTitle}>
            <FormattedMessage id="ContactUsPage.contactDetailsHeading" />
          </h1>

          <div className={css.contactOptions}>
            <div className={css.singleContactSection}>
              <div className={css.whatsappIcon}>Whatsapp</div>
              <div className={css.contactDetails}>
                <p className={css.contactText}>
                  <b><FormattedMessage id="ContactUsPage.contactWhatsappTitle" /></b><br />
                  <div className={css.whatsappQRCode}>WhatsappQRCode</div>
                  <FormattedMessage id="ContactUsPage.contactWhatsappText" /><br />
                  <FormattedMessage id="ContactUsPage.contactWhatsappText2" />
                </p>
              </div>
            </div>

            <div className={css.singleContactSection}>
              <div className={css.emailIcon}>E-mail</div>
              <div className={css.contactDetails}>
                <p className={css.contactText}>
                  <b><FormattedMessage id="ContactUsPage.contactEmailTitle" /></b><br />
                  <FormattedMessage id="ContactUsPage.contactEmailText" values={{ email }} /><br />
                </p>
              </div>
            </div>

            <div className={css.singleContactSection}>
              <div className={css.phoneIcon}>Phone</div>
              <div className={css.contactDetails}>
                <p className={css.contactText}>
                  <b><FormattedMessage id="ContactUsPage.contactPhoneTitle" /></b><br />
                  <FormattedMessage id="ContactUsPage.contactPhoneText" /><br />
                  <FormattedMessage id="ContactUsPage.contactPhoneText2" />
                </p>
              </div>
            </div>
          </div>
          <br />
          <br />
          <h2 className={css.mainTitle}>
            <FormattedMessage id="ContactUsPage.addressHeading" />
          </h2>
          <p className={css.contactText}>
            <FormattedMessage id="ContactUsPage.addressDetailsLine1" /><br />
            <FormattedMessage id="ContactUsPage.addressDetailsLine2" /><br />
            <FormattedMessage id="ContactUsPage.addressDetailsLine3" /><br />
            <FormattedMessage id="ContactUsPage.addressDetailsLine4" />
          </p>
        </div>
      </div>
    );
  }
};

ContactUsPageComponent.defaultProps = {
  currentUserListing: null,
  currentUserListingFetched: false,
};

ContactUsPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};
const mapStateToProps = state => {
  const { currentUser } = state.user;
  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser
  };
};

const ContactUsPage = compose(
  withRouter,
  connect(mapStateToProps),
  injectIntl
)(ContactUsPageComponent);

export default ContactUsPage;