import React, { Component } from 'react';
import { bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { propTypes } from '../../util/types';

import css from './BuyerTermsPage.module.css';

export class BuyerTermsPageComponent extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      history,
      intl
    } = this.props;
    return (
      <div className={css.vegshelfInformationContainer}>
        <div className={css.vegshelfInformationBox1}>
          <h1 className={css.mainTitle}>
            <FormattedMessage id="BuyerTermsPage.buyerTerms" />
          </h1>
          <p className={css.bodyText}>
            <i>Last updated on Apr 4, 2022</i>
            <br /><br />
            Welcome to VEGSHELF - a curated online wholesale platform connecting emerging food & beverage brands with retailers, accessible at <a href="https://vegshelf.com/" target="_blank">www.vegshelf.com</a> (the “Platform”), and owned by Vegshelf UG (haftungsbeschränkt), registered with the Amtsgericht Düsseldorf with the commercial register number HRB 88669, located at Leopoldstr. 49, 40211 Düsseldorf, Germany. VAT number DE328398511, e-mail: <a href="mailto:hello@vegshelf.com" target="_blank">hello@vegshelf.com</a>.
            <br /><br />
            These terms (the “Terms”) set forth the legally binding setting on which the Visitor and the Buyer may access and use the Platform, and provide the terms and conditions under which VEGSHELF provides its Services that will enable the Buyer to discover and purchase emerging food and beverage Products through the digital wholesale Platform. These Terms govern the relationships between VEGSHELF and each Visitor and Buyer and prevail over any other document.
            <br /><br /><br />

            <b>1. DEFINITIONS AND INTERPRETATIONS</b>
            <br /><br />
            In these Terms, the capitalized terms with the respective meanings as described below are used. Words in the singular include the plural and vice versa.
            <br /><br />
            <b>“Account”</b>: means an account of the Member on the Platform that can be identified with a unique e-mail address.
            <br /><br />
            <b>“Buyer”</b>: means the Member using the Service to discover, view, request, or purchase Products on the Platform for the purpose of resale of those Products, including but not limited to brick and mortar stores, online shops, retail chains, cafes, coffee shops, subscription box services, hotels, gyms, food delivery companies, and other companies reselling food & beverage Products.
            <br /><br />
            <b>“Complaint Submission Period”</b>: means a time period of 72 hours from the date of Completed Delivery, when the Buyer can submit any complaint or raise an issue (e.g. damaged and/or missing Products) related to Order and/or Products received.
            <br /><br />
            <b>“Completed Delivery”</b>: means when Products have been delivered at the Buyer’s location on the date mentioned on the delivery note and, in quality and state, as specified in the Product Listing by the Supplier, and the Supplier has updated the order status as “Delivered” in the Platform.
            <br /><br />
            <b>“Content”</b>: means any information added, created, submitted, uploaded, distributed, or posted by the Member to the Platform, via the Services or sent to VEGSHELF via e-mail, including but not limited to photos, videos, audios, graphics, written posts, messages and comments, trademarks, files, logos, certification documents, company and/or Product details.
            <br /><br />
            <b>“Fulfillment”</b>: means the process of delivering Products from the selling Supplier to the buying Buyer at the Buyer’s location. This can include warehousing, packing, issuing a delivery note, handling, picking up, shipping, and more.
            <br /><br />
            <b>“Invoice”</b>: means an invoice issued by VEGSHELF, for and/or on behalf of the Supplier, either payable by the Buyer for the Products that were purchased by the Buyer through the Platform, or payable by the Supplier in return for the Service supplied by VEGSHELF.
            <br /><br />
            <b>“Member”</b>: means the Visitor who has registered on the Platform, and whose registration has been verified and approved by VEGSHELF, and thus has an open Account on the Platform.
            <br /><br />
            <b>“Order”</b>: means Order Request that has been confirmed by the Supplier selling the Product.
            <br /><br />
            <b>“Order Request”</b>: means the request of the Buyer to purchase a Product on the Platform that is pending the confirmation or rejection by the Supplier selling the Product within 72 hours from the moment of receiving such request.
            <br /><br />
            <b>“Party”</b>: means, individually or together, VEGSHELF and/or the Supplier and/or the Buyer and/or the Member and/or the Visitor.
            <br /><br />
            <b>“Product”</b>: means any unique consumer-packaged food and beverage product listed, offered, and sold on the Platform by the Supplier to the Buyer. Product has to comply with these Terms. Each unique Product is represented by its own digital “Product Listing” created and published on the Platform by the selling Supplier or VEGSHELF on behalf of such Supplier.
            <br /><br />
            <b>“Service”</b>: means all of the VEGSHELF online services, which are made available to the Member through the Platform (described in detail in paragraph 3 of these Terms).
            <br /><br />
            <b>“Store”</b>: means an online wholesale storefront that consists of key company information and the Supplier’s Products that are offered and sold through the Platform.
            <br /><br />
            <b>“Supplier”</b>: means the Member using the Service to offer, list, distribute, trade, or sell Products through the Platform to Buyers, including but not limited to brand, producer, manufacturer, or other companies.
            <br /><br />
            <b>“Visitor”</b>: means a representative of a registered business who visits or accesses the Platform, regardless of whether this representative is a Member or not.
            <br /><br />
            <b>“VEGSHELF”</b>: Vegshelf UG (haftungsbeschränkt), registered with the Amtsgericht Düsseldorf with the commercial register number HRB 88669, located at Leopoldstr. 49, 40211 Düsseldorf, Germany, and with VAT number DE328398511.
            <br /><br /><br />

            <b>2. ACCEPTANCE OF TERMS AND CHANGES TO THE TERMS</b>
            <br /><br />
            2.1.	When visiting or accessing the Platform and using the Services, the Buyer expressly agrees, without restriction, to be legally bound by all the provisions of these Terms and the Privacy Policy.
            <br /><br />
            2.2.	If the Visitor or the Buyer does not understand, read, and/or agree to the Terms, the Visitor or the Buyer undertakes to stop visiting, accessing, or using the Platform and the Service in any manner, and, as the case may be, abstain from registering on the Platform.
            <br /><br />
            2.3.	In case of changes of legislation, of the case-law, or of a change of the business model or economic circumstances, VEGSHELF, at its sole discretion, explicitly reserves the right to change, edit, and replace these Terms at any time. The new Terms will be placed on the Platform and/or notice will be sent via e-mail to the Buyer at least fourteen (14) calendar days before the changes take effect. During this fourteen (14) calendar day period, the Buyer has the right to reject the new Terms by notifying VEGSHELF via e-mail (<a href="mailto:hello@vegshelf.com" target="_blank">hello@vegshelf.com</a>), in which case the Buyer will no longer be able to use and access the Service, and the Buyer’s right to access and use the Service will expire at the end of the aforementioned period of fourteen (14) calendar days. If the Buyer does not reject the new Terms and uses the Service after a change of the Terms is effective, it is to be deemed as the Buyers’ acceptance of the changed Terms.
            <br /><br /><br />

            <b>3. DESCRIPTION OF THE SERVICES</b>
            <br /><br />
            3.1.	VEGSHELF has created an internet-based business-to-business Platform where Suppliers can offer and sell Products, and the Buyer can discover, place Orders and pay for these Products, as part of the Service.
            <br /><br />
            3.2.	The Platform is designed to offer Services exclusively to Visitors and Members who are registered businesses. The Visitor and the Buyer who is entering into these Terms on behalf of a registered business warrant that they have the authority to bind the Party they are representing to these Terms. Services are not designed and thus not available to end-consumers.
            <br /><br />
            3.3.	VEGSHELF has an intermediary role between the Buyer and the Supplier of the Products therefore it is to be understood that: (i) VEGSHELF is not involved in the direct selling of the Products between the Buyer and the Supplier; (ii) the Buyer and the Supplier decide at their own discretion on whether to do business with the other Party through the Platform; (iii) VEGSHELF is not a seller, reseller, or owner of the Products that are being listed and sold through the Platform; (iv) VEGSHELF never enters into possession of the Products and does not hold stock of the Products; (v) VEGSHELF has no control over the sale or the payment of the Products sold and ordered on the Platform.
            <br /><br />
            Therefore, VEGSHELF rejects any liability for the acts and omissions of the Supplier and/or the Buyer on the Platform.
            <br /><br />
            3.4.	The Service and use of the Platform are free of charge for the Buyer. Any payments made on the Platform by the Buyer are intended for the Supplier, who then pays a commission to VEGSHELF for the intermediation and facilitation of the sale of their Products on the Platform.
            <br /><br />
            3.5.	VEGSHELF has the right at its own discretion to change, suspend or discontinue any part of the Services, introduce new features or limits on certain features or restrict access to parts or all of the Services unless this disproportionately impairs the material obligations of VEGSHELF due to the contract with the Buyer. VEGSHELF may give the Buyer a notice when such material change to the Services adversely affecting the Buyer is being made.
            <br /><br /><br />

            <b>4. ACCESS TO THE PLATFORM AND SERVICES</b>
            <br /><br />
            4.1.	The Platform is accessible by any Visitor. However, the Visitor is being granted access to the Services and all its functionalities, only after the Visitor has registered as the Buyer through the Platform, and VEGSHELF has approved their request and thus granted access to the Services.
            <br /><br />
            4.2.	The Visitor and the Buyer are responsible for ensuring that their access to the Platform and usage of the Services is in compliance with all laws and regulations that apply to them in their respective countries and when transacting with companies from abroad. If by accessing and using the Platform and the Services the Visitor or the Buyer violates any law or regulation applicable to them, the Visitor or the Buyer shall stop accessing and using the Platform and the Services immediately and will be held liable for any damages or costs sustained by VEGSHELF in any such case.
            <br /><br />
            4.3.	VEGSHELF reserves the right at any time and at its sole discretion to interrupt or suspend access to the Platform and/or the Services, including but not limited to operational reasons, technical updates, failures of communication networks, and/or servers, maintenance, internal choices, and force majeure.
            <br /><br />
            4.4.	VEGSHELF does not warrant that access to the Services and the Platform shall be uninterrupted or error-free.
            <br /><br /><br />

            <b>5. THE BUYER REGISTRATION TERMS</b>
            <br /><br />
            5.1.	To purchase the Products through the Platform and have access to the full functionality of the Service as the Buyer, the Visitor must submit a registration request to and be approved by VEGSHELF. Both the registration process and Buyer Account opening are free of charge. The Buyer registration form is accessible to any Visitor through the Platform and requires the Visitor to provide basic information about themselves and their company.
            <br /><br />
            5.2.	To qualify for the Buyer Account, the Buyer must have a legally registered business that resells food and/or beverage Products, including but not limited to one of the following profiles: brick and mortar shop, online store, pop-up store, subscription box service, retail chain, cafe, coffee shop, hotel, gym, food delivery company. The Buyer must be able to present proof of a valid VAT number, company registration number, website address and other details as may be required by VEGSHELF.
            <br /><br />
            5.3.	The registration as the Buyer may only be done by an individual who is at least 18 years old and authorized to represent the Buyer’s legal entity, thus the Visitor registering as the Buyer warrants that they are authorized to agree to these Terms on behalf of the legal entity that they are representing when registering.
            <br /><br />
            5.4.	The Visitor registering as the Buyer agrees to use the email address with their website’s domain name and agrees to refrain from registering using the email address with the domain name pointing to an external website or service.
            <br /><br />
            5.5.	The Visitor registering as the Buyer undertakes that the information they provide during the registration process is correct, sincere, accurate, up-to-date and such that does not infringe third-party rights.
            <br /><br />
            5.6.	VEGSHELF will use good faith efforts to review the Visitor’s registration request as soon as possible. However, the Visitor acknowledges that VEGSHELF can review a limited amount of requests in a given time, and is not obliged to accept the Visitor as the Buyer, thus releasing VEGSHELF from any liability regarding the reviewal speed and process of their registration request.
            <br /><br />
            5.7.	VEGSHELF at its sole discretion reserves the right to accept registration requests, to change the Buyer eligibility criteria, and/or refuse to offer the Service to the Visitor by rejecting or deleting the registration request at any time without prior notice and without providing a reason.
            <br /><br />
            5.8.	After the Visitor has submitted their registration request through the Platform and VEGSHELF has approved it, a contractual relationship between VEGSHELF and the Buyer is formed.
            <br /><br />
            5.9.	The Buyer undertakes to create and use only one Account. When completing the registration process and opening an Account with VEGSHELF, the Buyer will need to choose a secure password. It is the Buyer’s sole responsibility to keep their password private and confidential from any unauthorized Party. The Buyer must promptly notify VEGSHELF by sending an e-mail to <a href="mailto:hello@vegshelf.com" target="_blank">hello@vegshelf.com</a> if they believe that someone has used or has access to their Account without permission.
            <br /><br />
            5.10.	The Buyer is required to confirm their email address, as instructed on the Platform, in order to receive further notifications related to their Order Requests, Orders, and the Account.
            <br /><br />
            5.11.	The Buyer undertakes to take full responsibility for their Account, all activity on it, and correctness of their Account data. It is the Buyer's responsibility to keep the data up-to-date, sincere and correct throughout the time of using the Services. The Buyer’s failure to provide accurate, complete and updated data may result in the Buyer not being eligible to access the Platform and the Service.
            <br /><br /><br />

            <b>6. THE BUYER’S DIGITAL COMPANY PROFILE AND MAINTENANCE OF IT</b>
            <br /><br />
            6.1.	The  Buyer has their digital company profile allocated to them on the Platform where the Buyer can showcase their company to Suppliers by adding Content such as images, descriptions, and key company information on the Platform.
            <br /><br />
            6.2.	The Buyer’s digital company profile creation on the Platform is free of charge. However, the Buyer is solely responsible for all the related and incurred costs with its creation including but not limited to the creation of professional company photos and texts.
            <br /><br />
            6.3.	The Buyer is solely responsible for adding Content about their company on the Platform in accordance with instructions provided on the Platform unless agreed in advance that VEGSHELF will assist with the creation of their digital company profile on the Platform. In case VEGSHELF is assisting with the digital company profile creation, the Buyer is liable for making sure all the Content is correct, up-to-date, and does not infringe any third-party rights.
            <br /><br />
            6.4.	The Buyer is liable and responsible for all the Content that their digital company profile contains and their compliance with these Terms. By creating the digital company profile, the Buyer warrants that they have all the necessary approvals, licenses, and rights to use the Content submitted. Moreover, the Buyer is responsible for paying all royalties or similar payments that are or may become due to any third party based on their Content and display or other use of that Content on the Platform.
            <br /><br />
            6.5.	The Buyer agrees to refrain from submitting to or making available on the Platform the Content that is fraudulent, unlawful, abusive, vulgar, or inappropriate in any other way as determined by VEGSHELF at its exclusive discretion, including but not limited to the Content that: (i) breaches copyrights, trademarks, patents, trade secrets, rights of privacy and publicity, or any rights of a third party; (ii) violates any law, regulation, or rule that may apply; (iii) abuses and threatens others; (iv) is misleading, false, or defamatory; (v) contains pornography, (vi) is racist.
            <br /><br />
            6.6.	The Buyer agrees that VEGSHELF may edit or remove the Content they publish on their digital company profile, without any liability or prior notice to the Buyer, or ask for the additional Content in order to comply with VEGSHELF’s requirements and specifications. VEGSHELF at its sole discretion has the right to determine whether the Buyer’s provided Content satisfies expected quality and in case of a failure, VEGSHELF reserves the right to remove or edit such Content.
            <br /><br />
            6.7.	It is the Buyer’s sole responsibility to keep their digital company profile up to date.
            <br /><br />
            6.8.	The Buyer warrants that all information provided or submitted by them for publishing on their digital company profile on the Platform is correct, sincere, accurate, up-to-date, and does not infringe third-party rights.
            <br /><br /><br />

            <b>7. CONTENT LICENSE</b>
            <br /><br />
            7.1.	The Buyer undertakes to publish the Content on the Platform in compliance with these Terms and with any legal and regulatory provisions in force.
            <br /><br />
            7.2.	The Buyer, from which the Content has originated and has been publicly or privately distributed, is liable for it and takes responsibility for the character, accuracy, completeness, and truth of the Content. The Buyer warrants that they own their Content in accordance with these Terms or have the necessary rights to use it.
            <br /><br />
            7.3.	Should the Content provided by the Buyer fails to comply with these Terms or any applicable laws and/or regulations, VEGSHELF reserves the right to remove any such Content without prior notice.
            <br /><br />
            7.4.	The Buyer acknowledges that their Content may be viewed by any Visitor, Supplier, and other Buyer, who can access their digital company profile on the Platform.
            <br /><br />
            7.5.	By providing the Content through the Platform and the Services, the Buyer grants VEGSHELF a worldwide, free, non-exclusive, assignable, transferable, perpetual, sublicensable, and irrevocable license for the whole duration of the contractual relationship, so as to use, display, format, edit, translate, publish, reproduce, disseminate, represent, commercialize, and exploit the copyright, publicity, trademark, and database rights with respect to the Content solely for the purpose of providing and advertising the Platform and the Service, on any other media (any tangible or digital medium, any press, publication, report, presentation, website, promotion) and without restriction as to a number of copies, specific audience or geographic area of use.
            <br /><br />
            7.6.	The Buyer warrants that they are the exclusive owner of their Content and that they have all rights to grant the Content license to VEGSHELF without infringement or violation of any third party rights, including without limitation, any privacy rights, copyrights, trademarks, patents, trade secrets, publicity rights, contract rights, or any other proprietary or intellectual property rights.
            <br /><br />
            7.7.	VEGSHELF does not guarantee that any Content will be made available through the Platform and the Service. Moreover, VEGSHELF has the right to edit, remove or block any Content at its sole discretion, at any time, without notice to the Buyer and without providing a reason, including but not limited to, after receiving claims from third parties or public authorities relating to such Content, as well as based on VEGSHELF’s concerns of violation of these Terms by the Buyer.
            <br /><br />
            7.8.	If for any reason the Buyer wishes to remove their Content from the Platform, they can do so either through their Account on the Platform or by contacting VEGSHELF at <a href="mailto:hello@vegshelf.com" target="_blank">hello@vegshelf.com</a>. Once the Buyer’s Content is deleted, it is automatically removed from the Platform and the Service, however, it is to be understood that the Content may remain on VEGSHELF backup copies, advertisements of the Platform on VEGSHELF’s or third-party platforms, social media channels, and media for a reasonable period of time and according to applicable laws.
            <br /><br />
            7.9.	VEGSHELF reserves the right at any time and at its sole discretion to remove or modify any Content from the Platform and the Services for any reason, including, but not limited to, practical, business, or technical reasons. The Buyer undertakes not to hold VEGSHELF liable, nor will ask for compensation to be paid to them for any such activity.
            <br /><br />
            7.10.	The Content uploaded on the Platform through the Services is made available solely for informational purposes. VEGSHELF does not warrant the completeness and accuracy of any such Content. Any reliance the Buyer and/or the Visitor places on such Content is at their own risk.
            <br /><br />
            7.11.	In the event of any dispute between the Members in relation to the Content, it is solely Member’s responsibility to solve the issue directly with each other without involving VEGSHELF in the resolution of the dispute, hereby releasing VEGSHELF from any liability in this regard.
            <br /><br /><br />

            <b>8. INTELLECTUAL PROPERTY RIGHTS</b>
            <br /><br />
            8.1.	The Content of the Platform and the Service, provided by the Members and VEGSHELF, is protected by trademarks, trade secrets, copyrights, patents, or other proprietary rights and laws and is the respective Party’s intellectual property. The Visitor and/or the Buyer must obey and respect all copyright information and limitations of the Content accessed through the Platform and the Service.
            <br /><br />
            8.2.	Unless explicitly permitted under these Terms, the Visitor and/or the Buyer are strictly prohibited from using and/or copying any intellectual property, including but not limited to any Content provided by other Members, partners, or VEGSHELF, any feature, design element, source code, data, or the user interface of the Platform without the prior written approval of VEGSHELF.
            <br /><br />
            8.3.	Any intellectual property rights created as a result of the use of the Platform and the Service by the Buyer and performance of the Services by VEGSHELF is the property of VEGSHELF, except intellectual property related to the Content provided by the Buyer.
            <br /><br /><br />

            <b>9. PURCHASING THROUGH THE PLATFORM</b>
            <br /><br />
            9.1.	The Buyer may directly communicate with Suppliers through direct messages on the Platform. The Supplier is responsible to respond to any messages from the Buyer in a timely and professional manner, as advised within 24 hours upon receiving the message.
            <br /><br />
            9.2.	The Buyer warrants that it will not sell further the Products ordered on the Platform on any third party marketplace, including but not limited to Amazon, without written consent from the Supplier or VEGSHELF.
            <br /><br />
            9.3.	The Buyer agrees that each Supplier has a fixed minimum Order sum of €100.00 (one hundred euros and zero cents) (excluding taxes and shipping) as set by VEGSHELF, and it is not possible to place the Order from the Supplier for the sum below the Order minimum.
            <br /><br />
            9.4.	The Buyer is solely responsible for the VAT collection, remittance, and applicable reporting to authorities. Specific VAT rates vary depending on the location of the Supplier and the Buyer.
            <br /><br />
            9.5.	The Buyer warrants to keep the Product wholesale prices fully confidential and not to disclose them to any third parties.
            <br /><br />
            9.6.	Suppliers on the Platform are encouraged by VEGSHELF to keep sufficient inventory on hand for Products that are advertised as available on the Platform and keep the Product availability up to date. However, VEGSHELF does not warrant the Supplier’s inventory.
            <br /><br />
            9.7.	The Buyer can request free Product samples from the Supplier if such availability of free samples is communicated by the Supplier on their Product Listing(s) on the Platform. If free Product samples are offered, the Supplier undertakes to deliver the free Product samples to the requesting Buyer on the Supplier’s own terms and conditions.
            <br /><br />
            9.8.	The Buyer can place an Order and pay for the Products (incl. applicable taxes and shipping) through the Platform. After the Buyer has completed the purchase on the Platform, payment has been reserved from the Buyer’s bank account, and the Supplier is informed about the new Order Request via e-mail and on the Platform. Upon receiving the Order Request from the Buyer on the Platform, the Supplier is responsible for responding by accepting or rejecting the Order Request within 72 hours on the Platform.
            <br /><br />
            9.9.	All payments for the Order(s) made on the Platform by the Buyer can be made only using a valid credit or debit card and, if supported by the Buyer’s bank, with 3D Secure verification.
            <br /><br />
            9.10.	The Buyer acknowledges that the Supplier may refuse to complete the Buyer’s purchase and cancel the Order Request for any reason. If the Supplier rejects or does not respond to the Order Request by accepting or rejecting it within the 72-hour time limit, the Order Request is canceled and no longer available. In such a case, the Buyer’s payment is automatically refunded to the Buyer’s bank account immediately but no later than within 14 days, depending on the Buyer’s bank.
            <br /><br />
            9.11.	By accepting the Order Request, the Supplier undertakes to arrange, and execute the Order Fulfillment. The Completed Delivery of the Order can be deemed only when the Order has arrived at the Buyer’s location and the Buyer has signed the delivery note. Until the moment of the Completed Delivery, the Products are the responsibility of the Supplier.
            <br /><br />
            9.12.	The Buyer agrees to provide a valid, physical address to which the Supplier should deliver and the Buyer will receive the Products purchased through the Platform.
            <br /><br />
            9.13.	During the Order Fulfillment, the Supplier uses all reasonable efforts to meet the lead time as defined in their Product Listing, Store and displayed to the Buyer when placing the Order on the Platform. In the event of experiencing delays, the Supplier agrees to communicate about any delay with the Buyer immediately through the Platform.
            <br /><br />
            9.14.	The Supplier agrees to issue a delivery note, and the Buyer undertakes to sign the delivery note on the date the Order is delivered to the Buyer.
            <br /><br />
            9.15.	The Buyer agrees to receive the Order status updates as well as information when the Complaint Submission Period starts at its e-mail address and on the Platform.
            <br /><br />
            9.16.	Orders placed by the Buyer are independent of each other and identifiable with a unique Order number.
            <br /><br />
            9.17.	If the Buyer fails to receive the Order, the Buyer is responsible for contacting the Supplier and solving the issue with the Supplier directly.
            <br /><br />
            9.18.	After the Completed Delivery, the Complaint Submission Period sets in, and the Buyer may flag any issue with the received Order and/or Products within 72 hours by contacting the Supplier and/or VEGSHELF through the Platform. If there are no issues flagged within 72 hours, the Complaint Submission Period expires and the Order is deemed successfully completed.
            <br /><br />
            9.19.	The Buyer is obliged to contact VEGSHELF immediately at <a href="mailto:hello@vegshelf.com" target="_blank">hello@vegshelf.com</a> in any of the following cases: (i) received wrong Products; (ii) received damaged Products; (iii) received suspected or actual counterfeit Products; (iv) no Products have been delivered while system falsely claims that Order has been delivered; (v) Products do not match to the Product Listing’s photos and description on the Platform.
            <br /><br />
            9.20.	The Buyer agrees that it is their sole responsibility to resolve the complaints directly with the Supplier, by the means of, including but not limited to, accepting the money back, or receiving the re-sent Order from the Supplier. The Buyer agrees that VEGSHELF has no obligation to intervene at all and the Buyer undertakes to indemnify VEGSHELF from any liability in this regard.
            <br /><br />
            9.21.	VEGSHELF does not act exclusively for any one Supplier, nor is affiliated with any particular Supplier. Any interactions and communication the Buyer has with the Supplier through the Platform and the Service are solely between the Buyer and the Supplier. VEGSHELF disclaims all liability in connection therewith.
            <br /><br />
            9.22.	Each Member, including Suppliers, on the Platform has been vetted by VEGSHELF, including but not limited to verifying their company registration information, email addresses, website, and traction. By doing this VEGSHELF ensures the quality and trustworthiness of Suppliers using the Platform.
            <br /><br />
            9.23.	VEGSHELF is not and will not be the Party to any contract that may come into existence between the Buyer and the Supplier, or whomever the Buyer may otherwise get to know through the Platform and the Service, and VEGSHELF will not be held liable for any damages, costs, losses, and claims suffered by the Buyer as a result of the Buyer’s business with any Supplier they have come into contact with through the Platform and the Service.
            <br /><br />
            9.24.	In the event that VEGSHELF becomes aware of the Buyer’s activity (i) encouraging the Supplier to complete purchases outside of the Platform; (ii) enticing or attempting to entice the Supplier away from the Platform; (iii) infringing laws and regulations applicable to VEGSHELF; (iv) being potentially damaging, anti-commercial, unfair, defamatory or harmful towards VEGSHELF, any Member, the Platform or any third-party, VEGSHELF reserves the right to terminate these Terms immediately.
            <br /><br /><br />

            <b>10. INVOICING</b>
            <br /><br />
            10.1.	The Buyer through its Account on the Platform is responsible to provide and ensure that all the legal information that has to appear on the Invoice in regard to the Buyer’s legal obligations and domestic laws is correct and up-to-date.
            <br /><br />
            10.2.	On the date when the Supplier confirms the Order Request, the Invoice for the Order total sum (including taxes and shipping) is prepared by VEGSHELF for and on behalf of the Supplier and is sent to the Buyer on the Platform and/or e-mail. On this date, all the Invoice details are frozen and irrevocable. The Order Invoice is being settled by the Buyer automatically on the date when the Supplier confirms the Order Request and previously reserved Order total sum (incl. tax and shipping fee) is being debited from the Buyer’s bank account.
            <br /><br /><br />

            <b>11. PROHIBITED USES</b>
            <br /><br />
            11.1.	The Visitor and the Buyer have a duty to act in good faith and respectfully in their relationships with VEGSHELF and other Visitors and Members. The Visitor and the Buyer undertakes to refrain from doing any of the following:
            <br /><br />
            11.1.1.	Avoid or attempt to bypass any measures VEGSHELF may use to prevent or restrict access to the Platform and/or the Service;
            <br /><br />
            11.1.2.	Embed, display, mirror, use or frame the Platform, or any individual element within the Platform or the Service, VEGSHELF name, trademark and/or logo, the Platform layout, element and/or design of any page without prior written consent from VEGSHELF;
            <br /><br />
            11.1.3.	Use any kind of manual or automated software, data-mining or other tools to "crawl" or “spider” any page of the Platform, or collect or extract data from the Platform or the Service;
            <br /><br />
            11.1.4.	Scan, test or probe the vulnerability of any VEGSHELF system or network or breach any authentication or security measures;
            <br /><br />
            11.1.5.	Decompile, disassemble, reverse engineer, or otherwise attempt to obtain any of the source code, underlying ideas, information, software, or algorithms used to provide the Platform, Service, and Content;
            <br /><br />
            11.1.6.	Use any meta tags, metadata, or other hidden text using VEGSHELF trademark, logo, URL, or Product name without written consent from VEGSHELF;
            <br /><br />
            11.1.7.	Store or collect any information from or of other Members and/or Visitors, by which they may be identifiable, from the Platform and the Service without prior written consent from them and in a manner that does not violate Privacy Policy of VEGSHELF or relevant privacy laws;
            <br /><br />
            11.1.8.	Harvest any Content from the Platform or the Services without prior written consent from VEGSHELF;
            <br /><br />
            11.1.9.	Interfere with or aim to interfere with proper workings of the Platform and the Service and/or the access of any Member, Visitor, network, host, including but not limited to sending a virus, flooding, spamming, overloading the Platform and the Service;
            <br /><br />
            11.1.10. Bypass any measures VEGSHELF has put in place to secure the Platform and the Service;
            <br /><br />
            11.1.11. Submit, upload, download, post, or otherwise distribute any Content on the Platform or through the Service that: i) breaches and infringes any trademark, patent, trade secret, copyright, right of publicity, or other rights of another person or entity and/or violates any law or legal duty; ii) is imprecise, false, ambiguous, inaccurate, or untruthful; iii) impersonates any person or entity and related sensitive information; iv) creates unsolicited or unauthorized advertising or promotional material, spam, junk mail, or chain letters; v) contains software viruses, worms, files, Trojan horses, other malicious programs or any other form of malware intended to disrupt and damage the proper function of hardware or software, or to damage or obtain unauthorized access to any data, password, or other information of VEGSHELF, Members or of any third party using the Platform and the Service; vi) is fraudulent, unlawful, abusive, obscene, pornographic, vulgar, offensive, or is inappropriate in any other way as determined by VEGSHELF at its exclusive discretion; vii) promotes discrimination, bigotry, hatred, racism, or harassment against any individual or group; viii) promotes illegal or harmful activities or substances.
            <br /><br />
            11.1.12. Misrepresent or impersonate any other person or legal entity, including Members, and VEGSHELF employees;
            <br /><br />
            11.1.13.	 Try to damage or get unauthorized access to any system, the Account, log in and/or password, data, or other information in relation to the Platform, whether it belongs to VEGSHELF, any Member or other Party;
            <br /><br />
            11.1.14. Create cognate works of a full or separate part of the Platform and/or the Service;
            <br /><br />
            11.1.15. Copy, lease, or distribute the rights that the Buyer and/or the Visitor receives while using the Platform and/or the Service;
            <br /><br />
            11.1.16. Take any action that imposes an unreasonable load on VEGSHELF infrastructure, as determined by VEGSHELF at its sole discretion;
            <br /><br />
            11.1.17. Seek, ask, invite or seduce other Members to circumvent and do business with other Members outside of the Platform and/o Service;
            <br /><br />
            11.1.18. Use the Platform, the Services, and the Content in any way prohibited by these Terms and/or encourage and/or enable any third-party to do any of the things mentioned above.
            <br /><br />
            11.2.	If it becomes known to the Buyer or the Visitor that these Terms are violated by any other Member or Visitor, they agree to immediately notify VEGSHELF by sending an e-mail to <a href="mailto:hello@vegshelf.com" target="_blank">hello@vegshelf.com</a>.
            <br /><br />
            11.3.	The Visitor and the Buyer are liable for any damages or costs sustained by VEGSHELF due to their infringement of these Terms.
            <br /><br />
            11.4.	VEGSHELF reserves the right to investigate and prosecute violations of these Terms to the fullest extent of the law. VEGSHELF reserves the right to involve, cooperate, share information with law enforcement authorities, government agencies, or other oversight bodies for the prosecution of the Visitor or the Buyer who violate these Terms.
            <br /><br />
            11.5.	Parties agree that VEGSHELF’s recordings on VEGSHELF’s or its third-party servers related to the use of the Platform and the Service, connection and activity logs, any documents, e-mails, and communication exchanged on the Platform or in the context of these Terms, is considered as proof between the Parties.
            <br /><br /><br />

            <b>12. MARKETING OF THE PLATFORM AND LINKS TO THIRD PARTY WEBSITES</b>
            <br /><br />
            12.1.	The Buyer agrees not to provide links to any third-party website in their digital company profile, which may violate any of these Terms, unless explicitly stated otherwise in these Terms or by the guidelines on the Platform. VEGSHELF reserves the right to remove links from the Platform without prior notice to the Buyer.
            <br /><br />
            12.2.	The Buyer may link VEGSHELF homepage (<a href="https://vegshelf.com/" target="_blank">www.vegshelf.com</a>) to advertise their Account or the Platform and the Service to their audience, Visitors, and other Members, and provided the Buyer does so only in a way that is fair, legal, and does not damage VEGSHELF reputation.
            <br /><br />
            12.3.	The Buyer agrees not to use “spam” or unnecessarily repetitive information to market or sell their business to any Supplier on the Platform and through the Service.
            <br /><br />
            12.4.	The Platform and the Service may contain links to third-party websites or resources. The Visitor and the Buyer agree that VEGSHELF is not liable or responsible for the products, services, or content of such websites, as well as the availability or correctness of such websites or resources. The Visitor and the Buyer undertake sole responsibility and assume all risks arising from their use of any such websites or resources.
            <br /><br /><br />

            <b>13. ELECTRONIC COMMUNICATIONS</b>
            <br /><br />
            13.1.	The Visitor and the Buyer agree and consent to receive communication from VEGSHELF electronically. VEGSHELF will communicate with the Visitor and the Buyer by e-mail, phone, or by posting notices on the Platform and/or through the Service. The Visitor and the Buyer agree that all agreements, notices, and other communications that VEGSHELF provides to them electronically satisfy any legal requirements that such communications be in writing.
            <br /><br /><br />

            <b>14. PRIVACY AND PERSONAL DATA</b>
            <br /><br />
            14.1.	VEGSHELF obtains and handles data relating to identified or identifiable individuals for the purpose of carrying out the Services and managing relationships with Visitors and Buyers.
            <br /><br />
            14.2.	VEGSHELF processes the Visitor’s and the Buyer’s personal data in accordance with GDPR as detailed in VEGSHELF’s Privacy Policy, which is available on the Platform and is incorporated in these Terms.
            <br /><br /><br />

            <b>15. EFFECTIVE DATE OF THE TERMS</b>
            <br /><br />
            15.1.	For the Buyer these Terms come into force on the Buyer’s registration on an indefinite term basis until the contractual relationship is terminated in accordance with article 16 of these Terms.
            <br /><br /><br />

            <b>16. TERMINATION AND DELETION OF THE ACCOUNT</b>
            <br /><br />
            16.1.	VEGSHELF holds the right to terminate or disable the Buyer’s Account and suspend the Buyer’s access to the Platform and the Services at any time, with or without notice, and for any reason at VEGSHELF discretion, including the Buyer’s breach of these Terms. VEGSHELF holds the sole right to decide whether the Buyer is in violation of any of the restrictions set forth in these Terms. Termination of the Account may result in the destruction of all information associated with the Buyer’s Account.
            <br /><br />
            16.2.	If for any reason the Buyer wishes to terminate or delete their Account, they may do so at any time by contacting VEGSHELF at <a href="mailto:hello@vegshelf.com" target="_blank">hello@vegshelf.com</a>. When the Buyer’s Account is deleted, it will be removed from the Platform and the Services. However, the Buyer’s Content may persist in backup copies and on the Platform for a reasonable period of time as determined by VEGSHELF and according to applicable laws.
            <br /><br />
            16.3.	Provisions that, by nature should survive termination of these Terms shall survive termination. By way of example, all of the following will survive termination: any obligation the Buyer has to pay or indemnify to VEGSHELF, any limitations on VEGSHELF liability, any part of these Terms regarding ownership of intellectual property rights, and the Terms regarding disputes between VEGSHELF and the Buyer.
            <br /><br /><br />

            <b>17. WARRANTY DISCLAIMER AND LIABILITY</b>
            <br /><br />
            17.1.	VEGSHELF gives no warranty to the Buyer regarding the Products, their quality, compliance, availability, and related claims and/or warranties the Supplier has made on the Platform and/or through the Service. The Buyer agrees that VEGSHELF does not have the control and/or power to verify or inspect any Products sold on the Platform. The Buyer indemnifies and holds VEGSHELF harmless from any losses, liabilities, damages and costs resulting from a claim from the Supplier or any third party.
            <br /><br />
            17.2.	VEGSHELF does not make any representations or warranties concerning any Content contained on or accessed through the Platform and the Service, and will not be responsible or liable for the completeness, safety, accuracy, quality, copyright compliance, legality, or reliability of the Content contained in or accessed through the Services. VEGSHELF makes no representations or warranties regarding suggestions or recommendations of the Products offered or purchased through the Platform and the Service.
            <br /><br />
            17.3.	To the fullest extent permitted by applicable law, the liability of VEGSHELF for contractual breaches of duty and tort shall be limited to intent and gross negligence. This does not apply in cases of injuries to life, limb, health, and claims due to delay as well as claims due to the breach of essential contractual obligations, i.e. obligations arising from the nature of the contract and the breach of which endangers the achievement of the purpose of the contract. In this respect, VEGSHELF is liable for every degree of fault. In the event of a slightly negligent breach of essential contractual obligations, VEGSHELF shall only be liable for the typically foreseeable damage. In all other respects, no liability exists. Claims arising from the Product Liability Act shall remain unaffected.
            <br /><br />
            17.4.	To the fullest extent allowed by applicable law, the Buyer agrees to indemnify and hold VEGSHELF, its affiliates, agents, employees, and partners harmless from and against any and all claims, liabilities, damages (actual and consequential), losses, and expenses (including attorneys’ fees and other legal costs) arising from or in any way related to any third party claims relating to (i) the Buyer’s misuse of the Platform and the Service (including any actions taken by a third party using the Buyer’s Account due to at least negligent loss of log-in data by the Buyer), and (ii) the Buyer’s violation of these Terms.
            <br /><br />
            17.5.	The Buyer agrees to compensate VEGSHELF in a timely manner for any reasonably and properly incurred expenses (including expenses of attorneys) as they are incurred by VEGSHELF in regard to investigating, preparing for, or providing evidence in any pending or threatened action or claim brought under due to (i) the Buyer’s misuse of the Services (including any actions taken by a third party using the Buyer’s Account), and (ii) the Buyer’s violation of these Terms.
            <br /><br /><br />

            <b>18. ASSIGNMENT</b>
            <br /><br />
            18.1.	The Buyer is not allowed to assign, transfer, sublicense or delegate their rights or obligations hereunder, or their Account to any other third party, in any way without prior written consent by VEGSHELF.
            <br /><br />
            18.2.	VEGSHELF may freely transfer, assign, delegate or otherwise dispose of all or part of its rights, obligations, or these Terms without consent.
            <br /><br /><br />

            <b>19. DISPUTE RESOLUTION AND GOVERNING LAW; JURISDICTION</b>
            <br /><br />
            19.1.	VEGSHELF encourages Buyers and Visitors to contact VEGSHELF if they are having an issue, before resorting to the courts. In the unfortunate situation where legal action does arise, these Terms and all other rules and policies incorporated by reference will be governed by and construed in accordance with the laws of the Federal Republic of Germany.
            <br /><br />
            19.2.	If the Buyer or the Visitor is a merchant according to German law, a legal entity under public law or a special fund under public law, the place of jurisdiction shall be VEGSHELF registered office. However, VEGSHELF shall also be entitled to sue at the court of the Buyer’s place of residence.
            <br /><br />
            19.3.	Any disputes between Members shall be settled between the relevant Members without VEGSHELF intervening in the settlement of or to settle such disputes.
            <br /><br /><br />

            <b>20. MISCELLANEOUS</b>
            <br /><br />
            20.1.	If any provision of these Terms is found to be invalid, unenforceable, or ineffective, that provision will be limited or eliminated to the minimum extent necessary, so these Terms shall otherwise remain in full force, effect, and enforceable.
            <br /><br />
            20.2.	Legally relevant declarations and notifications regarding this contractual relationship must be made in writing or in electronic text.
          </p>
        </div>
      </div>
    );
  }
};

BuyerTermsPageComponent.defaultProps = {
  currentUserListing: null,
  currentUserListingFetched: false,
};

BuyerTermsPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};
const mapStateToProps = state => {
  const { currentUser } = state.user;
  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser
  };
};

const BuyerTermsPage = compose(
  withRouter,
  connect(mapStateToProps),
  injectIntl
)(BuyerTermsPageComponent);

export default BuyerTermsPage;