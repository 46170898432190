import React, { Component } from 'react';
import { bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { propTypes } from '../../util/types';
import config from '../../config';
import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  TabNav,
} from '../../components';
import {
  TopbarContainer,
  HowItWorksForSuppliersPage,
  HowItWorksForBuyersPage
} from '..';
import { localePart, storedLocale } from '../../components/Wrapper/ClassWrapper';

import facebookImage from '../../assets/VegshelfFacebook-1200x630.png';
import twitterImage from '../../assets/VegshelfTwitter-600x314.png';
import css from './HowItWorksPage.module.css';

export class HowItWorksPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { stepIndex: 0 };
  }
  render() {
    const {
      intl,
      scrollingDisabled,
    } = this.props;
    let { tab } = this.props;
    tab = tab || 'for-suppliers';
    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage({ id: 'HowItWorksPage.schemaTitle' }, { siteTitle });
    const schemaDescription = intl.formatMessage({ id: 'HowItWorksPage.schemaDescription' }, { siteTitle });
    const schemaImage = `${config.canonicalRootURL}${facebookImage}`;
    //const locale = sessionStorage.getItem('locale') || localePart();
    const locale = storedLocale() || localePart();
    var pages = {
      'for-suppliers': (<HowItWorksForSuppliersPage {...this.props} />),
      'for-buyers': (<HowItWorksForBuyersPage {...this.props} />)
    };

    const tabs = [
      {
        text: (
          <span>
            <FormattedMessage id="HowItWorksPage.Suppliers" />
          </span>
        ),
        selected: tab == 'for-suppliers',
        linkProps: {
          name: 'HowItWorksForSuppliersPage',
          //params: { tab: 'for-suppliers' }
          params: { tab: 'for-suppliers', locale: locale }
        },
      },
      {
        text: (
          <span>
            <FormattedMessage id="HowItWorksPage.Buyers" />
          </span>
        ),
        selected: tab == 'for-buyers',
        linkProps: {
          name: 'HowItWorksForBuyersPage',
          //params: { tab: 'for-buyers' }
          params: { tab: 'for-buyers', locale: locale }
        },
      }
    ];
    const nav = <TabNav rootClassName={css.tabs} tabRootClassName={css.tab} tabs={tabs} />;

    return (
      <Page
        className={css.root}
        scrollingDisabled={scrollingDisabled}
        contentType="website"
        description={schemaDescription}
        title={schemaTitle}
        robots='index'
        facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
        twitterImages={[
          { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
        ]}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'WebPage',
          description: schemaDescription,
          name: schemaTitle,
          image: [schemaImage],
        }}
      >
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div className={css.aboutVegshelfContainer}>
              <div className={css.tabContainer}>
                {nav}
              </div>
              <div className={css.informationContainer}>
                {pages[tab]}
              </div>
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
};

HowItWorksPageComponent.defaultProps = {
  currentUserListing: null,
  currentUserListingFetched: false,
};

HowItWorksPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};
const mapStateToProps = state => {
  const { currentUser } = state.user;
  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser
  };
};



const HowItWorksPage = compose(
  withRouter,
  connect(mapStateToProps),
  injectIntl
)(HowItWorksPageComponent);

export default HowItWorksPage;
