import classNames from "classnames";
import { object } from "prop-types";
import React, { Component } from "react";
import { defineTheLongestDeliveryPeriod, MINIMAL_ORDER_FOR_FREE_SHIPPING } from "../../ducks/shopping-cart.duck";
import { TransitionStates } from "../../ducks/transition.duck";
import IconCard from "../IconCard/IconCard";
import NamedLink from "../NamedLink/NamedLink";
import { DownloadOrderInvoiceSection } from "../TransactionViewItem/TransactionViewItem";
import { slugify } from "../../util/urlTrimming";
import css from "./BuyerTransactionViewItem.module.css";
import { FormattedMessage } from "react-intl";
import { storedLocale, localePart } from "../Wrapper/ClassWrapper";

const locale = storedLocale() || localePart();

function formattedDate(date, separator = "/") {
  if (!date) {
    return null;
  }
  let year = date.getFullYear();
  let month = (1 + date.getMonth()).toString().padStart(2, "0");
  let day = date.getDate().toString().padStart(2, "0");

  return day + separator + month + separator + year;
}

function formatPrice(price) {
  const msrp = parseFloat(price);
  return (Math.round(msrp * 100) / 100).toFixed(2);
}

/**
 * Actual shipping fee.
 * @param {number} brandTotal
 * @param {number} shippingFee
 * @returns {number}
 */
export function calculateBrandShippingFee({ brandTotal, shippingFee }) {
  return brandTotal < MINIMAL_ORDER_FOR_FREE_SHIPPING ? shippingFee : 0;
}

export class BuyerTransactionViewItem extends Component {
  constructor(props) {
    super(props);
    this.state = { shippedDate: new Date(), displayShippingDaySeletor: false, displayDeliveredConfirm: false };
  }

  render() {
    const { cart, showDetails, onShowDetailsClick, buyerCurrentCompany } = this.props;
    const transactions = cart?.transactions || [];
    const brandsShortData = transactions.map((t) => ({
      id: `${t?.attributes?.protectedData?.brandInfo?.brandId}`,
      name: t?.attributes?.protectedData?.brandInfo?.brandName,
      status: TransitionStates.parseBuyerState(t.attributes.lastTransition),
    }));
    const firstTransaction = transactions[0];
    const brands = cart?.validBrands || [];
    const productsImages = [];
    brands.forEach((b) => {
      b.products.forEach((p) => {
        if (p.mainImageUrl && productsImages.length !== 4) {
          productsImages.push(p.mainImageUrl);
        }
      });
    });
    if (productsImages.length > 1 && productsImages.length < 4) {
      for (let i = productsImages.length; i < 4; i++) {
        productsImages.push(null);
      }
    }
    const deliveryData = cart?.deliveryData || {};
    const paymentData = cart?.paymentData || {};
    const currentBillingData = buyerCurrentCompany?.attributes?.publicData;

    /**
     * {orderSummaryInitialValue} - Accumulator value.
     * @type {{initRawVAT: number, finalShippingCosts: number, finalRawVAT: number, initShippingCosts: number, initSubTotalExclVAT: number, finalSubTotalExclVAT: number}}
     */
    const orderSummaryInitialValue = {
      initSubTotalExclVAT: 0,
      initShippingCosts: 0,
      initRawVAT: 0,
      finalSubTotalExclVAT: 0,
      finalShippingCosts: 0,
      finalRawVAT: 0,
    };
    /**
     * Order Summary Calculation.
     * @returns {orderSummaryInitialValue}
     */
    const orderSummary = brands.reduce((acc, { brandTotal, shippingFee, cancelled, brandVAT }) => {
      const shippingCosts = calculateBrandShippingFee({ brandTotal, shippingFee });

      acc.initSubTotalExclVAT += brandTotal;
      acc.initShippingCosts += shippingCosts;
      acc.initRawVAT += brandVAT;

      if (!cancelled) {
        acc.finalSubTotalExclVAT += brandTotal;
        acc.finalShippingCosts += shippingCosts;
        acc.finalRawVAT += brandVAT;
      }

      return acc;
    }, orderSummaryInitialValue);
    const {
      initSubTotalExclVAT,
      initShippingCosts,
      initRawVAT,
      finalSubTotalExclVAT,
      finalShippingCosts,
      finalRawVAT,
    } = orderSummary;

    const subTotalExclVAT = formatPrice(initSubTotalExclVAT);
    const shippingFee = formatPrice(initShippingCosts);
    const subTotalExclVATWithShippingFee = formatPrice(finalSubTotalExclVAT + finalShippingCosts || 0);
    const VAT = formatPrice(initRawVAT);
    const subTotalInclVAT = formatPrice((finalSubTotalExclVAT + finalShippingCosts + finalRawVAT) || 0);

    return (
      <div className={classNames(css.transactionItem, { [css.showDetailsEnable]: showDetails })}>
        <div className={css.shortDataContainer}>
          <div className={css.orderLogo}>
            {productsImages.map((imgUrl, idx) => {
              const styleLogo = {};
              if (imgUrl) {
                styleLogo.backgroundImage = `url('${imgUrl}')`;
              }
              return (
                <div
                  key={`oi_${cart.key}_${idx}`}
                  className={classNames({
                    [css.singleImageProduct]: productsImages.length === 1,
                    [css[`imagePart${idx}`]]: productsImages.length !== 1,
                  })}
                  style={styleLogo}
                >
                  &nbsp;
                </div>
              );
            })}
          </div>
          <div className={css.shortTotalInfo}>
            <div className={css.orderInfoContainer}>
              <div className={css.orderShortInfo}>
                <div className={css.orderDate}>
                  {formattedDate(firstTransaction.attributes.createdAt)}
                </div>
                <div className={css.orderTotal}>
                  {subTotalInclVAT}&nbsp;€
                </div>
              </div>
              <div className={css.supplierNameAndStatusContainer}>
                {brandsShortData.map((b, index) => {
                  return (
                    <div key={`sbi_${b.id}_${index}`} className={css.supplierNameAndStatusRow}>
                      <NamedLink name="SupplierDetailsPage" params={{ brand: slugify(b.name), id: b.id, locale: locale }}>
                        <div className={css.supplierBrandName}>
                          {b.name}
                        </div>
                      </NamedLink>
                      <div className={classNames(css.shortBrandInfoStatus,
                        { [css.brandCanceledStatus]: b.status === "CANCELLED" })}>
                        {b.status}
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
            <div className={css.detailsButtonContainer}>
              <a className={css.showDetailsButton} onClick={() => onShowDetailsClick()}>
                <FormattedMessage id="BuyerTransactionViewItem.viewOrder" />
              </a>
            </div>
          </div>
        </div>
        {showDetails && (
          <div className={css.orderDetailsContainer}>
            <div className={css.orderDetailsRow}>
              <div>
                <div className={css.previewItemData}>
                  <div className={css.previewItemFirstColumn}>
                    <h1 className={css.previewItemTitle}>
                      <FormattedMessage id="BuyerTransactionViewItem.deliveryAddress" />
                    </h1>
                    <div className={css.previewItemValue}>{deliveryData.companyName}</div>
                    <div className={css.previewItemValue}>{deliveryData.streetAndNumber}</div>
                    <div className={css.previewItemValue}>{deliveryData.additionalInfo}</div>
                    <div className={css.previewItemValue}>{`${deliveryData.postCode} ${deliveryData.city}`}</div>
                    <div className={css.previewItemValue}>{deliveryData.region}</div>
                    <div className={css.previewItemValue}>{deliveryData.country}</div>
                  </div>
                </div>
                <div className={css.previewItemData}>
                  <div className={css.previewItemFirstColumn}>
                    <h1 className={css.previewItemTitle}>
                      <FormattedMessage id="BuyerTransactionViewItem.billingAddress" />
                    </h1>
                    <div className={css.previewItemValue}>{currentBillingData?.companyLegalName}</div>
                    <div className={css.previewItemValue}>{currentBillingData?.streetAndNumber}</div>
                    <div className={css.previewItemValue}>
                      {`${currentBillingData.postcode} ${currentBillingData.city}`}
                    </div>
                    <div className={css.previewItemValue}>{currentBillingData?.country}</div>
                  </div>
                </div>
              </div>
              {paymentData.card ? (
                <div>
                  <div className={css.previewItemData}>
                    <div className={css.previewItemFirstColumn}>
                      <h1 className={css.previewItemTitle}>
                        <FormattedMessage id="BuyerTransactionViewItem.paymentMethod" />
                      </h1>
                      <div className={css.previewItemValue}>
                        <div>
                          <IconCard brand={paymentData.card.brand} className={css.cardIcon} />
                          ****&nbsp;{paymentData.card.last4Digits}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              <div className={css.orderSummary}>
                <h1>
                  <FormattedMessage id="BuyerTransactionViewItem.orderSummary" />
                </h1>
                <div className={css.brandPriceBlock}>
                  <div key={`brandTotalPrice_Products`} className={css.cartProperty}>
                    <div className={css.propertyLabel}>
                      <FormattedMessage id="ShoppingCartPage.summaryTitle" />
                    </div>
                    <div className={css.propertyValue}>
                      {subTotalExclVAT}&nbsp;€
                    </div>
                  </div>
                  {brands.map((p, index) => {
                    return (
                      <React.Fragment key={`${p.brandId}_${index}`}>
                        {p.cancelled ? (
                          <div key={`brandTotalPrice${p.brandId}`} className={css.cartProperty}>
                            <div className={css.propertyLabel}>
                              <FormattedMessage id="BuyerTransactionViewItem.cancellation" />{p.brandName}&nbsp;
                            </div>
                            <div className={css.propertyValue}>
                              -&nbsp;{formatPrice(p.brandTotal)}&nbsp;€
                            </div>
                          </div>
                        ) : null}
                      </React.Fragment>
                    );
                  })}
                  <div className={css.cartProperty}>
                    <div className={css.propertyLabel}>
                      <FormattedMessage id="BuyerTransactionViewItem.shipping" />
                    </div>
                    <div className={css.propertyValue}>
                      {shippingFee}&nbsp;€
                    </div>
                  </div>
                  {brands.map((p, index) => {
                    const isPaidShipping = p.brandTotal < MINIMAL_ORDER_FOR_FREE_SHIPPING;

                    return (
                      <React.Fragment key={`${p.brandId}_${index}`}>
                        {p.cancelled ? (
                          <div key={`brandTotalPrice${p.brandId}`} className={css.cartProperty}>
                            <div className={css.propertyLabel}>
                              <FormattedMessage id="BuyerTransactionViewItem.cancellation" />{p.brandName}&nbsp;
                            </div>
                            <div className={css.propertyValue}>
                              {`- ${formatPrice(isPaidShipping ? p.shippingFee : 0.00)} €`}
                            </div>
                          </div>
                        ) : null}
                      </React.Fragment>
                    );
                  })}
                </div>
                <div className={css.cartProperty}>
                  <div className={css.propertyLabel}>
                    <FormattedMessage id="BuyerTransactionViewItem.subtotalExclVAT" />
                  </div>
                  <div className={css.propertyValue}>
                    {subTotalExclVATWithShippingFee}&nbsp;€
                  </div>
                </div>
                <div className={css.cartProperty}>
                  <div className={css.propertyLabel}>
                    <FormattedMessage id="BuyerTransactionViewItem.vat" />
                  </div>
                  <div className={css.propertyValue}>
                    {VAT}&nbsp;€
                  </div>
                </div>
                {brands.map((p, index) => {
                  return (
                    <React.Fragment key={`${p.brandId}_${index}`}>
                      {p.cancelled ? (
                        <div key={`brandTotalPrice${p.brandId}`} className={css.cartProperty}>
                          <div className={css.propertyLabel}>
                            <FormattedMessage id="BuyerTransactionViewItem.cancellation" />{p.brandName}&nbsp;
                          </div>
                          <div className={css.propertyValue}>
                            {`- ${formatPrice(p.brandVAT)} €`}
                          </div>
                        </div>
                      ) : null}
                    </React.Fragment>
                  );
                })}
                <div className={css.cartPropertyTotal}>
                  <div className={css.propertyLabelTotal}>
                    <FormattedMessage id="BuyerTransactionViewItem.orderTotal" />
                  </div>
                  <div className={css.propertyValueTotal}>
                    {subTotalInclVAT}&nbsp;€
                  </div>
                </div>
              </div>
            </div>
            {this.renderProductsStep()}
            {/*TODO: Temporary hidden!*/}
            {/*<DownloadOrderInvoiceSection disabled={false} brands={brands.map((brand) => `"${brand.brandName}"`)} />*/}
          </div>
        )}
      </div>
    );
  }

  renderProductsStep() {
    const validBrands = this.props.cart.validBrands || [];

    return (
      <div className={css.brands}>
        {validBrands.map((brand) => this.renderBrandItem(brand))}
      </div>
    );
  }

  renderBrandItem(brand) {
    const { brandTotal, shippingFee } = brand;
    const longestShippingMessage = defineTheLongestDeliveryPeriod(brand.products);
    const brandShippingCosts = calculateBrandShippingFee({ brandTotal, shippingFee });

    return (
      <div key={brand?.brandId || -1} className={css.brandItem}>
        <div className={css.brandItemHeader}>
          <div className={css.brandHeaderMainColumn}>
            {brand.cancelled ? <h4 className={css.brandCancelledLabel}>
              <FormattedMessage id="BuyerTransactionViewItem.cancelledBySupplier" />
            </h4> : null}
            <h2 className={css.brandTitle}>
              {brand?.brandId ?
                <NamedLink name="SupplierDetailsPage" params={{ brand: slugify(brand.brandName), id: brand.brandId, locale: locale }}>
                  {brand.brandName}
                  &nbsp; |&nbsp;
                </NamedLink>
                : null
              }
              <span>
                {brand.cancelled ? "-" : null}
                &nbsp;{formatPrice(brandTotal)}&nbsp;€<FormattedMessage id="BuyerTransactionViewItem.exclVAT" />
              </span>
            </h2>
            <h4 className={css.brandSubTitle}><FormattedMessage id="BuyerTransactionViewItem.orderNumber" />{brand.transaction.id.uuid}</h4>
          </div>
          <NamedLink
            name="BuyerSendMessagePage"
            className={css.shopBrandButton}
            params={{ receiver: brand.brandId, title: "Re", locale: locale }}
          >
            <FormattedMessage id="BuyerTransactionViewItem.messageBrand" />
          </NamedLink>
        </div>
        <div className={css.productContainer}>
          <div className={css.productItemsContainer}>
            {brand.products.map((product) => {
              const chooseStep = product?.unitsOfPackage || 1;
              const chooseQuantityItems = [];
              const currentValue = parseFloat(product.count);
              for (let i = 1; i < 11 || i <= currentValue; i++) {
                chooseQuantityItems.push(chooseStep * i);
              }
              const style = {};
              if (product.mainImageUrl) {
                style.backgroundImage = `url('${product.mainImageUrl}')`;
              }

              return (
                <div key={product.id} className={css.productItem}>
                  <NamedLink name="ProductDetailsPage" params={{ product: slugify(product.productName), id: product.id, locale: locale }}>
                    <div className={css.productLogo} style={style}>&nbsp;</div>
                  </NamedLink>
                  <div className={css.productDetails}>
                    <div className={css.productNameRow}>
                      <h1 className={css.productName}>
                        <NamedLink name="ProductDetailsPage" params={{ product: slugify(product.productName), id: product.id, locale: locale }}>
                          {product.productName}
                        </NamedLink>
                      </h1>
                    </div>
                    <div className={css.productIDRow}>
                      <FormattedMessage id="BuyerTransactionViewItem.productID" />{product.id}
                    </div>
                    <div className={css.productQuantityAndPriceRow}>
                      <div className={css.productPriceRow}>
                        {product.count}&nbsp;<FormattedMessage id="BuyerTransactionViewItem.units" />
                      </div>
                      <div className={css.productPrice}>
                        <FormattedMessage id="BuyerTransactionViewItem.total" />&nbsp;&nbsp;&nbsp;&nbsp;
                        {brand.cancelled ? "-" : null}
                        &nbsp;{formatPrice(product.totalPrice)}&nbsp;€
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className={css.shippingFeeContainer}>
            <span className={css.brandShippingFee}>
              <FormattedMessage id="BuyerTransactionViewItem.shippingSummary" />{brand.cancelled && brandShippingCosts !== 0 ? "-" : ""}&nbsp;{brandShippingCosts.toFixed(2)}&nbsp;€<FormattedMessage id="BuyerTransactionViewItem.exclVAT" />
            </span>
            {!brand.cancelled ? <span className={css.brandDeliveryTime}>{longestShippingMessage}</span> : null}
          </div>
        </div>
      </div>
    );
  }
}

BuyerTransactionViewItem.propTypes = {
  cart: object.isRequired,
};

export default BuyerTransactionViewItem;