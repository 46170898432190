import { localePart, storedLocale } from "../components/Wrapper/ClassWrapper";

export const LEAD_TIMES_PREFIX = "lt";

//const language = sessionStorage.getItem('locale') || localePart();
const language = storedLocale() || localePart();
const english = language === "en";
const german = language === "de";

const productCategoriesEN = [
  {
    code: 1, name: "Snacks & Sweets", categoryCode: "sweets-and-snacks", subCategories: [
      { code: 2, name: "Chocolate", categoryCode: "chocolate" },
      { code: 3, name: "Bars & Bites", categoryCode: "energyandbarsandbites" },
      { code: 4, name: "Chips & Crackers", categoryCode: "chips" },
      { code: 5, name: "Popcorn & Popped snacks", categoryCode: "pretzels" },
      { code: 6, name: "Candies & Gummies", categoryCode: "candiesandgummies" },
      { code: 7, name: "Cookies & Cakes", categoryCode: "othersweetsandsnacks" },
      { code: 8, name: "Nuts & Dried fruits", categoryCode: "nutsanddriedfruits" },
    ],
  },
  {
    code: 9, name: "Pantry & Condiments", categoryCode: "pantry-and-ready-meals", subCategories: [
      { code: 10, name: "Sweet spreads, Nut butters & Jams", categoryCode: "spreadsandsauces" },
      { code: 11, name: "Sauces, Oils & Vinegars", categoryCode: "oilsandvinegars" },
      { code: 12, name: "Cereals & Granola", categoryCode: "cerealsandmuesli" },
      { code: 13, name: "Baking mixes", categoryCode: "bakingsupplies" },
      { code: 14, name: "Fermented foods & Pickles", categoryCode: "fermentedfoodsandpickles" },
      { code: 15, name: "Ready meals", categoryCode: "readymeals" },
      { code: 16, name: "Tea & Coffee", categoryCode: "teaandcoffee" },
    ],
  },
  {
    code: 17, name: "Beverages", categoryCode: "beverages", subCategories: [
      { code: 18, name: "Alcohol-free spirits", categoryCode: "alcoholfreespirits" },
      { code: 19, name: "Wellness drinks", categoryCode: "wellnessdrinks" },
      { code: 20, name: "Kombucha & Chai", categoryCode: "kombuchachaiandicetea" },
      { code: 21, name: "Energy drinks", categoryCode: "bottledcoffeeandenergydrinks" },
      { code: 22, name: "Soft drinks", categoryCode: "softdrinks" },
    ],
  },
  {
    code: 23, name: "Plant-based Cheese & Dairy", categoryCode: "dairy-and-cheese-alternatives", subCategories: [
      { code: 24, name: "Plant-based cheese", categoryCode: "plantbasedcheese" },
      { code: 25, name: "Plant-based milk", categoryCode: "milkalternatives" },
      { code: 26, name: "Cream alternatives", categoryCode: "plantbasedcream" },
      { code: 27, name: "Plant-based cream cheese", categoryCode: "breadspreadsandvegancreamcheese" },
      { code: 28, name: "Plant-based butter", categoryCode: "butteralternatives" },
    ],
  },
];

const productCategoriesDE = [
  {
    code: 1, name: "Snacks & Süßigkeiten", categoryCode: "sweets-and-snacks", subCategories: [
      { code: 2, name: "Schokolade", categoryCode: "chocolate" },
      { code: 3, name: "Riegel & Häppchen", categoryCode: "energyandbarsandbites" },
      { code: 4, name: "Chips & Cracker", categoryCode: "chips" },
      { code: 5, name: "Popcorn & Gepuffte Snacks", categoryCode: "pretzels" },
      { code: 6, name: "Bonbons & Gummis", categoryCode: "candiesandgummies" },
      { code: 7, name: "Kekse & Kuchen", categoryCode: "othersweetsandsnacks" },
      { code: 8, name: "Nüsse & Trockenfrüchte", categoryCode: "nutsanddriedfruits" },
    ],
  },
  {
    code: 9, name: "Speisekammer", categoryCode: "pantry-and-ready-meals", subCategories: [
      { code: 10, name: "Süße Aufstriche, Nussbutter & Konfitüren", categoryCode: "spreadsandsauces" },
      { code: 11, name: "Essig, Öl & Saucen", categoryCode: "oilsandvinegars" },
      { code: 12, name: "Müsli, Granola & Cerealien", categoryCode: "cerealsandmuesli" },
      { code: 13, name: "Backen", categoryCode: "bakingsupplies" },
      { code: 14, name: "Pickles & Fermentiertes", categoryCode: "fermentedfoodsandpickles" },
      { code: 15, name: "Fertiggerichte", categoryCode: "readymeals" },
      { code: 16, name: "Tee & Kaffee", categoryCode: "teaandcoffee" },
    ],
  },
  {
    code: 17, name: "Getränke", categoryCode: "beverages", subCategories: [
      { code: 18, name: "Alkoholfreie Spirituosen", categoryCode: "alcoholfreespirits" },
      { code: 19, name: "Wellness-Getränke", categoryCode: "wellnessdrinks" },
      { code: 20, name: "Kombucha & Chai", categoryCode: "kombuchachaiandicetea" },
      { code: 21, name: "Energy-Drinks", categoryCode: "bottledcoffeeandenergydrinks" },
      { code: 22, name: "Erfrischungsgetränke", categoryCode: "softdrinks" },
    ],
  },
  {
    code: 23, name: "Milchprodukt-Alternativen", categoryCode: "dairy-and-cheese-alternatives", subCategories: [
      { code: 24, name: "Käse-Alternativen", categoryCode: "plantbasedcheese" },
      { code: 25, name: "Milch-alternativen", categoryCode: "milkalternatives" },
      { code: 26, name: "Creme-Alternativen", categoryCode: "plantbasedcream" },
      { code: 27, name: "Frischkäse-Alternativen", categoryCode: "breadspreadsandvegancreamcheese" },
      { code: 28, name: "Butter-Alternativen", categoryCode: "butteralternatives" },
    ],
  },
];

const reducedProductCategoriesEN = [
  {
    code: 1, name: "Snacks & Sweets", metaName: "sweet & snack", categoryMetaTag: "Sweets & snacks at wholesale prices on Vegshelf", categoryCode: "sweets-and-snacks", subCategories: [
      { code: 2, name: "Chocolate", categoryCode: "chocolate" },
      { code: 3, name: "Bars & Bites", categoryCode: "energyandbarsandbites" },
      { code: 4, name: "Chips & Crackers", categoryCode: "chips" },
      { code: 5, name: "Popcorn & Popped snacks", categoryCode: "pretzels" },
      { code: 6, name: "Candies & Gummies", categoryCode: "candiesandgummies" },
      { code: 7, name: "Cookies & Cakes", categoryCode: "othersweetsandsnacks" },
      { code: 8, name: "Nuts & Dried fruits", categoryCode: "nutsanddriedfruits" },
    ],
  },
  {
    code: 9, name: "Pantry & Condiments", metaName: "condiment & pantry", categoryMetaTag: "Condiments & pantry items at wholesale prices on Vegshelf", categoryCode: "pantry-and-ready-meals", subCategories: [
      { code: 10, name: "Sweet spreads, Nut butters & Jams", categoryCode: "spreadsandsauces" },
      { code: 11, name: "Sauces, Oils & Vinegars", categoryCode: "oilsandvinegars" },
      { code: 12, name: "Cereals & Granola", categoryCode: "cerealsandmuesli" },
      { code: 13, name: "Baking mixes", categoryCode: "bakingsupplies" },
      { code: 14, name: "Fermented foods & Pickles", categoryCode: "fermentedfoodsandpickles" },
      { code: 15, name: "Ready meals", categoryCode: "readymeals" },
      { code: 16, name: "Tea & Coffee", categoryCode: "teaandcoffee" },
    ],
  },
  {
    code: 17, name: "Beverages", metaName: "drink & craft beverage", categoryMetaTag: "Unique drinks & beverages at wholesale prices on Vegshelf", categoryCode: "beverages", subCategories: [
      { code: 18, name: "Alcohol-free spirits", categoryCode: "alcoholfreespirits" },
      { code: 19, name: "Wellness drinks", categoryCode: "wellnessdrinks" },
      { code: 20, name: "Kombucha & Chai", categoryCode: "kombuchachaiandicetea" },
      { code: 21, name: "Energy drinks", categoryCode: "bottledcoffeeandenergydrinks" },
      { code: 22, name: "Soft drinks", categoryCode: "softdrinks" },
    ],
  },
  {
    code: 23, name: "Plant-based Cheese & Dairy", metaName: "plant-based cheese & dairy", categoryMetaTag: "Plant-based cheese & dairy at wholesale prices on Vegshelf", categoryCode: "dairy-and-cheese-alternatives", subCategories: [
      { code: 24, name: "Plant-based cheese", categoryCode: "plantbasedcheese" },
      { code: 25, name: "Plant-based milk", categoryCode: "milkalternatives" },
      { code: 26, name: "Cream alternatives", categoryCode: "plantbasedcream" },
      { code: 27, name: "Plant-based cream cheese", categoryCode: "breadspreadsandvegancreamcheese" },
      { code: 28, name: "Plant-based butter", categoryCode: "butteralternatives" },
    ],
  },
];

const reducedProductCategoriesDE = [
  {
    code: 1, name: "Snacks & Süßigkeiten", metaName: "Süßes & Snacks", categoryMetaTag: "Süßigkeiten & Snacks zu Großhandelspreisen auf Vegshelf", categoryCode: "sweets-and-snacks", subCategories: [
      { code: 2, name: "Schokolade", categoryCode: "chocolate" },
      { code: 3, name: "Riegel & Häppchen", categoryCode: "energyandbarsandbites" },
      { code: 4, name: "Chips & Cracker", categoryCode: "chips" },
      { code: 5, name: "Popcorn & Gepuffte Snacks", categoryCode: "pretzels" },
      { code: 6, name: "Bonbons & Gummis", categoryCode: "candiesandgummies" },
      { code: 7, name: "Kekse & Kuchen", categoryCode: "othersweetsandsnacks" },
      { code: 8, name: "Nüsse & Trockenfrüchte", categoryCode: "nutsanddriedfruits" },
      
    ],
  },
  {
    code: 9, name: "Speisekammer", metaName: "Gewürze & Speisekammer", categoryMetaTag: "Gewürze und Speisekammerartikel zu Großhandelspreisen auf Vegshelf", categoryCode: "pantry-and-ready-meals", subCategories: [
      { code: 10, name: "Süße Aufstriche, Nussbutter & Konfitüren", categoryCode: "spreadsandsauces" },
      { code: 11, name: "Essig, Öl & Saucen", categoryCode: "oilsandvinegars" },
      { code: 12, name: "Müsli, Granola & Cerealien", categoryCode: "cerealsandmuesli" },
      { code: 13, name: "Backen", categoryCode: "bakingsupplies" },
      { code: 14, name: "Pickles & Fermentiertes", categoryCode: "fermentedfoodsandpickles" },
      { code: 15, name: "Fertiggerichte", categoryCode: "readymeals" },
      { code: 16, name: "Tee & Kaffee", categoryCode: "teaandcoffee" },      
    ],
  },
  {
    code: 17, name: "Getränke", metaName: "Getränk & handwerkliches Getränk", categoryMetaTag: "Einzigartige Getränke & Getränke zu Großhandelspreisen auf Vegshelf", categoryCode: "beverages", subCategories: [
      { code: 18, name: "Alkoholfreie Spirituosen", categoryCode: "alcoholfreespirits" },
      { code: 19, name: "Wellness-Getränke", categoryCode: "wellnessdrinks" },
      { code: 20, name: "Kombucha & Chai", categoryCode: "kombuchachaiandicetea" },
      { code: 21, name: "Energy-Drinks", categoryCode: "bottledcoffeeandenergydrinks" },
      { code: 22, name: "Erfrischungsgetränke", categoryCode: "softdrinks" },
    ],
  },
  {
    code: 23, name: "Milchprodukt-Alternativen", metaName: "Käse und Milchprodukte auf pflanzlicher Basis", categoryMetaTag: "Pflanzlicher Käse und Milchprodukte zu Großhandelspreisen auf Vegshelf", categoryCode: "dairy-and-cheese-alternatives", subCategories: [
      { code: 24, name: "Käse-Alternativen", categoryCode: "plantbasedcheese" },
      { code: 25, name: "Milch-alternativen", categoryCode: "milkalternatives" },
      { code: 26, name: "Creme-Alternativen", categoryCode: "plantbasedcream" },
      { code: 27, name: "Frischkäse-Alternativen", categoryCode: "breadspreadsandvegancreamcheese" },
      { code: 28, name: "Butter-Alternativen", categoryCode: "butteralternatives" },      
    ],
  },
];

const freeSamplesEN = [
  { code: "fs1", name: "Yes" },
  { code: "fs2", name: "No" },
];

const freeSamplesDE = [
  { code: "fs1", name: "Ja" },
  { code: "fs2", name: "Nein" },
];

const requiredTemperaturesEN = [
  { code: "t1", name: "Ambient" },
  { code: "t2", name: "Chilled" },
];

const requiredTemperaturesDE = [
  { code: "t1", name: "Umgebungstemperatur" },
  { code: "t2", name: "Kühl" },
];

import iconValue1 from "../assets/ethically-sourced-000000.png";
import iconValue2 from "../assets/women-owned-000000.png";
import iconValue3 from "../assets/gluten-free-000000.png";
import iconValue4 from "../assets/hand-made-000000.png";
import iconValue5 from "../assets/no-artificial-additives-000000.png";
import iconValue6 from "../assets/no-palm-oil-000000.png";
import iconValue7 from "../assets/upcycled-000000.png";
import iconValue8 from "../assets/organic-000000.png";
import iconValue9 from "../assets/small-batch-000000.png";
import iconValue10 from "../assets/sustainable-packaging-000000.png";
import iconValue11 from "../assets/vegan-000000.png";
import iconValue12 from "../assets/zero-sugar-000000.png";
import iconValue13 from "../assets/made-in-europe-000000.png";
import iconValue14 from "../assets/award-winning-000000.png";
import iconValue15 from "../assets/regenerative-ag-000000.png";

const productPropertiesEN = [
  { code: "prop1", name: "Ethically sourced", iconUrl: iconValue1 },
  { code: "prop2", name: "Women owned", iconUrl: iconValue2 },
  { code: "prop3", name: "Gluten-free", iconUrl: iconValue3 },
  { code: "prop4", name: "Hand-made", iconUrl: iconValue4 },
  { code: "prop5", name: "No artificial additives", iconUrl: iconValue5 },
  { code: "prop6", name: "No palm oil", iconUrl: iconValue6 },
  { code: "prop7", name: "Upcycled", iconUrl: iconValue7 },
  { code: "prop8", name: "Organic", iconUrl: iconValue8 },
  { code: "prop9", name: "Small-batch", iconUrl: iconValue9 },
  { code: "prop10", name: "Sustainable packaging", iconUrl: iconValue10 },
  { code: "prop11", name: "Vegan", iconUrl: iconValue11 },
  { code: "prop12", name: "Zero sugar", iconUrl: iconValue12 },
  { code: "prop13", name: "Made in Europe", iconUrl: iconValue13 },
  { code: "prop14", name: "Award-winning", iconUrl: iconValue14 },
  { code: "prop15", name: "Supports regenerative farming", iconUrl: iconValue15 },
];

const productPropertiesDE = [
  { code: "prop1", name: "Ethisch beschafft", iconUrl: iconValue1 },
  { code: "prop2", name: "Von Frauen geführt", iconUrl: iconValue2 },
  { code: "prop3", name: "Glutenfrei", iconUrl: iconValue3 },
  { code: "prop4", name: "Handgemacht", iconUrl: iconValue4 },
  { code: "prop5", name: "Ohne künstliche Zusatzstoffe", iconUrl: iconValue5 },
  { code: "prop6", name: "Ohne Palmöl", iconUrl: iconValue6 },
  { code: "prop7", name: "Upcycled", iconUrl: iconValue7 },
  { code: "prop8", name: "Bio", iconUrl: iconValue8 },
  { code: "prop9", name: "Kleinserien", iconUrl: iconValue9 },
  { code: "prop10", name: "Nachhaltige Verpackung", iconUrl: iconValue10 },
  { code: "prop11", name: "Vegan", iconUrl: iconValue11 },
  { code: "prop12", name: "Ohne Zucker", iconUrl: iconValue12 },
  { code: "prop13", name: "Hergestellt in Europa", iconUrl: iconValue13 },
  { code: "prop14", name: "Preisgekrönt", iconUrl: iconValue14 },
  { code: "prop15", name: "Für regenerative Landwirtschaft", iconUrl: iconValue15 },
];

const unitTypesEN = [
  { code: "ut1", name: "g" },
  { code: "ut2", name: "kg" },
  { code: "ut3", name: "l" },
  { code: "ut4", name: "ml" },
  { code: "ut5", name: "Other" },
];

const unitTypesDE = [
  { code: "ut1", name: "g" },
  { code: "ut2", name: "kg" },
  { code: "ut3", name: "l" },
  { code: "ut4", name: "ml" },
  { code: "ut5", name: "Andere" },
];

const packUnitTypesEN = [
  { code: "put1", name: "per bag" },
  { code: "put2", name: "per box" },
  { code: "put3", name: "per carton" },
  { code: "put4", name: "per crate" },
  { code: "put5", name: "Other" },
];

const packUnitTypesDE = [
  { code: "put1", name: "pro Beutel" },
  { code: "put2", name: "pro Box" },
  { code: "put3", name: "pro Karton" },
  { code: "put4", name: "pro Kiste" },
  { code: "put5", name: "Andere" },
];

const leadTimesEN = [
  { code: `${LEAD_TIMES_PREFIX}1`, name: "1 - 2 business days", minDays: 0, maxDays: 2 },
  { code: `${LEAD_TIMES_PREFIX}2`, name: "3 - 5 business days", minDays: 3, maxDays: 5 },
  { code: `${LEAD_TIMES_PREFIX}3`, name: "6 - 10 business days", minDays: 6, maxDays: 10 },
  { code: `${LEAD_TIMES_PREFIX}4`, name: "11 - 15 business days", minDays: 11, maxDays: 15 },
  { code: `${LEAD_TIMES_PREFIX}5`, name: "16 - 20 business days", minDays: 16, maxDays: 20 },
  { code: `${LEAD_TIMES_PREFIX}6`, name: "21 - 30 business days", minDays: 21, maxDays: 30 },
];

const leadTimesDE = [
  { code: `${LEAD_TIMES_PREFIX}1`, name: "1 - 2 Werktage", minDays: 0, maxDays: 2 },
  { code: `${LEAD_TIMES_PREFIX}2`, name: "3 - 5 Werktage", minDays: 3, maxDays: 5 },
  { code: `${LEAD_TIMES_PREFIX}3`, name: "6 - 10 Werktage", minDays: 6, maxDays: 10 },
  { code: `${LEAD_TIMES_PREFIX}4`, name: "11 - 15 Werktage", minDays: 11, maxDays: 15 },
  { code: `${LEAD_TIMES_PREFIX}5`, name: "16 - 20 Werktage", minDays: 16, maxDays: 20 },
  { code: `${LEAD_TIMES_PREFIX}6`, name: "21 - 30 Werktage", minDays: 21, maxDays: 30 },
];

export const getProductCategories = () => {
  const productCategories = english ? productCategoriesEN : (german ? productCategoriesDE : productCategoriesEN);
  return productCategories.map(({ code, name, categoryCode, subCategories }) => ({
    code,
    name,
    categoryCode,
    subCategories,
  }));
};

export const getReducedProductCategories = () => {
  const reducedProductCategories = english ? reducedProductCategoriesEN : (german ? reducedProductCategoriesDE : reducedProductCategoriesEN);
  return reducedProductCategories.map(({ code, name, metaName, categoryMetaTag, categoryCode, subCategories }) => ({
    code,
    name,
    metaName,
    categoryMetaTag,
    categoryCode,
    subCategories,
  }));
};

export const getUnitTypes = () => {
  const unitTypes = english ? unitTypesEN : (german ? unitTypesDE : unitTypesEN);
  return unitTypes.map(({ code, name }) => ({ code, name }))
};

export const getPackUnitTypes = () => {
  const packUnitTypes = english ? packUnitTypesEN : (german ? packUnitTypesDE : packUnitTypesEN);
  return packUnitTypes.map(({ code, name }) => ({ code, name }))
};

export const getProductProperties = () => {
  const productProperties = english ? productPropertiesEN : (german ? productPropertiesDE : productPropertiesEN);
  return productProperties.map(({ code, name, iconUrl }) => ({ code, name, iconUrl }))
};

export const getRequiredTemperatures = () => {
  const requiredTemperatures = english ? requiredTemperaturesEN : (german ? requiredTemperaturesDE : requiredTemperaturesEN);
  return requiredTemperatures.map(({ code, name }) => ({ code, name }))
};

export const getFreeSamples = () => {
  const freeSamples = english ? freeSamplesEN : (german ? freeSamplesDE : freeSamplesEN);
  return freeSamples.map(({ code, name }) => ({ code, name }))
};

export const getLeadTimes = () => {
  const leadTimes = english ? leadTimesEN : (german ? leadTimesDE : leadTimesEN);
  return leadTimes.map(({ code, name, minDays, maxDays }) => ({ code, name, minDays, maxDays }));
};

const getProductDictionaries = (lang) => ({
  categories: getProductCategories(lang),
  reducedCategories: getReducedProductCategories(lang),
  freeSamples: getFreeSamples(lang),
  requiredTemperatures: getRequiredTemperatures(lang),
  productProperties: getProductProperties(lang),
  unitTypes: getUnitTypes(lang),
  leadTimes: getLeadTimes(lang),
  packUnitTypes: getPackUnitTypes(lang),
});

export default getProductDictionaries;