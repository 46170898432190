import { getAllInvoices } from "../../util/invoicing";
import { storableError } from "../../util/errors";
import { loadSupplierDetailsError } from "../ProductDetailsPage/ProductDetailsPage.duck";
import { types as sdkTypes } from "../../util/sdkLoader";
import { fetchCompanyNamesByUID } from "../SupplierInvoicesPage/SupplierInvoicesPage.duck";

const { UUID } = sdkTypes;

// ================ Action types ================ //
export const BUYER_INVOICES_LOAD = "app/buyer/invoices/request";
export const BUYER_INVOICES_SUCCESS = "app/buyer/invoices/request/success";
export const BUYER_INVOICES_ERROR = "app/buyer/invoices/request/error";

// ================ Reducer ================ //
const initialInvoicesMeta = {
  page: 1,
  page_size: 100,
  total_items: 0,
};

const initialState = {
  invoicesLoadError: null,
  invoicesLoadInProgress: false,
  invoicesList: [],
  invoicesMeta: initialInvoicesMeta,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case BUYER_INVOICES_LOAD:
      return {
        ...state,
        invoicesLoadInProgress: true,
        invoicesList: null,
        invoicesMeta: initialInvoicesMeta,
        invoicesLoadError: null
      };
    case BUYER_INVOICES_SUCCESS:
      const { invoices, ...rest } = payload;
      return { ...state, invoicesLoadInProgress: false, invoicesList: [...invoices], invoicesMeta: { ...rest } };
    case BUYER_INVOICES_ERROR:
      return { ...state, invoicesLoadInProgress: false, invoicesLoadError: payload };
    default:
      return state;
  }
}

// ================ Action creators ================ //
export const invoicesRequestLoad = () => ({ type: BUYER_INVOICES_LOAD });
export const invoicesRequestLoadSuccess = (data) => ({ type: BUYER_INVOICES_SUCCESS, payload: data });
export const invoicesRequestLoadError = (error) => ({ type: BUYER_INVOICES_ERROR, payload: error, error: true });

// ================ Thunks ================ //
export const LoadBuyerInvoices = (params) => (dispatch, getState, sdk) => {
  const { currentPage } = params;

  dispatch(invoicesRequestLoad());
  const customer_external_uid = getState()?.buyerCompany.buyerCurrentCompany.id.uuid;

  return getAllInvoices({ customer_external_uid, currentPage })
    .then(async (res) => {
      const invoices = await Promise.all(res.invoices.map(async (invoice) => {
        const { supplier_external_uid: id } = invoice;

        const supplier = await sdk.listings.show({ id: new UUID(id) })
          .then(({ data: { data: { attributes: { publicData: { companyName, brandName } } } } }) => ({
            id,
            companyName,
            brandName,
          }))
          .catch((e) => console.error(e));

        return ({ ...invoice, supplier });
      }));

      dispatch(invoicesRequestLoadSuccess({ ...res, invoices }));
    })
    .catch((err) => dispatch(invoicesRequestLoadError(err)));
};
