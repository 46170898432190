import React, { Component } from 'react';
import { bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { propTypes } from '../../util/types';

import css from './ImprintPage.module.css';
import faircommerceLogo from "../../assets/faircommerceLogo.png";

const linkToFairCommerce = <a href="http://ec.europa.eu/odr" target="_blank">www.haendlerbund.de/faircommerce</a>;
const linkToEC = <a href="http://ec.europa.eu/odr" target="_blank">http://ec.europa.eu/odr</a>;
const email = <a href="mailto:hello@vegshelf.com" target="_blank">hello@vegshelf.com</a>;


export class ImprintPageComponent extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      history,
      intl
    } = this.props;
    return (
      <div className={css.vegshelfInformationContainer}>
        <div className={css.vegshelfInformationBox1}>
          <h1 className={css.mainTitle}>
            <FormattedMessage id="ImprintPage.Imprint" />
          </h1>
          <p className={css.bodyText}>
            <b><FormattedMessage id="ImprintPage.imprintTextLawNr" /></b><br />
            <FormattedMessage id="ImprintPage.imprintAddressLine1" /><br />
            <FormattedMessage id="ImprintPage.imprintAddressLine2" /><br />
            <FormattedMessage id="ImprintPage.imprintAddressLine3" /><br />
            <FormattedMessage id="ImprintPage.imprintAddressLine4" /><br />
            <FormattedMessage id="ImprintPage.imprintContactDetails1" values={{ email }} /><br />
            <FormattedMessage id="ImprintPage.imprintContactDetails2" /><br />
            <br />
            <b><FormattedMessage id="ImprintPage.managingInfo1" /></b><br />
            <FormattedMessage id="ImprintPage.managingInfo2" /><br />
            <FormattedMessage id="ImprintPage.managingInfo3" /><br />
            <FormattedMessage id="ImprintPage.registerInfo1" /><br />
            <br />
            <b><FormattedMessage id="ImprintPage.bankDetails1" /></b><br />
            <FormattedMessage id="ImprintPage.bankDetails2" /><br />
            <FormattedMessage id="ImprintPage.bankDetails3" /><br />
            <FormattedMessage id="ImprintPage.bankDetails4" /><br />
            <br />
            <b><FormattedMessage id="ImprintPage.fairCommerce" /></b><br />
            <FormattedMessage id="ImprintPage.fairCommerceText" values={{ linkToFairCommerce }} /><br />
            <div className={css.fairCommerceLogo}>
              <a href="https://www.haendlerbund.de/faircommerce" target="_blank">
                <img src={faircommerceLogo} /*src="https://www.haendlerbund.de/de/images/faircommerce-logo.png"*/
                  alt="We are participants in the FairCommerce initiative" />
              </a>
            </div>
            <br />
            <b><FormattedMessage id="ImprintPage.onlineDisputeResolution1" /></b><br />
            <FormattedMessage id="ImprintPage.onlineDisputeResolution2" />
            <br />
            <br />
            <b><FormattedMessage id="ImprintPage.onlineDisputeResolution3" /></b><br />
            <FormattedMessage id="ImprintPage.onlineDisputeResolution4" values={{ linkToEC }} />
          </p>
        </div>
      </div>
    );
  }
};

ImprintPageComponent.defaultProps = {
  currentUserListing: null,
  currentUserListingFetched: false,
};

ImprintPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};
const mapStateToProps = state => {
  const { currentUser } = state.user;
  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser
  };
};

const ImprintPage = compose(
  withRouter,
  connect(mapStateToProps),
  injectIntl
)(ImprintPageComponent);

export default ImprintPage;