import { normalizeShoppingCart } from "../../ducks/shopping-cart.duck";
import { TransitionStates } from "../../ducks/transition.duck";
import { storableError } from "../../util/errors";
import { ORDERS_PER_PAGE } from "../SupplierOrdersAndRequestsPage/SupplierOrdersAndRequestsPage.duck";

// ================ Action types ================ //
export const BUYER_ORDER_REQUESTS_LOAD = "app/Buyer/orders/requests";
export const BUYER_ORDER_REQUESTS_SUCCESS = "app/Buyer/orders/requests/success";
export const BUYER_ORDER_REQUESTS_ERROR = "app/Buyer/orders/requests/error";
export const BUYER_ORDERS_LOAD = "app/Buyer/orders";
export const BUYER_ORDERS_RESET = "app/Buyer/orders/reset";
export const BUYER_ORDERS_SUCCESS = "app/Buyer/orders/success";
export const BUYER_ORDERS_ERROR = "app/Buyer/orders/error";

// ================ Reducer ================ //
const initialState = {
  orderRequestsLoadError: null,
  orderRequestsLoadInProgress: false,
  orderRequests: null,
  ordersLoadError: null,
  ordersLoadInProgress: false,
  orders: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case BUYER_ORDER_REQUESTS_LOAD:
      return { ...state, orderRequestsLoadError: null, orderRequestsLoadInProgress: true, orderRequests: null };
    case BUYER_ORDER_REQUESTS_SUCCESS:
      return { ...state, orderRequestsLoadInProgress: false, orderRequests: payload };
    case BUYER_ORDER_REQUESTS_ERROR:
      return { ...state, orderRequestsLoadInProgress: false, orderRequestsLoadError: payload };
    case BUYER_ORDERS_RESET:
      return { ...state, ordersLoadError: null, ordersLoadInProgress: false, orders: null };
    case BUYER_ORDERS_LOAD:
      return { ...state, ordersLoadError: null, ordersLoadInProgress: true };
    case BUYER_ORDERS_SUCCESS:
      const { carts: newCarts, meta } = payload;
      const oldCarts = state.orders?.carts ?? [];
      const carts = mergeCarts({ oldCarts, newCarts });

      return {
        ...state,
        ordersLoadInProgress: false,
        orders: { meta, carts },
      };
    case BUYER_ORDERS_ERROR:
      return { ...state, ordersLoadInProgress: false, ordersLoadError: payload };
    default:
      return state;
  }
}

// ================ Action creators ================ //
export const buyerRequestsLoad = () => ({ type: BUYER_ORDER_REQUESTS_LOAD });
export const buyerRequestsLoadSuccess = (data) => ({ type: BUYER_ORDER_REQUESTS_SUCCESS, payload: data });
export const buyerRequestsLoadError = error => ({ type: BUYER_ORDER_REQUESTS_ERROR, payload: error, error: true });

export const buyerOrdersLoad = () => ({ type: BUYER_ORDERS_LOAD });
export const buyerOrdersReset = () => ({ type: BUYER_ORDERS_RESET });
export const buyerOrdersLoadSuccess = (data) => ({ type: BUYER_ORDERS_SUCCESS, payload: data });
export const buyerOrdersLoadError = error => ({ type: BUYER_ORDERS_ERROR, payload: error, error: true });

// ================ Thunks ================ //
export const LoadRequests = () => (dispatch, getState, sdk) => {
  dispatch(buyerRequestsLoad());

  return sdk.transactions
    .query({
      only: "order",
      lastTransitions: ["transition/request-order"],
    })
    .then((res) => {
      dispatch(buyerRequestsLoadSuccess(res.data));
    })
    .catch((e) => {
      dispatch(buyerRequestsLoadError(storableError(storableError(e))));
      // This is thrown so that form can be cleared
      // after a timeout on changePassword submit handler
      throw e;
    });
};

export const LoadOrders = (params) => (dispatch, getState, sdk) => {
  dispatch(buyerOrdersLoad());

  return sdk.transactions.query({
    perPage: ORDERS_PER_PAGE,
    page: params?.pageIndex || 1,
    only: "order",
    lastTransitions: [
      "transition/request-order",
      "transition/reject",
      "transition/expire-confirm",
      "transition/confirm-order",
      "transition/shipped-order",
      "transition/delivered-order",
      "transition/paid-order",
    ],
  }).then((res) => {
      const keys = {};
      const carts = [];
      const meta = res?.data?.meta;

      if (res?.data?.data?.length) {
        res.data.data.forEach((t) => {
          const initialPaymentData = Object.assign({}, t?.attributes?.protectedData?.cart?.paymentData);
          let cart = t?.attributes?.protectedData?.cart;
          const brandId = t?.attributes?.protectedData?.brandInfo?.brandId;
          const correlationId = cart.uniqueBuyerOrderId;

          if (cart && correlationId) {
            const brand = cart.brands.find((b) => b.brandId === brandId);
            brand.transaction = t;
            brand.transactionState = TransitionStates.parseBuyerState(t.attributes.lastTransition);
            brand.cancelled = brand.transactionState === "CANCELLED";

            if (!keys[correlationId]) {
              cart = normalizeShoppingCart(cart).shoppingData;
              keys[correlationId] = cart;
              cart.transactions = [t];
              cart.paymentData = { ...initialPaymentData };
              carts.push({ ...cart, brands: [brand], validBrands: [brand], correlationId });
            } else {
              keys[correlationId].transactions.push(t);
              carts.forEach((c) => {
                if (c.correlationId === correlationId) {
                  c.brands = [...c.brands, brand];
                  c.validBrands = [...c.validBrands, brand];
                }
              });
            }
          }
        });
      }

      dispatch(buyerOrdersLoadSuccess({ carts, meta }));
    })
    .catch((e) => {
      dispatch(buyerOrdersLoadError(storableError(e)));
      throw e;
    });
};

function mergeCarts({ oldCarts, newCarts }) {
  return newCarts.reduce((acc, curr) => {
    const existedIndex = acc.findIndex((el) => el.correlationId === curr.correlationId);

    if (existedIndex > -1) {
      acc[existedIndex] = {
        ...acc[existedIndex],
        transactions: [
          ...acc[existedIndex].transactions,
          ...curr.transactions,
        ],
        brands: [
          ...acc[existedIndex].brands,
          ...curr.brands,
        ],
        validBrands: [
          ...acc[existedIndex].validBrands,
          ...curr.validBrands,
        ],
      };

      return acc;
    }

    acc.push(curr);
    return acc;
  }, oldCarts);
}
