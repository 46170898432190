import { bool, object } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { TransactionViewItem } from "../../components";
import LoaderComponent from "../../components/Loader/Loader";
import EmptyStateComponent from "../../components/ProductEmptyState/ProductEmptyState";
import { transition, TransitionStates } from "../../ducks/transition.duck";
import { isScrollingDisabled } from "../../ducks/UI.duck";
import { injectIntl, intlShape } from "../../util/reactIntl";
import { propTypes } from "../../util/types";
import { LoadRequests } from "../SupplierOrdersAndRequestsPage/SupplierOrdersAndRequestsPage.duck";
import css from "./SupplierOrderRequestsPage.module.css";

export class SupplierOrderRequestsPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { openedTransactionIndex: -1 };
    this.newSupplier = null;
    this.onShowDetailsClick = this.onShowDetailsClick.bind(this);
    this.onTransitionClick = this.onTransitionClick.bind(this);
    this.onResetExpandedOrder = this.onResetExpandedOrder.bind(this);
  }

  async onTransitionClick(transaction, newTransition) {
    const transitionItem = {
      id: transaction.id.uuid,
      transitionName: newTransition,
      customerId: transaction?.attributes?.protectedData?.companyId,
      brand: transaction?.attributes?.protectedData?.brandInfo,
      newStatus: TransitionStates.parseState(newTransition),
    };
    await this.props.onTransition(transitionItem);
  }

  onShowDetailsClick(itemIndex) {
    let newIndex = itemIndex;
    if (newIndex === this.state.openedTransactionIndex) {
      newIndex = -1;
    }
    this.setState({ ...this.state, openedTransactionIndex: newIndex });
  }

  onResetExpandedOrder() {
    this.setState((prev) => ({ ...prev, openedTransactionIndex: -1 }));
  }

  render() {
    const {
      // orderRequestsLoadError,
      orderRequestsLoadInProgress,
      orderRequests,
      supplierCurrentCompany,
      transitionError,
      transitionInProgress,
    } = this.props;

    const requests = [...(orderRequests?.data || [])];
    const hasAnyData = requests.length > 0;

    const emptyStateOptions = {
      containerOffsetClassName: "orderAndRequestOffset",
      iconStylesClassName: "orderAndRequestIcon",
      titleMessageId: "SupplierOrderRequestsPage.noSearchResultTitle",
      textMessageId: "SupplierOrderRequestsPage.noSearchResultText",
    };

    return (
      <div className={css.pageWrapper}>
        {!hasAnyData ?
          (
            <div className={css.emptyDataContainer}>
              {orderRequestsLoadInProgress ?
                <LoaderComponent />
                :
                <EmptyStateComponent options={emptyStateOptions} />
              }
            </div>
          ) : (
            <>
              {/*<h1 className={css.pageHeader}>Order requests</h1>*/}
              {supplierCurrentCompany && requests.map((r, idx) => {
                const onShowDetailsClick = () => this.onShowDetailsClick(idx);
                const {
                  companyName,
                  companyVATnumber,
                  companyRegistrationNumber,
                  streetAndNumber,
                  city,
                  postcode,
                  companyRCSnumber,
                  companyShareCapital,
                  country,
                } = supplierCurrentCompany?.attributes?.publicData;

                return (
                  <TransactionViewItem
                    key={r.id.uuid}
                    transactionItem={{
                      ...r,
                      supplierInvoiceData : {
                        companyId: supplierCurrentCompany?.id.uuid,
                        companyName,
                        address: streetAndNumber,
                        pin: companyRegistrationNumber,
                        tin: companyVATnumber,
                        city,
                        postcode,
                        companyRCSnumber,
                        companyShareCapital,
                        country,
                      },
                    }}
                    showDetails={this.state.openedTransactionIndex === idx}
                    canChangeStatus={true}
                    onShowDetailsClick={onShowDetailsClick}
                    onTransitionClick={this.onTransitionClick}
                    onResetExpandedOrderClick={this.onResetExpandedOrder}
                    transitionError={transitionError}
                    transitionInProgress={transitionInProgress}
                  />
                );
              })}
            </>
          )}
      </div>
    );
  }
}

SupplierOrderRequestsPageComponent.defaultProps = {
  currentUserListing: null,
  currentUserListingFetched: false,
};

SupplierOrderRequestsPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
  // from withRouter
  history: object.isRequired,
  location: object.isRequired,
  // from injectIntl
  intl: intlShape.isRequired,
};
const mapStateToProps = (state) => {
  //const { supplierRegistrationError, supplierRegistrationInProgress, supplierRegistered } = state.SupplierOrderRequestsPage;
  const { supplierCurrentCompany } = state.supplierCompany;
  const { transitionError, transitionInProgress } = state.Transition;


  return {
    scrollingDisabled: isScrollingDisabled(state),
    supplierCurrentCompany,
    transitionError,
    transitionInProgress,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onTransition: (values) => dispatch(transition(values)),
});

const SupplierOrderRequestsPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
)(SupplierOrderRequestsPageComponent);

SupplierOrderRequestsPage.loadData = () => (dispatch /*getState*/) => {
  return dispatch(LoadRequests());
};

export default SupplierOrderRequestsPage;
