import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { injectIntl } from "../../util/reactIntl";
import facebookImage from "../../assets/VegshelfFacebook-1200x630.png";
import config from "../../config";
import twitterImage from "../../assets/VegshelfTwitter-600x314.png";
import { Footer, LayoutWrapperFooter, LayoutWrapperMain, LayoutWrapperTopbar, Page, TabNav } from "../../components";
import { TopbarContainer } from "../index";
import { isScrollingDisabled } from "../../ducks/UI.duck";
import css from "./SuppliernvoicingPage.module.css";
import { string } from "prop-types";
import {
  SUPPLIER_INVOICING_TAB_LINK_NAMES,
  SUPPLIER_INVOICING_TAB_MESSAGES,
  SUPPLIER_INVOICING_TABS,
  // Importing additional object for subscription based model pilot
  SUPPLIER_INVOICING_TAB
} from "./variables";
import SupplierInvoicesPage from "../SupplierInvoicesPage/SupplierInvoicesPage";
import { supplierLoadCompany } from "../../ducks/supplier-company.duck";
import { localePart, storedLocale } from '../../components/Wrapper/ClassWrapper';

class SupplierInvoicingPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { loaded: false };
  }

  render() {
    const {
      intl,
      scrollingDisabled,
      tab = SUPPLIER_INVOICING_TABS.order,
      invoicesList,
      invoicesMeta,
      invoicesLoadInProgress,
      // Additional prop accessing for checking if Supplier is part of subscription based model pilot
      supplierCurrentCompany
    } = this.props;

    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage({ id: 'SupplierInvoicingPage.schemaTitle' }, { siteTitle });
    const schemaDescription = intl.formatMessage({ id: 'SupplierInvoicingPage.schemaDescription' }, { siteTitle });
    const schemaImage = `${config.canonicalRootURL}${facebookImage}`;
    //const locale = sessionStorage.getItem('locale') || localePart();
    const locale = storedLocale() || localePart();

    // Check if Supplier is part of subscription based model pilot
    const isPilotUser = supplierCurrentCompany?.attributes?.publicData?.pilotUser;

    const pages = {
      [SUPPLIER_INVOICING_TABS.order]: (
        <SupplierInvoicesPage
          tab={tab}
          invoicesList={invoicesList}
          invoicesMeta={invoicesMeta}
          invoicesLoadInProgress={invoicesLoadInProgress}
        />
      ),
      [SUPPLIER_INVOICING_TABS.commission]: (
        <SupplierInvoicesPage
          tab={tab}
          invoicesList={invoicesList}
          invoicesMeta={invoicesMeta}
          invoicesLoadInProgress={invoicesLoadInProgress}
        />
      ),
    };

    // Only order inovice page to be displayed for subscription based model pilot users
    const pilotPages = {
      [SUPPLIER_INVOICING_TAB.order]: (
        <SupplierInvoicesPage
          tab={tab}
          invoicesList={invoicesList}
          invoicesMeta={invoicesMeta}
          invoicesLoadInProgress={invoicesLoadInProgress}
        />
      ),
    };

    const tabs = Object
      .keys(SUPPLIER_INVOICING_TABS)
      .map((key) => {
        const tabName = intl.formatMessage({ id: SUPPLIER_INVOICING_TAB_MESSAGES[key] });

        return ({
          id: key,
          text: <span>{tabName}</span>,
          selected: tab === SUPPLIER_INVOICING_TABS[key],
          linkProps: {
            name: SUPPLIER_INVOICING_TAB_LINK_NAMES[key],
            params: {
              tab: key,
              locale: locale
            },
          },
        });
      });

      // Only order inovice tab to be displayed for subscription based model pilot users
      const singleTab = Object
      .keys(SUPPLIER_INVOICING_TAB)
      .map((key) => {
        const tabName = intl.formatMessage({ id: SUPPLIER_INVOICING_TAB_MESSAGES[key] });

        return ({
          id: key,
          text: <span>{tabName}</span>,
          selected: tab === SUPPLIER_INVOICING_TAB[key],
          linkProps: {
            name: SUPPLIER_INVOICING_TAB_LINK_NAMES[key],
            params: {
              tab: key,
            },
          },
        });
      });

    return (
      <Page
        className={css.root}
        scrollingDisabled={scrollingDisabled}
        contentType="website"
        description={schemaDescription}
        title={schemaTitle}
        robots='noindex'
        facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
        twitterImages={[
          { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
        ]}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'WebPage',
          description: schemaDescription,
          name: schemaTitle,
          image: [schemaImage],
        }}
      >
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div className={css.pageContainer}>
            <div className={css.invoiceSection}>
              <TabNav rootClassName={css.tabs} tabRootClassName={css.tab} tabs={isPilotUser ? singleTab : tabs} />
              <div className={css.pageContent}>
                {/* Original tab and page function removed for subscription based model pilot */}
                {/*pages[tab]*/}
                {/* Check to render page for subscription based model pilot users or other users */}
                {isPilotUser ? pilotPages[tab] : pages[tab]}
              </div>
            </div>
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </Page>
    );
  }
}

SupplierInvoicingPageComponent.defaultProps = {
  tab: SUPPLIER_INVOICING_TABS.order,
};

SupplierInvoicingPageComponent.propTypes = {
  tab: string,
};

const mapStateToProps = (state) => {
  const { invoicesList, invoicesMeta, invoicesLoadInProgress } = state.SupplierInvoicesPage;
  const { supplierCurrentCompany } = state.supplierCompany;

  return {
    scrollingDisabled: isScrollingDisabled(state),
    invoicesList,
    invoicesMeta,
    invoicesLoadInProgress,
    supplierCurrentCompany,
  };
};

const mapDispatchToProps = () => ({});

const SupplierInvoicingPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(SupplierInvoicingPageComponent);

SupplierInvoicingPage.loadData = (params) => (dispatch) => {
  return Promise.all([
    dispatch(supplierLoadCompany({ ...params })),
  ]);
};

export default SupplierInvoicingPage;
