import classNames from "classnames";
import { object } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { addOrRemoveFavourites } from "../../ducks/favourite.duck";
import { displayRegistrationConfirmDialog } from "../../ducks/Modal.duck";
import { parseListingState, ProductStatesTranslates } from "../../ducks/supplier-company.duck";
import { FormattedMessage, injectIntl } from "../../util/reactIntl";
import NamedLink from "../NamedLink/NamedLink";
import { slugify } from "../../util/urlTrimming";
import { localePart, storedLocale } from "../Wrapper/ClassWrapper";
import { pathByRouteName } from "../../util/routes";
import routeConfiguration from "../../routeConfiguration";
import css from "./ProductViewItem.module.css";

//locale: sessionStorage.getItem('locale') || localePart();
const locale = storedLocale() || localePart();

export class ProductViewItemComponent extends Component {
  constructor(props) {
    super(props);
    this.onFavouriteButtonClick = this.onFavouriteButtonClick.bind(this);
  }

  onFavouriteButtonClick() {
    this.props.onAddOrRemoveFavourite(this.props.productItem);
  }

  render() {
    const {
      productItem,
      isFirst,
      showState,
      showEditButton,
      favouriteProductIds,
      currentUserType,
      isAuthenticated,
      onShowRegistrationConfirmDialog,
      onAddClick,
    } = this.props;

    if (productItem?.newLine) {
      return (<div className={css.newLine}>&nbsp;</div>);
    }

    let productState = parseListingState(productItem);
    const isOutOfStock = productState === "outOfStock";
    const isDraft = productState === "draft";
    const nodeCss = classNames(
      css.productItem,
      { [css.isFirstProduct]: isFirst, [css.newButton]: productItem.newButton, [css.isOutOfStock]: isOutOfStock },
    );

    if (productItem.newButton) {
      return (
        <div key={productItem.id.uuid} className={nodeCss}>
          <a className={css.addProductButton} onClick={() => onAddClick()}>
            <div className={css.addProductButtonIcon}>add</div>
            <FormattedMessage className={css.addProductButtonLabel} id="SupplierProfilePage.addProductButtonLabel" />
          </a>
        </div>
      );
    }

    const style = {};
    const images = productItem?.attributes?.publicData?.productImages;
    if (images && images.length > 0) {
      style.backgroundImage = `url('${images[0].url}')`;
    }

    const price = parseFloat(productItem?.attributes?.publicData?.wholesalePrice);
    const displayPrice = (Math.round(price * 100) / 100).toFixed(2);
    const msrp = parseFloat(productItem?.attributes?.publicData?.suggestedPrice);
    const displayMsrp = (Math.round(msrp * 100) / 100).toFixed(2);
    const isBackSoonState = isOutOfStock && !showState;
    if (isBackSoonState) {
      productState = "backSoon";
    }

    const stateCss = classNames(css.productState,
      { [css.hideState]: !showState && !isBackSoonState, [css[productState]]: true });
    const editButtonCss = classNames(css.editButtonCss, { [css.hideState]: !showEditButton });
    const detailsPage = showEditButton
      ? (isDraft ? "SupplierEditProductPage" : "ProductSupplierDetailsPage") : "ProductDetailsPage";
    const isFavourite = favouriteProductIds ? favouriteProductIds.indexOf(productItem?.id?.uuid) > -1 : false;
    const favouriteButtonCss = classNames(css.favouriteButtonCss, { [css.hideState]: currentUserType !== "buyer" });
    const favouriteIconButtonCss = classNames(
      css.favouriteProductButtonIcon,
      { [css.favouriteProductDisabledButtonIcon]: !isFavourite, [css.favouriteProductEnabledButtonIcon]: isFavourite },
    );
    const supplierProfilePageName = 'SupplierProfilePage';
    const localeParams = {locale};
    const supplierProfilePagePath = pathByRouteName(supplierProfilePageName, routeConfiguration(), localeParams);

    return (
      <div key={productItem?.id?.uuid} className={nodeCss}>
        {/*isAuthenticated
          ? (*/
            <NamedLink name={detailsPage} params={{ product: slugify(productItem?.attributes?.title), id: productItem?.id?.uuid, locale: locale }}>
              <div className={css.outOfStockMask}>&nbsp;</div>
              <div className={css.productImage} style={style}>
                <div className={classNames({ [css.emptyImage]: true, [css.hasProductMainPhoto]: (images && images.length > 0) })}></div>
              </div>
            </NamedLink>
          /*) : (
            <a onClick={onShowRegistrationConfirmDialog}>
              <div className={css.outOfStockMask}>&nbsp;</div>
              <div className={css.productImage} style={style}>
                <div className={classNames({ [css.emptyImage]: true, [css.hasProductMainPhoto]: (images && images.length > 0) })}></div>
              </div>
            </a>
          )*/}
        <div className={css.productItemInfo}>
          <h2 className={css.productName}>
            {/*isAuthenticated ? 
            (*/
              <NamedLink name={detailsPage} params={{ product: slugify(productItem?.attributes?.title), id: productItem?.id?.uuid, locale: locale }}>
              {productItem?.attributes?.title}, {productItem?.attributes?.publicData?.weight}&nbsp;{(productItem?.attributes?.publicData?.weightUnitCustom || productItem?.attributes?.publicData?.weightUnit)}
            </NamedLink>
            /*) : 
            (<a name={detailsPage} onClick={onShowRegistrationConfirmDialog}>
              {productItem?.attributes?.title}
            </a>)*/}
          </h2>
          <p className={css.productPrice}>
            {isAuthenticated ? (
              <>
                €&nbsp;{displayPrice} &nbsp;
              </>
            ) : (
              <a className={css.registrationButton} onClick={onShowRegistrationConfirmDialog}>
                <FormattedMessage id="SearchProductsPage.wholesalePrice" />
              </a>
            )} 
            <span className={css.msrpLabel}>
            •&nbsp;&nbsp;<FormattedMessage id="SearchProductsPage.MSRPLabel" /> €&nbsp;{displayMsrp}
            </span>
          </p>
          <p className={css.brandName}>
            {productItem?.attributes?.publicData?.brandId ? (
              <NamedLink
                name="SupplierDetailsPage"
                params={{ brand: slugify(productItem?.attributes?.publicData?.brandName), id: productItem?.attributes?.publicData?.brandId, locale: locale }}
                onClick={(e) => {
                  if (this.props.currentUserListing?.attributes.publicData.brandId === productItem?.attributes?.publicData?.brandId) {
                    e.preventDefault();
                    //this.props.history.push("/supplier-profile-page");
                    this.props.history.push(supplierProfilePagePath);
                  }
                }}
              >
                {productItem?.attributes?.publicData?.brandName}
              </NamedLink>
            ) : null}
          </p>
        </div>
        <div className={stateCss}>
          {productItem?.attributes?.state ? ProductStatesTranslates[productState] : null}
        </div>
        <NamedLink className={editButtonCss} name="SupplierEditProductPage" params={{ id: productItem.id.uuid, locale: locale }}>
          <div className={css.editProductButtonIcon}>add</div>
        </NamedLink>
        <a onClick={this.onFavouriteButtonClick} className={favouriteButtonCss}>
          <div className={favouriteIconButtonCss}></div>
        </a>
      </div>
    );
  }
}

ProductViewItemComponent.propTypes = {
  productItem: object.isRequired,
};

const mapStateToProps = (state) => {
  const { currentUserListing } = state.user;

  return {
    favouriteProductIds: state.Favourites.productIds,
    currentUserType: state.user.currentUserType,
    isAuthenticated: state.Auth.isAuthenticated,
    currentUserListing,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onAddOrRemoveFavourite: (product) => dispatch(addOrRemoveFavourites({ product })),
  onShowRegistrationConfirmDialog: () => dispatch(displayRegistrationConfirmDialog(true, true)),
});

const ProductViewItem = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
)(ProductViewItemComponent);

export default ProductViewItem;