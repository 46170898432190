import React, { Component } from 'react';
import { bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { propTypes } from '../../util/types';

import { uploadImage } from './SupplierProfileInvoicingPage.duck';
import BuyerBillingDetailsForm from "../../forms/BuyerBillingDetailsForm/BuyerBillingDetailsForm";
import { SUPPLIER_TAB_MESSAGES } from "../SupplierSettingsPage/variables";
import { supplierUpdateCompany } from "../../ducks/supplier-company.duck";
import LoaderComponent from "../../components/Loader/Loader";

export class SupplierProfileInvoicingPageComponent extends Component {
  constructor(props) {
    super(props);
    this.submitBillingDetails = this.submitBillingDetails.bind(this);
    this.state = { firstLoad: false };
  }

  async submitBillingDetails(params) {
    const { companyVAT: companyVATnumber, companyLegalName: companyName, ...rest } = params;
    const newBillingDetails = { ...rest, companyVATnumber, companyName };
    this.setState((prev) => ({ ...prev, firstLoad: true }));

    return await this.props.onSupplierUpdateCompany(newBillingDetails);
  }

  render() {
    const {
      // history,
      // location,
      // scrollingDisabled,
      // currentUser,
      // image,
      // uploadImageError,
      // uploadInProgress,
      intl,
      currentCompany,
      currentCompanyUpdateInProgress,
    } = this.props;

    const billingDetails = {
      companyLegalName: currentCompany?.attributes?.publicData?.companyName,
      companyRegistrationNumber: currentCompany?.attributes?.publicData?.companyRegistrationNumber,
      companyVATnumber: currentCompany?.attributes?.publicData?.companyVATnumber,
      streetAndNumber: currentCompany?.attributes?.publicData?.streetAndNumber,
      city: currentCompany?.attributes?.publicData?.city,
      postcode: currentCompany?.attributes?.publicData?.postcode,
      country: currentCompany?.attributes?.publicData?.country,
      companyRCSnumber: currentCompany?.attributes?.publicData?.companyRCSnumber,
      companyShareCapital: currentCompany?.attributes?.publicData?.companyShareCapital,
    };

    if (!this.state.firstLoad && (currentCompanyUpdateInProgress || !currentCompany /*|| !stripeCustomerFetched*/)) {
      return <LoaderComponent/>;
    }

    if (!currentCompany) {
      return null;
    }

    return (
      <BuyerBillingDetailsForm
        intl={intl}
        billingDetails={billingDetails}
        onSubmit={this.submitBillingDetails}
        billingDetailsUpdateInProgress={currentCompanyUpdateInProgress}
      />
    );
  }
}

SupplierProfileInvoicingPageComponent.defaultProps = {
  currentUserListing: null,
  currentUserListingFetched: false,
};

SupplierProfileInvoicingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
  // from withRouter
  history: object.isRequired,
  location: object.isRequired,
  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = (state) => {
  const { currentUser } = state.user;
  const {
    supplierCurrentCompany: currentCompany,
    supplierUpdateCompanyInProgress: currentCompanyUpdateInProgress,
  } = state.supplierCompany;

  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
    currentCompany,
    currentCompanyUpdateInProgress,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onImageUpload: (data) => dispatch(uploadImage(data)),
  onSupplierUpdateCompany: (data) => dispatch(supplierUpdateCompany(data)),
});

const SupplierProfileInvoicingPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(SupplierProfileInvoicingPageComponent);

export default SupplierProfileInvoicingPage;
