import { localePart, storedLocale } from "../components/Wrapper/ClassWrapper";

const language = storedLocale() || localePart();

const annualSales = {
  en: [
    { code: 1, name: "< 100 000 €" },
    { code: 2, name: "100 000 – 250 000 €" },
    { code: 3, name: "250 000 – 500 000 €" },
    { code: 4, name: "500 000 – 1 000 000 €" },
    { code: 5, name: "1 000 000 – 5 000 000 €" },
    { code: 6, name: "> 5 000 000 €" },
    { code: 7, name: "N/A" }
  ],
  de: [
    { code: 1, name: "< 100 000 €" },
    { code: 2, name: "100 000 – 250 000 €" },
    { code: 3, name: "250 000 – 500 000 €" },
    { code: 4, name: "500 000 – 1 000 000 €" },
    { code: 5, name: "1 000 000 – 5 000 000 €" },
    { code: 6, name: "> 5 000 000 €" },
    { code: 7, name: "K/A" }
  ]
};

const getAnnualSales = lang => {
  // Add the lnew locale here so that the correct translations will be returned.
  // If locale is unknown or the translation is missing, this will default to english coutnry name.
  const revenue = annualSales[language] || annualSales.en;
  const codes = revenue.map(c => {

    return { code: c.code, name: c.name };
  });
  return codes;
};

export default getAnnualSales;














