const SUPPLIER_TABS = {
  "account" : "account",
  "payout-details" : "payout-details",
  "invoicing" : "invoicing",
  "notifications" : "notifications",
};

Object.freeze(SUPPLIER_TABS);

const SUPPLIER_TAB_MESSAGES = {
  [SUPPLIER_TABS["account"]] : "SupplierSettingsPage.settingsTabAccount",
  [SUPPLIER_TABS["payout-details"]] : "SupplierSettingsPage.settingsTabPayoutDetails",
  [SUPPLIER_TABS["invoicing"]] : "SupplierSettingsPage.settingsTabInvoicing",
  [SUPPLIER_TABS["notifications"]] : "SupplierSettingsPage.settingsTabNotifications",
};

Object.freeze(SUPPLIER_TAB_MESSAGES);

const SUPPLIER_TAB_LINK_NAMES = {
  [SUPPLIER_TABS["account"]] : "SupplierProfileAccountPage",
  [SUPPLIER_TABS["payout-details"]] : "SupplierProfilePayoutDetailsPage",
  [SUPPLIER_TABS["invoicing"]] : "SupplierProfileInvoicingPage",
  [SUPPLIER_TABS["notifications"]] : "SupplierProfileNotificationsPage",
};

Object.freeze(SUPPLIER_TAB_LINK_NAMES);

export {
  SUPPLIER_TABS,
  SUPPLIER_TAB_MESSAGES,
  SUPPLIER_TAB_LINK_NAMES,
};
