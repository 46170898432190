import React, { Component } from 'react';
import { bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import getProductDictionaries from '../../translations/productDictionaries';
import getCountryCodes from '../../translations/countryCodes';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import config from '../../config';
import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  ProductContainer,
  NamedLink,
  PaginationButtons
} from '../../components';
import { TopbarContainer } from '../../containers';

import facebookImage from '../../assets/VegshelfFacebook-1200x630.png';
import twitterImage from '../../assets/VegshelfTwitter-600x314.png';
import css from './CategorySectionPage.module.css';
import { suppliersLoad, categorySectionSave, categorySectionLoad, categorySectionAdd, categorySectionDelete, categorySectionUpdate } from './CategorySectionPage.duck';
import { formSubscriptionItems } from 'final-form';

export class CategorySectionPageComponent extends Component {
  constructor(props) {
    super(props);
    this.onUpButtonClick = this.onUpButtonClick.bind(this);
    this.onDownButtonClick = this.onDownButtonClick.bind(this);
    this.onDeleteButtonClick = this.onDeleteButtonClick.bind(this);
    this.onNameChange = this.onNameChange.bind(this);
    this.onBrandChange = this.onBrandChange.bind(this);
    this.onColorChange = this.onColorChange.bind(this);
    this.onVisibleChange = this.onVisibleChange.bind(this);
    this.onSaveButtonClick = this.onSaveButtonClick.bind(this);
    this.onAddButtonClick = this.onAddButtonClick.bind(this);
  }
  onUpButtonClick(item) {
    const allItems = [...(this.props.categorySections || [])];
    allItems.sort((f, s) => {
      if (f.order < s.order) return -1;
      if (f.order > s.order) return 1;
      return 0;
    });
    for (let i = 1; i < allItems.length; i++) {
      if (allItems[i] === item) {
        const element = { ...allItems[i - 1] };
        element.order = item.order;
        this.props.onUpdate(element);
        break;
      }
    }
    const updatedItem = { ...item };
    updatedItem.order--;
    this.props.onUpdate(updatedItem);
  }
  onDownButtonClick(item) {
    const allItems = [...(this.props.categorySections || [])];
    allItems.sort((f, s) => {
      if (f.order > s.order) return -1;
      if (f.order < s.order) return 1;
      return 0;
    });
    for (let i = 1; i < allItems.length; i++) {
      if (allItems[i] === item) {
        const element = { ...allItems[i - 1] };
        element.order = item.order;
        this.props.onUpdate(element);
        break;
      }
    }
    const updatedItem = { ...item };
    updatedItem.order++;
    this.props.onUpdate(updatedItem);
  }
  onDeleteButtonClick(item) {
    if (confirm("Delete?")) {
      this.props.onDelete(item);
    }

  }
  onNameChange(item, event) {
    const updatedItem = { ...item };
    updatedItem.title = event.target.value;
    this.props.onUpdate(updatedItem);
  }
  onBrandChange(item, event) {
    const selectedSupplier = this.props.suppliers.filter(x => x.id.uuid === event.target.value)[0];
    const updatedItem = { ...item };
    if (selectedSupplier) {
      updatedItem.authorId = selectedSupplier.relationships.author.data.id.uuid;
      updatedItem.brandId = selectedSupplier.id.uuid;
      updatedItem.brandName = selectedSupplier.attributes.title;
      updatedItem.brandCountry = selectedSupplier.attributes.publicData.country;
      updatedItem.brandCity = selectedSupplier.attributes.publicData.city;
      updatedItem.brandLogo = selectedSupplier.attributes.publicData.logoUrl;
      updatedItem.brandMainImage = selectedSupplier.attributes.publicData.logoUrl;
    } else {
      updatedItem.authorId = null;
      updatedItem.brandId = null;
      updatedItem.brandName = null;
      updatedItem.brandCountry = null;
      updatedItem.brandCity = null;
      updatedItem.brandLogo = null;
      updatedItem.brandMainImage = null;
    }
    this.props.onUpdate(updatedItem);

  }
  onAddButtonClick() {
    const selectedSupplier = this.props.suppliers[0];
    const newItem = {
      authorId: selectedSupplier?.relationships?.author?.data?.id?.uuid,
      brandId: selectedSupplier?.id?.uuid,
      brandName: selectedSupplier?.attributes?.title,
      brandCountry: selectedSupplier?.attributes?.publicData?.country,
      brandCity: selectedSupplier?.attributes?.publicData?.city,
      brandLogo: selectedSupplier?.attributes?.publicData?.logoUrl,
      brandMainImage: selectedSupplier?.attributes?.publicData?.logoUrl,
      color: '#fcfaf7',
      visible: true,
      title: 'New section'
    };
    let lastId = 0;
    let lastOrder = 0;

    const allItems = [...(this.props.categorySections || [])];

    for (let i = 0; i < allItems.length; i++) {
      if (allItems[i].key > lastId) {
        lastId = allItems[i].key;
      }
      if (allItems[i].order > lastOrder) {
        lastOrder = allItems[i].order;
      }
    }
    newItem.order = lastOrder + 1;
    newItem.key = lastId + 1;
    this.props.onAdd(newItem);
  }
  onColorChange(item, event) {
    const updatedItem = { ...item };
    updatedItem.color = event.target.value;
    this.props.onUpdate(updatedItem);

  }
  onVisibleChange(item, event) {
    const updatedItem = { ...item };
    updatedItem.visible = event.target.checked;
    this.props.onUpdate(updatedItem);

  }
  onSaveButtonClick() {
    this.props.onSave();
  }
  render() {
    const {
      intl,
      categorySections,
      categorySectionsInProgress,
      categorySectionsError,
      saveCategorySectionsInProgress,
      saveCategorySectionsError,
      suppliers,
      suppliersInProgress,
      suppliersError,
      hasChanges,
      currentUser,
      scrollingDisabled
    } = this.props;

    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage({ id: "CategorySectionPage.schemaTitle" }, { siteTitle });
    const schemaDescription = intl.formatMessage({ id: "CategorySectionPage.schemaDescription" }, { siteTitle });
    const schemaImage = `${config.canonicalRootURL}${facebookImage}`;
    const viewCategories = [...(categorySections || [])];
    viewCategories.sort((f, s) => {
      if (f.order < s.order) return -1;
      if (f.order > s.order) return 1;
      return 0;
    });
    const viewSuppliers = suppliers || [];

    return (
      <Page
        className={css.root}
        scrollingDisabled={scrollingDisabled}
        contentType="website"
        description={schemaDescription}
        title={schemaTitle}
        robots='noindex'
        facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
        twitterImages={[
          { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
        ]}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'WebPage',
          description: schemaDescription,
          name: schemaTitle,
          image: [schemaImage],
        }}
      >
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div className={css.pageContainer}>
              <div className={css.newBrandsPageContent}>
                <h1 className={css.pageTitle}>Featured new brands</h1>
                <div className={css.newBrandsTableContainer}>
                  <table className={css.newBrandsTable}>
                    <thead>
                      <tr>
                        <th>Category name</th>
                        <th>Brand</th>
                        <th>Order</th>
                        <th>Color</th>
                        <th>Visible</th>
                        <th>Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      {viewCategories.map((c, idx) => {
                        return (
                          <tr key={c.key + 'categoryRow'} style={{ backgroundColor: c.color }}>

                            <td>
                              <div className={css.categoryName}>
                                <input type='text' name={'nameCategory' + c.key} className={css.textInput} defaultValue={c.title} onChange={(e) => this.onNameChange(c, e)} />
                              </div>
                            </td>

                            <td>
                              <div className={css.brandPreview}>
                                <div className={css.logoBrand} style={{ backgroundImage: `url('${c.brandLogo}')` }}>&nbsp;</div>
                                <div className={css.brandInfo}>
                                  <h1 className={css.brandTitle}>
                                    <select name={'selectBrand' + c.key} value={c.brandId} className={css.brandSelect} onChange={(e) => this.onBrandChange(c, e)}>
                                      <option value={null}>-------</option>
                                      {viewSuppliers.map(s => {
                                        return (<option key={s.id.uuid + '_supplier_' + c.key} value={s.id.uuid}>{s.attributes.title}</option>);
                                      })}
                                    </select>
                                  </h1>
                                  <h2 className={css.brandCountry}>{c.brandCountry ? `${c.brandCountry}, ` : null} {c.brandCity}</h2>
                                </div>
                              </div>
                            </td>

                            <td>
                              <div className={css.buttonsUpDown}>
                                <button className={css.buttonStyleUp} disabled={idx === 0} onClick={() => this.onUpButtonClick(c)}>Up</button>
                                <button className={css.buttonStyleDown} disabled={idx === (viewCategories.length - 1)} onClick={() => this.onDownButtonClick(c)}>Down</button>
                              </div>
                            </td>

                            <td>
                              <div className={css.brandColor}>
                                <input type='text' name={'colorCategory' + c.key} className={css.textInputColor} defaultValue={c.color} onChange={(e) => this.onColorChange(c, e)} />
                              </div>
                            </td>

                            <td>
                              <div className={css.tickbox}>
                                <input type='checkbox' name={'visible' + c.key} checked={c.visible} onChange={(e) => this.onVisibleChange(c, e)} />
                              </div>
                            </td>

                            <td>
                              <div className={css.deleteButton}>
                                <button className={css.deleteButtonIcon} onClick={() => this.onDeleteButtonClick(c)}>Delete</button>
                              </div>
                            </td>
                          </tr>);
                      })}
                    </tbody>
                  </table>
                  <div className={css.buttonsContainer}>
                    <button className={css.buttonStyleAdd} onClick={() => this.onAddButtonClick()}>Add</button>
                    <button className={css.buttonStyleSave} disabled={hasChanges !== true} onClick={() => this.onSaveButtonClick()}>Save</button>
                  </div>
                </div>
              </div>
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
};

CategorySectionPageComponent.defaultProps = {
  currentUserListing: null,
  currentUserListingFetched: false,
};

CategorySectionPageComponent.propTypes = {
  // from injectIntl
  intl: intlShape.isRequired,
};
const mapStateToProps = state => {
  return {
    scrollingDisabled: isScrollingDisabled(state),
    ...state.CategorySectionPage,
    currentUser: state.user
  };
};

const mapDispatchToProps = dispatch => ({
  onUpdate: item => dispatch(categorySectionUpdate(item)),
  onDelete: item => dispatch(categorySectionDelete(item)),
  onAdd: item => dispatch(categorySectionAdd(item)),
  onSave: () => dispatch(categorySectionSave())
});

const CategorySectionPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(CategorySectionPageComponent);

CategorySectionPage.loadData = () => (dispatch, getState, sdk) => {
  return Promise.all([dispatch(suppliersLoad()), dispatch(categorySectionLoad())]);
};
export default CategorySectionPage;
