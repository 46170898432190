import { storableError } from '../../util/errors';

import { login, authenticationInProgress, signup, signupWithIdp } from '../../ducks/Auth.duck';
import { buyerCreateCompany } from '../../ducks/buyer-company.duck';
import { getProductCategories } from '../../translations/productDictionaries';

// ================ Action types ================ //

export const PRODUCTS_TOP_REQUEST = 'app/Feature/Product/top/request';
export const PRODUCTS_TOP_SUCCESS = 'app/Feature/Product/top/request/success';
export const PRODUCTS_TOP_ERROR = 'app/Feature/Product/top/request/error';

export const SEARCH_PRODUCTS_REQUEST = 'app/Feature/Product/search/request';
export const SEARCH_PRODUCTS_SUCCESS = 'app/Feature/Product/search/request/success';
export const SEARCH_PRODUCTS_ERROR = 'app/Feature/Product/search/request/error';

// ================ Reducer ================ //

const initialState = {
  topCategoryProductsError: null,
  topCategoryProductsInProgress: false,
  searchProductsError: null,
  searchProductsInProgress: false,
  topCategoryProducts: null,
  searchedProducts: null
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case PRODUCTS_TOP_REQUEST:
      return {
        ...state,
        topCategoryProductsInProgress: true,
        topCategoryProductsError: null,
        topCategoryProducts: null,
      };
    case PRODUCTS_TOP_SUCCESS:
      return { ...state, topCategoryProductsInProgress: false, topCategoryProducts: payload };
    case PRODUCTS_TOP_ERROR:
      return { ...state, topCategoryProductsInProgress: false, topCategoryProductsError: payload };
    case SEARCH_PRODUCTS_REQUEST:
      return {
        ...state,
        searchProductsInProgress: true,
        searchProductsError: null,
        searchedProducts: null,
      };
    case SEARCH_PRODUCTS_SUCCESS:
      return { ...state, searchProductsInProgress: false, searchedProducts: payload };
    case SEARCH_PRODUCTS_ERROR:
      return { ...state, searchProductsInProgress: false, searchProductsError: payload };
    default:
      return state;
  }
}

// ================ Action creators ================ //

export const loadTopProductsRequest = () => ({ type: PRODUCTS_TOP_REQUEST });
export const loadTopProductsSuccess = (products) => ({ type: PRODUCTS_TOP_SUCCESS, payload: products });
export const loadTopProductsError = error => ({
  type: PRODUCTS_TOP_ERROR,
  payload: error,
  error: true,
});

export const searchProductsRequest = () => ({ type: SEARCH_PRODUCTS_REQUEST });
export const searchProductsSuccess = (products) => ({ type: SEARCH_PRODUCTS_SUCCESS, payload: products });
export const searchProductsError = error => ({
  type: SEARCH_PRODUCTS_ERROR,
  payload: error,
  error: true,
});
// ================ Thunks ================ //

export const loadTopCategoryProducts = params => (dispatch, getState, sdk) => {
  dispatch(loadTopProductsRequest());
  const { 
    productCategoryCode } = params;
      const filter = { pub_dataType: 'product', perPage : 5, pub_displayInFeature: 'enable' };
      if(productCategoryCode) {
          filter.pub_rootCategory = productCategoryCode;
      }
      return sdk.listings
      .query(filter)
      .then(response => {
        dispatch(loadTopProductsSuccess(response?.data));
        return response;
      })
      .catch(e => {
        return dispatch(loadTopProductsError(e));
      });
};

export const searchProducts = params => (dispatch, getState, sdk) => {
  dispatch(searchProductsRequest());
  const { 
    productCategoryCode, 
    pageIndex,
    subCategory, 
    property, 
    shipping, 
    location, 
    search,
    temperature,
     price } = params;
    const filter = { 
      pub_dataType: 'product',
      pub_displayInFeature: 'enable',
      perPage : 60,
      page: pageIndex || 1 
      };
    let category;
    let filterCount = 0;
    if(productCategoryCode) {
        filter.pub_rootCategory = productCategoryCode;
    }
    if(subCategory){
      filter.pub_subCategory = subCategory;
      filterCount++;
    }
    if(price){
      filter.price = price;
      filterCount++;
    }
    if(location && location.length > 0){
      filter.pub_location = location.join(',');
      filterCount++;
    }
    if(temperature && temperature.length > 0){
      filter.pub_requiredTemperature = temperature.join(',');
      filterCount++;
    }
    if(shipping && shipping.length > 0){
      filter.pub_leadTime = shipping.join(',');
      filterCount++;
    }
    if(search){
      filter.keywords = search;
    }
    if(property && property.length > 0){
      filter.pub_properties = `has_any:${property.join(',')}`;
      filterCount++;
    }
    return sdk.listings
    .query(filter)
    .then(response => {
      const data = {...response.data, filterCount: filterCount};
      dispatch(searchProductsSuccess(data));
      return response;
    })
    .catch(e => {
      return dispatch(searchProductsError(e));
    });
};