import React from 'react';
const RegistrationRejected = props => {
    const { name, comment } = props;
    return (<div>
       Hey {name},
       <br/>
       <br/>
       Thank you for showing interest in joining Vegshelf wholesale marketplace!  
       <br/>
       We have reviewed your information. Unfortunately, at this time we cannot grant you access to our platform since your company does not fully meet our selection criteria: {comment} 
       <br/>
       Please head to our website's FAQ section to read more on how we select our brands and retailers: www.vegshelf.com 
       <br/>
       <br/>
       Meanwhile, if you have any questions, please do not hesitate to contact us at hello@vegshelf.com
       <br/>
       <br/>
       Best regards,
       <br/>
       The Vegshelf team
    </div>);
};
export default RegistrationRejected;