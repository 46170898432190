import { checkEmailAddress, saveEmailRequest } from '../util/api';
import { storableError } from '../util/errors';

// ================ Action types ================ //
export const MODAL_REGISTRATION_CONFIRM_DIALOG = 'app/modal/registration/confirm/visible';
export const MODAL_REGISTRATION_EMAIL = 'app/modal/registration/email';
export const MODAL_REGISTRATION_VALIDATE_EMAIL_IN_PROGRESS = 'app/modal/registration/email/validate';
export const MODAL_REGISTRATION_EMAIL_VALIDATE_EMAIL_ERROR = 'app/modal/registration/email/error';
//export const MODAL_SINGLE_PRODUCT_CART_DIALOG = 'app/modal/listing/visible';
export const EMAIL_SAVE_REQUEST = 'app/modal/registration/emailSave/request';
export const EMAIL_SAVE_SUCCESS = 'app/modal/registration/emailSave/success';
export const EMAIL_SAVE_ERROR = 'app/modal/registration/emailSave/error';

// ================ Reducer ================ //
const initialState = {
  showRegistrationConfirmDialog: false,
  validedEmail: null,
  registrationModeBuyer: true,
  validateEmailInProgress: false,
  validationEmailHasError: false,
  //showSingleProductCartDialog: false
  emailSaveRequestInProgress: false,
  emailSaveRequestError: null,
  emailRequestSent: false
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case MODAL_REGISTRATION_CONFIRM_DIALOG:
      return {
        ...state,
        validedEmail: payload.state ? null : state.validedEmail,
        validationEmailHasError: false,
        validateEmailInProgress: false,
        showRegistrationConfirmDialog: payload.state,
        registrationModeBuyer: !!payload.isBuyer
      };
    case MODAL_REGISTRATION_EMAIL:
      return {
        ...state,
        validationEmailHasError: false,
        showRegistrationConfirmDialog: payload.validedEmail && state.showRegistrationConfirmDialog ? false : state.showRegistrationConfirmDialog,
        validedEmail: payload.validedEmail,
        validateEmailInProgress: false,
      };
    case MODAL_REGISTRATION_VALIDATE_EMAIL_IN_PROGRESS:
      return {
        ...state,
        validationEmailHasError: false,
        validedEmail: null,
        validateEmailInProgress: true,
      };
    case MODAL_REGISTRATION_EMAIL_VALIDATE_EMAIL_ERROR:
      return {
        ...state,
        validedEmail: null,
        validationEmailHasError: true,
        validateEmailInProgress: false,
      };
    //case MODAL_SINGLE_PRODUCT_CART_DIALOG:
      //return {
        //...state,
        //validedEmail: payload.state ? null : state.validedEmail,
        //validationEmailHasError: false,
        //validateEmailInProgress: false,
        //showSingleProductCartDialog: payload.state,
        //registrationModeBuyer: !!payload.isBuyer
      //};
    case EMAIL_SAVE_REQUEST:
      return {
        ...state,
        emailSaveRequestInProgress: true,
        emailSaveRequestError: null,
        emailRequestSent: false,
      };
    case EMAIL_SAVE_SUCCESS:
      return { ...state, emailSaveRequestInProgress: false, emailRequestSent: true };
    case EMAIL_SAVE_ERROR:
      return { ...state, emailSaveRequestInProgress: false, emailSaveRequestError: payload };
    default:
      return state;
  }
}

// ================ Action creators ================ //
export const displayRegistrationConfirmDialog = (state, isBuyer) => ({ type: MODAL_REGISTRATION_CONFIRM_DIALOG, payload: { state, isBuyer } });
export const validedEmailAddress = (email) => ({ type: MODAL_REGISTRATION_EMAIL, payload: { validedEmail: email } });
export const validedEmailAddressClear = () => ({ type: MODAL_REGISTRATION_EMAIL, payload: { validedEmail: null } });
export const validedEmailAddressInProgress = () => ({ type: MODAL_REGISTRATION_VALIDATE_EMAIL_IN_PROGRESS });
export const validedEmailAddressError = () => ({ type: MODAL_REGISTRATION_EMAIL_VALIDATE_EMAIL_ERROR });
//export const displaySingleProductCartDialog = (state, isBuyer) => ({ type: MODAL_SINGLE_PRODUCT_CART_DIALOG, payload: { state, isBuyer } });
export const emailSaveRequest = () => ({ type: EMAIL_SAVE_REQUEST});
export const emailSaveSuccess = () => ({ type: EMAIL_SAVE_SUCCESS});
export const emailSaveError = (error) => ({ type: EMAIL_SAVE_ERROR, payload: error, error: true });

// 16.07.23 Vegshelf freeze: changed checkUserEmail to add firstName as part of freeze changes

export const checkUserEmail = (email, firstName) => async (dispatch/*, getState, sdk*/) => {

// 16.07.23 Vegshelf freeze: commented out the below code to ensure user e-mail is not checked in order to avoid no Sharetribe API connection error, only save in MongoDB
  
  /*dispatch(validedEmailAddressInProgress());
  try{
    const response = await checkEmailAddress(email);
    if(response.data.data.id.uuid){
      dispatch(validedEmailAddressError());
    }
  } catch {
    dispatch(validedEmailAddress(email));
  };*/
  
  dispatch(emailSaveRequest());

  return saveEmailRequest({ email, firstName })
    .then(() => {
      dispatch(emailSaveSuccess());
    })
    .catch(e => {
      dispatch(emailSaveError(storableError(storableError(e))));
      // This is thrown so that form can be cleared
      // after a timeout on changePassword submit handler
      throw e;
    });
};
