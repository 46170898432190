import classNames from "classnames";
import { array, bool, func, string } from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import css from "../../containers/ProfilePayoutDetailsPage/ProfilePayoutDetailsPage.module.css";
import { Button } from "../index";
import LoaderComponent from "../Loader/Loader";
import CountryShippingFee from "./CountryShippingFee";

export const countriesMocks = [
  /*{
    isShip: false,
    name: "Austria",
    shippingFee: null,
  },*/
  {
    isShip: false,
    name: "Belgium",
    shippingFee: null,
  },
  {
    isShip: false,
    name: "France",
    shippingFee: null,
  },
  {
    isShip: false,
    name: "Germany",
    shippingFee: null,
  },
  {
    isShip: false,
    name: "Netherlands",
    shippingFee: null,
  },
];

export const SHIPPING_FEE_REG_EXP = /^\d+(?:[.]\d{1,2}|$)$/;

const CountryShippingSection = ({
  intl,
  submitButtonText,
  onSubmit,
  shippingFeeCountries,
  shippingFeeSaveInProgress,
}) => {
  const titleText = intl.formatMessage({ id: "StripePayoutPage.shippingTitle" });
  const requiredFieldErrorMsg = intl.formatMessage({ id: "RequiredField.warningText" });
  const invalidFieldErrorMsg = intl.formatMessage({ id: "StripePayoutPage.invalidField" });
  const allCheckedFalseErrorMsg = intl.formatMessage({ id: "StripePayoutPage.generalError" });

  const [generalError, setGeneralError] = useState("");
  const resetGeneralError = () => setGeneralError("");

  const [countriesErrors, setCountriesErrors] = useState(countriesMocks.map((({ name }) => ({ name, error: "" }))));
  const resetErrors = () => {
    setCountriesErrors((prev) => prev.map((e) => ({ ...e, error: "" })));
  };

  const [countries, setCountries] = useState(shippingFeeCountries);
  useEffect(() => {
    setCountries([...shippingFeeCountries]);
  }, [shippingFeeCountries]);

  const onChangeIsShip = (e) => {
    const eventName = e.target.name;
    setCountries((prev) => prev.map((country) => {
      if (country.name === eventName) {
        if (country.isShip) {
          return ({ ...country, shippingFee: "", isShip: !country.isShip });
        }
        return ({ ...country, isShip: !country.isShip });
      }
      return ({ ...country });
    }));
    setCountriesErrors((prev) => {
      return prev.map(({ name, error }) => {
        if (name === eventName) {
          return ({ name, error: "" });
        }
        return ({ name, error });
      });
    });
    resetGeneralError();
  };

  const validateFeeValue = (val) => {
    const MIN_PRICE = 0;
    const MAX_PRICE = 35.00;
    return SHIPPING_FEE_REG_EXP.test(val)
      && val >= MIN_PRICE
      && val <= MAX_PRICE;
  };

  const onChangeFee = (e) => {
    const value = e.target.value;
    const eventName = e.target.name;
    const isValidValue = validateFeeValue(value);

    setCountries((prev) => prev.map((country) => {
      if (country.name === eventName) {
        return ({ ...country, shippingFee: value });
      }
      return ({ ...country });
    }));
    setCountriesErrors((prev) => {
      return prev.map(({ name, error }) => {
        if (name === eventName) {
          const errorMsg = !value && value !== 0
            ? requiredFieldErrorMsg
            : isValidValue ?  "" : invalidFieldErrorMsg;
          return ({ name, error: errorMsg });
        }
        return ({ name, error });
      });
    });
    resetGeneralError();
  };

  const submitValidation = useCallback(() => {
    const failedValidations = countries.reduce((acc, { name, isShip, shippingFee }) => {
      const existingError = countriesErrors.find((e) => e.name === name && e.error);

      if (isShip && !shippingFee || existingError) {
        acc.push({ name, error: existingError ? existingError.error : requiredFieldErrorMsg });
      }
      return acc;
    }, []);

    setCountriesErrors((prev) => prev.map((e) => {
      const existingError = failedValidations.find(({ name }) => name === e.name);
      if (existingError) {
        return ({ ...e, error: existingError.error });
      }
      return e;
    }));

    return failedValidations;
  }, [countries]);

  const onSubmitProcess = useCallback(() => {
    const validationPassed = submitValidation();
    const atLeastOne = countries.map(({ isShip }) => isShip).includes(true);

    if (!atLeastOne) {
      setGeneralError(allCheckedFalseErrorMsg);
    }

    if (!validationPassed.length && atLeastOne) {
      resetErrors();
      resetGeneralError();
      onSubmit(countries);
    }
  }, [countries]);

  return (
    <div className={classNames(css.sectionBackground, css.sectionMargin)}>
      <h1 className={css.title}>{titleText}</h1>
      {shippingFeeSaveInProgress ? <LoaderComponent /> : null}
      <div>
        {countries.map((country) => {
          const { isShip, shippingFee, name } = country;
          const error = countriesErrors.find((err) => err.name === name).error;
          const description = intl.formatMessage(
            { id: "StripePayoutPage.countryDescription" },
            { name },
          );
          const shippingDescription = intl.formatMessage(
            { id: "StripePayoutPage.countryShippingDescription" },
            { name },
          );
          const shippingFeePlaceholder = intl.formatMessage({ id: "StripePayoutPage.shipCountry.placeHolder"});

          return (
            <CountryShippingFee
              key={name}
              name={name}
              error={error}
              description={description}
              shippingDescription={shippingDescription}
              isShip={isShip}
              shippingFee={shippingFee}
              onChangeFee={onChangeFee}
              onChangeIsShip={onChangeIsShip}
              shippingFeePlaceholder={shippingFeePlaceholder}
            />
          );
        })}
      </div>
      <p className={css.generalError}>{generalError}</p>
      <Button className={css.submitButton} type="submit" onClick={onSubmitProcess}>
        {submitButtonText}
      </Button>
    </div>
  );
};

CountryShippingSection.propTypes = {
  submitButtonText: string.isRequired,
  onSubmit: func.isRequired,
  shippingFeeCountries: array.isRequired,
  shippingFeeSaveInProgress: bool.isRequired,
};

export default CountryShippingSection;
