import { bool, object } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { ContactDetailsPage, PasswordChangePage, DeleteAccountPage } from "../../containers";
import { isScrollingDisabled } from "../../ducks/UI.duck";
import { injectIntl, intlShape } from "../../util/reactIntl";
import { propTypes } from "../../util/types";
import { uploadImage } from "./SupplierProfileAccountPage.duck";
import css from "./SupplierProfileAccountPage.module.css";
import LoaderComponent from "../../components/Loader/Loader";

export class SupplierProfileAccountPageComponent extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    if (!this.props.authInfoLoaded || !this.props.currentUser) {
      return <LoaderComponent />;
    }

    return (
      <div className={css.accountPageContainer}>
        <ContactDetailsPage />
        <PasswordChangePage />
        <DeleteAccountPage />
      </div>
    );
  }
}

SupplierProfileAccountPageComponent.defaultProps = {
  currentUserListing: null,
  currentUserListingFetched: false,
};

SupplierProfileAccountPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
  // from withRouter
  history: object.isRequired,
  location: object.isRequired,
  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = (state) => {
  const { currentUser } = state.user;
  const { authInfoLoaded } = state.Auth;

  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
    authInfoLoaded,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onImageUpload: (data) => dispatch(uploadImage(data))
});

const SupplierProfileAccountPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(SupplierProfileAccountPageComponent);

export default SupplierProfileAccountPage;
