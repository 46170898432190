import React, { Component } from 'react';
import { bool } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import config from '../../config';
import { NamedLink } from '../../components';
import { displayRegistrationConfirmDialog } from '../../ducks/Modal.duck';
import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
} from '../../components';

import { TopbarContainer } from '..';
import { FormattedMessage } from '../../util/reactIntl';

import facebookImage from '../../assets/VegshelfFacebook-1200x630.png';
import twitterImage from '../../assets/VegshelfTwitter-600x314.png';
import css from './Blog3Page.module.css';

import arrowLeft from "../../assets/arrow-left-000000.svg";
import articleThumbnailImage from '../../assets/independent-grocery-shops.png';
import visual1 from '../../assets/consumer-trying-new-product.png';
import authorImage from '../../assets/baiba-soika-vegshelf.png';

export class Blog3PageComponent extends Component {
  constructor(props) {
    super(props);
    //this.state = { categoryIndex: 0, planIndex: 1, blogIndex: 0, FAQIndex: -1 };
  }
  render() {
    const {
      intl,
      scrollingDisabled,
      params,
    } = this.props;

    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage({ id: 'Blog3.schemaTitle' }, { siteTitle });
    const schemaDescription = intl.formatMessage({ id: 'Blog3.schemaDescription' }, { siteTitle });
    const schemaImage = `${config.canonicalRootURL}${facebookImage}`;

    const link1 = (
      <a className={css.linkFormat} href="https://www.vegshelf.com" target="_blank" >
        <FormattedMessage id="Blog3.link_1" />
      </a>
    );

    const email1 = (
      <a className={css.linkFormat} href="mailto:hello@vegshelf.com" target="_blank" >
        <FormattedMessage id="DeleteAccountPage.email" />
      </a>
    );

    return (
      <Page
        className={css.root}
        scrollingDisabled={scrollingDisabled}
        contentType="website"
        description={schemaDescription}
        title={schemaTitle}
        robots='index'
        facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
        twitterImages={[
          { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
        ]}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'WebPage',
          description: schemaDescription,
          name: schemaTitle,
          image: [schemaImage],
        }}
      >
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div className={css.blogArticleContainer}>
              <div className={css.blogArticleSection}>
                <NamedLink className={css.backToBlogSection} name="BlogPage">
                  <img className={css.arrow} src={arrowLeft} alt="" />
                  <p className={css.backToBlogText}>
                    <FormattedMessage id="BlogSinglePage.backToMainBlogPage" />
                  </p>
                </NamedLink>
                <h1 className={css.articleTitle}>
                  <FormattedMessage id="Blog3.title" />
                </h1>
                <p className={css.blogDate}>
                  <FormattedMessage id="Blog3.date" />
                </p>
                <img className={css.articleThumbnailImage} src={articleThumbnailImage} alt="Beautiful storefront of independent grocery shop" />
                <div className={css.blogAuthorSection}>
                  <img className={css.authorImage} src={authorImage} alt="Baiba Soika, Co-founder and CEO of Vegshelf" />
                  <p className={css.blogAuthor}>
                    <FormattedMessage id="BlogPage.authorBaiba" />
                  </p>
                  <p className={css.blogAuthorPosition}>
                    <FormattedMessage id="BlogPage.authorBaibaPosition" />
                  </p>
                </div>
                <div className={css.articleTextContainer}>
                  <p className={css.articleIntroParagraph}>
                    <FormattedMessage id="Blog3.introParagraph_1" />
                  </p>
                  <p className={css.articleParagraph}>
                    <FormattedMessage id="Blog3.introParagraph_2" />
                  </p>
                  <p className={css.articleSubtitle}>
                    <FormattedMessage id="Blog3.subtitle_1" />
                  </p>
                  <p className={css.articleParagraph}>
                    <FormattedMessage id="Blog3.paragraph_1_1" />
                  </p>
                  <p className={css.articleParagraph}>
                    <FormattedMessage id="Blog3.paragraph_1_2" />
                  </p>
                  <p className={css.articleSubtitle}>
                    <FormattedMessage id="Blog3.subtitle_2" />
                  </p>
                  <p className={css.articleParagraph}>
                    <FormattedMessage id="Blog3.paragraph_2_1" />
                  </p>
                  <p className={css.articleParagraph}>
                    <FormattedMessage id="Blog3.paragraph_2_2" />
                  </p>
                  <img className={css.paragraphVisual} src={visual1} alt="Person trying a locally-produced condiment" />
                  <p className={css.imageCopyrightText}>
                    <FormattedMessage id="Blog3.visual1Copyright" />
                  </p>
                  <p className={css.articleSubtitle}>
                    <FormattedMessage id="Blog3.subtitle_3" />
                  </p>
                  <p className={css.articleParagraph}>
                    <FormattedMessage id="Blog3.paragraph_3_1" />
                  </p>
                  <p className={css.articleParagraph}>
                    <FormattedMessage id="Blog3.paragraph_3_2" />
                  </p>
                  <p className={css.articleSubtitle}>
                    <FormattedMessage id="Blog3.subtitle_4" />
                  </p>
                  <p className={css.articleParagraph}>
                    <FormattedMessage id="Blog3.paragraph_4_1" />
                  </p>
                  <p className={css.articleParagraph}>
                    <FormattedMessage id="Blog3.paragraph_4_2" />
                  </p>
                  <p className={css.articleSubtitle}>
                    <FormattedMessage id="Blog3.subtitle_5" />
                  </p>
                  <p className={css.articleParagraph}>
                    <FormattedMessage id="Blog3.paragraph_5_1" />
                  </p>
                  <p className={css.articleParagraph}>
                    <FormattedMessage id="Blog3.paragraph_5_2" values={{ link1 }} />
                  </p>
                  <p className={css.articleSubtitle}>
                    <FormattedMessage id="Blog3.closingTitle" />
                  </p>
                  <p className={css.articleParagraph}>
                    <FormattedMessage id="Blog3.closingParagraph_1" />
                  </p>
                  <p className={css.articleParagraph}>
                    <FormattedMessage id="Blog3.closingParagraph_2" values={{ email1 }} />
                  </p>
                  <p className={css.articleImageCopyrightText}>
                    <FormattedMessage id="Blog3.articleImageCopyright" />
                  </p>
                </div>
              </div>
            </div>
            <div className={css.callToRegisterContainer}>
              <div className={css.callToRegisterSection}>
                <h3 className={css.callToRegisterTitle}>
                  <FormattedMessage id="BlogPage.registerBrand" />
                </h3>
                <div className={css.signUpSection}>
                  <div className={css.buttonSection}>
                    <NamedLink className={css.signUpButton} name="ApplyToSellPage">
                      <FormattedMessage id="BlogPage.registerBrandSignupButton" />
                    </NamedLink>
                  </div>
                  <p className={css.moreInfo}>
                    <FormattedMessage id="BlogPage.registerBrandWantToBuy" />
                    <NamedLink className={css.loginLink} name="LandingPage">
                      <FormattedMessage id="BlogPage.registerMoreInfo" />
                    </NamedLink>
                  </p>
                </div>
              </div>
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page >
    );
  }
};

Blog3PageComponent.defaultProps = {
};

Blog3PageComponent.propTypes = {
  scrollingDisabled: bool.isRequired
};
const mapStateToProps = state => {

  return {
    scrollingDisabled: isScrollingDisabled(state)
  };
};

const mapDispatchToProps = dispatch => ({
  onShowRegistrationConfirmDialog: () => dispatch(displayRegistrationConfirmDialog(true, false))
});

const Blog3Page = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(Blog3PageComponent);

export default Blog3Page;