import classNames from "classnames";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Form as FinalForm } from "react-final-form";
import { compose } from "redux";
import { FieldTextInput, Form, NamedLink, PrimaryButton } from "../../components";
import { FormattedMessage, injectIntl, intlShape } from "../../util/reactIntl";
import * as validators from "../../util/validators";

import css from "./ValidateEmailForm.module.css";

export class ValidateEmailFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { password: "" };
    this.password = React.createRef();
    this.onInputChange = this.onInputChange.bind(this);
    this.confirmPasswordValidator = this.confirmPasswordValidator.bind(this);
  }

  confirmPasswordValidator(value) {
    return this.password.current.value === value ? undefined : "*";
  }

  onInputChange(event) {
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        render={(formRenderProps) => {
          const {
            rootClassName,
            className,
            formId,
            handleSubmit,
            inProgress,
            invalid,
            // intl,
            // values,
            // idp,
            submitFailed,
            touched,
            buttonText,
            hideModal,
            // form,
            errors,
            emailExists,
          } = formRenderProps;

          const classes = classNames(rootClassName || css.root, className);
          const submitInProgress = inProgress;
          const submitDisabled = invalid || submitInProgress;
          const required = validators.required(<FormattedMessage id="ValidateEmailForm.enterBusinessEmail" />);
          // 16.07.23 Vegshelf freeze: added nameRequired as a validator for registrationConfirmDialogue as part of Vegshelf freeze
          const nameRequired = validators.required(<FormattedMessage id="ValidateEmailForm.enterName" />);
          const emailValid = validators.emailFormatValid(<FormattedMessage id="ValidateEmailForm.enterValidEmail" />);
          const hasError = (field) => {
            return (errors["email"]) && (submitFailed || touched[field]);
          };

          return (
            <Form className={classes} onSubmit={handleSubmit}>
              <div>
                <div className={css.formRow}>
                  <div className={css.formField}>
                    <div>
                      {emailExists && (
                        <span className={css.errorMessage}>
                          <FormattedMessage id="ValidateEmailForm.accountExists" />
                          <NamedLink name="LoginPage" onClick={hideModal}>
                            <FormattedMessage id="ValidateEmailForm.logIn" />
                          </NamedLink>
                          <FormattedMessage id="ValidateEmailForm.or" />
                          <NamedLink name="PasswordRecoveryPage" onClick={hideModal}>
                            <FormattedMessage id="ValidateEmailForm.resetPassword" />
                          </NamedLink>
                        </span>
                      )}
                      {hasError("email") && (<span className={css.errorMessage}>{errors["email"]}</span>)}
                      
                      {/* 16.07.23 Vegshelf freeze: added firstName error for registrationConfirmDialogue as part of Vegshelf freeze */}
                      {hasError("firstName") && (<span className={css.errorMessage}>{errors["firstName"]}</span>)}
                    </div>
                    
                    {/* 16.07.23 Vegshelf freeze: added firstName firstName input for registrationConfirmDialogue as part of Vegshelf freeze */}
                    <label htmlFor={formId ? `${formId}.firstName` : "firstName"}>
                      <FormattedMessage id="UserFields.name" />
                      <span className={css.requiredMarker}>*</span>
                    </label>
                    <div>
                      <FieldTextInput
                        type="text"
                        id={formId ? `${formId}.firstName` : "firstName"}
                        name="firstName"
                        validate={validators.composeValidators(nameRequired)}
                      />
                    </div>
                    <br/>

                    <label htmlFor={formId ? `${formId}.email` : "email"}>
                      <FormattedMessage id="UserFields.email" />
                      <span className={css.requiredMarker}>*</span>
                    </label>
                    <div>
                      <FieldTextInput
                        type="text"
                        id={formId ? `${formId}.email` : "email"}
                        name="email"
                        validate={validators.composeValidators(required, emailValid)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={css.bottomWrapper}>
                <PrimaryButton
                  type="submit" className={css.submitButton}
                  inProgress={submitInProgress}
                  disabled={submitDisabled}
                >
                  {buttonText}
                </PrimaryButton>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

ValidateEmailFormComponent.defaultProps = { inProgress: false };

ValidateEmailFormComponent.propTypes = {
  inProgress: PropTypes.bool,
  // from injectIntl
  intl: intlShape.isRequired,
};

const ValidateEmailForm = compose(injectIntl)(ValidateEmailFormComponent);

ValidateEmailForm.displayName = "ValidateEmailForm";

export default ValidateEmailForm;
