import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { NamedLink } from '../../components';
import { compose } from 'redux';
import { displayRegistrationConfirmDialog } from '../../ducks/Modal.duck';
import imageOne from '../../assets/store-owner-vegshelf.png';
import imageTwo from '../../assets/retail-buyers-assistant-for-product-sourcing.png';

import css from './SectionHero.module.css';
import { connect } from 'react-redux';

const SectionHeroComponent = props => {
  const { rootClassName, className, isAuthenticated, onDisplayRegistrationConfirmDialog } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      <div className={css.sectionHeroContainerOne}>
      </div>
      <div className={css.sectionHeroContainerTwo}>
      </div>
      <div className={css.sectionHeroContainerThree}>
        <div className={css.heroText}>
          <header><h1 className={css.heroMainTitle}>
            <FormattedMessage id="SectionHero.mainTitle1" />&nbsp;
            <span>&nbsp;&nbsp;<FormattedMessage id="SectionHero.mainTitle2" />&nbsp;&nbsp;</span>
            <br></br>
            <FormattedMessage id="SectionHero.mainTitle3" />
          </h1></header>
          <h2 className={css.heroSubTitle}>
            <FormattedMessage id="SectionHero.mainSubtitle1" />
          </h2>
          {isAuthenticated ? (<div className={css.buttonContainer}>
            <NamedLink
              name="NewSearchProductsPage"
              className={css.signUpButtonRegistered}>
              <FormattedMessage id="SectionHero.browseProducts" />
            </NamedLink>
          </div>) : (<div className={css.buttonContainer}>
            <NamedLink
              name="ApplyToSellPage"
              className={css.registerButton}>
              <FormattedMessage id="SectionHero.registerToSell" />
            </NamedLink>
            <a
              onClick={() => onDisplayRegistrationConfirmDialog()}
              className={css.signUpButton}>
              <FormattedMessage id="SectionHero.signUpToShop" />
            </a>
          </div>)}
        </div>
        <div className={css.imageContainer}>
          <img className={css.imageOne} src={imageOne} alt="Order today's most exciting food & beverage brands for your store" />
          <img className={css.imageTwo} src={imageTwo} alt="Retailer's secret weapon for staying current" />
        </div>
      </div>
    </div>
  );
};

SectionHeroComponent.defaultProps = { rootClassName: null, className: null };

SectionHeroComponent.propTypes = {
  rootClassName: string,
  className: string,
};
const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  return {
    isAuthenticated
  };
};
const mapDispatchToProps = dispatch => ({
  onDisplayRegistrationConfirmDialog: (isBuyer) => dispatch(displayRegistrationConfirmDialog(true, true)),
});
const SectionHero = compose(
  connect(mapStateToProps, mapDispatchToProps),
)(SectionHeroComponent);

export default SectionHero;
