import React from 'react';
import { bool, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import css from './SectionHowItWorks.module.css';
import { displayRegistrationConfirmDialog } from '../../ducks/Modal.duck';
import { compose } from 'redux';
import { connect } from 'react-redux';

const SectionHowItWorksComponent = props => {
  const { rootClassName, className, isAuthenticated, onDisplayRegistrationConfirmDialog } = props;

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      <div className={css.steps}>
        {isAuthenticated ? (
          null
        ) : (
          <div className={css.stepCallToRegister}>
            <h3 className={css.stepCallToRegisterTitle}>
              <FormattedMessage id="SectionHowItWorks.callToRegister" />
            </h3>
            <div className={css.buttonContainer}>
              <a
                onClick={() => onDisplayRegistrationConfirmDialog()}
                className={css.signUpButton}>
                <FormattedMessage id="SectionHowItWorks.registerBuyer" />
              </a>
            </div>
          </div>
        )}
        
        <div className={css.step}>
          <div className={css.stepIcon1}></div>
          <h3 className={css.stepTitle}>
            <FormattedMessage id="SectionHowItWorks.part1Title" />
          </h3>
        </div>

        <div className={css.step}>
          <div className={css.stepIcon2}></div>
          <h3 className={css.stepTitleLarge}>
            <FormattedMessage id="SectionHowItWorks.part2Title" />
          </h3>
        </div>

        <div className={css.step}>
          <div className={css.stepIcon3}></div>
          <h3 className={css.stepTitle}>
            <FormattedMessage id="SectionHowItWorks.part3Title" />
          </h3>
        </div>

      </div>
    </div>
  );
};

SectionHowItWorksComponent.defaultProps = {
  rootClassName: null,
  className: null,
};

SectionHowItWorksComponent.propTypes = {
  rootClassName: string,
  className: string,
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  return {
    isAuthenticated
  };
};
const mapDispatchToProps = dispatch => ({
  onDisplayRegistrationConfirmDialog: (isBuyer) => dispatch(displayRegistrationConfirmDialog(true, true)),
});
const SectionHowItWorks = compose(
  connect(mapStateToProps, mapDispatchToProps),
)(SectionHowItWorksComponent);

export default SectionHowItWorks;