import React, { Component } from 'react';
import { bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { propTypes } from '../../util/types';

import css from './SupplierTermsPage.module.css';

export class SupplierTermsPageComponent extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      history,
      intl
    } = this.props;
    return (
      <div className={css.vegshelfInformationContainer}>
        <div className={css.vegshelfInformationBox1}>
          <h1 className={css.mainTitle}>
            <FormattedMessage id="SupplierTermsPage.supplierTerms" />
          </h1>
          <p className={css.bodyText}>
            <i>Last updated on Apr 5, 2022</i>
            <br /><br />
            Welcome to VEGSHELF - a curated online wholesale platform connecting emerging food & beverage brands with retailers, accessible at <a href="https://vegshelf.com/" target="_blank">www.vegshelf.com</a> (the “Platform”), and owned by Vegshelf UG (haftungsbeschränkt), registered with the Amtsgericht Düsseldorf with the commercial register number HRB 88669, located at Leopoldstr. 49, 40211 Düsseldorf, Germany. VAT number DE328398511, e-mail: <a href="mailto:hello@vegshelf.com" target="_blank">hello@vegshelf.com</a>.
            <br /><br />
            These terms (the “Terms”) set forth the legally binding setting on which the Visitor and the Supplier may access and use the Platform, and provide the terms and conditions under which VEGSHELF provides the Service that will enable the Supplier to market and sell emerging food and beverage Products through the digital wholesale Platform. These Terms govern the relationships between VEGSHELF and each Visitor and Supplier and prevail over any other document.
            <br /><br /><br />

            <b>1. DEFINITIONS AND INTERPRETATIONS</b>
            <br /><br />
            In these Terms, the capitalized terms with the respective meanings as described below are used. Words in the singular include the plural and vice versa.
            <br /><br />
            <b>“Account”</b>: means an account of the Member on the Platform that can be identified with a unique e-mail address.
            <br /><br />
            <b>“Buyer”</b>: means the Member using the Service to discover, view, request, or purchase Products on the Platform for the purpose of resale of those Products, including but not limited to brick and mortar stores, online shops, retail chains, cafes, coffee shops, subscription box services, hotels, gyms, food delivery companies, and other companies reselling food & beverage Products.
            <br /><br />
            <b>“Complaint Submission Period”</b>: means a time period of 72 hours from the date of Completed Delivery, when the Buyer can submit any complaint or raise an issue (e.g. damaged and/or missing Products) related to Order and/or Products received.
            <br /><br />
            <b>“Completed Delivery”</b>: means when Products have been delivered at the Buyer’s location on the date mentioned on the delivery note and, in quality and state, as specified in the Product Listing by the Supplier, and the Supplier has updated the order status as “Delivered” in the Platform.
            <br /><br />
            <b>“Content”</b>: means any information added, created, submitted, uploaded, distributed, or posted by the Member to the Platform, via the Services or sent to VEGSHELF via e-mail, including but not limited to photos, videos, audios, graphics, written posts, messages and comments, trademarks, files, logos, certification documents, company and/or Product details.
            <br /><br />
            <b>“Fulfillment”</b>: means the process of delivering Products from the selling Supplier to the buying Buyer at the Buyer’s location. This can include warehousing, packing, issuing a delivery note, handling, picking up, shipping, and more.
            <br /><br />
            <b>“Invoice”</b>: means an invoice issued by VEGSHELF, for and/or on behalf of the Supplier, either payable by the Buyer for the Products that were purchased by the Buyer through the Platform, or payable by the Supplier in return for the Service supplied by VEGSHELF.
            <br /><br />
            <b>“Member”</b>: means the Visitor who has registered on the Platform, and whose registration has been verified and approved by VEGSHELF, and thus has an open Account on the Platform.
            <br /><br />
            <b>“Order”</b>: means Order Request that has been confirmed by the Supplier selling the Product.
            <br /><br />
            <b>“Order Request”</b>: means the request of the Buyer to purchase a Product on the Platform that is pending the confirmation or rejection by the Supplier selling the Product within 72 hours from the moment of receiving such request.
            <br /><br />
            <b>“Party”</b>: means, individually or together, VEGSHELF and/or the Supplier and/or the Buyer and/or the Member and/or the Visitor.
            <br /><br />
            <b>“Product”</b>: means any unique consumer-packaged food and beverage product listed, offered, and sold on the Platform by the Supplier to the Buyer. Product has to comply with these Terms. Each unique Product is represented by its own digital “Product Listing” created and published on the Platform by the selling Supplier or VEGSHELF on behalf of such Supplier.
            <br /><br />
            <b>“Service”</b>: means all of the VEGSHELF online services, which are made available to the Member through the Platform (described in detail in paragraph 3 of these Terms).
            <br /><br />
            <b>“Store”</b>: means an online wholesale storefront that consists of key company information and the Supplier’s Products that are offered and sold through the Platform.
            <br /><br />
            <b>“Supplier”</b>: means the Member using the Service to offer, list, distribute, trade, or sell Products through the Platform to Buyers, including but not limited to brand, producer, manufacturer, or other companies.
            <br /><br />
            <b>“Visitor”</b>: means a representative of a registered business who visits or accesses the Platform, regardless of whether this representative is a Member or not.
            <br /><br />
            <b>“VEGSHELF”</b>: Vegshelf UG (haftungsbeschränkt), registered with the Amtsgericht Düsseldorf with the commercial register number HRB 88669, located at Leopoldstr. 49, 40211 Düsseldorf, Germany, and with VAT number DE328398511.
            <br /><br /><br />

            <b>2. ACCEPTANCE OF TERMS AND CHANGES TO THE TERMS</b>
            <br /><br />
            2.1. 	When visiting or accessing the Platform and using the Services, the Supplier expressly agrees, without restriction, to be legally bound by all the provisions of these Terms and the Privacy Policy.
            <br /><br />
            2.2.	If the Visitor or the Supplier does not understand, read, and/or agree to the Terms, the Visitor or the Supplier undertakes to stop visiting, accessing, or using the Platform and the Service in any manner, and, as the case may be, abstain from registering on the Platform.
            <br /><br />
            2.3.	In case of changes of legislation, of the case-law, or of a change of the business model or economic circumstances, VEGSHELF, at its sole discretion, explicitly reserves the right to change, edit, and replace these Terms and any supplementary terms published on the platform or in the Supplier profile at any time. The new Terms will be placed on the Platform and/or notice will be sent via e-mail to the Supplier at least fourteen (14) calendar days before the changes take effect. During this fourteen (14) calendar day period, the Supplier has the right to reject the new Terms by notifying VEGSHELF via e-mail (<a href="mailto:hello@vegshelf.com" target="_blank">hello@vegshelf.com</a>), in which case the Supplier will no longer be able to use and access the Service, and the Supplier’s right to access and use the Service will expire at the end of the aforementioned period of fourteen (14) calendar days. If the Supplier does not reject the new Terms and uses the Service after a change of the Terms is effective, it is to be deemed as the Supplier’s acceptance of the changed Terms.
            <br /><br /><br />

            <b>3. DESCRIPTION OF THE SERVICES</b>
            <br /><br />
            3.1.	VEGSHELF has created an internet-based business-to-business Platform where the Supplier can offer and sell Products, and Buyers can discover, place Orders and pay for these Products, as part of the Service.
            <br /><br />
            3.2.	The Platform is designed to offer Services exclusively to Visitors and Members who are registered businesses. The Visitor and the Supplier who is entering into these Terms on behalf of a registered business warrant that they have the authority to bind the Party they are representing to these Terms. Services are not designed and thus not available to end-consumers.
            <br /><br />
            3.3.	VEGSHELF has an intermediary role between the Buyer and the Supplier of the Products therefore it is to be understood that: (i) VEGSHELF is not involved in the direct selling of the Products between the Buyer and the Supplier; (ii) the Buyer and the Supplier decide at their own discretion on whether to do business with the other Party through the Platform; (iii) VEGSHELF is not a seller, reseller, or owner of the Products that are being listed and sold through the Platform; (iv) VEGSHELF never enters into possession of the Products and does not hold stock of the Products; (v) VEGSHELF has no control over the sale or the payment of the Products sold and ordered on the Platform.
            <br /><br />
            Therefore, VEGSHELF rejects any liability for the acts and omissions of the Supplier and/or the Buyer on the Platform.
            <br /><br />
            3.4.	VEGSHELF has the right at its own discretion to change, suspend or discontinue any part of the Services, introduce new features or limits on certain features or restrict access to parts or all of the Services unless this disproportionately impairs the material obligations of VEGSHELF due to the contract with the Supplier. VEGSHELF may give the Supplier a notice when such material change to the Services adversely affecting the Supplier is being made.
            <br /><br /><br />

            <b>4. ACCESS TO THE PLATFORM AND SERVICES</b>
            <br /><br />
            4.1.	The Platform is accessible by any Visitor. However, the Visitor is being granted access to the Services and all its functionalities, only after the Visitor has registered as the Supplier through the Platform, and VEGSHELF has approved their request and thus granted access to the Services.
            <br /><br />
            4.2.	The Visitor and the Supplier are responsible for ensuring that their access to the Platform and usage of the Services is in compliance with all laws and regulations that apply to them in their respective countries and when transacting with companies from abroad. If by accessing and using the Platform and the Services the Visitor or the Supplier violates any law or regulation applicable to them, the Visitor or the Supplier shall stop accessing and using the Platform and the Services immediately and will be held liable for any damages or costs sustained by VEGSHELF in any such case.
            <br /><br />
            4.3.	VEGSHELF reserves the right at any time and at its sole discretion to interrupt or suspend access to the Platform and/or the Services, including but not limited to operational reasons, technical updates, failures of communication networks, and/or servers, maintenance, internal choices, and force majeure.
            <br /><br />
            4.4.	VEGSHELF does not warrant that access to the Services and the Platform shall be uninterrupted or error-free.
            <br /><br /><br />

            <b>5. THE SUPPLIER REGISTRATION TERMS</b>
            <br /><br />
            5.1.	To sell the Products through the Platform and have access to the full functionality of the Service as the Supplier, the Visitor must submit a registration request to, and be approved by VEGSHELF. Both the registration process and the Supplier Account opening are free of charge. The Supplier registration form is accessible to any Visitor through the Platform and requires the Visitor to provide basic information about them, their company, and Products.
            <br /><br />
            5.2.	To qualify for the Supplier Account, the Supplier must have a legally registered business that manufactures and/or offers for sale consumer-packaged food and/or beverage Products within, including but not limited to, one or several following Product categories: meat alternatives, seafood alternatives, plant-based dairy alternatives, plant-based snacks, plant-based sweets, plant-based ready-made meals, bread, plant-based baked goods, and beverages. The Supplier must be able to present proof of a valid VAT number, company registration number, website address, and other details as may be required by VEGSHELF.
            <br /><br />
            5.3.	The registration as the Supplier may only be done by an individual who is at least 18 years old and authorized to represent the Supplier’s legal entity, thus the Visitor registering as the Supplier warrants that they are authorized to agree to these Terms on behalf of the legal entity that they are representing when registering.
            <br /><br />
            5.4.	The Visitor registering as the Supplier agrees to use the email address with their website’s domain name and agrees to refrain from registering using the email address with the domain name pointing to an external website or service.
            <br /><br />
            5.5.	The Visitor registering as the Supplier undertakes that the information it provides during the registration process is correct, sincere, accurate, up-to-date, and such that does not infringe third-party rights.
            <br /><br />
            5.6.	VEGSHELF will use good faith efforts to review the Visitor’s registration request as soon as possible. However, the Visitor acknowledges that VEGSHELF can review a limited amount of requests in a given time, and is not obliged to accept the Visitor as the Supplier, thus releasing VEGSHELF from any liability regarding the reviewal speed and process of their registration request.
            <br /><br />
            5.7.	VEGSHELF at its sole discretion reserves the right to accept registration requests, to change the Supplier eligibility criteria, and/or refuse to offer the Service to the Visitor by rejecting or deleting the registration request at any time without prior notice and without providing a reason.
            <br /><br />
            5.8.	After the Visitor has submitted their registration request through the Platform and VEGSHELF has approved it, a contractual relationship between VEGSHELF and the Supplier is formed.
            <br /><br />
            5.9.	The Supplier undertakes to create and use only one Account. When completing the registration process and opening an Account with VEGSHELF, the Supplier will need to choose a secure password. It is the Supplier’s sole responsibility to keep their password private and confidential from any unauthorized Party. The Supplier must promptly notify VEGSHELF by sending an e-mail to <a href="mailto:hello@vegshelf.com" target="_blank">hello@vegshelf.com</a> if they believe that someone has used or has access to their Account without permission.
            <br /><br />
            5.10.	The Supplier will be required to provide additional information through the Platform about their shipping terms and set up their payout by providing business-related documentation and identifying the bank account to which the Supplier wishes to receive a payout with Stripe, VEGSHELF payment service partner. By setting the payout account with Stripe, the Supplier agrees to <a href="https://stripe.com/en-gb-de/connect-account/legal" target="_blank">Stripe Connect terms</a>.
            <br /><br />
            5.11.	The Supplier is required to confirm their email address, as instructed on the Platform, in order to receive further notifications related to their Order Requests, Orders, and the Account.
            <br /><br />
            5.12.	The Supplier expressly accepts to authorize VEGSHELF to issue Invoices on the Supplier’s behalf for the Orders made by the Buyers through the Platform.
            <br /><br />
            5.13.	The Supplier undertakes to take full responsibility for their Account, all activity on it, and correctness of their Account data. It is the Supplier’s responsibility to keep the data up-to-date, sincere and correct throughout the time of using the Services. The Supplier’s failure to provide accurate, complete and updated data may result in the Supplier not being eligible to access the Platform and the Service.
            <br /><br /><br />

            <b>6. THE STORE, PRODUCTS, PRODUCT LISTINGS, AND MAINTENANCE OF THEM</b>
            <br /><br />
            6.1.	The Supplier has their digital Store allocated to them on the Platform where the Supplier can showcase their company and make Products available for sale to either all Buyers on the Platform or Buyers of specific geographic areas.
            <br /><br />
            6.2.	To be able to showcase and sell Products through the Platform and the Service, VEGSHELF requires the Supplier to list their entire Product assortment, including all Product variations, unless agreed otherwise with VEGSHELF in writing, by creating and submitting for publishing Product Listings through the Platform.
            <br /><br />
            6.3.	Both the Store and Product Listing creation is free of charge for the Supplier. However, the Supplier is solely responsible for all the related and incurred costs with the Store and Product Listing creation and submission including but not limited to the creation of professional Product photos and texts.
            <br /><br />
            6.4.	The Supplier is solely responsible for adding information about their company and creating Product Listings in their Store in accordance with instructions provided on the Platform unless agreed in advance that VEGSHELF will assist with the creation of their Store on the Platform. In case VEGSHELF is assisting with the Store creation, the Supplier is liable for making sure all the Content is correct, up-to-date, and does not infringe any third-party rights.
            <br /><br />
            6.5.	Each Product Listing consists of the key trade information about the Product including but not limited to the Product name, images, description, certifications, lead time, price information, packaging specifics, the applicable VAT rate, and other product-related Content.
            <br /><br />
            6.6.	The Supplier is liable and responsible for all the Content that their Store and Product Listings contain and their compliance with these Terms. By creating and submitting Product Listings, the Supplier warrants that they have all the necessary approvals, licenses, and rights to use the Content submitted. Moreover, the Supplier is responsible for paying all royalties or similar payments that are or may become due to any third party based on their Content and display or other use of that Content on the Platform
            <br /><br />
            6.7.	The Supplier agrees to refrain from submitting to or making available on the Platform the Content that is fraudulent, unlawful, abusive, vulgar, or inappropriate in any other way as determined by VEGSHELF at its exclusive discretion, including but not limited to the Content that: (i) breaches copyrights, trademarks, patents, trade secrets, rights of privacy and publicity, or any rights of a third party; (ii) violates any law, regulation, or rule that may apply; (iii) abuses and threatens others; (iv) is misleading, false, or defamatory; (v) contains pornography, (vi) is racist.
            <br /><br />
            6.8.	The Supplier agrees to refrain from selling Products that are fraudulent, unlawful, abusive, or inappropriate in any other way as determined by VEGSHELF at its exclusive discretion, including but not limited to Products that: (i) breach copyrights, trademarks, patents, trade secrets, rights of privacy and publicity, or any rights of a third party; (ii) violate any law, regulation, or rule that may apply.
            <br /><br />
            6.9.	In the event that VEGSHELF recognizes that Products that are in breach with these Terms are listed for sale, VEGSHELF reserves the right to: (i) removes these Product Listings from the Platform; (ii) block or temporarily suspend the Supplier’s Account; (iii) terminate these Terms immediately and delete the Supplier’s Account; (iv) gather, disclose and/or transfer the information, including the Supplier’s personal information as the case may be, about any such potentially fraudulent activity to the competent authorities and cooperate with competent authorities upon request.
            <br /><br />
            6.10.	The Supplier warrants that their Product Listing is submitted within the appropriate product category. If VEGSHELF considers that the Supplier has submitted for publishing the Product Listing to an inappropriate category, VEGSHELF reserves the right to re-classify and re-post the Product Listing.
            <br /><br />
            6.11.	The Supplier warrants that their Product Listing and Store Content do not contain any links to any external website that does not comply with instructions on the Platform.
            <br /><br />
            6.12.	The Supplier agrees that VEGSHELF may edit or remove the Content they publish or submit for publishing for the Store and Product Listings, without any liability or prior notice to the Supplier, or ask for additional Content in order to comply with VEGSHELF’s requirements and specifications. VEGSHELF at its sole discretion has the right to determine whether the Supplier’s provided Content satisfies expected quality and in case of a failure, VEGSHELF reserves the right to remove or edit such Content.
            <br /><br />
            6.13.	VEGSHELF reserves the right solely at its discretion to accept or reject publishing any Product Listing and has the final discretion as to the Content of the Supplier’s Store.
            <br /><br />
            6.14.	It is the Supplier’s sole responsibility to keep the Product Listings and the Store up to date including but not limited to full Product ranges, up-to-date prices, packaging details, and Product images.
            <br /><br />
            6.15. 	The Supplier is solely responsible for ensuring that all their Products sold on the Platform comply with these Terms and any legal and regulatory provisions in force. The Supplier warrants:
            <br /><br />
            6.15.1.	to have all legal and beneficial rights to offer and sell Products on the Platform and to sell Products to Buyers;
            <br /><br />
            6.15.2.	that their Products comply with all applicable regulations and laws, including but not limited to, labeling, packaging, safety, testing, warning, and other consumer protection law requirements;
            <br /><br />
            6.15.3.	that their Products have been produced, handled, stored, packaged, and shipped and delivered strictly according to relevant food safety and hygiene regulations, applicable laws, good manufacturing practices, with relevant use by dates clearly shown and in the required temperatures and conditions;
            <br /><br />
            6.15.4.	that Product-specific information and claims are represented and communicated accurately, truthfully, and precisely through the Product Listings and the Store on the Platform;
            <br /><br />
            6.15.5.	that their Products have not been contaminated, does not have any damage or defect at the time of selling, and are of good quality, edible, and fit for human consumption;
            <br /><br />
            6.15.6.	that they have all necessary approvals and certifications relating to Product's food safety and quality, as well as Product-specific claims;
            <br /><br />
            6.16.	The Supplier agrees that VEGSHELF is not responsible for determining whether their company and/or Products meet the criteria mentioned in paragraph 6.15, therefore the Supplier is exclusively liable and responsible for meeting the above requirements in regard to their Products.
            <br /><br />
            6.17.	The Supplier agrees and warrants to market and sell their Products on the Platform and through the Services at the equivalent or lower price as it would be off of the Platform for other wholesale buyers. It is to be understood that if VEGSHELF finds evidence that the Supplier is offering and selling their Products on the Platform at a higher price than it would be off of the Platform, VEGSHELF reserves the right to warn the Supplier and temporarily or permanently ban their Account and access to the Platform and the Services.
            <br /><br />
            6.18.	The Supplier warrants that all information published by the Supplier on the Platform, on the Store and Product Listings is correct, sincere, accurate, up-to-date, and does not infringe third-party rights.
            <br /><br />
            6.19.	The Supplier must set the typical delivery lead time, which should be an accurate estimation of their actual lead time. The lead time will be visible to Buyers on the Supplier’s Product Listings and Store on the Platform for the purpose of informing Buyers, who may rely on it when placing an Order.
            <br /><br />
            6.20.	The Supplier agrees to keep sufficient inventory on hand for Products that are advertised as available on the Platform. The Supplier agrees to promptly update the status of Product Listing if the Product is currently not available. The Supplier agrees to promptly remove any Product Listings from the Platform if the Product is discontinued and/or no longer available.
            <br /><br /><br />

            <b>7. CONTENT LICENSE</b>
            <br /><br />
            7.1.	The Supplier undertakes to publish the Content on the Platform in compliance with these Terms and with any legal and regulatory provisions in force.
            <br /><br />
            7.2.	The Supplier, from which the Content has originated and has been publicly or privately distributed, is liable for it and takes responsibility for the character, accuracy, completeness, and truth of the Content. The Supplier warrants that they own their Content in accordance with these Terms or have the necessary rights to use it.
            <br /><br />
            7.3.	Should the Content provided by the Supplier fails to comply with these Terms or any applicable laws and/or regulations, VEGSHELF reserves the right to remove any such Content without prior notice.
            <br /><br />
            7.4.	The Supplier acknowledges that their Content may be viewed by any Visitor, Buyer, and other Supplier, who can access their Product Listings and Store on the Platform.
            <br /><br />
            7.5.	By providing the Content through the Platform and the Services, the Supplier grants VEGSHELF a worldwide, free, non-exclusive, assignable, transferable, perpetual, sublicensable, and irrevocable license for the whole duration of the contractual relationship, so as to use, display, format, edit, translate, publish, reproduce, disseminate, represent, commercialize, and exploit the copyright, publicity, trademark, and database rights with respect to the Content solely for the purpose of providing and advertising the Platform and the Service, on any other media (any tangible or digital medium, any press, publication, report, presentation, website, promotion) and without restriction as to a number of copies, specific audience or geographic area of use.
            <br /><br />
            7.6.	The Supplier warrants that they are the exclusive owner of their Content and that they have all rights to grant the Content license to VEGSHELF without infringement or violation of any third party rights, including without limitation, any privacy rights, copyrights, trademarks, patents, trade secrets, publicity rights, contract rights, or any other proprietary or intellectual property rights.
            <br /><br />
            7.7	VEGSHELF does not guarantee that any Content will be made available through the Platform and the Service. Moreover, VEGSHELF has the right to edit, remove or block any Content at its sole discretion, at any time, without notice to the Supplier and without providing a reason, including but not limited to, after receiving claims from third parties or public authorities relating to such Content, as well as based on our concerns of violation of these Terms by the Supplier.
            <br /><br />
            7.8.	If for any reason the Supplier wishes to remove their Content from the Platform, they can do so either through their Account on the Platform or by contacting VEGSHELF at <a href="mailto:hello@vegshelf.com" target="_blank">hello@vegshelf.com</a>. Once the Supplier’s Content is deleted, it is automatically removed from the Platform and the Service, however, it is to be understood that the Content may remain on VEGSHELF backup copies, advertisements of the Platform on VEGSHELF’s or third-party platforms, social media channels, and media for a reasonable period of time and according to applicable laws.
            <br /><br />
            7.9.	VEGSHELF reserves the right at any time and at its sole discretion to remove or modify any Content from the Platform and the Services for any reason, including, but not limited to, practical, business, or technical reasons. The Supplier undertakes not to hold VEGSHELF liable, nor will ask for compensation to be paid to them for any such activity.
            <br /><br />
            7.10.	The Content uploaded on the Platform through the Services is made available solely for informational purposes. VEGSHELF does not warrant the completeness and accuracy of any such Content. Any reliance the Supplier and/or the Visitor places on such Content is at their own risk.
            <br /><br />
            7.11.	In the event of any dispute between Members in relation to Content, it is solely Member’s responsibility to solve the issue directly with each other without involving VEGSHELF in the resolution of the dispute, hereby releasing VEGSHELF from any liability in this regard.
            <br /><br /><br />

            <b>8. INTELLECTUAL PROPERTY RIGHTS</b>
            <br /><br />
            8.1.	The Content of the Platform and the Service, provided by Members and VEGSHELF, is protected by trademarks, trade secrets, copyrights, patents, or other proprietary rights and laws and is the respective Party’s intellectual property. The Visitor and/or the Supplier must obey and respect all copyright information and limitations of the Content accessed through the Platform and the Service.
            <br /><br />
            8.2.	Unless explicitly permitted under these Terms, the Visitor and/or the Supplier are strictly prohibited from using and/or copying any intellectual property, including but not limited to any Content provided by other Members, partners, or VEGSHELF, any feature, design element, source code, data, the user interface of the Platform without the prior written approval of VEGSHELF.
            <br /><br />
            8.3.	Any intellectual property rights created as a result of the use of the Platform and the Service by the Supplier and performance of the Services by VEGSHELF is the property of VEGSHELF, except intellectual property related to Content provided by the Supplier.
            <br /><br /><br />

            <b>9. THE ORDER REQUESTS, ORDERS, AND ORDER FULFILLMENT</b>
            <br /><br />
            9.1.	Buyers may directly communicate with the Supplier through direct messages on the Platform. The Supplier is responsible to respond to any messages from Buyers in a timely and professional manner, as advised within 24 hours upon receiving the message.
            <br /><br />
            9.2.	The Supplier undertakes to truthfully and clearly display their Product sales terms and conditions on their Store and Product Listings on the Platform, and perform the sale and Order Fulfillment of Products according to information the Supplier has stated.
            <br /><br />
            9.3.	The Supplier agrees to comply with a fixed minimum Order sum of €100.00 (one hundred euros and zero cents) (excluding taxes and shipping) as set by VEGSHELF and undertakes to define their Product sale, delivery, and Order Fulfillment terms and conditions accordingly.
            <br /><br />
            9.4.	The Supplier is solely responsible for the VAT collection, remittance, and applicable reporting to authorities. Specific VAT rates vary depending on the location of the Supplier and the Buyer.
            <br /><br />
            9.5.	It is the Supplier’s responsibility to decide whether free samples of the Product are being offered and to truthfully and clearly communicate such a decision in the Product Listing according to the instructions on the Platform. If free Product samples are offered, the Supplier undertakes to deliver the free Product samples to the requesting Buyer on its own terms and conditions. The Supplier understands that Buyer requesting free Product samples on the Platform does not warrant that the Buyer will place the Order after receiving the Product samples.
            <br /><br />
            9.6.	The Supplier will be informed about the new Order Request by notification via email and on the Platform. Upon receiving an Order Request from the Buyer on the Platform, the Supplier is responsible for responding by accepting or rejecting the Order Request within the time limit and in conditions indicated on the Platform. If the Order Request has not been accepted or rejected within the time limit, the Order Request automatically expires rendering the Order Request canceled and no longer available.
            <br /><br />
            9.7.	By accepting the Order Request, the Supplier autonomously and under its sole responsibility undertakes to arrange, manage and execute the Order Fulfillment including but not limited to preparation of the Order, creation of a shipping label, Product handling, transportation, and delivery at the place as identified by the Buyer in their Order Request in the time period as identified by the Supplier’s lead time on the Platform.
            <br /><br />
            9.8.	The Supplier undertakes to define their own terms and conditions of Order Fulfillment so that they respect the applicable terms and conditions of Product sale and payment defined in these Terms. The Supplier is liable to that extent. In the event of a contradiction of the Supplier’s terms and conditions of Order Fulfillment with these Terms, these Terms and the information published on the Platform prevail.
            <br /><br />
            9.9.	The Supplier undertakes to issue a delivery note to be signed by the Buyer on the date the Order is delivered to the Buyer.
            <br /><br />
            9.10.	Once the Order has been shipped out to the Buyer, the Supplier undertakes to update the Order status on the Platform in a timely manner and no longer than within 48 hours. The Order can be deemed shipped only when the Order has started its transit to the Buyer by the Supplier or its affiliated shipping provider. If requested by VEGSHELF, the Supplier agrees to provide official documentation and/or tracking number verifying that the Order has been shipped out to the Buyer.
            <br /><br />
            9.11.	During Order Fulfillment, the Supplier should use all reasonable efforts to meet the lead time as defined in their Product Listing and/or Store on the Platform. Failing to do that, the Supplier agrees to communicate about any delay with the Buyer immediately through the Platform.
            <br /><br />
            9.12.	Once the Order has been delivered to the Buyer, the Supplier undertakes to update the Order status on the Platform in a timely manner and no longer than within 48 hours. Delivery of the Order can be deemed completed only when the Order has arrived at Buyer’s location and Buyer has signed the delivery note. If requested by VEGSHELF, the Supplier agrees to provide official documentation verifying that the Order has been delivered to the Buyer. Until the moment of Completed Delivery, the products are the responsibility of the Supplier.
            <br /><br />
            9.13.	If Buyer fails to receive the Order, Buyer is responsible for contacting the Supplier and solving the issue with the Supplier directly.
            <br /><br />
            9.14.	After the Completed Delivery, a Complaint Submission Period sets in, and the Buyer may flag any issue with the received Order and/or Products within 72 hours by contacting the Supplier and/or VEGSHELF through the Platform. If there are no issues flagged within the 72 hours, the Complaint Submission Period expires and the Order is deemed successfully completed and is cleared for proceeding with issuing the payout to the Supplier.
            <br /><br />
            9.15.	The Supplier agrees that it is their sole responsibility to resolve the complaints directly with Buyers, by, including but not limited to, making a claim with their shipping provider, offering money back, or re-sending the Order. The Supplier agrees that VEGSHELF has no obligation to intervene at all and the Supplier undertakes to indemnify VEGSHELF from any liability in this regard.
            <br /><br />
            9.16.	VEGSHELF reserves the right to deduct payouts or charge the bank associated with the Supplier’s Account related to Products and/or Order that is reported as damaged or missing.
            <br /><br />
            9.17.	The Supplier is solely responsible for incurring shipping costs and costs related to the Order Fulfillment. Reimbursement in part or all of the actual shipping costs incurred by the Supplier is subject to the applicable limitations as defined in the Supplier’s Account on the Platform. Reimbursed shipping cost sum appears on the Order Invoice.
            <br /><br />
            9.18.	The Supplier indemnifies and holds harmless VEGSHELF from any costs, expenses, liabilities, damages, and losses resulting from a claim from a Buyer or any third party in connection with the Supplier’s use of the Platform and the Service and/or a breach of these Terms by the Supplier.
            <br /><br />
            9.19.	VEGSHELF does not act exclusively for any one Buyer, nor is affiliated with any particular Buyer. Any interactions and communication the Supplier has with the Buyer through the Platform and the Service are solely between the Supplier and the Buyer. VEGSHELF disclaims all liability in connection therewith.
            <br /><br />
            9.20.	Each Member, including Buyers, on the Platform has been vetted by VEGSHELF, including but not limited to verifying their company registration information, email addresses, website, and traction. By doing this VEGSHELF not only ensures the quality of Buyers using the Platform but thus also encourages the Supplier to approve the Buyer’s order requests without requesting any further information from them.
            <br /><br />
            9.21.	VEGSHELF is not and will not be a Party to any contract that may come into existence between the Supplier and the Buyer, or who the Supplier may otherwise get to know through the Platform and the Service, and VEGSHELF will not be held liable for any damages, costs, losses, and claims suffered by the Supplier as a result of the Supplier’s business with any Buyer they have come into contact with through the Platform and the Service.
            <br /><br />
            9.22.	Orders placed by the Buyer are independent of each other and identifiable with a unique Order number.
            <br /><br />
            9.23.	In the event that VEGSHELF becomes aware of the Supplier’s activity (i) encouraging the Buyer to complete purchases outside of the Platform; (ii) enticing ar attempting to entice the Buyer away from the Platform; (iii) infringing laws and regulations applicable to VEGSHELF; (iv) being potentially damaging, anti-commercial, unfair, defamatory or harmful towards VEGSHELF, any Member, the Platform or any third-party, VEGSHELF reserves the right to terminate these Terms immediately.
            <br /><br /><br />

            <b>10. INVOICING, COMMISSION FEE, AND THE PAYOUT</b>
            <br /><br />
            10.1.	The Supplier agrees that invoicing in regard to all Orders will be handled by VEGSHELF in accordance with the following provisions.
            <br /><br />
            10.2.	The Supplier through its Account on the Platform is responsible to provide and ensure that all the legal information that has to appear on the Invoice in regard to the Supplier’s legal obligations and domestic laws is correct and up-to-date.
            <br /><br />
            10.3.	On the date when the Supplier confirms the Order Request, an Invoice for the Order total sum (including taxes and shipping) is prepared by VEGSHELF for and on behalf of the Supplier and is sent to the Supplier and the Buyer on the Platform and/or email. On this date, all the Invoice details are frozen and irrevocable.
            <br /><br />
            10.4. 	When placing an Order, the Order total sum is reserved within the Buyer’s account. The Order Invoice is settled automatically on the date when the Supplier confirms the Order Request and the previously reserved Order total sum (incl. tax and shipping fee) is debited from the Buyer’s bank account.
            <br /><br />
            10.5.	In return for the Service supplied, the Supplier undertakes to pay VEGSHELF a commission fee of 10% for returning Order and 15% for opening Order. The commission fee is calculated from the total Order sum, excluding taxes and shipping costs. The sum of the commission fee is to be increased by any applicable taxes. The commission fee is billed for any Order placed on the Platform. The commission fee paid by the Supplier is not refundable.
            <br /><br />
            10.6.	VEGSHELF reserves the right to adjust the commission fee with at least 60 (sixty) day prior notice to the Supplier. If the Supplier disagrees with the commission fee adjustment, both parties may terminate the Supplier’s Account. If the Supplier does not terminate their Account and uses the Service after a change of the commission fee is effective without expressly declining the commission fee adjustment, it is to be deemed as the Supplier’s acceptance of the changed commission fee.
            <br /><br />
            10.7.	On the date when the Complaint Submission Period expires, the Invoice for the commission fee is prepared by VEGSHELF, and sent to the Supplier on the Platform and/or e-mail. On this date, all the Invoice details are frozen and irrevocable. The commission fee Invoice is being settled by the Supplier automatically on the date when the Complaint Submission Period expires and the Supplier payout sum is calculated by deducting the commission fee (incl. tax) from the total Order sum (incl. tax and shipping fee) and the Supplier’s payout is being processed.
            <br /><br />
            10.8.	The speed of which the Supplier receives its payout depends on the Supplier’s bank, however, it shall not be longer than 14 (fourteen) working days from the date when the Complaint Submission Period has expired.
            <br /><br />
            10.9.	The Supplier receives the whole amount of the Order Invoice (incl. tax and shipping fee) to its bank account, minus the amount of the commission fee Invoice (incl. tax) payable to VEGSHELF.
            <br /><br />
            10.10.	Funds payable to the Supplier in regard to the Invoices are kept on the Supplier’s Stripe (VEGSHELF payment service partner) account that is set up by the Supplier as part of the onboarding, prior to being paid by Stripe to the Supplier. The funds payable to the Supplier in regard to Invoices are never to be in possession of VEGSHELF or in VEGSHELF’s control.
            <br /><br /><br />

            <b>11. PROHIBITED USES</b>
            <br /><br />
            11.1.	The Visitor and the Supplier has a duty to act in good faith and respectfully in their relationships with VEGSHELF and other Visitors and Members. The Visitor and the Supplier undertake to refrain from doing any of the following:
            <br /><br />
            11.1.1.	Avoid or attempt to bypass any measures VEGSHELF may use to prevent or restrict access to the Platform and/or the Service;
            <br /><br />
            11.1.2.	Embed, display, mirror, use or frame the Platform, or any individual element within the Platform or the Service, VEGSHELF name, trademark and/or logo, the Platform layout, element and/or design of any page without prior written consent from VEGSHELF;
            <br /><br />
            11.1.3.	Use any kind of manual or automated software, data-mining or other tools to "crawl" or “spider” any page of the Platform, or collect or extract data from the Platform or the Service;
            <br /><br />
            11.1.4.	Scan, test or probe the vulnerability of any VEGSHELF system or network or breach any authentication or security measures;
            <br /><br />
            11.1.5.	Decompile, disassemble, reverse engineer, or otherwise attempt to obtain any of the source code, underlying ideas, information, software, or algorithms used to provide the Platform, Service, and Content;
            <br /><br />
            11.1.6.	Use any meta tags, metadata, or other hidden text using VEGSHELF trademark, logo, URL, or Product name without written consent from VEGSHELF;
            <br /><br />
            11.1.7.	Store or collect any information from or of other Members and/or Visitors, by which they may be identifiable, from the Platform and the Service without prior written consent from them and in a manner that does not violate Privacy Policy of VEGSHELF or relevant privacy laws;
            <br /><br />
            11.1.8.	Harvest any Content from the Platform or the Services without prior written consent from VEGSHELF;
            <br /><br />
            11.1.9.	Interfere with or aim to interfere with proper workings of the Platform and the Service and/or the access of any Member, Visitor, network, host, including but not limited to sending a virus, flooding, spamming, overloading the Platform and the Service;
            <br /><br />
            11.1.10. Bypass any measures VEGSHELF has put in place to secure the Platform and the Service;
            <br /><br />
            11.1.11. Submit, upload, download, post, or otherwise distribute any Content on the Platform or through the Service that: i) breaches and infringes any trademark, patent, trade secret, copyright, right of publicity, or other rights of another person or entity and/or violates any law or legal duty; ii) is imprecise, false, ambiguous, inaccurate, or untruthful; iii) impersonates any person or entity and related sensitive information; iv) creates unsolicited or unauthorized advertising or promotional material, spam, junk mail, or chain letters; v) contains software viruses, worms, files, Trojan horses, other malicious programs or any other form of malware intended to disrupt and damage the proper function of hardware or software, or to damage or obtain unauthorized access to any data, password, or other information of VEGSHELF, Members or of any third party using the Platform and the Service; vi) is fraudulent, unlawful, abusive, obscene, pornographic, vulgar, offensive, or is inappropriate in any other way as determined by VEGSHELF at its exclusive discretion; vii) promotes discrimination, bigotry, hatred, racism, or harassment against any individual or group; viii) promotes illegal or harmful activities or substances.
            <br /><br />
            11.1.12. Misrepresent or impersonate any other person or legal entity, including Members, and VEGSHELF employees;
            <br /><br />
            11.1.13. Try to damage or get unauthorized access to any system, the Account, log in and/or password, data, or other information in relation to the Platform, whether it belongs to VEGSHELF, any Member or other Party;
            <br /><br />
            11.1.14. Create cognate works of a full or separate part of the Platform and/or the Service;
            <br /><br />
            11.1.15. Copy, lease, or distribute the rights that the Supplier and/or the Visitor receives while using the Platform and/or the Service;
            <br /><br />
            11.1.16. Take any action that imposes an unreasonable load on VEGSHELF infrastructure, as determined by VEGSHELF at its sole discretion;
            <br /><br />
            11.1.17. Seek, ask, invite or seduce other Members to circumvent and do business with other Members outside of the Platform and/o Service;
            <br /><br />
            11.1.18. Obtain or try to obtain the payment without delivering the Order to the Buyer;
            <br /><br />
            11.1.19. Use the Platform, the Services, and the Content in any way prohibited by these Terms and/or encourage and/or enable any third-party to do any of the things mentioned above.
            <br /><br />
            11.2.	If it becomes known to the Supplier or the Visitor that these Terms are violated by any other Member or Visitor, they agree to immediately notify VEGSHELF by sending an e-mail to <a href="mailto:hello@vegshelf.com" target="_blank">hello@vegshelf.com</a>.
            <br /><br />
            11.3.	The Visitor and the Supplier are liable for any damages or costs sustained by VEGSHELF due to their infringement of these Terms.
            <br /><br />
            11.4.	VEGSHELF reserves the right to investigate and prosecute violations of these Terms to the fullest extent of the law. VEGSHELF reserves the right to involve, cooperate, share information with law enforcement authorities, government agencies, or other oversight bodies for the prosecution of the Visitor or the Supplier who violate these Terms.
            <br /><br />
            11.5.	Parties agree that VEGSHELF’s recordings on VEGSHELF’s or its third-party servers related to the use of the Platform and the Service, connection and activity logs, any documents, e-mails, and communication exchanged on the Platform or in the context of these Terms, is considered as proof between the Parties.
            <br /><br /><br />

            <b>12. MARKETING OF THE PLATFORM AND LINKS TO THIRD PARTY WEBSITES</b>
            <br /><br />
            12.1.	The Supplier agrees not to provide links to any external website in their Product Listings or the Store, unless explicitly stated otherwise in these Terms or by the guidelines on the Platform, that may violate any of these Terms. VEGSHELF reserves the right to remove links from the Platform without prior notice to the Supplier.
            <br /><br />
            12.2.	The Supplier may link VEGSHELF homepage (<a href="https://vegshelf.com/" target="_blank">www.vegshelf.com</a>) to advertise their Account or the Platform and the Service to their audience, Visitors, and other Members, and provided the Supplier does so only in a way that is fair, legal, and does not damage VEGSHELF reputation.
            <br /><br />
            12.3.	The Supplier agrees not to use “spam” or unnecessarily repetitive information to market or sell Products to any Buyer on the Platform and through the Service.
            <br /><br />
            12.4.	The Platform and the Service may contain links to third-party websites or resources. The Visitor and the Supplier agree that VEGSHELF is not liable or responsible for the products, services, or content of such websites, as well as the availability or correctness of such websites or resources. The Visitor and the Supplier undertake sole responsibility and assume all risks arising from their use of any such websites or resources.
            <br /><br /><br />

            <b>13. ELECTRONIC COMMUNICATIONS</b>
            <br /><br />
            13.1.	The Visitor and the Supplier agree and consent to receive communication from VEGSHELF electronically. VEGSHELF will communicate with the Visitor and the Supplier by e-mail, phone, or by posting notices on the Platform and/or through the Service. The Visitor and the Supplier agree that all agreements, notices, and other communications that VEGSHELF provides to them electronically satisfy any legal requirements that such communications be in writing.
            <br /><br /><br />

            <b>14. PRIVACY AND PERSONAL DATA</b>
            <br /><br />
            14.1.	VEGSHELF obtains and handles data relating to identified or identifiable individuals for the purpose of carrying out the Services and managing relationships with Visitors and Suppliers.
            <br /><br />
            14.2.	VEGSHELF processes the Visitor’s and the Supplier’s personal data in accordance with GDPR as detailed in VEGSHELF’s Privacy Policy, which is available on the Platform and is incorporated in these Terms.
            <br /><br /><br />

            <b>15. EFFECTIVE DATE OF THE TERMS</b>
            <br /><br />
            15.1.	For the Supplier these Terms come into force on the Supplier’s registration on an indefinite term basis until the contractual relationship is terminated in accordance with article 16 of these Terms.
            <br /><br /><br />

            <b>16. TERMINATION AND DELETION OF THE ACCOUNT</b>
            <br /><br />
            16.1.	VEGSHELF holds the right to terminate or disable the Supplier’s Account and suspend the Supplier’s access to the Platform and the Services at any time, with or without notice, and for any reason at VEGSHELF discretion, including the Supplier’s breach of these Terms. VEGSHELF holds the sole right to decide whether the Supplier is in violation of any of the restrictions set forth in these Terms. Termination of the Account may result in the destruction of all information associated with the Supplier’s Account.
            <br /><br />
            16.2.	If for any reason the Supplier wishes to terminate or delete their Account, they may do so at any time by contacting VEGSHELF at <a href="mailto:hello@vegshelf.com" target="_blank">hello@vegshelf.com</a>. Fees paid hereunder are non-refundable. When the Supplier’s Account is deleted, it will be removed from the Platform and the Services. However, the Supplier’s Content may persist in backup copies and on the Platform for a reasonable period of time as determined by VEGSHELF and according to applicable laws.
            <br /><br />
            16.3.	Provisions that, by nature should survive termination of these Terms shall survive termination. By way of example, all of the following will survive termination: any obligation the Supplier has to pay or indemnify to VEGSHELF, any limitations on VEGSHELF liability, any part of these Terms regarding ownership of intellectual property rights, and Terms regarding disputes between VEGSHELF and the Supplier.
            <br /><br /><br />

            <b>17. WARRANTY DISCLAIMER AND LIABILITY</b>
            <br /><br />
            17.1.	The Supplier shall be solely liable for the Products it has sold and delivered to the Buyer or any of the Supplier’s warranties made in that respect through the Platform and/or the Service.
            <br /><br />
            17.2.	VEGSHELF does not make any representations or warranties concerning any Content contained in or accessed through the Platform and the Service, and will not be responsible or liable for the completeness, safety, accuracy, quality, copyright compliance, legality, or reliability of the Content contained in or accessed through the Services. VEGSHELF makes no representations or warranties regarding suggestions or recommendations of the Products offered or purchased through the Platform and the Service.
            <br /><br />
            17.3.	To the fullest extent permitted by applicable law, the liability of VEGSHELF for contractual breaches of duty and tort shall be limited to intent and gross negligence. This does not apply in cases of injuries to life, limb, health, and claims due to delay as well as claims due to the breach of essential contractual obligations, i.e. obligations arising from the nature of the contract and the breach of which endangers the achievement of the purpose of the contract. In this respect, VEGSHELF is liable for every degree of fault. In the event of a slightly negligent breach of essential contractual obligations, VEGSHELF shall only be liable for the typically foreseeable damage, whereas that liability shall not exceed the total of the fees paid to VEGSHELF by the Supplier over the entire term of the contractual relationship. In all other respects, no liability exists. Claims arising from the Product Liability Act shall remain unaffected.
            <br /><br />
            17.4.	To the fullest extent allowed by applicable law, the Supplier agrees to indemnify and hold VEGSHELF, its affiliates, agents, employees, and partners harmless from and against any and all claims, liabilities, damages (actual and consequential), losses, and expenses (including attorneys’ fees and other legal costs) arising from or in any way related to any third party claims relating to (i) the Supplier’s misuse of the Platform and the Service (including any actions taken by a third party using the Supplier’s Account due to at least negligent loss of log-in data by the Supplier), and (ii) the Supplier’s violation of these Terms.
            <br /><br />
            17.5.	The Supplier agrees to compensate VEGSHELF in a timely manner for any reasonably and properly incurred expenses (including expenses of attorneys) as they are incurred by VEGSHELF in regard to investigating, preparing for, or providing evidence in any pending or threatened action or claim brought under due to (i) the Supplier’s misuse of the Services (including any actions taken by a third party using the Supplier’s Account), and (ii) the Supplier’s violation of these Terms.
            <br /><br /><br />

            <b>18. ASSIGNMENT</b>
            <br /><br />
            18.1.	The Supplier is not allowed to assign, transfer, sublicense or delegate their rights or obligations hereunder, or their Account to any other third party, in any way without prior written consent by VEGSHELF.
            <br /><br />
            18.2.	VEGSHELF may freely transfer, assign, delegate or otherwise dispose of all or part of its rights, obligations, or these Terms without consent.
            <br /><br /><br />

            <b>19. DISPUTE RESOLUTION AND GOVERNING LAW; JURISDICTION</b>
            <br /><br />
            19.1.	VEGSHELF encourages Suppliers and Visitors to contact VEGSHELF if they are having an issue, before resorting to the courts. In the unfortunate situation where legal action does arise, these Terms and all other rules and policies incorporated by reference will be governed by and construed in accordance with the laws of the Federal Republic of Germany.
            <br /><br />
            19.2.	If the Supplier or the Visitor is a merchant according to German law, a legal entity under public law or a special fund under public law, the place of jurisdiction shall be VEGSHELF registered office. However, VEGSHELF shall also be entitled to sue at the court of the Supplier’s place of residence.
            <br /><br />
            19.3.	Any disputes between Members shall be settled between the relevant Members without VEGSHELF intervening in the settlement of or to settle such disputes.
            <br /><br /><br />

            <b>20. MISCELLANEOUS</b>
            <br /><br />
            20.1.	If any provision of these Terms is found to be invalid, unenforceable, or ineffective, that provision will be limited or eliminated to the minimum extent necessary, so these Terms shall otherwise remain in full force, effect and enforceable.
            <br /><br />
            20.2.	Legally relevant declarations and notifications regarding this contractual relationship must be made in writing or in electronic text.
          </p>
        </div>
      </div>
    );
  }
};

SupplierTermsPageComponent.defaultProps = {
  currentUserListing: null,
  currentUserListingFetched: false,
};

SupplierTermsPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};
const mapStateToProps = state => {
  const { currentUser } = state.user;
  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser
  };
};

const SupplierTermsPage = compose(
  withRouter,
  connect(mapStateToProps),
  injectIntl
)(SupplierTermsPageComponent);

export default SupplierTermsPage;