import classNames from "classnames";
import { bool, object } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import facebookImage from "../../assets/VegshelfFacebook-1200x630.png";
import twitterImage from "../../assets/VegshelfTwitter-600x314.png";
import addProductIconWhite from "../../assets/plus-ffffff.svg";
import {
  Footer,
  InlineTextButton,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Menu,
  MenuContent,
  MenuItem,
  MenuLabel,
  NamedLink,
  Page,
  ProductContainer,
} from "../../components";
import config from "../../config";
import { TopbarContainer } from "../../containers";
import {
  parseListingState,
  supplierCreateCompany,
  supplierDeleteCompanyCover,
  supplierUpdateCompany,
  supplierUpdateCompanyCover,
  supplierUpdateCompanyLogo,
} from "../../ducks/supplier-company.duck";
import { isScrollingDisabled } from "../../ducks/UI.duck";
import { SupplierCompanyForm } from "../../forms";
import { FormattedMessage, injectIntl, intlShape } from "../../util/reactIntl";
import { propTypes } from "../../util/types";
import { pathByRouteName } from "../../util/routes";
import routeConfiguration from "../../routeConfiguration";
import { localePart, storedLocale } from "../../components/Wrapper/ClassWrapper";
import css from "./SupplierProfilePage.module.css";

// Check if there's requirements on selected type: 'past_due', 'currently_due' etc.
const hasRequirements = (stripeAccountData, requirementType) =>
  stripeAccountData != null &&
  stripeAccountData.requirements &&
  Array.isArray(stripeAccountData.requirements[requirementType]) &&
  stripeAccountData.requirements[requirementType].length > 0;

export const shippedCountriesMsg = (countries) => {
  return countries.reduce((acc, curr) => {
    const firstCountrySeparator = !acc ? "" : ", ";
    if (curr.isShip) {
      acc = acc + `${firstCountrySeparator}${curr.name}`;
    }

    return acc;
  }, "");
};

export class SupplierProfilePageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { viewLastIndex: 6, displayUpdateForm: false, displaySetupPayoutModal: false };
    this.newSupplier = null;
    this.coverFileSelector = React.createRef();
    this.avatarFileSelector = React.createRef();
    this.atLeastOneShippingCountry = this.props.supplierCurrentCompany?.attributes?.publicData?.shippingFeeCountries
      ?.find((country) => country.isShip);
    this.onCoverChange = this.onCoverChange.bind(this);
    this.onAvatarChange = this.onAvatarChange.bind(this);
    this.onDeleteCoverImage = this.onDeleteCoverImage.bind(this);
    this.onShowAllClick = this.onShowAllClick.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onAddProduct = this.onAddProduct.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.supplierUpdateCompanyInProgress !== this.props.supplierUpdateCompanyInProgress
      && nextProps.supplierUpdateCompany) {
      this.setState({ displayUpdateForm: false });
    }
  }

  onSubmit(values) {
    this.props.onSupplierUpdateCompany({ ...values });
  }

  onAddProduct(stripeConnected) {
    const addProductPageName = "SupplierAddProductPage";
    const locale = storedLocale() || localePart();
    const params = {locale}
    const addProductPagePath = pathByRouteName(addProductPageName, routeConfiguration(), params)
    if (stripeConnected && this.atLeastOneShippingCountry) {
      //this.props.history.push("/supplier/add-product");
      this.props.history.push(addProductPagePath);
    } else {
      this.setState({ displaySetupPayoutModal: true });
    }
  }

  onShowAllClick() {
    this.setState({ ...this.state, viewLastIndex: this.state.viewLastIndex + 6 });
  }

  onCoverChange(event) {
    event.stopPropagation();
    event.preventDefault();
    const file = event.target.files[0];
    if (!file) {
      return;
    }
    const tempId = `${file.name}_${Date.now()}`;
    this.props.onSupplierUpdateCompanyCover({ id: tempId, file });
  }

  onAvatarChange(event) {
    event.stopPropagation();
    event.preventDefault();
    const file = event.target.files[0];
    if (!file) {
      return;
    }
    const tempId = `${file.name}_${Date.now()}`;
    this.props.onSupplierLogo({ id: tempId, file });
  }

  onDeleteCoverImage(event) {
    event.stopPropagation();
    event.preventDefault();
    this.props.onSupplierDeleteCompanyCover();
  }

  render() {
    const { intl, scrollingDisabled, supplierCurrentCompany, supplierCompanyProducts, stripeAccount } = this.props;
    const siteTitle = config.siteTitle;
    const companyData = supplierCurrentCompany?.attributes;
    const companyDetails = companyData?.publicData;
    const shippingCountries = shippedCountriesMsg(companyData?.publicData?.shippingFeeCountries || []);
    const schemaTitle = intl.formatMessage({ id: "SupplierProfilePage.schemaTitle" }, { siteTitle });
    const schemaDescription = intl.formatMessage({ id: "SupplierProfilePage.schemaDescription" }, { siteTitle });
    const schemaImage = `${config.canonicalRootURL}${facebookImage}`;
    const coverStyles = {};
    const companyLogo = {};
    const hasLogo = !!companyDetails?.logoUrl;
    if (companyDetails?.coverUrl) {
      coverStyles.backgroundImage = `url('${companyDetails?.coverUrl}')`;
    } else {
      coverStyles.backgroundColor = `#f8f8e8`;
    }
    if (companyDetails?.logoUrl) {
      companyLogo.backgroundImage = `url('${companyDetails?.logoUrl}')`;
    } else {
      companyLogo.backgroundColor = `#ffffff`;
    }
    let products = [...(supplierCompanyProducts || [])];
    products = [
      ...products.filter(p => (parseListingState(p) === "published")),
      ...products.filter(p => (parseListingState(p) === "outOfStock")),
      ...products.filter(p => (parseListingState(p) === "pending")),
      ...products.filter(p => (parseListingState(p) === "draft")),
    ];
    const showExpandButton = this.state.viewLastIndex < products.length;
    products = products.slice(0, this.state.viewLastIndex);
    const newLabelCount = 3 - products.length;
    for (let i = 0; i < newLabelCount; i++) {
      products.push({ newButton: true, id: { uuid: i } });
    }
    const stripeAccountData = stripeAccount?.attributes?.stripeAccountData;
    const requirementsMissing = stripeAccount
      && (hasRequirements(stripeAccountData, "past_due") ||
        hasRequirements(stripeAccountData, "currently_due"));
    const stripeConnected = !!stripeAccount && !!stripeAccount.id && !requirementsMissing;

    const emptyStateOptions = {
      containerOffsetClassName: "searchOffset",
      iconStylesClassName: "searchIcon",
      titleMessageId: "SearchProductsPage.noSearchResultTitle",
      textMessageId: "SearchProductsPage.noSearchResultText",
    };

    const setupPaymentSettingsBtnText = intl.formatMessage({ id: "StripePayoutPage.setupPaymentSettingsBtnText" });

    return (
      <Page
        className={css.root}
        scrollingDisabled={scrollingDisabled}
        contentType="website"
        description={schemaDescription}
        title={schemaTitle}
        robots='noindex'
        facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
        twitterImages={[
          { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
        ]}
        schema={{
          "@context": "http://schema.org",
          "@type": "WebPage",
          description: schemaDescription,
          name: schemaTitle,
          image: [schemaImage],
        }}
      >
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div className={css.profileCompanyContainer}>
              <input
                id="coverFileSelector"
                type="file"
                ref={(ref) => this.coverFileSelector = ref}
                style={{ display: "none" }}
                onChange={this.onCoverChange}
                accept="image/*"
              />
              <input
                id="avatarFileSelector"
                type="file"
                ref={(ref) => this.avatarFileSelector = ref}
                style={{ display: "none" }}
                onChange={this.onAvatarChange}
                accept="image/*"
              />
              <div className={css.coverContainer}>
                <div className={css.coverPhotoContainer} style={coverStyles}>
                  {supplierCompanyProducts?.length >= 1 ? null :
                  (!stripeConnected || !this.atLeastOneShippingCountry ? (
                    <div className={css.stripeNotConnected}>
                      <NamedLink name="SupplierProfilePayoutDetailsPage">
                        {setupPaymentSettingsBtnText}
                      </NamedLink>
                    </div>
                  ) : null)}
                </div>
              </div>
              <div className={css.editingContainer}>
                <div className={css.editCoverPanel}>
                  <Menu>
                    <MenuLabel className={css.coverMenuLabel}>
                      <a className={css.editCoverLink}>
                        <div className={css.updateImageButton}>Change</div>
                      </a>
                    </MenuLabel>
                    <MenuContent className={css.profileMenuContent}>
                      <MenuItem key="selectCoverImage">
                        <InlineTextButton
                          rootClassName={css.menuItemFirst}
                          onClick={() => {
                            this.coverFileSelector.click();
                          }}
                        >
                          <FormattedMessage id="UserFields.coverPhotoUpload" />
                          <div className={css.menuItemFirstHelperText}>
                            <FormattedMessage id="SupplierProfilePage.changeCoverImageInfo" />
                          </div>
                        </InlineTextButton>
                      </MenuItem>
                      <MenuItem key="DeleteCoverImage">
                        <InlineTextButton
                          rootClassName={css.menuItemSecond}
                          onClick={(e) => {
                            this.onDeleteCoverImage(e);
                          }}
                        >
                          <FormattedMessage id="UserFields.coverPhotoDelete" />
                        </InlineTextButton>
                      </MenuItem>
                    </MenuContent>
                  </Menu>
                </div>
              </div>
              <div className={css.profileCompanySection}>
                <div className={css.companyInfoPanel}>
                  <div className={css.companyInfoFirstColumn}>
                    <div className={css.companyLogo} style={companyLogo}>
                      <div className={classNames(css.editCompanyLogoPanel, { [css.hasLogo]: hasLogo })}>
                        <a
                          className={classNames(css.editCompanyLogoLink, { [css.hasLogo]: hasLogo })}
                          onClick={() => {
                            this.avatarFileSelector.click();
                          }}
                        >
                          <div className={classNames(css.updateImageButtonPlain, { [css.hasLogo]: hasLogo })}>Change</div>
                          <FormattedMessage id="UserFields.logoUpload" />
                        </a>
                      </div>
                    </div>
                    <div className={css.companyName}>{companyDetails?.brandName}</div>
                    <div className={css.companyLocation}>{companyDetails?.city}, {companyDetails?.country}</div>
                    <div className={css.companyPropertyRowContainer}>
                      <div className={css.companyPropertyRow}>
                        <span className={css.companyFieldLabel}>
                          <FormattedMessage id="SupplierProfilePage.LabelLeadTime" />
                        </span>
                        <span className={css.companyFieldValue}>{companyDetails?.leadTime}</span>
                        <span className={css.companyFieldValueShipping}>{shippingCountries && ` to ${shippingCountries}`}</span>
                      </div>
                      <div className={css.companyPropertyRow}>
                        <span className={css.companyFieldLabel}>
                          <FormattedMessage id="SupplierProfilePage.LabelMOQ" />
                        </span>
                        <span className={css.companyFieldValue}>€{companyDetails?.moq}</span>
                      </div>
                      <div className={css.companyPropertyRow}>
                        <span className={css.companyFieldLabel}>
                          <FormattedMessage id="UserFields.numberOfStockistsColon" />
                        </span>
                        <span className={css.companyFieldValue}>{companyDetails?.numberOfStores}</span>
                      </div>
                      <div className={css.companyPropertyRow}>
                        <span className={css.companyFieldLabel}>
                          <FormattedMessage id="UserFields.yearEstablishedColon" />
                        </span>
                        <span className={css.companyFieldValue}>{companyDetails?.establishedIn}</span>
                      </div>
                      <div className={css.companyPropertyRow}>
                        <span className={css.companyFieldLabel}>
                          <FormattedMessage id="UserFields.instagramColon" />
                        </span>
                        <a href={`https://www.instagram.com/${companyDetails?.instagram}`} target="_blank">
                          <span className={css.companyFieldValueIG}>{companyDetails?.instagram}</span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className={css.companyInfoSecondColumn}>
                    <div className={css.editCompanyInfo}>
                      <a className={css.editCompanyLink} onClick={() => this.setState({ displayUpdateForm: true })}>
                        <div className={css.editCompanyButton}>Change</div>
                        <FormattedMessage id="SupplierProfilePage.editCompanyInfoLink" />
                      </a>
                      <a
                        name="SupplierAddProductPage"
                        onClick={() => this.onAddProduct(stripeConnected)}
                        className={css.addProductButton}
                      >
                        <img className={css.addProductIconWhite} src={addProductIconWhite} />
                        <FormattedMessage id="SupplierProfilePage.LabelAddNewProduct" />
                      </a>
                    </div>
                    <div className={css.companyDescriptionLabel}>
                      <FormattedMessage id="SupplierProfilePage.LabelCompanyDescription" />
                    </div>
                    <div className={css.companyDescriptionValue}>
                      {companyDetails?.description || (<span className={css.emptyDescriptionPlaceholder}><FormattedMessage
                        id="SupplierProfilePage.EmptyCompanyDescription" /></span>)}
                    </div>
                  </div>
                </div>
                <div className={css.productContainer}>
                  <ProductContainer
                    products={products}
                    rowItemCount={3}
                    showState={true}
                    showEditButton={true}
                    onAddClick={() => this.onAddProduct(stripeConnected)}
                    emptyStateOptions={emptyStateOptions}
                  />
                </div>
                {showExpandButton ? (
                  <div className={css.showAllProduct}>
                    <a onClick={this.onShowAllClick} className={css.showAllButton}>
                      <FormattedMessage className={css.addProductButtonLabel} id="SupplierProfilePage.showAll" />
                    </a>
                  </div>
                ) : null}
                {this.renderUpdateForm()}
                {this.renderConfirmPayout(intl)}
              </div>
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }

  renderUpdateForm() {
    const { supplierCurrentCompany, supplierUpdateCompanyInProgress } = this.props;
    const { displayUpdateForm } = this.state;
    return (displayUpdateForm && (
      <div className={css.previewDialogContainer}>
        <div className={css.previewDialog}>
          <div className={css.dialogHeader}>
            <button className={css.closeButtonInfoModal} onClick={() => this.setState({ displayUpdateForm: false })} />
            <h1 className={css.previewDialogTitle}>
              <FormattedMessage id="UserFields.editCompanyInfo" />
            </h1>
          </div>
          <div className={css.previewDialogContent}>
            <SupplierCompanyForm
              currentCompany={supplierCurrentCompany}
              inProgress={supplierUpdateCompanyInProgress}
              onSubmit={this.onSubmit}
            />
          </div>
        </div>
      </div>
    ));
  }

  renderConfirmPayout(intl) {
    const { displaySetupPayoutModal } = this.state;
    const setupPaymentSettingsTitleMsg = intl.formatMessage({ id: "StripePayoutPage.setupPaymentSettings" });
    const setupPaymentSettingsDescriptionMsg = intl.formatMessage({ id: "StripePayoutPage.setupPaymentSettingsDescription" });
    const setupPaymentSettingsBtnText = intl.formatMessage({ id: "StripePayoutPage.setupPaymentSettingsBtnText" });
    const setupPaymentSettingsButton = intl.formatMessage({ id: "StripePayoutPage.setupPaymentSettingsButton" });

    return (displaySetupPayoutModal && (
      <div className={css.previewDialogContainer}>
        <div className={css.previewSmallDialog}>
          <div className={css.dialogHeader}>
            <button className={css.closeButtonPayoutModal} onClick={() => this.setState({ displaySetupPayoutModal: false })} />
            <h1 className={css.previewDialogTitle}>{setupPaymentSettingsTitleMsg}</h1>
          </div>
          <div className={css.previewDialogContent}>
            <p>{setupPaymentSettingsDescriptionMsg}</p>
            <NamedLink className={css.setupPayoutButton} name="SupplierProfilePayoutDetailsPage">
              {setupPaymentSettingsButton}
            </NamedLink>
            <a
              className={css.setupPayoutGoBackButton}
              onClick={() => this.setState({ displaySetupPayoutModal: false })}
            >
              <FormattedMessage id="SupplierProfilePage.goBack" />
            </a>
          </div>
        </div>
      </div>
    ));
  }
}

SupplierProfilePageComponent.defaultProps = {
  currentUserListing: null,
  currentUserListingFetched: false,
};

SupplierProfilePageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
  // from withRouter
  history: object.isRequired,
  location: object.isRequired,
  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = (state) => {
  const { currentUser } = state.user;
  const { image, uploadImageError, uploadInProgress } = state.SupplierProfilePage;
  const {
    supplierCurrentCompany,
    supplierCompanyProducts,
    supplierUpdateCompany,
    supplierUpdateCompanyInProgress,
  } = state.supplierCompany;
  const { stripeAccount } = state.stripeConnectAccount;

  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
    image,
    uploadImageError,
    uploadInProgress,
    supplierCurrentCompany,
    supplierCompanyProducts,
    stripeAccount,
    supplierUpdateCompany,
    supplierUpdateCompanyInProgress,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onSupplierCreateCompany: (data) => dispatch(supplierCreateCompany(data)),
  onSupplierLogo: (values) => dispatch(supplierUpdateCompanyLogo(values)),
  onSupplierUpdateCompanyCover: (values) => dispatch(supplierUpdateCompanyCover(values)),
  onSupplierDeleteCompanyCover: (values) => dispatch(supplierDeleteCompanyCover(values)),
  onSupplierUpdateCompany: (data) => dispatch(supplierUpdateCompany(data)),
});

const SupplierProfilePage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
)(SupplierProfilePageComponent);

export default SupplierProfilePage;
