import React, { Component } from "react";
import { compose } from "redux";
import { FormattedMessage, injectIntl, intlShape } from "../../util/reactIntl";
import css from "./BuyerBillingDetailsForm.module.css";
import { Form as FinalForm } from "react-final-form";
import { FieldSelect, FieldTextInput, Form, PrimaryButton } from "../../components";
import PropTypes from "prop-types";
import { LabelWithErrorMsg } from "../ContactDetailsForm/ContactDetailsForm";
import classNames from 'classnames';
import getCountryCodes from "../../translations/countryCodes";
import * as validators from "../../util/validators";

class BuyerBillingDetailsFormComponent extends Component {
  render() {
    return (
      <FinalForm
        {...this.props}
        render={(renderProps) => {
          const {
            billingDetails,
            billingDetailsUpdateInProgress,
            intl,
            handleSubmit,
            form,
            hasValidationErrors,
            hasSubmitErrors,
            dirtySinceLastSubmit,
          } = renderProps;

          const {
            companyLegalName,
            companyRegistrationNumber,
            streetAndNumber,
            city,
            postcode,
            country,
            companyVATnumber,
            companyRCSnumber,
            companyShareCapital,
          } = billingDetails;

          // ================ general ================ //
          const pageName = "BuyerSettingsPage";
          const getInputFormattedMessage = (name) => intl.formatMessage({ id: `${pageName}.${name}` });
          const requiredFieldFormattedMessage = getInputFormattedMessage("requiredField");
          const requiredField = validators.required(requiredFieldFormattedMessage);
          const hasError = (field) => form.getState().touched[field] && form.getState().errors.hasOwnProperty(field) || false;
          const submitDisabled = hasValidationErrors
            || (hasSubmitErrors && !dirtySinceLastSubmit)
            || billingDetailsUpdateInProgress;
          const isFrenchCompany = country === intl.formatMessage({ id: "StripePayoutPage.shipCountry.France" });

          // ================ companyLegalName ================ //
          const companyLegalNameInputId = "companyLegalName";
          const companyLegalNameLabel = getInputFormattedMessage("companyLegalNameLabel");
          const companyLegalNamePlaceholder = getInputFormattedMessage("companyLegalNamePlaceholder");
          const isCompanyLegalNameError = hasError(companyLegalNameInputId);

          // ================ companyRegistrationNumber ================ //
          const companyRegistrationNumberInputId = "companyRegistrationNumber";
          const companyRegistrationNumberLabel = getInputFormattedMessage("companyRegistrationNumberLabel");
          const companyRegistrationNumberPlaceholder = getInputFormattedMessage("companyRegistrationNumberPlaceholder");
          const isCompanyRegistrationNumberError = hasError(companyRegistrationNumberInputId);

          // ================ VAT ================ //
          const VATLabel = getInputFormattedMessage("VATLabel");
          const countryVATInputId = "countryVAT";
          const companyVATInputId = "companyVAT";
          const companyVATPlaceholder = getInputFormattedMessage("companyVATPlaceholder");
          const isCompanyVATError = hasError(companyVATInputId);

          // ================ streetAndNumber ================ //
          const streetAndNumberInputId = "streetAndNumber";
          const streetAndNumberLabel = getInputFormattedMessage("streetAndNumberLabel");
          const streetAndNumberPlaceholder = getInputFormattedMessage("streetAndNumberPlaceholder");
          const isStreetAndNumberError = hasError(streetAndNumberInputId);

          // ================ companyRCSnumber ================ //
          const companyRCSnumberInputID = "companyRCSnumber";
          const companyRCSnumberLabel = getInputFormattedMessage("companyRCSnumberLabel");
          const companyRCSnumberPlaceholder = getInputFormattedMessage("companyRCSnumberPlaceholder");
          const companyRCSnumberError = hasError(companyRCSnumberInputID);

          // ================ companyShareCapital ================ //
          const companyShareCapitalInputID = "companyShareCapital";
          const companyShareCapitalLabel = getInputFormattedMessage("companyShareCapitalLabel");
          const companyShareCapitalPlaceholder = getInputFormattedMessage("companyShareCapitalPlaceholder");
          const companyShareCapitalError = hasError(companyShareCapitalInputID);

          // ================ city ================ //
          const cityInputId = "city";
          const cityLabel = getInputFormattedMessage("cityLabel");
          const cityPlaceholder = getInputFormattedMessage("cityPlaceholder");
          const isCityError = hasError(cityInputId);

          // ================ postcode ================ //
          const postcodeInputId = "postcode";
          const postcodeLabel = getInputFormattedMessage("postcodeLabel");
          const postcodePlaceholder = getInputFormattedMessage("postcodePlaceholder");
          const isPostCodeError = hasError(postcodeInputId);

          // ================ country ================ //
          const countryInputId = "country";
          const countryLabel = getInputFormattedMessage("countryLabel");
          const countryPlaceholder = getInputFormattedMessage("countryPlaceholder");

          // TODO: investigate potential validator appraoch //
          /*
          const submitDisabled = isCompanyLegalNameError 
          || isCompanyRegistrationNumberError
           || isCompanyVATError
            || isStreetAndNumberError
             || companyRCSnumberError
              || companyShareCapitalError
               || isCityError
                || isPostCodeError;
                
          const isAnyFieldError = isCompanyLegalNameError 
          || isCompanyRegistrationNumberError
           || isCompanyVATError
            || isStreetAndNumberError
             || companyRCSnumberError
              || companyShareCapitalError
               || isCityError
                || isPostCodeError;

          const submitDisabled = billingDetailsUpdateInProgress || isAnyFieldError;
                */


          return (
            <Form className={css.billingDetailsContainer} onSubmit={handleSubmit}>
              <h1 className={css.billingDetailsTitle}>
                {getInputFormattedMessage("paymentTabTitle")}
              </h1>
              <h2 className={css.billingDetailsSubtitle}>
                <FormattedMessage id="BuyerSettingsPage.paymentTabSubtitle" />
              </h2>
              <div className={css.billingForm}>
                <div className={classNames(css.formField, css.maxFormWidth)}>
                  <LabelWithErrorMsg
                    htmlFor={companyLegalNameInputId}
                    labelText={companyLegalNameLabel}
                    isRequired
                    errorMsg={requiredFieldFormattedMessage}
                    isError={isCompanyLegalNameError}
                  >
                    <FieldTextInput
                      type="text"
                      className={classNames(css.textInput, { [css.invalidInputValue]: isCompanyLegalNameError })}
                      name={companyLegalNameInputId}
                      id={companyLegalNameInputId}
                      placeholder={companyLegalNamePlaceholder}
                      defaultValue={companyLegalName}
                      validate={requiredField}
                    />
                  </LabelWithErrorMsg>
                </div>
                <div className={classNames(css.formTwoColumnsPart, css.maxFormWidth)}>
                  <div className={css.formFirstColumn}>
                    <LabelWithErrorMsg
                      htmlFor={companyRegistrationNumberInputId}
                      labelText={companyRegistrationNumberLabel}
                      isRequired
                      errorMsg={requiredFieldFormattedMessage}
                      isError={isCompanyRegistrationNumberError}
                    >
                      <FieldTextInput
                        type="text"
                        className={classNames(css.textInput, { [css.invalidInputValue]: isCompanyRegistrationNumberError })}
                        name={companyRegistrationNumberInputId}
                        id={companyRegistrationNumberInputId}
                        placeholder={companyRegistrationNumberPlaceholder}
                        defaultValue={companyRegistrationNumber}
                        validate={requiredField}
                      />
                    </LabelWithErrorMsg>
                    {isFrenchCompany ? (
                      <div className={css.formField}>
                        <LabelWithErrorMsg
                          htmlFor={companyRCSnumberInputID}
                          labelText={companyRCSnumberLabel}
                          //isRequired
                          errorMsg={requiredFieldFormattedMessage}
                          isError={companyRCSnumberError}
                        >
                          <FieldTextInput
                            type="text"
                            className={classNames(css.textInput, { [css.invalidInputValue]: companyRCSnumberError })}
                            name={companyRCSnumberInputID}
                            id={companyRCSnumberInputID}
                            placeholder={companyRCSnumberPlaceholder}
                            defaultValue={companyRCSnumber}
                          //validate={requiredField}
                          />
                        </LabelWithErrorMsg>
                      </div>
                    ) : null}
                    <div className={css.formField}>
                      <LabelWithErrorMsg
                        htmlFor={streetAndNumberInputId}
                        labelText={streetAndNumberLabel}
                        isRequired
                        errorMsg={requiredFieldFormattedMessage}
                        isError={isStreetAndNumberError}
                      >
                        <FieldTextInput
                          type="text"
                          className={classNames(css.textInput, { [css.invalidInputValue]: isStreetAndNumberError })}
                          name={streetAndNumberInputId}
                          id={streetAndNumberInputId}
                          placeholder={streetAndNumberPlaceholder}
                          defaultValue={streetAndNumber}
                          validate={requiredField}
                        />
                      </LabelWithErrorMsg>
                    </div>
                    <div className={css.formField}>
                      <LabelWithErrorMsg
                        htmlFor={postcodeInputId}
                        labelText={postcodeLabel}
                        isRequired
                        errorMsg={requiredFieldFormattedMessage}
                        isError={isPostCodeError}
                      >
                        <FieldTextInput
                          type="text"
                          className={classNames(css.textInput, { [css.invalidInputValue]: isPostCodeError })}
                          name={postcodeInputId}
                          id={postcodeInputId}
                          placeholder={postcodePlaceholder}
                          defaultValue={postcode}
                          validate={requiredField}
                        />
                      </LabelWithErrorMsg>
                    </div>
                  </div>
                  <div className={css.formSecondColumn}>
                    <div className={classNames(css.fieldInputContainer, css.vatContainer)}>
                      <LabelWithErrorMsg
                        htmlFor={countryVATInputId + companyVATInputId}
                        labelText={VATLabel}
                        isRequired
                        errorMsg={requiredFieldFormattedMessage}
                        isError={isCompanyVATError}
                      >
                        <FieldTextInput
                          className={classNames(css.textInput, { [css.invalidInputValue]: isCompanyVATError })}
                          type="text"
                          name={companyVATInputId}
                          id={companyVATInputId}
                          placeholder={companyVATPlaceholder}
                          defaultValue={companyVATnumber}
                          validate={requiredField}
                        />
                      </LabelWithErrorMsg>
                    </div>
                    {isFrenchCompany ? (
                      <div className={css.formField}>
                        <LabelWithErrorMsg
                          htmlFor={companyShareCapitalInputID}
                          labelText={companyShareCapitalLabel}
                          //isRequired
                          errorMsg={requiredFieldFormattedMessage}
                          isError={companyShareCapitalError}
                        >
                          <FieldTextInput
                            className={classNames(css.textInput, { [css.invalidInputValue]: companyShareCapitalError })}
                            type="text"
                            name={companyShareCapitalInputID}
                            id={companyShareCapitalInputID}
                            placeholder={companyShareCapitalPlaceholder}
                            defaultValue={companyShareCapital}
                          //validate={requiredField}
                          />
                        </LabelWithErrorMsg>
                      </div>
                    ) : null}
                    <div className={css.formField}>
                      <LabelWithErrorMsg
                        htmlFor={cityInputId}
                        labelText={cityLabel}
                        isRequired
                        errorMsg={requiredFieldFormattedMessage}
                        isError={isCityError}
                      >
                        <FieldTextInput
                          className={classNames(css.textInput, { [css.invalidInputValue]: isCityError })}
                          type="text"
                          name={cityInputId}
                          id={cityInputId}
                          placeholder={cityPlaceholder}
                          defaultValue={city}
                          validate={requiredField}
                        />
                      </LabelWithErrorMsg>
                    </div>
                    <div className={css.formField}>
                      <LabelWithErrorMsg htmlFor={countryInputId} labelText={countryLabel}>
                        <FieldTextInput
                          type="text"
                          className={css.textInput}
                          name={countryInputId}
                          id={countryInputId}
                          placeholder={countryPlaceholder}
                          defaultValue={country}
                          readOnly
                        />
                      </LabelWithErrorMsg>
                    </div>
                  </div>
                </div>
              </div>
              <div className={css.bottomWrapper}>
                <PrimaryButton
                  type="submit"
                  inProgress={billingDetailsUpdateInProgress}
                  disabled={submitDisabled}
                  className={css.submitButton}
                >
                  <FormattedMessage id="ContactDetailsForm.saveChanges" />
                </PrimaryButton>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

const BuyerBillingDetailsForm = compose(injectIntl)(BuyerBillingDetailsFormComponent);

const { object, func, bool } = PropTypes;

BuyerBillingDetailsForm.propTypes = {
  onSubmit: func.isRequired,
  billingDetails: object.isRequired,
  billingDetailsUpdateInProgress: bool.isRequired,
  intl: intlShape.isRequired,
};

BuyerBillingDetailsForm.displayName = "BuyerBillingDetailsForm";

export default BuyerBillingDetailsForm;
