const BUYER_INVOICING_TABS = {
  invoicing: "invoicing",
};

Object.freeze(BUYER_INVOICING_TABS);

const BUYER_INVOICING_TAB_MESSAGES = {
  [BUYER_INVOICING_TABS.invoicing]: "BuyerInvoicingPage.settingsTabInvoicing",
};

Object.freeze(BUYER_INVOICING_TAB_MESSAGES);

const BUYER_INVOICING_TAB_LINK_NAMES = {
  [BUYER_INVOICING_TABS.invoicing]: "BuyerInvoicingPage",
};

Object.freeze(BUYER_INVOICING_TAB_LINK_NAMES);

export {
  BUYER_INVOICING_TABS,
  BUYER_INVOICING_TAB_MESSAGES,
  BUYER_INVOICING_TAB_LINK_NAMES,
};
