import { storableError } from '../../util/errors';
import { registerationBuyerRequest } from '../../util/api';

import { send } from '../../ducks/mail.duck';

// ================ Action types ================ //
export const BUYER_REGISTRATION_REQUEST = 'app/Buyer/request/REGISTRATION';
export const BUYER_REGISTRATION_SUCCESS = 'app/Buyer/request/REGISTRATION_SUCCESS';
export const BUYER_REGISTRATION_ERROR = 'app/Buyer/request/REGISTRATION_ERROR';

// ================ Reducer ================ //
const initialState = {
  buyerRegistrationRequestError: null,
  buyerRegistrationRequestInProgress: false,
  buyerRequestSent: false
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case BUYER_REGISTRATION_REQUEST:
      return {
        ...state,
        buyerRegistrationRequestInProgress: true,
        buyerRegistrationRequestError: null,
        buyerRequestSent: false,
      };
    case BUYER_REGISTRATION_SUCCESS:
      return { ...state, buyerRegistrationRequestInProgress: false, buyerRequestSent: true };
    case BUYER_REGISTRATION_ERROR:
      return { ...state, buyerRegistrationRequestInProgress: false, buyerRegistrationRequestError: payload };
    default:
      return state;
  }
}

// ================ Action creators ================ //
export const buyerRegistrationRequest = () => ({ type: BUYER_REGISTRATION_REQUEST });
export const buyerRegistrationSuccess = () => ({ type: BUYER_REGISTRATION_SUCCESS });
export const buyerRegistrationError = (error) => ({ type: BUYER_REGISTRATION_ERROR, payload: error, error: true });

// ================ Thunks ================ //
export const buyerRegistration = (request) => (dispatch) => {
  dispatch(buyerRegistrationRequest());

  return registerationBuyerRequest({ ...request, iAgreeToReceive: true })
    .then(() => {
      dispatch(buyerRegistrationSuccess());
      dispatch(send({
        subject: 'Vegshelf registration request received',
        template: 'user-registration-sent',
        data: {
          name: `${request.firstName}`
        },
        to: request.businessEMail
      }));
    })
    .catch(e => {
      dispatch(buyerRegistrationError(storableError(storableError(e))));
      // This is thrown so that form can be cleared
      // after a timeout on changePassword submit handler
      throw e;
    });
};
