import React from 'react';
import { connect } from 'react-redux';
import RegistrationApproved from './user-registration-approved';
import RegistrationRejected from './user-registration-rejected';
import RegistrationRequestSent from './user-registration-request-sent';

const MailTemplatesComponent = props => {
    const { mailTemplate, templateData } = props;
    const templates = {
        'user-registration-approve' : (d) => { return (<RegistrationApproved {...d}/>)},
        'user-registration-reject' : (d) => { return (<RegistrationRejected {...d}/>)},
        'user-registration-sent': (d) => { return (<RegistrationRequestSent {...d}/>)}
    };
    return (<div style={{display: 'none'}} id="mail-template-node">
        {templates[mailTemplate]? templates[mailTemplate](templateData) : null}
    </div>);
};

const mapStateToProps = state => {

    return {
      ...state.MailSender
    };
  };
  
  
  const MailTemplates = connect(mapStateToProps)(MailTemplatesComponent);
  
  export default MailTemplates;