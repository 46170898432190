import { bool, object } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import facebookImage from "../../assets/VegshelfFacebook-1200x630.png";
import twitterImage from "../../assets/VegshelfTwitter-600x314.png";
import {
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Page,
  TabNav,
} from "../../components";
import config from "../../config";
import {
  ProfilePayoutDetailsPage,
  SupplierProfileAccountPage,
  SupplierProfileInvoicingPage,
  SupplierProfileNotificationsPage,
  TopbarContainer,
} from "../../containers";
import { isScrollingDisabled } from "../../ducks/UI.duck";
import { FormattedMessage, injectIntl, intlShape } from "../../util/reactIntl";
import { propTypes } from "../../util/types";
import { localePart, storedLocale } from '../../components/Wrapper/ClassWrapper';
import css from "./SupplierSettingsPage.module.css";
import { SUPPLIER_TAB_LINK_NAMES, SUPPLIER_TAB_MESSAGES, SUPPLIER_TABS } from "./variables";

export class SupplierSettingsPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { stepIndex: 0 };
    this.newSupplier = null;
  }

  render() {
    const { intl, scrollingDisabled } = this.props;
    const tab = this.props.tab || 'account';
    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage({ id: 'SupplierSettingsPage.schemaTitle' }, { siteTitle });
    const schemaDescription = intl.formatMessage({ id: 'SupplierSettingsPage.schemaDescription' }, { siteTitle });
    const schemaImage = `${config.canonicalRootURL}${facebookImage}`;
    const locale = storedLocale() || localePart();

    const pages = {
      'account': (<SupplierProfileAccountPage {...this.props} />),
      'payout-details': (<ProfilePayoutDetailsPage {...this.props} supplierMode={true} />),
      'invoicing': (<SupplierProfileInvoicingPage {...this.props} />),
      'notifications': (<SupplierProfileNotificationsPage {...this.props} />),
    };

    const tabs = Object.keys(SUPPLIER_TABS).map((key) => {
      return ({
        id: key,
        text: (
          <span>
            <FormattedMessage id={SUPPLIER_TAB_MESSAGES[key]} />
          </span>
        ),
        selected: tab === SUPPLIER_TABS[key],
        linkProps: {
          name: SUPPLIER_TAB_LINK_NAMES[key],
          params: {
            tab: key,
            locale: locale
          },
        },
      });
    });

    const facebookImages = [
      {
        url: facebookImage,
        width: 1200,
        height: 630,
      },
    ];

    const twitterImages = [
      {
        url: `${config.canonicalRootURL}${twitterImage}`,
        width: 600,
        height: 314,
      },
    ];

    const pageSchema = {
      '@context': 'https://schema.org',
      '@type': 'WebPage',
      description: schemaDescription,
      name: schemaTitle,
      image: [schemaImage],
    };

    return (
      <Page
        className={css.root}
        scrollingDisabled={scrollingDisabled}
        contentType='website'
        description={schemaDescription}
        title={schemaTitle}
        robots='noindex'
        facebookImages={facebookImages}
        twitterImages={twitterImages}
        schema={pageSchema}
      >
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div className={css.settingsContainer}>
              <div className={css.settingsSection}>
                <TabNav
                  rootClassName={css.tabs}
                  tabRootClassName={css.tab}
                  tabs={tabs} />
                <div className={css.pageContainer}>
                  {pages[tab]}
                </div>
              </div>
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

SupplierSettingsPageComponent.defaultProps = {
  currentUserListing: null,
  currentUserListingFetched: false,
};

SupplierSettingsPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
  // from withRouter
  history: object.isRequired,
  location: object.isRequired,
  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = (state) => {
  const { currentUser } = state.user;

  return { scrollingDisabled: isScrollingDisabled(state), currentUser };
};

const mapDispatchToProps = (/*dispatch*/) => ({
  // onSupplierRegistration: values => dispatch(supplierRegistration(values)),
});

const SupplierSettingsPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
)(SupplierSettingsPageComponent);

export default SupplierSettingsPage;
