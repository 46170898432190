import config from "../config";
import { approveProduct } from "../util/api";
import { denormalisedResponseEntities } from "../util/data";
import { types as sdkTypes, util as sdkUtil } from "../util/sdkLoader";
import { fetchStripeAccount } from "./stripeConnectAccount.duck";
import { currentUserShowSuccess } from "./user.duck";
import { LoadSupplierInvoices } from "../containers/SupplierInvoicesPage/SupplierInvoicesPage.duck";
import { LoadBuyerInvoices } from "../containers/BuyerInvoicesPage/BuyerInvoicesPage.duck";
import { buyerGetCompanyError, buyerGetCompanySuccess } from "./buyer-company.duck";
import { SUPPLIER_INVOICING_TABS } from "../containers/SupplierInvoicingPage/variables";

const { UUID } = sdkTypes;
const CURRENCY = config.currency;

export const ProductStates = {
  draft: "draft",
  pending: "pending",
  published: "published",
  closed: "closed",
  outOfStock: "outOfStock",
};

export const ProductStatesTranslates = {
  draft: "draft",
  pending: "pending",
  published: "published",
  closed: "closed",
  outOfStock: "out of stock",
  backSoon: "back soon",
};

export const parseListingState = (product) => {
  const state = product?.attributes?.state || "draft";
  const lockBuy = product?.attributes?.publicData?.lockBuy === true;
  if (state === "draft") {
    return "draft";
  }
  if (state === "pendingApproval") {
    return "pending";
  }
  if (state === "closed") {
    return "closed";
  }
  if (state === "published" && lockBuy) {
    return "outOfStock";
  }
  if (state === "published" && !lockBuy) {
    return "published";
  }
  return state;
};

// ================ Action types ================ //
export const SUPPLIER_COMPANY_CREATE_REQUEST = "app/Supplier/Company/CREATE";
export const SUPPLIER_COMPANY_CREATE_SUCCESS = "app/Supplier/Company/CREATE_SUCCESS";
export const SUPPLIER_COMPANY_CREATE_ERROR = "app/Supplier/Company/CREATE_ERROR";

export const SUPPLIER_COMPANY_UPDATE_REQUEST = "app/Supplier/Company/UPDATE";
export const SUPPLIER_COMPANY_UPDATE_SUCCESS = "app/Supplier/Company/UPDATE_SUCCESS";
export const SUPPLIER_COMPANY_UPDATE_ERROR = "app/Supplier/Company/UPDATE_ERROR";

export const SUPPLIER_GET_CURRENT_COMPANY_REQUEST = "app/Supplier/Company/GET";
export const SUPPLIER_GET_CURRENT_COMPANY_SUCCESS = "app/Supplier/Company/GET_SUCCESS";
export const SUPPLIER_GET_CURRENT_COMPANY_ERROR = "app/Supplier/Company/GET_ERROR";

export const SUPPLIER_UPDATE_COMPANY_LOGO_REQUEST = "app/Supplier/Company/logo/UPDATE";
export const SUPPLIER_UPDATE_COMPANY_LOGO_SUCCESS = "app/Supplier/Company/logo/UPDATE_SUCCESS";
export const SUPPLIER_UPDATE_COMPANY_LOGO_ERROR = "app/Supplier/Company/logo/UPDATE_ERROR";

export const SUPPLIER_UPDATE_COMPANY_COVER_REQUEST = "app/Supplier/Company/cover/UPDATE";
export const SUPPLIER_UPDATE_COMPANY_COVER_SUCCESS = "app/Supplier/Company/cover/UPDATE_SUCCESS";
export const SUPPLIER_UPDATE_COMPANY_COVER_ERROR = "app/Supplier/Company/cover/UPDATE_ERROR";

export const SUPPLIER_DELETE_COMPANY_COVER_REQUEST = "app/Supplier/Company/cover/DELETE";
export const SUPPLIER_DELETE_COMPANY_COVER_SUCCESS = "app/Supplier/Company/cover/DELETE_SUCCESS";
export const SUPPLIER_DELETE_COMPANY_COVER_ERROR = "app/Supplier/Company/cover/DELETE_ERROR";

export const SUPPLIER_GET_COMPANY_PRODUCTS_REQUEST = "app/Supplier/Company/products/GET";
export const SUPPLIER_GET_COMPANY_PRODUCTS_SUCCESS = "app/Supplier/Company/products/GET_SUCCESS";
export const SUPPLIER_GET_COMPANY_PRODUCTS_ERROR = "app/Supplier/Company/products/GET_ERROR";

export const SUPPLIER_PRODUCT_CHANGE_STATUS_REQUEST = "app/Supplier/product/state/request";
export const SUPPLIER_PRODUCT_CHANGE_STATUS_SUCCESS = "app/Supplier/product/state/success";
export const SUPPLIER_PRODUCT_CHANGE_STATUS_ERROR = "app/Supplier/product/state/error";

export const HIDE_NOTIFICATION = "app/Supplier/product/HIDE_NOTIFICATION";

// ================ Reducer ================ //
const initialState = {
  supplierCreateCompanyError: null,
  supplierCreateCompanyInProgress: false,
  supplierCreatedCompany: false,
  supplierCurrentCompany: null,
  supplierGetCurrentCompanyError: null,
  supplierGetCurrentCompanyInProgress: false,
  supplierUpdateCompanyError: null,
  supplierUpdateCompanyInProgress: false,
  supplierUpdateCompany: false,
  supplierUpdateCompanyLogoError: null,
  supplierUpdateCompanyLogoInProgress: false,
  supplierUpdateCompanyLogo: false,
  supplierUpdateCompanyCoverError: null,
  supplierUpdateCompanyCoverInProgress: false,
  supplierUpdateCompanyCover: false,
  supplierDeleteCompanyCoverError: null,
  supplierDeleteCompanyCoverInProgress: false,
  supplierDeleteCompanyCover: false,
  supplierLoadCompanyProductsError: null,
  supplierLoadCompanyProductsInProgress: false,
  supplierLoadedCompanyProducts: false,
  supplierCompanyProducts: null,
  supplierProductChangeStateError: null,
  supplierProductChangeStateProgress: false,
  supplierProductStateChanged: false,
  notificationText: "",
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SUPPLIER_COMPANY_CREATE_REQUEST:
      return {
        ...state,
        supplierCreateCompanyInProgress: true,
        supplierCreateCompanyError: null,
        supplierCreatedCompany: false,
      };
    case SUPPLIER_COMPANY_CREATE_SUCCESS:
      return { ...state, supplierCreateCompanyInProgress: false, supplierCreatedCompany: true };
    case SUPPLIER_COMPANY_CREATE_ERROR:
      return { ...state, supplierCreateCompanyInProgress: false, supplierCreateCompanyError: payload };
    case SUPPLIER_PRODUCT_CHANGE_STATUS_REQUEST:
      return {
        ...state,
        supplierProductChangeStateError: null,
        supplierProductChangeStateProgress: true,
        supplierProductStateChanged: false,
      };
    case SUPPLIER_PRODUCT_CHANGE_STATUS_SUCCESS:
      return {
        ...state, supplierProductChangeStateProgress: false, supplierProductStateChanged: true,
        notificationText: payload,
      };
    case SUPPLIER_PRODUCT_CHANGE_STATUS_ERROR:
      return { ...state, supplierProductChangeStateProgress: false, supplierProductChangeStateError: payload };
    case SUPPLIER_COMPANY_UPDATE_REQUEST:
      return {
        ...state,
        supplierUpdateCompanyInProgress: true,
        supplierUpdateCompanyError: null,
        supplierUpdateCompany: false,
      };
    case SUPPLIER_COMPANY_UPDATE_SUCCESS:
      return { ...state, supplierUpdateCompanyInProgress: false, supplierUpdateCompany: true };
    case SUPPLIER_COMPANY_UPDATE_ERROR:
      return { ...state, supplierUpdateCompanyInProgress: false, supplierUpdateCompanyError: payload };
    case SUPPLIER_GET_CURRENT_COMPANY_REQUEST:
      return {
        ...state,
        supplierGetCurrentCompanyInProgress: true,
        supplierGetCurrentCompanyError: null,
        // TODO: Why we should assign supplierCurrentCompany to null on every [GET] request?
        // supplierCurrentCompany: null,
      };
    case SUPPLIER_GET_CURRENT_COMPANY_SUCCESS:
      return { ...state, supplierGetCurrentCompanyInProgress: false, supplierCurrentCompany: payload };
    case SUPPLIER_GET_CURRENT_COMPANY_ERROR:
      return { ...state, supplierGetCurrentCompanyInProgress: false, supplierGetCurrentCompanyError: payload };
    case SUPPLIER_UPDATE_COMPANY_LOGO_REQUEST:
      return {
        ...state,
        supplierUpdateCompanyLogoInProgress: true,
        supplierUpdateCompanyLogoError: null,
        supplierUpdateCompanyLogo: false,
      };
    case SUPPLIER_UPDATE_COMPANY_LOGO_SUCCESS:
      return { ...state, supplierUpdateCompanyLogoInProgress: false, supplierUpdateCompanyLogo: true };
    case SUPPLIER_UPDATE_COMPANY_LOGO_ERROR:
      return { ...state, supplierUpdateCompanyLogoInProgress: false, supplierUpdateCompanyLogoError: payload };
    case SUPPLIER_UPDATE_COMPANY_COVER_REQUEST:
      return {
        ...state,
        supplierUpdateCompanyCoverInProgress: true,
        supplierUpdateCompanyCoverError: null,
        supplierUpdateCompanyCover: false,
      };
    case SUPPLIER_UPDATE_COMPANY_COVER_SUCCESS:
      return { ...state, supplierUpdateCompanyCoverInProgress: false, supplierUpdateCompanyCover: true };
    case SUPPLIER_UPDATE_COMPANY_COVER_ERROR:
      return { ...state, supplierUpdateCompanyCoverInProgress: false, supplierUpdateCompanyCoverError: payload };
    case SUPPLIER_DELETE_COMPANY_COVER_REQUEST:
      return {
        ...state,
        supplierDeleteCompanyCoverInProgress: true,
        supplierDeleteCompanyCoverError: null,
        supplierDeleteCompanyCover: false,
      };
    case SUPPLIER_DELETE_COMPANY_COVER_SUCCESS:
      return { ...state, supplierDeleteCompanyCoverInProgress: false, supplierDeleteCompanyCover: true };
    case SUPPLIER_DELETE_COMPANY_COVER_ERROR:
      return { ...state, supplierDeleteCompanyCoverInProgress: false, supplierDeleteCompanyCoverError: payload };
    case SUPPLIER_GET_COMPANY_PRODUCTS_REQUEST:
      return {
        ...state,
        supplierLoadCompanyProductsError: null,
        supplierLoadCompanyProductsInProgress: true,
        supplierLoadedCompanyProducts: false,
        supplierCompanyProducts: null,
      };
    case SUPPLIER_GET_COMPANY_PRODUCTS_SUCCESS:
      return { ...state, supplierLoadCompanyProductsInProgress: false, supplierCompanyProducts: payload };
    case SUPPLIER_GET_COMPANY_PRODUCTS_ERROR:
      return { ...state, supplierLoadCompanyProductsInProgress: false, supplierLoadCompanyProductsError: payload };
    case HIDE_NOTIFICATION:
      return { ...state, notificationText: "" };
    default:
      return state;
  }
}

// ================ Action creators ================ //
export const supplierCompanyCreateRequest = () => ({ type: SUPPLIER_COMPANY_CREATE_REQUEST });
export const supplierCompanyCreateSuccess = () => ({ type: SUPPLIER_COMPANY_CREATE_SUCCESS });
export const supplierCompanyCreateError = (error) => ({
  type: SUPPLIER_COMPANY_CREATE_ERROR,
  payload: error,
  error: true,
});
export const supplierCompanyUpdateRequest = () => ({ type: SUPPLIER_COMPANY_UPDATE_REQUEST });
export const supplierCompanyUpdateSuccess = () => ({ type: SUPPLIER_COMPANY_UPDATE_SUCCESS });
export const supplierCompanyUpdateError = (error) => ({
  type: SUPPLIER_COMPANY_UPDATE_ERROR,
  payload: error,
  error: true,
});
export const supplierCompanyUpdateLogoRequest = () => ({ type: SUPPLIER_UPDATE_COMPANY_LOGO_REQUEST });
export const supplierCompanyUpdateLogoSuccess = () => ({ type: SUPPLIER_UPDATE_COMPANY_LOGO_SUCCESS });
export const supplierCompanyUpdateLogoError = (error) => ({
  type: SUPPLIER_UPDATE_COMPANY_LOGO_ERROR,
  payload: error,
  error: true,
});
export const supplierCompanyUpdateCoverRequest = () => ({ type: SUPPLIER_UPDATE_COMPANY_COVER_REQUEST });
export const supplierCompanyUpdateCoverSuccess = () => ({ type: SUPPLIER_UPDATE_COMPANY_COVER_SUCCESS });
export const supplierCompanyUpdateCoverError = (error) => ({
  type: SUPPLIER_UPDATE_COMPANY_COVER_ERROR,
  payload: error,
  error: true,
});
export const supplierCompanyDeleteCoverRequest = () => ({ type: SUPPLIER_DELETE_COMPANY_COVER_REQUEST });
export const supplierCompanyDeleteCoverSuccess = () => ({ type: SUPPLIER_DELETE_COMPANY_COVER_SUCCESS });
export const supplierCompanyDeleteCoverError = (error) => ({
  type: SUPPLIER_DELETE_COMPANY_COVER_ERROR,
  payload: error,
  error: true,
});
export const supplierGetCompanyRequest = () => ({ type: SUPPLIER_GET_CURRENT_COMPANY_REQUEST });
export const supplierGetCompanySuccess = (company) => ({
  type: SUPPLIER_GET_CURRENT_COMPANY_SUCCESS, payload: company,
});
export const supplierGetCompanyError = (error) => ({
  type: SUPPLIER_GET_CURRENT_COMPANY_ERROR,
  payload: error,
  error: true,
});
export const supplierGetCompanyProductsRequest = () => ({ type: SUPPLIER_GET_COMPANY_PRODUCTS_REQUEST });
export const supplierGetCompanyProductsSuccess = (products) => ({
  type: SUPPLIER_GET_COMPANY_PRODUCTS_SUCCESS, payload: products,
});
export const supplierGetCompanyProductsError = (error) => ({
  type: SUPPLIER_GET_COMPANY_PRODUCTS_ERROR,
  payload: error,
  error: true,
});

export const hideNotification = () => ({ type: HIDE_NOTIFICATION });

export const supplierProductChangeStateRequest = () => ({ type: SUPPLIER_PRODUCT_CHANGE_STATUS_REQUEST });
export const supplierProductChangeStateSuccess = (notificationText) => ({
  type: SUPPLIER_PRODUCT_CHANGE_STATUS_SUCCESS, payload: notificationText,
});
export const supplierProductChangeStateError = (error) => ({
  type: SUPPLIER_PRODUCT_CHANGE_STATUS_ERROR,
  payload: error,
  error: true,
});

// ================ Thunks ================ //
export const supplierCreateCompany = (params) => (dispatch, getState, sdk) => {
  dispatch(supplierCompanyCreateRequest());
  const { brandName } = params;

  const companyItem = {
    title: brandName,
    description: brandName,
    price: { amount: 0, currency: CURRENCY },
    //  state:'published',
    publicData: {
      ...params,
      dataType: "company",
      moq: 100,
    },
  };

  const queryParams = {
    expand: true,
    include: ["author"],
  };

  return sdk.ownListings
    .create(companyItem, queryParams)
    .then((response) => {
      return approveProduct(response.data.data.id)
        .then((response) => {
          dispatch(supplierLoadCompany());
          dispatch(supplierCompanyCreateSuccess());
          return response;
        })
        .catch((e) => {
          return dispatch(supplierCompanyCreateError(e));
        });
    })
    .catch((e) => {
      return dispatch(supplierCompanyCreateError(e));
    });
};

const updateBrandDataInProducts = async (brandData, sdk) => {
  const response = await sdk.ownListings.query({ authorId: brandData.id, pub_dataType: "product" });
  const products = response?.data?.data?.filter(
    (x) => x.attributes.publicData.dataType === "product" && x?.attributes?.state !== "closed",
  ) || [];

  for (let i = 0; i < products.length; i++) {
    const product = {
      id: products[i].id,
      publicData: {
        brandName: brandData.publicData.brandName,
        location: brandData.publicData.country,
      },
    };

    await sdk.ownListings.update(product);
  }
};

export const supplierUpdateCompany = (params) => (dispatch, getState, sdk) => {
  dispatch(supplierCompanyUpdateRequest());
  const {
    annualSales,
    brandName,
    leadTime,
    countries,
    instagram,
    description,
    establishedIn,
    employees,
    numberOfStores,
    logoImage,
    city,
    companyName,
    companyRegistrationNumber,
    companyVATnumber,
    country,
    postcode,
    streetAndNumber,
    companyRCSnumber,
    companyShareCapital,
  } = params;

  const currentCompany = getState().supplierCompany.supplierCurrentCompany;
  const currentCompanyId = currentCompany?.id;
  const companyData = currentCompany?.attributes;
  const companyDetails = companyData?.publicData;
  const shippingFeeCountries = companyData?.publicData.shippingFeeCountries;
  let companyItem = {
    id: currentCompanyId,
    title: brandName || currentCompany?.attributes.title,
    description: description || currentCompany?.attributes.description,
    price: { amount: 0, currency: CURRENCY },
    publicData: {
      dataType: "company",
      annualSales: annualSales || companyDetails.annualSales,
      brandName: brandName || companyDetails.brandName,
      country: country || companyDetails.country,
      city: city || companyDetails.city,
      companyName: companyName || companyDetails.companyName,
      companyRegistrationNumber: companyRegistrationNumber || companyDetails.companyRegistrationNumber,
      companyVATnumber: companyVATnumber || companyDetails.companyVATnumber,
      postcode: postcode || companyDetails.postcode,
      streetAndNumber: streetAndNumber || companyDetails.streetAndNumber,
      leadTime: leadTime || companyDetails.leadTime,
      countries: countries || companyDetails.countries,
      instagram: instagram || companyDetails.instagram,
      description: description || companyDetails.description,
      establishedIn: establishedIn || companyDetails.establishedIn,
      employees: employees || companyDetails.employees,
      moq: 100,
      numberOfStores: numberOfStores || companyDetails.numberOfStores,
      logoImageId: companyDetails.logoImageId,
      logoUrl: companyDetails.logoUrl,
      coverImageId: companyDetails.coverImageId,
      coverUrl: companyDetails.coverUrl,
      shippingFeeCountries: shippingFeeCountries || companyDetails.shippingFeeCountries,
      companyRCSnumber: companyRCSnumber || companyDetails.companyRCSnumber,
      companyShareCapital: companyShareCapital || companyDetails.companyShareCapital,
    },
  };

  return updateBrandDataInProducts(companyItem, sdk)
    .then(() => {
      if (!logoImage) {
        return sdk.ownListings
          .update(companyItem)
          .then((response) => {
            dispatch(supplierLoadCompany());
            dispatch(supplierCompanyUpdateSuccess());
            dispatch(supplierLoadCompanyProducts());
            return response;
          })
          .catch((e) => {
            return dispatch(supplierCompanyUpdateError(e));
          });
      }
      return dispatch(supplierUpdateCompanyLogo({ file: logoImage, companyData: companyItem }));
    });
};

export const supplierLoadCompany = (params) => (dispatch, getState, sdk) => {
  let authorId = getState()?.user?.currentUser?.id;
  if (!authorId) {
    return;
  }
  dispatch(supplierGetCompanyRequest());
  return sdk.listings
    .query({ authorId, pub_dataType: "company" })
    .then((response) => {
      dispatch(supplierGetCompanySuccess(response?.data?.data[0]));
      dispatch(LoadSupplierInvoices({
        current_page: 1,
        invoices_type: params.invoices_type || SUPPLIER_INVOICING_TABS.order,
      }));
      dispatch(fetchStripeAccount());
      return response;
    })
    .catch((e) => {
      return dispatch(supplierGetCompanyError(e));
    });
};

export function supplierUpdateCompanyLogo(actionPayload) {
  return (dispatch, getState, sdk) => {
    dispatch(supplierCompanyUpdateLogoRequest());
    const queryParams = {
      expand: true,
      "fields.image": ["variants.square-small", "variants.square-small2x", "variants.landscape-crop2x"],
    };

    return sdk.images
      .upload({ image: actionPayload.file }, queryParams)
      .then((resp) => {
        const currentCompany = getState().supplierCompany.supplierCurrentCompany;
        const companyData = currentCompany?.attributes;
        const companyDetails = companyData?.publicData;
        const coverId = companyDetails?.coverImageId;
        const newLogoId = resp.data.data.id.uuid;
        const logoUrl = resp.data.data.attributes.variants["square-small2x"].url;
        const images = [resp.data.data.id];
        if (coverId) {
          images.push(new UUID(coverId));
        }
        let companyItem = actionPayload.companyData ? actionPayload.companyData : {
          id: currentCompany?.id,
          publicData: {},
        };
        companyItem.images = images;
        companyItem.publicData.logoImageId = newLogoId;
        companyItem.publicData.logoUrl = logoUrl;

        const queryParams = {
          expand: true,
          include: ["author"],
        };
        if (!currentCompany?.id) {
          delete companyItem["id"];
        }
        return (currentCompany?.id ? sdk.ownListings
          .update(companyItem) : sdk.ownListings
          .create(companyItem, queryParams))
          .then((response) => {
            if (!currentCompany?.id) {
              approveProduct(response.data.data.id)
                .then(() => {
                  dispatch(updateProfileImage({ profileImageId: resp.data.data.id, file: actionPayload.file }));
                  dispatch(supplierLoadCompany());
                  dispatch(supplierCompanyUpdateLogoSuccess());
                });
              return;
            }
            dispatch(updateProfileImage({ profileImageId: resp.data.data.id, file: actionPayload.file }));
            dispatch(supplierLoadCompany());
            dispatch(supplierCompanyUpdateLogoSuccess());
            return response;
          })
          .catch((e) => {
            if (actionPayload.companyData)
              dispatch(supplierCompanyUpdateError(e));
            return dispatch(supplierCompanyUpdateLogoError(e));
          });
      })
      .catch((e) => {
        if (actionPayload.companyData)
          dispatch(supplierCompanyUpdateError(e));
        return dispatch(supplierCompanyUpdateLogoError(e));
      });
  };
};

export function supplierUpdateCompanyCover(actionPayload) {
  return (dispatch, getState, sdk) => {
    dispatch(supplierCompanyUpdateCoverRequest());
    const queryParams = {
      expand: true,
      "fields.image": ["variants.supplier-cover"],
      "imageVariant.supplier-cover": sdkUtil.objectQueryString({
        w: 1280,
        h: 270,
        fit: "crop",
      }),
    };

    return sdk.images
      .upload({ image: actionPayload.file }, queryParams)
      .then((resp) => {
        const currentCompany = getState().supplierCompany.supplierCurrentCompany;
        const companyData = currentCompany?.attributes;
        const companyDetails = companyData?.publicData;
        const logoId = companyDetails.logoImageId;
        const newCoverId = resp.data.data.id.uuid;
        const coverUrl = resp.data.data.attributes.variants["supplier-cover"].url;
        const images = [resp.data.data.id];
        if (logoId) {
          images.push(new UUID(logoId));
        }
        const companyItem = {
          id: currentCompany.id,
          images: images,
          publicData: { coverImageId: newCoverId, coverUrl: coverUrl },
        };

        return sdk.ownListings
          .update(companyItem)
          .then((response) => {
            dispatch(supplierLoadCompany());
            dispatch(supplierCompanyUpdateCoverSuccess());
            return response;
          })
          .catch((e) => {
            return dispatch(supplierCompanyUpdateCoverError(e));
          });
      })
      .catch((e) => dispatch(supplierCompanyUpdateCoverError(e)));
  };
};

const updateProfileImage = (actionPayload) => {
  return (dispatch, getState, sdk) => {
    const queryParams = {
      expand: true,
      "fields.image": ["variants.square-small", "variants.square-small2x", "variants.landscape-crop2x"],
    };
    return sdk.images
      .upload({ image: actionPayload.file }, queryParams)
      .then((resp) => {
        return sdk.currentUser
          .updateProfile({ profileImageId: resp.data.data.id }, queryParams)
          .then((response) => {
            const entities = denormalisedResponseEntities(response);
            if (entities.length !== 1) {
              throw new Error("Expected a resource in the sdk.currentUser.updateProfile response");
            }
            const currentUser = entities[0];

            // Update current user in state.user.currentUser through user.duck.js
            dispatch(currentUserShowSuccess(currentUser));
            dispatch(supplierCompanyUpdateSuccess());
          })
          .catch((e) => dispatch(supplierCompanyUpdateLogoError(e)));
      })
      .catch((e) => dispatch(supplierCompanyUpdateLogoError(e)));
  };
};

export function supplierDeleteCompanyCover() {
  return (dispatch, getState, sdk) => {
    dispatch(supplierCompanyDeleteCoverRequest());
    const currentCompany = getState().supplierCompany.supplierCurrentCompany;
    const companyData = currentCompany?.attributes;
    const companyDetails = companyData?.publicData;
    const logoId = companyDetails.logoImageId;
    const images = [];
    if (logoId) {
      images.push(new UUID(logoId));
    }
    const companyItem = {
      id: currentCompany.id,
      images: images,
      publicData: { coverImageId: null, coverUrl: null },
    };

    return sdk.ownListings
      .update(companyItem)
      .then((response) => {
        dispatch(supplierLoadCompany());
        dispatch(supplierCompanyDeleteCoverSuccess());
        return response;
      })
      .catch((e) => {
        return dispatch(supplierCompanyDeleteCoverError(e));
      });
  };
};

export const supplierLoadCompanyProducts = () => (dispatch, getState, sdk) => {
  const authorId = getState()?.user?.currentUser?.id;
  if (!authorId) {
    return;
  }
  dispatch(supplierGetCompanyProductsRequest());

  return sdk.ownListings
    .query({ authorId, pub_dataType: "product" })
    .then((response) => {
      dispatch(supplierGetCompanyProductsSuccess(response?.data?.data.filter(
        (x) => x.attributes.publicData.dataType === "product" && x?.attributes?.state !== "closed",
      )));
      return response;
    })
    .catch((e) => {
      return dispatch(supplierGetCompanyProductsError(e));
    });
};

export const updateProductState = (params) => async (dispatch, getState, sdk) => {
  dispatch(supplierProductChangeStateRequest());
  const { productId, state, currentState, notificationText } = params;
  const id = new UUID(productId);
  try {
    if (state === ProductStates.published) {
      dispatch(hideNotification());
      const companyItem = {
        id: id,
        publicData: { lockBuy: false },
      };
      await sdk.ownListings.update(companyItem);
      await sdk.ownListings.publishDraft({ id }, { expand: true });
      dispatch(supplierProductChangeStateSuccess(notificationText));
      dispatch(supplierLoadCompanyProducts());
      setTimeout(() => {
        dispatch(hideNotification());
      }, 6000);
    }
    if (state === ProductStates.closed && currentState !== ProductStates.draft) {
      dispatch(hideNotification());
      await sdk.ownListings.close({ id }, { expand: true });
      dispatch(supplierProductChangeStateSuccess(notificationText));
      dispatch(supplierLoadCompanyProducts());
      setTimeout(() => {
        dispatch(hideNotification());
      }, 6000);
    }
    if (state === ProductStates.closed && currentState === ProductStates.draft) {
      dispatch(hideNotification());
      await sdk.ownListings.discardDraft({ id }, { expand: true });
      dispatch(supplierProductChangeStateSuccess(notificationText));
      dispatch(supplierLoadCompanyProducts());
      setTimeout(() => {
        dispatch(hideNotification());
      }, 6000);
    }
    if (state === ProductStates.outOfStock) {
      dispatch(hideNotification());
      const companyItem = {
        id: id,
        publicData: { lockBuy: true },
      };
      await sdk.ownListings.update(companyItem);
      dispatch(supplierProductChangeStateSuccess(notificationText));
      dispatch(supplierLoadCompanyProducts());
      setTimeout(() => {
        dispatch(hideNotification());
      }, 6000);
    }
  } catch (e) {
    return dispatch(supplierProductChangeStateError(e));
  }
};
