import { storableError } from '../../util/errors';
import {
  approveRegistrationRequest,
  deleteRegistrationRequest,
  getBuyerRequests,
  getSupplierRequests,
  rejectRegistrationRequest
} from '../../util/api';
import { send } from '../../ducks/mail.duck';

// ================ Action types ================ //
export const REGISTRATION_REQUEST_LOAD_SUPPLIERS = 'app/registration/request/load/suppliers';
export const REGISTRATION_REQUEST_LOAD_SUPPLIERS_SUCCESS = 'app/registration/request/load/suppliers/success';
export const REGISTRATION_REQUEST_LOAD_SUPPLIERS_ERROR = 'app/registration/request/load/suppliers/error';

export const REGISTRATION_REQUEST_LOAD_BUYERS = 'app/registration/request/load/buyers';
export const REGISTRATION_REQUEST_LOAD_BUYERS_SUCCESS = 'app/registration/request/load/buyers/success';
export const REGISTRATION_REQUEST_LOAD_BUYERS_ERROR = 'app/registration/request/load/buyers/error';

export const REGISTRATION_REQUEST_DELETE = 'app/registration/request/delete';
export const REGISTRATION_REQUEST_DELETE_SUCCESS = 'app/registration/request/delete/success';
export const REGISTRATION_REQUEST_DELETE_ERROR = 'app/registration/request/delete/error';

export const REGISTRATION_REQUEST_APPROVE = 'app/registration/request/approve';
export const REGISTRATION_REQUEST_APPROVE_SUCCESS = 'app/registration/request/approve/success';
export const REGISTRATION_REQUEST_APPROVE_ERROR = 'app/registration/request/approve/error';

export const REGISTRATION_REQUEST_REJECT = 'app/registration/request/reject';
export const REGISTRATION_REQUEST_REJECT_SUCCESS = 'app/registration/request/reject/success';
export const REGISTRATION_REQUEST_REJECT_ERROR = 'app/registration/request/reject/error';

// ================ Reducer ================ //
const initialState = {
  requestType: null,
  suppliersRegistrationRequestsError: null,
  suppliersRegistrationRequestsInProgress: false,
  suppliersRegistrationRequests: null,
  buyersRegistrationRequestsError: null,
  buyersRegistrationRequestsInProgress: false,
  buyersRegistrationRequests: null,
  deleteRequestError: null,
  deleteRequestInProgress: false,
  deleteRequestSuccess: false,
  approveRequestError: null,
  approveRequestInProgress: false,
  approveRequestSuccess: false,
  rejectRequestError: null,
  rejectRequestInProgress: false,
  rejectRequestSuccess: false
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case REGISTRATION_REQUEST_LOAD_SUPPLIERS:
      return {
        ...state,
        requestType: 'suppliers',
        suppliersRegistrationRequestsError: null,
        suppliersRegistrationRequestsInProgress: true,
        suppliersRegistrationRequests: null
      };
    case REGISTRATION_REQUEST_LOAD_SUPPLIERS_SUCCESS:
      return { ...state, suppliersRegistrationRequestsInProgress: false, suppliersRegistrationRequests: payload };
    case REGISTRATION_REQUEST_LOAD_SUPPLIERS_ERROR:
      return { ...state, suppliersRegistrationRequestsInProgress: false, suppliersRegistrationRequestsError: payload };
    case REGISTRATION_REQUEST_LOAD_BUYERS:
      return {
        ...state,
        requestType: 'buyers',
        buyersRegistrationRequestsError: null,
        buyersRegistrationRequestsInProgress: true,
        buyersRegistrationRequests: null
      };
    case REGISTRATION_REQUEST_LOAD_BUYERS_SUCCESS:
      return { ...state, buyersRegistrationRequestsInProgress: false, buyersRegistrationRequests: payload };
    case REGISTRATION_REQUEST_LOAD_BUYERS_ERROR:
      return { ...state, buyersRegistrationRequestsInProgress: false, buyersRegistrationRequestsError: payload };
    case REGISTRATION_REQUEST_DELETE:
      return {
        ...state,
        deleteRequestError: null,
        deleteRequestInProgress: true,
        deleteRequestSuccess: false
      };
    case REGISTRATION_REQUEST_DELETE_SUCCESS:
      return { ...state, deleteRequestInProgress: false, deleteRequestSuccess: true };
    case REGISTRATION_REQUEST_DELETE_ERROR:
      return { ...state, deleteRequestInProgress: false, deleteRequestError: payload };
    case REGISTRATION_REQUEST_APPROVE:
      return {
        ...state,
        approveRequestError: null,
        approveRequestInProgress: true,
        approveRequestSuccess: false
      };
    case REGISTRATION_REQUEST_APPROVE_SUCCESS:
      return { ...state, approveRequestInProgress: false, approveRequestSuccess: true };
    case REGISTRATION_REQUEST_APPROVE_ERROR:
      return { ...state, approveRequestInProgress: false, approveRequestError: payload };
    case REGISTRATION_REQUEST_REJECT:
      return {
        ...state,
        rejectRequestError: null,
        rejectRequestInProgress: true,
        rejectRequestSuccess: false
      };
    case REGISTRATION_REQUEST_REJECT_SUCCESS:
      return { ...state, rejectRequestInProgress: false, rejectRequestSuccess: true };
    case REGISTRATION_REQUEST_REJECT_ERROR:
      return { ...state, rejectRequestInProgress: false, rejectRequestError: payload };
    default:
      return state;
  }
}

// ================ Action creators ================ //
export const supplierRequestsLoad = () => ({ type: REGISTRATION_REQUEST_LOAD_SUPPLIERS });
export const supplierRequestsLoadSuccess = (items) => ({
  type: REGISTRATION_REQUEST_LOAD_SUPPLIERS_SUCCESS,
  payload: items
});
export const supplierRequestsLoadError = error => ({
  type: REGISTRATION_REQUEST_LOAD_SUPPLIERS_ERROR,
  payload: error,
  error: true,
});

export const buyerRequestsLoad = () => ({ type: REGISTRATION_REQUEST_LOAD_BUYERS });
export const buyerRequestsLoadSuccess = (items) => ({ type: REGISTRATION_REQUEST_LOAD_BUYERS_SUCCESS, payload: items });
export const buyerRequestsLoadError = error => ({
  type: REGISTRATION_REQUEST_LOAD_BUYERS_ERROR,
  payload: error,
  error: true,
});

export const deleteRequestLoad = () => ({ type: REGISTRATION_REQUEST_DELETE });
export const deleteRequestLoadSuccess = () => ({ type: REGISTRATION_REQUEST_DELETE_SUCCESS });
export const deleteRequestLoadError = error => ({
  type: REGISTRATION_REQUEST_DELETE_ERROR,
  payload: error,
  error: true,
});

export const approveRequestLoad = () => ({ type: REGISTRATION_REQUEST_APPROVE });
export const approveRequestLoadSuccess = () => ({ type: REGISTRATION_REQUEST_APPROVE_SUCCESS });
export const approveRequestLoadError = error => ({
  type: REGISTRATION_REQUEST_APPROVE_ERROR,
  payload: error,
  error: true,
});

export const rejectRequestLoad = () => ({ type: REGISTRATION_REQUEST_REJECT });
export const rejectRequestLoadSuccess = () => ({ type: REGISTRATION_REQUEST_REJECT_SUCCESS });
export const rejectRequestLoadError = error => ({
  type: REGISTRATION_REQUEST_REJECT_ERROR,
  payload: error,
  error: true,
});

// ================ Thunks ================ //
export const loadRequests = () => (dispatch, getState, sdk) => {
  if (getState().RegistrationModeratorPage.requestType === 'suppliers') {
    dispatch(loadSupplierRequests());
  }
  if (getState().RegistrationModeratorPage.requestType === 'buyers') {
    dispatch(loadBuyerRequests());
  }
};

export const loadSupplierRequests = () => (dispatch, getState, sdk) => {
  dispatch(supplierRequestsLoad());

  return getSupplierRequests()
    .then((res) => {
      dispatch(supplierRequestsLoadSuccess(res.data.requests));
    })
    .catch(e => {
      dispatch(supplierRequestsLoadError(storableError(storableError(e))));
      // This is thrown so that form can be cleared
      // after a timeout on changePassword submit handler
      throw e;
    });
};

export const loadBuyerRequests = () => (dispatch, getState, sdk) => {
  dispatch(buyerRequestsLoad());

  return getBuyerRequests()
    .then((res) => {
      dispatch(buyerRequestsLoadSuccess(res.data.requests));
    })
    .catch(e => {
      dispatch(buyerRequestsLoadError(storableError(storableError(e))));
      // This is thrown so that form can be cleared
      // after a timeout on changePassword submit handler
      throw e;
    });
};

export const deleteRequest = (key) => (dispatch, getState, sdk) => {
  dispatch(deleteRequestLoad());

  return deleteRegistrationRequest(key)
    .then(() => {
      dispatch(deleteRequestLoadSuccess());
      dispatch(loadRequests());
    })
    .catch(e => {
      dispatch(deleteRequestLoadError(storableError(storableError(e))));
      // This is thrown so that form can be cleared
      // after a timeout on changePassword submit handler
      throw e;
    });
};

export const approveRequest = (request) => (dispatch) => {
  dispatch(approveRequestLoad());
  return approveRegistrationRequest(request.key)
    .then(() => {
      dispatch(approveRequestLoadSuccess());
      dispatch(send({
        subject: 'Great news - Your registration has been approved!',
        template: 'user-registration-approve',
        data: {
          name: `${request.data.firstName}`,
          code: request.key
        },
        to: request.data.businessEMail
      }));
      dispatch(loadRequests());
    })
    .catch(e => {
      dispatch(approveRequestLoadError(storableError(storableError(e))));
      // This is thrown so that form can be cleared
      // after a timeout on changePassword submit handler
      throw e;
    });
};

export const rejectRequest = (request, comment) => (dispatch) => {
  dispatch(rejectRequestLoad());

  return rejectRegistrationRequest(request.key, comment)
    .then(() => {
      dispatch(rejectRequestLoadSuccess());
      dispatch(send({
        subject: 'Thank you for your interest in joining Vegshelf!',
        template: 'user-registration-reject',
        data: {
          comment,
          name: `${request.data.firstName}`
        },
        to: request.data.businessEMail
      }));
      dispatch(loadRequests());

    })
    .catch(e => {
      dispatch(rejectRequestLoadError(storableError(storableError(e))));
      // This is thrown so that form can be cleared
      // after a timeout on changePassword submit handler
      throw e;
    });
};
