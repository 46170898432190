import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form as FinalForm, Field } from 'react-final-form';
import { intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { Form, IconHourGlass } from '../../components';
// added components for displaying Registration modal
import { displayRegistrationConfirmDialog } from "../../ducks/Modal.duck";
import { connect } from "react-redux";
import { compose } from "redux";

import css from './TopbarSearchForm.module.css';

const identity = v => v;

const KEY_CODE_ENTER = 13;

class TopbarSearchFormComponent extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.searchInput = null;
  }

  onChange(event) {
    this.searchValue = event.target.value;
  }

  onKeyDown(e) {
    if (this.searchInput.value === '') return;
    if (e.keyCode === KEY_CODE_ENTER) {

      this.props.onSubmit({ search: this.searchInput.value });
    }
  }

  render() {

    // 16.07.23 Vegshelf freeze: added props for displaying Registration modal for Vegshelf freeze
    const { onShowRegistrationConfirmDialog } = this.props;

    return (
      <FinalForm
        {...this.props}
        render={formRenderProps => {
          const { rootClassName, className, desktopInputRoot, intl, isMobile, initialValues } = formRenderProps;

          const classes = classNames(rootClassName, className);
          const desktopInputRootClass = css.desktopInputRoot;
          // Allow form submit only when the place has changed
          const preventFormSubmit = e => e.preventDefault();

          return (
            <Form className={classes} onSubmit={preventFormSubmit}>
              <Field
                name="location"
                format={identity}
                render={({ input, meta }) => {
                  const { onChange, ...restInput } = input;

                  // Merge the standard onChange function with custom behaviur. A better solution would
                  // be to use the FormSpy component from Final Form and pass this.onChange to the
                  // onChange prop but that breaks due to insufficient subscription handling.
                  // See: https://github.com/final-form/react-final-form/issues/159
                  const searchOnChange = value => {
                    onChange(value);
                    this.onChange(value);
                  };

                  const searchInput = { ...restInput, onChange: searchOnChange };
                  return (

                    <div className={desktopInputRootClass}>
                      <div className={css.desktopIcon}>
                        <IconHourGlass />
                      </div>
                      <input
                        className={css.desktopInput}
                        type="search"
                        autoComplete="off"
                        placeholder={intl.formatMessage({ id: 'TopbarSearchForm.placeholder' })}
                        name={searchInput.name}
                        // value={search}
                        onChange={this.onChange}
                        // 16.07.23 Vegshelf freeze: changed onKeyDown to show registration modal in order to disable Search for Vegshelf freeze
                        onKeyDown={onShowRegistrationConfirmDialog}
                        //onKeyDown={this.onKeyDown}
                        defaultValue={initialValues?.search}
                        ref={node => {
                          this.searchInput = node;
                        }}
                      />
                    </div>
                  );
                }}
              />
            </Form>
          );
        }}
      />
    );
  }
}

const { func, string, bool } = PropTypes;

TopbarSearchFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  desktopInputRoot: null,
  isMobile: false,
};

TopbarSearchFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  desktopInputRoot: string,
  onSubmit: func.isRequired,
  isMobile: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

//const TopbarSearchForm = injectIntl(TopbarSearchFormComponent);

// 16.07.23 Vegshelf freeze: added mapStateToProps for displaying Registration modal
const mapStateToProps = (state) => ({});

// 16.07.23 Vegshelf freeze: added mapDispatchToProps for displaying Registration modal
const mapDispatchToProps = (dispatch) => ({
  onShowRegistrationConfirmDialog: () => dispatch(displayRegistrationConfirmDialog(true, true)),
});

const TopbarSearchForm = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
)(TopbarSearchFormComponent);

export default TopbarSearchForm;
