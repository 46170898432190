import classNames from "classnames";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import facebookImage from "../../assets/VegshelfFacebook-1200x630.png";
import twitterImage from "../../assets/VegshelfTwitter-600x314.png";
import checkGreen from "../../assets/check-circle-green.svg";
import editImage from "../../assets/pencil-000000.svg";
import heartFill from "../../assets/heart-fill-000000.svg";
import heartEmpty from "../../assets/heart-000000.svg";
import {
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  NamedLink,
  Page,
  ProductContainer,
  SingleProductCartDialog,
  Menu,
  MenuContent,
  MenuItem,
  MenuLabel,
} from "../../components";
import { Select } from "../../components/Select/Select";
import config from "../../config";
import { ShoppingCartPage, TopbarContainer } from "../../containers";
import { addOrRemoveFavourites } from "../../ducks/favourite.duck";

import { displaySingleProductCartDialog } from '../../ducks/CartModal.duck';

import { displayRegistrationConfirmDialog } from "../../ducks/Modal.duck";
import { shoppingCartAddProduct, shoppingCartClearAddedProduct } from "../../ducks/shopping-cart.duck";
import { parseListingState, ProductStatesTranslates } from "../../ducks/supplier-company.duck";
import { isScrollingDisabled } from "../../ducks/UI.duck";
import { slugify } from "../../util/urlTrimming";

import ProductDictionaries from "../../translations/productDictionaries";
import getProductDictionaries from "../../translations/productDictionaries";
import { FormattedMessage, injectIntl } from "../../util/reactIntl";
import { shippedCountriesMsg } from "../SupplierProfilePage/SupplierProfilePage";
import { loadProductDetails } from "./ProductDetailsPage.duck";
import { localePart, storedLocale } from "../../components/Wrapper/ClassWrapper";
import checkIcon from '../../assets/check-000000.svg';

import css from "./ProductDetailsPage.module.css";

const locale = storedLocale() || localePart();

function formatPrice(price) {
  const msrp = parseFloat(price);
  return (Math.round(msrp * 100) / 100).toFixed(2);
}
export class ProductDetailsPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewLastIndex: 6,
      imageIndex: 0,
      quantity: null,
      goToCart: false,
      zoomEnable: false,
      imageMouseX: 0,
      imageMouseY: 0,
    };
    this.onImageClick = this.onImageClick.bind(this);
    this.onChangeQuantity = this.onChangeQuantity.bind(this);
    this.onAddToCartClick = this.onAddToCartClick.bind(this);
    this.onFavouriteButtonClick = this.onFavouriteButtonClick.bind(this);
    this.onImageMouseMove = this.onImageMouseMove.bind(this);
    this.onShowAllClick = this.onShowAllClick.bind(this);
    this.dictionaries = ProductDictionaries();
  }

  onImageMouseMove(e, zoomEnable) {
    const imagePosition = e.target.getBoundingClientRect();
    const x = e.clientX - imagePosition.left;
    const y = e.clientY - imagePosition.top;
    this.setState({ zoomEnable, imageMouseX: x, imageMouseY: y });
  }

  onImageClick(i) {
    this.setState({ ...this.state, imageIndex: i });
  }

  onShowAllClick() {
    this.setState({ ...this.state, viewLastIndex: this.state.viewLastIndex + 6 });
  }

  onFavouriteButtonClick() {
    this.props.onAddOrRemoveFavourite(this.props.productDetails);
  }

  async onAddToCartClick() {
    const productInfo = this.props.productDetails?.attributes;
    const productPublicData = productInfo?.publicData;
    const images = productPublicData?.productImages || [];
    const dictionaries = getProductDictionaries();
    const shipped = productPublicData?.leadTime
      ? dictionaries.leadTimes.filter((l) => l.code === productPublicData?.leadTime)[0]?.name : null;
    const shippingFee = this.props.supplierDetails?.attributes?.publicData?.shippingFeeCountries
      .find(({ name }) => name === this.props.buyerCurrentCompany?.attributes?.publicData?.country)?.shippingFee;

    await this.props.onAddProductToCart({
      id: this.props.productDetails.id.uuid,
      productName: `${productInfo.title}`,
      price: parseFloat(productPublicData?.wholesalePrice),
      unitsOfPackage: productPublicData?.packagingUnitCount,
      mainImageUrl: images[0]?.url,
      count: this.state.quantity || parseFloat(productPublicData?.packagingUnitCount),
      brandId: productPublicData?.brandId,
      brandName: productPublicData?.brandName,
      bardImageUrl: this.props.supplierDetails?.attributes?.publicData?.logoUrl,
      shippingDays: this.props.supplierDetails?.attributes?.publicData?.leadTime,
      productShippingDays: shipped,
      productShippingDaysCode: productPublicData?.leadTime,
      shippingFee,
      /**
       * VATItems - individual product VAT value.
       */
      VATItems: productPublicData?.vat,
      brandCountryCode: this.props.supplierDetails?.attributes?.publicData?.country,
      currentUserCountry: this.props.buyerCurrentCompany?.attributes?.publicData?.country,
      deliveryData: this.props.buyerCurrentCompany?.attributes?.publicData?.deliveryAddress,
      weight: productPublicData.weight,
      weightUnit: productPublicData.weightUnit,
      // Additional prop added for subscription based model pilot
      // pilotUser later accessed in shopping-cart.duck.js file
      pilotUser: this.props.supplierDetails?.attributes?.publicData?.pilotUser,
    });
    this.setState({ ...this.state, goToCart: true });
  }

  onChangeQuantity(event) {
    this.setState({ ...this.state, quantity: parseFloat(event.target.value) });
  }

  render() {
    const {
      // history,
      intl,
      // location,
      scrollingDisabled,
      // supplierOtherProductsError,
      // supplierOtherProductsProgress,
      supplierOtherProducts,
      // productDetailsError,
      // productDetailsProgress,
      productDetails,
      // supplierDetailsError,
      addedProduct,
      supplierDetails,
      isAuthenticated,
      favouriteProductIds,
      currentUserType,
      onShowRegistrationConfirmDialog,
      onShoppingCartClearAddedProduct,

      // onShowSingleProductCartDialog added for Soft Launch single vendor shopping cart
      onShowSingleProductCartDialog,

      supplierCompanyProducts,
      supplierCurrentCompany,
      buyerCurrentCompany,

      // shoppingCartProductCount and shoppingCart added for Soft Launch single vendor shopping cart
      shoppingCartProductCount,
      shoppingCart,
    } = this.props;

    const isOwnProduct = productDetails?.attributes?.publicData.brandId === supplierCurrentCompany?.id?.uuid;
    const isSupplier = supplierCurrentCompany?.id?.uuid === supplierDetails?.id?.uuid;
    const addToCartText = intl.formatMessage({ id: "SearchProductsPage.addToCart" });
    const IsNotAvailableToShipText = intl.formatMessage({ id: "SearchProductsPage.isNotAvailableToShip" });
    const schemaImage = `${config.canonicalRootURL}${facebookImage}`;
    const productInfo = productDetails?.attributes;
    const productPublicData = productInfo?.publicData;
    const siteTitle = config.siteTitle;
    const supplierProductName = productInfo?.title;
    const schemaTitle = intl.formatMessage({ id: "ProductDetailsPageEdit.schemaTitle" }, { supplierProductName, siteTitle });
    const schemaDescription = intl.formatMessage({ id: "ProductDetailsPageEdit.schemaDescription" }, { supplierProductName, siteTitle });
    const shippingCountriesMessage = shippedCountriesMsg(
      supplierDetails?.attributes?.publicData?.shippingFeeCountries || []
    );
    const currentUserCountry = buyerCurrentCompany?.attributes?.publicData?.country;
    const availableCountries = supplierDetails?.attributes?.publicData?.shippingFeeCountries;
    const isAvailableInYourCountry = availableCountries?.find((c) => c.isShip && c.name === currentUserCountry);
    const images = productPublicData?.productImages || [];
    const price = parseFloat(productPublicData?.wholesalePrice);
    const displayPrice = (Math.round(price * 100) / 100).toFixed(2);
    const msrp = parseFloat(productPublicData?.suggestedPrice);
    const displayMsrp = (Math.round(msrp * 100) / 100).toFixed(2);
    const styleMainImage = {
      "--mouse-zoom-X": `${0 - this.state.imageMouseX}px`,
      "--mouse-zoom-Y": `${0 - this.state.imageMouseY}px`,
    };
    if (images && images.length > 0) {
      styleMainImage.backgroundImage = `url('${images[this.state.imageIndex].url}')`;
    }
    const styleBrandLogo = {};
    if (supplierDetails?.attributes?.publicData?.logoUrl) {
      styleBrandLogo.backgroundImage = `url('${supplierDetails?.attributes?.publicData?.logoUrl}')`;
    }

    const dictionaries = getProductDictionaries();
    const shipped = productPublicData?.leadTime ? dictionaries.leadTimes.filter(
      l => l.code === productPublicData?.leadTime)[0]?.name : null;

    const properties = productPublicData?.properties ? dictionaries.productProperties.filter(
      l => productPublicData?.properties.indexOf(l.code) > -1) : [];
    let allProducts = isSupplier ? supplierCompanyProducts : supplierOtherProducts?.data;
    allProducts = allProducts || [];
    allProducts = [
      ...allProducts.filter((p) => (parseListingState(p) === "published")),
      ...allProducts.filter((p) => (parseListingState(p) === "outOfStock")),
    ];
    let viewProducts = (allProducts || []).filter(p => {
      return p.id.uuid !== productDetails?.id?.uuid;
    });
    const lastIndex = isAuthenticated ? this.state.viewLastIndex : 3;
    const showExpandButton = lastIndex < viewProducts.length;
    //const showExpandButton = this.state.viewLastIndex < viewProducts.length;
    viewProducts = viewProducts.slice(0, lastIndex/*this.state.viewLastIndex*/);
    const chooseQuantityItems = [];
    const chooseStep = productPublicData?.packagingUnitCount || 1;
    for (let i = 1; i < 31; i++) {
      chooseQuantityItems.push(chooseStep * i);
    }
    const lockBuy = productPublicData?.lockBuy === true;
    const isFavourite = favouriteProductIds ? favouriteProductIds.indexOf(productDetails?.id?.uuid) > -1 : false;
    const favouriteButtonCss = classNames(css.fovouriteButtonCss, { [css.hideState]: currentUserType !== "buyer" });
    const registeredUserPrice = classNames(css.productPriceContainer, { [css.visitorPriceContainer]: isAuthenticated === false });

    let productState = parseListingState(productDetails);
    const isOutOfStock = productState === "outOfStock";
    const stateCss = classNames(css.productState, { [css[productState]]: true });
    const valueWithCalculatedPrice = (value) => {
      const priceOfQuantity = Number(displayPrice * value).toFixed(2);
      return (`${value} (€${priceOfQuantity})`);
    };
    const visitorValue = (value) => {
      return (`${value}`);
    };

    const isSupplierUser = currentUserType !== "buyer";
    const addToCartDisabled = !isAuthenticated
      || isSupplierUser
      || lockBuy
      || addedProduct
      || !isAvailableInYourCountry;

    const emptyStateOptions = {
      containerOffsetClassName: "searchOffset",
      iconStylesClassName: "searchIcon",
      titleMessageId: "SearchProductsPage.noSearchResultTitle",
      textMessageId: "SearchProductsPage.noSearchResultText",
    };

    const shoppingCartBrands = shoppingCart?.brands || [];
    const shoppingCartProducts = shoppingCartBrands
      .map((b) => (b?.products || []))
      .reduce(function (a, b) {
        return a.concat(b);
      }, []);

    const mobileCartNotification =
      <div className={css.shoppingButton}>
        {!!addedProduct ? (
          <div className={css.bkPopupHandler} onClick={() => onShoppingCartClearAddedProduct()}>&nbsp;</div>
        ) : null}
        <Menu isOpen={!!addedProduct} onToggleActive={(s) => {
          !s && onShoppingCartClearAddedProduct()
        }}>
          <MenuLabel className={css.menuLabel}>
          </MenuLabel>
          <MenuContent className={css.addedProductNotification}>
            <MenuItem key='addedProduct'>
              <div className={css.addedProduct}>
                <h1>
                  <img className={css.checkIcon} src={checkIcon} alt="" />
                  <FormattedMessage id="TopbarDesktop.cart.addedToCart" />
                </h1>
                {shoppingCartProducts.map((p, idx) => {
                  const style = {};
                  if (p.mainImageUrl) {
                    style.backgroundImage = `url('${p.mainImageUrl}')`;
                  }
                  return (
                    <div key={`scp${idx}${p.id}`}>
                      <NamedLink
                        name="ProductDetailsPage"
                        className={css.addedProductDetails}
                        onClick={() => onShoppingCartClearAddedProduct()}
                        params={{ product: slugify(p.productName), id: p.id, locale: locale }}
                      >
                        <div className={css.addedProductPhoto} style={style}>&nbsp;</div>
                        <div className={css.productDetails}>
                          <h1 className={css.productTitle}>
                            {p.productName}
                          </h1>
                          <h2 className={css.productSubTitle}>€{formatPrice(p.totalPrice)}<FormattedMessage id="TopbarDesktop.cart.exclVAT" /></h2>
                          <h2 className={css.productSubTitle}>{p.count}<FormattedMessage id="TopbarDesktop.cart.units" /></h2>
                        </div>
                      </NamedLink>
                    </div>
                  );
                })}
                <div className={css.shoppingCartButtons}>
                  <NamedLink
                    onClick={() => onShoppingCartClearAddedProduct()}
                    className={css.goToShoppingCart}
                    name="ShoppingCartPage"
                  >
                    <FormattedMessage id="TopbarDesktop.cart.goToCart" />
                  </NamedLink>
                </div>
              </div>
            </MenuItem>
          </MenuContent>
        </Menu>
      </div>
      ;

    // The following functions are for Soft Launch to limit shopping cart only to single brand

    // Gets Brand ID of curent product listing.
    const productListingBrandId = productPublicData?.brandId;

    // Gets Brand ID of the Brand in the Shopping Cart.
    const shoppingCartBrandId = shoppingCart?.brands[0]?.brandId;

    // Compares if current product listing Brand ID is the same as the Brand ID of the Brand in the Shopping Cart
    const brandIdComparison = productListingBrandId === shoppingCartBrandId;

    // Validates if the shopping cart is empty. If not, compares Brand IDs. If IDs match, allows to add product to shopping cart.
    // If IDs do not match, throws modal window informing user that only single brand can be added to cart.
    // Finally, if shopping cart is empty, allows to add product to shopping cart.
    const productAddToCartCheck = shoppingCartProductCount ? brandIdComparison ? () => this.onAddToCartClick() : onShowSingleProductCartDialog : () => this.onAddToCartClick();

    // Validates if user is Product's Supplier. Different messages used for Product's Supplier and Visitors/Buyers/Other Suppliers.
    const productSupplierMessage = <div className={stateCss}>{ProductStatesTranslates[productState]}</div>;
    const otherUserMessage = <div className={css.productStateNonSupplier}><FormattedMessage id="ProductDetailsPagePublic.backSoon" /></div>;
    // Commented out on 10.04.2023
    // Commented out to temporarily solve product status "blinking" on slow page load.
    // TO DO: fix required for isSupplier: outOfStockMessage should run only when isSupplier fully loaded (currently switching from 'true' to 'false').
    //const outOfStockMessage = isAuthenticated && !buyerTest && isSupplier ? productSupplierMessage : isOutOfStock && !isSupplier ? otherUserMessage : null;
    //const outOfStockMessage = isSupplier ? productSupplierMessage : isOutOfStock && !isSupplier ? otherUserMessage : null;
    const outOfStockMessage = isOutOfStock ? otherUserMessage : null;

    return (
      <Page
        className={css.root}
        scrollingDisabled={scrollingDisabled}
        contentType="website"
        description={schemaDescription}
        title={schemaTitle}
        robots='index'
        facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
        twitterImages={[
          { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
        ]}
        schema={{
          "@context": "http://schema.org",
          "@type": "WebPage",
          description: schemaDescription,
          name: schemaTitle,
          image: [schemaImage],
        }}
      >
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer productCategoryCode={productPublicData?.rootCategory} />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div className={css.pageContainer}>
              {mobileCartNotification}
              <div className={css.mainProductInfo}>
                <div className={css.infoWrapper}>
                  <div className={css.productImages}>
                    <div className={classNames(css.mainImageContainer,
                      { [css.zoomMode]: this.state.zoomEnable, [css.isOutOfStock]: isOutOfStock })}
                      style={styleMainImage} onMouseMove={(e) => this.onImageMouseMove(e, true)}
                      onMouseOver={(e) => this.onImageMouseMove(e, true)}
                      onMouseOut={(e) => this.onImageMouseMove(e, false)}>&nbsp;
                      <div className={css.outOfStockMask}>&nbsp;</div>
                      {outOfStockMessage}
                      {currentUserType === "buyer" ? (
                        <a onClick={this.onFavouriteButtonClick} className={favouriteButtonCss}>
                          <div className={css.fovouriteProductButtonIcon}>
                            <img className={css.heartIcon} src={isFavourite ? heartFill : heartEmpty} alt="" />
                          </div>
                        </a>
                      ) : null}
                      {currentUserType === "supplier" && isSupplier && productDetails?.id?.uuid ? (
                        <NamedLink
                          name="SupplierEditProductPage"
                          params={{ id: productDetails.id.uuid, locale: locale }}
                          className={css.editButton}
                        >
                          <img src={editImage} alt="" /><FormattedMessage id="ProductDetailsPageEdit.editListing" /></NamedLink>
                      ) : null}
                    </div>
                    <div className={css.otherImageContainer} style={{ display: (images.length > 0 ? "flex" : "none") }}>
                      {images.map((img, i) => {
                        const style = { backgroundImage: `url('${img.url}')` };
                        const imageCss = classNames(css.imagePreview,
                          { [css.imagePreviewSelect]: i === this.state.imageIndex, [css.isOutOfStock]: isOutOfStock });
                        return (
                          <div key={img.id} className={imageCss} style={style} onClick={() => this.onImageClick(i)}>
                            &nbsp;
                            <div className={css.outOfStockMask}>&nbsp;</div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className={css.productInfo}>
                    <div className={css.brandInfo}>
                      <div className={css.brandLogo} style={styleBrandLogo}>
                        {!supplierDetails?.attributes?.publicData?.logoUrl ?
                          <span className={css.companyLogoInitials}>
                            {supplierDetails?.attributes?.publicData?.companyName?.charAt(0)}
                          </span>
                          : null
                        }
                      </div>
                      <div>
                        <div className={css.brandTitle}>
                          {supplierDetails?.id?.uuid ? (
                            <NamedLink
                              name={isSupplier ? "SupplierProfilePage" : "SupplierDetailsPage"}
                              params={isSupplier ? { locale: locale } : { brand: slugify(supplierDetails?.attributes?.publicData?.brandName), id: supplierDetails?.id?.uuid, locale: locale }}
                            >
                              {supplierDetails?.attributes?.publicData?.brandName}&nbsp; • &nbsp;{supplierDetails?.attributes?.publicData?.city},&nbsp;{supplierDetails?.attributes?.publicData?.country}
                            </NamedLink>) : null}
                        </div>
                        <div className={css.brandSubTitle}><FormattedMessage id="ProductDetailsPagePublic.minOrderValue" />
                          €&nbsp;{supplierDetails?.attributes?.publicData?.moq}</div>
                      </div>
                    </div>
                    <h1
                      className={css.productName}>{productInfo?.title},&nbsp;{productPublicData?.weight}&nbsp;{(productPublicData?.weightUnitCustom || productPublicData?.weightUnit)}</h1>
                    <div className={registeredUserPrice/*css.productPriceContainer*/}>
                      <h4 className={css.wholesalePrice}>
                        {isAuthenticated ? (
                          <>€&nbsp;{displayPrice}<FormattedMessage id="ProductDetailsPagePublic.wholesalePriceExclVAT" />&nbsp;&nbsp;•&nbsp;&nbsp;</>
                        ) : (
                          <a className={css.registrationButton} onClick={onShowRegistrationConfirmDialog}>
                            <FormattedMessage id="ProductDetailsPagePublic.wholesalePrice" />
                          </a>
                        )}
                      </h4>
                      <h4 className={css.productMSRP}>
                        <FormattedMessage id="ProductDetailsPagePublic.suggestedRetailPrice" />€&nbsp;{displayMsrp}
                      </h4>
                    </div>
                    <h4 className={css.productSoldBy}>
                      <FormattedMessage id="ProductDetailsPagePublic.soldBy" />
                      {productPublicData?.packagingUnitCount}
                      &nbsp;
                      {productPublicData?.packWeightUnitCustom ?
                        productPublicData?.packWeightUnitCustom
                        : 
                        <>
                          <FormattedMessage id="ProductDetailsPagePublic.units" />
                          {this.dictionaries.packUnitTypes.find((w) => (w.code === productPublicData?.packWeightUnit))?.name}
                        </>
                      }
                    </h4>
                    {isAuthenticated ? (
                      <div>
                        <label htmlFor="chooseQuantitySelector" className={css.chooseQuantityLabel}>
                          <FormattedMessage id="ProductDetailsPagePublic.chooseQuantity" />
                        </label>
                        {!isNaN(displayPrice) && (
                          <Select
                            items={chooseQuantityItems}
                            defaultValue={valueWithCalculatedPrice(productPublicData?.packagingUnitCount)}
                            displaySelectedValue={valueWithCalculatedPrice}
                            disabled={isSupplierUser || !isAvailableInYourCountry || isOutOfStock}
                            customOnClick={this.onChangeQuantity}
                          />
                        )}

                        {/* button for Soft Launch for single vendor shopping cart */}
                        <button
                          disabled={addToCartDisabled}
                          className={classNames(css.addToCartButton, { [css.addToCardLock]: addedProduct })}
                          onClick={productAddToCartCheck}
                        >
                          {!isAvailableInYourCountry && !isSupplierUser ? IsNotAvailableToShipText : addToCartText}
                        </button>
                        <SingleProductCartDialog onFavouriteButtonClick={this.onFavouriteButtonClick} />

                        {/* button for multi-vendor shopping cart
                      <button
                        disabled={addToCartDisabled}
                        className={classNames(css.addToCartButton, { [css.addToCardLock]: addedProduct })}
                        onClick={() => this.onAddToCartClick()}
                      >
                        {!isAvailableInYourCountry && !isSupplierUser ? IsNotAvailableToShipText : addToCartText}
                      </button>
                      */}

                      </div>
                    ) : (
                      <div>
                        <label htmlFor="chooseQuantitySelector" className={css.chooseQuantityLabel}>
                          <FormattedMessage id="ProductDetailsPagePublic.chooseQuantity" />
                        </label>
                        {!isNaN(displayPrice) && (
                          <Select
                            items={chooseQuantityItems}
                            defaultValue={visitorValue(productPublicData?.packagingUnitCount)}
                            displaySelectedValue={visitorValue}
                            disabled={isOutOfStock}
                            customOnClick={this.onChangeQuantity}
                          />
                        )}
                        <button
                          disabled={isOutOfStock}
                          className={css.addToCartButton}
                          onClick={onShowRegistrationConfirmDialog}
                        >
                          {isOutOfStock ? <FormattedMessage id="SupplierProductsPage.outOfStock" /> : <FormattedMessage id="SectionHero.signUpToShop" />}
                        </button>
                      </div>
                    )}
                    <h5 className={css.productShipped}>
                      <FormattedMessage id="ProductDetailsPagePublic.shipsFromBrand" />({shipped})
                      {shippingCountriesMessage && ` to ${shippingCountriesMessage}`}
                    </h5>
                    <h5 className={css.productFieldRow}>
                      <span className={css.fieldLabel}><FormattedMessage id="ProductDetails.ingredients" /></span>
                      <span className={css.fieldValue}>{productPublicData?.ingredientList}</span>
                    </h5>
                    <h5 className={css.productFieldRow}>
                      <span className={css.fieldLabel}><FormattedMessage id="ProductDetails.certification" /></span>
                      <span className={css.fieldValue}>{productPublicData?.certification}</span>
                    </h5>
                    <h5 className={css.productFieldRow}>
                      <span className={css.fieldLabel}><FormattedMessage id="ProductDetails.gtin" /></span>
                      <span className={css.fieldValue}>{productPublicData?.eanOrGtinNumber}</span>
                    </h5>
                    <h5 className={css.productFieldRow}>
                      <span className={css.fieldLabel}><FormattedMessage id="ProductDetails.netWeight" /></span>
                      <span className={css.fieldValue}>
                        {productPublicData?.weight}&nbsp;{(productPublicData?.weightUnitCustom || productPublicData?.weightUnit)}
                      </span>
                    </h5>
                    <h5 className={css.productFieldRow}>
                      <span className={css.fieldLabel}><FormattedMessage id="ProductDetails.shelfLife" /></span>
                      <span className={css.fieldValue}>{productPublicData?.shelfLife}<FormattedMessage id="ProductDetails.shelfLifeMonths" /></span>
                    </h5>
                    <h5 className={css.productFieldRow}>
                      <span className={css.fieldLabel}><FormattedMessage id="ProductDetails.storageTemperature" /></span>
                      <span className={css.fieldValue}>
                        {productPublicData?.requiredTemperature ?
                          this.dictionaries.requiredTemperatures
                            .find((f) => (f.code === productPublicData?.requiredTemperature)).name
                          : null
                        }
                      </span>
                    </h5>
                    <h5 className={css.productFieldRow}>
                      <span className={css.fieldLabel}><FormattedMessage id="ProductDetails.labelLanguage" /></span>
                      <span className={css.fieldValue}>{productPublicData?.languageOfProductLabel}</span>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <div className={css.propertiesWrapper}>
              <div className={css.propertiesContainer}>
                <h2 className={css.propertiesTitle}>
                  <FormattedMessage id="ProductDetailsPagePublic.propertiesTitle1" />
                  <i><FormattedMessage id="ProductDetailsPagePublic.propertiesHighlighted" /></i>
                  <FormattedMessage id="ProductDetailsPagePublic.propertiesTitle2" />
                </h2>
                <div className={css.properties}>
                  {properties.map((p) => (<div key={p.code} className={css.property}><img className={css.propertyIcon} src={`${p.iconUrl}`} alt={p.name} />{p.name}</div>))}
                </div>
              </div>
            </div>
            <div className={css.productDescriptionsSection}>
              <div className={css.productDescriptions}>
                <div className={css.productDescriptionContainer}>
                  <div className={css.productDescriptionTitle}><FormattedMessage id="ProductDetails.productDescription" /></div>
                  <div className={css.productDescriptionContent}>{productInfo?.description}</div>
                  {productPublicData?.freeSamples === "Yes" ? (
                    <div className={css.productSamples}>
                      <div className={css.productSamplesLabel}><FormattedMessage id="ProductDetailsPagePublic.samples" /></div>
                      <div className={css.productSamplesContent}>
                        <img src={checkGreen} alt="" /><FormattedMessage id="ProductDetailsPagePublic.freeSamples" />
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className={css.companyInfoContainer}>
                  <div className={css.aboutCompany}>
                    <div className={css.aboutLabel}><FormattedMessage id="ProductDetailsPagePublic.aboutCompany" /></div>
                    <div className={css.aboutCompanyContent}>
                      {supplierDetails?.attributes?.description}
                    </div>
                    {supplierDetails?.id?.uuid ? (
                      <div className={css.companyButtons}>
                        <NamedLink
                          name={isSupplier ? "SupplierProfilePage" : "SupplierDetailsPage"}
                          params={isSupplier ? { locale: locale } : { brand: slugify(supplierDetails?.attributes?.publicData?.brandName), id: supplierDetails?.id?.uuid, locale: locale }}
                          className={css.visitCompanyProfile}
                        >
                          <FormattedMessage id="ProductDetailsPagePublic.discoverBrand" />
                        </NamedLink>
                        {isAuthenticated && currentUserType === "buyer" ? (
                          <NamedLink
                            name="BuyerSendMessagePage"
                            className={css.contactBrandButton}
                            params={{ receiver: supplierDetails?.id?.uuid, title: productInfo?.title || "Re", locale: locale }}
                          >
                            <FormattedMessage id="ProductDetailsPagePublic.messageBrand" />
                          </NamedLink>) : null}
                        {/*TODO: Communication supplier-to-supplier temporary disabled.*/}
                        {/*{isAuthenticated && currentUserType === "supplier" && !isOwnProduct ? (*/}
                        {/*  <NamedLink*/}
                        {/*    name="SupplierSendMessagePage"*/}
                        {/*    className={css.contactBrandButton}*/}
                        {/*    params={{*/}
                        {/*      receiver: supplierDetails?.id?.uuid, title: productInfo?.title || "Re",*/}
                        {/*    }}*/}
                        {/*  >*/}
                        {/*    Message brand*/}
                        {/*  </NamedLink>) : null}*/}
                      </div>) : null}
                  </div>
                </div>
              </div>
            </div>
            {viewProducts.length > 0 ? (
              <div className={css.otherSupplierProductsContainer}>
                <div className={css.otherSupplierProducts}>
                  <h1>
                    <span>{isSupplier ? <FormattedMessage id="ProductDetailsPageEdit.otherProducts" /> : <FormattedMessage id="ProductDetailsPageEdit.otherProductsFromBrand" />}</span>
                    {isSupplier ? (
                      <NamedLink name="SupplierProductsPage" className={css.manageProducts}>
                        <img src={editImage} alt="" />
                        <FormattedMessage id="ProductDetailsPageEdit.manageProductListings" />
                      </NamedLink>
                    ) : null}
                  </h1>
                  <div className={css.otherProductsContainer}>
                    <ProductContainer
                      products={viewProducts}
                      rowItemCount={3}
                      showState={isSupplier}
                      align="left"
                      showEditButton={isSupplier}
                      emptyStateOptions={emptyStateOptions}
                    />
                  </div>
                  {showExpandButton && !isAuthenticated ? (
                    <div className={css.showAllProduct}>
                      <a onClick={onShowRegistrationConfirmDialog} className={css.showAllButton}>
                        <FormattedMessage className={css.addProductButtonLabel} id="SupplierProfilePage.showAll" />
                      </a>
                    </div>
                  ) : null}
                  {showExpandButton && isAuthenticated ? (
                    <div className={css.showAllProduct}>
                      <a onClick={this.onShowAllClick} className={css.showAllButton}>
                        <FormattedMessage className={css.addProductButtonLabel} id="SupplierProfilePage.showAll" />
                      </a>
                    </div>
                  ) : null}
                </div>
              </div>
            ) : null}
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

ProductDetailsPageComponent.defaultProps = {
  currentUserListing: null,
  currentUserListingFetched: false,
};

ProductDetailsPageComponent.propTypes = {};

const mapStateToProps = (state) => {
  const {
    supplierOtherProductsError,
    supplierOtherProductsProgress,
    supplierOtherProducts,
    productDetailsError,
    productDetailsProgress,
    productDetails,
    supplierDetailsError,
    supplierDetailsProgress,
    supplierDetails,
  } = state.ProductDetailsPage;
  const { isAuthenticated } = state.Auth;
  const { buyerCurrentCompany } = state.buyerCompany;

  // shoppingCartProductCount and shoppingCart added for Soft Launch single vendor shopping cart
  const { shoppingCartProductCount, shoppingCart, addedProduct } = state.ShoppingCartStore;

  // addedProduct
  const { supplierCurrentCompany, supplierCompanyProducts } = state.supplierCompany;
  const { currentUserType } = state.user;

  return {
    scrollingDisabled: isScrollingDisabled(state),
    supplierOtherProductsError,
    supplierOtherProductsProgress,
    supplierOtherProducts,
    productDetailsError,
    productDetailsProgress,
    productDetails,
    supplierDetailsError,
    supplierDetailsProgress,
    supplierDetails,
    isAuthenticated,
    currentUserType,
    buyerCurrentCompany,
    supplierCurrentCompany,
    favouriteProductIds: state.Favourites.productIds,
    supplierCompanyProducts,
    addedProduct,

    // shoppingCartProductCount and shoppingCart added for Soft Launch single vendor shopping cart
    shoppingCartProductCount,
    shoppingCart,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onAddProductToCart: (values) => {
    return dispatch(ShoppingCartPage.loadData())
      .then(() => {
        return dispatch(shoppingCartAddProduct(values));
      });
  },
  onAddOrRemoveFavourite: (product) => dispatch(addOrRemoveFavourites({ product })),
  onShowRegistrationConfirmDialog: () => dispatch(displayRegistrationConfirmDialog(true, true)),
  onShoppingCartClearAddedProduct: () => dispatch(shoppingCartClearAddedProduct()),

  // onShowSingleProductCartDialog added for Soft Launch single vendor shopping cart
  onShowSingleProductCartDialog: () => dispatch(displaySingleProductCartDialog(true)),
});

const ProductDetailsPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
)(ProductDetailsPageComponent);

ProductDetailsPage.loadData = (args) => (dispatch) => {
  return Promise.all([
    dispatch(loadProductDetails({ id: args.id, supplierMode: args.supplierMode })),
    dispatch(ShoppingCartPage.loadData())
  ]);
};

ProductDetailsPage.setInitialValues = () => {
};

export default ProductDetailsPage;