import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { injectIntl, FormattedMessage } from "../../util/reactIntl";
import { isScrollingDisabled } from "../../ducks/UI.duck";
import classNames from "classnames";
import css from "./BuyerInvoicesPage.module.css";
import { tableColumns } from "./mocks";
import EmptyStateComponent from "../../components/ProductEmptyState/ProductEmptyState";
import LoaderComponent from "../../components/Loader/Loader";
import { format } from "date-fns";
import { downloadPDF, openPDF } from "../../util/invoicing";
import { PaginationButtons } from "../../components";

class BuyerInvoicesPageComponent extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      invoicesList,
      invoicesMeta: { page: currentPage, page_size, total_items },
      onChangeBuyerInvoicesPage,
    } = this.props;
    const totalPages = Math.ceil(total_items / page_size);

    const emptyStateOptions = {
      containerOffsetClassName: "invoicing",
      iconStylesClassName: "invoicingIcon",
      titleMessageId: "BuyerInvoicingPage.emptyStateHeader",
      textMessageId: "BuyerInvoicingPage.emptyStateText",
    };

    // Pre-loader
    if (this.props.invoicesLoadInProgress) {
      return <LoaderComponent />;
    }

    // Empty state
    if (!this.props.invoicesList?.length) {
      return (
        <div className={css.emptyStateContainer}>
          <EmptyStateComponent invoices={[]} options={emptyStateOptions} />
        </div>
      );
    }

    const onPreviewPDF = (url, pdfTitle) => () => openPDF({ url, pdfTitle });
    const onDownloadPDF = (url, pdfTitle) => () => downloadPDF({ url, pdfTitle });

    return (
      <>
        <table className={css.content}>
          <thead>
            <tr className={classNames(css.tableRow, css.headerRow)}>
              {tableColumns.map(({ key, name }) => (
                <td key={key}>{name}</td>
              ))}
            </tr>
          </thead>
          <tbody>
            {invoicesList.map(({ created, invoice_number, url, supplier }, index) => {
              const pdfTitle = `Invoice #${invoice_number}`;

              return (
                <tr key={invoice_number + "61313f0c-9d3e-47c0-8e1c-bbfa85db6e97" + index} className={css.tableRow}>
                  <td>{format(new Date(created), "MMM dd, yyyy")}</td>
                  <td>{invoice_number}</td>
                  <td>
                    <span>{supplier.companyName}</span>
                    <br />
                    <span>({supplier.brandName})</span>
                  </td>
                  <td>Paid</td>
                  <td>
                    <span className={css.viewPrintLink} onClick={onPreviewPDF(url, pdfTitle)}><FormattedMessage id="BuyerInvoicingPage.viewPrint" /></span>
                  </td>
                  <td>
                    <span className={css.viewPrintLink} onClick={onDownloadPDF(url, pdfTitle)}><FormattedMessage id="BuyerInvoicingPage.download" /></span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className={css.pagination}>
          <PaginationButtons
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={onChangeBuyerInvoicesPage}
          />
        </div>
      </>
    );
  }
}

BuyerInvoicesPageComponent.defaultProps = {};

BuyerInvoicesPageComponent.propTypes = {};


const mapStateToProps = (state) => {

  return {
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const mapDispatchToProps = (/*dispatch*/) => {

  return {};
};

const BuyerInvoicesPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(BuyerInvoicesPageComponent);

export default BuyerInvoicesPage;