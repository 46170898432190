import { bool, object } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { StripeConnectAccountStatusBox } from "../../components";
import CountryShippingSection from "../../components/CountryShippingFee/CountryShippingSection";
import config from "../../config";
import { getStripeConnectAccountLink, stripeAccountClearError } from "../../ducks/stripeConnectAccount.duck";
import { isScrollingDisabled } from "../../ducks/UI.duck";
import { StripeConnectAccountForm } from "../../forms";
import routeConfiguration from "../../routeConfiguration";
import { ensureCurrentUser } from "../../util/data";
import { FormattedMessage, injectIntl, intlShape } from "../../util/reactIntl";
import { createResourceLocatorString } from "../../util/routes";
import { propTypes } from "../../util/types";
import { loadData, savePayoutDetails, updateShippingFees } from "./ProfilePayoutDetailsPage.duck";
import { localePart, storedLocale } from "../../components/Wrapper/ClassWrapper";
import css from "./ProfilePayoutDetailsPage.module.css";

const STRIPE_ONBOARDING_RETURN_URL_SUCCESS = "success";
const STRIPE_ONBOARDING_RETURN_URL_FAILURE = "failure";

const locale = storedLocale() || localePart();

/**
 * Create return URL for the Stripe onboarding form
 */
const createReturnURL = (returnURLType, rootURL, routes, isSupplier) => {
  const path = createResourceLocatorString(
    isSupplier ? "SupplierProfilePayoutDetailsUrlPage" : "BuyerProfilePayoutDetailsUrlPage",
    routes,
    { returnURLType, locale },
    {},
  );
  const root = rootURL ? rootURL.replace(/\/$/, "") : "";

  return `${root}${path}`;
};

/**
 * Get attribute: stripeAccountData
 */
const getStripeAccountData = stripeAccount => stripeAccount.attributes.stripeAccountData || null;

/**
 * Get last 4 digits of bank account returned in Stripe account
 */
const getBankAccountLast4Digits = (stripeAccountData) =>
  stripeAccountData && stripeAccountData.external_accounts.data.length > 0
    ? stripeAccountData.external_accounts.data[0].last4
    : null;

/**
 * Check if there's requirements on selected type: 'past_due', 'currently_due' etc.
 */
const hasRequirements = (stripeAccountData, requirementType) =>
  stripeAccountData != null &&
  stripeAccountData.requirements &&
  Array.isArray(stripeAccountData.requirements[requirementType]) &&
  stripeAccountData.requirements[requirementType].length > 0;

/**
 * Redirect user to Stripe's hosted Connect account onboarding form
 */
const handleGetStripeConnectAccountLinkFn = (getLinkFn, commonParams) => type => () => {
  getLinkFn({ type, ...commonParams })
    .then((url) => {
      window.location.href = url;
    })
    .catch((err) => console.error(err));
};

export class ProfilePayoutDetailsPageComponent extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      currentUser,
      /*scrollingDisabled,*/
      getAccountLinkInProgress,
      getAccountLinkError,
      createStripeAccountError,
      updateStripeAccountError,
      fetchStripeAccountError,
      stripeAccountFetched,
      stripeAccount,
      onPayoutDetailsFormChange,
      onPayoutDetailsFormSubmit,
      onGetStripeConnectAccountLink,
      payoutDetailsSaveInProgress,
      payoutDetailsSaved,
      shippingFeeSaveInProgress,
      // shippingFeeSaved,
      shippingFeeCountries,
      params,
      intl,
      supplierMode,
    } = this.props;

    const returnURLType = params?.returnURLType;
    const ensuredCurrentUser = ensureCurrentUser(currentUser);
    const currentUserLoaded = !!ensuredCurrentUser.id;
    const stripeConnected = currentUserLoaded && !!stripeAccount && !!stripeAccount.id;
    const formDisabled = getAccountLinkInProgress;
    const rootURL = config.canonicalRootURL;
    const routes = routeConfiguration();
    const successURL = createReturnURL(STRIPE_ONBOARDING_RETURN_URL_SUCCESS, rootURL, routes, supplierMode);
    const failureURL = createReturnURL(STRIPE_ONBOARDING_RETURN_URL_FAILURE, rootURL, routes, supplierMode);
    const accountId = stripeConnected ? stripeAccount.id : null;
    const stripeAccountData = stripeConnected ? getStripeAccountData(stripeAccount) : null;
    const requirementsMissing = stripeAccount
      && (
        hasRequirements(stripeAccountData, "past_due")
        || hasRequirements(stripeAccountData, "currently_due")
      );

    const savedCountry = stripeAccountData ? stripeAccountData.country : null;

    const handleGetStripeConnectAccountLink = handleGetStripeConnectAccountLinkFn(
      onGetStripeConnectAccountLink,
      {
        accountId,
        successURL,
        failureURL,
      },
    );

    // const returnedNormallyFromStripe = returnURLType === STRIPE_ONBOARDING_RETURN_URL_SUCCESS;
    const returnedAbnormallyFromStripe = returnURLType === STRIPE_ONBOARDING_RETURN_URL_FAILURE;
    const showVerificationNeeded = stripeConnected && requirementsMissing;
    const submitButtonText = intl.formatMessage({ id: "StripePayoutPage.submitButtonText" });

    return (
      <div className={css.payoutAndShippingPageContainer}>
        <div className={css.payoutDetailsSection}>
          <h1 className={css.payoutDetailsTitle}>
            <FormattedMessage id="StripePayoutPage.heading" />
          </h1>
          {!currentUserLoaded ?
            <div className={css.loadingText}>
              <FormattedMessage id="StripePayoutPage.loadingData" />
            </div>
            :
            returnedAbnormallyFromStripe && !getAccountLinkError ?
              <FormattedMessage id="StripePayoutPage.redirectingToStripe" />
              :
              <StripeConnectAccountForm
                disabled={formDisabled}
                inProgress={payoutDetailsSaveInProgress}
                ready={payoutDetailsSaved}
                currentUser={ensuredCurrentUser}
                stripeBankAccountLastDigits={getBankAccountLast4Digits(stripeAccountData)}
                savedCountry={savedCountry}
                submitButtonText={submitButtonText}
                stripeAccountError={createStripeAccountError || updateStripeAccountError || fetchStripeAccountError}
                stripeAccountLinkError={getAccountLinkError}
                stripeAccountFetched={stripeAccountFetched}
                onChange={onPayoutDetailsFormChange}
                onSubmit={onPayoutDetailsFormSubmit}
                onGetStripeConnectAccountLink={handleGetStripeConnectAccountLink}
                stripeConnected={stripeConnected}
              >
                {stripeConnected && !returnedAbnormallyFromStripe && showVerificationNeeded ?
                  <StripeConnectAccountStatusBox
                    type="verificationNeeded"
                    inProgress={getAccountLinkInProgress}
                    onGetStripeConnectAccountLink={handleGetStripeConnectAccountLink("custom_account_verification")}
                  />
                  :
                  stripeConnected && !returnedAbnormallyFromStripe && savedCountry ?
                    <StripeConnectAccountStatusBox
                      type="verificationSuccess"
                      inProgress={getAccountLinkInProgress}
                      disabled={payoutDetailsSaveInProgress}
                      onGetStripeConnectAccountLink={handleGetStripeConnectAccountLink("custom_account_update")}
                    />
                    :
                    null
                }
              </StripeConnectAccountForm>
          }
        </div>
        <div className={css.shippingDetailsSection}>
          {/*<h1 className={css.shippingDetailsTitle}>
            <FormattedMessage id="StripePayoutPage.shippingTitle" />
        </h1>*/}
          <CountryShippingSection
            intl={intl}
            submitButtonText={submitButtonText}
            onSubmit={this.props.onUpdateShippingFees}
            shippingFeeCountries={shippingFeeCountries}
            shippingFeeSaveInProgress={shippingFeeSaveInProgress}
          />
        </div>
      </div>
    );
  }
}

ProfilePayoutDetailsPageComponent.defaultProps = {
  currentUserListing: null,
  currentUserListingFetched: false,
};

ProfilePayoutDetailsPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
  // from withRouter
  history: object.isRequired,
  location: object.isRequired,
  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = (state) => {
  const {
    getAccountLinkInProgress,
    getAccountLinkError,
    createStripeAccountError,
    updateStripeAccountError,
    fetchStripeAccountError,
    stripeAccount,
    stripeAccountFetched,
  } = state.stripeConnectAccount;
  const { currentUser } = state.user;
  const {
    payoutDetailsSaveInProgress,
    payoutDetailsSaved,
    shippingFeeCountries,
    shippingFeeSaveInProgress,
  } = state.ProfilePayoutDetailsPage;

  return {
    currentUser,
    getAccountLinkInProgress,
    getAccountLinkError,
    createStripeAccountError,
    updateStripeAccountError,
    fetchStripeAccountError,
    stripeAccount,
    stripeAccountFetched,
    payoutDetailsSaveInProgress,
    payoutDetailsSaved,
    shippingFeeSaveInProgress,
    shippingFeeCountries,
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  onPayoutDetailsFormChange: () => dispatch(stripeAccountClearError()),
  onPayoutDetailsFormSubmit: (values, isUpdateCall) => dispatch(savePayoutDetails(values, isUpdateCall)),
  onGetStripeConnectAccountLink: (params) => dispatch(getStripeConnectAccountLink(params)),
  onUpdateShippingFees: (shippingCountries) => dispatch(updateShippingFees(shippingCountries)),
});

const ProfilePayoutDetailsPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
)(ProfilePayoutDetailsPageComponent);

ProfilePayoutDetailsPage.loadData = loadData;

export default ProfilePayoutDetailsPage;
