import React from 'react';
import { bool, string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { NamedLink } from '../../components';
import { storedLocale, localePart } from '../Wrapper/ClassWrapper';
import { displayRegistrationConfirmDialog } from '../../ducks/Modal.duck';
import { connect } from 'react-redux';
import { compose } from 'redux';

import imageShopCheeseAndDairyAlternatives from '../../assets/dairy-and-cheese-alternatives-vegshelf.png';
import imageShopSweetsAndSnacks from '../../assets/sweets-and-snacks-vegshelf.png';
import imageShopPantry from '../../assets/pantry-vegshelf.png';
import imageShopCondiments from '../../assets/condiments-vegshelf.png';
import imageShopBeverages from '../../assets/beverages-vegshelf.png';
import imageShopCustomized from '../../assets/customized-service-vegshelf-wholesale-marketplace.png';

import css from './SectionTopCategories.module.css';

const locale = storedLocale() || localePart();

// 16.07.23 Vegshelf freeze: removed links to products as part of Vegshelf freeze, added registrationConfirmDialogue instead

const SectionTopCategoriesComponent = props => {
  const { rootClassName, className, isAuthenticated, onDisplayRegistrationConfirmDialog } = props;

  const categoryItems = (
    <>
      <div className={css.categoryItem}>
        <a className={css.categoryLink} params={{ productCategoryCode: 'dairy-and-cheese-alternatives', locale: locale }} onClick={() => onDisplayRegistrationConfirmDialog(true)}>
          <img src={imageShopCheeseAndDairyAlternatives} className={css.categoryImage1} alt="Dairy and Cheese alternatives" />
          <h3>
            <FormattedMessage id="SectionTopCategories.ShopDairyAndCheeseAlternatives" className={css.categoryTitle} />
          </h3>
        </a>
      </div>
      <div className={css.categoryItem}>
        <a className={css.categoryLink} params={{ productCategoryCode: 'beverages', locale: locale }} onClick={() => onDisplayRegistrationConfirmDialog(true)}>
          <img src={imageShopBeverages} className={css.categoryImage2} alt="Beverages" />
          <h3>
            <FormattedMessage id="SectionTopCategories.ShopBeverages" className={css.categoryTitle} />
          </h3>
        </a>
      </div>
      <div className={css.categoryItem}>
        <a className={css.categoryLink} params={{ productCategoryCode: 'pantry-and-ready-meals', locale: locale }} onClick={() => onDisplayRegistrationConfirmDialog(true)}>
          <img src={imageShopPantry} className={css.categoryImage3} alt="Pantry" />
          <h3>
            <FormattedMessage id="SectionTopCategories.ShopPantry" className={css.categoryTitle} />
          </h3>
        </a>
      </div>
      <div className={css.categoryItem}>
        <a className={css.categoryLink} params={{ productCategoryCode: 'sweets-and-snacks', locale: locale }} onClick={() => onDisplayRegistrationConfirmDialog(true)}>
          <img src={imageShopSweetsAndSnacks} className={css.categoryImage4} alt="Sweets and Snacks" />
          <h3>
            <FormattedMessage id="SectionTopCategories.ShopSweetsAndSnacks" className={css.categoryTitle} />
          </h3>
        </a>
      </div>
      <div className={css.categoryItem}>
        <a className={css.categoryLink} params={{ productCategoryCode: 'pantry-and-ready-meals', locale: locale }} onClick={() => onDisplayRegistrationConfirmDialog(true)}>
          <img src={imageShopCondiments} className={css.categoryImage5} alt="Condiments" />
          <h3>
            <FormattedMessage id="SectionTopCategories.ShopCondiments" className={css.categoryTitle} />
          </h3>
        </a>
      </div>
      <div className={css.categoryItem}>
        <a className={css.categoryLink} href="mailto:hello@vegshelf.com" target="_blank" >
          <img src={imageShopCustomized} className={css.categoryImage6} alt="Looking for something else? We're happy to help. Reach out to us." />
        </a>
      </div>
    </>
  )

  return (
    <div className={css.categoryContainer}>
      <div className={css.categorySection}>
        <div className={css.categoryTextContainer}>
          <h2 className={css.categoryTextContainerTitle}>
            <FormattedMessage id="SectionTopCategories.MainTitle1" />
            &nbsp;<i><FormattedMessage id="SectionTopCategories.MainTitle2" /></i>&nbsp;
            <br className={css.mobileLineBreak} />
            <FormattedMessage id="SectionTopCategories.MainTitle3" />
          </h2>
          <p className={css.categoryTextContainerSubtitle}>
            <FormattedMessage id="SectionTopCategories.SubTitle" />
          </p>
        </div>
        <div className={css.categoryImages}>
          {categoryItems}
        </div>
      </div>
    </div>
  );
};

SectionTopCategoriesComponent.defaultProps = {
  rootClassName: null,
  className: null,
  currentUserListing: null,
  currentUserListingFetched: false,
};

SectionTopCategoriesComponent.propTypes = {
  rootClassName: string,
  className: string,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
};

// 16.07.23 Vegshelf freeze: state and dispatch for registrationConfirmDialogue

const mapStateToProps = (state) => ({});
const mapDispatchToProps = dispatch => ({
  onDisplayRegistrationConfirmDialog: (isBuyer) => dispatch(displayRegistrationConfirmDialog(true, true)),
});
const SectionTopCategories = compose(
  connect(mapStateToProps, mapDispatchToProps),
)(SectionTopCategoriesComponent);

export default SectionTopCategories;
