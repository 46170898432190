import { bool, object } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { TransactionViewItem } from "../../components";
import EmptyStateComponent from "../../components/ProductEmptyState/ProductEmptyState";
import { transition } from "../../ducks/transition.duck";
import { isScrollingDisabled } from "../../ducks/UI.duck";
import { injectIntl, intlShape } from "../../util/reactIntl";
import { propTypes } from "../../util/types";
import { LoadRequests } from "../BuyerOrdersAndRequestsPage/BuyerOrdersAndRequestsPage.duck";
import css from "./BuyerOrderRequestsPage.module.css";

export class BuyerOrderRequestsPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { openedTransactionIndex: -1 };
    this.onShowDetailsClick = this.onShowDetailsClick.bind(this);
    this.onTransitionClick = this.onTransitionClick.bind(this);
  }

  onTransitionClick(transaction, newTransition) {
    const transitionItem = {
      id: transaction.id.uuid,
      transitionName: newTransition,
    };
    this.props.onTransition(transitionItem);
  }

  onShowDetailsClick(itemIndex) {
    let newIndex = itemIndex;
    if (newIndex === this.state.openedTransactionIndex) {
      newIndex = -1;
    }
    this.setState({ ...this.state, openedTransactionIndex: newIndex });
  }

  render() {
    const { /*orderRequestsLoadError, orderRequestsLoadInProgress,*/ orderRequests } = this.props;
    const requests = [...(orderRequests?.data || [])];
    const hasAnyData = requests.length > 0;

    const emptyStateOptions = {
      containerOffsetClassName: "orderAndRequestOffset",
      iconStylesClassName: "orderAndRequestIcon",
      titleMessageId: "BuyerOrderPage.noSearchResultTitle",
      textMessageId: "BuyerOrderPage.noSearchResultText",
    };

    return (
      <div>
        {hasAnyData ? (
          <>
            <h1 className={css.pageHeader}>Order requests</h1>
            <div>
              {requests.map((r, idx) => {
                return (
                  <TransactionViewItem
                    key={r.id.uuid}
                    transactionItem={r}
                    showDetails={this.state.openedTransactionIndex === idx}
                    canChangeStatus={false}
                    onShowDetailsClick={() => this.onShowDetailsClick(idx)}
                    onTransitionClick={this.onTransitionClick}
                  />
                );
              })}
            </div>
            <div />
          </>
        ) : (
          <EmptyStateComponent options={emptyStateOptions} />
        )}
      </div>
    );
  }
}

BuyerOrderRequestsPageComponent.defaultProps = {
  currentUserListing: null,
  currentUserListingFetched: false,
};

BuyerOrderRequestsPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
  // from withRouter
  history: object.isRequired,
  location: object.isRequired,
  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = (state) => {
  //const { supplierRegistrationError, supplierRegistrationInProgress, supplierRegistered } = state.BuyerOrderRequestsPage;

  return {
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  onTransition: (values) => dispatch(transition(values)),
});

const BuyerOrderRequestsPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
)(BuyerOrderRequestsPageComponent);

BuyerOrderRequestsPage.loadData = () => (dispatch/*, getState*/) => {
  return dispatch(LoadRequests());
};

export default BuyerOrderRequestsPage;
