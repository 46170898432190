import React, { Component } from 'react';
import Mailchimp from 'react-mailchimp-form';
import { FormattedMessage } from '../../util/reactIntl';

import css from './MailchimpForBuyers.module.css';

class MailchimpForBuyers extends Component {
    render() {
        return (
            <Mailchimp
                action={process.env.REACT_APP_BUYER_KEY}
                fields={[
                    {
                        name: 'EMAIL',
                        placeholder: 'Email',
                        type: 'email',
                        required: true
                    },
                    {
                        name: 'NAME',
                        placeholder: 'Name',
                        type: 'text',
                        required: true
                    },
                    {
                        name: 'SURNAME',
                        placeholder: 'Surname',
                        type: 'text',
                        required: true
                    }
                ]}
                messages={
                    {
                        sending: <FormattedMessage id="MailchimpForBuyers.messageSending" />,
                        success: <FormattedMessage id="MailchimpForBuyers.messageThankYou" />,
                        error: <FormattedMessage id="MailchimpForBuyers.messageError" />,
                        empty: <FormattedMessage id="MailchimpForBuyers.messageWriteAnEmail" />,
                        duplicate: <FormattedMessage id="MailchimpForBuyers.messageTooManyAttempts" />,
                        button: <FormattedMessage id="MailchimpForBuyers.signMeUp" />
                    }
                }
                className={css.buyerForm}
            />
        );
    }
}

export default MailchimpForBuyers;