import React, { Component } from 'react';
import { bool } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import config from '../../config';
import { displayRegistrationConfirmDialog } from '../../ducks/Modal.duck';
import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  NamedLink,
} from '../../components';

import { TopbarContainer } from '..';
import { FormattedMessage } from '../../util/reactIntl';

import facebookImage from '../../assets/VegshelfFacebook-1200x630.png';
import twitterImage from '../../assets/VegshelfTwitter-600x314.png';
import css from './WhyVeganPage.module.css';
import factImage1 from "../../assets/food-for-wellness-vegshelf.png";
import factImage2 from "../../assets/food-for-environment-vegshelf.png";
import factImage3 from "../../assets/food-for-animal-welfare-vegshelf.png";

export class WhyVeganPageComponent extends Component {
  constructor(props) {
    super(props);
    //this.state = { categoryIndex: 0, planIndex: 1, blogIndex: 0, FAQIndex: -1 };
  }
  render() {
    const {
      intl,
      scrollingDisabled,
      params,
    } = this.props;

    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage({ id: 'WhyVeganPage.schemaTitle' }, { siteTitle });
    const schemaDescription = intl.formatMessage({ id: 'WhyVeganPage.schemaDescription' }, { siteTitle });
    const schemaImage = `${config.canonicalRootURL}${facebookImage}`;
    const aboutUsLink = <NamedLink name="AboutUsPage" ><FormattedMessage id="WhyVeganPage.aboutUsLinkText" /></NamedLink>;

    return (
      <Page
        className={css.root}
        scrollingDisabled={scrollingDisabled}
        contentType="website"
        description={schemaDescription}
        title={schemaTitle}
        robots='index'
        facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
        twitterImages={[
          { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
        ]}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'WebPage',
          description: schemaDescription,
          name: schemaTitle,
          image: [schemaImage],
        }}
      >
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div className={css.pageContainer}>
              <div className={css.pageWrapper}>
                <div className={css.pageText}>
                  <h1 className={css.pageTitle}>
                    <FormattedMessage id="WhyVeganPage.pageTitle" />
                  </h1>
                  <p className={css.pageSubtitle}>
                    <FormattedMessage id="WhyVeganPage.pageSubtitleParagraph1" values={{ aboutUsLink }} />
                  </p>
                  <p className={css.pageSubtitle}>
                    <FormattedMessage id="WhyVeganPage.pageSubtitleParagraph2" />
                  </p>
                  <div className={css.facts}>
                    <div className={css.singleFactSection}>
                      <p className={css.factSectionTitle}>
                        <FormattedMessage id="WhyVeganPage.factTitle1" />
                      </p>
                      <img className={css.factImage} src={factImage1} alt="Wellness and health"></img>
                      <p className={css.factHighlight}>
                        <FormattedMessage id="WhyVeganPage.factHighlight1" />
                      </p>
                      <p className={css.factText}>
                        <FormattedMessage id="WhyVeganPage.factText1" />
                      </p>
                      <p className={css.factSource}>
                        <FormattedMessage id="WhyVeganPage.factSource1" />
                      </p>
                    </div>

                    <div className={css.singleFactSection}>
                      <p className={css.factSectionTitle}>
                        <FormattedMessage id="WhyVeganPage.factTitle2" />
                      </p>
                      <img className={css.factImage} src={factImage2} alt="Climate and environment"></img>
                      <p className={css.factHighlight}>
                        <FormattedMessage id="WhyVeganPage.factHighlight2" />
                      </p>
                      <p className={css.factText}>
                        <FormattedMessage id="WhyVeganPage.factText2" />
                      </p>
                      <p className={css.factSource}>
                        <FormattedMessage id="WhyVeganPage.factSource2" />
                      </p>
                    </div>

                    <div className={css.singleFactSection}>
                      <p className={css.factSectionTitle}>
                        <FormattedMessage id="WhyVeganPage.factTitle3" />
                      </p>
                      <img className={css.factImage} src={factImage3} alt="Animal welfare"></img>
                      <p className={css.factHighlight}>
                        <FormattedMessage id="WhyVeganPage.factHighlight3" />
                      </p>
                      <p className={css.factText}>
                        <FormattedMessage id="WhyVeganPage.factText3" />
                      </p>
                      <p className={css.factSource}>
                        <FormattedMessage id="WhyVeganPage.factSource3" />
                      </p>
                    </div>

                  </div>
                  <p className={css.pageSubtitle}>
                    <FormattedMessage id="WhyVeganPage.pageSubtitleParagraph3" />
                  </p>
                  <p className={css.pageSubtitle}>
                    <FormattedMessage id="WhyVeganPage.pageSubtitleParagraph4" />
                  </p>
                </div>
              </div>
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page >
    );
  }
};

WhyVeganPageComponent.defaultProps = {
};

WhyVeganPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired
};
const mapStateToProps = state => {

  return {
    scrollingDisabled: isScrollingDisabled(state)
  };
};

const mapDispatchToProps = dispatch => ({
  onShowRegistrationConfirmDialog: () => dispatch(displayRegistrationConfirmDialog(true, false))
});

const WhyVeganPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(WhyVeganPageComponent);

export default WhyVeganPage;