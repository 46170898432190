import React, { useState } from 'react';
import { bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
/* Swiper components */
import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react';
SwiperCore.use([Navigation]);
/* Swiper components */
import { injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { displayRegistrationConfirmDialog } from '../../ducks/Modal.duck';
import { propTypes } from '../../util/types';
import config from '../../config';
import classNames from 'classnames';
import ReactTypingEffect from 'react-typing-effect';
import { FormattedMessage } from '../../util/reactIntl';
import {
  Page,
  SectionHero,
  SectionHowItWorks,
  SectionTopCategories,
  SectionDiscoverOurBrands,
  SectionFeedback,
  SectionGrowWholesaleBusiness,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  MailchimpForBuyers,
  SectionStores,
  NamedLink,
  NamedRedirect,
  SectionProducts,
} from '../../components';
import { TopbarContainer } from '../../containers';

import facebookImage from '../../assets/VegshelfFacebook-1200x630.png';
import twitterImage from '../../assets/VegshelfTwitter-600x314.png';
import css from './LandingPage.module.css';

import Blog_1 from '../../assets/vegshelf-blog-where-source-vegan-products.png';
import Blog_2 from '../../assets/vegshelf-blog-grocery-shops-need-more-vegan-products.png';
import Blog_3 from '../../assets/vegshelf-blog-coffee-shops-need-more-vegan-products.png';
import Blog_4 from '../../assets/vegshelf-blog-independent-retail-for-emerging-cpg.png';

import arrowLeft from '../../assets/arrow-left-000000.svg';
import arrowRight from '../../assets/arrow-right-000000.svg';

export const LandingPageComponent = props => {
  const {
    history,
    intl,
    location,
    scrollingDisabled,
    currentUserType,
    isAuthenticated,
    onDisplayRegistrationConfirmDialog,
  } = props;

  const [FAQIndex, setFAQIndex] = useState(-1);

  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'LandingPage.schemaDescription' }, { siteTitle });
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;

  const blogs = [
    {
      imageUrl: Blog_1,
      imageAltText: "Where to source vegan food and beverage products",
      pageLink: "BlogArticle4",
    },
    {
      imageUrl: Blog_2,
      imageAltText: "Why retailers need more vegan products in assortment",
      pageLink: "BlogArticle1"
    },
    {
      imageUrl: Blog_3,
      imageAltText: "Why coffee shops need to offer vegan food and drinks",
      pageLink: "BlogArticle2"
    },
    {
      imageUrl: Blog_4,
      imageAltText: "Why independent retail is perfect for new brands",
      pageLink: "BlogArticle3"
    }
  ];

  const email = <a href="mailto:hello@vegshelf.com" target="_blank">hello@vegshelf.com</a>;
  const email2 = <a href="mailto:hello@vegshelf.com" target="_blank">hello@vegshelf.com</a>;

  const FAQ = [
    {
      question: <FormattedMessage id="BuyerLandingPage.faq.q1" />,
      answer: <FormattedMessage id="BuyerLandingPage.faq.a1" />
    },
    {
      question: <FormattedMessage id="BuyerLandingPage.faq.q2" />,
      answer: <FormattedMessage id="BuyerLandingPage.faq.a2" />
    },
    {
      question: <FormattedMessage id="BuyerLandingPage.faq.q3" />,
      answer: <FormattedMessage id="BuyerLandingPage.faq.a3" />
    },
    {
      question: <FormattedMessage id="BuyerLandingPage.faq.q4" />,
      answer: <FormattedMessage id="BuyerLandingPage.faq.a4" />
    },
    {
      question: <FormattedMessage id="BuyerLandingPage.faq.q5" />,
      answer: <FormattedMessage id="BuyerLandingPage.faq.a5" />
    },
    {
      question: <FormattedMessage id="BuyerLandingPage.faq.q6" />,
      answer: <FormattedMessage id="BuyerLandingPage.faq.a6" />
    },
    {
      question: <FormattedMessage id="BuyerLandingPage.faq.q7" />,
      answer: <FormattedMessage id="BuyerLandingPage.faq.a7" values={{ email }} />
    },
    {
      question: <FormattedMessage id="BuyerLandingPage.faq.q8" />,
      answer: <FormattedMessage id="BuyerLandingPage.faq.a8" />
    },
    {
      question: <FormattedMessage id="BuyerLandingPage.faq.q9" />,
      answer: <FormattedMessage id="BuyerLandingPage.faq.a9" />
    },
    {
      question: <FormattedMessage id="BuyerLandingPage.faq.q10" />,
      answer: <FormattedMessage id="BuyerLandingPage.faq.a10" values={{ email2 }} />
    },
    {
      question: <FormattedMessage id="BuyerLandingPage.faq.q11" />,
      answer: <FormattedMessage id="BuyerLandingPage.faq.a11" />
    },
    {
      question: <FormattedMessage id="BuyerLandingPage.faq.q12" />,
      answer: <FormattedMessage id="BuyerLandingPage.faq.a12" />
    }
  ];

  const viewProducts = [];

  // 16.07.23 Vegshelf freeze: commented out links to avoid redirect as part of Vegshelf freeze
  
  /*if (isAuthenticated && currentUserType === 'buyer') {
    return <NamedRedirect name="BuyerLandingPage" />
  }
  if (isAuthenticated && currentUserType === 'supplier') {
    return <NamedRedirect name="SupplierLandingPage" />
  }*/
  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      robots='index'
      facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div className={css.heroContainer}>
            <SectionHero className={css.hero} history={history} location={location} />
          </div>
          <SectionProducts product={viewProducts} />
          <ul className={css.sections}>
            <li className={css.section}>
              <div className={css.sectionHowItWorks}>
                <SectionHowItWorks />
              </div>
            </li>
            <li className={css.section}>
              <div className={css.sectionTopCategories}>
                <SectionTopCategories />
              </div>
            </li>
            <li className={css.sectionFeedback}>
              <div className={css.sectionContent}>
                <SectionFeedback />
              </div>
            </li>
            <li className={css.section}>
              <div className={css.sectionDiscoverBrands}>
                <SectionDiscoverOurBrands />
              </div>
            </li>
          </ul>
          <div className={css.FAQSection}>
            <h3 className={css.sectionFAQTitle}>
              <FormattedMessage id="BuyerLandingPage.faqTitle" />
            </h3>
            <div className={css.questionContainer}>
              {FAQ.map((f, i) => {
                const itemCss = classNames(css.questionItem, {
                  [css.selectedQuestion]: FAQIndex === i,
                });
                return (
                  <div key={i} className={itemCss}>
                    <p
                      className={css.question}
                      onClick={() => {
                        setFAQIndex(FAQIndex === i ? -1 : i);
                      }}>
                      {f.question}
                    </p>
                    <p className={css.answer}>{f.answer}</p>
                  </div>
                );
              })}
            </div>
          </div>
          <div className={css.section}>
            <div className={css.sectionGrowBusiness}>
              <SectionStores />
            </div>
          </div>
          <div className={css.section}>
            <div className={css.sectionGrowBusiness}>
              <SectionGrowWholesaleBusiness />
            </div>
          </div>
          <div className={css.resourceContainer}>
            <div className={css.resourcesSection}>
              <div className={css.resourceInfo}>
                <p className={css.resourceInfoBlogTitle}>
                  <FormattedMessage id="BuyerLandingPage.blogTitle" />
                </p>
                <div className={css.firstTextPartContainer}>
                  <h2 className={css.resourceInfoBlogSubTitle}>
                    <FormattedMessage id="BuyerLandingPage.blogSubtitle1" />
                  </h2>
                </div>
                <p className={css.resourceInfoBlogText}>
                  <FormattedMessage id="BuyerLandingPage.blogText" />
                </p>
                <div className={css.resourceButtons}>
                  <NamedLink className={css.resourcesReadMoreButton} name="BlogPage">
                    <FormattedMessage id="BuyerLandingPage.blogButton" />
                  </NamedLink>
                </div>
              </div>
              <div className={css.resourceItems}>
                <Swiper
                  simulateTouch={false}
                  setWrapperSize
                  navigation={{
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                  }}
                  spaceBetween={30}
                  speed={300}
                  loop
                  loopedSlides={blogs.length}
                  slidesPerView="auto"
                >
                  {blogs.map((item, i) => {
                    return (
                      <SwiperSlide key={i} className={css.resourceItem}>
                        <NamedLink name={item.pageLink}>
                          <img src={item.imageUrl} className={css.resourceImage} alt={item.imageAltText} />
                        </NamedLink>
                      </SwiperSlide>
                    );
                  })}
                  <div className={css.swiperButtonContainer}>
                    <div className={classNames(css.resourceLeftButton, 'swiper-button-prev')}>
                      <img src={arrowLeft} alt="" ></img>
                    </div>
                    <div className={classNames(css.resourceRightButton, 'swiper-button-next')}>
                      <img src={arrowRight} alt="" ></img>
                    </div>
                  </div>
                </Swiper>
              </div>
            </div>
          </div>
          <div className={css.mediaSectionContainer}>
            <div className={css.mediaSection}>
              {/*<div className={css.firstColumnMediaData}>
                <p className={css.mediaSectionTitle}>
                  <FormattedMessage id="SupplierLandingPage.socialMedia" />
                </p>
                <h3 className={css.mediaDescription}>
                  <FormattedMessage id="SupplierLandingPage.letsHangOut" />
                </h3>
                <div className={css.mediaButtons}>
                  <a className={css.mediaLinkedIn} href="https://www.linkedin.com/company/vegshelf/" target="_blank"></a>
                  <a className={css.mediaTwitter} href="https://twitter.com/vegshelf" target="_blank"></a>
                  <a className={css.mediaFacebook} href="https://www.facebook.com/vegshelf/" target="_blank"></a>
                  <a className={css.mediaInstagram} href="https://www.instagram.com/vegshelf/" target="_blank"></a>
                  <a className={css.mediaTiktok} href="https://www.tiktok.com/@vegshelf" target="_blank"></a>
                </div>
              </div>*/}
              {/*<div className={css.secondColumnMediaData}>*/}
              <p className={css.newsletterSectionTitle}>
                <FormattedMessage id="BuyerLandingPage.newsletterSectionTitle" />
              </p>
              <h2 className={css.buyerNewsletterMainTitle}>
                <FormattedMessage id="BuyerLandingPage.newsletterTitle1" />
                &nbsp;<i><FormattedMessage id="BuyerLandingPage.newsletterTitle2" /></i>&nbsp;
                <FormattedMessage id="BuyerLandingPage.newsletterTitle3" />
              </h2>
              <MailchimpForBuyers />
              {/*</div>*/}
            </div>
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page >
  );
};

LandingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUserType } = state.user;

  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUserType,
    //isAuthenticated: state.Auth.isAuthenticated
  };
};

const mapDispatchToProps = dispatch => ({
  onDisplayRegistrationConfirmDialog: (isBuyer) => dispatch(displayRegistrationConfirmDialog(true, true)),
});


// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(LandingPageComponent);

export default LandingPage;