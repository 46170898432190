import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { injectIntl } from "../../util/reactIntl";
import facebookImage from "../../assets/VegshelfFacebook-1200x630.png";
import config from "../../config";
import twitterImage from "../../assets/VegshelfTwitter-600x314.png";
import { Footer, LayoutWrapperFooter, LayoutWrapperMain, LayoutWrapperTopbar, Page, TabNav } from "../../components";
import { TopbarContainer } from "../index";
import { isScrollingDisabled } from "../../ducks/UI.duck";
import css from "./BuyerInvoicingPage.module.css";
import { string } from "prop-types";
import { BUYER_INVOICING_TAB_LINK_NAMES, BUYER_INVOICING_TAB_MESSAGES, BUYER_INVOICING_TABS } from "./variables";
import BuyerInvoicesPage from "../BuyerInvoicesPage/BuyerInvoicesPage";
import { buyerLoadCompany } from "../../ducks/buyer-company.duck";
import { LoadBuyerInvoices } from "../BuyerInvoicesPage/BuyerInvoicesPage.duck";
import { localePart, storedLocale } from '../../components/Wrapper/ClassWrapper';

class BuyerInvoicingPageComponent extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      intl,
      scrollingDisabled,
      tab,
      invoicesList,
      invoicesMeta,
      invoicesLoadInProgress,
      onChangeBuyerInvoicesPage,
    } = this.props;
    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage({ id: 'BuyerInvoicingPage.schemaTitle' }, { siteTitle });
    const schemaDescription = intl.formatMessage({ id: 'BuyerInvoicingPage.schemaDescription' }, { siteTitle });
    const schemaImage = `${config.canonicalRootURL}${facebookImage}`;
    //const locale = sessionStorage.getItem('locale') || localePart();
    const locale = storedLocale() || localePart();

    const pages = {
      [BUYER_INVOICING_TABS.invoicing]: (
        <BuyerInvoicesPage
          invoicesList={invoicesList}
          invoicesMeta={invoicesMeta}
          invoicesLoadInProgress={invoicesLoadInProgress}
          onChangeBuyerInvoicesPage={onChangeBuyerInvoicesPage}
        />
      ),
    };

    const tabs = Object
      .keys(BUYER_INVOICING_TABS)
      .map((key) => {
        const tabName = intl.formatMessage({ id: BUYER_INVOICING_TAB_MESSAGES[key] });

        return ({
          id: key,
          text: <span>{tabName}</span>,
          selected: tab === BUYER_INVOICING_TABS[key],
          linkProps: {
            name: BUYER_INVOICING_TAB_LINK_NAMES[key],
            params: {
              tab: key,
              locale: locale
            },
          },
        });
      });

    return (
      <Page
        scrollingDisabled={scrollingDisabled}
        className={css.root}
        contentType="website"
        description={schemaDescription}
        title={schemaTitle}
        robots='noindex'
        facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
        twitterImages={[
          { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
        ]}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'WebPage',
          description: schemaDescription,
          name: schemaTitle,
          image: [schemaImage],
        }}
      >
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div className={css.pageContainer}>
            <div className={css.invoiceSection}>
              <TabNav rootClassName={css.tabs} tabRootClassName={css.tab} tabs={tabs} />
              <div className={css.pageContent}>
                {pages[tab]}
              </div>
            </div>
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </Page>
    );
  }
}

BuyerInvoicingPageComponent.defaultProps = {
  tab: BUYER_INVOICING_TABS.invoicing,
};

BuyerInvoicingPageComponent.propTypes = {
  tab: string,
};


const mapStateToProps = (state) => {
  const { invoicesList, invoicesMeta, invoicesLoadInProgress } = state.BuyerInvoicesPage;
  const { buyerCurrentCompany } = state.buyerCompany;

  return {
    scrollingDisabled: isScrollingDisabled(state),
    invoicesList,
    invoicesMeta,
    invoicesLoadInProgress,
    buyerCurrentCompany,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onChangeBuyerInvoicesPage: (currentPage) => dispatch(LoadBuyerInvoices({ currentPage })),
});

const BuyerInvoicingPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(BuyerInvoicingPageComponent);

BuyerInvoicingPage.loadData = buyerLoadCompany;

export default BuyerInvoicingPage;
