import { getAllInvoices } from "../../util/invoicing";
import { types as sdkTypes } from "../../util/sdkLoader";

const { UUID } = sdkTypes;

// ================ Action types ================ //
export const SUPPLIER_INVOICES_LOAD = "app/supplier/invoices/request";
export const SUPPLIER_INVOICES_SUCCESS = "app/supplier/invoices/request/success";
export const SUPPLIER_INVOICES_ERROR = "app/supplier/invoices/request/error";

// ================ Reducer ================ //
const initialInvoicesMeta = {
  page: 1,
  page_size: 100,
  total_items: 0,
};

const initialState = {
  invoicesLoadError: null,
  invoicesLoadInProgress: true,
  invoicesList: [],
  invoicesMeta: initialInvoicesMeta,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case SUPPLIER_INVOICES_LOAD:
      return {
        ...state,
        invoicesLoadInProgress: true,
        invoicesList: null,
        invoicesMeta: initialInvoicesMeta,
        invoicesLoadError: null
      };
    case SUPPLIER_INVOICES_SUCCESS:
      const { invoices, ...rest } = payload;
      return { ...state, invoicesLoadInProgress: false, invoicesList: [...invoices], invoicesMeta: { ...rest } };
    case SUPPLIER_INVOICES_ERROR:
      return { ...state, invoicesLoadInProgress: false, invoicesLoadError: payload };
    default:
      return state;
  }
}

// ================ Action creators ================ //
export const invoicesRequestLoad = () => ({ type: SUPPLIER_INVOICES_LOAD });
export const invoicesRequestLoadSuccess = (data) => ({ type: SUPPLIER_INVOICES_SUCCESS, payload: data });
export const invoicesRequestLoadError = (error) => ({ type: SUPPLIER_INVOICES_ERROR, payload: error, error: true });

// ================ Thunks ================ //
export const LoadSupplierInvoices = (params) => (dispatch, getState, sdk) => {
  dispatch(invoicesRequestLoad());

  const { currentPage, invoices_type } = params;
  const supplier_external_uid = getState()?.supplierCompany.supplierCurrentCompany.id.uuid;
  const customer_external_uid = getState()?.supplierCompany.supplierCurrentCompany.id.uuid;

  // Configuring filter for different invoices types.
  let invoicesFilter = { currentPage };
  if (invoices_type === "order") {
    invoicesFilter = { ...invoicesFilter, supplier_external_uid }
  }
  else if (invoices_type === "commission") {
    invoicesFilter = { ...invoicesFilter, customer_external_uid }
  }

  return getAllInvoices({ ...invoicesFilter })
    .then(async (res) => {
      let invoices = [];

      if (invoices_type === "commission") {
        invoices = res.invoices.map((invoice) => {
          const buyer = {
            id: "61294da6-cd94-47bc-af00-897c0c833dfd",
            companyLegalName: "Vegshelf UG",
            companyName: "haftungsbeschränkt",
          };

          return ({ ...invoice, buyer });
        })
      } else {
        invoices = await Promise.all(res.invoices.map(async (invoice) => {
          const { customer_external_uid: id } = invoice;

          const buyer = await fetchCompanyNamesByUID(sdk, id);

          return ({ ...invoice, buyer });
        }));
      }

      dispatch(invoicesRequestLoadSuccess({ ...res, invoices }));
    })
    .catch((err) => dispatch(invoicesRequestLoadError(err)));
};

export async function fetchCompanyNamesByUID(sdk, id) {
  return await sdk.listings.show({ id: new UUID(id) })
    .then(({ data: { data: { attributes: { publicData: { companyLegalName, companyName } } } } }) => ({
      id,
      companyLegalName,
      companyName,
    }))
    .catch((e) => console.error(e));
}
