import React, { Component } from 'react';
import { bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { propTypes } from '../../util/types';

import css from './PrivacyPolicyPage.module.css';

export class PrivacyPolicyPageComponent extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      history,
      intl
    } = this.props;
    return (
      <div className={css.vegshelfInformationContainer}>
        <div className={css.vegshelfInformationBox1}>
          <h1 className={css.mainTitle}>
            <FormattedMessage id="PrivacyPolicyPage.privacyPolicy" />
          </h1>
          <p className={css.bodyText}>
          <i>Last updated on May 6, 2022</i>
          <br /><br />

          <b>INTRODUCTION</b>
          <br /><br />
          We attach great importance to the protection of personal­ data.In the following we inform you about the type , scope and purpose of the collection, processing and use of personal data.You can access this declaration at any time via our website if you wish.You can access this website at any time without providing any personal information.
          <br /><br />
          The Vegshelf website and platform might contain cross-reference links to other websites of third-party providers.Our privacy policy does not apply to websites of third parties.For this reason, you should carefully read the privacy policies of third-party websites collecting your personal data.If you have been referred to our website via links from third party sites, please also read the privacy policy of the third-party site to find out how they handle your data.
          <br /><br /><br />

          <b>1. SCOPE AND DATA CONTROLLER</b>
          <br /><br />
          This privacy policy applies:
          <br /><br />
          • To the use of the services and platform provided by Vegshelf UG (haftungsbeschränkt), Leopoldstr.49, 40211 Düsseldorf, Germany;
          <br />
          • To the use of the website <a href="https://vegshelf.com/" target="_blank">www.vegshelf.com</a> provided by Vegshelf UG (haftungsbeschränkt), Leopoldstr.49, 40211 Düsseldorf, Germany.
          <br /><br />
          The data controller is: Vegshelf UG (haftungsbeschränkt), Leopoldstr.49, 40211 Düsseldorf, Germany.
          <br /><br />
          We collect, process and use your personal data exclusively in accordance with the provisions of German and European data protection law, the basis of which can be found in the Federal Data Protection Act (BDSG), the Telemedia Act (TMG) and the General Data Protection Regulation (GDPR).
          <br /><br /><br />

          <b>2. CATEGORIES OF PERSONAL DATA COLLECTED</b>
          <br /><br />
          When using our website, our platform, services or contacting us, personal data is being collected.During general use of the website, your IP address is collected.
          <br /><br />
          In addition, the following personal data is collected when registering for our platform and using the platform or services thereupon:
          <br /><br />
          • Name and surname of the contact person
          <br />
          • Position in the company of the contact person
          <br />
          • Address of the contact person
          <br />
          • Phone number of the contact person
          <br />
          • Email address of the contact person
          <br /><br />
          If you enter other personal data in the input fields of our website or platform, enter it in forms provided by us or make it available to us in any other way, this data is also collected.
          <br /><br />
          The purpose of data collection is to provide the services necessary for the fulfilment of the contract.The legal basis is Art.6(1)(b) GDPR.
          <br /><br />
          In the case of data entered online, the purpose of the data collection is also to improve our online platform and services.The legal basis for this is Art.6(1)(f) GDPR.
          <br /><br />
          Should the data be used for purposes other than contract fulfilment or the improvement of our online platform or services, we will obtain your prior consent to such data processing.The legal basis in this case is article 6(1a) GDPR.
          <br /><br /><br />

          <b>3. DURATION OF STORAGE, DELETION OF PERSONAL DATA</b>
          <br /><br />
          The data collected and processed by us will only be stored by us until the storage purpose has been achieved but no longer than 3 years after the data has been collected, unless we are obliged to store the data for a longer period of time due to requirements of national or European law.
          <br /><br />
          Once the purpose of the storage has been achieved, the data is deleted.If an earlier deletion should be obligatory due to specifications of the national or European legislator, the deletion will take place at the time specified by the legislator.
          <br /><br /><br />
          
          <b>4. GENERAL DATA TRANSMISSION­, LOGGING AND PROCESSING FOR INTERNAL SYSTEM AND STATISTICAL PURPOSES WHEN USING THE WEBSITE</b>
          <br /><br />
          When using our website, general data is also collected about each access.That data is not personal and varies depending on the individual page visited.This data collection serves to transmit the online content and to improve the online platform and services.
          <br /><br />
          The data collected includes:
          <br /><br />
          • Name of the web page or file retrieved
          <br />
          • Date and time of retrieval
          <br />
          • Data volume transferred
          <br />
          • Notification of successful retrieval
          <br />
          • Browser type and version
          <br />
          • Operating system of the user
          <br />
          • Reference URL (the page that referred to our site)
          <br />
          • Requesting provider.
          <br /><br />
          It is not possible for us to assign this data to a specific person.This data is not merged with other data sources.
          <br /><br />
          In addition, the IP address of the user is also collected.This is sometimes classified as a personal data point.Therefore, regarding the IP address you have the rights set out in this privacy policy.
          <br /><br />
          We reserve the right to subsequently check the log data if there is a justified suspicion of illegal use based on concrete evidence.
          <br /><br />
          If services of third parties are integrated on our platform or otherwise used for the provision of our platform or services, the data mentioned in this section may also be collected from these third parties.We inform about any such data collection in a separate section of this privacy policy.
          <br /><br /><br />

          <b>5. COOKIES</b>
          <br /><br />
          Cookies include text information which a visited website stores on the user's computer via the user's browser.Storage is either temporary in the main memory (so-called session cookies) or permanent on the hard disk (so-called permanent cookies).Cookies send the cookie information back to the server when the same website is visited again, thus enabling the identification of the user.For example, user statistics can be collected, or the web offer can be adapted to the respective user.This information is sometimes used to identify the visitor to this website as a returning visitor.This use of cookies can be prevented at any time by deleting the cookies in the browser and on the visitor's terminal device.
          <br /><br />
          We use both session cookies and permanent cookies on our website and thus collect, process and use your usage data.We use cookies to make our website more user-friendly, more effective and safer, and to offer our users optimum user guidance and varied content.The data you enter on our website is stored in a cookie, which is exclusively stored on your terminal device.With each individual page request, data is transmitted to the servers of our website and, if necessary, stored in cookies.If advertisements are placed on one of our pages, the advertising partners behind the advertisement also use cookies to optimize the placement of advertisements.
          <br /><br />
          Our website can also be used without cookies.Users must deactivate or restrict the storage of cookies in their browser.The browser can also be set to notify users when a cookie is sent.Cookies can also be deleted from the hard disk of a computer at any time.This can be done manually by deleting the "Cookies" folder, or in many browsers by using the corresponding delete function.
          <br /><br />
          The use of the website or parts of our website may be restricted, less user-friendly or not possible at all if the storage of cookies is deactivated, as certain functions of our website are only available if the storage of cookies has been agreed to.
          <br /><br /><br />

          <b>6. CONTACT FORM</b>
          <br /><br />
          When contacting us via the contact option provided on our website (contact form) or via email, the data provided, which is necessary for processing the contact and the request behind it, is stored and processed.In particular, insofar as you enter this in the input masks, your first name and email address will be processed and stored for the purpose of processing the enquiry.The legal basis for this is Art.6(1)(f) GDPR.
          <br /><br /><br />

          <b>7. NEWSLETTER</b>
          <br /><br />
          You can subscribe to our newsletter on our website.Personal data is processed automatically when registering for the newsletter.Thus, the email address and the IP address of the user are saved.The data entered and collected during registration is used exclusively for the purpose of sending the newsletter and within the scope of the consent given by the user.The user can revoke this consent to the storage and processing of data at any time, even after registering for the newsletter, via a link in the newsletter or by sending a message using our contact details.We have to log the registrations for the newsletter to be able to prove a proper registration.For this purpose, we store the registration and confirmation time and the IP address of the user during the registration process.
          <br /><br />
          The legal basis for the collection and processing of personal data when registering for the newsletter is Art.6 (1) (a) GDPR.
          <br /><br /><br />

          <b>8. USE OF THIRD-PARTY SERVICES</b>
          <br /><br />
          Individual contents, functions and offers on our platform or website are integrated third-party contents.The functionality of these contents, functions and offers requires that the IP address of the user is collected and possibly stored by these third parties.
          <br /><br />

          a. Stripe
          <br />
          For the execution of payments, we enable the use of the payment service provider Stripe, which is operated by Stripe Inc., 510 Townsend Street, San Francisco, CA 94103, USA.When you make payments through this payment service provider, all payment details are forwarded to Stripe Inc., 510 Townsend Street, San Francisco, CA 94103, USA and servers in the USA.Stripe then forwards the payments from the payer to the recipient.The personal data transferred in this process include first name, last name, address, e-mail address, IP address, telephone number, mobile phone number or other data necessary for the processing of payments.
          <br /><br />
          More information about Stripe's terms of use and privacy policy can be found here: <a href="https://stripe.com/en-de/ssa" target="_blank">https://stripe.com/en-de/ssa</a>.
          <br /><br />

          b. Google Analytics
          <br />
          On our website, we use Google Analytics, a web analysis service provided by Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Ireland (“Google“).Google Analytics required the use of cookies, text files that are stored to your computer in order to facilitate an analysis of your use of the site.The cookies gather information about your visit to our site and send that information to and stored on a Google server in the United States.Having enabled IP-anonymization on our website, your IP-address will be shortened and anonymized using servers in the European Union or in signatory states to the Agreement on the European Economic Area before it is being transferred to the server in the United States.However, there are special scenarios in which your IP-address will be sent to a US-Server and shortened and anonymized there.Google uses the transferred information to analyse your use of our site, to compile report about your web activity and to provide us with other functions and services relating to your use of web and internet services.Google does not amalgamate the IP-address data transferred to its servers by your browser with other data.You can prevent the storage of cookies by adjusting the settings of your internet browser to deactivate or restrict the usage of cookies.When deactivating the use of cookies, our website or parts thereof may not be fully usable and/or certain functions may be deactivated as they require the use of cookies.
          <br /><br />
          You can also prevent the collection and transfer of your personal data by Google by installing the following plugin for your browser: <a href="https://tools.google.com/dlpage/gaoptout?hl=en" target="_blank">https://tools.google.com/dlpage/gaoptout?hl=en</a>. This browser add-on informs Google Analytics via JavaScript that no data and information on visits to websites may be transmitted to Google Analytics. The installation of the browser add-on is considered a contradiction by Google. If your information technology system is deleted, formatted or reinstalled at a later time, the browser add-on must be reinstalled to disable Google Analytics. If the browser add-on is uninstalled or deactivated by the person concerned or another person within their control, it is possible to reinstall or reactivate the browser add-on.
          <br /><br />
          Further information on the terms and conditions and the data protection policy of Google can be found here: <a href="https://www.google.de/intl/en/policies/" target="_blank">https://www.google.de/intl/en/policies/</a> or here <a href="https://marketingplatform.google.com/about/analytics/terms/de/" target="_blank">https://marketingplatform.google.com/about/analytics/terms/de/</a>. Our website uses the IP Anonymization function provided by Google to enable an anonymization of IP-addresses.
          <br /><br />

          c. Google AdSense
          <br />
          We use Google AdSense, a service for the integration of advertisements of Google ("Google").Google AdSense uses so-called "cookies", text files which are stored on the user's computer and which enable an analysis of the use of the website. Google AdSense also uses so-called web beacons (invisible graphics). Through these web beacons information such as visitor traffic on the pages of this offer can be evaluated.
          <br /><br />
          The information generated by cookies and web beacons about the use of this website (including the IP address of the user) and delivery of advertising formats are transmitted to a Google server and stored there.This information may be passed on by Google to contractual partners of Google.However, Google will not merge your IP address with other data stored by you.
          <br /><br />
          Users may refuse the use of cookies by selecting the appropriate settings on their browser, however, please note that if you do this you may not be able to use the full functionality of this website.By using this website, users agree to the processing of data collected about them by Google in the manner and for the purpose described above.
          <br /><br />
          The use of Google AdSense is based on article 6 (1)(f) GDPR.We have a legitimate interest in analysing user behaviour in order to optimize both our website and our advertising.
          <br /><br />
          Further information on Google AdWords and Google Conversion Tracking can be found in the Google Privacy Policy: <a href="https://www.google.com/policies/privacy/" target="_blank">https://www.google.com/policies/privacy/</a>.
          <br /><br />

          c. Hotjar
          <br />
          We use Hotjar in order to better understand our users’ needs and to optimize our marketplace and experience.Hotjar is a technology service that helps us better understand our users’ experience (e.g.how much time they spend on which pages, which links they choose to click, what users do and don’t like, etc.) and this enables us to build and maintain our service with user feedback.
          <br /><br />
          Hotjar uses cookies and other technologies to collect data on our users’ behavior and their devices.This includes a device's IP address (processed during your session and stored in a de-identified form), device screen size, device type (unique device identifiers), browser information, geographic location (country only), and the preferred language used to display our website. Hotjar stores this information on our behalf in a pseudonymized user profile. Hotjar is contractually forbidden to sell any of the data collected on our behalf.
          <br /><br />
          For further details, please see the ‘about Hotjar’ section of Hotjar’s support site here <a href="https://help.hotjar.com/hc/en-us/categories/115001323967-About-Hotjar" target="_blank">https://help.hotjar.com/hc/en-us/categories/115001323967-About-Hotjar</a> and their privacy policy here: <a href="https://www.hotjar.com/legal/policies/privacy/" target="_blank">https://www.hotjar.com/legal/policies/privacy/</a>
          <br /><br /><br />

          <b>9. YOUR RIGHTS</b>
          <br /><br />
          You have the following rights regarding your personal information:
          <br /><br />

          a. Right to be informed (Art.15 GDPR)
          <br />
          At any time you have the right to be informed about the data stored about you, the data categories, origin and recipients as well as the purpose of collecting, processing and storing that data including the duration of storing that data.You also have a right to be informed about your right to rectify, erase or restrict the processing of your personal data as well as about your right to have your personal data transferred to you.You can obtain that information as well as all other information we are obliged to provide by sending a request to the address in the imprint.
          <br /><br />

          b. Right to correct or complete incorrect/incomplete data; deletion of data (Art.16, 17 GDPR)
          <br />
          You also have the right to correct incorrect data and to have us block or delete your personal data, insofar as this does not conflict with statutory retention requirements.You can also limit the scope of the processing of data.
          <br /><br />

          c. Right to object to processing
          <br />
          You have the right to object to the processing of your personal data.We will thereafter no longer process the personal data unless there are compelling legitimate grounds for processing that outweigh the interests, rights and freedoms of the data subject, or unless the processing is for the purpose of enforcing, pursuing or defending legal claims.
          <br /><br />

          d. Right to data transfer
          <br />
          You have the right to receive the personal information you provide to us in a structured, common and machine-readable format.You also have the right to transfer this data to another person provided that the processing is based on a consent pursuant to Art.6 (1) (a) GDPR or Art.9 (2) (a) GDPR or to a contract pursuant to Art.6 (2) 1 (b) GDPR and that the processing is carried out using automated procedures.However, we do not use automated processing procedures.
          <br /><br />

          e. Right to cancellation ("right to be forgotten")
          <br />
          You have the right to request that the personal data relating to you be deleted without delay, if one of the following reasons applies and if processing is not obligatory:
          <br /><br />
          • The personal data has been collected or otherwise processed for a purpose for which it is no longer necessary.
          <br />
          • You revoke the consent on which the processing was based in accordance with Article 6 (1) (a) of the GDPR or Article 9 (2) (a) GDPR, and there is no other legal basis for processing.
          <br />
          • You object to the processing in accordance with Art.21 para.1 GDPR, and there are no legitimate reasons for the processing or you object to the processing in accordance with Art.21 para.2 GDPR.
          <br />
          • The personal data was processed unlawfully.
          <br />
          • The deletion of personal data is required to fulfil a legal obligation under EU or German law.
          <br />
          • The personal data was collected due a consent to the processing of children’s information pursuant to Art.8 para.1 GDPR.
          <br /><br />

          f. Right of appeal to the supervisory authority
          <br />
          Without prejudice to any other administrative or judicial remedy, you have the right to file a complaint at the competent authority (such as the State Data Protection Officer of the individual German states), in particular in the member state of your residence, place of work or place of alleged infringement, if you believe that the processing of your personal data is in breach of the provisions of the General Data Protection Regulation.
          <br /><br />

          g. Addressee for exercising your rights
          <br />
          If you would like to exercise the aforementioned rights, please address your request by stating your current address and your email address (if available).
          <br />
          • by letter to: Vegshelf UG (haftungsbeschränkt), Leopoldstr.49, 40211 Düsseldorf, Germany
          <br />
          • by email to: <a href="mailto:hello@vegshelf.com" target="_blank">hello@vegshelf.com</a>
          <br /><br /><br />

          <b>10. AMENDMENTS OF PRIVACY POLICY</b>
          <br /><br />
          We keep the rights to amend this privacy policy at any time.If there will be any changes made to this privacy policy, we will post the updated privacy policy here with an effective date.
          <br /><br />
          If we make significant changes to the privacy policy, we may also notify you by other means such as sending an email or posting a pop-up notice on our home page.
          <br /><br /><br />

          <b>11.  APPLICABLE LAW</b>
          <br /><br />
          German law shall be applicable.
          </p>
          </div>
        </div>
        );
  }
};

        PrivacyPolicyPageComponent.defaultProps = {
          currentUserListing: null,
        currentUserListingFetched: false,
};

        PrivacyPolicyPageComponent.propTypes = {
          scrollingDisabled: bool.isRequired,
        currentUserListing: propTypes.ownListing,
        currentUserListingFetched: bool,

        // from withRouter
        history: object.isRequired,
        location: object.isRequired,

        // from injectIntl
        intl: intlShape.isRequired,
};
const mapStateToProps = state => {
  const {currentUser} = state.user;
        return {
          scrollingDisabled: isScrollingDisabled(state),
        currentUser
  };
};

        const PrivacyPolicyPage = compose(
        withRouter,
        connect(mapStateToProps),
        injectIntl
        )(PrivacyPolicyPageComponent);

        export default PrivacyPolicyPage;