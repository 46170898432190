import React, { Component } from 'react';
import { bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { propTypes } from '../../util/types';
import config from '../../config';
import {
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  MailchimpForBuyers,
  NamedLink,
  NamedRedirect,
  Page
} from '../../components';
import { TopbarContainer } from '../../containers';
import { BuyerRegistrationForm } from '../../forms';
import { validedEmailAddressClear } from '../../ducks/Modal.duck';

import facebookImage from '../../assets/VegshelfFacebook-1200x630.png';
import twitterImage from '../../assets/VegshelfTwitter-600x314.png';
import css from './BuyerRegistrationFormPage.module.css';
import { buyerRegistration } from './BuyerRegistrationFormPage.duck';

export class BuyerRegistrationFormPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { sent: false };
    this.toSupplier = false;
  }

  componentWillUnmount() {
    if (this.toSupplier) {
      return;
    }

    this.props.onValidedEmailAddressClear();
  }

  render() {
    const {
      // history,
      intl,
      // location,
      onBuyerRegistration,
      scrollingDisabled,
      // buyerRegistrationRequestError,
      buyerRegistrationRequestInProgress,
      buyerRequestSent,
      validedEmail,
    } = this.props;

    const showThanksScreen = buyerRequestSent === true && this.state.sent === true;
    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage({ id: 'BuyerRegistrationFormPage.schemaTitle' }, { siteTitle });
    const schemaDescription = intl.formatMessage({ id: 'BuyerRegistrationFormPage.schemaDescription' }, { siteTitle });
    const schemaImage = `${config.canonicalRootURL}${facebookImage}`;

    const onSubmit = (values) => {
      const newBuyer = { ...values, businessEMail: validedEmail.toLowerCase(), iAgreeToReceive: !!values.iAgreeToReceive.length };
      onBuyerRegistration(newBuyer);
      this.setState({ ...this.state, sent: true })
    };

    if (validedEmail === null && !showThanksScreen && !this.toSupplier) {
      //return <Redirect to='/' />;
      return <NamedRedirect name="LandingPage" />
    }

    return (
      <Page
        className={css.root}
        scrollingDisabled={scrollingDisabled}
        contentType="website"
        description={schemaDescription}
        title={schemaTitle}
        robots='noindex'
        facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
        twitterImages={[
          { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
        ]}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'WebPage',
          description: schemaDescription,
          name: schemaTitle,
          image: [schemaImage],
        }}
      >
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer onlyLogo="true" />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain className={css.layoutWrapperMain}>
            <div className={css.formContainer}>
              <div className={css.formPanel}>
                {!showThanksScreen ? (
                  <>
                    <h1 className={css.formTitle}>
                      <FormattedMessage id="BuyerRegistrationFormPage.step1FormTitle" />
                    </h1>
                    <p className={css.formDescription}>
                      <FormattedMessage id="BuyerRegistrationFormPage.step1FormDescription" />
                    </p>
                    <h4 className={css.goToRegistrationSupplier}>
                      <FormattedMessage id="BuyerRegistrationFormPage.step1LinkIntro" />
                      <NamedLink
                        name='SupplierRegistrationPage'
                        onClick={() => { this.toSupplier = true; }}
                      >
                        <FormattedMessage id="BuyerRegistrationFormPage.step1LinkText" />
                      </NamedLink>
                    </h4>
                    <div>
                      <div>
                        <BuyerRegistrationForm
                          className={css.form}
                          email={validedEmail}
                          inProgress={buyerRegistrationRequestInProgress}
                          buyerRegistered={buyerRequestSent}
                          onSubmit={onSubmit}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <div className={css.thanksScreenBlock}>
                    <h1>
                      <FormattedMessage id="BuyerRegistrationFormPage.thankYouTitle" />
                    </h1>
                    <p>
                      <FormattedMessage id="BuyerRegistrationFormPage.informationReceived" />
                    </p>
                    <div className={css.thanksButtons}>
                      <NamedLink name="LandingPage" className={css.goToHomeButton}>
                        <FormattedMessage id="BuyerRegistrationFormPage.backToHomepage" />
                      </NamedLink>
                    </div>
                    <div className={css.mediaSection}>
                      <div className={css.firstColumnMediaData}>
                        <h3 className={css.mediaSectionTitle}>
                          <FormattedMessage id="BuyerRegistrationFormPage.joinCommunity" />
                        </h3>
                        <MailchimpForBuyers />
                      </div>
                      <div className={css.secondColumnMediaData}>
                        <h3 className={css.mediaSectionTitle}>
                          <FormattedMessage id="BuyerRegistrationFormPage.socialMedia" />
                        </h3>
                        <div className={css.mediaButtons}>
                          <a className={css.mediaLinkedIn} href="https://www.linkedin.com/company/vegshelf/" target="_blank"></a>
                          <a className={css.mediaTwitter} href="https://twitter.com/vegshelf" target="_blank"></a>
                          <a className={css.mediaFacebook} href="https://www.facebook.com/vegshelf/" target="_blank"></a>
                          <a className={css.mediaInstagram} href="https://www.instagram.com/vegshelf/" target="_blank"></a>
                          <a className={css.mediaTiktok} href="https://www.tiktok.com/@vegshelf" target="_blank"></a>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

BuyerRegistrationFormPageComponent.defaultProps = {
  currentUserListing: null,
  currentUserListingFetched: false,
};

BuyerRegistrationFormPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
  // from withRouter
  history: object.isRequired,
  location: object.isRequired,
  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = (state) => {

  return {
    scrollingDisabled: isScrollingDisabled(state),
    ...state.BuyerRegistrationFormPage,
    validedEmail: state.Modals.validedEmail,
  };
};

const mapDispatchToProps = dispatch => ({
  onBuyerRegistration: values => dispatch(buyerRegistration(values)),
  onValidedEmailAddressClear: () => dispatch(validedEmailAddressClear()),
});

const BuyerRegistrationFormPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(BuyerRegistrationFormPageComponent);

export default BuyerRegistrationFormPage;