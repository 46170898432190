import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { FormattedMessage, injectIntl } from "../../util/reactIntl";
import { isScrollingDisabled } from "../../ducks/UI.duck";
import classNames from "classnames";
import css from "./SupplierInvoicesPage.module.css";
import { tableColumns } from "./mocks";
import EmptyStateComponent from "../../components/ProductEmptyState/ProductEmptyState";
import LoaderComponent from "../../components/Loader/Loader";
import { downloadPDF, openPDF } from "../../util/invoicing";
import { format } from "date-fns";
import { PaginationButtons } from "../../components";
import { LoadSupplierInvoices } from "./SupplierInvoicesPage.duck";
import { SUPPLIER_INVOICING_TABS } from "../SupplierInvoicingPage/variables";

class SupplierInvoicesPageComponent extends Component {
  constructor(props) {
    super(props);
    this.changeInvoicesPage = this.changeInvoicesPage.bind(this);
  }

  async changeInvoicesPage(page) {
    await this.props.onChangeSupplierInvoicesPage(page, this.props.tab);
  }

  render() {
    const { invoicesList, invoicesMeta: { page: currentPage, page_size, total_items } } = this.props;
    const totalPages = Math.ceil(total_items / page_size);

    const emptyStateOptions = {
      containerOffsetClassName: "invoicing",
      iconStylesClassName: "invoicingIcon",
      titleMessageId: "BuyerInvoicingPage.emptyStateHeader",
      textMessageId: "BuyerInvoicingPage.emptyStateText",
    };

    if (this.props.invoicesLoadInProgress) {
      return <LoaderComponent />;
    }

    if (!this.props.invoicesList?.length) {
      return (
        <div className={css.emptyStateContainer}>
          <EmptyStateComponent invoices={[]} options={emptyStateOptions} />
        </div>
      );
    }

    const onPreviewPDF = (url, pdfTitle) => () => openPDF({ url, pdfTitle });
    const onDownloadPDF = (url, pdfTitle) => () => downloadPDF({ url, pdfTitle });

    return (
      <>
        <table className={css.content}>
          <thead>
            <tr className={classNames(css.tableRow, css.headerRow)}>
              {tableColumns.map(({ key, name }) => {
                if (key === "customer" && this.props.tab === SUPPLIER_INVOICING_TABS.commission) {
                  return (
                    <td key={key}>
                      <FormattedMessage id="SupplierInvoicingPage.supplier" />
                    </td>
                  )
                }

                return (
                  <td key={key}>{name}</td>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {invoicesList.map(({ created, invoice_number, url, issuer_external_uid, buyer }, index) => {
              const pdfTitle = `Invoice #${invoice_number}`;

              return (
                <tr key={invoice_number + "_" + index} className={css.tableRow}>
                  <td>{format(new Date(created), "MMM dd, yyyy")}</td>
                  <td>{invoice_number}</td>
                  <td>
                    <span>{buyer.companyLegalName}</span>
                    <br />
                    <span>({buyer.companyName})</span>
                  </td>
                  <td>
                    <FormattedMessage id="SupplierInvoicingPage.paid" />
                  </td>
                  <td>
                    <span className={css.viewPrintLink} onClick={onPreviewPDF(url, pdfTitle)}>
                      <FormattedMessage id="SupplierInvoicingPage.viewPrint" />
                    </span>
                  </td>
                  <td>
                    <span className={css.viewPrintLink} onClick={onDownloadPDF(url, pdfTitle)}>
                      <FormattedMessage id="SupplierInvoicingPage.download" />
                    </span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className={css.pagination}>
          <PaginationButtons
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={this.changeInvoicesPage}
          />
        </div>
      </>
    );
  }
}

SupplierInvoicesPageComponent.defaultProps = {};

SupplierInvoicesPageComponent.propTypes = {};


const mapStateToProps = (state) => {

  return {
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  onChangeSupplierInvoicesPage: (currentPage, invoices_type) => {
    return dispatch(LoadSupplierInvoices({ currentPage, invoices_type }))
  },
});

const SupplierInvoicesPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(SupplierInvoicesPageComponent);

export default SupplierInvoicesPage;
