import React, { Component } from 'react';
import css from './RegistrationRequestsPage.module.css';

function formattedDate(date) {
  if (!date) {
    return null;
  }
  let year = date.getFullYear();
  let month = (1 + date.getMonth()).toString().padStart(2, '0');
  let day = date.getDate().toString().padStart(2, '0');

  return day + '.' + month + '.' + year;
}

export class RegistrationRequestsPage extends Component {
  constructor(props) {
    super(props);
    this.state = { selectedRequest: null };
  }

  parseProperties() {
    if (!this.state.selectedRequest) {
      return [];
    }
    const selectedRequest = this.state.selectedRequest.data;
    const isBuyer = this.state.selectedRequest.type === 'buyer';
    const detailsMap = isBuyer ?
      {
        //***** BUYER FIELDS *****//
        companyName: {
          title: "Store name",
          parse: (v) => (v),
          order: 0,
          col: 0,
        },
        firstName: {
          title: "First name",
          parse: (v) => (v),
          order: 1,
          col: 0,
        },
        position: {
          title: "Position",
          parse: () => (selectedRequest.position),
          order: 2,
          col: 0,
        },
        businessEMail: {
          title: "Business e-mail",
          parse: (v) => (v),
          order: 3,
          col: 0,
        },
        companyRegistrationNumber: {
          title: "Company registration number",
          parse: (v) => (v),
          order: 4,
          col: 0,
        },
        streetAndNumber: {
          title: "Street and number",
          parse: (v) => (v),
          order: 5,
          col: 0,
        },
        postcode: {
          title: "Postcode",
          parse: (v) => (v),
          order: 6,
          col: 0,
        },
        establishedIn: {
          title: "Year established",
          parse: () => (selectedRequest.establishedIn),
          order: 7,
          col: 0,
        },
        typeOfBusiness: {
          title: "The type of business you have",
          parse: () => (selectedRequest.typeOfBusinessCustom || selectedRequest.typeOfBusiness),
          order: 8,
          col: 0,
        },
        buyerImportants: {
          title: "What’s important to you and your customers?",
          parse: () => ((selectedRequest.buyerImportants || []).join(', ')),
          order: 9,
          col: 0,
        },
        iAgreeToReceive: {
          title: "I agree to receive communication from Vegshelf, and agree to site Terms and Privacy Policy",
          parse: () => ('Yes'),
          order: 10,
          col: 0,
        },
        website: {
          title: "Website",
          parse: (v) => (v),
          order: 0,
          col: 1,
        },
        lastName: {
          title: "Last name",
          parse: (v) => (v),
          order: 1,
          col: 1,
        },
        phoneNumber: {
          title: "Phone number",
          parse: (v) => (`${selectedRequest.phoneCode} ${v}`),
          order: 2,
          col: 1,
        },
        companyLegalName: {
          title: "Company’s legal name",
          parse: () => (selectedRequest.companyLegalName),
          order: 3,
          col: 1,
        },
        companyVATnumber: {
          title: "VAT number",
          parse: (v) => (selectedRequest.companyVATcode ? `${selectedRequest.companyVATcode}${v}` : v),
          order: 4,
          col: 1,
        },
        city: {
          title: "City",
          parse: (v) => (v),
          order: 5,
          col: 1,
        },
        country: {
          title: "Country",
          parse: () => (selectedRequest.countryCustom || selectedRequest.country),
          order: 6,
          col: 1,
        },
        annualSales: {
          title: "Annual sales, €",
          parse: (v) => (v),
          order: 7,
          col: 1,
        },
        numberOfStoresYouWorkWith: {
          title: "Number of sales outlets",
          parse: (v) => (selectedRequest.numberOfStoresYouWorkWith),
          order: 8,
          col: 1,
        },
        howDidYouHearAboutVegshelf: {
          title: "How did you hear about Vegshelf?",
          parse: (v) => (selectedRequest.howDidYouHearAboutVegshelfCustom || v),
          order: 9,
          col: 1,
        },
      }
      :
      {
        //***** Supplier FIELDS *****//
        brandName: {
          title: "Brand name",
          parse: (v) => (v),
          order: 0,
          col: 0,
        },
        firstName: {
          title: "First name",
          parse: (v) => (v),
          order: 1,
          col: 0,
        },
        position: {
          title: "Position",
          parse: () => (selectedRequest.position),
          order: 2,
          col: 0,
        },
        businessEMail: {
          title: "Business e-mail",
          parse: (v) => (v),
          order: 3,
          col: 0,
        },
        companyRegistrationNumber: {
          title: "Company registration number",
          parse: (v) => (v),
          order: 4,
          col: 0,
        },
        streetAndNumber: {
          title: "Street and number",
          parse: (v) => (v),
          order: 5,
          col: 0,
        },
        postcode: {
          title: "Postcode",
          parse: (v) => (v),
          order: 6,
          col: 0,
        },
        establishedIn: {
          title: "Year established",
          parse: () => (selectedRequest.establishedIn),
          order: 7,
          col: 0,
        },
        primaryCategory: {
          title: "Primary product category",
          parse: (v) => (selectedRequest.primaryCategoryCustom || v),
          order: 8,
          col: 0,
        },
        numberOfStoresYouWorkWith: {
          title: "Number of stockists",
          parse: () => (selectedRequest.numberOfStoresYouWorkWith),
          order: 9,
          col: 0,
        },
        website: {
          title: "Website",
          parse: (v) => (v),
          order: 10,
          col: 0,
        },
        iAgreeToReceive: {
          title: "I agree to receive communication from Vegshelf, and agree to site Terms and Privacy Policy",
          parse: () => ('Yes'),
          order: 11,
          col: 0,
        },
        instagram: {
          title: "Instagram handle",
          parse: (v) => (selectedRequest.instagram),
          order: 0,
          col: 1,
        },
        lastName: {
          title: "Last name",
          parse: (v) => (v),
          order: 1,
          col: 1,
        },
        phoneNumber: {
          title: "Phone number",
          parse: (v) => (`${selectedRequest.phoneCode} ${v}`),
          order: 2,
          col: 1,
        },
        companyLegalName: {
          title: "Company’s legal name",
          parse: () => (selectedRequest.companyLegalName),
          order: 3,
          col: 1,
        },
        companyName: {
          title: "Company’s legal name",
          parse: () => (selectedRequest.companyName),
          order: 3.5,
          col: 1,
        },
        companyVATnumber: {
          title: "VAT number",
          parse: (v) => (selectedRequest.companyVATcode ? `${selectedRequest.companyVATcode}${v}` : v),
          order: 4,
          col: 1,
        },
        city: {
          title: "City",
          parse: (v) => (v),
          order: 5,
          col: 1,
        },
        country: {
          title: "Country",
          parse: () => (selectedRequest.countryCustom || selectedRequest.country),
          order: 6,
          col: 1,
        },
        annualSales: {
          title: "Annual sales, €",
          parse: (v) => (v),
          order: 7,
          col: 1,
        },
        numberOfProducts: {
          title: "Number of products in your assortment",
          parse: () => (selectedRequest.numberOfProducts),
          order: 8,
          col: 1,
        },
        leadTime: {
          title: "Average lead time",
          parse: () => (selectedRequest.leadTime),
          order: 9,
          col: 1,
        },
        howDidYouHearAboutVegshelf: {
          title: "How did you hear about Vegshelf?",
          parse: (v) => (selectedRequest.howDidYouHearAboutVegshelfCustom || v),
          order: 10,
          col: 1,
        },
      };
    const detailsProperties = [];
    let colIndex = 0;
    let fieldIndex = 0;
    for (const key in selectedRequest) {
      if (detailsMap[key]) {
        colIndex = detailsMap[key].col;
        detailsProperties[colIndex] = detailsProperties[colIndex] || [];
        const item = {
          title: detailsMap[key].title,
          value: detailsMap[key].parse(selectedRequest[key]),
          order: detailsMap[key].order
        };
        detailsProperties[colIndex].push(item);
      }
      fieldIndex++;
    }
    return detailsProperties;
  }

  render() {
    const {
      title,
      requests,
      onApprove,
      onReject,
      onDelete
    } = this.props;
    const items = [...(requests || [])];
    const detailsProperties = this.parseProperties();

    return (
      <div className={css.pageContent}>
        <h1 className={css.pageHeaderText}>{title}</h1>
        <table className={css.requestsTable}>
          <thead>
            <tr>
              <th>Company name</th>
              <th>Name, surname</th>
              <th>Country</th>
              <th>City</th>
              <th>Created</th>
              <th>Status</th>
              <th>&nbsp;</th>
              <th>&nbsp;</th>
              <th>&nbsp;</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {items.map((r) => {
              return (<tr key={r.key}>
                <td>{r.data.companyName}</td>
                <td>{r.data.firstName} {r.data.lastName}</td>
                <td>{r.data.country}</td>
                <td>{r.data.city}</td>
                <td>{formattedDate(r.createdDate)}</td>
                <td>{r.status}</td>
                <td>
                  <a className={css.requestButton} onClick={() => this.setState({ ...this.state, selectedRequest: r })}>
                    View details
                  </a>
                </td>
                <td>
                  {r.status !== 'approved' ? (
                    <a
                      className={css.requestButton}
                      onClick={() => this.setState({
                        ...this.state,
                        activeKey: r.key,
                        request: r,
                        showApproveDialog: true
                      })}
                    >Approve</a>
                  ) : (
                    <>&nbsp;</>
                  )}
                </td>
                <td>
                  {r.status !== 'rejected' ? (
                    <a
                      className={css.requestButton}
                      onClick={() => this.setState({
                        ...this.state,
                        activeKey: r.key,
                        request: r,
                        showRejectDialog: true
                      })}
                    >Reject</a>
                  ) : (
                    <>&nbsp;</>
                  )}
                </td>
                <td>
                  <a
                    className={css.requestButton}
                    onClick={() => this.setState({
                      ...this.state,
                      activeKey: r.key,
                      request: r,
                      showDeleteDialog: true
                    })}
                  >Delete</a>
                </td>
              </tr>);
            })}
          </tbody>
        </table>

        {this.state.selectedRequest ? (
          <div
            className={css.previewDialogContainer}
            onClick={() => this.setState({ ...this.state, selectedRequest: null })}
          >
            <div className={css.previewDialog} onClick={(e) => e.stopPropagation()}>
              <h1 className={css.previewDialogTitle}>Request details</h1>
              <div className={css.previewDialogGroups}>
                {detailsProperties.map((propertyGroup, i) => {
                  const properties = [...propertyGroup];
                  properties.sort((f, s) => {
                    if (f.order < s.order) return -1;
                    if (f.order > s.order) return 1;
                    return 0;
                  });

                  return (
                    <div key={`dp${i}`} className={css.propertiesColumn}>
                      {properties.map((p, i) => {
                        return (<div key={`pf${i}`} className={css.propertyField}>
                          <h4>{p.title}</h4>
                          <h5>{p.value}</h5>
                        </div>)
                      })}
                    </div>
                  );
                })}
              </div>
              <div className={css.previewDialogButtons}>
                <a
                  className={css.previewDialogCancelButton}
                  onClick={() => this.setState({ ...this.state, selectedRequest: null })}
                ></a>
              </div>
            </div>
          </div>
        ) : null}

        {this.state.showDeleteDialog ? (<div className={css.previewDialogContainer}>
          <div className={css.previewSmallDialog}>
            <h1 className={css.previewDialogTitle}>Delete request</h1>
            <p className={css.previewDialogContent}>
              You’re about to delete registration request.
              <br></br>
              This activity cannot be undone.
            </p>
            <div className={css.previewDialogButtons}>
              <a
                className={css.previewDialogCancelButton}
                onClick={() => this.setState({ ...this.state, activeKey: null, showDeleteDialog: false })}
              ></a>
              <a
                className={css.previewDialogConfirmButton}
                onClick={() => {
                  onDelete(this.state.activeKey);
                  this.setState({ ...this.state, activeKey: null, showDeleteDialog: false });
                }}
              >Delete</a>
            </div>
          </div>
        </div>) : null}

        {this.state.showApproveDialog ? (
          <div className={css.previewDialogContainer}>
            <div className={css.previewSmallDialog}>
              <h1 className={css.previewDialogTitle}>Approve request</h1>
              <p className={css.previewDialogContent}>
                You’re about to approve registration request.
                <br></br>
                This activity cannot be undone.
              </p>
              <div className={css.previewDialogButtons}>
                <a
                  className={css.previewDialogCancelButton}
                  onClick={() => this.setState({
                    ...this.state,
                    activeKey: null,
                    showApproveDialog: false
                  })}
                ></a>
                <a
                  className={css.previewDialogConfirmButton}
                  onClick={() => {
                    onApprove(this.state.request);
                    this.setState({ ...this.state, activeKey: null, showApproveDialog: false });
                  }}
                >Approve</a>
              </div>
            </div>
          </div>
        ) : null}

        {this.state.showRejectDialog ? (
          <div className={css.previewDialogContainer}>
            <div className={css.previewSmallDialog}>
              <h1 className={css.previewDialogTitle}>Reject request</h1>
              <p className={css.previewDialogContent}>
                You’re about to reject registration request.
                <br></br>
                This activity cannot be undone.
                <br />
                <h5 className={css.rejectCommentLabel}>Comment:</h5>
                <textarea
                  className={css.rejectCommentTextarea}
                  ref={(el) => {
                    this.txtComment = el;
                  }}
                />
              </p>
              <div className={css.previewDialogButtons}>
                <a
                  className={css.previewDialogCancelButton}
                  onClick={() => this.setState({ ...this.state, activeKey: null, showRejectDialog: false })}
                ></a>
                <a
                  className={css.previewDialogConfirmButton}
                  onClick={() => {
                    onReject(this.state.request, this.txtComment.value);
                    this.setState({ ...this.state, activeKey: null, showRejectDialog: false });
                  }}
                >Reject</a>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default RegistrationRequestsPage;
