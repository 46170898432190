import React from "react";
import { string, shape, oneOf } from "prop-types";
import { Link } from "react-router-dom";
import { FormattedMessage } from "../../util/reactIntl";
import css from "./ProductEmptyState.module.css";
import classNames from "classnames";

const EmptyStateComponent = ({
  options: {
    containerOffsetClassName,
    iconStylesClassName,
    titleMessageId,
    textMessageId,
    buttonMessageId,
    buttonLinkAddress,
  },
}) => {
  const containerCss = classNames(css.noSearchResultContainer, css[containerOffsetClassName]);
  const iconCss = classNames(css.emptyStateIcon, css[iconStylesClassName]);

  return (
    <div className={containerCss}>
      <div className={iconCss} />
      <p className={css.noSearchResultTitle}>
        <FormattedMessage id={titleMessageId} />
      </p>
      <p className={css.noSearchResultText}>
        <FormattedMessage id={textMessageId} />
      </p>
      {buttonLinkAddress && buttonMessageId ?
        <Link to={buttonLinkAddress} className={css.browseProductsLink}>
          <FormattedMessage id={buttonMessageId} />
        </Link>
        : null
      }
    </div>
  );
};

/**
 * @description Array of available offsets CSS classes.
 * @type {string[]}
 */
const availableContainerOffsets = [
  "buyersFavoriteOffset",
  "searchOffset",
  "messagesOffset",
  "orderAndRequestOffset",
  "cartOffset",
  "invoicing",
];

/**
 * @description Array of available icons CSS classes.
 * @type {string[]}
 */
const availableIcons = [
  "emojiNeutralIcon",
  "searchIcon",
  "messagesIcon",
  "orderAndRequestIcon",
  "cartIcon",
  "invoicingIcon",
];

export const EmptyStateOptions = shape({
  containerOffsetClassName: oneOf(availableContainerOffsets).isRequired,
  iconStylesClassName: oneOf(availableIcons).isRequired,
  titleMessageId: string.isRequired,
  textMessageId: string.isRequired,
  buttonMessageId: string,
  buttonLinkAddress: string,
}).isRequired;

EmptyStateComponent.propTypes = {
  options: EmptyStateOptions,
};

export default EmptyStateComponent;
