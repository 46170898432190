// These helpers are calling FTW's own server-side routes
// so, they are not directly calling Marketplace API or Integration API.
// You can find these api endpoints from 'server/api/...' directory

import { types as sdkTypes, transit } from './sdkLoader';
import config from '../config';
import Decimal from 'decimal.js';
const isBrowser = () => typeof window !== "undefined"
export const apiBaseUrl = () => {
  const port = process.env.REACT_APP_DEV_API_SERVER_PORT;
  const useDevApiServer = process.env.NODE_ENV === 'development' && !!port;

  // In development, the dev API server is running in a different port
  if (useDevApiServer) {
    return `http://localhost:${port}`;
  }

  // Otherwise, use the same domain and port as the frontend
  return `${window.location.origin}`;
};

// Application type handlers for JS SDK.
//
// NOTE: keep in sync with `typeHandlers` in `server/api-util/sdk.js`
export const typeHandlers = [
  // Use Decimal type instead of SDK's BigDecimal.
  {
    type: sdkTypes.BigDecimal,
    customType: Decimal,
    writer: v => new sdkTypes.BigDecimal(v.toString()),
    reader: v => new Decimal(v.value),
  },
];

const serialize = data => {
  return transit.write(data, { typeHandlers, verbose: config.sdk.transitVerbose });
};

const deserialize = str => {
  return transit.read(str, { typeHandlers });
};

const post = (path, body, method = 'POST') => {
  if(!isBrowser()){
    return new Promise((res)=>{res();});
  }
  const url = `${apiBaseUrl()}${path}`;
  const options = {
    method: method,
    credentials: 'include',
    headers: {
      'Content-Type': 'application/transit+json',
    },
    body: body ? serialize(body) : null,
  };
  return fetch(url, options).then(res => {
    const contentTypeHeader = res.headers.get('Content-Type');
    const contentType = contentTypeHeader ? contentTypeHeader.split(';')[0] : null;

    if (res.status >= 400) {
      return res.json().then(data => {
        let e = new Error();
        e = Object.assign(e, data);

        throw e;
      });
    }
    if (contentType === 'application/transit+json') {
      return res.text().then(deserialize);
    } else if (contentType === 'application/json') {
      return res.json();
    }
    return res.text();
  });
};

// Fetch transaction line items from the local API endpoint.
//
// See `server/api/transaction-line-items.js` to see what data should
// be sent in the body.
export const transactionLineItems = body => {
  return post('/api/transaction-line-items', body);
};

// Initiate a privileged transaction.
//
// With privileged transitions, the transactions need to be created
// from the backend. This endpoint enables sending the booking data to
// the local backend, and passing that to the Marketplace API.
//
// See `server/api/initiate-privileged.js` to see what data should be
// sent in the body.
export const initiatePrivileged = body => {
  return post('/api/initiate-privileged', body);
};

// Transition a transaction with a privileged transition.
//
// This is similar to the `initiatePrivileged` above. It will use the
// backend for the transition. The backend endpoint will add the
// payment line items to the transition params.
//
// See `server/api/transition-privileged.js` to see what data should
// be sent in the body.
export const transitionPrivileged = body => {
  return post('/api/transition-privileged', body);
};

// Create user with identity provider (e.g. Facebook or Google)
//
// If loginWithIdp api call fails and user can't authenticate to Flex with idp
// we will show option to create a new user with idp.
// For that user needs to confirm data fetched from the idp.
// After the confirmation, this endpoint is called to create a new user with confirmed data.
//
// See `server/api/auth/createUserWithIdp.js` to see what data should
// be sent in the body.
export const createUserWithIdp = body => {
  return post('/api/auth/create-user-with-idp', body);
};
export const loginApi = () => {
  return post('/api/login-as', null, 'GET');
};
export const getNotificationSettings = () => {
  return post('/api/get-notification-settings', null, 'GET');
};
export const getNotifications = () => {
  return post('/api/get-notifications', null, 'GET');
};
export const saveNotificationSettings = (settings) => {
  return post('/api/save-notification-settings', { settings }, 'POST');
};
export const sendNotification = (id, type, message, customData) => {
  return post('/api/send-notification', { id, type, message, customData }, 'POST');
};
export const getCategorySections = () => {
  return post('/api/get-category-sections', null, 'GET');
};
export const getInvoiceNumber = () => {
  return post('/api/get-invoice-number', null, 'GET');
};
export const saveCategorySections = (sections) => {
  return post('/api/save-category-sections', { sections }, 'POST');
};
export const approveProduct = (id) => {
  return post('/api/approve', { id }, 'POST');
};
export const checkEmailAddress = (email) => {
  /**
   * @description Formatting to the lower case - avoid possibility sign up several times with the same value but CAPS.
   * @description If email already used - API will response with a user details, else - response will be equal to null.
   * @type {string}
   */
  const caseInsensitiveEmail = email.toLowerCase();
  return post('/api/checkEmail', { email: caseInsensitiveEmail }, 'POST');
};
// Commented out until function developed further
//export const marketplaceCheck = (name) => {
//  return post('/api/marketplacecheck', { name }, 'POST');
//};
export const saveEmailRequest = (email) => {
  return post('/api/email-request', { email }, 'POST');
};
// Function for logging user logins
export const saveEmailLoginRequest = (email) => {
  return post('/api/email-login-request', { email }, 'POST');
};
export const registerationSupplierRequest = (request) => {
  return post('/api/registeration-supplier', { request }, 'POST');
};
export const registerationBuyerRequest = (request) => {
  return post('/api/registeration-buyer', { request }, 'POST');
};
export const deleteRegistrationRequest = (key) => {
  return post('/api/delete-request', { key }, 'POST');
};
export const getRegistrationRequest = (key) => {
  return post('/api/get-request', { key }, 'POST');
};
export const approveRegistrationRequest = (key) => {
  return post('/api/approve-request', { key }, 'POST');
};
export const rejectRegistrationRequest = (key, comment) => {
  return post('/api/reject-request', { key, comment }, 'POST');
};
export const getSupplierRequests = () => {
  return post('/api/get-supplier-requests', null, 'GET');
};
export const getBuyerRequests = () => {
  return post('/api/get-buyer-requests', null, 'GET');
};
export const sendMail = (to, subject, body) => {
  return post('/api/send-mail', { to, subject, body }, 'POST');
};
