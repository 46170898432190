import React, { Component } from 'react';
import { bool } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import config from '../../config';
import { NamedLink } from '../../components';
import { displayRegistrationConfirmDialog } from '../../ducks/Modal.duck';
import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
} from '../../components';

import { TopbarContainer } from '..';
import { FormattedMessage } from '../../util/reactIntl';

import facebookImage from '../../assets/VegshelfFacebook-1200x630.png';
import twitterImage from '../../assets/VegshelfTwitter-600x314.png';
import css from './Blog1Page.module.css';

import arrowLeft from "../../assets/arrow-left-000000.svg";
import articleThumbnailImage from '../../assets/vegan-products-in-retail.png';
import paragraph1visual1 from '../../assets/dietary-lifestyle-in-germany-france-netherlands.png';
import paragraph2visual1 from '../../assets/register-as-retailer-on-vegshelf.png';
import paragraph3visual1 from '../../assets/one-third-global-greenhouse-gas-emissions-from-food.png';
import authorImage from '../../assets/baiba-soika-vegshelf.png';

export class Blog1PageComponent extends Component {
  constructor(props) {
    super(props);
    //this.state = { categoryIndex: 0, planIndex: 1, blogIndex: 0, FAQIndex: -1 };
  }
  render() {
    const {
      intl,
      scrollingDisabled,
      params,
    } = this.props;

    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage({ id: 'Blog1.schemaTitle' }, { siteTitle });
    const schemaDescription = intl.formatMessage({ id: 'Blog1.schemaDescription' }, { siteTitle });
    const schemaImage = `${config.canonicalRootURL}${facebookImage}`;

    const link1 = (
      <a className={css.linkFormat} href="https://www.vegshelf.com" target="_blank" >
        <FormattedMessage id="Blog1.link_1" />
      </a>
    );

    const email1 = (
      <a className={css.linkFormat} href="mailto:hello@vegshelf.com" target="_blank" >
        <FormattedMessage id="DeleteAccountPage.email" />
      </a>
    );

    return (
      <Page
        className={css.root}
        scrollingDisabled={scrollingDisabled}
        contentType="website"
        description={schemaDescription}
        title={schemaTitle}
        robots='index'
        facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
        twitterImages={[
          { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
        ]}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'WebPage',
          description: schemaDescription,
          name: schemaTitle,
          image: [schemaImage],
        }}
      >
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div className={css.blogArticleContainer}>
              <div className={css.blogArticleSection}>
                <NamedLink className={css.backToBlogSection} name="BlogPage">
                  <img className={css.arrow} src={arrowLeft} alt="" />
                  <p className={css.backToBlogText}>
                    <FormattedMessage id="BlogSinglePage.backToMainBlogPage" />
                  </p>
                </NamedLink>
                <h1 className={css.articleTitle}>
                  <FormattedMessage id="Blog1.title" />
                </h1>
                <p className={css.blogDate}>
                  <FormattedMessage id="Blog1.date" />
                </p>
                <img className={css.articleThumbnailImage} src={articleThumbnailImage} alt="Vegan food and beverage products on the grocery store shelves" />
                <div className={css.blogAuthorSection}>
                  <img className={css.authorImage} src={authorImage} alt="Baiba Soika, Co-founder and CEO of Vegshelf" />
                  <p className={css.blogAuthor}>
                    <FormattedMessage id="BlogPage.authorBaiba" />
                  </p>
                  <p className={css.blogAuthorPosition}>
                    <FormattedMessage id="BlogPage.authorBaibaPosition" />
                  </p>
                </div>
                <div className={css.articleTextContainer}>
                  <p className={css.articleIntroParagraph}>
                    <FormattedMessage id="Blog1.introParagraph" />
                  </p>
                  <h2 className={css.articleSubtitle}>
                    <FormattedMessage id="Blog1.subtitle_1" />
                  </h2>
                  <p className={css.articleParagraph}>
                    <FormattedMessage id="Blog1.paragraph_1_1" />
                  </p>
                  <img className={css.paragraphVisual} src={paragraph1visual1} alt="Vegan, vegetarian, flexitarian, and other dietary lifestyle in Germany, France, and Netherlands" />
                  <p className={css.imageCopyrightText}>
                    <FormattedMessage id="Blog1.paragraph1Visual1Copyright" />
                  </p>
                  <p className={css.articleParagraph}>
                    <FormattedMessage id="Blog1.paragraph_1_2" />
                  </p>

                  <h2 className={css.articleSubtitle}>
                    <FormattedMessage id="Blog1.subtitle_2" />
                  </h2>
                  <p className={css.articleParagraph}>
                    <FormattedMessage id="Blog1.paragraph_2_1" />
                  </p>
                  <p className={css.articleParagraph}>
                    <FormattedMessage id="Blog1.paragraph_2_2" />
                  </p>
                  <p className={css.articleParagraph}>
                    <FormattedMessage id="Blog1.paragraph_2_3" />
                  </p>
                  <img className={css.paragraphVisual} src={paragraph2visual1} alt="Order outstanding wholesale vegan food and beverage products for your retail on Vegshelf" />
                  <p className={css.imageCopyrightText}>
                    <FormattedMessage id="Blog1.paragraph2Visual1Copyright" />
                  </p>
                  <p className={css.articleParagraph}>
                    <FormattedMessage id="Blog1.paragraph_2_4" values={{ link1 }} />
                  </p>

                  <h2 className={css.articleSubtitle}>
                    <FormattedMessage id="Blog1.subtitle_3" />
                  </h2>
                  <p className={css.articleParagraph}>
                    <FormattedMessage id="Blog1.paragraph_3_1" />
                  </p>
                  <p className={css.articleParagraph}>
                    <FormattedMessage id="Blog1.paragraph_3_2" />
                  </p>
                  <img className={css.paragraphVisual} src={paragraph3visual1} alt="More than 30% of global greenhouse gas emissions is coming from the food industry" />
                  <p className={css.imageCopyrightText}>
                    <FormattedMessage id="Blog1.paragraph3Visual1Copyright" />
                  </p>
                  <p className={css.articleParagraph}>
                    <FormattedMessage id="Blog1.paragraph_3_3" />
                  </p>
                  <h2 className={css.articleSubtitle}>
                    <FormattedMessage id="Blog1.closingTitle" />
                  </h2>
                  <p className={css.articleParagraph}>
                    <FormattedMessage id="Blog1.closingParagraph_1" />
                  </p>
                  <p className={css.articleParagraph}>
                    <FormattedMessage id="Blog1.closingParagraph_2" />
                  </p>
                  <p className={css.articleParagraph}>
                    <FormattedMessage id="Blog1.closingParagraph_3" values={{ email1 }} />
                  </p>
                  <p className={css.articleImageCopyrightText}>
                    <FormattedMessage id="Blog1.articleImageCopyright" />
                  </p>
                </div>
              </div>
            </div>
            <div className={css.callToRegisterContainer}>
              <div className={css.callToRegisterSection}>
                <h3 className={css.callToRegisterTitle}>
                  <FormattedMessage id="BlogPage.registerStore" />
                </h3>
                <div className={css.signUpSection}>
                  <div className={css.buttonSection}>
                    <NamedLink className={css.signUpButton} name="LandingPage">
                      <FormattedMessage id="BlogPage.registerBuyerSignupButton" />
                    </NamedLink>
                  </div>
                  <p className={css.moreInfo}>
                    <FormattedMessage id="BlogPage.registerBrandWantToSell" />
                    <NamedLink className={css.loginLink} name="ApplyToSellPage">
                      <FormattedMessage id="BlogPage.registerMoreInfo" />
                    </NamedLink>
                  </p>
                </div>
              </div>
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page >
    );
  }
};

Blog1PageComponent.defaultProps = {
};

Blog1PageComponent.propTypes = {
  scrollingDisabled: bool.isRequired
};
const mapStateToProps = state => {

  return {
    scrollingDisabled: isScrollingDisabled(state)
  };
};

const mapDispatchToProps = dispatch => ({
  onShowRegistrationConfirmDialog: () => dispatch(displayRegistrationConfirmDialog(true, false))
});

const Blog1Page = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(Blog1PageComponent);

export default Blog1Page;