import classNames from "classnames";
import { bool, object } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import facebookImage from "../../assets/VegshelfFacebook-1200x630.png";
import twitterImage from "../../assets/VegshelfTwitter-600x314.png";
import brandIcon from "../../assets/best-vegan-food-brands.png";
import {
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Menu,
  MenuContent,
  MenuItem,
  MenuLabel,
  NamedLink,
  Page,
  PaginationButtons,
  ProductContainer,
} from "../../components";
import config from "../../config";
import { TopbarContainer } from "../../containers";
import { isScrollingDisabled } from "../../ducks/UI.duck";
import getCountryCodes from "../../translations/countryCodes";
import getProductDictionaries from "../../translations/productDictionaries";
import { FormattedMessage, injectIntl, intlShape } from "../../util/reactIntl";
import { propTypes } from "../../util/types";
import { loadTopCategoryProducts, searchProducts } from "./SearchProductsPage.duck";
import { slugify } from "../../util/urlTrimming";
import { localePart, storedLocale } from "../../components/Wrapper/ClassWrapper";
import { displayRegistrationConfirmDialog } from "../../ducks/Modal.duck";
import css from "./SearchProductsPage.module.css";
import NotFoundPage from "../NotFoundPage/NotFoundPage";
import LoaderComponent from "../../components/Loader/Loader";

import arrowLeft from '../../assets/arrow-left-000000.svg';
import arrowRight from '../../assets/arrow-right-000000.svg';

const locale = storedLocale() || localePart();

const newest = <FormattedMessage id="SearchProductsPage.newest" />;
const oldest = <FormattedMessage id="SearchProductsPage.oldest" />;

export class SearchProductsPageComponent extends Component {
  constructor(props) {
    super(props);
    this.defaultFilter = {
      pageIndex: 1,
      subCategory: null,
      property: null,
      shipping: null,
      location: null,
      temperature: null,
      price: null,
      hasFilter: false,
    };
    this.state = { ...this.defaultFilter, isfilterByDateOpened: false, menuItemSelected: newest, mobileFilterMenu: false };
    this.searchInterval = null;
    this.onClearFilterClick = this.onClearFilterClick.bind(this);
    this.onChangeFilter = this.onChangeFilter.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.onFilterByDateItemClick = this.onFilterByDateItemClick.bind(this);
  }

  onFilterByDateItemClick(menuItemSelected) {
    this.setState({ menuItemSelected });
  }
  onClearFilterClick() {
    const filter = { ...this.defaultFilter };
    this.setState(filter);
    this.search(filter);
  }
  onChangeFilter(fieldName, event, multiselect) {
    const filter = { ...this.state, hasFilter: true };
    if (!multiselect) {
      filter[fieldName] = event.target.value;
    } else {
      filter[fieldName] = filter[fieldName] || [];
      if (event.target.checked) {
        filter[fieldName].push(event.target.value);
      } else {
        filter[fieldName] = filter[fieldName].filter(v => {
          return v !== event.target.value;
        });
      }
    }
    this.setState(filter);
    this.search(filter);
  }
  onPageChange(page) {
    const newState = { ...this.state, pageIndex: page };
    this.setState(newState);
    this.props.onSearchProducts({ ...(this.props.params || {}), ...newState });
  }
  search(filter) {
    if (this.searchInterval) {
      clearTimeout(this.searchInterval);
    }
    setTimeout(() => {
      this.props.onSearchProducts({ ...(this.props.params || {}), ...filter });
      this.searchInterval = null;
    }, 2000);
  }
  componentDidUpdate(prevProps) {
    // Reset filters if /categories subpage is changed
    if (prevProps.params.productCategoryCode !== this.props.params.productCategoryCode) {
      this.onClearFilterClick();
    }
  }

  render() {
    const {
      history,
      intl,
      scrollingDisabled,
      params,
      // topCategoryProductsError,
      // topCategoryProductsInProgress,
      // searchProductsError,
      // searchProductsInProgress,
      topCategoryProducts,
      searchedProducts,
      isAuthenticated,
      onShowRegistrationConfirmDialog,
      searchProductsInProgress,
    } = this.props;

    const schemaImage = `${config.canonicalRootURL}${facebookImage}`;
    let category = null;
    let subCategories = [];
    const dictionaries = getProductDictionaries();
    const countries = getCountryCodes();
    if (params.productCategoryCode) {
      category = dictionaries.reducedCategories.filter((x) => x.categoryCode === params.productCategoryCode)[0];
      if (!category) {
        return <NotFoundPage />;
      }
      subCategories = category.subCategories;
    }
    const currentCategoryName = category ? `in ${category.name}` : null;
    const siteTitle = config.siteTitle;
    //const categoryName = category ? category.name : `Search results for "${params.search}"`;
    const categoryName = category ? category.metaName : null;
    const searchResultText = params.search;
    const categoryMetaTag = category ? category.categoryMetaTag : intl.formatMessage({ id: 'SearchPage.productsContainerTitle' }, { searchResultText });
    //const schemaTitle = intl.formatMessage({ id: 'SearchProductsPage.schemaTitle' }, { categoryName, siteTitle });
    const schemaTitle = intl.formatMessage({ id: 'SearchProductsPage.schemaTitle' }, { categoryMetaTag });
    //const schemaDescription = intl.formatMessage({ id: 'SearchProductsPage.schemaDescription' }, { categoryName, siteTitle });
    const schemaDescription = category ? intl.formatMessage({ id: 'SearchProductsPage.schemaDescription' }, { categoryName, siteTitle }) : intl.formatMessage({ id: 'SearchPage.productsContainerTitle' }, { searchResultText });
    //const productsContainerTitle = category ? `${category.name}` : `Search results for "${params.search}"`;
    const productsContainerTitle = category ? `${category.name}` : intl.formatMessage({ id: 'SearchPage.productsContainerTitle' }, { searchResultText });
    const topProducts = topCategoryProducts?.data || [];
    const viewProducts = searchedProducts?.data || [];
    const totalProductsMoreThanSix = searchedProducts?.meta?.totalItems > 6;
    const noProductsFound = searchedProducts?.meta?.totalItems < 1;
    const firstSixProducts = searchedProducts?.data.slice(0, 6) || [];
    const viewProductsCheck = isAuthenticated ? viewProducts : (totalProductsMoreThanSix ? firstSixProducts : viewProducts);
    const currentPage = this.state.pageIndex;
    const totalPages = searchedProducts?.meta?.totalPages || 1;
    const totalCount = searchedProducts?.meta?.totalItems || 0;

    const categoryDescriptionTop = [
      {
        id: intl.formatMessage({ id: 'SearchProductsPage.CategoryNameTop1' }),
        topCategoryDescription: intl.formatMessage({ id: 'SearchProductsPage.CategoryDescriptionTop1' }),
        bottomCategoryTitle1: intl.formatMessage({ id: 'SearchProductsPage.CategoryTitleBottom1_1' }),
        bottomCategoryDescription1_1: intl.formatMessage({ id: 'SearchProductsPage.CategoryDescriptionBottom1_1' }),
        bottomCategoryDescription1_2: intl.formatMessage({ id: 'SearchProductsPage.CategoryDescriptionBottom1_2' }),
        bottomCategoryDescription1_3: intl.formatMessage({ id: 'SearchProductsPage.CategoryDescriptionBottom1_3' }),
        bottomCategoryTitle2: intl.formatMessage({ id: 'SearchProductsPage.CategoryTitleBottom1_2' }),
        bottomCategoryDescription2_1: intl.formatMessage({ id: 'SearchProductsPage.CategoryDescriptionBottom1_4' }),
        bottomCategoryDescription2_2: intl.formatMessage({ id: 'SearchProductsPage.CategoryDescriptionBottom1_5' }),
        bottomCategoryDescription2_3: intl.formatMessage({ id: 'SearchProductsPage.CategoryDescriptionBottom1_6' }),
        bottomCategoryDescription2_4: intl.formatMessage({ id: 'SearchProductsPage.CategoryDescriptionBottom1_7' }),
        bottomCategoryDescription2_5: intl.formatMessage({ id: 'SearchProductsPage.CategoryDescriptionBottom1_8' }),
      },
      {
        id: intl.formatMessage({ id: 'SearchProductsPage.CategoryNameTop2' }),
        topCategoryDescription: intl.formatMessage({ id: 'SearchProductsPage.CategoryDescriptionTop2' }),
        bottomCategoryTitle1: intl.formatMessage({ id: 'SearchProductsPage.CategoryTitleBottom2_1' }),
        bottomCategoryDescription1_1: intl.formatMessage({ id: 'SearchProductsPage.CategoryDescriptionBottom2_1' }),
        bottomCategoryDescription1_2: intl.formatMessage({ id: 'SearchProductsPage.CategoryDescriptionBottom2_2' }),
        bottomCategoryDescription1_3: intl.formatMessage({ id: 'SearchProductsPage.CategoryDescriptionBottom2_3' }),
        bottomCategoryTitle2: intl.formatMessage({ id: 'SearchProductsPage.CategoryTitleBottom2_2' }),
        bottomCategoryDescription2_1: intl.formatMessage({ id: 'SearchProductsPage.CategoryDescriptionBottom2_4' }),
        bottomCategoryDescription2_2: intl.formatMessage({ id: 'SearchProductsPage.CategoryDescriptionBottom2_5' }),
        bottomCategoryDescription2_3: intl.formatMessage({ id: 'SearchProductsPage.CategoryDescriptionBottom2_6' }),
        bottomCategoryDescription2_4: intl.formatMessage({ id: 'SearchProductsPage.CategoryDescriptionBottom2_7' }),
        bottomCategoryDescription2_5: intl.formatMessage({ id: 'SearchProductsPage.CategoryDescriptionBottom2_8' }),
      },
      {
        id: intl.formatMessage({ id: 'SearchProductsPage.CategoryNameTop3' }),
        topCategoryDescription: intl.formatMessage({ id: 'SearchProductsPage.CategoryDescriptionTop3' }),
        bottomCategoryTitle1: intl.formatMessage({ id: 'SearchProductsPage.CategoryTitleBottom3_1' }),
        bottomCategoryDescription1_1: intl.formatMessage({ id: 'SearchProductsPage.CategoryDescriptionBottom3_1' }),
        bottomCategoryDescription1_2: intl.formatMessage({ id: 'SearchProductsPage.CategoryDescriptionBottom3_2' }),
        bottomCategoryDescription1_3: intl.formatMessage({ id: 'SearchProductsPage.CategoryDescriptionBottom3_3' }),
        bottomCategoryTitle2: intl.formatMessage({ id: 'SearchProductsPage.CategoryTitleBottom3_2' }),
        bottomCategoryDescription2_1: intl.formatMessage({ id: 'SearchProductsPage.CategoryDescriptionBottom3_4' }),
        bottomCategoryDescription2_2: intl.formatMessage({ id: 'SearchProductsPage.CategoryDescriptionBottom3_5' }),
        bottomCategoryDescription2_3: intl.formatMessage({ id: 'SearchProductsPage.CategoryDescriptionBottom3_6' }),
        bottomCategoryDescription2_4: intl.formatMessage({ id: 'SearchProductsPage.CategoryDescriptionBottom3_7' }),
        bottomCategoryDescription2_5: intl.formatMessage({ id: 'SearchProductsPage.CategoryDescriptionBottom3_8' }),
      },
      {
        id: intl.formatMessage({ id: 'SearchProductsPage.CategoryNameTop4' }),
        topCategoryDescription: intl.formatMessage({ id: 'SearchProductsPage.CategoryDescriptionTop4' }),
        bottomCategoryTitle1: intl.formatMessage({ id: 'SearchProductsPage.CategoryTitleBottom4_1' }),
        bottomCategoryDescription1_1: intl.formatMessage({ id: 'SearchProductsPage.CategoryDescriptionBottom4_1' }),
        bottomCategoryDescription1_2: intl.formatMessage({ id: 'SearchProductsPage.CategoryDescriptionBottom4_2' }),
        bottomCategoryDescription1_3: intl.formatMessage({ id: 'SearchProductsPage.CategoryDescriptionBottom4_3' }),
        bottomCategoryTitle2: intl.formatMessage({ id: 'SearchProductsPage.CategoryTitleBottom4_2' }),
        bottomCategoryDescription2_1: intl.formatMessage({ id: 'SearchProductsPage.CategoryDescriptionBottom4_4' }),
        bottomCategoryDescription2_2: intl.formatMessage({ id: 'SearchProductsPage.CategoryDescriptionBottom4_5' }),
        bottomCategoryDescription2_3: intl.formatMessage({ id: 'SearchProductsPage.CategoryDescriptionBottom4_6' }),
        bottomCategoryDescription2_4: intl.formatMessage({ id: 'SearchProductsPage.CategoryDescriptionBottom4_7' }),
      },
    ];

    let topCategoryText;
    let bottomTitle;
    let bottomCategoryText;
    let bottomCategoryTitle1;
    let bottomCategoryTitle2;
    let bottomCategoryDescription1_1;
    let bottomCategoryDescription1_2;
    let bottomCategoryDescription1_3;
    let bottomCategoryDescription2_1;
    let bottomCategoryDescription2_2;
    let bottomCategoryDescription2_3;
    let bottomCategoryDescription2_4;
    let bottomCategoryDescription2_5;


    for (const categorySpecificText of categoryDescriptionTop) {
      if (params.search?.length >= 0) {
        break
      }
      if (categorySpecificText.id === category.name) {
        topCategoryText = categorySpecificText.topCategoryDescription;
        bottomTitle = categorySpecificText.bottomCategoryTitle;
        bottomCategoryText = categorySpecificText.bottomCategoryDescription;
        bottomCategoryTitle1 = categorySpecificText.bottomCategoryTitle1;
        bottomCategoryTitle2 = categorySpecificText.bottomCategoryTitle2;
        bottomCategoryDescription1_1 = categorySpecificText.bottomCategoryDescription1_1;
        bottomCategoryDescription1_2 = categorySpecificText.bottomCategoryDescription1_2;
        bottomCategoryDescription1_3 = categorySpecificText.bottomCategoryDescription1_3;
        bottomCategoryDescription2_1 = categorySpecificText.bottomCategoryDescription2_1;
        bottomCategoryDescription2_2 = categorySpecificText.bottomCategoryDescription2_2;
        bottomCategoryDescription2_3 = categorySpecificText.bottomCategoryDescription2_3;
        bottomCategoryDescription2_4 = categorySpecificText.bottomCategoryDescription2_4;
        bottomCategoryDescription2_5 = categorySpecificText.bottomCategoryDescription2_5;
        break;
      } else {
        topCategoryText = null;
        bottomTitle = null;
        bottomCategoryText = null;
        bottomCategoryTitle1 = null;
        bottomCategoryTitle2 = null;
        bottomCategoryDescription1_1 = null;
        bottomCategoryDescription1_2 = null;
        bottomCategoryDescription1_3 = null;
        bottomCategoryDescription2_1 = null;
        bottomCategoryDescription2_2 = null;
        bottomCategoryDescription2_3 = null;
        bottomCategoryDescription2_4 = null;
        bottomCategoryDescription2_5 = null;
      }
    };

    const filterCount = searchedProducts?.filterCount || 0;
    const filterLabelId = filterCount === 1 ? 'SearchProductsPage.filterSelected' : 'SearchProductsPage.filtersSelected';
    const filterTitle = filterCount > 0
      ? intl.formatMessage({ id: filterLabelId }, { filterCount: filterCount })
      : intl.formatMessage({ id: 'SearchProductsPage.noFilterUse' });
    const filterFormat = [
      {
        fieldName: 'subCategory',
        shortModeFiled: null,
        nameId: 'SearchProductsPage.SubCategoryFilterLabel',
        multiselect: true,
        hide: !subCategories || subCategories.length < 1,
        items: subCategories.map(c => {
          return {
            value: c.categoryCode,
            name: c.name,
          };
        }),
      },
      {
        fieldName: 'property',
        shortModeFiled: 'propertyShowAll',
        nameId: 'SearchProductsPage.ValuesFilterLabel',
        multiselect: true,
        items: dictionaries.productProperties.map(c => {
          return {
            value: c.code,
            name: c.name,
          };
        }),
      },
      {
        fieldName: 'shipping',
        shortModeFiled: 'shippingShowAll',
        nameId: 'SearchProductsPage.ShippingFilterLabel',
        multiselect: true,
        items: dictionaries.leadTimes.map(c => {
          return {
            value: c.code.toString(),
            name: c.name,
          };
        }),
      },
      {
        fieldName: 'location',
        shortModeFiled: 'locationShowAll',
        multiselect: true,
        nameId: 'SearchProductsPage.LocationFilterLabel',
        items: countries.map(c => {
          return {
            value: c.name,
            name: c.name,
          };
        }),
      },
      {
        fieldName: 'temperature',
        nameId: 'SearchProductsPage.TemperatureFilterLabel',
        multiselect: true,
        items: dictionaries.requiredTemperatures.map(c => {
          return {
            value: c.code.toString(),
            name: c.name,
          };
        }),
      },
      {
        fieldName: 'price',
        shortModeFiled: 'priceShowAll',
        nameId: 'SearchProductsPage.PriceFilterLabel',
        multiselect: false,
        hide: !isAuthenticated,
        items: [
          { name: <FormattedMessage id="SearchProductsPage.priceFilter1" />, value: '0,2' },
          { name: '€2.00 - €3.99', value: '2,4' },
          { name: '€4.00 - €6.99', value: '4,7' },
          { name: '€7.00 - €9.99', value: '7,10' },
          { name: <FormattedMessage id="SearchProductsPage.priceFilter2" />, value: '10,' },
        ],
      },
    ];
    const emptyStateOptions = {
      containerOffsetClassName: "searchOffset",
      iconStylesClassName: "searchIcon",
      titleMessageId: "SearchProductsPage.noSearchResultTitle",
      textMessageId: "SearchProductsPage.noSearchResultText",
    };

    const greyIcon = !this.state.hasFilter;

    const mobileFilters = (
      <div className={css.productFilterSectionMobile}>
        {filterFormat
          .filter(f => !f.hide)
          .map(filter => {
            const hiddenSeeMoreButton = !filter.shortModeFiled;
            const showAllItems =
              hiddenSeeMoreButton || this.state[filter.shortModeFiled] === true;
            const items = showAllItems ? filter.items : filter.items.slice(0, 3);
            const showMoreText = showAllItems
              ? 'SearchProductsPage.hideLabel'
              : 'SearchProductsPage.seeMoreLabel';

            const arrowIconCss = classNames(css.arrow, {
              [css.up]: showAllItems,
              [css.down]: !showAllItems,
            });
            return (
              <div key={filter.fieldName} className={css.filterFieldItem}>
                <p className={css.filterName}>
                  <FormattedMessage id={filter.nameId} />
                </p>
                {items.map(item => {
                  const key = `${filter.fieldName}_${item.value}`;
                  const checked = filter.multiselect
                    ? (this.state[filter.fieldName] || []).indexOf(
                      item.value.toString()
                    ) > -1
                    : this.state[filter.fieldName] === item.value;
                  return (
                    <div key={key} className={css.filterOption}>
                      <input
                        type={filter.multiselect ? 'checkbox' : 'radio'}
                        value={item.value}
                        checked={checked}
                        name={filter.fieldName}
                        id={key}
                        onChange={e => {
                          this.onChangeFilter(filter.fieldName, e, filter.multiselect);
                        }}
                      />
                      <label htmlFor={key}>{item.name}</label>
                    </div>
                  );
                })}
                {hiddenSeeMoreButton ? null : (
                  <a
                    className={css.seeMoreButton}
                    onClick={() => {
                      let s = { ...this.state };
                      s[filter.shortModeFiled] = !s[filter.shortModeFiled];
                      this.setState(s);
                    }}
                  >
                    <i className={arrowIconCss} />
                    <FormattedMessage id={showMoreText} />
                  </a>
                )}
              </div>
            );
          })}
      </div>
    );

    const renderUpdateForm = () => {
      const { mobileFilterMenu } = this.state;
      return (mobileFilterMenu && (<div className={css.mobileFilterModalContainer}>
        <div className={css.mobileFilterModalWindow}>
          <button className={css.closeButton} onClick={() => this.setState({ mobileFilterMenu: false })} />
          {mobileFilters}
        </div>
      </div>));
    };

    return (
      <Page
        className={css.root}
        scrollingDisabled={scrollingDisabled}
        contentType="website"
        description={schemaDescription}
        title={schemaTitle}
        robots='index'
        facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
        twitterImages={[
          { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
        ]}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'WebPage',
          description: schemaDescription,
          name: schemaTitle,
          image: [schemaImage],
        }}
      >
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer productCategoryCode={params.productCategoryCode} searchInputValue={params.search} />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div className={css.topProductsContainer}>
              <div className={css.topProducts}>
                <div className={css.topProductInfo}>
                  <p className={currentCategoryName ? css.featuredThisWeekLabel : css.featuredThisWeekAllCategoriesLabel}>
                    <FormattedMessage id="SearchProductsPage.newInWeekLabel" />
                  </p>
                  <p className={css.inCategoryLabel}>{currentCategoryName}</p>
                </div>
                <div className={css.topProductContainer}>
                  {topProducts.map((p) => {
                    const style = {};
                    const mainImageUrl = p.attributes.publicData.mainImageUrl;
                    if (p.attributes.publicData.mainImageUrl) {
                      style.backgroundImage = `url('${mainImageUrl}')`;
                    }
                    const images = p.attributes.publicData.productImages;
                    if (!mainImageUrl && images && images.length > 0) {
                      style.backgroundImage = `url('${images[0].url}')`;
                    }
                    return (
                      <div key={p.id.uuid} className={css.topProductItem}>
                        <NamedLink
                          name="SupplierDetailsPage"
                          params={{ brand: slugify(p?.attributes?.publicData?.brandName), id: p?.attributes?.publicData?.brandId, locale: locale }}
                        >
                          <div className={css.topProductImage} style={style}>
                            &nbsp;
                          </div>
                        </NamedLink>
                        <p className={css.topProductTitle}>
                          <NamedLink
                            name="SupplierDetailsPage"
                            params={{ brand: slugify(p?.attributes?.publicData?.brandName), id: p?.attributes?.publicData?.brandId, locale: locale }}
                          >
                            {p.attributes?.publicData?.brandName}
                          </NamedLink>
                        </p>
                        <p className={css.topProductCountry}>
                          {p.attributes?.publicData?.location}
                        </p>
                      </div>
                    );
                  })}
                </div>
                <div className={css.swiperButtonContainer}>
                  <div className={classNames(css.leftButton)}>
                    <img src={arrowLeft} alt=""></img>
                  </div>
                  <div className={classNames(css.rightButton)}>
                    <img src={arrowRight} alt=""></img>
                  </div>
                </div>
              </div>
            </div>
            <div className={css.pageContentContainer}>
              <div className={css.pageContent}>
                <div className={css.filterMobileMenu}>
                  <a className={css.filtersButtonText} onClick={() => this.setState({ mobileFilterMenu: true })}>
                    <FormattedMessage id="SearchProductsPage.mobileFilterButton" />
                  </a>
                </div>
                <div className={css.productFilterSection}>
                  {filterFormat
                    .filter(f => !f.hide)
                    .map(filter => {
                      const hiddenSeeMoreButton = !filter.shortModeFiled;
                      const showAllItems =
                        hiddenSeeMoreButton || this.state[filter.shortModeFiled] === true;
                      const items = showAllItems ? filter.items : filter.items.slice(0, 3);
                      const showMoreText = showAllItems
                        ? 'SearchProductsPage.hideLabel'
                        : 'SearchProductsPage.seeMoreLabel';
                      const arrowIconCss = classNames(css.arrow, {
                        [css.up]: showAllItems,
                        [css.down]: !showAllItems,
                      });
                      return (
                        <div key={filter.fieldName} className={css.filterFieldItem}>
                          <p className={css.filterName}>
                            <FormattedMessage id={filter.nameId} />
                          </p>
                          {items.map(item => {
                            const key = `${filter.fieldName}_${item.value}`;
                            const checked = filter.multiselect
                              ? (this.state[filter.fieldName] || []).indexOf(
                                item.value.toString()
                              ) > -1
                              : this.state[filter.fieldName] === item.value;
                            return (
                              <div key={key} className={css.filterOption}>
                                <input
                                  type={filter.multiselect ? 'checkbox' : 'radio'}
                                  value={item.value}
                                  checked={checked}
                                  name={filter.fieldName}
                                  id={key}
                                  onChange={e => {
                                    this.onChangeFilter(filter.fieldName, e, filter.multiselect);
                                  }}
                                />
                                <label htmlFor={key}>{item.name}</label>
                              </div>
                            );
                          })}
                          {hiddenSeeMoreButton ? null : (
                            <a
                              className={css.seeMoreButton}
                              onClick={() => {
                                let s = { ...this.state };
                                s[filter.shortModeFiled] = !s[filter.shortModeFiled];
                                this.setState(s);
                              }}
                            >
                              <i className={arrowIconCss} />
                              <FormattedMessage id={showMoreText} />
                            </a>
                          )}
                        </div>
                      );
                    })}
                </div>
                <div className={css.productsContainer}>
                  <div className={css.productsHeaderContainer}>
                    <h1 className={css.containerTitle}>{productsContainerTitle}</h1>
                    <h2 className={css.categoryDescription}>
                      {topCategoryText}
                    </h2>
                    <div className={css.productsHeaderSection}>
                      <div className={css.productsHeader}>
                        <p className={css.containerSubTitle}>
                          {isAuthenticated ?
                            <>
                              {totalCount}&nbsp;
                              <FormattedMessage id="SearchProductsPage.productsLabel" />
                              &nbsp; | &nbsp;
                            </> : null
                          }
                          {filterTitle}
                        </p>
                      </div>
                      <div className={css.clearFiltersAndSortByContainer}>
                        <div>
                          <button className={css.filterClearButton}
                            disabled={!this.state.hasFilter}
                            onClick={this.onClearFilterClick}>
                            <div className={classNames(css.filterClearButtonIcon, { [css.filterClearButtonIconDisabled]: greyIcon })}>Clear</div>
                            <FormattedMessage id="SearchProductsPage.clearAllFiltersLabel" />
                          </button>
                        </div>
                        <div className={css.sortbyButtonContainer}>
                          <div className={css.sortByName}><FormattedMessage id="SearchProductsPage.sortBy" />
                          </div>
                          <Menu>
                            <MenuLabel className={css.filterMenuLabel}>
                              <div className={css.sortByFilterOptions}>{this.state.menuItemSelected}
                              </div>
                              <div className={css.filterArrow}></div>
                            </MenuLabel>
                            <MenuContent className={css.filterMenuContent}>
                              <MenuItem
                                className={css.menuItem}
                                key={newest}
                                onClick={() => {
                                  this.onChangeFilter('sort', { target: { value: 'createdAt' } });
                                  this.onFilterByDateItemClick(newest);
                                }}
                              >
                                <FormattedMessage id="SearchProductsPage.filterNewest" />
                              </MenuItem>
                              <MenuItem
                                className={css.menuItem}
                                key={oldest}
                                onClick={() => {
                                  this.onChangeFilter('sort', { target: { value: '-createdAt' } });
                                  this.onFilterByDateItemClick(oldest);
                                }}
                              >
                                <FormattedMessage id="SearchProductsPage.filterOldest" />
                              </MenuItem>
                            </MenuContent>
                          </Menu>
                        </div>
                      </div>
                    </div>
                  </div>
                  {searchProductsInProgress ? <LoaderComponent /> :
                    <div className={css.productGrid}>
                      <ProductContainer products={viewProductsCheck} rowItemCount={3} emptyStateOptions={emptyStateOptions} />
                    </div>}

                  {!searchProductsInProgress && !isAuthenticated && !noProductsFound ?
                    (
                      <div className={css.showAllProduct}>
                        <div className={css.showAllProductTextContainer}>
                          <h2 className={css.showAllTitle}>
                            <FormattedMessage id="SearchProductsPage.showMoreTitle" />
                          </h2>
                          <p className={css.showAllSubtitle}>
                            <FormattedMessage id="SearchProductsPage.showMoreSubtitle" />
                          </p>
                          <div className={css.showAllButtonContainer}>
                            <a onClick={onShowRegistrationConfirmDialog} className={css.showAllButton}>
                              <FormattedMessage id="SearchProductsPage.showMoreButton" />
                            </a>
                          </div>
                        </div>
                        <div className={css.showAllProductImageContainer}>
                          <img className={css.brandIcon} src={brandIcon} alt="Sign up to get a full access to the marketplace. It’s easy, fast & free." />
                        </div>
                      </div>
                    ) : null
                  }
                  <div className={css.productPager}>
                    {isAuthenticated ?
                      <PaginationButtons
                        totalPages={totalPages}
                        currentPage={currentPage}
                        onPageChange={this.onPageChange}
                      /> : null}
                  </div>
                  <div className={css.categoryBottomDescriptionSection}>
                    <h2 className={css.bottomCategoryTitle}>
                      {bottomCategoryTitle1}
                    </h2>
                    <p className={css.categoryDescription}>
                      {bottomCategoryDescription1_1}
                    </p>
                    <p className={css.categoryDescription}>
                      {bottomCategoryDescription1_2}
                    </p>
                    <p className={css.categoryDescription}>
                      {bottomCategoryDescription1_3}
                    </p>
                    <h2 className={css.bottomCategoryTitle}>
                      {bottomCategoryTitle2}
                    </h2>
                    <p className={css.categoryDescription}>
                      {bottomCategoryDescription2_1}
                    </p>
                    <p className={css.categoryDescription}>
                      {bottomCategoryDescription2_2}
                    </p>
                    <p className={css.categoryDescription}>
                      {bottomCategoryDescription2_3}
                    </p>
                    <p className={css.categoryDescription}>
                      {bottomCategoryDescription2_4}
                    </p>
                    <p className={css.categoryDescription}>
                      {bottomCategoryDescription2_5}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {renderUpdateForm()}
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

SearchProductsPageComponent.defaultProps = {
  currentUserListing: null,
  currentUserListingFetched: false,
};

SearchProductsPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
  // from withRouter
  history: object.isRequired,
  location: object.isRequired,
  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = (state) => {
  const { isAuthenticated } = state.Auth;
  const {
    topCategoryProductsError,
    topCategoryProductsInProgress,
    searchProductsError,
    searchProductsInProgress,
    topCategoryProducts,
    searchedProducts
  } = state.SearchProductsPage;

  return {
    scrollingDisabled: isScrollingDisabled(state),
    topCategoryProductsError,
    topCategoryProductsInProgress,
    searchProductsError,
    searchProductsInProgress,
    topCategoryProducts,
    searchedProducts,
    isAuthenticated
  };
};

const mapDispatchToProps = (dispatch) => ({
  onSearchProducts: (values) => dispatch(searchProducts(values)),
  onShowRegistrationConfirmDialog: () => dispatch(displayRegistrationConfirmDialog(true, true)),
});

const SearchProductsPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(SearchProductsPageComponent);

SearchProductsPage.loadData = (params) => (dispatch/*, getState, sdk*/) => {
  return Promise.all([dispatch(loadTopCategoryProducts(params)), dispatch(searchProducts(params))]);
};

export default SearchProductsPage;
